import React from 'react';
import Layout from '../../layout/layout';
import AddVariantNameForm from '../../wrapper/variant/AddVariantNameForm';

const AddVariantNamePage = () => {

  return (
    <Layout>
      <AddVariantNameForm />
    </Layout>
  )
}

export default AddVariantNamePage