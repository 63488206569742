import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Layout from "../../layout/layout";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
// import makeAnimated from "react-select/animated";
// import Select from 'react-select'
import axiosauth from "../../axios-config-auth";
import SuccessModal from "../../components/halper/SuccessModal";

const AddTaxonomy = () => {
  // const animatedComponents = makeAnimated()
  const history = useHistory();
  const [specs, setspecs] = useState([
    {
      startAmount: "",
      endAmount: "",
      gstPercentage: "",
    },
  ]);
  const [gstobj, setgstObj] = useState([]);

  const [taxName, setTaxname] = useState("");

  const [success, setSuccess] = useState(false);
  const [networkError, setNetworkError] = useState("");
  const [redirect, setRedirect] = useState(false);
  // const [gstError, setGstError] = useState("");

  // const [gst, setGst] = useState("");
  // console.log("gst", specs)

  const [speNameError, setSpeNameError] = useState("");
  const [speValueError, setSpeValueError] = useState("");
  // const [spegstError, setSpegstError] = useState("");
  const [isValidprice, setIsValidPrice] = useState();
  const [invalidGST, setInvalidGST] = useState("");
  const [invalid, setInvalid] = useState(false);

  const addspecs = () => {
    setspecs([...specs, { startAmount: "", endAmount: "", gstPercentage: "" }]);
  };

  const handleChange2 = (e, name, index) => {
    const { value } = e.target;
    // console.log("valuuuueee", value)
    const list = [...specs];
    list[index][name] = value;
    setspecs(list);
    setSpeNameError("");
    setSpeValueError("");
  };

  const removespecs = (index) => {
    const temp = [...specs];
    const filter = temp.filter((item, i) => i !== index);
    setspecs(filter);
  };

  const handleSubmit = () => {
    function checkValueGraterorNot(obj) {
      // debugger;
      if (parseInt(obj.startAmount) == null) {
        return false;
      }
      if (parseInt(obj.endAmount) == null) {
        return true;
      }
      // console.log((obj.gstPercentage) ,parseInt(obj.endAmount))
      if (obj.gstPercentage == "") {
        // alert("Invalid GST") /
        setInvalidGST("Invalid GST");
        // setTimeout(()=>{
        //     setInvalidGST("")
        // },4000)
        // return false
      }
      if (parseInt(obj.startAmount) >= parseInt(obj.endAmount)) {
        return false;
      }
      return true;
    }

    function objectGstCheck(ListObject) {
      if (ListObject.length < 1) {
        // alert("Enter Valid Price ")
        return false;
      }
      var count = ListObject.length;
      for (var obj = 0; obj < count; obj++) {
        if (obj < count && obj + 1 != count) {
          if (
            parseInt(ListObject[obj + 1].startAmount) <=
            parseInt(ListObject[obj].endAmount)
          ) {
            alert("Enter Valid Start Price should be greater than  End Price");
            return false;
          }
          if (
            parseInt(ListObject[obj + 1].gstPercentage) <=
            parseInt(ListObject[obj].gstPercentage)
          ) {
            alert("Enter Valid  Start GST should be greater than End GST");
            return false;
          }

          if (!checkValueGraterorNot(ListObject[obj])) {
            // alert("Enter Valid End Price")
            return false;
          }
        } else {
          if (!checkValueGraterorNot(ListObject[obj])) {
            // alert("Enter Valid Price ")
            return false;
          }
        }
      }
      return true;
    }
    var demo1 = specs;

    setIsValidPrice(objectGstCheck(demo1));

    let obj = [];

    specs.forEach((e) => {
      obj.push({
        startAmount: parseInt(e.startAmount),
        endAmount: parseInt(e.endAmount),
        gstPercentage: parseInt(e.gstPercentage),
      });
    });
    setgstObj(obj);
    // console.log("gstobj====>>", gstobj)

    if (objectGstCheck(demo1) === true) {
      let obj1 = {};
      // console.log("===",invalidGST)
      // if(invalidGST.length > 0) {
      //     console.log("enter")
      //     obj1.taxName=obj
      //     obj1.gstName=taxName

      obj1 = {
        taxName: obj,
        gstName: taxName,
      };
      // }
      // console.log(obj1);
      // let obj1 = {
      //     "taxName":invalidGST.length>0 ? obj :"",
      //     "gstName":  taxName ,

      // }
      // let obj = {
      //     "gstName" :"GST3",
      //    "taxName": [{
      //        "startAmount":"10" ,
      //        "endAmount": "100",
      //        "gstPercentage": "12"
      //    },
      //    {
      //        "startAmount":"1000" ,
      //        "endAmount": "10045",
      //        "gstPercentage": "12"
      //    }

      //    ]
      // }
      axiosauth
        .post("/gst/create-gst", obj1)
        .then(async (response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            setSuccess(true);
          } else {
            setNetworkError("Some Error Occured");
            setTimeout(() => {
              setNetworkError("");
            }, 6000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const closeModal = () => {
    setRedirect(true);
  };

  const myStyle = {
    marginTop: "12px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "12px",
    color: "#4F4F4F",
  };

  return (
    <Layout>
      <div className="d-flex justify-content-between mt-3">
        <div
          className="left_arrow2 back-arrow align-self-center mb-0"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Add Taxonomy</h2>
        </div>
        <div className="my-5">
          <div className="d-flex ">
            <div className="button ml-0">
              <button onClick={() => history.goBack()}>Back</button>
            </div>
            <div className="button ml-2">
              <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
          </div>
        </div>
      </div>
      {networkError.length > 4 && (
        <p style={{ color: "red" }}>{networkError}</p>
      )}
      <div className="container">
        <div class="mb-3">
          <label className="form-label" style={myStyle}>
            Tax Slab Name
          </label>
          <input
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="Enter tax name"
            onChange={(e) => setTaxname(e.target.value)}
          />
        </div>
        <div className="specification justify-content-between">
          <>
            <label className="form-label" style={myStyle}>
              Tax Slab Value
            </label>
            {specs && specs.length > 0
              ? specs.map((value, index) => {
                  return (
                    <>
                      <section
                        className=" my-3 d-flex"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className="" style={{ width: "33%" }}>
                          <input
                            type="number"
                            placeholder="From..."
                            value={value.startAmount}
                            className={"form-control form-control-nonround"}
                            mandatory={true}
                            onChange={(e) =>
                              handleChange2(e, "startAmount", index)
                            }
                          />

                          <i style={{ fontSize: "13px", color: "red" }}>
                            {speNameError}
                          </i>
                        </div>

                        <div className=" mx-3" style={{ width: "33%" }}>
                          <input
                            type="number"
                            placeholder="To..."
                            value={value.endAmount}
                            className={"form-control form-control-nonround"}
                            mandatory={true}
                            onChange={(e) =>
                              handleChange2(e, "endAmount", index)
                            }
                          />

                          <i style={{ fontSize: "13px", color: "red" }}>
                            {speValueError}
                          </i>
                        </div>
                        {/* <div id="w-100"  style={{width:"33%"}}> 
                                                <Select 
                                                    closeMenuOnSelect={true}
                                                    components={animatedComponents}
                                                    options={gstList}
                                                    name="gst"
                                                    // required
                                                    onChange={handleSelectGst}
                                                    // onChange={(e) =>
                                                    //      handleChange2(e, "gst", index)
                                                    //     }

                                                />
                                                <i className="error">{gstError}</i>
                                            </div> */}
                        {/* </div> */}
                        <div style={{ width: "33%" }}>
                          <select
                            className="form-select form-control form-control-nonround"
                            aria-label="Default select example"
                            required={true}
                            onChange={
                              (e) => {
                                handleChange2(e, "gstPercentage", index);
                                setInvalid(true);
                              }
                              // console.log(e.target.value)
                            }
                          >
                            <option value="0" selected>
                              Open this select menu
                            </option>
                            <option value="3">3 %</option>
                            <option value="5">5 %</option>
                            <option value="12">12 %</option>
                            <option value="18">18 %</option>
                            <option value="28">28 %</option>
                          </select>
                          <i style={{ fontSize: "13px", color: "red" }}>
                            {invalidGST.length > 2 ? "Invalid GST" : ""}
                          </i>
                        </div>

                        <span className="mx-5">
                          {specs.length > 1 && (
                            <button
                              className="cross_button"
                              style={{ paddingTop: "0" }}
                              onClick={() => removespecs(index)}
                            >
                              {" "}
                              X
                            </button>
                          )}
                        </span>
                      </section>
                    </>
                  );
                })
              : null}

            {specs.length < 10 ? (
              <div className="button-variant">
                <button onClick={() => addspecs()}>+ADD</button>
              </div>
            ) : null}
          </>
        </div>
      </div>
      {invalid == true && (
        <div className="button">
          <button className="px-5" onClick={handleSubmit}>
            Save
          </button>
        </div>
      )}
      {redirect ? <Redirect to="/taxonomy" /> : null}
      {success ? (
        <SuccessModal message="New Taxonomy Created !" onClick={closeModal} />
      ) : null}
    </Layout>
  );
};

export default AddTaxonomy;
