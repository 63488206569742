import {
    FATCH__USER,
    USER_LOADING,
    USER_FAIL,
    SEARCHING,
    FATCH__USER_BY_ID
} from '../constants/types';

const initialState = {
    loading: false,
    data: [],
    error: ''
}

export function userData(state = initialState, action) {
    switch (action.type) {
        case FATCH__USER:
            return {
                ...state,
                data: action.payload,
                loading: false
            }

        case FATCH__USER_BY_ID:
            return {
                ...state,
                data: action.payload,
                loading: false
            }

        case USER_LOADING:
            return {
                ...state,
                data: [],
                loading: true,
            }

        case USER_FAIL:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }
        case SEARCHING:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        default:
            return state;
    }
}