import React, { useEffect, useRef, useState } from 'react';
import uploadIcon from '../../assets/icons/upload.png';
import { Redirect, useParams } from "react-router-dom";
import { fetchCollectionById, updateCollection } from '../../redux/actions/collectionAction';
import { useDispatch } from 'react-redux';
import SuccessModal from '../../components/halper/SuccessModal';
import Loader from 'react-loader-spinner';
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory } from "react-router-dom"
import editImageIcon from '../../assets/icons/edit.png';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosauth from '../../axios-config-auth';
import { Editor } from '@tinymce/tinymce-react';

const EditCollectionForm = () => {
  const animatedComponents = makeAnimated();
  const history = useHistory()
  const [file, setfile] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [isExist, setExist] = useState(false)
  const [urlFile, setUrlFile] = useState("")
  const { collectionId } = useParams();
  const [redirect, setRedirect] = useState(false);

  const dispatch = useDispatch();
  // const collections = useSelector(state => state.collectionData);
  const [collections, setCollections] = useState()
  const [loading, setLoading] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(null);
  const [error, setError] = useState(false);
  const [seoText, setSEOText] = useState('');
  const editorRef = useRef(null);

  // ==============================================================
  const [isApply, setIsApply] = useState("all-Products");
  const [productOptions, setproductOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [subsubcategoryOptions, setSubsubCategoryOptions] = useState([]) 
  const [checkboxLoading , setCheckboxloading] = useState(false);

  const[product , setProduct] = useState([]);
  const [productuse , setproductuse] = useState([])
  const [selectProduct, setSelectProduct] = useState([]);

  const [cat , setCat] = useState([])
  const [categoryuse, setcategoryuse] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);

  const [subcat , setSubCat] = useState([])
  const [subcategoryuse, setSubcategoryuse] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState([]);

  const [subsubcat , setSubSubCat] = useState([])
  const [subsubcategoryuse, setSubsubcategoryuse] = useState([]);
  const [selectSubsubCategory, setSelectSubsubCategory] = useState([]);


  const handleProduct = (selectedOption) => {
    setSelectProduct({ selectedOption });
    setproductuse(selectedOption);
  };

  const handleCategory = (selectedOption) => {
    setSelectCategory({ selectedOption });
    setcategoryuse(selectedOption);
  };
  const handleSUbCategory = (selectedOption) => {
    setSelectSubCategory({ selectedOption });
    setSubcategoryuse(selectedOption);
  };

  const handleSubsubCategory = (selectedOption) => {
    setSelectSubsubCategory({ selectedOption });
    setSubsubcategoryuse(selectedOption);
  };


  
  useEffect(() => {
    let arr = [];
    const obj = {
      limit: 10000000,
      // suspend: "false",
      filters: {
        suspend: "false"
        // sub1categoryslug: search,
      },
      sorts: { sortbyPrice: 1 },
      userId: ""
    }
    axiosauth
      .post("/products/loadv2", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
          console.log("setProduct====>>" ,res.data.products )
          setProduct(res.data.products)
          for (var i = 0; i < res.data.products.length > 0; i++) {
            arr.push({
              value: res.data.products[i]._id,
              label: res.data.products[i].catalogueName
            })
          }
          setproductOptions(arr)

        } else {
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

  }, [])


  useEffect(() => {
    let arr = [];
    const obj = {
      "limit": 10000,
      "filters": {
        "type": "category"
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
          // setCategoryObj(res.data.categories)
          setCat(res.data.categories)
          if (res.data.categories) {
            for (var i = 0; i < res.data.categories.length; i++) {
              arr.push({
                value: res.data.categories[i]._id,
                label: res.data.categories[i].name
              })
            }
          }
          setCategoryOptions(arr)
          setCheckboxloading(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let arr = [];
    const obj = {
      "limit": 10000,
      "filters": {
        "type": "sub1"
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
          setSubCat(res.data.categories)
          if (res.data.categories) {
            for (var i = 0; i < res.data.categories.length; i++) {
              arr.push({
                value: res.data.categories[i]._id,
                label: res.data.categories[i].name + " (" + res.data.categories[i].parentCategory[0]?.name + " )"
              })
            }
            setCheckboxloading(true)
            setSubCategoryOptions(arr)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let arr = [];
    const obj = {
      "limit": 10000,
      "filters": {
        "type": "sub2"
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
          // console.log("--------->>>>" , res.data.categories)
          setSubSubCat(res.data.categories)
          if (res.data.categories) {
            for (var i = 0; i < res.data.categories.length; i++) {
              arr.push({
                value: res.data.categories[i]._id,
                label: res.data.categories[i].name + " (" + res.data.categories[i].parentCategory[0]?.name + " )"
              })
            }
            setSubsubCategoryOptions(arr)
          }
          setCheckboxloading(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [collection, setCollection] = useState({
    name: '',
    description: '',
    value: "",
    startPrice: null,
    endPrice: null,
    startOffer: null,
    endOffer: null,
    collectionType: null,
  })

  const handleChange = (e) => {
    setCollection({ ...collection, error: false, [e.target.name]: e.target.value })
  }

  const submitCollection = (e) => {
    e.preventDefault();

    if (collection.collectionType === "price") {

      if (parseInt(collection.startPrice) > parseInt(collection.endPrice)) {
        setError(true)
        alert("Please Enter End Value Greater than Start Value")
        return 0;
      }
    }

    if (collection.collectionType === "offer") {

      if ((parseInt(collection.startOffer) >= parseInt(collection.endOffer))) {
        setError(true)
        alert("Please Enter End Offer Greater than Start Offer")
        return 0;
      }
    }

    if (collection.collectionType === "offer") {

      if ((parseInt(collection.endOffer) > 99)) {
        setError(true)
        alert("Please Enter End Offer Greater than Start Offer")
        return 0;
      }
    }
    if (collection.collectionType === "offer") {

      if ((parseInt(collection.startOffer) > 99)) {
        setError(true)
        alert("Please Enter End Offer Greater than Start Offer")
        return 0;
      }
    }

    var product_list = [];
    var category_list = [];
    var subcategory_list = [];
    var subsubcategory_list = [];

    if (isApply === "specific-products") {
      for (var i = 0; i < selectProduct.selectedOption?.length; i++) { 
        product_list.push(selectProduct.selectedOption[i].value);
      }
    }
    if (isApply === "specific-category") {
      for (var i = 0; i < selectCategory.selectedOption?.length; i++) { 
        category_list.push(selectCategory.selectedOption[i].value);
      }
    }
    if (isApply === "specific-sub-category") {
      for (var i = 0; i < selectSubCategory.selectedOption?.length; i++) { 
        subcategory_list.push(selectSubCategory.selectedOption[i].value);
      }
    }
    if (isApply === "specific-sub-sub-category") {
      for (var i = 0; i < selectSubsubCategory.selectedOption?.length; i++) { 
        subsubcategory_list.push(selectSubsubCategory.selectedOption[i].value);
      }
    }

// console.log("product_list---->>" , product_list ,category_list , subcategory_list , subsubcategory_list)

    let obj = {
      _id: collectionId,
      title: collection.name,
      active: true,
      searchKey: (collection.collectionType === "tag" || collection.collectionType === "name") ? collection.value : null,
      description: collection.shortDescription,
      type: collection.collectionType,
      priceGt: collection.collectionType === "price" ? collection.endPrice : null,
      priceLt: collection.collectionType === "price" ? collection.startPrice : null,
      discountFrom: collection.collectionType === "offer" ? collection.startOffer : null,
      seoText : seoText,
      discountTo: collection.collectionType === "offer" ? collection.endOffer : null,
      ...( product_list.length>0 && isApply === "specific-products" && { products : product_list,category:[],sub1category:[],sub2category:[]}),
      ...( category_list.length>0 && isApply === "specific-category" && { category : category_list,products:[],sub1category:[],sub2category:[]}),
      ...( subcategory_list.length>0 && isApply === "specific-sub-category" && { sub1category : subcategory_list,products:[],category:[],sub2category:[]}),
      ...( subsubcategory_list.length>0 && isApply === "specific-sub-sub-category" && { sub2category : subsubcategory_list,products:[],category:[],sub1category:[]}),
      ...(isApply === 'all-Products' && {products:[],category:[],sub1category:[],sub2category:[]})
    }


    dispatch(updateCollection(obj, urlFile, setUpdateStatus));
  }
 
  console.log(subsubcat)


  useEffect(() => {
    // console.log("/edit-collection/" , collections?.collections.image)
    if (collections?.collections?.image) {
      setExist(true)
      setIsFile(true)
      // console.log(collections?.collections?.image[0].url)
      setfile(collections?.collections?.image[0].url)
    }
    else {
      setExist(false)
      setIsFile(false)
    }
    if (typeof collections === "object") {

      setCollection(
        {
          name: collections.collections.title,
          shortDescription: collections.collections.description,
          collectionType: collections.collections.type,
          startPrice: collections.collections.priceLt,
          endPrice: collections.collections.priceGt,
          startOffer: collections.collections.discountFrom,
          endOffer: collections.collections.discountTo,
          value: collections.collections.searchKey,
        }
      )

      setSEOText(collections.collections?.seoText || '')
      // console.log("collections------>>>>",collections.collections)
      

      if (collections.collections.products && collections.collections?.products?.length !== 0) {
        setIsApply("specific-products");
        let plist = [];

        for (let a = 0; a < collections.collections?.products.length; a++) {
          let index;

       
          for (let x = 0; x < product?.length; x++) {
            if (product[x]._id === collections.collections?.products[a]) { 
              index = x;
            }
          } 
          plist.push({
            value: product[index]?._id,
            label: product[index]?.catalogueName,
          });
        }
        setproductuse(plist);
        setCheckboxloading(true)
      }

      if (collections.collections.category && collections.collections?.category?.length !== 0) {
        setIsApply("specific-category");
        let catlist = [];

        for (let a = 0; a < collections.collections?.category.length; a++) {
          let index;

          for (let x = 0; x < cat?.length; x++) {
            if (cat[x]._id === collections.collections?.category[a]) {
              index = x;
            }
          } 
          catlist.push({
            value: cat[index]?._id,
            label: cat[index]?.name,
          });
        }
        setcategoryuse(catlist);
        setCheckboxloading(true)
      }

      if (collections.collections.sub1category && collections.collections?.sub1category?.length !== 0) {
        setIsApply("specific-sub-category");
        let subcatlist = [];

        for (let a = 0; a < collections.collections?.sub1category.length; a++) {
          let index;
          // console.log("sub category--->>" ,subcat)
          for (let x = 0; x < subcat?.length; x++) {
          

            if (subcat[x]._id === collections.collections?.sub1category[a]) {

              index = x;
            }
          } 
          subcatlist.push({
            value: subcat[index]?._id,
            label: subcat[index]?.name,
          });
        }
        setSubcategoryuse(subcatlist);
        setCheckboxloading(true)
      }

      if (collections.collections.sub2category && collections.collections?.sub2category?.length !== 0 ) {
        setIsApply("specific-sub-sub-category");
        let subsubcatlist = [];

        for (let a = 0; a < collections.collections?.sub2category.length; a++) {
          let index;  
          for (let x = 0; x < subsubcat?.length; x++) { 
         

            if (subsubcat[x]._id === collections.collections?.sub2category[a]) { 
                 console.log("sub sub category--->>" )
              index = x;
            }
          } 
          // console.log("sub sub category--->>" ,subsubcat[index]?.name, subsubcat[index]?._id)
          subsubcatlist.push({
            value: subsubcat[index]?._id,
            label: subsubcat[index]?.name,
          });
        }
        setSubsubcategoryuse(subsubcatlist);
        setCheckboxloading(true)
      }
 
    }
  }, [collections ,product, cat , subcat , subsubcat]);


  useEffect(() => {
    dispatch(fetchCollectionById({ _id: collectionId }, setCollections, setLoading));
  }, [dispatch, collectionId]);

  const closeModal = () => {
    setRedirect(true);
  }

  return (
    <>
      {redirect ? <Redirect to="/collection" /> : null}
      {/*  {collections.data.success === true ? <SuccessModal message="Collection Updated !" onClick={closeModal} /> : null} */}
      {updateStatus === true ? <SuccessModal message="Collection Updated !" onClick={closeModal} /> : null}

      {!loading ?

        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={5000} //5 secs

          />
        </div>
        :



        <form onSubmit={submitCollection} className="form-wrap">
          <div className="form-header">
            <div
              className="left_arrow2 back-arrow align-self-center mb-0"
              style={{
                display: "flex",
                alignItems: "baseline",
                fontFamily: "inherit",
              }}
            >
              <img
                src={left_arrow2}
                onClick={() => history.goBack()}
                alt="left-arrow"
              />{" "}
              &nbsp;
              <h2>Edit Collection</h2>
            </div>
          </div>

          <div className="form-body">
            <div className="single-field">
              <p>Collection Name <b>*</b></p>
              <input type="text"
                placeholder="Collection Name"
                name="name"
                value={collection.name}
                onChange={handleChange}
              />
            </div>

            <section>
              <div className="two-field w-100">
                <p>
                  Collection Type
                  <b>*</b>
                </p>
                <div className="d-flex">
                  <select
                    id='collectionType'
                    name='collectionType'
                    class="form-select"
                    aria-label="Default select example"
                    onChange={handleChange}
                    required={true}
                    value={collection.collectionType}
                  >
                    <option selected>Select Collection Type</option>
                    <option value="name">Name</option>
                    <option value="price">Price</option>
                    <option value="offer">Offer</option>
                    <option value="tag">Tag</option>
                  </select>

                  {(collection.collectionType === "name" || collection.collectionType === "tag") && (
                    <input
                      type="text"
                      id='value'
                      name='value'
                      value={(collection.collectionType === "name" || collection.collectionType === "tag") ? collection.value : null}
                      placeholder={`Enter ${collection.collectionType === "name" ? "Name" : "Tag"}`}
                      onChange={handleChange}
                      required={true}
                    />
                  )}

                  {(collection.collectionType === "price" || collection.collectionType === "offer") && (
                    <>
                      <input
                        type="number"
                        id='startPrice'
                        //  style={{  border: "1px solid #00FF00" }}
                        style={{ border: error === true ? "1px solid #EE4B2B" : null }}
                        name={`${collection.collectionType === "price" ? 'startPrice' : 'startOffer'}`}
                        value={`${collection.collectionType === "price" ? collection.startPrice : collection.startOffer}`}
                        placeholder={`Enter ${collection.collectionType === "price" ? "Price" : "Offer"} Start Value`}
                        onChange={handleChange}
                        min={0}
                        required={true}
                      />
                      <input
                        type="number"
                        id='endPrice'
                        style={{ border: error === true ? "1px solid #EE4B2B" : null }}
                        name={`${collection.collectionType == "price" ? "endPrice" : "endOffer"}`}
                        value={`${collection.collectionType === "price" ? collection.endPrice : collection.endOffer}`}
                        placeholder={`Enter ${collection.collectionType === "price" ? "Price" : "Offer"} End Value`}
                        onChange={handleChange}
                        min={0}
                        required={true}
                      />
                    </>
                  )}
                </div>
              </div>
            </section>


            <div id="short-description">
              <p>Description <b>*</b></p>
              <textarea
                placeholder="Description"
                name="shortDescription"
                value={collection.shortDescription}
                onChange={handleChange}
              />
            </div>

{checkboxLoading === true && 
            <div className="container mt-5">
              <div className="radio-wrap ">
                <h4 className="mb-3">
                  {" "}
                  Applied to <b>*</b>
                </h4>

                <div className="alignment-justify">
                  <aside>
                    <input
                      type="radio"
                      name="gender"
                      value="all-Products"
                      checked={isApply === "all-Products" ? true : false}
                      onClick={() => setIsApply("all-Products")}
                    />
                    <span>No Select</span>
                  </aside>
                  <aside>
                    <input
                      type="radio"
                      name="gender"
                      value="specific-products"
                      checked={isApply === "specific-products" ? true : false}
                      onClick={() => setIsApply("specific-products")}
                    />
                    <span>Specific Products</span>
                  </aside>
                  <aside>
                    <input
                      type="radio"
                      name="gender"
                      value="specific-category"
                      checked={isApply === "specific-category" ? true : false}
                      onClick={() => setIsApply("specific-category")}
                    />
                    <span>Specific Category</span>
                  </aside>
                  <aside>
                    <input
                      type="radio"
                      name="gender"
                      value="specific-sub-category"
                      checked={isApply === "specific-sub-category" ? true : false}
                      onClick={() => setIsApply("specific-sub-category")}
                    />
                    <span>Specific Sub Category</span>
                  </aside>
                  <aside>
                    <input
                      type="radio"
                      name="gender"
                      value="specific-sub-sub-category"
                      checked={isApply === "specific-sub-sub-category" ? true : false}
                      onClick={() => setIsApply("specific-sub-sub-category")}
                    />
                    <span>Specific Sub SUb Category</span>
                  </aside>

                </div>
              </div>
              <div className='mb-5'>
                {isApply === "specific-products" ? (
                  <div id="select-tag">
                    <p></p>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      value={productuse}
                      options={productOptions}
                      onChange={handleProduct}
                    />
                  </div>
                ) : null}

                {isApply === "specific-category" ? (
                  <div id="select-tag">
                    <p></p>
                    <Select 
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      value={categoryuse}
                      options={categoryOptions}
                      onChange={handleCategory}
                    />
                  </div>
                ) : null}

                {isApply === "specific-sub-category" ? (
                  <div id="select-tag">
                    <p></p>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      value={subcategoryuse}
                      options={subCategoryOptions}
                      onChange={handleSUbCategory}
                    />
                  </div>
                ) : null}

                {isApply === "specific-sub-sub-category" ? (
                  <div id="select-tag">
                    <p></p>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      value={subsubcategoryuse}
                      options={subsubcategoryOptions}
                      onChange={handleSubsubCategory}
                    />
                  </div>
                ) : null}
              </div>
            </div>
}
           
            <p className="label-form"> {`Image Dimension Must be (400 x 400 ) , image (jpg, png , WebP) and image size must be less than < 200KB`}<b>*</b></p>
            <section className="image-uploaded">
              {!isFile ?
                <div className="upload-btn-wrapper mt-2">
                  <button className="uplod-btn">
                    {console.log("first")}
                    <img src={uploadIcon} alt="" /><br />
                    Upload Image
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/*"
                    onChange={(e) => {

                      if (e.currentTarget.files[0].size >= 204800) {
                        alert("Images size must be less than 200KB")
                        return 0
                      }
                      // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                      setIsFile(true);
                      // setUrlFile(e.currentTarget.files[0]);

                      let img = new Image()
                      img.src = URL.createObjectURL(e.currentTarget.files[0])
                      img.onload = () => {
                        if (img.width === 400 && img.height === 400) {
                          setUrlFile(e.target.files[0]);
                          setfile(img.src);
                          setIsFile(true);
                        } else {
                          alert('Incorrect image dimension. Please upload an image 400 X 400 pixels.')
                        }
                      }
                    }}
                  />
                </div> :
                <>

                  <aside className="image">

                    <div className="overlay">
                      <label htmlFor="file-upload">
                        <img htmlFor="upload-image" className="edit-img" src={editImageIcon} alt="" />
                      </label>
                    </div> 
                    <img className="screen" src={file} alt="" />

                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: 'none' }}
                      name="myfile"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                        }
                        // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                        setIsFile(true);
                        // setUrlFile(e.currentTarget.files[0]);

                        let img = new Image()
                        img.src = URL.createObjectURL(e.currentTarget.files[0])
                        img.onload = () => {
                          if (img.width === 400 && img.height === 400) {
                            setUrlFile(e.target.files[0]);
                            setfile(img.src);
                            setIsFile(true);
                          } else {
                            alert('Incorrect image dimension. Please upload an image  400 X 400 pixels.')
                          }
                        }
                      }}
                    // required
                    />
                  </aside>
                </>
              }
            </section>
            <div className='mt-3'>
              <p className="label-form">SEO Text </p>
              <Editor
                  onInit={(evt, editor) => { editorRef.current = editor }}
                  // initialValue={seoText}
                  value={seoText}
                  onEditorChange={(newText) => {
                        setSEOText(newText)
                        // setSEOText({ ...seoText, newText })
                  }}
                  init={{
                      height: 450,
                      menubar: true,
                      plugins: [
                          'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                          'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                          'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
              />
          </div>
          </div>
          <div className="button">
            <button>Update</button>
          </div>
        </form>
      }
    </>
  )
}

export default EditCollectionForm