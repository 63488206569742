import React, { useState, useEffect } from 'react';
import "../../style/bootstrap.min.css"
import axiosauth from '../../axios-config-auth';
import DatePicker from "react-datepicker";
import DatePickerInput from '../../components/halper/DatePickerInput';
import DownloadExcelModal from '../../components/halper/DownloadExcelModal';
import LoaderModal from '../../components/halper/LoaderModal';
import { useHistory, Link } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import searcha from '../../assets/icons/search_icon.png'
import Pagination from "react-js-pagination";
import Select from 'react-select'
import Loader from 'react-loader-spinner';
import NoData from '../../components/halper/NoData';
import ConfirmationModal from '../../components/halper/ConfirmationModal';
import { handleToast } from '../../utils/customFunction';

const OrderList = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [showLoader1, setShowLoader1] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [orders, setOrders] = useState([]);
  const [total, settotal] = useState(0);
  const [pageStatus, setPageStatus] = useState(null)
  const [excelLink, setExcelLink] = useState(null);
  const [isUnshipped, setIsUnshipped] = useState({
    unShipped:true,
    isCancelled:false,
  });
  const [isMoveTo,setIsMoveTo] = useState('')
  const [selectOrders,setSelectOrders] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [move,setMove] = useState(false);

  const { errorMsg,successMsg } = handleToast();
  
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get('page');
  const statusData = [
    { "value": "isDelivered", "label": "Delivered" },
    { "value": "isCancelled", "label": "Cancelled" },
    { "value": "isShipped", "label": "Shipped" },
    { "value": "isShipped", "label": "In Progress" }
  ]

  const handlePageChange = (pageNumber) => {

    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
    let filter = pageStatus
    if (filter === null) {
      filter = {}
    }
    if (excelDateStart !== null && startDate !== null) {
      var myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);

      filter.date = {
        "startDate": formatDate(excelDateStart),
        "endDate": formatDate(myDate)

      }
    }
    else {
    }

    const newFilter = {...managePayload(),...filter}
    let obj = {
      "fields": "max",
      "limit": 30,
      // "limit": 10,
      "count": (pageNumber - 1) * 10,

      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
      "filters": (newFilter)
      // "filters": (filter)
    }

    axiosauth.post('/order/load-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setOrders(res.data.orders)
          settotal(res.data.total);
          setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const handlePageChange5 = (pageNumber) => {
    handlePageChange(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  const managePayload = () =>{
    if(isUnshipped.unShipped){
      return {
        "isShipped":false,
        "isDelivered":false,
        "isCancelled":false
      }
    }
    if(isUnshipped.isCancelled){
      return { "isCancelled":true }
    }
    return {"isShipped":true,"isCancelled":false}
  }

  const loadOrder = () => {
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    }
    let filter = pageStatus
    if (filter === null) {
      filter = {}
    }
    if (excelDateStart !== null && startDate !== null) {
      var myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);

      filter.date = {
        "startDate": formatDate(excelDateStart),
        "endDate": formatDate(myDate)
      }
    }
    else {
    }

    const newFilter = {...managePayload(),...filter}
    // const newFilter = {isShipped:!isUnshipped.unShipped,...filter}
    let obj = {
      "fields": "max",
      "limit": 30,
      // "limit": 10,
      "count": (pageCount - 1) * 10,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
      "filters": (newFilter)
      // "filters": (filter)
    }

    axiosauth.post('/order/load-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setOrders(res.data.orders)
          settotal(res.data.total);
          setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    loadOrder();
  }, [isUnshipped])

  const showDate = (data) => {
    var d = new Date(data);
    var date = d.getUTCDate();
    // var date = d.getDate();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
    var year = d.getFullYear();
    var dateStr = date + " " + months[month] + " " + year;
    return dateStr;
  };

  const searching = (search) => {
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    }
    let filter = pageStatus
    if (filter === null) {
      filter = {}
    }
    filter.orderId = search
    if (excelDateStart !== null && startDate !== null) {

      var myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);
      filter.date = {
        "startDate": formatDate(excelDateStart),
        "endDate": formatDate(myDate)

      }
    }
    else {
    }
    const newFilter = {...managePayload(),...filter}
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    let obj = {
      "fields": "max",
      "limit": 30,
      // "limit": 10,
      "count": (pageCount - 1) * 10,
      "filters": newFilter,
      // "filters": filter,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }
    }
    axiosauth.post('/order/load-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setOrders(res.data.orders)
          settotal(res.data.total);
          // setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const handleSelect = (selectedOption) => { 
    setPageStatus({ [selectedOption.value]: (selectedOption.label === "In Progress" ? false : true) })
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    }
    let deliveryFilter = {

    }
    if (excelDateStart !== null && startDate !== null) {
      var myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);
      deliveryFilter = {
        [selectedOption.value]: (selectedOption.label === "In Progress" ? false : true),

        "date": {
          "startDate": formatDate(excelDateStart),
          "endDate": formatDate(myDate)
        }
      }
    }
    else {
      deliveryFilter = {
        [selectedOption.value]: (selectedOption.label === "In Progress" ? false : true)
      }
    }
    const newFilter = {...managePayload(),...deliveryFilter}
    let obj = {
      "fields": "max",
      "limit": 30,
      // "limit": 10,
      "count": (pageCount - 1) * 10,
      "filters": newFilter,
      // "filters": deliveryFilter,
      // "filters":{[selectedOption.value]:(selectedOption.label === "Delivered" ? "true" :selectedOption.label === "Cancelled" ? "true" :selectedOption.label === "Shipped"?"true":selectedOption.label === "In Progress"?"false":"false"),},
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }
    }
    setShowLoader(true);
    axiosauth.post('/order/load-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data) 
        if (res.success === true) {
          setOrders(res.data.orders)
          settotal(res.data.total);
          setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  const [excelDateStartError, setExcelDateStartError] = useState("")
  const [startDateError, setstartDateError] = useState("")
  const [excelDateStart, setExcelDateStart] = useState(null);

  const excelDateSearch = (date) => {
    if (excelDateStart === null) {
      setExcelDateStartError("Select Start Date")
      return 0
    }
    if (date === null) {
      setstartDateError("Select End Date")
      return 0
    }
    if (date < excelDateStart) {
      alert("End Date Must Be Greater Than Start Date")
      return 0
    }
    setStartDate(date)
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    }

    var myDate = new Date(date);
    myDate.setDate(date.getDate() + 1);
    let filter = pageStatus
    if (filter === null) {
      filter = {}
    }
    if (excelDateStart !== null && date !== null) {
      filter.date = {
        "startDate": formatDate(excelDateStart),
        "endDate": formatDate(myDate)
      }
    }
    else {
    }
    const newFilter = {...managePayload(),...filter}
    let obj = {
      "fields": "max",
      "limit": 30,
      // "limit": 10,
      "count": (pageCount - 1) * 10,
      "filters":newFilter,
      // "filters":filter,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }
    }
    axiosauth.post('/order/load-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data) 
        if (res.success === true) {
          setExcelDateStartError("")
          setstartDateError("")
          setOrders(res.data.orders)
          settotal(res.data.total);
          setShowLoader(false);
        } else {
          setExcelDateStartError("")
          setstartDateError("")
          setOrders([])
          settotal(0);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const excelDateSearch1 = (date) => {
    if (date === null) {
      setExcelDateStartError("Select Start Date")
      return 0
    }
    if (startDate === null) {
      setstartDateError("Select End Date")
      return 0
    }
    if (startDate < date) {
      alert("End Date Must Be Greater Than Start Date")
      return 0
    }
    setExcelDateStart(date)
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    }
 
    var myDate = new Date(startDate);
    myDate.setDate(startDate.getDate() + 1);

    let filter = pageStatus
    if (filter === null) {
      filter = {}
    }
    if (excelDateStart !== null && date !== null) {
      filter.date = {
        "startDate": formatDate(date),
        "endDate": formatDate(myDate)

      }
    }
    else {
    }
    const newFilter = {...managePayload(),...filter}
    let obj = {
      "fields": "max",
      "limit": 30,
      // "limit": 10,
      "count": (pageCount - 1) * 10,
      "filters": newFilter,
      // "filters": filter,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }
    }
    axiosauth.post('/order/load-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data) 
        if (res.success === true) {
          setExcelDateStartError("")
          setstartDateError("")
          setOrders(res.data.orders)
          settotal(res.data.total);
          setShowLoader(false);
        } else {
          setExcelDateStartError("")
          setstartDateError("")
          setOrders([])
          settotal(0);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const downloadExcel = () => {
    if (excelDateStart === null) {
      setExcelDateStartError("Select Start Date")
      return 0
    }
    if (startDate === null) {
      setstartDateError("Select End Date")
      return 0
    }
    if (startDate < excelDateStart) {
      alert("End Date Must Be Greater Than Start Date")
      return 0
    }
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    }

    var myDate = new Date(startDate);
    myDate.setDate(startDate.getDate() + 1);
    let filter = pageStatus
    if (filter === null) {
      filter = {}
    }
    if (excelDateStart !== null && startDate !== null) {
      filter.date = {
        "startDate": formatDate(excelDateStart),
        "endDate": formatDate(myDate)
      }
    }
    else {
    }
    let obj = {
      "count": 0,
      "limit": 10000,
      "filters": filter,
    }
    setShowLoader1(true);
    axiosauth.post('/order/generate-order-xlsx', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setstartDateError("")
          setExcelDateStartError("") 
          setShowLoader1(false);
          setExcelLink(`https://backend.toyvala.com/${res.data}`)

        } else {

        }
      })
      .catch((error) => {
        console.log(error);

      })
  }

  const HandleClose = () => {
    if(move){
      setMove(false)
    }else{
      setIsMoveTo('')
    }
  }

  const moveProduct = () => {
    let obj={}
    if(move){
      obj={
        "orderIds":selectOrders
      }
    }else{
      obj={
        "orderIds":[isMoveTo]
      }
    }

    setShowLoader(true);
    axiosauth.post('/order/ship-orders', obj)
    .then(async (response) => {
      let res = JSON.parse(response.data)
      if (res.success === true) {
        loadOrder()
        setShowLoader(false);
        successMsg(res?.message)
        if(move){
          setIsAllSelected(false)
          setMove(false)
          setSelectOrders([])
        } 
        else {
          setIsMoveTo('')
        }
        
      } else {
        setShowLoader(false);
        errorMsg(JSON.parse(response?.data)?.message)
      }
    })
    .catch((error) => {
      console.log(error);
      errorMsg(error)
    })
  }

  const handleCheckBox = (e) => {
    if(!isAllSelected){
      const { value } = e.target;
      const selectOrdersClone = [...selectOrders];
      const data = selectOrdersClone.findIndex((item) => item === value);
  
      if (data === -1) selectOrdersClone.push(value);
      else selectOrdersClone.splice(data, 1);
      setSelectOrders(selectOrdersClone);
    }
  }

  useEffect(() => {
    if(isAllSelected){
      let ls=[]
      orders.forEach(item=>{
        ls.push(item?._id)
      })
      setSelectOrders(ls)
    }else{
      setSelectOrders([])
    }
  },[isAllSelected])

  const manageAllList = (itemData) => {
    const data = selectOrders.find((item) => item === itemData);
    if (data) return true;
    return false;
  };

  const handleRoute = () => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  }

  return (
    <>
      {showLoader1 === true ? <LoaderModal /> : null}
      {excelLink !== null ?
        <DownloadExcelModal>
          <span className="excel-title">Download Order List</span><br />
          <hr />
          <a href={excelLink} onClick={() => setExcelLink(null)} target="_blank" rel="noopener noreferrer" className="exportLink">Download</a>
        </DownloadExcelModal>
        : null}

      <div className="row">
        <div className="col-9">
          <div className="search-box">
            <img src={searcha} alt="search-icon" />
            <input type="text"
              placeholder={"Search By Order Id"}
              onChange={(e) => searching(e.target.value)}
            />
          </div>
        </div>

        <div className="col-3">
          <div className="statusSelect">
            <Select
              placeholder={'Status'}
              closeMenuOnSelect={true}
              options={statusData}
              onChange={handleSelect}
            />
          </div>
        </div>

      </div>
      <div className="row">
        <div className="col-md-6 col-sm-3"></div>
        <div className="col-md-2 col-sm-3">
          <div className="p-1 datePicorderExcel">
            <DatePicker
              selected={excelDateStart}
              onChange={date => {
                setExcelDateStart(date)
                excelDateSearch1()
              }}
              customInput={<DatePickerInput place={"Select Start Date"} value={excelDateStart} isRound={true} />}
              peekNextMonth
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />

          </div>
          <p style={{ color: "red" }}>{excelDateStartError}</p>
        </div>

        <div className="col-md-2 col-sm-3">
          <div className="p-1 datePicorderExcel">
            <DatePicker
              selected={startDate}
              onChange={date => excelDateSearch(date)}
              customInput={<DatePickerInput place={"Select End Date"} value={startDate} isRound={true} />}
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />

          </div>
          <p style={{ color: "red" }}>{startDateError}</p>

        </div>

        <div className="col-md-2 col-sm-3">
          <div className={"button2"}>
            <button onClick={downloadExcel}>Export Excel</button>
          </div>
        </div>

      </div>

      <div>
        <ul class="nav">
          <li class="nav-item">
            <button
              class={`nav-link manage-order-btn mr-2 ${isUnshipped.unShipped ? 'active':''}`}
              onClick={()=>{
                handleRoute()
                setIsUnshipped({...isUnshipped,unShipped:true,isCancelled:false})
              }}>
              UnShipped Orders
            </button>
          </li>
          <li class="nav-item">
            <button
              class={`nav-link manage-order-btn mr-2 ${!isUnshipped.unShipped && !isUnshipped.isCancelled ? 'active':''}`}
              onClick={()=>{
                handleRoute()
                setIsUnshipped({...isUnshipped,unShipped:false,isCancelled:false})
              }}>
              Sent to Ship
            </button>
          </li>
          <li class="nav-item">
            <button
              class={`nav-link manage-order-btn ${isUnshipped.isCancelled ? 'active':''}`}
              onClick={()=>{
                handleRoute()
                setIsUnshipped({...isUnshipped,unShipped:false,isCancelled:true})
              }}>
              Cancelled
            </button>
          </li>
        </ul>
      </div>

      <div className='mt-2'>
        {selectOrders.length ? (
          <button class="btn btn-success" onClick={()=>setMove(true)}>{isAllSelected ? 'Move All' :'Move Selected'}</button>
        ) : null}
      </div>

      {!showLoader ?
        <>
          {orders && orders.length > 0 ?
            <>
              <div className="table-wrap overflow-auto table-responsive card py-1 " style={{ height: "500px" }}>

                <table className="w-100">
                  <thead>
                    <tr>
                      {isUnshipped.unShipped ? <th className="text-nowrap px-2"><input type="checkbox" onChange={()=>setIsAllSelected(!isAllSelected)} /> Select All</th>:''}
                      <th className="text-nowrap px-2">Sr. No.</th>
                      <th className="text-nowrap px-2">Order Id</th>
                      <th className="text-nowrap px-2">Payment Type</th>
                      <th className="text-nowrap px-2">Order Total</th>
                      <th className="text-nowrap px-2">Customer Name</th>
                      <th className="text-nowrap px-2">Mobile No.</th>
                      <th className="text-nowrap px-2">Order Date</th>
                      <th className="text-nowrap px-2">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, index) => {
                      return (
                        <>
                          <tr>
                            {isUnshipped.unShipped ? <td><input type="checkbox" value={order?._id} onChange={handleCheckBox} checked={manageAllList(order?._id)}/></td>:''}
                            <td>{((pageCount ? pageCount : 1) - 1) * 30 + (index + 1)}</td>
                            <td className="text-nowrap px-2">
                              <Link to={`/order-details/${order.orderId}`}>
                                {order.orderId}
                              </Link>
                            </td>
                            <td className="text-nowrap px-2">{order?.paymentType}</td>
                            <td className="text-nowrap px-2"><span>&#8377;</span>{order.total.toFixed(2)}</td>
                            <td className="text-nowrap px-2">{order.address[0]?.firstName}</td>
                            <td className="text-nowrap px-2">{order.address[0]?.mobile}</td>
                            <td className="text-nowrap px-2">{showDate(order.createdOn)}</td>
                            <td className="text-nowrap px-2">
                              {isUnshipped.unShipped ? (
                                <button className='btn btn-success' onClick={()=>setIsMoveTo(order?._id)} disabled={selectOrders.length}>Move to Ship</button>
                              ) : (
                                <>
                                {order.isCancelled === true ?
                                  <span className="isCancelled">Cancelled</span>
                                  :
                                  (order.isDelivered === true ?
                                      <span className="isDelivered">Delivered</span>
                                      :
                                      (order.isShipped === true ?
                                          <span className="isShipped">Shipped</span>
                                          :
                                          <span className="isProgress">In Progress</span>
                                      )
                                  )
                                }
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      )
                    }
                    )}
                  </tbody>
                </table>
              </div>
            </>
            :
            <NoData message="Data Not Found" />
          }
        </>
        :
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div>
      }
      <div className="pagination-custom">
        <Pagination
          hideDisabled
          activePage={parseInt(pageCount)}
          itemsCountPerPage={30}
          totalItemsCount={total}
          pageRangeDisplayed={5}
          hideNavigation
          onChange={handlePageChange5}
        />
      </div>

      {isMoveTo || move ? (
        <ConfirmationModal
        title=''
        content='Are you sure, You want to ship this order now?'
        HandleClose={HandleClose}
        performAction={moveProduct}
      />
      ) : null}
    </>
  )
}

export default OrderList