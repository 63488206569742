import React, { useState, useEffect } from 'react';
import Layout from '../../layout/layout';
import axiosauth from '../../axios-config-auth';
import { useHistory, useParams, Link, Redirect } from 'react-router-dom';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import Loader from 'react-loader-spinner';
import SuccessModal from '../../components/halper/SuccessModal';

const OrderReturnDetails = () => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(true);
  const { orderId } = useParams();
  const [statusChange , setStatuschange] = useState(); 
  const[responseMessage , setResponseMessage] = useState("")
  const[updateSuccess , setUpdateSuccess] = useState(false);
  const[redirect , setredirect] = useState(false);


  const [product, setproduct] = useState({
    brandname: '',
    name: '',
    category: "",
    subCategory: "",
    paymentType: "",
    total: "",
    returnQuestion: "",
    returnComment: "",
    exchangeForDiffrent: false,
    refundFor: false,
    productId: "",
    order_id: "",
    adminExchangeConfirmm: false,
    adminReturnConfirmm: false,
    cancelledByAdmin: false,
  });

  const { brandname, name, category, subCategory, paymentType, total, returnQuestion,
    returnComment, exchangeForDiffrent, refundFor, productId, order_id, adminExchangeConfirmm, adminReturnConfirmm,cancelledByAdmin } = product;

  useEffect(() => {
    axiosauth.get(`/order/return-order/${orderId}`)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {

          setShowLoader(false);
          setproduct({
            brandname: res.data.product?.brand?.name,
            name: res.data.product.catalogueName,
            category: res.data.product.category.category.slug,
            subCategory: res.data.product.category.sub1.slug,
            paymentType: res.data.order.paymentType,
            total: res.data.order.total,
            returnQuestion: res.data.returnAnswer,
            returnComment: res.data.returnComment,
            exchangeForDiffrent: res.data.exchangeForDiffrent,
            refundFor: res.data.refundFor,

            adminReturnConfirmm: res.data.adminReturnConfirm,
            adminExchangeConfirmm: res.data.adminExchangeConfirm,
            cancelledByAdmin:res?.data?.cancelledByAdmin,
            productId: res.data.product._id,
            order_id: res.data.order._id,
          })
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, []); 

  const handleSubmit = () => {
    let obj = {
      order: order_id,
      product: productId,
      ...(exchangeForDiffrent && statusChange==="true" && { adminExchangeConfirm:  true   }),
      ...(refundFor && statusChange==="true" && { adminReturnConfirm:  true   }), 
      ...(statusChange==="false" && {cancelledByAdmin : true})

    }
 
    axiosauth.post('/order/order-return-approve', obj).then((response) => {
      const res = JSON.parse(response.data); 

      if (res.success) {
        setUpdateSuccess(true)
        setResponseMessage(res.message) 
      }
      // if (res.success===false) {
      //   setUpdateSuccess(true)
      //   setResponseMessage(res.message) 
      // }

    }).catch((error) => {
      console.log("Error is --->>", error)
    })
  }

  // console.log("product===>", product)

  const closeModal = () => {
    setredirect(true);

  }

  return (
    <Layout>

{updateSuccess === true ? <SuccessModal message={responseMessage} onClick={closeModal} /> : null}
      {redirect ? <Redirect to="/orders-return?page=1" /> : null}

      {!showLoader ?

        <div style={{ marginBotton: '30px' }}>
          <section className="detail-heading">
            <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
              <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" />
              <h2>Return / Exchange Product Details : </h2>
            </div>
          </section>


          <section className="detail-body" >
            <div>Brand Name :</div>
            <div className="titleStyle">{brandname}</div>
          </section>
          <section className="detail-body" >
            <div>Product Name :</div>
            <div className="titleStyle">{name}</div>
          </section>

          <section className="detail-body" >
            <div>Categories :</div>
            <div className="titleStyle">{category}</div>
          </section>

          <section className="detail-body" >
            <div>Sub-Categories :</div>
            <div className="titleStyle">{subCategory}</div>
          </section>

          <section className="detail-body" >
            <div>paymentType :</div>
            {paymentType && paymentType.length > 0 &&
              <div className="titleStyle">{paymentType}</div>}
          </section>

          <section className="detail-body" >
            <div>Total Price :</div>
            {total && total > 0 &&
              <div className="titleStyle">{total}</div>}
          </section>
          <section className="detail-body" >
            <div>Return Reason :</div>
            {returnQuestion && returnQuestion.length > 0 &&
              <div className="titleStyle">{returnQuestion}</div>}
          </section>

          {/* <section className="detail-body" >
            <div>Images:</div>
            <div className="titleStyle">{
              imageUrls && imageUrls.length > 0 && (
                imageUrls.map((imgurl, index) => {
                  return (
                    <>
                      <img key={index} src={imgurl.url} alt="" style={{ height: '150px', width: '120px', padding: '5px' }} />
                    </>
                  )
                })
              )
            }</div>
          </section>  */}
          <section className="detail-body" >
            <div>Return Comment  :</div>
            {returnComment && returnComment.length > 0 &&
              <div className="titleStyle">{returnComment}</div>}
          </section>
          <section className="detail-body" >
            <div>Approved By Admin :</div>
            <div className="titleStyle">{adminReturnConfirmm || adminExchangeConfirmm ? 'Approved' : cancelledByAdmin ? 'Rejected' :'Requested'}</div>
            {/* <div className="titleStyle">{refundFor && adminReturnConfirmm ? "Return Confirm" : "Peding"} {exchangeForDiffrent && adminExchangeConfirmm && "Exchange  Confirm"}</div> */}
          </section>
          <section className="detail-body" >
            <div>Request For :</div>

            <div className="titleStyle">{refundFor && "Refund"} {exchangeForDiffrent && "Exchange"}</div>
          </section>
          <section className="detail-body" >
            <div>Accept / Reject :</div>

            {refundFor ?
              <select className=''
                onChange={(e) => setStatuschange(e.target.value)}
              >
                <option selected disabled>Select Refund</option>
                <option value={true}>Accept</option>
                <option value={false}>Reject</option>
              </select>
              :
              <select className=''
                onChange={(e) => setStatuschange(e.target.value)}
              >
                <option selected disabled>Select Exchange</option>
                <option value={true}>Accept</option>
                <option value={false}>Reject</option>
              </select>
            }

          </section>







          {/* <section className="detail-body" >
            <div>Search tags :</div>
            <div className="titleStyle">{searchTags}</div>
          </section>

          <section className="detail-body" >
            <div>Images:</div>
            <div className="titleStyle">{
              imageUrls && imageUrls.length > 0 && (
                imageUrls.map((imgurl, index) => {
                  return (
                    <>
                      <img key={index} src={imgurl.url} alt="" style={{ height: '150px', width: '120px', padding: '5px' }} />
                    </>
                  )
                })
              )
            }</div>
          </section> */}


          <div className="button">

            <button onClick={() => history.goBack()}>Back</button> &nbsp;


            <button onClick={handleSubmit}>Submit</button>

          </div>

        </div>
        :
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div>
      }

      <br />

    </Layout>
  );
};

export default OrderReturnDetails;
