import React, { useState, useEffect } from 'react';
import { updateVariantName, fetchVariantById } from '../../redux/actions/variantAction';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useParams, useHistory } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';
import left_arrow2 from '../../assets/icons/down_arrow2.png'

const EditVariantNameForm = () => {


  const dispatch = useDispatch();
  const varData = useSelector(state => state.variantData);
  // console.log("varData", varData.data)
  const { variantId } = useParams();
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);
  const [variant, setVariant] = useState({
    name: ''
  })

  const handleChange = (e) => {
    setVariant({ ...variant, error: false, [e.target.name]: e.target.value })
  }

  const variantSubmit = (e) => {
    e.preventDefault();
    let obj = {
      "variantId": variantId,
      "variantName": variant.name,
    }
    dispatch(updateVariantName(obj));
  }

  useEffect(() => {
    if (typeof varData === "object") {
      setVariant(
        {
          name: varData.data.variantName
        }
      )
    }
  }, [varData])




  useEffect(() => {

    dispatch(fetchVariantById({ variantId: variantId }));
  }, [dispatch, variantId]);

  const closeModal = () => {
    setRedirect(true);
  }


  return (
    <>

<br/>
<br/>
      {/* <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img onClick={() => history.goBack()} src={left_arrow2}  alt="left-arrow" /> &nbsp;
              <h2>Edit Variant Name</h2>
        </div> */}
         <div className='d-flex justify-content-between'>
                    <div className="left_arrow2 back-arrow align-self-center mb-0" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                        <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                        <h2>Edit Variant Name</h2>

                    </div>
                    <div>
                        <div className='d-flex '>
                            <div className="button ml-0">
                                <button onClick={() => history.goBack()}>Back</button>
                            </div>
                            <div className="button ml-2">
                                <button onClick={() => window.location.reload()}>Refresh</button>
                            </div>
                        </div>
                    </div>
                </div>

      <form onSubmit={variantSubmit} className="form-wrap">
        {/* <div className="form-header">
          <h4>Edit Variant Name</h4>
        </div> */}
        <div className="form-body">
          <div className="single-field" >
            <p>Variant Name <b>*</b></p>
            <input type="text"
              placeholder="Variant Name"
              name="name"
              value={variant.name}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="button">
          <button>Update</button>
        </div>
      </form>
      {redirect ? <Redirect to="/variant-manage" /> : null}
      {varData.data.message === "success" ? <SuccessModal message="Variant Name Updated !" onClick={closeModal} /> : null}
    </>
  )
}

export default EditVariantNameForm