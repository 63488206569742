import {
  BANNER_LOAD,
  BANNER_UPLOAD,
  BANNER_DELETE,
  BANNER_UPDATE,
  BANNER_FAIL
} from '../constants/types';

const initialState = {
  loading: false,
  data: [],
  error: ''
}

export function bannerData(state = initialState, action) {
  switch (action.type) {
    case BANNER_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case BANNER_UPLOAD:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case BANNER_DELETE:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case BANNER_UPDATE:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case BANNER_FAIL:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}