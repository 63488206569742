import React from 'react';
import ItemDetailView from './ItemDetailView';

const ItemDetails = ({collection,total, orderId, loadProductById, payment , billaddress , shipaddress,createdBy}) => {
    return (
        <div>
            <ItemDetailView payment={payment} loadProductById={loadProductById} total={total} orderId={orderId} collections={collection} billaddress={billaddress}  shipaddress={shipaddress} createdBy={createdBy}/>
        </div>
    )
}

export default ItemDetails;
