import React, { useEffect } from 'react';
import Layout from "../../layout/layout";
import BannerView from '../../wrapper/banner/BannerView';
import { useDispatch } from 'react-redux';
import { fetchBanners } from '../../redux/actions/bannerAction';


const Banner = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchBanners())
    })
    return (
        <Layout>
            <BannerView />
            {/* <BannerView /> */}
            {/* <BannerView /> */}
        </Layout>
    )
}

export default Banner;
