import React from 'react'
import Layout from '../../layout/layout'
import ReviewList from '../../wrapper/review/ReviewList'

const Review = () => {
    return (
        <Layout>
            <ReviewList />
        </Layout>
    )
}

export default Review
