import React, { useState } from "react";
// import SuccessModal from "../../components/halper/SuccessModal";
import {  useHistory } from "react-router-dom";
import axiosauth from "../../axios-config-auth";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import makeAnimated from "react-select/animated";
// import Select from "react-select";

const AddVariantNameForm = () => {
  // const animatedComponents = makeAnimated();
  const [errorMsg, setErrorMsg] = useState("");
  // const [variantvalue, setVariantVale] = useState("");
  const [success, setSuccess] = useState(false);
  const [variants, setVariants] = useState([]);
  const [sendVarinat, setSendVariant] = useState([]);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);
  const [variant, setVariant] = useState({
    name: "",
  });

  const handleChange = (e) => {
    setVariant({ ...variant, error: false, [e.target.name]: e.target.value });
  };

  const variantSubmit = (e) => {
    e.preventDefault();
    let obj = {
      variantName: variant.name,
    };

    axiosauth
      .post("/variants/create-variant/", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        console.log("res:", res);
        if (res.success === true) {
          // console.log("variant")
          setSuccess(true);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeModal = () => {
    // setRedirect(true);
    // setShow(false)
    // window.location.reload()
  };
  // ================================================================
  const getAllVariant = (search) => {
    let obj = {
      filters: {
        variantName: search,
      },
    };
    axiosauth
      .post("/variants/findAllVariants/", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          if (res.success === true) {
            if (res.data) {
              let allVarList = [];
              for (var k = 0; k < res.data.length; k++) {
                allVarList.push({
                  value: res.data[k].parent._id,
                  label: res.data[k].parent.variantName,
                });
              }
              setVariants(allVarList);
            }
          }
        } else {
          console.log("else block");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const [showButton ,setShowButton] = useState(true)
  // console.log("values======>>>",values)

  const onSubmit = (index, value) => { 
     
    let obj = {
      value: value.value,
      displayName:value.displayName,
      parentVariant: variant.name,
    };
    // console.log("obj", obj);
    axiosauth
      .post("/variants/create-variant-value/", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          alert("Variant Added");
          getAllVariant();
          setShow(true);
        } else {
          setErrorMsg("variant Value Already Exist");
          getAllVariant();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSuccess = () => {
    setShow(false);
    setRedirect(true);
  };

  const handleSelect = (selectedOption) => {
    setSendVariant(selectedOption.label);
  };

  // const [variantForm, setVariantForm] = useState({
  //   variantValue: "",
  //   parentVariant: "",
  // });

  // const handleChange2 = (name) => (event) => {
  //   setVariantForm({
  //     // ...variantForm,
  //     error: false,
  //     [name]: event.target.value,
  //   });
  // };
  //   const handleInputChange=(e)=>{
  //     getAllVariant(e);
  //  }
  const [specs, setspecs] = useState([
    {
      // "name": "",
      value: "",
      displayName:""
    },
  ]);

  const handleChange2 = (e, name, index) => {
    const { value } = e.target;
    const list = [...specs];
    list[index][name] = value;
    setspecs(list);
    // setSpeNameError("")
    // setSpeValueError("")
  };

  console.log(specs);

  const removespecs = (index) => {
    const temp = [...specs];
    const filter = temp.filter((item, i) => i !== index);
    setspecs(filter);
  };

  const addspecs = () => {
    // setShowButton(true)
    setspecs([...specs, { value: "" }]);
  };

  return (
    <>
      <br />
      <br />

      <div className="d-flex justify-content-between">
        <div
          className="left_arrow2 back-arrow align-self-center mb-0"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Add Variant Name</h2>
        </div>
        <div>
          <div className="d-flex ">
            <div className="button ml-0">
              <button onClick={() => history.goBack()}>Back</button>
            </div>
            <div className="button ml-2">
              <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={variantSubmit} className="form-wrap">
        <div className="form-header">
          <h4>Add Variant Name</h4>
        </div>
        <div className="form-body">
          <div className="single-field">
            <p>
              Variant Name <b>*</b>
            </p>
            <input
              type="text"
              placeholder="Variant Name"
              name="name"
              onChange={handleChange}
              readOnly={success === true}
            />
          </div>
        </div>
        {success === false ? (
          <div className="button">
            <button>Add</button>
          </div>
        ) : null}
      </form>
      {/* {redirect ? <Redirect to="/variant-name-manage" /> : null} */}

      {/* {success ? <SuccessModal message="New Variant Created !" onClick={closeModal} /> : null} */}

      {/* ============================= */}

      {success === true && (
        <>
          {/* // <form  style={{ marginTop: "50px" }}> */}
          <div className="container mx-3">
            <h4>Add Variant Value</h4>
          </div>

          <div className="form-wrap">
            {/* <input type="text" onChange={(e)=>setVariantVale(e.target.value)} /> */}
            <>
              {specs && specs.length > 0
                ? specs.map((value, index) => {
                    return (
                      <>
                        <section>
                          <div className="two-field d-flex align-center">
                            <input
                              placeholder="Variant Value"
                              value={value.value}
                              className={"form-control form-control-nonround"}
                              mandatory={true}
                              onChange={(e) => handleChange2(e, "value", index)}
                            />
                            <input
                              placeholder="Display Name"
                              value={value.displayName}
                              className={"form-control form-control-nonround"}
                              mandatory={true}
                              onChange={(e) => handleChange2(e, "displayName", index)}
                            />
                            <span style={{ padding: "10px" }}>
                              {specs.length > 1 && (
                                <button
                                  className="cross_button"
                                  onClick={() => removespecs(index)}
                                >
                                  {" "}
                                  X
                                </button>
                              )}
                            </span>
                            {/* {showButton === true && */}
                            <div
                              className="button "
                              style={{ marginTop: "0", marginLeft: "10px" }}
                            >
                              <button onClick={() => onSubmit(index, value)}>
                                save
                              </button>
                            </div>
                            {/* } */}
                          </div>
                        </section>
                      </>
                    );
                  })
                : null}

              {specs.length < 10 ? (
                <div className="button-variant">
                  <button onClick={() => addspecs()}>+ADD</button>
                </div>
              ) : null}
            </>

             
            <span style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
              {errorMsg}
            </span>
          </div>

          {/* </form> */}
        </>
      )}
    </>
  );
};

export default AddVariantNameForm;
