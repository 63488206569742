import {
    FETCH_VARIANT,
    USER_LOADING,
    USER_FAIL,
    CREATE_VARIANT,
    FETCH_VARIANT_BY_ID,
} from '../constants/types';

import axiosauth from '../../axios-config-auth';

export function loadingVariant() {
    return {
        type: USER_LOADING,
    };
}

export function loadVariant(user) {
    return {
        type: FETCH_VARIANT,
        payload: user
    };
}
export function loadVariantById(user) {
    return {
        type: FETCH_VARIANT_BY_ID,
        payload: user
    };
}

export function addVariant(user) {
    return {
        type: CREATE_VARIANT,
        payload: user
    };
}

export function variantFail(user) {
    return {
        type: USER_FAIL,
        payload: user
    };
}


export const fetchVariant = () => {
    return async dispatch => {
        dispatch(loadingVariant());
        let obj={
            "count":0,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            }
        }

        axiosauth.post('/variants/findAllVariants/',obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("variantAction:",res)
                if (res.success === true) {
                    dispatch(loadVariant(res));
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch((error) => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};


export const fetchVariantSearch = (search) => {
    return async dispatch => {
        dispatch(loadingVariant());
        let obj={
            "count":0,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            },
            "filters": {
                "variantName": search
                }
        }

        axiosauth.post('/variants/findAllVariants/',obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("variantAction:",res)
                if (res.success === true) {
                    dispatch(loadVariant(res));
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch((error) => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};



export const fetchVariantPage = (pageNumber) => {
    return async dispatch => {
        dispatch(loadingVariant());
        let obj={
            "count":(pageNumber - 1) * 10,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            }
        }

        axiosauth.post('/variants/findAllVariants/',obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(loadVariant(res));
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch((error) => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};



export const createVariant = (obj) => {
    return async dispatch => {
        dispatch(loadingVariant());

        axiosauth.post('/variants/create-variant/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("res:", res)
                if (res.success === true) {
                    // console.log("variant")
                    dispatch(addVariant(res));
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch((error) => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};

export const fetchVariantById = (obj) => {
    return async dispatch => {
        dispatch(loadingVariant());
        axiosauth.post('/variants/find-variantby-id/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(loadVariantById(res.variant.parent));
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch(() => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};


export const fetchVariantValueById = (obj) => {
    return async dispatch => {
        dispatch(loadingVariant());
        axiosauth.post('/variants/find-variantby-id/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(loadVariantById(res.variant.values));
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};




export const updateVariantName = (obj) => {
    return async dispatch => {
        dispatch(loadingVariant());

        axiosauth.post('/variants/update-variant/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(addVariant(res));
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch(() => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};

export const updateVariantValue = (obj) => {
    return async dispatch => {
        dispatch(loadingVariant());

        axiosauth.post('/variants/update-variant-value/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log(res)
                if (res.success === true) {
                    dispatch(addVariant(res));
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch(() => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};


export const deleteVariantNameById = (obj) => {
    return async dispatch => {
        dispatch(loadingVariant());

        axiosauth.post('/variants/remove-variant/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(fetchVariant());
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch(() => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};


export const deleteVariantValueById = (obj) => {
    return async dispatch => {
        dispatch(loadingVariant());

        axiosauth.post('/remove-variant-value', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("res:", res)
                if (res.success === true) {
                    // console.log("variant deletd")
                    dispatch(fetchVariant());
                } else {
                    dispatch(variantFail(res.message));
                }
            })
            .catch(() => {
                // console.log(error);
                dispatch(variantFail("Network Error"));
            })

    };
};


