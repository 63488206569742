import React, { useRef, useState } from 'react'
import Layout from "../../layout/layout";
import uploadIcon from '../../assets/icons/upload.png';
import axiosauth from '../../axios-config-auth';
import { Redirect } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';
import LoaderModal from '../../components/halper/LoaderModal';
// import CustomCropper from '../../components/halper/CustomCropper';
import editImageIcon from '../../assets/icons/edit.png';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { confirmAlert } from 'react-confirm-alert';
import { Editor } from '@tinymce/tinymce-react';

const AddBrand = () => {

    // const [src, selectFile]=useState(null);
    const [result, setResult] = useState(null);
    const [result2, setResult2] = useState(null);
    // const [imageObj, setImageObj] = useState(null);
    // const [imageObj2, setImageObj2] = useState(null);
    const [show, setShow] = useState(false);
    const editorRef = useRef(null);

    const [file, setfile] = useState(null);
    const [file2, setfile2] = useState(null)
    // const [urlFile, setUrlFile] = useState("");
    // const [isFile, setIsFile] = useState(false);
    const [redirect, setRedirect] = useState(false);

    // console.log("filr=>", file2)
    const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [back, setBack] = useState(false)

    // Error State
    const [nameError, setNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [urlError, setUrlError] = useState("");
    const [charCount, setCharCount] = useState(0);
    const [countError, setCountError] = useState(null);
    const [errorMessage,setErreorMessage] = useState()
    const [seoText , setSEOText] = useState('')

    const [brand, setBrand] = useState({
        name: "",
        description: "",
    });

    const handleChange = (e) => {
        setBrand({ ...brand, error: false, [e.target.name]: e.target.value });
        setNameError("");
        setDescriptionError("")
        if (e.target.name === "description") {
            setCharCount(e.target.value.length)
            setCountError("")
        }
    }
    
    const imageUpdate = (brandId) => {
        var formData = new FormData();
        formData.append("brandId", brandId); 
        formData.append("logo", file.length>1 ? result :null);
        formData.append("image", file2.length>1? result2:null) 
        axiosauth.post('/brand/upload-brand-logo', formData)
            .then(async (response) => {

                let res = JSON.parse(response.data)
                if (res.success === true) {
                    // console.log("image res:", res); 
                    setShowLoader(false) 
                    setShow(true) 

                } else {

                }
            })
            .catch((error) => {
                console.log(error);

            })
    }

// console.log("seoText------>>" , seoText)


    const submitBrand = (e) => {
        e.preventDefault();

        if (brand.name === "") {
            setNameError("Brand Name Required");
            return 0;
        }
        if (file === null) {
            setUrlError("Brand Logo Required")
            setNameError("")
            return 0;
        }
        if (file2 === null) {
            setUrlError("Image Required")
            setNameError("")
            return 0;
        }
        if (brand.description === "") {
            setDescriptionError("Brand Description Required");
            setUrlError("")
            return 0;
        }

        if (charCount > 2000) {
            setCountError("Brand description should be <=2000 Character.")
            setDescriptionError("");
            setUrlError("")
            return 0;
        }




        let obj = {
            name: brand.name,
            description: brand.description,
            seoText :seoText,
        }
        // console.log("brand", obj)

        setShowLoader(true)

        axiosauth.post('/brand/create-brand', obj)
            .then(async (response) => {
                // console.log("response", response);
                let res = JSON.parse(response.data)
                if (res.success === true) {


                    localStorage.setItem("brandId", res.data.brandId)
                    // console.log("brand create res:", res)
                    if (result !== null && file2 !== null) {
                        imageUpdate(res.data.brandId);
                    }
                    else {
                        setShow(true);
                    }

                } else {
                    setShowLoader(false) 
                    setErreorMessage("Some Error Occured.....")
                    // setShow(true);
                }
            })
            .catch(() => {
                // console.log(error);
                setNetworkError(true);
            })
    }

    const closeModal = () => {
        setRedirect(true);
    }

    const connectionError = () => {
        setNetworkError(false)
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const backButton = () => {

        if (brand.name !== "") {

            confirmAlert({
                // title: 'Change Status',
                message: 'Are you sure to do this. You will loss your data.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () =>
                            setBack(true)
                    },
                    {
                        label: 'No',
                        onClick: () => console.log("No")
                    }
                ]
            });
        } else {
            setBack(true)
        }

    }

    const onlyAlphaNumericSpaceWithCommaAllow =(e)=> {
        const re = /[a-zA-Z-0-9 ]+/g;
        if (!re.test(e.key)) {
          e.preventDefault();
        }
      }


    return (
        <Layout>



            <form onSubmit={submitBrand} className="form-wrap">
                {/* <div className="form-header"> */}
                {/* <h4>Add Brand</h4> */}

                <div className="left_arrow back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                    <img src={left_arrow2} onClick={backButton} alt="left-arrow" /> &nbsp;
                    <h2>Add Brand</h2>
                </div>
                {/* </div> */}
                <div className="form-body">

                    <div className="single-field">
                        <p>Brand Name <b>*</b></p>
                        <input type="text"
                            id="name"
                            // pattern="^[A-Za-z]+$"
                        //   oninvalid="setCustomValidity('Please enter alphabets only. ')"
                            placeholder="Type Brand Name"
                            name="name"
                            onKeyPress={(e) => onlyAlphaNumericSpaceWithCommaAllow (e)}
                            onChange={handleChange}
                        />
                        <i className="error">{nameError}</i>
                    </div>

                    <br />
                    <p className="label-form"> {`Logo Dimension Must be (281 X 80) , image (jpg, png , WebP) and image size must be less than < 200KB` }<b>*</b></p>
                    <section className="image-uploaded">

                        {file === null && result === null ?
                            <aside className="upload-btn-wrapper">
                                <button className="uplod-btn">
                                    <img src={uploadIcon} alt="" /><br />
                                    {/* Upload Logo */}
                                    <p>Upload Logo
                                        <br />
                                    <span style={{fontSize:"10px"}}>281 X 80</span></p>

                                </button>
                                <input
                                    type="file"
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        setResult(e.target.files[0])
                                        if (e.currentTarget.files[0].size >= 204800) {
                                            alert("Images size must be less than 200KB")
                                            return 0
                                        }
                                        // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                                        setUrlError("")  

                                        let img = new Image()
                                        img.src = URL.createObjectURL(e.currentTarget.files[0])
                                        img.onload = () => {
                                            if (img.width === 281 && img.height === 80) {
                                                setfile(img.src); 
                                            } else {
                                                alert('Incorrect image dimension. Please upload an image 281X80 pixels.')
                                            }
                                        }
                                    }}
                                />

                            </aside> :
                            <aside className="image">
                                <div className="overlay">
                                    <label htmlFor="file-upload">
                                        <img htmlFor="upload-image" className="edit-img" src={editImageIcon} alt="" />
                                    </label>
                                </div>
                                <img className="screen" src={file ? file : null} alt="" />

                                <input
                                    type="file"
                                    id="file-upload"
                                    style={{ display: 'none' }}
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => { 
                                        setResult(e.target.files[0])
                                        // setfile(URL.createObjectURL(e.currentTarget.files[0]));  
                                        if (e.currentTarget.files[0].size >= 204800) {
                                            alert("Images size must be less than 200KB")
                                            return 0
                                        }
                                        let img = new Image()
                                        img.src = URL.createObjectURL(e.currentTarget.files[0])
                                        img.onload = () => {
                                            if (img.width === 281 && img.height === 80) {
                                                setfile(img.src);
                                                // alert("correct image")
                                            } else {
                                                alert('Incorrect image dimension. Please upload an image 281X80 pixels.')
                                            }
                                        }
                                        }

                                    }
                                />
 
                            </aside>
                        }

                    </section>
 
                    <p className="label-form my-5"> {`Image Dimension Must be (281 X 420) , image (jpg, png , WebP) and image size must be less than < 200KB` }<b>*</b></p>
                    <section className="image-uploaded">

                        {file2 === null && result2 === null ?
                            <aside className="upload-btn-wrapper">
                                <button className="uplod-btn">
                                    <img src={uploadIcon} alt="" /><br />
                                    <p>Upload image
                                        <br />
                                    <span style={{fontSize:"10px"}}>281 X 420</span></p>
                                </button>
                                <input
                                    type="file"
                                    name="myfile1"
                                    accept="image/*"
                                    onChange={(e) => {
                                        // console.log("size===>>",e.currentTarget.files[0].size)
                                        if (e.currentTarget.files[0].size >= 204800) {
                                            alert("Images size must be less than 200KB")
                                            return 0
                                        }
                                        setResult2(e.target.files[0])
                                        // setfile2(URL.createObjectURL(e.currentTarget.files[0]));
                                        // setUrlError("") 

                                        let img = new Image()
                                        img.src = URL.createObjectURL(e.currentTarget.files[0])
                                        img.onload = () => {
                                            if (img.width === 281 && img.height === 420) {
                                                setfile2(img.src);
                                                // alert("correct image")
                                            } else {
                                                alert('Incorrect image dimension. Please upload an image 281X420 pixels.')
                                            }
                                        }
                                    }}
                                />

                            </aside> :
                            <aside className="image">
                                <div className="overlay">
                                    <label htmlFor="file-upload2">
                                        <img htmlFor="upload-image" className="edit-img" src={editImageIcon} alt="" />
                                    </label>
                                </div>
                                <img className="screen" src={file2 ? file2 : null} alt="" />

                                <input
                                    type="file"
                                    id="file-upload2"
                                    style={{ display: 'none' }}
                                    name="myfile1"
                                    accept="image/*"
                                    onChange={(e) => {
                                        
                                        if (e.currentTarget.files[0].size >= 204800) {
                                            alert("Images size must be less than 200KB")
                                            return 0
                                        }
                                        // setfile2(URL.createObjectURL(e.currentTarget.files[0])); 
                                        setResult2(e.target.files[0])

                                        let img = new Image()
                                        img.src = URL.createObjectURL(e.currentTarget.files[0])
                                        img.onload = () => {
                                            if (img.width === 281 && img.height === 420) {
                                                setfile2(img.src); 
                                            } else {
                                                alert('Incorrect image dimension. Please upload an image  281X420 pixels.')
                                            }
                                        }
                                    }}
                                
                                /> 
                            </aside>
                        }

                    </section>

                    <i className="error">{urlError}</i>
                    <br />
                    <div id="short-description">
                        <p className="label-form">Brand Description (Maximum-2000 Character, Character Count:<span 
                        // className={charCount <= 500 ? "word-counter" : "word-counter-error"}
                         > {charCount}</span>) <b>*</b></p>
                        <textarea
                            placeholder="Short Description"
                            name="description"
                            onChange={handleChange}
                            // required
                            maxLength={2000}
                            onInput={maxLengthCheck}
                            // onKeyDown={e => /[+\-!@#$%^&*()_+{}:></~]$/.test(e.key) && e.preventDefault()}
                        />
                        <i className="error">{descriptionError}{countError}</i>
                    </div>

                    <div className='mt-3'>
                    <p className="label-form">SEO Text </p>
                    <Editor
                onInit={(evt, editor) => { editorRef.current = editor }}
                initialValue=""
                onEditorChange={(newText) => {
                //   console.log(newText)
                  setSEOText(newText)
                }}
                init={{
                  height: 450,
                  menubar: true,
                  plugins: [
                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
                    </div>

                </div>
                <div className="button">
                    <button>Add</button>
                </div>
                {errorMessage?.length>3 ? <span className='text-danger'> {errorMessage}</span> :null}
            </form>

            {redirect ? <Redirect to="/brand?page=1" /> : null}
            {back ? <Redirect to="/brand?page=1" /> : null}
            {show === true ? <SuccessModal message="New Brand Added!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null}
           
        </Layout>
    )
}

export default AddBrand
