import {
    FETCH_SUB_SUB_CATEGORY,
    FETCH_SUB_SUB_CATEGORY_SUCCESS,
    FETCH_SUB_SUB_CATEGORY_FAILURE,
    CREATE_SUB_SUB_CATEGORY,
    FETCH_SUB_SUB_CATEGORY_BY_ID,
    SEARCH_SUB_SUB_CATEGORY,
    UPDATE_SUB_SUB_CATEGORY
  } from '../constants/types';
  import axiosauth from '../../axios-config-auth';
  
  export function loadingSubSubCategory() {
    return {
      type: FETCH_SUB_SUB_CATEGORY,
    };
  }
  
  export function fetchSubSubCategory(category) {
    return {
      type: FETCH_SUB_SUB_CATEGORY_SUCCESS,
      payload: category
    };
  }
  
  export function fetchSubSubCategoryFail(category) {
    return {
      type: FETCH_SUB_SUB_CATEGORY_FAILURE,
      payload: category
    };
  }
  export function loadCategoryById(category) {
    return {
      type: FETCH_SUB_SUB_CATEGORY_BY_ID,
      payload: category
    };
  }
  
  export function searchCategory(category) {
    return {
      type: SEARCH_SUB_SUB_CATEGORY,
      payload: category
    };
  }
  export function addSubSubCategory(category) {
    return {
      type: CREATE_SUB_SUB_CATEGORY,
      payload: category
    };
  }
  
  export function EditSubSubCategory(category) {
    return {
      type: UPDATE_SUB_SUB_CATEGORY,
      payload: category
    };
  }
  
  // -------------------------------------------------
  
  export const loadSubSubCategory = (search) => {
    return async dispatch => {
      dispatch(loadingSubSubCategory());
      let obj = {
      "limit": 1000,

        "filters": {
          // "type": "sub2",
          "type": "sub1",
          name: search
        },
        "sortBy": {
          "type": "createdOn",
          "order": -1
        },
      }
      // axiosauth.post('/categories/load', obj)
      // axiosauth.post('/categories/load?subCategory=subCategory', obj)
      axiosauth.post('/categories/load?subCategory=subCategory', obj)
        .then(async (response) => {
          console.log("response----------->>>>",response)
          let res = JSON.parse(response.data) 
          console.log('res---------->>' , res)
          if (response.status === 200) {
            dispatch(fetchSubSubCategory(res.data));
          } else {
            dispatch(fetchSubSubCategoryFail(res.message));
          }
        })
        .catch((error) => {
          // console.log(error);
          dispatch(fetchSubSubCategoryFail("Network Error"));
        })
  
    };
  };
  
  export const loadSubSubCategoryByPage = (pageNumber) => {
    return async dispatch => {
      dispatch(loadingSubSubCategory());
      let obj = {
        count: (pageNumber - 1) * 10,
        filters: {
          // type: "sub2",
          type: "sub1",
        },
        "sortBy": {
          "type": "createdOn",
          "order": -1
        },
      };
      // axiosauth.post('/categories/load', obj)
      axiosauth.post('/categories/load?subCategory=subCategory', obj)
        .then(async (response) => {
          // console.log("response ----->>",response)
          let res = JSON.parse(response.data) 
          if (response.status === 200) {
            dispatch(fetchSubSubCategory(res.data));
          } else {
            dispatch(fetchSubSubCategoryFail(res.message));
          }
        })
        .catch((error) => {
          // console.log(error);
          dispatch(fetchSubSubCategoryFail("Network Error"));
        })
  
    };
  };
  
  
  export const loadSubSubCategoryById = (id) => {
    return async dispatch => {
      dispatch(loadingSubSubCategory());
      // console.log("category by id")
      let obj = {
        "categoryId": id
      };
      // axiosauth.post('/categories/load-by-id', obj)
      axiosauth.post('/categories/load-by-id', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          if (res.success === true) {
            // console.log("if res:", res)
            dispatch(fetchSubSubCategory(res.data.category.category[0]));
          } else {
            dispatch(fetchSubSubCategoryFail(res.message));
          }
        })
        .catch((error) => {
          // console.log(error);
          dispatch(fetchSubSubCategoryFail("Network Error"));
        })
  
    };
  };
  
  
  export const deleteSubSubCategoryById = (obj) => {
    return async dispatch => {
      dispatch(loadingSubSubCategory());
      axiosauth.post('/categories/delete-categories', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          // console.log("response->", res)
          // console.log("inside action delete - ", res)
          if (res.success === true) {
            // console.log("successfully deleted !", res)
            dispatch(loadSubSubCategory());
          } else {
            // console.log("failed !!")
            dispatch(fetchSubSubCategoryFail(res.message));
          }
        })
        .catch((error) => {
          // console.log(error);
          dispatch(fetchSubSubCategoryFail("Network Error"));
        })
  
    };
  };
  
  
  const subSubCategoryImageUpdate = (cId, imageFile) => {
    var formData = new FormData();
    formData.append("categoryId", cId);
    formData.append("type", "rectangle");
    formData.append("image", imageFile);
    axiosauth.post('/categories/edit-image', formData)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          console.log("image res:", res);
        } else {
          console.log("..")
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  
  export const createSubSubCategory = (obj, fileImage) => {
    return async dispatch => {
      dispatch(loadingSubSubCategory());
      axiosauth.post('/categories/create-category', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          if (res.success === true) {
            subSubCategoryImageUpdate(res.message.categoryId, fileImage)
            dispatch(addSubSubCategory(res));
          } else {
            alert(res.message)
            dispatch(fetchSubSubCategoryFail(res.message));
          }
        })
        .catch((error) => {
          // console.log("error")
          // console.log(error);
          dispatch(fetchSubSubCategoryFail("Network Error"));
        })
  
    };
  };
  
  export const updateSubSubCategory = (obj, fileImage) => {
    // console.log("obj.categoryId",obj.categoryId)
    // console.log("fileImage",fileImage)
    return async dispatch => {
      dispatch(loadingSubSubCategory());
      axiosauth.post('/categories/update-category', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          // console.log("res:", res)
          if (res.success === true) {
            subSubCategoryImageUpdate(obj.categoryId, fileImage)
            dispatch(EditSubSubCategory(res));
          } else {
            dispatch(fetchSubSubCategoryFail(res.message));
          }
        })
        .catch((error) => {
          // console.log(error);
          dispatch(fetchSubSubCategoryFail("Network Error"));
        })
  
    };
  };
  