import {
  BANNER_LOAD,
  BANNER_UPLOAD,
  BANNER_DELETE,
  BANNER_UPDATE,
  BANNER_FAIL
} from '../constants/types';

import axiosauth from '../../axios-config-auth';

export function loadBanner(banner) {
  return {
    type: BANNER_LOAD,
    payload: banner
  };
}

export function uploadBanner(banner) {
  return {
    type: BANNER_UPLOAD,
    payload: banner
  };
}

export function updateBanner(banner) {
  return {
    type: BANNER_UPDATE,
    payload: banner
  };
}

export function deleteBanner(banner) {
  return {
    type: BANNER_DELETE,
    payload: banner
  };
}

export function bannerFail(banner) {
  return {
    type: BANNER_FAIL,
    payload: banner
  };
}


export const fetchBanners = () => {
  return async dispatch => {
    dispatch(loadBanner());
    // let obj = {
    //   "filters": {
    //     "title": ""
    //   }
    // }
    axiosauth.post('/banner/load-banner')
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("banner fetch  response", res)
        if (res.success === true) {
          // console.log("res", res)
          dispatch(loadBanner(res.data));
        } else {
          dispatch(bannerFail(res.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(bannerFail("Network Error"));
      })
  };
};

//  Delete banner ::

export const uploadBanners = (obj) => {
  return async dispatch => {
    dispatch(uploadBanner());
    axiosauth.post('/banner/post-banner', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          // console.log("banner response", res)
          dispatch(uploadBanner(res.data));
        } else {
          dispatch(bannerFail(res.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(bannerFail("Network Error"));
      })
  };
};
