import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ToastProvider } from 'react-toast-notifications';
import { ToastContainer } from 'react-toastify';

// Import HOC MiddleWares
import requireAuth from './components/userAuth/require_auth';
import noRequireAuth from './components/userAuth/no_require_auth';

// Pages Import ::
import Login from "./pages/login"
// forgot Password
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ResetPassword from './pages/forgotPassword/ResetPassword';

import UserDetail from './pages/userDetail';
import ProductsDetail from './pages/product/productsDetail';
import OrderDetail from './pages/ordersDetail';
import OrderReturn from './pages/OrderReturn';
import CategoryManage from './pages/category/manageCategories';
import CartDetail from './pages/cartDetail';
import CartView from './pages/CartView';
import PastOrderView from './pages/orderView';

import CouponManage from './pages/coupon/manageCoupon'
import CreateCategory from './pages/category/CreateCategory';
import EditCategory from './pages/category/EditCategory';
import CreateCoupon from './pages/coupon/CreateCoupon';
import UpdateCoupon from './pages/coupon/UpdateCoupon';
import Cms from './pages/cms/Cms';
import HomePageSeo from './pages/home-seo/HomePageSeo';
import AddHomeSeo from './pages/home-seo/AddHomeSeo';
import EditHomeSeo from './pages/home-seo/EditHomeSeo';
import ViewDetail from './pages/ViewDetailUser';
import Collection from './pages/collection/Collection';
import Review from './pages/review/Review';
import Banner from './pages/banner/Banner';
import AddBanner from './pages/banner/AddBanner';
import EditBanner from './pages/banner/EditBanner';
import EditBannerImage from './pages/banner/EditBannerImage';

import ManageSlideBanner from './pages/banner/ManageSlideBanner';
import ManageUpperBanner from './pages/banner/ManageUpperBanner';
import ManageLowerBanner from './pages/banner/ManageLowerBanner';
import Dashboard from './pages/dashboard/dashboard';
import AddCollection from './pages/collection/AddCollection';
import Setting from './pages/setting/Setting';
// Variant Pages
import AddVariantPage from './pages/variant/AddvariantValues';
import AddVariantNamePage from './pages/variant/AddVariantName';
import ManageVariantPage from './pages/variant/ManageVariant';
import ManageVariantNamePage from './pages/variant/ManageVariantName';
import EditVariant from './pages/variant/EditVariant';
import EditVariantNamePage from './pages/variant/EditVariantName';
// 
import EditSubCategory from './pages/category/EditSubCategory';
import CreateSubCategory from './pages/category/CreateSubCategory';
import EditCollection from './pages/collection/EditCollection';

//  Css ::
import "./style/login.scss"
import "./style/sidebar.scss"
import "./style/menubar.scss"
import "./style/common.scss"
import "./style/cms.scss";
import "./style/forms.scss"
import "./style/dashboard.scss"
import "./style/successModal.scss"
import "./style/brand.scss"
// bootstrap css
// import "./style/bootstrap.min.css"
// loader css
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// alert message 
import 'react-confirm-alert/src/react-confirm-alert.css';

// bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';


// import authenticated action from  Login action
import { authenticated } from './redux/actions/login';
import Brand from './pages/brand/Brand';
import AddBrand from './pages/brand/AddBrand';
import AddTrademark from './pages/brand/AddTrademark';
import UpdateBrand from './pages/brand/UpdateBrand';
import BrandVendor from './pages/brand/BrandVendor';
import ManageCatalogue from './pages/brand/ManageCatalogue';
import AddProduct from './pages/brand/AddProduct';
// import AddProductImage from './pages/brand/AddProductImage';
import AddVariantImage from './pages/brand/AddVariantImage';
import BrandViewPage from './pages/brand/ViewBrandDetail';
import Variant from './pages/brand/Variant';
import EditProduct from './pages/brand/EditProduct';
import AddVariant from './pages/brand/AddVariant';
import EditTrademark from './pages/brand/EditTrademark';
import EditProductImage from './pages/brand/EditProductImage';
import EditVariantImage from './pages/brand/EditVariantImage';
import EditBrandVariant from './pages/brand/EditBrandVariant';
import ProductDetails from './pages/brand/ProductDetails';
import VariantViewDetails from './pages/brand/VariantViewDetails';
import AddProductVariant from './pages/product/AddProductVariant';
import EditProductVariant from './pages/product/EditProductVariant';
import ProductVarinatDetails from './pages/product/ProductVarinatDetails';
import ProductVariant from './pages/product/ProductVariant';
import CreateProduct from './pages/product/CreateProduct';
import OrderDetails from './wrapper/orders/OrderDetails';
import ReviewDetails from './pages/review/ReviewDetails';
import CreateSubSubCategory from './pages/category/CreateSubSubCategory';
import {OptionsManagements} from "../src/pages/option_management/OptionsManagements"
import {ViewOptions} from "../src/pages/option_management/ViewOptions"
import AddStyle from './pages/style/addStyle';
import StyleList from "./pages/style/StyleList"
import IdealList from './pages/style/IdealList';
import TrendsList from './pages/style/TrendsList';
import TraderTypeList from './pages/style/TraderTypeList';
import SeasonList from './pages/style/SeasonList';
import ConditionList from './pages/style/ConditionList';
import AddIdealsForm from './wrapper/productManagement/AddIdealsForm';
import AddTrendsForm from "./wrapper/productManagement/AddTrendsForm"
import AddTraderTypeForm from "./wrapper/productManagement/AddTraderTypeForm"
import AddConditionForm from './wrapper/productManagement/AddConditionForm';
import AddSeasonForm from './wrapper/productManagement/AddSeasonForm'
import UpdateStyle from './wrapper/productManagement/viewDetails/UpdateStyle';
import UpdateIdeal from './wrapper/productManagement/viewDetails/UpdateIdeal';
import UpdateSeason from './wrapper/productManagement/viewDetails/UpdateSeason';
import UpdateTrends from './wrapper/productManagement/viewDetails/UpdateTrends';
import UpdateTraderType from './wrapper/productManagement/viewDetails/UpdateTraderType';
import UpdateCondition from './wrapper/productManagement/viewDetails/UpdateCondition';
import UpdateCMSPage from './wrapper/cms/cmspages/updatecms/UpdateCMSPage';
import AddTopBanner from './pages/banner/AddTopBanner';
import AddFooterBanner from './pages/banner/AddFooterBanner';
import AddMiddleBanner from './pages/banner/AddMiddleBanner';
import EditTopBanner from './pages/banner/EditTopBanner';
import EditMiddleBanner from './pages/banner/EditMiddleBanner';
import EditFooterBanner from './pages/banner/EditFooterBanner';
import AddGetKnowUS from './wrapper/cms/cmspages/AddGetKnowUS';
// import ImageManagement from './pages/image-management/ImageManagement';
// import UpdateImgFolder from './pages/image-management/UpdateImgFolder'; 
import AddPolicy from './wrapper/cms/cmspages/updatecms/AddPolicy'; 
import AddLetUsHelp from './wrapper/cms/cmspages/updatecms/AddLetUsHelp'; 
import AddConnectWithUs from './wrapper/cms/cmspages/updatecms/AddConnetWithUs';
import { AddOptionProduct } from './pages/option_management/AddOptionProduct';
// import { EditSubSubCategory } from './redux/actions/subSubCategoryAction';
import EditSubSubCategoryForm from './pages/category/EditSubSubCategoryFrom';
// import { EditSubSubCategory } from './redux/actions/subSubCategoryAction';
// import {EditSubSubCategory}  from './pages/category/EditSubSubCategory'
import Texonomy from './pages/taxonomy/Texonomy'
import AddTexonomy from './pages/taxonomy/AddTaxonomy'
import UpdateTexonomy from './pages/taxonomy/UpdateTaxonomy';
import SuspendedProductList from './wrapper/suspendedProduct/SuspendedProductList';
import SuspendedProductVariantList from './wrapper/suspendedProduct/SuspendedProductVariantList';
import SubCategoryList from './wrapper/category/subCategoryList';
import SubSubCategoryList from './wrapper/category/SubSubCategoryList';
import GajariGajariCoins from './pages/gajariPoints/ManageGajariCoins'
import AddGajariCoins from './pages/gajariPoints/AddGajariCoins';
// import AvailCoins from './pages/gajariPoints/AvailCoins';
import CategoryBanner from './wrapper/banner/CategoryBnner';
import AddCategoriesBanner from './pages/banner/AddCategoriesBanner';
import EditCategoryBanner from './pages/banner/EditCategoryBanner';
import AddSpecialFooter from './wrapper/cms/cmspages/updatecms/AddSpecialFooter';
import UpdateGajaricoins from './pages/gajariPoints/updateGajaricoins';
import ViewvariantValue from './wrapper/variant/ViewVariantValue';
import ShippingCharge from './wrapper/ShippingCharges/ShippingCharge';
import Explore from './wrapper/explore/Explore';
import BanUserDetail from './wrapper/userManage/banUserDetail'; 
import AddShippingCharges from './wrapper/ShippingCharges/AddShippingCharge';
import UpdateShippingCharges from './wrapper/ShippingCharges/updateShippingCharge';
import DealOfTheDay from './pages/dealoftheday/DealOfTheDayManagement';
import AddDealOfTheDay from './pages/dealoftheday/AddDealOfTheDay';
import Offer from './wrapper/offer/Offer';
import LoginBanner from './wrapper/banner/LoginBanner';
import AddLoginBanner from './pages/banner/AddLoginBanner';
import { SecondaryPriceSetting } from './wrapper/productSetting/SecondaryPriceSetting';
import { AddOfferSetting } from './wrapper/productSetting/AddOfferSetting';
import AddCODSetting from './wrapper/productSetting/AddCODSetting';
import { LoadOfferSetting } from './wrapper/productSetting/loadOfferSetting';
import { EditOfferSetting } from './wrapper/productSetting/EditOfferSetting';
import UpdateDealofTheDay from './pages/dealoftheday/UpdateDealOfTheDay';
import EditLoginBanner from './pages/banner/EditLoginBanner';
import EditMiddleBannerImage from './pages/banner/EditMiddleBannerImage';
import EditFooterBannerImage from './pages/banner/EditFooterBannerImage';
import OrderReturnDetails from './wrapper/orders/OrderReturnDetails';



function App() {
 
  const user = localStorage.getItem('accesstoken');

  if (user) {
    store.dispatch(authenticated(true));
  }


  return (
    <Provider store={store}>
          <ToastProvider placement='bottom-right'>

      <Router>
        <div>
          <Switch>
            <Route path="/" exact component={noRequireAuth(Login)} />
            <Route path="/forgot-password" exact component={noRequireAuth(ForgotPassword)} />
            <Route path="/reset-password" exact component={noRequireAuth(ResetPassword)} />
            {/* Dashboard */}
            <Route path="/dashboard" exact component={requireAuth(Dashboard)} />
            <Route path="/users" component={requireAuth(UserDetail)} />
            <Route path="/ban-users" component={requireAuth(BanUserDetail)} />
            <Route path="/user-detail/:userId" component={ViewDetail} />
            {/* product details */}
            <Route path="/products" component={requireAuth(ProductsDetail)} />
            <Route path="/edit-products-variant" component={requireAuth(EditProductVariant)} />
            <Route path="/add-products-variant" component={requireAuth(AddProductVariant)} />
            <Route path="/products-variant" component={requireAuth(ProductVariant)} />
            <Route path="/products-variant-details" component={requireAuth(ProductVarinatDetails)} />
            <Route path="/create-product" component={requireAuth(CreateProduct)} />

            <Route path="/orders" component={requireAuth(OrderDetail)} />
            <Route path="/orders-return" component={requireAuth(OrderReturn)} />
            <Route path="/order-details/:orderId" exact component={requireAuth(OrderDetails)} />

            <Route path="/return-order-details/:orderId" exact component={requireAuth(OrderReturnDetails)} />
            {/* Category Pages */}
            <Route path="/category-manage" component={requireAuth(CategoryManage)} />
            <Route path="/create-category" component={requireAuth(CreateCategory)} />
            <Route path="/edit-category/:categoryId" component={requireAuth(EditCategory)} />
            <Route path="/create-sub-category" component={requireAuth(CreateSubCategory)} />
            <Route path="/edit-sub-category/:categoryId" component={requireAuth(EditSubCategory)} />
            <Route path="/create-sub-sub-category" exact component={requireAuth(CreateSubSubCategory)} />
            <Route path="/edit-sub-sub-category/:categoryId" component={requireAuth(EditSubSubCategoryForm)} />

            {/* collections */}
            <Route path="/collection" component={requireAuth(Collection)} />
            <Route path="/add-collection" component={requireAuth(AddCollection)} />
            <Route path="/edit-collection/:collectionId" component={requireAuth(EditCollection)} />
            
            {/* coupons pages */}
            <Route path="/coupon-manage" component={requireAuth(CouponManage)} />
            <Route path="/create-coupon" component={requireAuth(CreateCoupon)} />
            <Route path="/update-coupon/:voucherId" component={requireAuth(UpdateCoupon)} />
            {/* Variant Pages */}
            <Route path="/variant-manage" component={requireAuth(ManageVariantPage)} />
            <Route path="/add-variant/:variantId" component={requireAuth(AddVariantPage)} />
            <Route path="/variant-name-manage/:variandId" component={requireAuth(ManageVariantNamePage)} />
            <Route path="/add-variant-name" component={requireAuth(AddVariantNamePage)} />
            <Route path="/edit-variant/:variantId" component={requireAuth(EditVariant)} />
            <Route path="/edit-variant-name/:variantId" component={requireAuth(EditVariantNamePage)} />
            {/* Review and rationg */}
            <Route path="/review" component={requireAuth(Review)} />
            <Route path="/cart-manage" component={requireAuth(CartDetail)} />
            <Route path="/cart-view/:cartId" component={requireAuth(CartView)} />
            <Route path="/past-orders-view/:cartId" component={requireAuth(PastOrderView)} />

            {/* Banner */}
            <Route path="/add-banner" component={requireAuth(AddBanner)} />
            <Route path="/edit-banner" component={requireAuth(EditBanner)} />
            <Route path="/edit-banner-image" component={requireAuth(EditBannerImage)} />
            <Route path="/edit-login-banner-image/:bannerId" component={requireAuth(EditLoginBanner)} />
            <Route path="/edit-middle-banner-image/:bannerId" component={requireAuth(EditMiddleBannerImage)} />
            <Route path="/edit-footer-banner-image/:bannerId" component={requireAuth(EditFooterBannerImage)} />

            <Route path="/middle-banner" component={requireAuth(ManageSlideBanner)} />
            <Route path="/top-banner" component={requireAuth(ManageUpperBanner)} />
            <Route path="/footer-banner" component={requireAuth(ManageLowerBanner)} />

            <Route path="/categories-banner" component={requireAuth(CategoryBanner)} /> 
            <Route path="/add-category-banner" component={requireAuth(AddCategoriesBanner)} />
            <Route path="/edit-category-banner" component={requireAuth(EditCategoryBanner)} />

            <Route path="/login-banner" component={requireAuth(LoginBanner)} /> 
            <Route path="/add-login-banner" component={requireAuth(AddLoginBanner)} /> 
            
            <Route path="/banner" component={requireAuth(Banner)} />
            <Route path="/cms" component={requireAuth(Cms)} />
            <Route path="/home-page-seo" component={requireAuth(HomePageSeo)} />
            <Route path="/add-home-page-seo" component={requireAuth(AddHomeSeo)} />
            <Route path="/edit-home-page-seo/:seoId" component={requireAuth(EditHomeSeo)} />
            <Route path="/logout" exact component={requireAuth(Login)} />
            {/* Setting */}
            <Route path="/setting" component={requireAuth(Setting)} />
            {/* mennage brand */}
            <Route path="/brand" component={requireAuth(Brand)} />
            <Route path="/review-details" component={requireAuth(ReviewDetails)} />

            <Route path="/brand-vendor" component={requireAuth(BrandVendor)} />
            <Route path="/add-brand" component={requireAuth(AddBrand)} />
            <Route path="/add-trademark" component={requireAuth(AddTrademark)} />
            <Route path="/update-brand/:brandId" component={requireAuth(UpdateBrand)} />
            <Route path="/manage-product" component={requireAuth(ManageCatalogue)} />
            <Route path="/add-product" component={requireAuth(AddProduct)} />
            {/* <Route path="/add-product-image" component={requireAuth(AddProductImage)} /> */}
             <Route path="/add-variant-image/:varImgId" component={requireAuth(AddVariantImage)} />
             <Route path="/add-catalouge-image/:varImgId" component={requireAuth(AddVariantImage)} />
            <Route path="/brand-detail/:brandId" component={requireAuth(BrandViewPage)} />
            <Route path="/brand-variant" component={requireAuth(Variant)} />
            <Route path="/update-product/:catalogueId" component={requireAuth(EditProduct)} />
            <Route path="/add-brand-variant" component={requireAuth(AddVariant)} />
            <Route path="/update-trademark/:brandId" component={requireAuth(EditTrademark)} />
            <Route path="/update-product-image/:catalogueId" component={requireAuth(EditProductImage)} />
            <Route path="/update-variant-image/:productId" component={requireAuth(EditVariantImage)} />
            <Route path="/update-brand-variant/:productVariantId" component={requireAuth(EditBrandVariant)} />
            <Route path="/brand-product-details/:catalogueId" component={requireAuth(ProductDetails)} />
            <Route path="/brand-variant-details/:productVariantId" component={requireAuth(VariantViewDetails)} />

            {/* ===============  Option manatement  =============== */}
            <Route path="/option-management" component={requireAuth(OptionsManagements)} />
            <Route path="/options/view/:optionId" component={requireAuth(ViewOptions)} />
            <Route path="/add-option-product/:optionId" component={requireAuth(AddOptionProduct)} />

             {/* ========================== Collection management============ */}
             {/* <Route path="/collections" component={requireAuth(CollectionManagements)} />
             <Route path="/collections/add-collections" component={requireAuth(AddCollections)} /> */}

            {/* ======================= product management========================== */}
            <Route path="/add-style" component={requireAuth(AddStyle)} />
            <Route path="/style-manage" component={requireAuth(StyleList)} />
            <Route path ="/update-style/:styleId" component={requireAuth(UpdateStyle)} />

            <Route path="/add-ideal" component={requireAuth(AddIdealsForm)} />
            <Route path="/ideals-manage" component={requireAuth(IdealList)} />
            <Route path ="/update-ideals/:idealId" component={requireAuth(UpdateIdeal)} />

            <Route path="/add-trends" component={requireAuth(AddTrendsForm)} />
            <Route path="/trends-manage" component={requireAuth(TrendsList)} /> 
            <Route path ="/update-trends/:trendsId" component={requireAuth(UpdateTrends)} />

            <Route path="/add-trader" component={requireAuth(AddTraderTypeForm)} /> 
            <Route path ="/update-tradet-type/:traderId" component={requireAuth(UpdateTraderType)} />
            <Route path="/trader-type-manage" component={requireAuth(TraderTypeList)} />

            <Route path="/add-season" component={requireAuth(AddSeasonForm)} />
            <Route path="/season-manage" component={requireAuth(SeasonList)} />
            <Route path ="/update-season/:seasonId" component={requireAuth(UpdateSeason)} />

            <Route path="/add-condition" component={requireAuth(AddConditionForm)} />
            <Route path="/condition-manage" component={requireAuth(ConditionList)} /> 
            <Route path ="/update-condition/:conditionId" component={requireAuth(UpdateCondition)} />

            {/* =======================update CMS page================== */}
            <Route path ="/update-cms/:id" component={requireAuth(UpdateCMSPage)} /> 
            <Route path="/add-get-knowUs" component={requireAuth(AddGetKnowUS)} />
            <Route path="/add-policy" component={requireAuth(AddPolicy)} />
            <Route path="/add-letus-help-you" component={requireAuth(AddLetUsHelp)} />
            <Route path="/add-connect-withus" component={requireAuth(AddConnectWithUs)} />
            <Route path ="/add-special-footer" component={requireAuth(AddSpecialFooter)}/>

            {/* ======================= Banner management================= */}
            <Route path="/add-top-banner" component={requireAuth(AddTopBanner)} />
            <Route path="/edit-top-banner" component={requireAuth(EditTopBanner)} />
            <Route path="/add-footer-banner" component={requireAuth(AddFooterBanner)} />
            <Route path="/edit-footer-banner" component={requireAuth(EditFooterBanner)} />
            <Route path="/add-middle-banner" component={requireAuth(AddMiddleBanner)} />
            <Route path="/edit-middle-banner" component={requireAuth(EditMiddleBanner)} />

            {/* ========================  Image Management============================ */}
            {/* <Route path="/image-management" component={requireAuth(ImageManagement)} />
            <Route path ="/edit-image-folder/:id" component={requireAuth(UpdateImgFolder)} /> */}
            
            {/* ============================ Texonomy =================================== */}
            <Route path="/taxonomy" component={requireAuth(Texonomy)} /> 
            <Route path="/add-taxonomy" component={requireAuth(AddTexonomy)} />
            <Route path ="/update-texonomy/:texonomyId" component={requireAuth(UpdateTexonomy)} />

            {/* ============================== Suspended Product================================== */}
            
            <Route path="/suspended-products" component={requireAuth(SuspendedProductList)} /> 

            <Route path="/suspended-products-variant" component={requireAuth(SuspendedProductVariantList)} />

            {/* ======================================== */} 
            <Route path="/manage-sub-category" component={requireAuth(SubCategoryList)} />
            <Route path="/manage-sub-sub-category" component={requireAuth(SubSubCategoryList)} />

            {/* ================= Coins ===================== */}

            <Route path="/manage-coins" component={requireAuth(GajariGajariCoins)}/>
            {/* <Route path="/avail-coins" component={requireAuth(AvailCoins)}/>*/}
            <Route path="/addgajaricoins" component={requireAuth(AddGajariCoins)}/> 
            <Route path="/update-gajaricoins/:coineId" component={requireAuth(UpdateGajaricoins)} />

            {/* ====================================== */}
            <Route path="/view-variant-value/:variantId" component={requireAuth(ViewvariantValue)} />

            {/* ==================== Explore and shipping charge===================== */}
            <Route path ="/shipping-charges" component={requireAuth(ShippingCharge)}/>
            <Route path ="/add-Shipping-charge" component={requireAuth(AddShippingCharges)}/>
            <Route path ="/update-Shipping-charge/:shipId" component={requireAuth(UpdateShippingCharges)}/>
            <Route path ="/explore" component={requireAuth(Explore)}/>
            <Route path ="/deal-of-the-management" component={requireAuth(DealOfTheDay)}/>
            <Route path ="/add-deal-of-the" component={requireAuth(AddDealOfTheDay)}/>
            <Route path ="/update-deal-of-the/:dealId" component={requireAuth(UpdateDealofTheDay)}/>
            <Route path ="/offer" component={requireAuth(Offer)}/>

            {/* ======================================================================= */}

            {/* <Route path ="/add-product-setting" component={requireAuth(SecondaryPriceSetting)}/> */}
            <Route path ="/add-offer-setting" component={requireAuth(AddOfferSetting)}/>
            <Route path ="/load-offer-setting" component={requireAuth(LoadOfferSetting)}/>
            <Route path ="/edit-offer-setting/:offersetting" component={requireAuth(EditOfferSetting)}/>
            {/* <Route path ="/add-cod-setting" component={requireAuth(AddCODSetting)}/> */}


          </Switch>
        </div>
        <ToastContainer />
      </Router>
      </ToastProvider>
    </Provider>
  );
}

export default App;