import React from 'react';
import Layout from "../../layout/layout"
import EditCoupon from '../../wrapper/coupon/EditCoupon';

const UpdateCoupon = () => {

    return (
        <Layout>
            <EditCoupon />
        </Layout>
    )
}

export default UpdateCoupon
