import React from 'react'
import Layout from '../../layout/layout'
import { OptionDetails } from './OptionDetails'

export const OptionsManagements = () => {
    return ( 
        <Layout> 
                <OptionDetails/> 
        </Layout>
    )
}

