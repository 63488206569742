import React,{useState, useEffect} from 'react';
import left_arrow2 from './../assets/icons/down_arrow2.png'
import { useParams,useHistory,Link} from 'react-router-dom';
import axiosauth from './../axios-config-auth';
// import noImage from './../assets/images/no-img.png';
import Loader from 'react-loader-spinner';
import Layout from "../layout/layout"



import 'react-confirm-alert/src/react-confirm-alert.css';

// import '../../style/customer.scss';
const OrderView = () => {

    const { userId } = useParams();
  const history = useHistory();
      const [showLoader, setShowLoader] = useState(true);
      const [user, setUser]=useState([]);
      const[orders,setorders]=useState([])
      useEffect(() => {
    let obj1 = {
        "userId":window.location.href.split("past-orders-view/")[1]
    }
    axiosauth.post('/admin/load-user-by-id', obj1)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
            // console.log("==== useeeeerrr===>", res.data.user)
            setUser(res.data.user)
          setShowLoader(false);
              
        } else {
         
        }
      })
      .catch((error) => {
        console.log(error);
      })
      let obj = {
        "count":0,
        "limit":1000,
        "filters":{
            "createdBy":localStorage.getItem("orderId")
        },
    }
    axiosauth.post('/order/load-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
            // console.log("==== useeeeerrr===>", res.data.user)
            setorders(res.data.orders)
          setShowLoader(false);
              
        } else {
         
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [userId]);

// console.log("--user-->",user)






    return (
        <Layout>
     

     <div>
      <section className="detail-heading">
      <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
       <img  onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" />
        <h2>Past Orders Details</h2>
        </div>
      </section>
  
   {!showLoader ?
         <>
         <section className="detail-body" >
            <div>NAME</div>
            <div className="titleStyle">{user.firstName+" "+user.lastName}</div>
          </section>
       
          {orders && orders.length>0 ?
                orders.map((c, index)=>{
              return(
      
                <section className="detail-body" >
                <div>Order Id: {c.orderId}</div>
                <div ><Link to={`/order-details/${c.orderId}`}>                           
                            View Details
                            </Link></div>
              </section>
        )
            })

            :null
            }



           <div className="button">
            
              <button  onClick={() => history.goBack()}>Back</button> &nbsp;
            </div>

                 </>
                 :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </div>
            } 

          </div>


        </Layout>
    )
}

export default OrderView
