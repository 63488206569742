import React, { useState, useEffect, useRef } from "react";
import {
  EditorState,
  ContentState,
  convertFromHTML, 
} from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Layout from "../../../../layout/layout";
import left_arrow2 from "../../../../assets/icons/down_arrow2.png";
import { useHistory, useParams } from "react-router";
import axiosauth from "../../../../axios-config-auth";
import Loader from "react-loader-spinner";
// import draftToHtml from "draftjs-to-html";
// import SuccessModal from '../../../components/halper/SuccessModal';
import SuccessModal from "../../../../components/halper/SuccessModal";
// import { Redirect } from "react-router-dom";
// import LoaderModal from "../../../../components/halper/LoaderModal";
// import NewEditor from "./Editor";
// import JoditEditor from "jodit-pro-react";
import { Editor } from '@tinymce/tinymce-react';


const UpdateCMSPage = () => {
  const history = useHistory();
  // const [active, setActive] = useState(true)
  const [updateData, setUpdateData] = useState(EditorState.createEmpty());
  const { id } = useParams();

  const [showLoader, setShowLoader] = useState(false);
  const [dataObj, setDataObj] = useState([]);
  const [name, setName] = useState("");
  const [isLink, setIsLink] = useState("");
  const [link, setLink] = useState("");
  const [editorContent, seteditorContent] = useState("");

  // const [redirect, setRedirect] = useState(false);
  const [showsuccess, setShowSuccess] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  // ===================
  const editorRef = useRef(null);
  const [content, setContent] = useState("");
  // console.log("value", content)

  const config = {
    readonly: false,
  };

  // const onEditorStateChange = (editorState) => {
  //   setUpdateData(editorState);
  //   let enterData = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  //   // console.log(enterData)
  //   seteditorContent(enterData);
  // };

  useEffect(() => {
    const obj = {
      id: id,
    };
    setShowLoader(true);
    axiosauth
      .post("/footer/list_footerbyid", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          //   console.log(res);
          setDataObj(res.data);
          setIsLink(res.data.is_link ? "true" : "false");
          setName(res.data.name);
          setLink(res.data.url);
          setShowLoader(false);
          setContent(res.data.body);
          setUpdateData(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(res.data.body))
            )
          );
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUpdate = () => {
    const regexp =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    var pattern =
      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

    if (isLink === "true") {
      if (pattern.test(link)) {
        const obj = {
          id: id,
          headName: dataObj?.headName,
          name: name,
          is_link: true,
          url: link,
          body: "",
        };
        axiosauth
          .post("/footer/update-footer", obj)
          .then(async (response) => {
            let res = JSON.parse(response.data);
            if (res.success === true) {
              setShowSuccess(true);
            } else {
              setNetworkError(true);
            }
          })
          .catch((error) => {
            // console.log(error);
            setNetworkError(true);
          });
      } else {
        alert("Pleae Enter valid Link");
      }
    }

    if (isLink === "false") {
      const obj = {
        id: id,
        headName: dataObj?.headName,
        name: name,
        is_link: false,
        body: content.newText,
        url: "",
      };
      axiosauth
        .post("/footer/update-footer", obj)
        .then(async (response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            setShowSuccess(true);
          } else {
            setNetworkError(true);
          }
        })
        .catch((error) => {
          // console.log(error);
          setNetworkError(true);
        });
    }
  };
  const closeModal = () => {
    history.push("/cms");
  };
  // const connectionError = () => {
  //     setNetworkError(false)
  // }

  // console.log("updateData", name, isLink, editorContent)

  return (
    <Layout>
      {!showLoader ? (
        <>
          <div
            className="left_arrow2 back-arrow my-5 mx-3"
            style={{
              display: "flex",
              alignItems: "baseline",
              fontFamily: "inherit",
            }}
          >
            <img
              src={left_arrow2}
              onClick={() => history.goBack()}
              alt="left-arrow"
            />{" "}
            &nbsp;
            <h2 className="mx-3">{dataObj?.headName}</h2>
          </div>
          <div className="container">
            <div className="single-field">
              <p>
                Page Name<b>*</b>
              </p>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-100 form-control"
                placeholder="Enter page name"
              />
            </div>

            <div className="radio-wrap">
              <h4> Is Link </h4>
              <div>
                <aside>
                  <input
                    type="radio"
                    name="specification"
                    value="true"
                    checked={isLink === "true" ? true : false}
                    onChange={(e) => {
                      //   console.log(e.target.value);
                      setIsLink(e.target.value);
                    }}
                  />{" "}
                  <span> Yes </span>{" "}
                </aside>{" "}
                <aside>
                  <input
                    type="radio"
                    name="specification"
                    value="false"
                    checked={isLink === "true" ? false : true}
                    onChange={(e) => {
                      //   console.log(e.target.value);
                      setIsLink(e.target.value);
                    }}
                    l
                  />
                  <span> No </span>
                </aside>
              </div>
            </div>
            {/* {isLink === 'true' ? */}
            <div
              className="single-field"
              style={{ display: isLink == "false" && "none" }}
            >
              <p>
                Link <b>*</b>
              </p>
              <input
                type="text"
                id="name"
                value={link}
                className="w-100 form-control"
                placeholder="Enter link"
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            {/* : */}
            <div
              className="cms-body my-5"
              style={{ display: isLink == "true" && "none" }}
            >
              {/* <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => {
                  // setContent(newContent)
                  //   console.log("new content", newContent);
                }}
              /> */}
              <Editor
                    onInit={(evt, editor) => { editorRef.current = editor }}
                    initialValue={content}
                    onEditorChange={(newText) => {
                      setContent({...content , newText})
                    }}
                    init={{
                      height: 200,
                      menubar: true,
                      plugins: [
                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
            </div>
            {/* } */}
            <div className="button">
              <button className="px-5" onClick={handleUpdate}>
                {" "}
                Update{" "}
              </button>{" "}
            </div>
          </div>
        </>
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={500000}
          />
        </div>
      )}
      {/* {show === true ? <SuccessModal message="CMS updated!" onClick={closeModal} /> : null} */}
      {/* {redirect ? <Redirect to="/cms" /> : null}
            {show === true ? <SuccessModal message="CMS updated!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null} */}

      {showsuccess === true ? (
        <SuccessModal message="CMS updated!" onClick={closeModal} />
      ) : null}
      {networkError === true ? (
        <SuccessModal message={"Some Error Occured"} onClick={closeModal} />
      ) : null}
    </Layout>
  );
};

export default UpdateCMSPage;
