import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosauth from "../../axios-config-auth";
import { Redirect } from "react-router-dom";
import SuccessModal from "../../components/halper/SuccessModal";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
// import { confirmAlert } from "react-confirm-alert";
import LoaderModal from "../../components/halper/LoaderModal";

const AddVariant = () => {
  const animatedComponents = makeAnimated();
  // const [value1, setValue1] = useState("false");
  const [redirect, setRedirect] = useState(false);
  const [show, setShow] = useState(false);
  // const [short, setShort] = useState(false);
  // const [long, setLong] = useState(false);
  const [sameImg, setSameImg] = useState(false);
  // const [spe, setSpe] = useState(false);

  // const [speNameError, setSpeNameError] = useState("");
  // const [speValueError, setSpeValueError] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [back, setBack] = useState(false);

  // eslint-disable-next-line
  // const [variants, setVariants] = useState([]);
  // const [variantValue, setVariantValue] = useState([]);
  const [variantValue1, setVariantValue1] = useState([]);
  // const [variantValue2, setVariantValue2] = useState([]);
  const [option, setOption] = useState("")

  // const [sendVarList, setsendVarList] = useState([]);
  // eslint-disable-next-line
  // const [sendVarList1, setsendVarList1] = useState([]);
  // eslint-disable-next-line
  // const [sendVarList2, setsendVarList2] = useState([]);
  // eslint-disable-next-line
  // const [variantshow, setvariantshow] = useState(true);
  // eslint-disable-next-line
  // const [variantshowa, setvariantshowa] = useState(false);
  // eslint-disable-next-line
  // const [variantshowb, setvariantshowb] = useState(false);
  // eslint-disable-next-line
  // const [varListParent, setVarListParent] = useState([]);
  const [catalogueData, setCatelogueData] = useState([]);

  // const [shortError, setShortError] = useState("");
  // const [longError, setLongError] = useState("");
  const [resMessage, setResMessage] = useState("");
  // const [varError, setVarError] = useState("");

  // const [shortCount, setShortCount] = useState(0);
  // const [longCount, setLongCount] = useState(0);
  // const [shortCountError, setShortCountError] = useState(null);
  // const [longCountError, setLongCountError] = useState(null);
  const [selectedOption, setselectedOption] = useState("");
  const [selectedOption1, setselectedOption1] = useState("");
  // const [selectedOption2, setselectedOption2] = useState("");
  // const [spec, setspec] = useState(false);
  const [varImgId, SetVarImgId] = useState("");

  // const [product, setproduct] = useState({
  //   name: "",
  //   shortDescription: "",
  //   longDescription: "",
  // });
  // const { shortDescription, longDescription } = product;

  const handleSelect = (selectedOption, variants) => {
    let varvaluelist = [];
    // console.log("select option", selectedOption);

    for (var p = 0; p < variants.length; p++) {
      if (selectedOption.value === variants[p].parent._id) {
       
        for (var l = 0; l < variants[p].values.length; l++) {
          // console.log("variants:=======>>>>", variants[p].values[l].value , variants[p].values[l]._id);
          varvaluelist.push({
            label: variants[p].values[l].value,
            value: variants[p].values[l]._id,
          });
        }
      }
    }
    setselectedOption(varvaluelist[0]);
    setVariantValue1(varvaluelist);
  };

  // const handleSelectValue = (selectedOption) => {
  //   setsendVarList(selectedOption.value);
  //   setVarError("");
  //   setselectedOption(selectedOption);
  //   console.log("sendVarList", sendVarList);
  // };

  // select variant 2
  const handleSelect1 = (selectedOption, variants) => {
    let varvaluelist = [];
    // setCategoryId(selectedOption.catObjid);
    // console.log("select option", selectedOption);
    for (var p = 0; p < variants.length; p++) {
      if (selectedOption.value === variants[p].parent._id) {
        // console.log("variants:", variants[p].values);
        for (var l = 0; l < variants[p].values.length; l++) {
          varvaluelist.push({
            label: variants[p].values[l].value,
            value: variants[p].values[l]._id,
          });
        }
      }
    }
    setselectedOption1(varvaluelist[0]);
// console.log("jshgjhs")
    setVariantValue1(varvaluelist);
  };

  const handleSelectValue1 = (selectedOption) => {
    // setsendVarList1(selectedOption.value);
    // console.log("sendVarList", sendVarList);
    setselectedOption1(selectedOption);
  };

  // select variant3
  const handleSelect2 = (selectedOption, variants) => {
    let varvaluelist = [];
    // setCategoryId(selectedOption.catObjid);
    // console.log("select option", selectedOption);

    for (var p = 0; p < variants.length; p++) {
      if (selectedOption.value === variants[p].parent._id) {
        // console.log("variants:", variants[p].values);
        for (var l = 0; l < variants[p].values.length; l++) {
          varvaluelist.push({
            label: variants[p].values[l].value,
            value: variants[p].values[l]._id,
          });
        }
      }
    }
    // setselectedOption2(varvaluelist[0]);
    // setVariantValue2(varvaluelist);
  };

  // const handleSelectValue2 = (selectedOption) => {
  //   setsendVarList2(selectedOption.value);
  //   console.log("sendVarList", sendVarList);
  //   setselectedOption2(selectedOption);
  // };

  /**
   * TODO: function use for updating values required for creating variant
   *@version 1.0.0
   * @author [ Unknown]
   */
  // const handleChange = (e) => {
  //   setproduct({ ...product, error: false, [e.target.name]: e.target.value });
  //   setShortError("");
  //   setLongError("");
  //   setResMessage("");

  //   if (e.target.name === "shortDescription") {
  //     setShortCount(product.shortDescription.split(" ").length);
  //   }

  //   if (e.target.name === "longDescription") {
  //     setLongCount(product.longDescription.split(" ").length);
  //   }

  //   setShortCountError(null);
  //   setLongCountError(null);
  // };

  const submitVariant = () => {
    var variantSend = [];
    if(option.length>0){
        variantSend.push(selectedOption1.value);
        variantSend.push(option);
    }
    else{
        variantSend.push(selectedOption1.value);
    }
    
    
    // variantSend.push(selectedOption2.value)

    // console.log("variantSend:", variantSend)

    // if(variantSend && variantSend.length===0){
    //     setVarError("Field Required!")
    //     return 0;
    // }

    // if (value1 === "true" && specs.length > 0 && spe === false) {
    //     if (specs[0].name === "") {
    //         setSpeNameError("Title Required");
    //         return 0;

    //     }
    //     if (specs[0].value === "") {
    //         setSpeValueError("Value required");
    //         return 0;
    //     }
    // }

    // if (!short && shortDescription.length === 0) {
    //     setShortError("Field Required!")
    //     return 0;
    // }

    // if (shortCount > 500) {
    //     setShortCountError("Short Description should be <=500 words.")
    //     setShortError("")

    //     return 0;
    // }

    // if (!long && longDescription.length === 0) {
    //     setLongError("Field Required!")
    //     return 0;
    // }

    // if (longCount > 2000) {
    //     setLongCountError("Long Description should be <=2000 words.")
    //     setShortError("");
    //     return 0;
    // }

    // let obj={
    // "baseProduct":localStorage.getItem("productobj"),
    // "variant":variantSend,
    // // "name":name,
    // "slug":catalogueData.name,
    // "seoTitle":catalogueData.name,
    // "seoDescription":catalogueData.name,
    // "shortDescription":(short? catalogueData.shortDescription :shortDescription),
    // "longDescription":(long?catalogueData.longDescription:longDescription),

    // specification: value1 === "true" ? catalogueData.specification :specs,
    // }
    

    // let obj = { 
    // baseProduct:catalogueData._id,
    //   variant: variantSend,
    //   name: catalogueData.name,
    //   slug: catalogueData.name,
    //   seoTitle: catalogueData.name,
    //   seoDescription: catalogueData.name,
    //   shortDescription: short
    //     ? catalogueData.shortDescription
    //     : shortDescription,
    //   longDescription: long ? catalogueData.longDescription : longDescription,
    //   specification: value1 === "true" && specs,
    //   shippingCost: 0,
    // };

    let obj={
        "baseProduct":catalogueData._id,
	"variant":variantSend,
	"name":catalogueData.name,
	"seoTitle":catalogueData.name,
	"seoDescription":catalogueData.name,
	"shortDescription":"",
	"longDescription": "",
    "specification":{
        "name":"color",
        "value":"vl"
    },
	"shippingCost":150
    }
    // console.log("variant obj:", obj);

    // console.log("specs--->", specs)

    setShowLoader(true);

    axiosauth
      .post("/product-variant/create-product-variant", obj)
      .then((response) => {
        let res = JSON.parse(response.data);

        if (res.message === "success") {
          // console.log("var as res:", res)
          if (!sameImg) {
            // imageUpdate(res.data.productVariantId);
            localStorage.setItem("baseProduct", res.data.baseProduct);
            SetVarImgId(res.data.productVariantId);
          }
          setShowLoader(false);
          setShow(true);
          localStorage.setItem("productVariantId", res.data.productVariantId);
          setResMessage("");
        } else {
          // console.log("var as res else:", res);
          setResMessage(res.message);
          setShowLoader(false);

          // setcatalogueerror(res.message)
        }
      })
      .catch((error) => {
        console.log(error);
        setNetworkError(true);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };

  // const handleChange1 = (e) => {
  //   if (e.target.value === "true") {
  //     setspec(true);
  //   } else {
  //     setspec(false);
  //   }
  //   setValue1(e.target.value);
  // };

  // const [specs, setspecs] = useState([
  //   {
  //     name: "",
  //     value: "",
  //   },
  // ]);
  /**
   * TODO: function use for removing specification row
   *@version 1.0.0
   * @author [ Unknown]
   */
  // const removespecs = (index) => {
  //   const temp = [...specs];
  //   const filter = temp.filter((item, i) => i !== index);
  //   setspecs(filter);
  // };
  /**
   * TODO: function use for adding specification row
   *@version 1.0.0
   * @author [ Unknown]
   */
  // const addspecs = () => {
  //   setspecs([...specs, { name: "", value: "" }]);
  // };

  /**
   * TODO: function use for handling name value change of specification
   *@version 1.0.0
   * @author [ Unknown]
   */
  // const handleChange2 = (e, name, index) => {
  //   const { value } = e.target;
  //   const list = [...specs];
  //   list[index][name] = value;
  //   setspecs(list);
  //   setSpeNameError("");
  //   setSpeValueError("");
  // };
  // console.log("specs", specs)

  useEffect(() => {
    let obj = {
      catalogueId: localStorage.getItem("image"),
    };
    axiosauth
      .post("/catalogue/find-catalogue-by-id", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          if (res.result) {
            setCatelogueData(res.result[0]);
            // console.log("catalogue res:", res.result[0]);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setvariantshow(true);

    const findVariants = () => {
      let obj = {};
      axiosauth
        .post("/variants/findAllVariants/", obj)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            let variants = [];
            var varList = [];
            variants.push({
              parent: {
                _id: "5f60998ee0cb47251035e65b",
                variantId: "yikh8vDPiUjkKVLO1bneTmkBQhbvRSRa",
                variantName: "colorzzzzzzzzzzzzzz",
                __v: 0,
              },
              values: [
                {
                  _id: "5f6c5b8be2eab8709342c308",
                  value: "redzzzzzzzzzzzzzzz",
                  parentVariant: "5f60998ee0cb47251035e65b",
                  variantId: "color-red",
                  __v: 0,
                },
                {
                  _id: "5f6c5b90e2eab8709342c309",
                  value: "blackzzzzzzzzzzzzzz",
                  parentVariant: "5f60998ee0cb47251035e65b",
                  variantId: "color-black",
                  __v: 0,
                },
                {
                  _id: "5f6c5b94e2eab8709342c30a",
                  value: "goldenzzzzzzzzzzzzzzzzz",
                  parentVariant: "5f60998ee0cb47251035e65b",
                  variantId: "color-golden",
                  __v: 0,
                },
              ],
            });
            for (var i = 0; i < res.data.length; i++) {
              variants.push(res.data[i]);
              varList.push({
                label: res.data[i].parent.variantName,
                value: res.data[i].parent._id,
              });
            }
            // console.log("jjdjd", varList)
            // setVariants(variants);
            // setVarListParent(varList);
            handleSelect(varList[0], variants);
            handleSelect1(varList[1], variants);
            handleSelect2(varList[2], variants);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          console.log("-----always executes");
        });
    };
    findVariants();
  }, []);

  // const handleInputChange=(search)=>{
  //     let obj={
  //         "filters": {
  //           "variantName": search
  //         }
  //       }
  //     axiosauth.post('/variants/findAllVariants/', obj).then(response => {
  //         let res = JSON.parse(response.data)
  //         if (res.success === true) {
  //             let variants = [];
  //             var varList = []
  //             variants.push({
  //                 "parent": {
  //                     "_id": "5f60998ee0cb47251035e65b",
  //                     "variantId": "yikh8vDPiUjkKVLO1bneTmkBQhbvRSRa",
  //                     "variantName": "colorzzzzzzzzzzzzzz",
  //                     "__v": 0
  //                 },
  //                 "values": [
  //                     {
  //                         "_id": "5f6c5b8be2eab8709342c308",
  //                         "value": "redzzzzzzzzzzzzzzz",
  //                         "parentVariant": "5f60998ee0cb47251035e65b",
  //                         "variantId": "color-red",
  //                         "__v": 0
  //                     },
  //                     {
  //                         "_id": "5f6c5b90e2eab8709342c309",
  //                         "value": "blackzzzzzzzzzzzzzz",
  //                         "parentVariant": "5f60998ee0cb47251035e65b",
  //                         "variantId": "color-black",
  //                         "__v": 0
  //                     },
  //                     {
  //                         "_id": "5f6c5b94e2eab8709342c30a",
  //                         "value": "goldenzzzzzzzzzzzzzzzzz",
  //                         "parentVariant": "5f60998ee0cb47251035e65b",
  //                         "variantId": "color-golden",
  //                         "__v": 0
  //                     }
  //                 ]
  //             })
  //             for (var i = 0; i < res.data.length; i++) {
  //                 variants.push(res.data[i])
  //                 varList.push({label:res.data[i].parent.variantName,value:res.data[i].parent._id})
  //             }
  //             setVariants(variants)
  //             setVarListParent(varList)
  //             handleSelect(varList[0],variants)
  //             handleSelect1(varList[1],variants)
  //         }
  //         else {

  //         }

  //     }).catch((error) => {
  //         console.log(error);
  //     }).then(() => {
  //         console.log("-----always executes");
  //     })
  // }

  const closeModal = () => {
    setRedirect(true);
  };

  const connectionError = () => {
    setNetworkError(false);
  };

  const backButton = () => {
    setBack(true)
    // if (sendVarList.length !== 0) {
    //   confirmAlert({
    //     // title: 'Change Status',
    //     message: "Are you sure to do this. You will loss your data.",
    //     buttons: [
    //       {
    //         label: "Yes",
    //         onClick: () => setBack(true),
    //       },
    //       {
    //         label: "No",
    //         onClick: () => console.log("No"),
    //       },
    //     ],
    //   });
    // } else {
    //   setBack(true);
    // }
  };

  // console.log("variantValue1=====>>>" , variantValue1)

  return (
    <Layout>
      {back ? <Redirect to="/brand-variant?page=1" /> : null}
      {networkError === true ? (
        <SuccessModal
          message="Internet Connection Error.."
          onClick={connectionError}
        />
      ) : null}
      {showLoader === true ? <LoaderModal /> : null}

      {redirect === true && sameImg === true ? (
        <Redirect to={`/brand-variant?page=1`} />
      ) : redirect === true ? (
        <Redirect to="/brand-variant?page=1" />
      ) : null}
      {show === true ? (
        <SuccessModal message="New Variant Added!" onClick={closeModal} />
      ) : null}

      <div className="form-wrap" style={{height:"100vh"}}>
        {/* <div className="form-header"> */}
        <div
          className="left_arrow2 back-arrow"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img src={left_arrow2} onClick={backButton} alt="left-arrow" /> &nbsp;
          <h2>Add Variant</h2>
        </div>

        {/* <h4>Add Variant</h4> */}
        {/* </div> */}
        <div className="form-body">
          <div className="single-field">
            <p>
              Product Name <b>*</b>
            </p>
            <input
              type="text"
              id="name"
              placeholder="Enter Product Name"
              name="name"
              value={catalogueData?.name}
              // onChange={handleChange}
              readOnly
            />
          </div>

          {/* <div style={{display:'flex',justifyContent:'space-between' }}> */}
          <div>
            <div className="select-variant">
              <div className="var1">
                <br />
                <h4 className="label-form">
                  {" "}
                  Variant <b>*</b>
                </h4>

                <>
                  <div className="row">
                    <div className="col-md-4">
                      <span className="ml-1">Size</span>
                      <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={variantValue1}
                        value={selectedOption1}
                        name="category"
                        required
                        onChange={handleSelectValue1}
                      />
                    </div>

                    {/* <div className="col-md-4">
                      <span className="ml-1">Colour</span>
 
                      <select
                        className="form-select form-control form-control-nonround"
                        aria-label="Default select example"
                        required={true}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setOption(e.target.value)
                        }}
                      >
                        <option value="" selected>
                          Open this select menu
                        </option>
                        {variantValue.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })} 
                      </select>
                    </div> */}

                    {/* <div className="col-md-4">
                                            <span className="ml-1">Colour</span>
                                            <Select
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={variantValue2}
                                                value={selectedOption2}
                                                name="category"
                                                required
                                                onChange={handleSelectValue2}
                                            />
                                        </div> */}
                  </div>
                </>
              </div>
              {/* 
<div className="var2">
                
             
                        <div className="select-flex">                          
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    // options={varListParent}
                                    name="category"
                                    // isDisabled={true}

                                    placeholder={"Type to search"}
                                    value={varListParent[1]}

                                    // onInputChange={handleInputChange}
                                    required
                                    // onChange={handleSelect1}
                                />
                            <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={variantValue1}
                                    value={selectedOption1}
                                    name="category"
                                    required
                                    onChange={handleSelectValue1}
                                />
                        </div>
                
              
              
               
</div> */}
              {/* 
<div className="var3">
                
               
                        <div className="select-flex">                          
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    // options={varListParent}
                                    value={varListParent[2]}
                                    // isDisabled={true}

                                    placeholder={"Type to search"}
                                    // onInputChange={handleInputChange}
                                    name="category"
                                    required
                                    // onChange={handleSelect2}
                                />
                            <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={variantValue2}
                                    value={selectedOption2}
                                    name="category"
                                    required
                                    onChange={handleSelectValue2}
                                />
                        </div>
            

                   
              
</div> */}
              {/* <i style={{ fontSize: "13px", color: "red" }}>
                {varError}
                {resMessage}
              </i> */}
            </div>

            {/* <div className="spec">

                            <div className="radio-wrap">



                                <div className="d-flex justify-content-between">
                                    <h4> Specification <b>*</b></h4>
                                    {spec === true ? (<div style={{ marginTop: '12px' }}>
                                        <input type="checkbox" className="mt-1" id="vehicle1" name="vehicle1" onChange={() => setSpe(!spe)} />
                                        <label className="ml-1 label-form" for="vehicle1">Same as Product</label><br />
                                    </div>) : null}

                                </div>

                                <div>
                                    <aside>
                                        <input
                                            type="radio"
                                            name="specification"
                                            value="true"
                                            checked={value1 === "true" ? true : false}
                                            onChange={handleChange1}
                                        />
                                        <span>Yes</span>
                                    </aside>
                                    <aside>
                                        <input
                                            type="radio"
                                            name="specification"
                                            value="false"
                                            checked={value1 === "true" ? false : true}
                                            onChange={handleChange1}
                                        />
                                        <span>No</span>
                                    </aside>
                                </div>
                            </div>

                            <div className="specification">

                                {value1 === "true" ?
                                    <>
                                        {specs && specs.length > 0
                                            ? specs.map((value, index) => {

                                                return (
                                                    <>
                                                        <section>
                                                            <div className="two-field">
                                                                <input
                                                                    placeholder="Title"
                                                                    value={value.name}
                                                                    className={'form-control form-control-nonround'}
                                                                    mandatory={true}
                                                                    onChange={(e) => handleChange2(e, "name", index)}

                                                                />

                                                                <i style={{ fontSize: '13px', color: 'red' }}>{speNameError}</i>
                                                            </div>
                                                            <div className="two-field">
                                                                <input
                                                                    placeholder="Value"
                                                                    value={value.value}
                                                                    className={'form-control form-control-nonround'}
                                                                    mandatory={true}
                                                                    onChange={(e) => handleChange2(e, "value", index)}

                                                                />

                                                                <i style={{ fontSize: '13px', color: 'red' }}>{speValueError}</i>
                                                            </div>
                                                            <div>
                                                                <button className="cross_button" onClick={() =>
                                                                    removespecs(index)}> X</button>
                                                            </div>
                                                        </section>
                                                    </>
                                                )

                                            })
                                            : null}


                                        {specs.length < 10 ? (

                                            <div className="button-variant">
                                                <button onClick={() => addspecs()}>+ADD</button>

                                            </div>) : null}

                                    </>
                                    : null
                                }

                            </div>

                        </div> */}
          </div>

          {/* <div id="short-description">
                    <div className="d-flex justify-content-between">
                        <p  className="label-form">Short Description (maximun-500 words, Word Count:<span className={shortCount<=500 ? "word-counter" :"word-counter-error"} > {shortCount}</span>)<b>*</b></p>
                        <div style={{marginTop:'12px'}}>
                        <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        checked={short}
                        onChange={()=>setShort(!short)}
                        />
                            <label className="ml-1 label-form" for="vehicle1">Same as Product</label><br/>
                            </div>
                    </div>

                        <textarea
                            placeholder="Enter Short Description"
                            name="shortDescription"
                            onChange={handleChange}
                            // maxLength={500}
                            // onInput={maxLengthCheck}
                            // onKeyDown={e => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                        />
                        <i style={{fontSize:'13px',color:'red'}}>{shortError}{shortCountError}</i>
                    </div> */}

          {/* <div id="short-description">
                    <div className="d-flex justify-content-between">
                        <p  className="label-form">Long Description (maximun-2000 words, Word Count:<span className={longCount<=2000 ? "word-counter" :"word-counter-error"}>{longCount} </span>) <b>*</b></p>
                        <div style={{marginTop:'12px'}}>
                        <input
                        type="checkbox"
                        id="vehicle1"
                        onChange={()=>setLong(!long)}
                        />
                    <label className="ml-1 label-form" for="vehicle1">Same as Product</label><br/>
                 </div>
                  </div>
                        <textarea
                            placeholder="Enter Long Description"
                            name="longDescription"
                            onChange={handleChange} 
                        />

                        <i style={{fontSize:'13px',color:'red'}}>{longError} {longCountError}</i>
                    </div> */}

          {/* image uploD */}

          {/* <section className="image-uploaded">
                        {!isFile ?
                            <div class="upload-btn-wrapper mt-2">
                                <button className="uplod-btn">
                                    <img src={uploadIcon} alt="" /><br />
                                Upload Logo
                                
                                </button>
                                <input
                                    type="file"
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.currentTarget.files[0].size >= 250000) {
                                            alert("Images size must be less than 250kb")
                                            return 0
                                        }
                                        setfile(URL.createObjectURL(e.currentTarget.files[0]));
                                        setIsFile(true);
                                        setUrlFile(e.currentTarget.files[0]);
                                    }}
                                />
                                
                            </div> :
                            <aside class="image">
                                <div class="overlay">
                                <label for="file-upload">
                                    <img for="upload-image" class="edit-img" src={editImageIcon} alt=""/>
                                </label>
                                </div>
                                <img class="screen" src={file} alt=""/>
                                
                                 <input
                                    type="file"
                                    id="file-upload"
                                    style={{display:'none'}}
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.currentTarget.files[0].size >= 250000) {
                                            alert("Images size must be less than 250kb")
                                            return 0
                                        }
                                        setfile(URL.createObjectURL(e.currentTarget.files[0]));
                                        setIsFile(true);
                                        setUrlFile(e.currentTarget.files[0]);
                                    }}
                                />
                            </aside>
                        }
                        
                    </section> */}

          {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    onChange={()=>setSameImg(!sameImg)}
                    className='d-end'
                    
                    />
                    <label className="ml-1 label-form" for="vehicle1">Images same as Product</label><br/> */}
        </div>

        <div className="button">
          <button onClick={submitVariant}>Add</button>
        </div>
      </div>
    </Layout>
  );
};

export default AddVariant;
