import React, { useState } from 'react';
import md5 from "md5";
import axios from '../../axiosconfig'
import { Redirect } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';

const ResetPasswordForm = () => {
  const [errorMessage, seterrorMessage] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [resetpassword, setResetPassword] = useState({
    userEmail: '',
    token: '',
    newPassword: '',
    confirmPassword: ""
  });

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }
  const { newPassword, confirmPassword } = resetpassword;

  const handleChange = (e) => {
    setResetPassword({ ...resetpassword, error: false, [e.target.name]: e.target.value });
  };


  const onSubmit = (e) => {
    e.preventDefault();
    setResetPassword({ ...resetpassword, error: false, loading: true });
    if (newPassword !== confirmPassword) {
      seterrorMessage("Password do not match")

    }
    else if (newPassword.length < 6) {
      seterrorMessage("Password too short")

    }
    else {
      let obj = {
        "email": getQueryVariable('email'),
        "token": getQueryVariable('token'),
        // "newPassword": md5(newPassword)
        "newPassword": (newPassword)
      }
      axios.post('/forgotpassword/password-reset', obj).then(response => {
        let res = JSON.parse(response.data)

        if (res.success === true) {
          seterrorMessage(res.message)
        }
        else {
          seterrorMessage(res.message)
        }

      }).catch((error) => {
        console.log(error);
      }).then(() => {
        console.log("-----always executes");
      })
    }
  }

  const closeModal = () => {
    setRedirect(true);
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="reset-form">
          <h3>
            Reset Password
        </h3>
          <h5>Reset Your Pasword</h5>
          <div>
            <p>New Password <b>*</b> </p>
            <input
              type="password"
              placeholder="New Password"
              name="newPassword"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <p>Confirm Password <b>*</b> </p>
            <input
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className="network-error">
        {netError}
      </div> */}
          <div className="error-msg">
            {errorMessage}
          </div>

          <button>Next</button>
        </div>
      </form>
      {redirect ? <Redirect to="/" /> : null}
      {errorMessage === "Password updated successfully" ? <SuccessModal message="Password Change Success, Please Login!" onClick={closeModal} /> : null}

    </>
  )
}

export default ResetPasswordForm