import React, { useEffect, useState } from 'react'
import SearchBox from '../../components/halper/SearchBox';
import deleteIcon from '../../assets/icons/delete.png';
import axiosauth from '../../axios-config-auth';
import Pagination from "react-js-pagination";
import Loader from 'react-loader-spinner';

const BrandVendorList = () => {
    const [activePage, setActivePage] = useState(1);
    const [total, settotal] = useState(0);
    const [collections, setCollections] = useState([]);
    const [showLoader, setShowLoader] = useState(true);



    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": (pageNumber - 1) * 10,
            "sortBy": {
                "type": "datePosted",
                "order": -1
            }
        }
        axiosauth.post('/collection/load-collection', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setCollections(res.data.collection)
                    settotal(res.data.count)

                } else {
                    console.log("else block")
                    setCollections([])
                    settotal(0)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    /**
   * TODO: event handle function when cretae new collections 
   *@version 1.0.0
   * @author [Unknown]
   */



    /**
   * TODO: function for get list of collections
   *@version 1.0.0
   * @author [Unknown]
   */
    // const getCollections = (status) => {
    //     let obj = {
    //         "fields": "max",
    //         "limit": 10,
    //         "count": 0,
    //         "sortBy": {
    //             "type": "datePosted",
    //             "order": -1
    //         },
    //     }
    //     axiosauth.post('/collection/load-collection', obj)
    //         .then(async (response) => {

    //             let res = JSON.parse(response.data)
    //             if (res.success === true) {
    //                 console.log(res.data.collection);
    //                 setCollections(res.data.collection)
    //                 settotal(res.data.count);
    //                 setShowLoader(false);

    //             } else {
    //                 setCollections([])
    //                 settotal(0);
    //                 setShowLoader(false);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // }
    const searching = (search) => {
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": 0,
            "sortBy": {
                "type": "datePosted",
                "order": -1
            },
            "filters": {
                "name": search
            }
        }
        axiosauth.post('/collection/load-collection', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setCollections(res.data.collection)
                    settotal(res.data.count)
                    setShowLoader(false);
                } else {
                    console.log("else block")
                    setCollections([])
                    settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }






    useEffect(() => {
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": 0,
            "sortBy": {
                "type": "datePosted",
                "order": -1
            },
        }
        axiosauth.post('/collection/load-collection', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setCollections(res.data.collection)
                    settotal(res.data.count);
                    setShowLoader(false);
                } else {
                    setCollections([])
                    settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);


    return (
        <div>
            <SearchBox
                placeholder="Search.."
                onChange={(e) => searching(e.target.value)}
            />
            {!showLoader ?
                <div className="table-wrap">
                    <table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Brand Name</th>
                                <th>Vendor Name</th>
                                <th>Brand Logo</th>
                                <th>View Product</th>
                                <th>Status</th>
                                <th>Delete Brand</th>
                                <th>View Brand</th>
                            </tr>
                        </thead>
                        <tbody>

                            {collections && collections.length > 0 ?
                                collections.map((collection, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{collection.name}</td>
                                            <td>Ajay</td>
                                            <td className="collection-img">
                                                <img src={collection.image} alt="" />
                                            </td>
                                            <td>Manage</td>
                                            <td>1</td>
                                            <td>
                                                <img src={deleteIcon} alt="delete" className="delete-icon" />
                                            </td>
                                            <td>eye</td>
                                        </tr>
                                    )
                                })

                                : null}

                        </tbody>
                    </table>

                    <div className="pagination-custom">
                        <Pagination
                            hideDisabled
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            hideNavigation
                            onChange={handlePageChange}
                        />
                    </div>

                </div>
                :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={3000} //3 secs
                    />
                </div>
            }
        </div>
    )
}

export default BrandVendorList
