import React, { useState, useEffect } from 'react';
import axiosauth from '../../axios-config-auth';
import DatePicker from "react-datepicker";
import DatePickerInput from '../../components/halper/DatePickerInput';
import { Link, useHistory } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import searcha from '../../assets/icons/search_icon.png'
import Pagination from "react-js-pagination";
import Select from 'react-select'
import SuccessModal from '../../components/halper/SuccessModal';
import NoData from '../../components/halper/NoData';
import left_arrow from '../../assets/icons/eye.png'
import { confirmAlert } from 'react-confirm-alert';
import Loader from 'react-loader-spinner';

const OrderList = () => {

  const [showLoader, setShowLoader] = useState(true);
  const [pageStatus, setPageStatus] = useState(null)

  const [startDate, setStartDate] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [orders, setOrders] = useState([]);
  const [total, settotal] = useState(0);
  const [dateError, setDateError] = useState({
    startDate:'',
    endDate:''
  });
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }
  const [show, setShow] = useState(false);

  // For page count
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get('page');

  const statusData = [
    { "value": "completed", "label": "Completed" },
    { "value": "requested", "label": "Requested" },
    { "value": "approved", "label": "Approved" },
    { "value": "rejected", "label": "Rejected" },
  ]


  const handlePageChange5 = (pageNumber) => {
    handlePageChange(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };
  const handlePageChange = (pageNumber) => {
    // history.push(`/managevendorbrand?pageCount=${pageNumber}`);
    let filter = {}
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
    var myDate = new Date(startDate);
    myDate.setDate(startDate ? startDate.getDate() + 1 : myDate.getDate() + 1);
    if (pageStatus !== null && startDate !== null) {
      filter = pageStatus
      filter.isReturn = "true"
      filter.date = {
        "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
        "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
      }
      // filter.date = {
      //   "startDate": "1999-01-22",
      //   "endDate": formatDate(myDate)
      // }
    }
    if (pageStatus !== null && startDate === null) {
      filter = pageStatus
      filter.isReturn = "true"
    }
    if (startDate !== null && pageStatus === null) {
      filter.isReturn = "true"
      filter.date = {
        "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
        "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
      }
      // filter.date = {
      //   "startDate": "1999-01-22",
      //   "endDate": formatDate(myDate)
      // }
    }
    if (startDate === null && pageStatus === null) {
      filter.isReturn = "true"
    }
    console.log("first  1")
    let obj = {
      "fields": "max",
      "limit": 10,
      "count": (pageNumber - 1) * 10,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
      "filters": filter,
    }
    // axiosauth.post('/order/return-order', obj)
    axiosauth.post('/order/get-return-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setOrders(res?.data?.orders || [])
          settotal(res?.data?.total || 0);
          // setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  // const handlePageChange5 = (pageNumber) => {
  //   handlePageChange(pageNumber);
  //   let currentUrlParams = new URLSearchParams(window.location.search);
  //   currentUrlParams.set('page', pageNumber);
  //   history.push(window.location.pathname + "?" + currentUrlParams.toString());
  // };


  const datesearching = (date) => {
    // setStartDate(date)
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
    var myDate = new Date(date);
    myDate.setDate(date.getDate() + 1);
    let filter = {
    }
    // console.log("bjhjdjds",pageStatus)
    if (pageStatus !== null) {
      filter.returnStatus = pageStatus.returnStatus
      filter.isReturn = "true"
      filter.date = {
        "startDate": "1999-01-22",
        "endDate": formatDate(myDate)
      }
    }
    else {
      filter = {
        "isReturn": "true",
        "date": {
          "startDate": "1999-01-22",
          "endDate": formatDate(myDate)
        }
      }
    }
    // console.log("bjhjdvernucajds",filter)

    let obj = {
      "fields": "max",
      "limit": 10,
      "count": (pageCount - 1) * 10,
      "filters": filter,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }
    } 
    axiosauth.post('/order/return-order', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("jdkd", res)
        if (res.success === true) {
          setOrders(res.data.orders)
          settotal(res.data.total);
          // setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(()=>{
    if(filterStartDate || startDate){
      if(!filterStartDate){
        setDateError({...dateError,startDate:'Start Date Required...',endDate:''})
        return
      }
      if(!startDate){
        setDateError({...dateError,startDate:'',endDate:'End Date Required...'})
        return
      }
      setDateError({...dateError,startDate:'',endDate:''})

      if(Number(pageCount) !== 1){
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('page', 1);
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
      }

      function formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
  
        return [year, month, day].join('-');
      }

      const manageDate = (date)=>{
        let myDate = new Date(date);
        myDate.setDate(date.getDate() + 1);
      }

      let filter = {
      }
      
      if (pageStatus !== null) {
        filter.returnStatus = pageStatus.returnStatus
        filter.isReturn = "true"
        filter.date = {
          "startDate": formatDate(filterStartDate),
          "endDate": formatDate(startDate)
        }
      }
      else {
        filter = {
          // "isReturn": "true",
          "date": {
            "startDate": formatDate(filterStartDate),
            "endDate": formatDate(startDate)
          }
        }
      }
  
      let obj = {
        // "fields": "max",
        "limit": 10,
        "count": pageCount !== 1 ? 0 : (pageCount - 1) * 10,
        "filters": filter,
        "sortBy": {
          "type": "createdOn",
          "order": -1
        }
      } 
      axiosauth.post('/order/get-return-orders', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          // console.log("jdkd", res)
          if (res.success === true) {
            setOrders(res?.data?.orders || [])
            settotal(res?.data?.total || 0);
            // setShowLoader(false);
          } else {
            setOrders([])
            settotal(0);
            // setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  },[startDate,filterStartDate])



  useEffect(() => {
    let filter = {}
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }

    if (pageStatus !== null && startDate !== null) {
      let myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);
      filter = pageStatus
      filter.isReturn = "true"
      filter.date = {
        "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
        "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
      }
      // filter.date = {
      //   "startDate": "1999-01-22",
      //   "endDate": formatDate(myDate)
      // }
    }
    if (pageStatus !== null && startDate === null) {
      filter = pageStatus
      filter.isReturn = "true"
    }
    if (startDate !== null && pageStatus === null) {
      let myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);
      filter.isReturn = "true"
      filter.date = {
        "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
        "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
      }
      // filter.date = {
      //   "startDate": "1999-01-22",
      //   "endDate": formatDate(myDate)
      // }
    }
    if (startDate === null && pageStatus === null) {
      filter.isReturn = "true"
    }
    let obj = {
      "fields": "max",
      "limit": 10,
      "count": (pageCount - 1) * 10,
      "filters": filter,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }
    } 
    axiosauth.post('/order/get-return-orders' ,obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)

        if (res.success === true) {
          // console.log("response------>>>", res.data)
          setOrders(res?.data?.orders || [])
          settotal(res?.data?.total || 0);
          setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
    // axiosauth.get('/order/return-order' ,obj)
    //   .then(async (response) => {
    //     let res = JSON.parse(response.data)

    //     if (res.success === true) {
    //       // console.log("response------>>>", res.data)
    //       setOrders(res.data)
    //       settotal(res.data.total);
    //       setShowLoader(false);
    //     } else {
    //       setOrders([])
    //       settotal(0);
    //       setShowLoader(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    // eslint-disable-next-line
  }, [pageCount, history]);


  const retuenOrder = () => {
    let obj = {
      "fields": "max",
      "limit": 10,
      "count": (pageCount - 1) * 10,
      "filters": { "isReturn": "true" },
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }
    }
    console.log("444444")
    axiosauth.post('/order/return-order', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("order res--->", res)
        if (res.success === true) {
          setOrders(res.data.orders)
          settotal(res.data.total);
          // setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  // Custom date
  const showDate = (data) => {
    var d = new Date(data);
    var date = d.getDate();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
    var year = d.getFullYear();
    var dateStr = date + " " + months[month] + " " + year;
    // var res =
    return dateStr;
  };


  const searching = (search) => {
    if (search.toString().length > 1) {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('page', 1);
      history.push(window.location.pathname + "?" + currentUrlParams.toString());
      let filter = {}
      function formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        return [year, month, day].join('-');
      }

      if (pageStatus !== null && startDate !== null) {
        var myDate = new Date(startDate);
        myDate.setDate(startDate.getDate() + 1);
        filter = pageStatus
        filter.isReturn = "true"
        filter.date = {
          "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
          "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
        }
        // filter.date = {
        //   "startDate": "1999-01-22",
        //   "endDate": formatDate(myDate)
        // }
        filter.orderId = search
      }
      if (pageStatus !== null && startDate === null) {
        filter = pageStatus
        filter.isReturn = "true"
        filter.orderId = search

      }
      if (startDate !== null && pageStatus === null) {
        let myDate = new Date(startDate);
        myDate.setDate(startDate.getDate() + 1);
        filter.isReturn = "true"
        filter.date = {
          "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
          "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
        }
        // filter.date = {
        //   "startDate": "1999-01-22",
        //   "endDate": formatDate(myDate)
        // }
        filter.orderId = search

      }
      if (startDate === null && pageStatus === null) {
        filter.isReturn = "true"
        filter.orderId = search

      }
      let obj = {
        "fields": "max",
        "limit": 10,
        "count": (pageCount - 1) * 10,
        "filters": filter,
        "sortBy": {
          "type": "createdOn",
          "order": -1
        }
      } 
      // axiosauth.post('/order/return-order', obj)
      axiosauth.post('/order/get-return-orders', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          // console.log("jdkd", res)
          if (res.success === true) {
            setOrders(res?.data?.orders || [])
            settotal(res?.data?.total || 0);
            // setShowLoader(false);
          } else {
            setOrders([])
            settotal(0);
            // setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
    else {
      let filter = {}
      function formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        return [year, month, day].join('-');
      }

      if (pageStatus !== null && startDate !== null) {
        let myDate = new Date(startDate);
        myDate.setDate(startDate.getDate() + 1);
        filter = pageStatus
        filter.isReturn = "true"
        filter.date = {
          "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
          "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
        }
        // filter.date = {
        //   "startDate": "1999-01-22",
        //   "endDate": formatDate(myDate)
        // }
        filter.orderId = search
      }
      // console.log("dnhnds",pageStatus)
      if (pageStatus !== null && startDate === null) {
        filter = pageStatus
        filter.isReturn = "true"
        filter.orderId = search

      }
      //  console.log("roganfilter",filter)
      if (startDate !== null && pageStatus === null) {
        let myDate = new Date(startDate);
        myDate.setDate(startDate.getDate() + 1);
        filter.isReturn = "true"
        filter.date = {
          "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
          "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
        }
        // filter.date = {
        //   "startDate": "1999-01-22",
        //   "endDate": formatDate(myDate)
        // }
        filter.orderId = search

      }
      if (startDate === null && pageStatus === null) {
        filter.isReturn = "true"
        filter.orderId = search

      }
      let obj = {
        "fields": "max",
        "limit": 10,
        "count": (pageCount - 1) * 10,
        "filters": filter,
        "sortBy": {
          "type": "createdOn",
          "order": -1
        }
      } 
      // axiosauth.post('/order/return-order', obj)
      axiosauth.post('/order/get-return-orders', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          // console.log("jdkd", res)
          if (res.success === true) {
            setOrders(res?.data?.orders || [])
            settotal(res?.data?.total || 0);
            // setShowLoader(false);
          } else {
            setOrders([])
            settotal(0);
            // setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }

  }


  const handleSelect = (selectedOption) => {
    setPageStatus({
      "returnStatus": (selectedOption.value === "completed" ? selectedOption.value : selectedOption.value === "requested" ? selectedOption.value : selectedOption.value === "approved" ? selectedOption.value : "rejected")
    })
    let filter = {}
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }

    if (startDate !== null) {
      var myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);
      filter = {
        "isReturn": "true",
        "returnStatus": (selectedOption.value === "completed" ? selectedOption.value : selectedOption.value === "requested" ? selectedOption.value : selectedOption.value === "approved" ? selectedOption.value : "rejected"),
        "date": {
          "startDate": filterStartDate ? formatDate(filterStartDate) : "1999-01-22",
          "endDate": startDate ? formatDate(startDate) :formatDate(myDate)
        },
        // "date": {
        //   "startDate": "1999-01-22",
        //   "endDate": formatDate(myDate)
        // },
      }
    }
    else {
      filter = {
        "isReturn": "true",
        "returnStatus": (selectedOption.value === "completed" ? selectedOption.value : selectedOption.value === "requested" ? selectedOption.value : selectedOption.value === "approved" ? selectedOption.value : "rejected"),

      }
    }
    let obj = {
      "fields": "max",
      "limit": 10,
      "count": (pageCount - 1) * 10,
      "filters": filter,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }
    }
    // axiosauth.post('/order/return-order', obj)
    axiosauth.post('/order/get-return-orders', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("order res--->", res)
        if (res.success === true) {
          setOrders(res?.data?.orders || [])
          settotal(res?.data?.total || 0);
          setShowLoader(false);
        } else {
          setOrders([])
          settotal(0);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })


  }






  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      /* Toggle between adding and removing the "active" class,
      to highlight the button that controls the panel */
      this.classList.toggle("active");

      /* Toggle between hiding and showing the active panel */
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }



  const closeModal = () => {
    setShow(false);
  }



  return (
    <>

      {show === true ? <SuccessModal message="Order Status Changed!" onClick={closeModal} /> : null}

      <div className="row">
        <div className="col-6">
          <div className="search-box">
            <img src={searcha} alt="search-icon" />
            <input type="text"
              placeholder={"Search By Order Id"}
              // onChange={onChange}
              onChange={(e) => searching(e.target.value)}
            />
          </div>
        </div>


        <div className="col-3">
          <div className="p-2 datePicorder2">
            <DatePicker
              selected={filterStartDate}
              onChange={date => setFilterStartDate(date)}
              // onChange={date => datesearching(date)}
              customInput={<DatePickerInput place={"Select Start Date"} value={filterStartDate} isRound={true} />}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
            {dateError?.startDate ? <p style={{color:'red'}}>{dateError?.startDate}</p>:''}
          <div className="p-2 datePicorder2">
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              // onChange={date => datesearching(date)}
              customInput={<DatePickerInput place={"Select End Date"} value={startDate} isRound={true} />}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
            {dateError?.endDate ? <p style={{color:'red'}}>{dateError?.endDate}</p>:''}

        </div>
        <div className="col-3">
          {/* <div className="search-box p-2"> */}
          <div className="statusSelect">

            <Select
              placeholder={'Status'}
              closeMenuOnSelect={true}
              options={statusData}
              onChange={handleSelect}

            />
          </div>

        </div>
      </div>


      {/* ================================================ */}

      {!showLoader ?
        <>
          <div className="acc ">

            <div className="row m-0 py-2 order-head" style={{ width: '100%' }}>

              <div className="col-1">
                Sr.No.
              </div>

              <div className="col-2">
                OrderId
              </div>

              <div className="col-1">
                Order Total
              </div>

              <div className="col-2">
                Customer Name

              </div>

              <div className="col-2">
                Date
              </div>
              <div className="col-2">
              Request For
              </div>

              <div className="col-1">
               Status
              </div>

              <div className="col-1">
                Action

              </div>
            </div>



            {orders && orders.length > 0 ?

              orders.map((order, index) => {
                return (
                  <>


                    <div className="tabs" key={index}>
                      <div className="tab">
                        {console.log("Order=====>>" , order)}
                        <input type="checkbox" id={index} />
                        <label className="tab-label" htmlFor={index}>

                          <div className="row m-0 p-0 " style={{ width: '100%' }}>

                            <div className="col-1">
                              {((pageCount ? pageCount : 1) - 1) * 10 + (index + 1)}
                            </div>

                            <div className="col-2">
                              {order?.order?.orderId}
                            </div>

                            <div className="col-1">
                              <span>&#8377;</span>{order?.order?.total?.toFixed(2)}
                            </div>

                            <div className="col-2">
                              {order?.order?.address && order?.order?.address?.length ? order?.order?.address[0]?.firstName : ''}
                              {/* {order.createdBy !== null ? (order?.createdBy?.firstName) : ""} {order.createdBy !== null ? (order.createdBy.lastName) : ""} */}
                            </div>

                            <div className="col-2">
                              {showDate(order?.createdOn)}
                            </div>
                            <div className="col-2">
                               {order.refundFor && "Return" }
                               {order.exchangeForDiffrent  && "Exchange"}
                            </div>

                            <div className={`col-1 ${order?.adminExchangeConfirm || order?.adminReturnConfirm ? 'approved-status' : order?.cancelledByAdmin ? 'rejected-status' :'requested-status'}`}>
                               {order?.adminExchangeConfirm || order?.adminReturnConfirm ? 'Approved' : order?.cancelledByAdmin ? 'Rejected' :'Requested'}
                            </div>

                            <div className="col-1">

                              

                              {/* <span className="isShipped">{order.isShipped ===true && order.isDelivered===false && order.isCancelled===false ? "Shipped":
                  <span className="isDelivered">{order.isShipped ===true && order.isDelivered===true && order.isCancelled===false ? "Delivered":
                    <span className="isCancelled">{order.isShipped ===true && order.isDelivered===true && order.isCancelled===true ? "Cancelled":
                      <span className="isProgress">In Progress</span>
                      }
                    </span>
                    }
                  </span>
                  }
                </span> */}
                <Link to={`/return-order-details/${order._id}`}>
                              <img src={left_arrow} alt="" className="return-arrow-icon"  />
                              </Link>
                              {/* Arr */}
                            </div>


                          </div>

                        </label>


                        {/* <div className="tab-content">


                          <div className="row m-0 py-2 order-product-head" style={{ width: '100%' }}>

                            <div className="col-1">
                              Sr.No.
                            </div>

                            <div className="col-3">
                              Name
                            </div>

                            <div className="col-2">
                              Price X Quan.
                            </div>

                            <div className="col-2">
                              Total

                            </div>

                            <div className="col-2">
                              Status
                            </div>

                            <div className="col-2">

                            </div>


                          </div>

                          {order.products && order.products.length > 0 ?
                            order.products.map((product, index) => {

                              const handleChange = (e) => {

                                confirmAlert({
                                  // title: 'Change Status',
                                  message: 'Are you sure you want to Change Status ?',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => {

                                        let obj = {
                                          "orderId": order.orderId,
                                          "productId": (product.productId ? product.productId._id : null),
                                          "returnStatus": (e.target.value === "approved" ? "approved" : e.target.value === "completed" ? "completed" : "rejected"),
                                          "isReturn": (e.target.value === "approved" ? true : e.target.value === "completed" ? true : false),
                                        }



                                        axiosauth.post('/order/update-order-product', obj)
                                          .then(async (response) => {
                                            let res = JSON.parse(response.data)
                                            if (res.success === true) {
                                              retuenOrder();
                                              setShow(true);
                                            } else {
                                            }
                                          })
                                          .catch((error) => {
                                            console.log(error);
                                          })
                                      }
                                    },
                                    {
                                      label: 'No',
                                      onClick: () => console.log("Hello")
                                    }
                                  ]
                                });

                              }

                              return (
                                <div className="row m-0 py-2 order-product-head1" style={{ width: '100%' }} key={index}>
                                  {console.log("product---->>", product)}
                                  <div className="col-1">
                                    {index + 1}
                                  </div>

                                  <div className="col-3">
                                    {product.productId !== null ? product.productId.productCatalogue.name : null}
                                    <small>
                                      {product.productId.selectedVariant.variant[0] ? product.productId.selectedVariant.variant[0].parentVariant.variantName : ""}
                                      {product.productId.selectedVariant.variant[0] ? "-" : ""}
                                      {product.productId.selectedVariant.variant[0] ? product.productId.selectedVariant.variant[0].value : ""}</small><br></br>
                                    <small>{product.productId.selectedVariant.variant[1] ? " " + product.productId.selectedVariant.variant[1].parentVariant.variantName : ""}
                                      {product.productId.selectedVariant.variant[1] ? "-" : ""}
                                      {product.productId.selectedVariant.variant[1] ? product.productId.selectedVariant.variant[1].value : ""}</small>{product.productId.selectedVariant.variant[1] ? (<br></br>) : null}
                                    <small> {product.productId.selectedVariant.variant[2] ? " " + product.productId.selectedVariant.variant[2].parentVariant.variantName : ""}
                                      {product.productId.selectedVariant.variant[2] ? "-" : ""}
                                      {product.productId.selectedVariant.variant[2] ? product.productId.selectedVariant.variant[2].value : ""}</small>
                                    {product.productId.selectedVariant.variant[2] ? (<br></br>) : null}
                                  </div>

                                  <div className="col-2">
                                    {product?.price} X {product?.quantity}
                                  </div>

                                  <div className="col-2">
                                    {product?.price}

                                  </div>

                                  <div className="col-2">


                                    <span className="isProgress text-capitalize"> {product?.returnStatus}</span>

                                    <span className="isShipped">{product.isShipped === true && product.isDelivered === false && product.isCancelled === false ? "Shipped" :
                                      <span className="isDelivered">{product.isShipped === true && product.isDelivered === true && product.isCancelled === false ? "Delivered" :
                                        <span className="isCancelled">{product.isShipped === true && product.isDelivered === true && product.isCancelled === true ? "Cancelled" :
                                          <span className="isProgress">In Progress</span>
                                        }
                                        </span>
                                      }
                                      </span>
                                    }
                                    </span>

                                  </div>


                                  <div className="col-2">
                                    <div className="statusSelectSingle">

                                      <select onChange={handleChange} style={{ paddingLeft: '0px' }}>
                                        <option value="" selected disabled hidden>{capitalize(product.returnStatus)}</option>
                                        <option value="approved" hidden={product.returnStatus === "approved" || product.returnStatus === "completed" || product.returnStatus === "rejected" ? true : false} disabled={product.returnStatus === "approved" ? true : false}>Approved</option>
                                        <option value="completed" hidden={product.returnStatus === "completed" || product.returnStatus === "requested" || product.returnStatus === "rejected"} disabled={product.returnStatus === "completed" ? true : false}>Completed</option>
                                        <option hidden={product.returnStatus === "completed" || product.returnStatus === "approved" ? true : false || product.returnStatus === "rejected" ? true : false} value="rejected">Rejected</option>
                                      </select>

                                    </div>

                                  </div>

                                </div>
                              )
                            })
                            : null}
                        </div> */}
                      </div>

                    </div>


                  </>
                )

              })


              :
              <NoData message="Data Not Found" />
            }




          </div>

          <div className="order-accordian order-acc">

            <div className="pagination-custom">
              <Pagination
                hideDisabled
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                hideNavigation
                onChange={handlePageChange5}
              />
            </div>
          </div>

        </>

        :
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div>
      }
    </>
  )
}

export default OrderList