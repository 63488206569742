import React, { useState, useEffect, useRef } from 'react';
import Layout from "../../layout/layout"
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DatePickerInput from '../../components/halper/DatePickerInput';
import axiosauth from '../../axios-config-auth';
import { AsyncPaginate } from 'react-select-async-paginate';
import SuccessModal from '../../components/halper/SuccessModal';
import { useHistory } from 'react-router-dom';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import makeAnimated from 'react-select/animated';
import country from '../../country.json'

const EditProductVariant = () => {
  const myRefname = useRef(null);
  // const [value, setValue] = useState(null);
  const [value19, setValue19] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  // const [gst, setgst] = useState(0)
  const [countryList, setCountryList] = useState([])
  const [productId, setProductId] = useState("");
  const [productCode, setProductCode] = useState("")
  // const [isDisplay, setIsDisplay] = useState("false")
  // const [isExchangeable, setIsExchangeable] = useState("false")
  // const [COD, setCOD] = useState("false");
  const [sku, setSKU] = useState("")
  const [mrp, setMRP] = useState("")
  const [price, setPrice] = useState("")
  const [totalQuantity, setTotalQuantity] = useState()
  // const [allExplore , setAllExplore] = useState([])
  // const [exploreuse , setExploreuse] = useState([]);
  // const [selectExplore, setSelectExplore] = useState([]);
  // const [shippingCharge , setShippingCharge] = useState();


  const history = useHistory();
  const [total2, settotal2] = useState(0)

  const animatedComponents = makeAnimated()
  // const handleSelectGst = (selectedOption, index) => {
  //   handleChange2(selectedOption.label, "type", index)

  //   handleChange2(selectedOption.value, "age", index)
  // }
  const handleSelectCountry = (selectedOption, index) => {

    handleChange2(selectedOption.value, "country", index)
  }
  // const gstList = [
  //   { value: "0-1", label: "Upto 1 Years" },
  //   { value: "0-2", label: "Upto 2 Years" },
  //   { value: "3-4", label: "3-4 Years" },
  //   { value: "5-7", label: "5-7 Years" },
  //   { value: "8-11", label: "8-11 Years" },

  //   { value: "12-100", label: "12 years above" },

  // ]


  /**
* TODO: function use for handle navigation
*@version 1.0.0
* @author [ Unknown]
*/
  // eslint-disable-next-line
  // const handleClick = () => {
  //   // myRefname.current.focus();
  //   myRefname.current.dispatchEvent(
  //     new MouseEvent('click', {
  //       view: window,
  //       bubbles: true,
  //       cancelable: true,
  //       buttons: 1,
  //     }),
  //   );
  // }



  const [catalogueerror, setcatalogueerror] = useState("")




  const [error, seterror] = useState("")


  // option's api call ::
  const [specs, setspecs] = useState([{
    "units": "",
    "value": "",
    "sku": "",
    "mrp": "",
    "price": "",
    "shippingCharge":"",
    "modelNumber": "",
    "hsnCode": "",
    "country": "",
    "age": "",
    "length": "",
    "breadth": "",
    "height": "",
    "mobile": "",
    "address": "",
    "email": "",
    "isReturnable": false,
    "isExchangable": false,
    showExpiry: false,
    "priceSecondaryDisplay": false,
    "cod": false,
    "essentialItem":false,
    "returnDays": "",
    "type": "",
    "date": new Date(),
    "dateToPass": "",

    "expiryDate": "",
    "dateToPass2": "",

  }])
  /**
* TODO: function use for removing specification row
*@version 1.0.0
* @author [ Unknown]
*/
  const removespecs = (index) => {
    const temp = [...specs];

    const filter = temp.filter((item, i) => i !== index)

    setspecs(filter)

  } 
  /**
* TODO: function use for creating new product 
*@version 1.0.0
* @author [ Unknown]
*/
  const submit = () => {
    // debugger; 
    
    if (parseInt(price) > parseInt(mrp)) {
      seterror(true)
      alert("Offer Price must be less than MRP")
      return 0
    }

    if (sku.length < 2) {
      seterror(true)
      alert("Please Check SKU ")
      return 0
    }

    // var explore =[];
    // for (var j = 0; j < selectExplore?.selectedOption?.length; j++) { 
    //   explore.push(selectExplore.selectedOption[j].value);
    // }


    var product = []
    for (var x = 0; x < specs.length; x++) { 
      product.push({
        // "businessId": localStorage.getItem("rohanbusiness"),
        "productId": localStorage.getItem("pid"),
        "manufacturerAddress": specs[x].address,

        "productCatalogue": value19.value,
        "brand": localStorage.getItem("bid"),
        "selectedVariant": specs[x].value.value,
        "modelNo": {
          productId: productId,
          productCode: productCode
        },
        "ageGroup": {
          "minAge": parseInt(specs[x].age.split("-")[0]),
          "maxAge": parseInt(specs[x].age.split("-")[1])
        },
        "dimensions": {
          length: specs[x].length,
          breadth: specs[x].breadth,
          height: specs[x].height
        },
        "sku": sku,
        "expiryDate": specs[x].expiryDate !==null ? specs[x].expiryDate : "",
        "manufacturerDate" :specs[x].date,

        "price": parseInt(price),
        "priceWithoutTax": parseInt(specs[x].price),
        "shippingCharge":parseInt(specs[x]?.shippingCharge),
        "offer": parseInt(price),
        "hsnCode": specs[x].hsnCode,
        "countryOfOrigin": specs[x].country,
        "mrp": parseInt(mrp),
        "netQuantity": totalQuantity,
        "stock": parseInt(specs[x].units),
        "manufacturerEmail": specs[x].email,
        "manufacturerPhone": specs[x].mobile,
        "returnDays": specs[x].returnDays,
        "isReturnable": specs[x].isReturnable,
        "isExchangable": specs[x].isExchangable,
        "priceSecondaryDisplay": specs[x].priceSecondaryDisplay,
        "cod": specs[x].cod,
        "essentialItem":specs[x].essentialItem,
        "status": "new like",
        "minOrder": 2,
        "available": "yes available",
        "gst": "gst",
        "explore" :[],

        // "geoLocation": {
        //     "type": "location",
        //     "coordinates": [
        //         35,
        //         35
        //     ]
        // }
      })
    }

    axiosauth.post("/products/update-product", product[0]).then(response => {

      let res = JSON.parse(response.data)

      if (res.message === "Product updated successfully") {
        setOpenSuccess(true);

      }
      else {

        setcatalogueerror(res.message)
      }

    }).catch((error) => {
      console.log(error);
    }).then(() => {
      console.log("-----always executes");
    })
  }

  // useEffect(()=>{
  //   axiosauth
  //   .post("/explore/load-Explore", { limit: 1000 })
  //   .then(async (response) => { 
  //       let res = JSON.parse(response.data);
        
  //       if (response.status === 200) { 
  //         setAllExplore(res.load); 
  //       } else {
  //           // setShowLoader(false);
  //       }
  //   })
  //   .catch((error) => {
  //       console.log(error);
  //   });
  // } ,[])

  useEffect(() => {
    var cnty = []
    for (var i = 0; i < country.length; i++) {
      cnty.push({
        value: country[i].name,
        label: country[i].name
      })
    }
    setCountryList(cnty)
    // setgst(localStorage.getItem("gst"))
    // setValue({
    //   label: localStorage.getItem("bname"),
    //   value: localStorage.getItem("pbrand")
    // })
    setValue19({
      label: localStorage.getItem("pname"),
      value: localStorage.getItem("pobj")
    })
    var slug;
    let obj = {
      productId: localStorage.getItem("pid")
    }


    axiosauth
      .post("/products/load-by-id", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        // console.log("rjsj", parseInt(res.data.product.ageGroup.minAge),parseInt(res.data.product.maxAge));
        if (res.success === true) {

          
          let e = new Date(res.data.product.manufacturerDate)
          let e2 = new Date(res.data.product?.expiryDate)
          let lbl = ""
          if (res.data.product.selectedVariant.variant[0]) {
            lbl += res.data.product.selectedVariant.variant[0].parentVariant.variantName + " " + res.data.product.selectedVariant.variant[0].value + " "
          }
          if (res.data.product.selectedVariant.variant[1]) {
            lbl += res.data.product.selectedVariant.variant[1].parentVariant.variantName + " " + res.data.product.selectedVariant.variant[1].value + " "
          }
          if (res.data.product.selectedVariant.variant[2]) {
            lbl += res.data.product.selectedVariant.variant[2].parentVariant.variantName + " " + res.data.product.selectedVariant.variant[2].value + " "
          }
          var age = ""
          var Type = ""
          

          setProductCode(res.data.product.modelNo?.productCode)
          setProductId(res.data.product.modelNo?.productId)

          // productId(res.data.product.modelNo?.productId) 

          // setCOD(res.data.product.cod)
          // setIsExchangeable(res.data.product.isExchangable)
          setSKU(res.data.product.sku)
          setMRP(res.data.product.mrp)
          setPrice(res.data.product.price)
          // setShippingCharge(res.data.product.shippingCharge)
          setTotalQuantity(res.data.product.netQuantity) 

          // if (res.data.product.explore&& res.data.product.explore?.length !== 0) {
          //   let explorelist = [];
          //   for (let a = 0; a < res.data.product.explore.length; a++) {
          //     let index;
          //     for (let x = 0; x < allExplore?.length; x++) { 
                
          //       if (allExplore[x]._id === res.data.product.explore[a]) { 
          //         index = x;
          //       }
          //     } 
          //     explorelist.push({
          //       value: allExplore[index]?._id,
          //       label: allExplore[index]?.name,
          //     });
          //   }
          //   console.log(explorelist)
          //   setExploreuse(explorelist); 
          // }

          setspecs([{
            "units": res?.data?.product.stock || '',
            "value": {
              "label": lbl,
              "value": res?.data?.product?.selectedVariant?._id
            },
            "length": res?.data?.product?.dimensions?.length || '',
            "breadth": res?.data?.product?.dimensions?.breadth || '',
            "height": res?.data?.product?.dimensions?.height || '',
            "sku": res?.data?.product?.sku || '',
            "hsnCode": res?.data?.product?.hsnCode || '',
            "country": res?.data?.product?.countryOfOrigin || '',
            "age": age || '',
            "type": Type || '',
            "address": res?.data?.product?.manufacturerAddress || '',
            "email": res?.data?.product?.manufacturerEmail || '',
            "mobile": res?.data?.product?.manufacturerPhone || '',
            "returnDays": res?.data?.product?.returnDays || '',
            "isReturnable": res?.data?.product?.isReturnable || false,
            "isExchangable": res?.data?.product?.isExchangable || false,
            "priceSecondaryDisplay": res?.data?.product?.priceSecondaryDisplay || false,
            "cod": res?.data?.product?.cod || false,
            "essentialItem":res?.data?.product?.essentialItem || false,

            "mrp": res?.data?.product?.mrp || '',
            // "modelNumber":res.data.product.modelNo,
            "modelNumber": {
              "productId": productId,
              "productCode": productCode,
            },
            "price": res?.data?.product?.priceWithoutTax || '',
            "shippingCharge" : res?.data?.product?.shippingCharge || '',
            "date": new Date(res?.data?.product?.manufacturerDate),
            "dateToPass": e.getFullYear() + "-" + ((e.getMonth() > 8) ? (e.getMonth() + 1) : ('0' + (e.getMonth() + 1))) + "-" + ((e.getDate() > 9) ? (e.getDate()) : ('0' + (e.getDate()))),
            
            "expiryDate": res.data.product?.expiryDate !==null ? new Date(res.data.product?.expiryDate) :"",
            "dateToPass2": e2.getFullYear() + "-" + ((e2.getMonth() > 8) ? (e2.getMonth() + 1) : ('0' + (e2.getMonth() + 1))) + "-" + ((e2.getDate() > 9) ? (e2.getDate()) : ('0' + (e2.getDate())))
           
          }]);
        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);
  // const[value,setValue]=useState(null)
  /**
* TODO: function use for handling name value change of specification
*@version 1.0.0
* @author [ Unknown]
*/
  // eslint-disable-next-line
  const handleChange2 = (e, name, index) => {
    seterror(false) 
    if (name === "isReturnable") { 

      const value = !e
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0
    }
    if (name === "isExchangable") { 
      const value = !e
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0
    }
    if (name === "showExpiry") { 
      const value = !e
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0
    }
    if (name === "priceSecondaryDisplay") {
      const value = !e
      const list = [...specs];
      list[index][name] = value;
      setspecs(list)
      return 0
    }
    if (name === "cod") {
      const value = !e
      const list = [...specs];
      list[index][name] = value;
      setspecs(list)
      return 0;

    }
    if (name === "essentialItem") {
      const value = !e
      const list = [...specs];
      list[index][name] = value;
      setspecs(list)
      return 0;

    }
    if (name === "value") {
      const value = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
    }
    else if (name === "country") {
      const value = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);

    }
    else if (name === "age") {
      const value = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);

    }
    else if (name === "type") {
      const value = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);

    }
    else if (name === "date") {
      let datetoPass = e.getFullYear() + "-" + ((e.getMonth() > 8) ? (e.getMonth() + 1) : ('0' + (e.getMonth() + 1))) + "-" + ((e.getDate() > 9) ? (e.getDate()) : ('0' + (e.getDate())))

      const value = e;
      const list = [...specs];
      list[index][name] = value;
      list[index]["dateToPass"] = datetoPass
      setspecs(list);
    }
    else if (name === "expiryDate") {
      let datetoPass = e.getFullYear() + "-" + ((e.getMonth() > 8) ? (e.getMonth() + 1) : ('0' + (e.getMonth() + 1))) + "-" + ((e.getDate() > 9) ? (e.getDate()) : ('0' + (e.getDate())))

      const value = e;
      const list = [...specs];
      list[index][name] = value;
      list[index]["dateToPass"] = datetoPass
      setspecs(list);
    }
    else {
      const { value } = e.target;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
    }


  };
  // const modalClose = () => {

  //   handleClick()
  // }

  // const handleExplore = (selectedOption) => { 
  //   setSelectExplore({ selectedOption });
  //   setExploreuse(selectedOption);
  // };

  // const explore_list = [];
  // if (allExplore) {
  //   for (var k = 0; k < allExplore.length; k++) {
  //     explore_list.push({ value: allExplore[k]._id, label: allExplore[k].name });
  //   }
  // }


  async function loadOptions2(search, loadedOptions, { page }) {
    let newarray = []
    let filtered
    let has_more = true
    let obj = {
      "fields": "max",
      "limit": 10,
      "count": (page - 1) * 10,
      "businessId": JSON.parse(localStorage.getItem("userData")).userId,

      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
      "filters": {
        "name": search,
        "baseProduct": value19.value,
        "unique": true

      }
    }

    if (loadedOptions.length === 0 || loadedOptions.length < total2) {
      await axiosauth.post('/product-variant/load', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          if (res.success === true) {
            if (res.total > 0)
              settotal2(res.total)
            // var filter= product.imageUrls.filter((item) => item !== null);

            for (var i = 0; i < res.data.length; i++) {
              let lbl = ""
              if (res.data[i].variant[0]) {
                lbl += res.data[i].variant[0].parentVariant.variantName + " " + res.data[i].variant[0].value + " "
              }
              if (res.data[i].variant[1]) {
                lbl += res.data[i].variant[1].parentVariant.variantName + " " + res.data[i].variant[1].value + " "
              }
              if (res.data[i].variant[2]) {
                lbl += res.data[i].variant[2].parentVariant.variantName + " " + res.data[i].variant[2].value + " "
              }
              newarray.push({
                label: lbl,
                value: res.data[i]._id
              })
            }
            var sarray = []
            for (var j = 0; j < specs.length; j++) {
              sarray.push(specs[j].value.value)
            }
            filtered = newarray.filter(function (item) {
              return sarray.indexOf(item.value) === -1
            }); 
          } else {
            has_more = false
          }
        })
        .catch((error) => {
          console.log(error);
        })

    }

    const responseJSON = {
      results: filtered,
      has_more: filtered.length > 0 ? has_more : false,
    };

    return {
      options: responseJSON.results,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  }

  const shouldLoadMore = () => {
    // const bottomBorder = (scrollHeight - clientHeight) / 2;

    return true
  };

  const [redirect, setRedirect] = useState(false);
  const closeModal = () => {
    setRedirect(true);
  }
 
  const onlyNumberAllow = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const SKUInputHandle = (e) => {
    const re = /[a-zA-Z0-9 ]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  return (
    <Layout>


      {/* {redirect ? <Redirect to="/products-variant?page=1" /> : null} */}
      {redirect ? history.goBack() : null}
      {openSuccess ? <SuccessModal message="Variant Updated!" onClick={closeModal} /> : null}


      {/* <div className="form-header">
            <h4>Edit Product Variant</h4>
          </div> */}
      <br />
      <br />

      <div className="left-arrow2 back-arrow"
        style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
        <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
        <h2>Edit Product Variant</h2>
      </div>

      <div id="select-tag">
        <p>Select Brand <b>*</b></p>
        <Select
          closeMenuOnSelect={false}
          // components={animatedComponents}
          value={{ label: localStorage.getItem("bname"), value: localStorage.getItem("bname") }}
          isDisabled

        />
      </div>

      <div id="select-tag">
        <p>Select Catalogue <b>*</b></p>
        <Select
          closeMenuOnSelect={false}
          // components={animatedComponents}
          // options={colourOptions}
          isDisabled
          value={{ label: localStorage.getItem("pname"), value: localStorage.getItem("pname") }}

        />
      </div>

      <div>
        {specs && specs.length > 0
          ? specs.map((value, index) => {

            return (

              <div className="form-wrap">



                <div className="form-header2">
                  <h4>Select Variant Product-{index + 1} {index !== 0 ? (<span style={{ color: 'red', float: 'right', cursor: 'pointer' }} onClick={() => removespecs(index)}>X</span>) : null}</h4>
                </div>

                <div className="form-body">

                  <div id="select-tag">
                    <p>Select Variant <b>*</b></p>
                    <AsyncPaginate
                      cacheUniqs={[value, value19, specs]}
                      value={specs[index].value}
                      placeholder={"Select/Search Variant"}
                      loadOptions={loadOptions2}
                      onChange={(e) => {
                        handleChange2(e, "value", index)
                      }}
                      shouldLoadMore={shouldLoadMore}
                      additional={{
                        page: 1,
                      }}
                    />

                    {error === true && specs[index].value === "" ? <i className="error">Please Select Variant</i> : null}
                  </div>

                  <section>
                    <div className="two-field ">
                      <p>Product Id<b></b></p>
                      <div className='d-flex'>
                        <select class="form-select" aria-label="Default select example"
                          onChange={(e) => {
                            setProductId(e.target.value)
                          }}
                          value={productId}
                        >
                          <option selected>Open this select menu</option>
                          <option value="EAN-13">EAN-13</option>
                          <option value="UPC-12">UPC-12</option>
                          <option value="ISBN-13">ISBN-13 </option>
                          <option value="FSSAI-LIC">FSSAI LIC NO.</option>
                          <option value="MPN">MPN </option>
                        </select>

                        <input type="text"
                          value={productCode.toUpperCase()}
                          onChange={(e) => setProductCode(e.target.value)}
                          placeholder="Enter Product Id"
                          onKeyPress={(e) => SKUInputHandle(e)}
                          // maxLength={12}
                          maxLength={productId === "UPC-12" ? 12 : productId === "ISBN-13" || productId === "EAN-13" ? 13 : 20}

                        />
                      </div>
                    </div>
                    <div className="two-field">

                      <p>SKU<b>*</b></p>
                      <input type="text"
                        id="sku"
                        name="sku"
                        value={sku.toUpperCase()}
                        onChange={(e) => setSKU(e.target.value)}
                        onKeyPress={(e) => SKUInputHandle(e)}
                        placeholder="Enter SKU"
                        minLength={8}
                        maxLength={30}
                      />
                      {/* {error === true && specs[index].sku === "" && <i className="error">Please Enter Sku</i>} */}


                    </div>




                    {/* <div className="two-field">
                          <p>MODEL NUMBER<b>*</b></p>
                             <input type="text"
                               id="sku"
                               name="sku"
                               value={specs[index].modelNumber}
                               onChange={(e) => handleChange2(e, "modelNumber", index)}
                               placeholder="Enter Model Number"
                               />

                          {error===true&&specs[index].modelNumber==="" && <i className="error">Please Enter Model Number</i>}
                          </div>  */}

                  </section>



                  <section>
                    <div className="two-field">
                      <p>Units<b>*</b></p>
                      <input type="number"
                        id="units"
                        placeholder="Enter Units"
                        name="units"
                        // value={specs[index].units}
                        value={totalQuantity}
                        onChange={(e) => setTotalQuantity(e.target.value)}
                        // onChange={(e) => handleChange2(e, "units", index)}
                        onKeyPress={(e) => onlyNumberAllow(e)}
                        min="0"

                      />

                      {error === true && specs[index].units === "" && <i className="error">Please Enter Units</i>}
                    </div>

                    <div className="two-field">
                      <p>MRP<b>*</b></p>
                      <input type="number"
                        id="mrp"
                        name="mrp"
                        value={mrp}
                        onChange={(e) => setMRP(e.target.value)}
                        onKeyPress={(e) => onlyNumberAllow(e)}
                        placeholder="Enter MRP"
                        min="0"
                      />


                      {/* {error === true && specs[index].mrp === "" && <i className="error">Please Enter Mrp</i>} */}

                    </div>

                  </section>
                  <section>
                    <div className="two-field">

                      <p>OFFER PRICE<b>*</b>

                      </p>
                      <input
                        type="number"
                        // value={specs[index].price}
                        value={price}
                        // onChange={(e) => handleChange2(e, "price", index)}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="Enter Price"
                        onKeyPress={(e) => onlyNumberAllow(e)}
                        min="0"
                        required
                      />
                      {parseInt(mrp) <
                        parseInt(price) && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            Price Should be less then MRP
                          </p>
                        )}
                    </div>
                    <div className="two-field">
                      <p>Country Of Origin<b>*</b></p>
                      <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={countryList}
                        value={{ value: specs[index].country, label: specs[index].country }}
                        name="gst"
                        // required
                        onChange={(e) => handleSelectCountry(e, index)}
                      />
                    </div> 
                    {/* <div className="radio-wrap mt-3" style={{ marginLeft: "4%", }}>
                      <div style={{ marginTop: "20px", display: "flex" }}>
                        <label
                          className="ml-1 label-form "
                          for="vehicle1"
                          style={{ marginTop: "-5px" }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              marginTop: "10px",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "12px",
                              color: "#4F4F4F",
                            }}
                          >
                            {" "}
                            Price Secondary Display
                          </p>
                        </label>
                        <input
                          className="mx-4 mt-1"
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          checked={specs[index].priceSecondaryDisplay}
                          onChange={(e) =>
                            handleChange2(specs[index].priceSecondaryDisplay, "priceSecondaryDisplay", index)
                          }
                        />

                        <br />
                      </div>
                    </div> */}
                  </section>
 
                  <section>
                    <div className="two-field">
                      <div className="datePic5">
                        <p className="label-form mt-2">MANUFACTURE DATE<b>*</b></p>
                        <DatePicker
                          selected={specs[index].date}

                          onChange={(dat) => {
                            var date = new Date(dat);

                            handleChange2(date, "date", index)
                          }}

                          customInput={<DatePickerInput value={specs[index].date} />}
                          maxDate={new Date()}

                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"

                        />
                      </div>
                    </div>
                    
                    <div className="two-field">
                      <div className="datePic5">
                        <p className="label-form mt-2">Expiry Date<b></b></p>
                        {/* {console.log("specs[index].expiryDate---",specs[index].expiryDate)} */}
                        {/* {specs[index].showExpiry === true &&  */}
                        <DatePicker
                          selected={specs[index]?.expiryDate}

                          onChange={(dat) => {
                            var date = new Date(dat);

                            handleChange2(date, "expiryDate", index)
                          }}

                          customInput={<DatePickerInput value={specs[index]?.expiryDate} />}
                          // maxDate={new Date()}
                          minDate={new Date()}

                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"

                        />
          {/* } */}
                      </div>
                    </div>
                    

                  </section>
                  <section>
                    {/* <div style={{ marginTop: "20px", display: "flex" }}>
                      <label
                        className="ml-1 label-form "
                        for="vehicle1"
                        style={{ marginTop: "-5px" }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            marginTop: "10px",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "12px",
                            color: "#4F4F4F",
                          }}
                        >
                          {" "}
                          COD<b></b>
                        </p>
                      </label>
                      <input
                        className="mx-4 mt-1"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        checked={specs[index].cod}
                        onChange={(e) =>
                          handleChange2(specs[index].cod, "cod", index)
                        }
                      />

                      <br />

                      <span className="mx-4 " style={{marginTop:"-5px"}}>
                        <label
                            className="mx-1"
                            style={{
                              fontSize: "18px",
                              marginTop: "10px",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "12px",
                              color: "#4F4F4F",
                            }}
                          >
                            Essential item
                          </label>
                           <input
                           className="mx-4 mt-1"
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          checked={specs[index].essentialItem}
                          onChange={(e) =>
                            handleChange2(
                              specs[index].essentialItem,
                              "essentialItem",
                              index
                            )
                          }
                        />
                          
                        </span>
                    </div> */}
                    
                    

                  </section>

                
                  <section>
                    {/* <div style={{ marginTop: '20px', display: "flex" }}>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        checked={specs[index].isReturnable}
                        onChange={(e) => handleChange2(specs[index].isReturnable, "isReturnable", index)}
                      />
                      <label className="ml-1 label-form" for="vehicle1">Is Returnable</label><br />
                      <span className='mx-4 '>
                        <input type="checkbox" className="radio" 
                          name="fooby "
                          checked={specs[index].isExchangable}
                          onChange={(e) => handleChange2(specs[index].isExchangable, "isExchangable", index)}
                         
                        />
                        <label
                          className='mx-1'
                          style={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "24px",
                            color: "#4F4F4F",

                          }}
                        >
                          Is Exchangeable</label>

                      </span>
                    </div> */}
                    <div className="two-field">
                      <p>Available Stock
                      </p>
                      <input
                        type="number"
                        value={specs[index].units}
                        // value={totalQuantity}
                        // onChange={(e) => setTotalQuantity(e.target.value)}
                        onChange={(e) => handleChange2(e, "units", index)}
                        placeholder="Enter Total Quantity"
                        onKeyPress={(e) => onlyNumberAllow(e)}
                        required
                        min="0"
                        max={10}
                      />
                    </div>
                  </section>
                  <section>
                    

                      {/* <div className="two-field">
                        <p>Select Sticky Stickers</p>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          value={exploreuse}
                          options={explore_list} 
                          onChange={handleExplore}
                        />
                      </div> */}
                    </section>
                    

                  {specs[index].isReturnable || specs[index].isExchangable ? (<section>
                    <div className="two-field">
                      <p>Return Days<b>*</b></p>

                      <input
                        type="number"
                        value={specs[index].returnDays}
                        onChange={(e) => handleChange2(e, "returnDays", index)}
                        placeholder="Enter Return Days"
                        required
                      />
                      {specs[index].returnDays === "" && error === true && specs[index].isReturnable && <p style={{
                        color: "red",
                        "fontSize": "small",
                        "marginTop": "10px",
                        "fontWeight": "200 !important"
                      }}>Please Enter Return Days</p>}
                    </div>
                  </section>) : ""} 
                </div> 
              </div>
            )
          })
          : null}


        <i className="error">{catalogueerror}</i>

        <div className="button" style={{ marginBottom:"50px" , marginTop:"-20px"}}>

 ;&nbsp;
          <button onClick={() => submit()}>update</button>
        </div>
      </div>


    </Layout>
  )
}

export default EditProductVariant
