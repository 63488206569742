import React, { useState} from "react"; 
import Layout from "../../layout/layout"; 
import axiosauth from "../../axios-config-auth";
import { useHistory } from "react-router-dom";
 
import SuccessModal from "../../components/halper/SuccessModal";
import left_arrow2 from "../../assets/icons/down_arrow2.png"; 
import { Redirect } from "react-router-dom";

const AddGajariCoins = () => {
  const [show, setShow] = useState(false);
  const [collectionMsg, setCollectionMasg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [redirect , setRedirect] = useState(false)
  // const myRefname = useRef(null);
  const history = useHistory();

  /**
   * TODO: function use for handle navigation
   *@version 1.0.0
   * @author [Rohan Jha]
   */
  // const handleClick = () => {
  //   // myRefname.current.focus();
  //   myRefname.current.dispatchEvent(
  //     new MouseEvent("click", {
  //       view: window,
  //       bubbles: true,
  //       cancelable: true,
  //       buttons: 1,
  //     })
  //   );
  // };

  const [shipping, setshipping] = useState({
    startvalue: "",
    cost: "",
    endvalue: "",
  });
  const [shippingstartvalueerror, setshippingstartvalueerror] = useState("");
  const [shippingcosterror, setshippingcosterror] = useState("");
  const [shippingendvalueerror, setshippingendvalueerror] = useState("");

  /**
   * TODO: function use for handle create brands form fields
   *@version 1.0.0
   * @author [Rohan Jha]
   */
  const handleChange = (name) => (event) => {
    setshippingstartvalueerror("");
    setshippingendvalueerror("");
    setshippingcosterror("");
    setErrorMsg("");
    setCollectionMasg("");
    setshipping({ ...shipping, error: false, [name]: event.target.value });
  };

  const onSubmit = () => {
    var error = "";
    var errora = "";
    var errorb = "";

    if (shipping.startvalue === "") {
      setshippingstartvalueerror("Please Enter Starting Value");
      error = "a";
    } else {
      error = "";
    }
    if (shipping.endvalue === "") {
      setshippingendvalueerror("Please Enter End Value");
      errora = "a";
    } else {
      errora = "";
    }
    if (parseInt(shipping.endvalue) < parseInt(shipping.startvalue)) {
      setshippingendvalueerror("End Value Must be Greater Than Starting Value");
      errora = "a";
    } else {
      errora = "";
    }
    if (shipping.cost === "") {
      setshippingcosterror("Please Enter cost");
      errorb = "a";
    } else {
      errorb = "";
    }
    if (!(shipping.cost >= 0 && shipping.cost <= 99)) {
      setshippingcosterror("Percentage Invalid");
      errorb = "a";
    } else {
      errorb = "";
    }
    if (error === "a" || errora === "a" || errorb === "a") {
      return 0;
    }
    setErrorMsg("");
    setCollectionMasg("");

    let obj = {
      // startAmount: parseInt(shipping.startvalue),
      // endAmount: parseInt(shipping.endvalue),
      // charge: parseInt(shipping.cost),
      // active: true,
      endAmount: shipping.endvalue ,
      startAmount:shipping.startvalue  ,
      value :shipping.cost
      
    };

    axiosauth
      .post("/coins-value/create", obj)
      .then(async (response) => {
        console.log("response", response);
        let res = JSON.parse(response.data);
        console.log("JSON.parse(response.data)--", JSON.parse(response.data))
        if (res.message === "success") {
          console.log("inside response")
          setErrorMsg("");

          setShow(true);
        } else {
          setErrorMsg(res.message);
          setCollectionMasg("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modalClose = () => {
    setshipping({
      startvalue: "",
      cost: "",
      endvalue: "",
    });
    setShow(false);
    // handleClick(); 
    setRedirect(true)
  };

  

  return (
    <Layout>
      
      {show ? ( <SuccessModal message="New Gajari Coins Created!" onClick={modalClose} />  ) : null}
       {redirect ? <Redirect to="/manage-coins?page=1" /> : null}
       
      <div
        className="left_arrow2 back-arrow my-3"
        style={{
          display: "flex",
          alignItems: "baseline",
          fontFamily: "inherit",
        }}
      >
        <img
          src={left_arrow2}
          onClick={() => history.goBack()}
          alt="left-arrow"
        />{" "}
        &nbsp;
        <h2>Add Gajari Coins</h2>
      </div>
      <div className="sub-category-wrap">
        <div className="form-group form-input">
          <label className="label-style ml-0" for="name">
            Minimum Order Value<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="number"
            name="name"
            className={
              shippingstartvalueerror !== "" || errorMsg !== ""
                ? "error form-control form-control-nonround"
                : "form-control form-control-nonround"
            }
            id="name"
            placeholder="Type here"
            value={shipping.startvalue}
            onChange={handleChange("startvalue")}
          />
          <p style={{color:"red"}}>{shippingstartvalueerror}</p>
        </div>
        <div className="form-group form-input">
          <label className="label-style ml-0" for="name">
            Maximum Order Value<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="number"
            name="name"
            className={
              shippingendvalueerror !== "" || errorMsg !== ""
                ? "error form-control form-control-nonround"
                : "form-control form-control-nonround"
            }
            id="name"
            placeholder="Type here"
            value={shipping.endvalue}
            onChange={handleChange("endvalue")}
          />
          <p style={{color:"red"}}>{shippingendvalueerror}</p>
        </div>
        <div className="form-group form-input">
          <label className="label-style ml-0" for="name">
            Percentage coins earned<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="number"
            name="name"
            className={
              shippingcosterror !== "" || errorMsg !== ""
                ? "error form-control form-control-nonround"
                : "form-control form-control-nonround"
            }
            id="name"
            placeholder="Type here"
            value={shipping.cost}
            onChange={handleChange("cost")}
          />
          <p>{shippingcosterror}</p>
        </div>

        <div className="button">
          <button onClick={onSubmit}>Save</button>
        </div>

        <span className="text-success font-weight-bold m-3">
          {collectionMsg}
        </span>
        <span className="text-danger font-weight-bold m-3">{errorMsg}</span>
      </div>

    </Layout>
  );
};

export default AddGajariCoins;
