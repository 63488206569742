export const IS_ERROR = "IS_ERROR";
export const IS_LOADING = "IS_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SESSIONEXPIRED = "SESSIONEXPIRED";
// password change

export const SEND_EMAIL_LINK = "SEND_EMAIL_LINK";
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";
export const SEND_EMAIL_LOADING = "SEND_EMAIL_LOADING";

export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const STEPPER = "STEPPER";
export const BACK = "BACK";
export const FORMTYPE = "FORMTYPE";
export const LATITUDE = "LATITUDE";
export const LONGITUDE = "LONGITUDE";
export const OPENMODAL = "OPENMODAL";
export const OTHERMODAL = "OTHERMODAL";

export const SHOWVENDOR = "SHOWVENDOR";

export const MODALOPEN = "MODALOPEN";
export const COUPONID = "COUPONID";
export const SEARCH = "SEARCH";
export const CATEGORY = "CATEGORY";

//  Category ::

export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILURE = "FETCH_CATEGORY_FAILURE";
export const FETCH_CATEGORY_BY_ID = "FETCH_CATEGORY_BY_ID"
export const CREATE_CATEGORY = "CREATE_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_IMAGE = "ADD_IMAGE";

//  SubCategory ::
export const FETCH_SUB_CATEGORY = "FETCH_SUB_CATEGORY";
export const FETCH_SUB_CATEGORY_SUCCESS = "FETCH_SUB_CATEGORY_SUCCESS";
export const FETCH_SUB_CATEGORY_FAILURE = "FETCH_SUB_CATEGORY_FAILURE";
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY"
export const FETCH_SUB_CATEGORY_BY_ID = "FETCH_SUB_CATEGORY_BY_ID"
export const SEARCH_SUB_CATEGORY = "SEARCH_SUB_CATEGORY"
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY"
export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY"

// sub sub category
export const FETCH_SUB_SUB_CATEGORY = "FETCH_SUB_SUB_CATEGORY";
export const FETCH_SUB_SUB_CATEGORY_SUCCESS = "FETCH_SUB_SUB_CATEGORY_SUCCESS";
export const FETCH_SUB_SUB_CATEGORY_FAILURE = "FETCH_SUB_SUB_CATEGORY_FAILURE";
export const CREATE_SUB_SUB_CATEGORY = "CREATE_SUB_SUB_CATEGORY"
export const FETCH_SUB_SUB_CATEGORY_BY_ID = "FETCH_SUB_SUB_CATEGORY_BY_ID"
export const SEARCH_SUB_SUB_CATEGORY = "SEARCH_SUB_SUB_CATEGORY"
export const UPDATE_SUB_SUB_CATEGORY = "UPDATE_SUB_SUB_CATEGORY"
export const DELETE_SUB_SUB_CATEGORY = "DELETE_SUB_SUB_CATEGORY"

// Feedback
export const FETCH_FEEDBACK = "FETCH_FEEDBACK";

// user data
export const FATCH__USER = "FATCH__USER";
export const FATCH__USER_BY_ID = "FATCH__USER_BY_ID";
export const USER_LOADING = "USER_LOADING";
export const USER_FAIL = "USER_FAIL";
export const SEARCHING = "SEARCHING";
export const DELETE_USER = "DELETE_USER";

// Collection Type

export const FETCH_COLLECTION = "FETCH_COLLECTION";
export const FETCH_COLLECTION_BY_ID = "FETCH_COLLECTION_BY_ID";
export const ADD_COLLECTION = "ADD_COLLECTION";
export const EDIT_COLLECTION = "EDIT_COLLECTION";

// coupon

export const FETCH_COUPON = "FETCH_COUPON";
export const FETCH_COUPON_BY_ID = "FETCH_COUPON_BY_ID";
export const CREATE_COUPON = "CREATE_COUPON";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const COUPON_COUNT = "COUPON_COUNT";
// product
export const FETCH_PRODUCT = "FETCH_PRODUCT";

// cms pages

export const FETCH_PAGES = "FETCH_PAGES";
export const EDIT_PAGES = "EDIT_PAGES";
export const PAGES_SUCCESS = "PAGES_SUCCESS";

// setting

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const PASSWORD_STATUS = "PASSWORD_STATUS";

// Variant Manager

export const FETCH_VARIANT = "FETCH_VARIANT";
export const FETCH_VARIANT_BY_ID = "FETCH_VARIANT_BY_ID";
export const CREATE_VARIANT = "CREATE_VARIANT";

//  Banner :

export const BANNER_LOAD = "BANNER_LOAD";
export const BANNER_UPLOAD = "BANNER_UPLOAD";
export const BANNER_DELETE = "BANNER_DELETE";
export const BANNER_UPDATE = "BANNER_UPDATE";
export const BANNER_FAIL = "BANNER_FAIL";

// Catalogue
export const CATALOGUE_BY_ID = "CATALOGUE_BY_ID";

