import React, { useState, useEffect } from 'react';
// import uploadIcon from '../../assets/icons/upload.png';
// import noImg from '../../assets/images/noimg.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanners } from '../../redux/actions/bannerAction';
import axiosauth from '../../axios-config-auth';
// import Loader from 'react-loader-spinner';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { Link,useHistory } from 'react-router-dom';
// import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
// import noImage from '../../assets/images/no-img.png';
import { confirmAlert } from 'react-confirm-alert';
import NoData from '../../components/halper/NoData';
import editIcon from '../../assets/icons/edit.png';

const BannerView = () => {
    const history = useHistory();

    const [bannerList, setBannerlist]=useState([]);

    const banners = useSelector(state => state.bannerdata)
    console.log("banner data->", banners, "type=>", typeof banners)

 
    // banner_list=[]
    // for(i=0;i<banners.length;i++)
    const dispatch = useDispatch()

    // const bannerUpload =(uploadfile)=>{
       

    //     var formData = new FormData();

    //     formData.append("title", "banner");
    //     formData.append("link", "http://toyvala-main-website.s3-website.ap-south-1.amazonaws.com/");
    //     formData.append("newtab", false);
    //     formData.append("alt", "banner-image");
    //     formData.append("available", true);
    //     formData.append("typeId", "O4FFLiVb");
    //     formData.append("image", uploadfile);



    //     dispatch(uploadBanners(formData))
    // }

    useEffect(() => {
        let obj={
            "filters":{
                typeId: "O4FFLiVb"
            }
    }
        axiosauth.post('/banner/load-banner', obj)
        .then(async (response) => {
            let res = JSON.parse(response.data)
            // console.log("jdkd", res)
            if (res.success === true) {
                console.log("banner true-->", res.data.banners);
                setBannerlist(res.data.banners)
            } else {
                console.log("banner fasle-->", res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })


        dispatch(fetchBanners())
    }, [dispatch])


    console.log("bannerList-->",bannerList)




    const loadBanner = ()=>{

        let obj={
                "filters":{
                    typeId: "O4FFLiVb"
                }
        }
                axiosauth.post('/banner/load-banner', obj)
                .then(async (response) => {
                    let res = JSON.parse(response.data)
                    // console.log("jdkd", res)
                    if (res.success === true) {
                        console.log("banner true-->", res.data.banners);
                        setBannerlist(res.data.banners)
                    } else {
                        setBannerlist([])

                        console.log("banner fasle-->", res.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        
    }



    return (
        <>

{/* ===================================== */}



<div>
            {/* <SearchBox
                placeholder="Search.."
            /> */}


            <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img src={left_arrow2}  onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                <h2>Manage Banner</h2>
                <div className="button">
                    <Link to="/add-banner">
                        <button>Add Banner</button>
                    </Link>
                </div>
            </div>
                <div className="table-wrap">
                    <table>
                    {bannerList&&bannerList.length>0?(  <thead>
                            <tr>
                                <th>Banner .No</th>
                                <th>Banner Title</th>
                                <th>Banner Image</th>
                                <th>Edit image</th>
                                {/* <th>Edit Details</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>):null}  
                        <tbody>
                            {bannerList && bannerList.length > 0 ?

                                bannerList.map((banner, index) => {



                                    const deleteBanner = () => {
                                        let obj = {
                                            "bannerIds": [banner.bannerId],
                                        }
                                        confirmAlert({
                                            // title: 'Change Status',
                                            message: 'Are you sure you want to delete this Banner ?',
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () => {
                                                        axiosauth.post('/banner/delete-banner', obj)
                                                            .then(async (response) => {

                                                                let res = JSON.parse(response.data)
                                                                if (res.success === true) {
                                                                    loadBanner()
                                                                } else {
                                                                    loadBanner()
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            })
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    onClick: () => console.log("Hello")
                                                }
                                            ]
                                        });

                                    }




                                //   let imgUrl=`https://arjoi-assets.s3.ap-south-1.amazonaws.com${banner.image}`
                                 

                                    return (
                                        <tr key={index+1}>
                                            <td>{index+1}</td>
                                            <td className="titleStyle title-name">{banner.title}</td>
                                            <td className="collection-img">
                                            {/* {banner.image} */}

                                                <img src={banner.image} alt="" />
                                            </td>
                                            {/* <td>
                                             <Link to={`/update-brand/${banner.brandId}`} >
                                                <img onClick={() => localStorage.setItem("banner", banner.brandId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                            </Link>
                                            
                                          
                                            </td> */}
                                              <td>
                                            <Link to={`/edit-banner-image`}>
                                                    <img onClick={() => localStorage.setItem("bannerId", banner.bannerId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                                </Link>
                                                </td>
                                            <td>
                                            <Link to={`/edit-banner`}>
                                                    <img onClick={() => localStorage.setItem("bannerId", banner.bannerId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                                </Link>
                                             <img onClick={() => deleteBanner()} src={deleteIcon} alt="delete" className="delete-icon" />
                                            
        
                                            </td>
                                        </tr>
                                    )
                                })

                                :
                                (                               <NoData message="Banner Not Found" />
                                )
                            }

                        </tbody>
                    </table>


                </div>
               

        </div>

{/* <img src="https://arjoi-assets.s3.ap-south-1.amazonaws.com/banners/DhKNUzs4ckdNhI4v.jpeg" alt=""/> */}



{/* =================================================== */}





           
        </>
    )
}

export default BannerView
