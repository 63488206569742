import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import axiosauth from "../../axios-config-auth";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import editImageIcon from "../../assets/icons/edit.png";
import delIcon from "../../assets/icons/delete.png";
import uploadIcon from "../../assets/icons/upload.png";
import left_arrow2 from "../../assets/icons/down_arrow2.png"; 
import Loader from "react-loader-spinner";
import { useParams } from "react-router";

const EditVariantImage = () => {
  const { productId } = useParams();
  const [calluseeffect, setcalluseeffect] = useState("");



// console.log("outside productId===>>" , productId)
  useEffect(()=>{
// console.log("inside productId===>>" , productId)

    loadImage();
  },[productId , calluseeffect])
  

  const [loading, setLoading] = useState(false);
  const [dependecies, setDependencies] = useState("")

  const [videoFile, setVideoFile] = useState(null);

  // eslint-disable-next-line
  const [imageUrl, setImageurl] = useState("");
  const [showfile, setshowfile] = useState(null);
  const [imageId, setImageId] = useState("");
  const [result, setResult] = useState(null);
  const [youtubeurl, setyoutubeurl] = useState(null);

  const [videoUrl, setVideoUrl] = useState(null);

  // eslint-disable-next-line
  const [imageUrl1, setImageurl1] = useState("");
  const [showfile1, setshowfile1] = useState(null);
  const [result1, setResult1] = useState(null);

  // eslint-disable-next-line
  const [imageUrl2, setImageurl2] = useState("");
  const [showfile2, setshowfile2] = useState(null);
  const [result2, setResult2] = useState(null);

  // eslint-disable-next-line
  const [imageUrl3, setImageurl3] = useState("");
  const [showfile3, setshowfile3] = useState(null);
  const [result3, setResult3] = useState(null);

  // eslint-disable-next-line
  const [imageUrl4, setImageurl4] = useState("");
  const [showfile4, setshowfile4] = useState(null);
  const [result4, setResult4] = useState(null);

  // eslint-disable-next-line
  const [imageUrl5, setImageurl5] = useState("");
  const [showfile5, setshowfile5] = useState(null);
  const [result5, setResult5] = useState(null);

  // eslint-disable-next-line
  const [imageUrl6, setImageurl6] = useState("");
  const [showfile6, setshowfile6] = useState(null);
  const [result6, setResult6] = useState(null);

  // eslint-disable-next-line
  const [imageUrl7, setImageurl7] = useState(""); 
  const [showfile7, setshowfile7] = useState(null); 
  
 

  const [fsize, setFsize] = useState("");
  const [sizeMsg, setSizeMsg] = useState("");

  const [fsize1, setFsize1] = useState("");
  const [sizeMsg1, setSizeMsg1] = useState("");

  const [fsize2, setFsize2] = useState("");
  const [sizeMsg2, setSizeMsg2] = useState("");

  const [fsize3, setFsize3] = useState("");
  const [sizeMsg3, setSizeMsg3] = useState("");

  const [fsize4, setFsize4] = useState("");
  const [sizeMsg4, setSizeMsg4] = useState("");

  const [fsize5, setFsize5] = useState("");
  const [sizeMsg5, setSizeMsg5] = useState("");

  const [fsize6, setFsize6] = useState("");
  const [sizeMsg6, setSizeMsg6] = useState("");

  const [fsize7, setFsize7] = useState("");
  const [sizeMsg7, setSizeMsg7] = useState("");

  const history = useHistory();
  const [product, setproduct] = useState("");
 
 
  const loadImage = () => {
    let obj = {
      "catalogueId": productId
    };
    axiosauth 
      .post("/catalogue/find-catalogue-by-id", obj)
      .then(async (response) => {


        let res = JSON.parse(response.data);

        if (res?.success === true) {
          setLoading(true)
          setproduct(res?.result[0]); 
          if (res?.result[0]?.videoUrls?.length > 0) {
            setVideoUrl(res?.result[0].videoUrls[0].url); 
          }

          if (res?.result[0]?.imageUrls?.length === 1) { 
            setshowfile(res?.result[0]?.imageUrls[0]?.url);
          }
          if (res?.result[0]?.imageUrls?.length === 2) { 
            setshowfile(res?.result[0].imageUrls[0].url);
            setshowfile1(res?.result[0].imageUrls[1].url);
          }
          if (res?.result[0].imageUrls?.length === 3) { 
            setshowfile(res?.result[0].imageUrls[0].url);
            setshowfile1(res?.result[0].imageUrls[1].url);
            setshowfile2(res?.result[0].imageUrls[2].url);
          }
          if (res?.result[0].imageUrls?.length === 4) { 
            setshowfile(res?.result[0].imageUrls[0].url);
            setshowfile1(res?.result[0].imageUrls[1].url);
            setshowfile2(res?.result[0].imageUrls[2].url);
            setshowfile3(res?.result[0].imageUrls[3].url);
          }
          if (res?.result[0].imageUrls?.length === 5) { 
            setshowfile(res?.result[0].imageUrls[0].url);
            setshowfile1(res?.result[0].imageUrls[1].url);
            setshowfile2(res?.result[0].imageUrls[2].url);
            setshowfile3(res?.result[0].imageUrls[3].url);
            setshowfile4(res?.result[0].imageUrls[4].url);
          }
          if (res?.result[0].imageUrls?.length === 6) {
            setshowfile(res?.result[0].imageUrls[0].url);
            setshowfile1(res?.result[0].imageUrls[1].url);
            setshowfile2(res?.result[0].imageUrls[2].url);
            setshowfile3(res?.result[0].imageUrls[3].url);
            setshowfile4(res?.result[0].imageUrls[4].url);
            setshowfile5(res?.result[0].imageUrls[5].url);
            // setshowfile6(res?.result[0].imageUrls[6].url);
          }
          if (res?.result[0].imageUrls?.length === 7) {
            setshowfile(res?.result[0].imageUrls[0].url);
            setshowfile1(res?.result[0].imageUrls[1].url);
            setshowfile2(res?.result[0].imageUrls[2].url);
            setshowfile3(res?.result[0].imageUrls[3].url);
            setshowfile4(res?.result[0].imageUrls[4].url);
            setshowfile5(res?.result[0].imageUrls[5].url);
            setshowfile6(res?.result[0].imageUrls[6].url);
          }
          if (res?.result[0].imageUrls?.length === 8) { 
            setshowfile(res?.result[0].imageUrls[0].url);
            setshowfile1(res?.result[0].imageUrls[1].url);
            setshowfile2(res?.result[0].imageUrls[2].url);
            setshowfile3(res?.result[0].imageUrls[3].url);
            setshowfile4(res?.result[0].imageUrls[4].url);
            setshowfile5(res?.result[0].imageUrls[5].url);
            setshowfile6(res?.result[0].imageUrls[6].url);
            setshowfile7(res?.result[0].imageUrls[7].url);
          }

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
 
  const handleChange22 = (imageurl, imageId) => {
    var formData = new FormData();
    formData.append("catalogueId", productId);
    formData.append("imageId", imageId);
    formData.append("image", imageurl);

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }

    axiosauth 
      .post("/catalogue/update-catalogue-image", formData)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res?.success === true) {
          setImageId("");
          alert("Image Edited Successfully");
          setshowfile(null);
          setshowfile1(null);
          setshowfile2(null);
          setshowfile3(null);
          setshowfile4(null);
          setshowfile5(null);
          setshowfile6(null);
          setshowfile7(null);
          setcalluseeffect(generateString(5));
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };
  // eslint-disable-next-line
  const handleChange2 = () => {
    if (fsize < 5000000) {
      setSizeMsg("");
      if (fsize1 < 5000000) {
        setSizeMsg1("");
        if (fsize2 < 5000000) {
          setSizeMsg2("");
          if (fsize3 < 5000000) {
            setSizeMsg3("");
            if (fsize4 < 5000000) {
              setSizeMsg4("");
              if (fsize5 < 5000000) {
                setSizeMsg5("");
                if (fsize6 < 5000000) {
                  setSizeMsg6("");
                  if (fsize7 < 5000000) {
                    setSizeMsg7("");
                    var formData = new FormData();
                    // var productId = localStorage.getItem("catalogueId");
                    formData.append("catalogueId", productId);
                    if (imageUrl !== "") {
                      formData.append("image", imageUrl);
                    }
                    if (imageUrl1 !== "") {
                      formData.append("image", imageUrl1);
                    }
                    if (imageUrl2 !== "") {
                      formData.append("image", imageUrl2);
                    }
                    if (imageUrl3 !== "") {
                      formData.append("image", imageUrl3);
                    }
                    if (imageUrl4 !== "") {
                      formData.append("image", imageUrl4);
                    }
                    if (imageUrl5 !== "") {
                      formData.append("image", imageUrl5);
                    }
                    if (imageUrl6 !== "") {
                      formData.append("image", imageUrl6);
                    }
                    if (imageUrl7 !== "") {
                      formData.append("image", imageUrl7);
                    }

                    axiosauth
                      .post("/catalogue/upload-product-images", formData)
                      .then((response) => {
                        let res = JSON.parse(response.data);
                        if (res?.success === true) {
                        } else {
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .then(() => {
                        console.log("-----always executes");
                      });
                  } else {
                    setSizeMsg7("image size should be less than 500kb");
                  }
                } else {
                  setSizeMsg6("image size should be less than 500kb");
                }
              } else {
                setSizeMsg5("image size should be less than 500kb");
              }
            } else {
              setSizeMsg4("image size should be less than 500kb");
            }
          } else {
            setSizeMsg3("image size should be less than 500kb");
          }
        } else {
          setSizeMsg2("image size should be less than 500kb");
        }
      } else {
        setSizeMsg1("image size should be less than 500kb");
      }
    } else {
      setSizeMsg("image size should be less than 500kb");
    }
  };
  // eslint-disable-next-line
  /**
   * TODO: function use for deleting specific image
   *@version 1.0.0
   * @author [ Unknown]
   */
  const deleteImage = (imageId) => {
    let obj = {
      catalogueId: productId,
      imageId: imageId,
    };

    confirmAlert({
      title: "Delete Image",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axiosauth
              .post("/catalogue/delete-catalogue-image", obj)
              .then((response) => {
                let res = JSON.parse(response.data);
                if (res?.success === true) {
                  alert("Image deleted successfully"); 
                  setshowfile(null);
                  setshowfile1(null);
                  setshowfile2(null);
                  setshowfile3(null);
                  setshowfile4(null);
                  setshowfile5(null);
                  setshowfile6(null);
                  setshowfile7(null);
                  setcalluseeffect(imageId); 
                } else {
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .then(() => {
                console.log("-----always executes");
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Hello"),
        },
      ],
    });
  };

  const deleteVedio = (id) => {
    let obj = {
      catalogueId: productId,
      videoId: id,
    };
    const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    return result;
  }
    axiosauth
      .post("/catalogue/delete-catalogue-video", obj)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res?.success === true) {
          alert("Video Deleted SuccessFully");
          setVideoFile(null)
          setVideoUrl(null)
          setcalluseeffect(generateString(5)); 
          setDependencies("video deleted sucessfully") 
         
        }
      })
      .catch((e) => {
        console.log("Netrork Error");
      });
  };


  const PostVedio = (file) => {
    var formData = new FormData();
    formData.append("catalogueId", productId);
    formData.append("image", file); 

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }
    axiosauth
      .post("/catalogue/push-product-video", formData)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res?.success === true) { 
          alert("Video Added SuccessFully");
          setcalluseeffect(generateString(5));
        }
      })
      .catch((e) => {
        console.log("Netrork Error");
      });
  };


  const UpdateVideo = (file, id) => {
    var formData = new FormData();
    formData.append("catalogueId", productId);
    formData.append("videoId", id);
    formData.append("image", file);

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }

    axiosauth
      .post("/catalogue/update-catalogue-video", formData)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res?.success === true) { 
          alert("Video Updated SuccessFully");
          setcalluseeffect(generateString(10));
        }
      })
      .catch((e) => {
        console.log("Netrork Error");
      });
  };



  const UploadThumbnail = (file) => {
    // console.log(id, file)
    var formData = new FormData();
    formData.append("productId", productId)
    // formData.append("thumbnailId",id)
    formData.append("image", file)

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }
    axiosauth
      .post("/products/push-product-thumbnail", formData)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res?.success === true) {
          alert("Thumbnail Updated SuccessFully");
          setcalluseeffect(generateString(5));
        }
      })
      .catch((e) => {
        console.log("Netrork Error");
      });

  }

  const UpdateThumbnail = (file, id) => {
    // console.log(id, file)
    var formData = new FormData();
    formData.append("productId", productId)
    formData.append("thumbnailId", id)
    formData.append("image", file)

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }
    axiosauth
      .post("/products/update-product-thumbnail", formData)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res?.success === true) {
          alert("Thumbnail Updated SuccessFully");
          setcalluseeffect(generateString(5));
        }
      })
      .catch((e) => {
        console.log("Netrork Error");
      });
  }

  const deleteThumbnail = (id) => {
    let obj = {
      productId: productId,
      thumbnailId: id,
    }
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }
    axiosauth
      .post("/products/delete-product-thumbnail", obj)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res?.success === true) {
          alert("Thumbnail Updated SuccessFully");
          setcalluseeffect(generateString(5));
        }
      })
      .catch((e) => {
        console.log("Netrork Error");
      });

  }

  const addSingleImage = (file) => {
    var formData = new FormData();
    formData.append("catalogueId", productId)
    formData.append("image", file)

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }
    axiosauth
      .post("/catalogue/push-catalogue-image", formData)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res?.success === true) {
          alert("Image Updated SuccessFully");
          setcalluseeffect(generateString(5));
        }
      })
      .catch((e) => {
        console.log("Netrork Error");
      });
  }

  return (
    <Layout>
      <div className="form-wrap"> 
        <div
          className="left_arrow2 back-arrow"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp; 
          <h2>{`Product Image Dimension Must be (1040 X 1480) , image (jpg, png , WebP),image size must be less than < 200KB`}</h2>
        </div>
 
        {loading === false ? (
          <div className="loader-style">
            <Loader
              type="ThreeDots"
              color="rgba(32, 159, 132, 1)"
              height={100}
              width={100}
              timeout={5000} //3 secs
            />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="single-image col-auto mb-5">
                {showfile === null ? (
                  <section className="image-uploaded">
                    <div className="upload-btn-wrapper">
                      <button className="uplod-btn">
                        <img src={uploadIcon} alt="" />
                        <br />
                        UPLOAD IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept={"image/*"}
                        onChange={(e) => {

                          if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                          }

                          setFsize(e.currentTarget.files[0].size); 
                          let img = new Image();
                          img.src = window.URL.createObjectURL(e.target.files[0]); 
                          img.onload = () => {
                            if (img.width === 1040 && img.height === 1480) {
                              setshowfile(URL.createObjectURL(e.target.files[0])); 
                              addSingleImage(e.target.files[0]) 
                            } else {
                              alert(
                                "Incorrect image dimension. Please upload an image 1024X1480 pixels."
                              );
                            }
                          };
                        }}
                      />
                    </div>
                  </section>
                ) : null}

                {showfile !== null ? (
                  <aside className="image">
                    <img
                      className="screen"
                      src={showfile != null ? showfile : result}
                      alt=""
                    />

                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: "none" }}
                      name="myfile"
                      accept={"image/*"}
                      onChange={(e) => {

                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                        }
                        setFsize(e.currentTarget.files[0].size); 
                        let img = new Image();
                        img.src = window.URL.createObjectURL(e.target.files[0]);

                        img.onload = () => {
                          if (img.width === 1040 && img.height === 1480) {
                            setImageId(product.imageUrls[0].imageId);
                            localStorage.setItem(
                              "imgId",
                              product.imageUrls[0].imageId
                            ); 
                            handleChange22(
                              e.target.files[0],
                              product.imageUrls[0].imageId
                            ); 
                          } else {
                            alert(
                              "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                            );
                          }
                        };
                      }}
                    />
                    <div className="overlay3">
                      <label htmlFor="file-upload">
                        <img
                          htmlFor="upload-image"
                          className="edit-img"
                          src={editImageIcon}
                          alt=""
                        />
                      </label>
                      <img
                        onClick={() => deleteImage(product.imageUrls[0]?.imageId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                      />
                    </div>
                  </aside>
                ) : null}
                <span style={{ fontSize: "12px", color: "red" }}>{sizeMsg}</span>
              </div>

              <div className="single-image col-auto mb-5">
                {showfile1 === null && showfile !== null ? (
                  <section className="image-uploaded">
                    <div className="upload-btn-wrapper mt-2">
                      <button className="uplod-btn">
                        <img src={uploadIcon} alt="" />
                        <br />
                        UPLOAD IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept={"image/*"}
                        onChange={(e) => {

                          if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                          } 
                          setFsize1(e.currentTarget.files[0].size);

                          let img = new Image();
                          img.src = window.URL.createObjectURL(e.target.files[0]); 
                          img.onload = () => {
                            if (img.width === 1040 && img.height === 1480) {
                              setshowfile1(URL.createObjectURL(e.target.files[0])); 
                              addSingleImage(e.target.files[0])
                            } else {
                              alert(
                                "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                              );
                            }
                          };
                        }}
                      />
                    </div>
                  </section>
                ) : null}

                {showfile1 !== null ? (
                  <aside className="image">
                    <img
                      className="screen"
                      src={showfile1 != null ? showfile1 : result1}
                      alt=""
                    />
                    <input
                      type="file"
                      id="file-upload1"
                      style={{ display: "none" }}
                      name="myfile"
                      accept={"image/*"}
                      onChange={(e) => {

                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                        }
                        setFsize1(e.currentTarget.files[0].size); 
                        let img = new Image();
                        img.src = window.URL.createObjectURL(e.target.files[0]);

                        img.onload = () => {
                          if (img.width === 1040 && img.height === 1480) {
                            setImageId(product.imageUrls[1].imageId);
                            localStorage.setItem(
                              "imgId",
                              product.imageUrls[1].imageId
                            ); 
                            handleChange22(
                              e.target.files[0],
                              product.imageUrls[1].imageId
                            ); 
                          } else {
                            alert(
                              "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                            );
                          }
                        };
                      }}
                    />
                    <div className="overlay3">
                      <label htmlFor="file-upload1">
                        <img
                          htmlFor="upload-image"
                          className="edit-img"
                          src={editImageIcon}
                          alt=""
                        />
                      </label>
                      <img
                        onClick={() => deleteImage(product.imageUrls[1].imageId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                      />
                    </div>
                  </aside>
                ) : null}
                <span style={{ fontSize: "12px", color: "red" }}>{sizeMsg1}</span>
              </div>

              <div className="single-image col-auto mb-5">
                {showfile2 === null && showfile1 !== null ? (
                  <section className="image-uploaded">
                    <div className="upload-btn-wrapper">
                      <button className="uplod-btn">
                        <img src={uploadIcon} alt="" />
                        <br />
                        UPLOAD IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept={"image/*"}
                        onChange={(e) => {

                          if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                          } 
                          setFsize2(e.currentTarget.files[0].size);

                          let img = new Image();
                          img.src = window.URL.createObjectURL(e.target.files[0]); 
                          img.onload = () => {
                            if (img.width === 1040 && img.height === 1480) {
                              setshowfile2(URL.createObjectURL(e.target.files[0])); 
                              addSingleImage(e.target.files[0])
                            } else {
                              alert(
                                "Incorrect image dimension. Please upload an image  1040X1480 pixels."
                              );
                            }
                          };
                        }}
                      />
                    </div>
                  </section>
                ) : null}
                {showfile2 !== null ? (
                  <aside className="image">
                    <img
                      className="screen"
                      src={showfile2 != null ? showfile2 : result2}
                      alt=""
                    />

                    <input
                      type="file"
                      id="file-upload2"
                      style={{ display: "none" }}
                      name="myfile"
                      accept={"image/*"}
                      onChange={(e) => {

                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                        }

                        setFsize2(e.currentTarget.files[0].size); 
                        let img = new Image();
                        img.src = window.URL.createObjectURL(e.target.files[0]);

                        img.onload = () => {
                          if (img.width === 1040 && img.height === 1480) {
                            setImageId(product.imageUrls[2].imageId);
                            localStorage.setItem(
                              "imgId",
                              product.imageUrls[2].imageId
                            ); 
                            handleChange22(
                              e.target.files[0],
                              product.imageUrls[2].imageId
                            ); 
                          } else {
                            alert(
                              "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                            );
                          }
                        };
                      }}
                    />
                    <div className="overlay3">
                      <label htmlFor="file-upload2">
                        <img
                          htmlFor="upload-image"
                          className="edit-img"
                          src={editImageIcon}
                          alt=""
                        />
                      </label>
                      <img
                        onClick={() => deleteImage(product.imageUrls[2].imageId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                      />
                    </div>
                  </aside>
                ) : null}
                <span style={{ fontSize: "12px", color: "red" }}>{sizeMsg2}</span>
              </div>

              <div className="single-image col-auto mb-5">
                {showfile3 === null && showfile2 !== null ? (
                  <section className="image-uploaded">
                    <div className="upload-btn-wrapper">
                      <button className="uplod-btn">
                        <img src={uploadIcon} alt="" />
                        <br />
                        UPLOAD IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept={"image/*"}
                        onChange={(e) => {

                          if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                          }

                          setFsize3(e.currentTarget.files[0].size); 
                          let img = new Image();
                          img.src = window.URL.createObjectURL(e.target.files[0]); 
                          img.onload = () => {
                            if (img.width === 1040 && img.height === 1480) {
                              setshowfile3(URL.createObjectURL(e.target.files[0])); 
                              addSingleImage(e.target.files[0])
                            } else {
                              alert(
                                "Incorrect image dimension. Please upload an image  1040X1480 pixels."
                              );
                            }
                          };
                        }}
                      />
                    </div>
                  </section>
                ) : null}
                {showfile3 !== null ? (
                  <aside className="image">
                    <img
                      className="screen"
                      src={showfile3 != null ? showfile3 : result3}
                      alt=""
                    />

                    <input
                      type="file"
                      id="file-upload3"
                      style={{ display: "none" }}
                      name="myfile"
                      accept={"image/*"}
                      onChange={(e) => {

                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                        }
                        setFsize3(e.currentTarget.files[0].size); 
                        let img = new Image();
                        img.src = window.URL.createObjectURL(e.target.files[0]);

                        img.onload = () => {
                          if (img.width === 1040 && img.height === 1480) {
                            setImageId(product.imageUrls[3].imageId);
                            localStorage.setItem(
                              "imgId",
                              product.imageUrls[3].imageId
                            ); 
                            handleChange22(
                              e.target.files[0],
                              product.imageUrls[3].imageId
                            ); 
                          } else {
                            alert(
                              "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                            );
                          }
                        };
                      }}
                    />
                    <div className="overlay3">
                      <label htmlFor="file-upload3">
                        <img
                          htmlFor="upload-image"
                          className="edit-img"
                          src={editImageIcon}
                          alt=""
                        />
                      </label>
                      <img
                        onClick={() => deleteImage(product.imageUrls[3].imageId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                      />
                    </div>
                  </aside>
                ) : null}
                <span style={{ fontSize: "12px", color: "red" }}>{sizeMsg2}</span>
              </div>



              <div className="single-image col-auto mb-5">
                {showfile4 === null && showfile3 !== null ? (
                  <section className="image-uploaded">
                    <div className="upload-btn-wrapper mt-2">
                      <button className="uplod-btn">
                        <img src={uploadIcon} alt="" />
                        <br />
                        UPLOAD IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept={"image/*"}
                        onChange={(e) => {

                          if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                          }

                          setFsize4(e.currentTarget.files[0].size);

                          let img = new Image();
                          img.src = window.URL.createObjectURL(e.target.files[0]); 
                          img.onload = () => {
                            if (img.width === 1040 && img.height === 1480) {
                              setshowfile4(URL.createObjectURL(e.target.files[0])); 
                              addSingleImage(e.target.files[0])
                            } else {
                              alert(
                                "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                              );
                            }
                          };
                        }}
                      />
                    </div>
                  </section>
                ) : null}

                {showfile4 !== null ? (
                  <aside className="image">
                    <img
                      className="screen"
                      src={showfile4 != null ? showfile4 : result4}
                      alt=""
                    />

                    <input
                      type="file"
                      id="file-upload4"
                      style={{ display: "none" }}
                      name="myfile"
                      accept={"image/*"}
                      onChange={(e) => {

                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                        }
                        setFsize4(e.currentTarget.files[0].size); 
                        let img = new Image();
                        img.src = window.URL.createObjectURL(e.target.files[0]);
                        img.onload = () => {
                          if (img.width === 1040 && img.height === 1480) {
                            setImageId(product.imageUrls[4].imageId);
                            localStorage.setItem(
                              "imgId",
                              product.imageUrls[4].imageId
                            ); 
                            handleChange22(
                              e.target.files[0],
                              product.imageUrls[4].imageId
                            );
                          } else {
                            alert(
                              "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                            );
                          }
                        };
                      }}
                    />
                    <div className="overlay3">
                      <label htmlFor="file-upload4">
                        <img
                          htmlFor="upload-image"
                          className="edit-img"
                          src={editImageIcon}
                          alt=""
                        />
                      </label>
                      <img
                        onClick={() => deleteImage(product.imageUrls[4].imageId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                      />
                    </div>
                  </aside>
                ) : null}
                <span style={{ fontSize: "12px", color: "red" }}>{sizeMsg4}</span>
              </div>

              <div className="single-image col-auto mb-5">
                {showfile5 === null && showfile4 !== null ? (
                  <section className="image-uploaded">
                    <div className="upload-btn-wrapper mt-2">
                      <button className="uplod-btn">
                        <img src={uploadIcon} alt="" />
                        <br />
                        UPLOAD IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept={"image/*"}
                        onChange={(e) => {

                          if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                          }

                          setFsize5(e.currentTarget.files[0].size); 
                          let img = new Image();
                          img.src = window.URL.createObjectURL(e.target.files[0]); 
                          img.onload = () => {
                            if (img.width === 1040 && img.height === 1480) {
                              setshowfile5(URL.createObjectURL(e.target.files[0])); 
                              addSingleImage(e.target.files[0])
                            } else {
                              alert(
                                "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                              );
                            }
                          };
                        }}
                      />
                    </div>
                  </section>
                ) : null}

                {showfile5 !== null ? (
                  <aside className="image">
                    <img
                      className="screen"
                      src={showfile5 != null ? showfile5 : result5}
                      alt=""
                    />

                    <input
                      type="file"
                      id="file-upload5"
                      style={{ display: "none" }}
                      name="myfile"
                      accept={"image/*"}
                      onChange={(e) => {

                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                        }

                        setFsize5(e.currentTarget.files[0].size); 
                        let img = new Image();
                        img.src = window.URL.createObjectURL(e.target.files[0]);

                        img.onload = () => {
                          if (img.width === 1040 && img.height === 1480) {
                            setImageId(product.imageUrls[5].imageId);
                            localStorage.setItem(
                              "imgId",
                              product.imageUrls[5].imageId
                            ); 
                            handleChange22(
                              e.target.files[0],
                              product.imageUrls[5].imageId
                            );
                          } else {
                            alert(
                              "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                            );
                          }
                        };
                      }}
                    />
                    <div className="overlay3">
                      <label htmlFor="file-upload5">
                        <img
                          htmlFor="upload-image"
                          className="edit-img"
                          src={editImageIcon}
                          alt=""
                        />
                      </label>
                      <img
                        onClick={() => deleteImage(product.imageUrls[5].imageId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                      />
                    </div>
                  </aside>
                ) : null}
                <span style={{ fontSize: "12px", color: "red" }}>{sizeMsg5}</span>
              </div>

              <div className="single-image col-auto mb-5">
                {showfile6 === null && showfile5 !== null ? (
                  <section className="image-uploaded">
                    <div className="upload-btn-wrapper mt-2">
                      <button className="uplod-btn">
                        <img src={uploadIcon} alt="" />
                        <br />
                        UPLOAD IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        accept={"image/*"}
                        onChange={(e) => {

                          if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                          }

                          setFsize6(e.currentTarget.files[0].size);

                          let img = new Image();
                          img.src = window.URL.createObjectURL(e.target.files[0]); 
                          img.onload = () => {
                            if (img.width === 1040 && img.height === 1480) {
                              setshowfile6(URL.createObjectURL(e.target.files[0])); 
                              addSingleImage(e.target.files[0])
                            } else {
                              alert(
                                "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                              );
                            }
                          };
                        }}
                      />
                    </div>
                  </section>
                ) : null}

                {showfile6 !== null ? (
                  <aside className="image">
                    <img
                      className="screen"
                      src={showfile6 != null ? showfile6 : result6}
                      alt=""
                    />

                    <input
                      type="file"
                      id="file-upload6"
                      style={{ display: "none" }}
                      name="myfile"
                      accept={"image/*"}
                      onChange={(e) => {

                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                        }

                        setFsize6(e.currentTarget.files[0].size); 
                        let img = new Image();
                        img.src = window.URL.createObjectURL(e.target.files[0]);

                        img.onload = () => {
                          if (img.width === 1040 && img.height === 1480) {
                            setImageId(product?.imageUrls[6]?.imageId);
                            localStorage.setItem(
                              "imgId",
                              product?.imageUrls[6]?.imageId
                            ); 
                            handleChange22(
                              e.target.files[0],
                              product?.imageUrls[6]?.imageId
                            );
                          } else {
                            alert(
                              "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                            );
                          }
                        };
                      }}
                    />
                    <div className="overlay3">
                      <label htmlFor="file-upload6">
                        <img
                          htmlFor="upload-image"
                          className="edit-img"
                          src={editImageIcon}
                          alt=""
                        />
                      </label>
                      <img
                        onClick={() => deleteImage(product.imageUrls[6].imageId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                      />
                    </div>
                  </aside>
                ) : null}
                <span style={{ fontSize: "12px", color: "red" }}>{sizeMsg6}</span>
              </div> 
            </div>

            <div style={{ width: "100%", height: "100%", marginTop: "50px" }}></div>
           
            {videoUrl === null ? (
              <div className=" bg-light p-3 rounded-3">
                <div className="upload-btn-wrapper px-3">
                  <button className="btn-uoload-custom">Upload a Video</button>
                  <input
                    type="file"
                    accept="video/*"
                    id="video1"
                    onChange={(e) => { 
                      setVideoFile(URL.createObjectURL(e.currentTarget.files[0])); 
                      PostVedio(e.target.files[0])

                    }}
                  ></input>
                </div>

                <span style={{ marginBottom: "20px" }}>
                  <video
                    width="350px"
                    src={videoFile}
                    type="video/mp4"
                    controls
                    playsInline
                  ></video>
                </span> 
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <div>
                    <video
                      width="350px"
                      src={videoFile == null ? videoUrl : videoFile}
                      type="video/mp4"
                      controls
                      playsInline
                    ></video>
                    <div className="overlay3 d-flex">
                      <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => { 
                          setVideoFile(
                            URL.createObjectURL(e.currentTarget.files[0])
                          );
                          UpdateVideo(
                            e.target.files[0],
                            product.videoUrls[0].videoId
                          );
                        }}
                      />
                      <img
                        onClick={() => deleteVedio(product?.videoUrls[0]?.videoId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  {/* <div className=" bg-light p-3 rounded-3">
                {thumbnail === null ?
                  <div className="mx-5 upload-btn-wrapper px-3">
                    <button class="btn-uoload-custom">
                      Upload Thumbnail for video
                    </button>
                    <input
                      type="file"
                      // name="myfile"
                      onChange={(e) => {
                        setthumbNail(
                          e.target.files[0] ? e.target.files[0] : null
                        );
                        UploadThumbnail(e.target.files[0])

                      }}
                      accept="image/*"
                    />
                  </div>
                  :
                  <>
                    <div className="mx-5 upload-btn-wrapper px-3">
                      <button class="btn-uoload-custom">
                        Update Thumbnail for video
                      </button>
                      <input
                        type="file"
                        // name="myfile"
                        onChange={(e) => {
                          setthumbNail(
                            e.target.files[0] ? e.target.files[0] : null
                          );
                          UpdateThumbnail(e.target.files[0], product.thumbnail[0].thumbnailId)

                        }}
                        accept="image/*"
                      />
                    </div>
                    <span className="mx-5">
                      {thumbnail === null ? (
                        <img
                          src={thumbnail}
                          style={{ height: "100px", width: "100px" }}
                        />
                      ) : (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src={thumbnail}
                        />
                      )}
                      <img
                        onClick={() => deleteThumbnail(product.thumbnail[0].thumbnailId)}
                        src={delIcon}
                        alt="delete"
                        className="del-icon"
                        style={{ cursor: "pointer", marginTop: "20px" }}
                      />
                    </span>
                  </>
                }
              </div> */}

                </div>
              </>
            )}


            {/* <div className="button">
              <button
                onClick={() => {
                  axiosauth
                    .post("/product-variant/update-product-variant", {
                      youtubeUrl: youtubeurl,
                      productVariantId: localStorage.getItem("variant"),
                      baseProduct: localStorage.getItem("baseProduct"),
                    })
                    .then((response) => {
                      let res = JSON.parse(response.data);
                      if (res?.success === true) {
                        alert("Successfully Updated");
                        history.goBack();
                      } else {
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      // setNetworkError(true)
                    })
                    .then(() => {
                      console.log("-----always executes");
                    });
                }}
              >
                Update
              </button>
            </div> */}
          </>)}
      </div>

    </Layout>
  );
};

export default EditVariantImage;
