import {
  FETCH_CATEGORY,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  CREATE_CATEGORY,
  FETCH_CATEGORY_BY_ID,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  ADD_IMAGE
} from '../constants/types';

const initialState = {
  loading: false,
  data: [],
  error: ''
}

export function categoryData(state = initialState, action) {
  
  switch (action.type) {
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case CREATE_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }


    case FETCH_CATEGORY:
      return {
        ...state,
        data: [],
        loading: true,
      }
    case FETCH_CATEGORY_BY_ID:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }

    case UPDATE_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }
    case DELETE_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }

    case FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload
      }
    case ADD_IMAGE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }

    default:
      return state;
  }
}