import {
    CATALOGUE_BY_ID,
    USER_FAIL,
    USER_LOADING
} from '../constants/types';

const initialState = {
    loading: false,
    data: [],
    category:[],
    error: ''
}

export function catalogueData(state = initialState, action) {
    switch (action.type) {
        case CATALOGUE_BY_ID:
            return {
                ...state,
                data: action.payload,
                loading: false
            }

        case USER_LOADING:
            return {
                ...state,
                data: [],
                loading: true
            }
        case USER_FAIL:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
}
