import React from 'react'
import Layout from '../../layout/layout'
import { ViewOptionsDetails } from './ViewOptionsDetails'

export const ViewOptions = () => {
    return (
         <Layout> 
              <ViewOptionsDetails/>
         </Layout>
    )
}
