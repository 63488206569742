import { combineReducers } from "redux";
import { isError, isLoading, errorMessage } from "./utils";
import { loginSuccess, authenticated, sessionExpiry } from "./login";
import { registerSuccess } from "./register";
import { userData } from "./userReducer";
import { collectionData } from "./collectionReducer";
import { couponData, couponCount } from "./couponReducer";
import { categoryData } from './categoryReducer';
import { subCategoryData } from './subcategoryReducer';
import { subSubCategoryData } from './subSubCategoryReducer';
import { productData } from './productReducer';
import { pageData, pageUpdate } from './cmsReducer';
import { changePassword } from './settingReducer';
import { variantData } from './variantReducer';
import { authData } from './authReducer';
import { bannerData } from './bannerReducer';
import { catalogueData } from './catalogueReducer';

export default combineReducers({
  isError,
  isLoading,
  errorMessage,
  sessionExpiry,
  loginSuccess,
  authenticated,
  registerSuccess,
  userData,
  collectionData,
  couponData,
  couponCount,
  categoryData,
  subCategoryData,
  subSubCategoryData,
  productData,
  pageData,
  pageUpdate,
  changePassword,
  variantData,
  authData,
  bannerData,
  catalogueData
});
