import React, { useState, useEffect } from 'react'; 
// import { useDispatch } from 'react-redux'; 
import axiosauth from '../../axios-config-auth';
import Loader from 'react-loader-spinner';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { Link, useHistory } from 'react-router-dom'; 
import deleteIcon from '../../assets/icons/delete.png'; 
import { confirmAlert } from 'react-confirm-alert';
import NoData from '../../components/halper/NoData';
import editIcon from '../../assets/icons/edit.png';

const TopBanner = () => {
    const history = useHistory();

    const [bannerList, setBannerlist] = useState([]);
    const [showLoader, setShowLoader] = useState(true); 

    useEffect(() => {
        let obj = {
            "filters": {
                typeId: "O4FFLiVa"
            }
        }
        axiosauth.post('/banner/load-banner', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("jdkd", res)
                if (res.success === true) {
                    console.log("banner true-->", res.data.banners);
                    setBannerlist(res.data.banners)
                    setShowLoader(false)
                } else { 
                    setShowLoader(false)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }, []) 

    const loadBanner = () => {

        let obj = {
            "filters": {
                typeId: "O4FFLiVa"
            }
        }
        axiosauth.post('/banner/load-banner', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("jdkd", res)
                if (res.success === true) {
                    console.log("banner true-->", res.data.banners);
                    setBannerlist(res.data.banners)
                } else {
                    setBannerlist([])

                    console.log("banner fasle-->", res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }



    return (
        <>

            <div className="left_arrow2 back-arrow mt-5" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                <h2>Manage Top Banner</h2>
                
            </div>
            <div className="button">
                    <Link to="/add-top-banner">
                        <button>Add Top Banner</button>
                    </Link>
                </div>
            {!showLoader ?
            <div className="table-wrap overflow-auto table-responsive card py-4 " style={{height:"500px"  }}>
                <table>
                    {bannerList && bannerList.length > 0 ? (<thead>
                        <tr>
                            <th>Banner .No</th>
                            <th>Banner Title</th>
                            <th>Desktop Banner Image</th>
                            <th>Mobile Banner Image</th>
                            <th>Edit image</th>
                            {/* <th>Edit Details</th> */}
                            <th>Actions</th>
                        </tr>
                    </thead>) : null}
                    <tbody>
                        {bannerList && bannerList.length > 0 ?

                            bannerList.map((banner, index) => {



                                const deleteBanner = () => {
                                    let obj = {
                                        "bannerIds": [banner.bannerId],
                                    }
                                    confirmAlert({
                                        // title: 'Change Status',
                                        message: 'Are you sure you want to delete this Banner ?',
                                        buttons: [
                                            {
                                                label: 'Yes',
                                                onClick: () => {
                                                    axiosauth.post('/banner/delete-banner', obj)
                                                        .then(async (response) => {

                                                            let res = JSON.parse(response.data)
                                                            if (res.success === true) {
                                                                loadBanner()
                                                            } else {
                                                                loadBanner()
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        })
                                                }
                                            },
                                            {
                                                label: 'No',
                                                onClick: () => console.log("Hello")
                                            }
                                        ]
                                    });

                                }




                                //   let imgUrl=`https://arjoi-assets.s3.ap-south-1.amazonaws.com${banner.image}`


                                return (
                                    <tr key={index + 1}>
                                        <td>{index + 1}</td>
                                        <td className="titleStyle title-name">{banner.title}</td>
                                        <td className="collection-img">
                                            {/* {banner.image} */}

                                            <img src={banner.image} alt="" />
                                        </td>
                                        <td className="collection-img">
                                        <img src={banner.mobileImage} alt="" />
                                        </td>
                                        {/* <td>
                                             <Link to={`/update-brand/${banner.brandId}`} >
                                                <img onClick={() => localStorage.setItem("banner", banner.brandId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                            </Link>
                                            
                                          
                                            </td> */}
                                        <td>
                                            <Link to={`/edit-banner-image`}>
                                                <img onClick={() => localStorage.setItem("bannerId", banner.bannerId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/edit-top-banner`}>
                                                <img onClick={() => localStorage.setItem("bannerId", banner.bannerId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                            </Link>
                                            <img onClick={() => deleteBanner()} src={deleteIcon} alt="delete" className="delete-icon" />


                                        </td>
                                    </tr>
                                )
                            })

                            :
                            (<NoData message="Banner Not Found" />
                            )
                        }

                    </tbody>
                </table>


            </div>
            :
                <div className="loader-style">
                  <Loader
                    type="ThreeDots"
                    color="rgba(32, 159, 132, 1)"
                    height={100}
                    width={100}
                    timeout={30000} //3 secs
                  />
                </div>
               }
        </>
    )
}

export default TopBanner
