import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout"; 
import uploadIcon from "../../assets/icons/upload.png";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBanners } from "../../redux/actions/bannerAction";
import axiosauth from "../../axios-config-auth";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory } from "react-router-dom"; 

import SuccessModal from "../../components/halper/SuccessModal";
import LoaderModal from "../../components/halper/LoaderModal"; 
import { handleToast } from "../../utils/customFunction";

const AddTopBanner = () => { 

  const [show, setShow] = useState(false);

  const history = useHistory();
  const [file, setfile] = useState(null);
  const [phoneFile, setPhoneFile] = useState(null);
  const [urlFile, setUrlFile] = useState(null);
  const [phoneUrlFile, setPhoneUrlFile] = useState(null);

  const [titleError, setTitleError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [phoneUrlError, setPhoneUrlError] = useState(null);

  const [showLoader, setShowLoader] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [bannerList, setBannerlist] = useState([]);
  const [linkerror, setlinkerror] = useState("");
  // const banners = useSelector((state) => state.bannerdata);
  // console.log("banner data->", banners, "type=>", typeof banners);

  // console.log("banner-->", urlFile); 
  const dispatch = useDispatch();
  const { errorMsg } = handleToast();

  const [slideBanner, setSlideBanner] = useState({
    title: "",
    newtab: false,
    link: "http://toyvala-main-website.s3-website.ap-south-1.amazonaws.com/",
    alt: "",
    available: true,
    typeId: "O4FFLiVb",
  });

  const handleChange = (e) => {
    setSlideBanner({
      ...slideBanner,
      error: false,
      [e.target.name]: e.target.value,
    });
    setTitleError("");
  };

  const bannerUpload = () => { 

    if (slideBanner.title === "") {
      setTitleError("Banner title Required");
      return 0;
    }
    if (slideBanner.link === null) {
      setlinkerror("Page Url required");
      return 0;
    }
    if (file === null) {
      setUrlError("Desktop Banner required");
      return 0;
    }
    if (phoneFile === null) {
      setPhoneUrlError("Mobile Banner required");
      return 0;
    }

    var formData = new FormData();

    formData.append("title", slideBanner.title);
    formData.append("link", slideBanner.link);
    formData.append("newtab", false);
    formData.append("alt", "wide-image");
    formData.append("available", true);
    formData.append("typeId", "O4FFLiVa");
    formData.append("image", urlFile);
    formData.append("mobileImage", phoneUrlFile);

    setShowLoader(true);

    axiosauth
      .post("/banner/post-banner", formData)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setShowLoader(false);
          setShow(true);
        } else {
          setShowLoader(false);
          errorMsg(JSON.parse(response?.data)?.message)
        }
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
 
  };

  useEffect(() => {
    let obj = {};
    axiosauth
      .post("/banner/load-banner", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        // console.log("jdkd", res)
        if (res.success === true) { 
          setBannerlist(res.data.banners);
        } 
        else {
          console.log("banner fasle-->", res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch(fetchBanners());
  }, [dispatch]);
 

  const closeModal = () => {
    setRedirect(true);
  };

  const connectionError = () => {
    setNetworkError(false);
  };

  return (
    <Layout>
      {redirect ? <Redirect to="/top-banner" /> : null}
      {show === true ? (
        <SuccessModal message="New Banner Added!" onClick={closeModal} />
      ) : null}
      {networkError === true ? (
        <SuccessModal
          message="Internet Connection Error.."
          onClick={connectionError}
        />
      ) : null}
      {showLoader === true ? <LoaderModal /> : null}

      <div className="form-wrap"> 
        <div
          className="left_arrow2 back-arrow"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            onClick={() => history.goBack()}
            src={left_arrow2}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Add Top Banner</h2>
        </div>
        {/* </div> */}
        <div className="form-body">
          <div className="single-field">
            <p>
              Banner Title <b>*</b>
            </p>
            <input
              type="text"
              id="title"
              placeholder="Enter title"
              name="title"
              onChange={handleChange}
            />
            <i className="error">{titleError}</i>
          </div>
 

          <div className="single-field">
            <p>
              Banner URL <b>*</b>
            </p>
            <input
              type="text"
              id="link"
              placeholder="page url"
              name="link"
              onChange={handleChange}
            />
            <i className="error">{linkerror}</i>
          </div>

          <div className="banner-wrap">
            <>
              {file ? <img src={file} alt="" /> : null}

              <p className="label-form my-5">
              {`Image Dimension Must be (2560 X 867) , image (jpg, png , WebP) and image size must be less than < 300KB` }<b>*</b>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <div class="upload-btn-wrapper1 desk-banner">
                  <button className="uplod-btn1">
                    <img src={uploadIcon} alt="" />
                    <p>
                      Upload Desktop image
                      <br />
                      <span className="mb-3" style={{ fontSize: "10px" }}>
                      2560 X 867
                      </span>
                    </p>
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    onChange={(e) => {
                      if (e.currentTarget.files[0]?.size >= 307200) {
                        alert("Images size must be less than 300KB")
                        return 0
                    }
                      setUrlError(null);

                      let img = new Image();
                      img.src = window.URL.createObjectURL(e.target.files[0]);
                      setUrlFile(e.currentTarget.files[0]);
                      img.onload = () => {
                        if (img.width === 2560 && img.height === 867) {
                          setfile(img.src);
                        } else {
                          alert(
                            "Incorrect image dimension. Please upload an image of 2560 X 867 pixels."
                          );
                        }
                      };
                    }}
                  />
                </div>

                <div style={{ display: "flex" }}></div>
              </div>
            </>
            <i className="error">{urlError}</i>
          </div>
          <div className="banner-wrap">
            <>
              {phoneFile ? <img src={phoneFile} alt="" /> : null}

              <p className="label-form my-5">
              {`Image Dimension Must be (1000 X 1048) , image (jpg, png , WebP) and image size must be less than < 300KB` }<b>*</b>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <div class="upload-btn-wrapper1 desk-banner">
                  <button className="uplod-btn1">
                    <img src={uploadIcon} alt="" />
                    <p>
                      Upload Mobile image
                      <br />
                      <span className="mb-3" style={{ fontSize: "10px" }}>
                      1000 X 1048
                      </span>
                    </p>
                  </button>
                  <input
                    type="file"
                    name="myfile1"
                    onChange={(e) => {
                      if (e.currentTarget.files[0]?.size >= 307200) {
                        alert("Images size must be less than 300KB")
                        return 0
                    }
                    setPhoneUrlError(null);

                      let img = new Image();
                      img.src = window.URL.createObjectURL(e.target.files[0]);
                      setPhoneUrlFile(e.currentTarget.files[0]);
                      img.onload = () => {
                        if (img.width === 1000 && img.height === 1048) {
                          setPhoneFile(img.src);
                        } else {
                          alert(
                            "Incorrect image dimension. Please upload an image of 1000 X 1048 pixels."
                          );
                        }
                      };
                    }}
                  />
                </div>

                <div style={{ display: "flex" }}></div>
              </div>
            </>
            <i className="error">{phoneUrlError}</i>
          </div>

        </div>
        <div className="button">
          <button onClick={bannerUpload}>Add Banner</button>
        </div>
      </div>
    </Layout>
  );
};

export default AddTopBanner;
