import React, { useState ,useEffect} from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axiosauth from '../../axios-config-auth';
import deleteIcon from '../../assets/icons/delete.png';
import NoData from '../../components/halper/NoData';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import editIcon from '../../assets/icons/edit.png';
// import LoaderModal from '../../components/halper/LoaderModal';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';




const TandC = () => {
  const history = useHistory()

    const [helpYou , setHelpYou] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const [orderStatus, setShowOrderStatus] = useState(true);
    const [delivery, setShowDelivery] = useState(false);
    const [cancellation, setShowCancellation] = useState(false);
    const [returns, setShowReturns] = useState(false);
    const [paymentOpt, setShowPaymentOpt] = useState(false);
    const [contactUs, setShowContactUs] = useState(false);
    const [customercare, setShowCustomerCare] = useState(false);
    const [faq, setShowFaq] = useState(false);

    const handleOrderStatus = () => {
        setShowOrderStatus(true);
        setShowDelivery(false);
        setShowCancellation(false);
        setShowReturns(false);
        setShowPaymentOpt(false);
        setShowContactUs(false);
        setShowCustomerCare(false);
        setShowFaq(false);
    }
    const handleDelivery = () => {
        setShowOrderStatus(false);
        setShowDelivery(true);
        setShowCancellation(false);
        setShowReturns(false);
        setShowPaymentOpt(false);
        setShowContactUs(false);
        setShowCustomerCare(false);
        setShowFaq(false);
    }
    const handleCancelllation = () => {
        setShowOrderStatus(false);
        setShowDelivery(false);
        setShowCancellation(true);
        setShowReturns(false);
        setShowPaymentOpt(false);
        setShowContactUs(false);
        setShowCustomerCare(false);
        setShowFaq(false);
    }
    const handleReturns = () => {
        setShowOrderStatus(false);
        setShowDelivery(false);
        setShowCancellation(false);
        setShowReturns(true);
        setShowPaymentOpt(false);
        setShowContactUs(false);
        setShowCustomerCare(false);
        setShowFaq(false);
    }
    const handlePaymentsOpt = () => {
        setShowOrderStatus(false);
        setShowDelivery(false);
        setShowCancellation(false);
        setShowReturns(false);
        setShowPaymentOpt(true);
        setShowContactUs(false);
        setShowCustomerCare(false);
        setShowFaq(false);
    }
    const handleContactUs = () => {
        setShowOrderStatus(false);
        setShowDelivery(false);
        setShowCancellation(false);
        setShowReturns(false);
        setShowPaymentOpt(false);
        setShowContactUs(true);
        setShowCustomerCare(false);
        setShowFaq(false);
    }
    const handleCustomerCare = () => {
        setShowOrderStatus(false);
        setShowDelivery(false);
        setShowCancellation(false);
        setShowReturns(false);
        setShowPaymentOpt(false);
        setShowContactUs(false);
        setShowCustomerCare(true);
        setShowFaq(false);
    }
    const handleFAQ = () => {
        setShowOrderStatus(false);
        setShowDelivery(false);
        setShowCancellation(false);
        setShowReturns(false);
        setShowPaymentOpt(false);
        setShowContactUs(false);
        setShowCustomerCare(false);
        setShowFaq(true);
    }

    useEffect(() => {

        const obj = {
            filters: {
                headName: "LET US HELP YOU"
            }
        }
setShowLoader(true)
        axiosauth.post('/footer/list_footer', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data) 
                if (res.success === true) {
                    setHelpYou(res.data)
                    setShowLoader(false)
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
                setShowLoader(false)
            })
    }, [])




    return (
        <> 
         <div className="button">
            <button onClick={()=>history.push('/add-letus-help-you')}>Add page</button>
          </div>
          {!showLoader ?
        <div className="table-wrap">
        <table>
        {helpYou && helpYou.length > 0 ?(<thead>
            <tr>
            <th>S.No</th>
              <th>Page Name</th>
              <th>Link</th> 
              <th>status</th>
              <th></th>
            </tr>
          </thead>):null}  
          <tbody>

                {
                  helpYou && helpYou.length > 0 ?
                    <>
                      {
                        helpYou.map((user, index) => {

                          const deleteUser = (id) => {
                            let obj = {
                              id:id
                            } 
                            confirmAlert({
                              // title: 'Change Status',
                              message: 'Are you sure you want to delete this page? ',
                              buttons: [
                                {
                                  label: 'Yes',
                                  onClick: () => {
                                    axiosauth.post('/footer/delete-footer' ,obj)
                                    .then(async (response) => {
                                        let res = JSON.parse(response.data)
                                        if (res.success === true) {
                                          const obj1 = {
                                            filters: {
                                                headName: "LET US HELP YOU"
                                            }
                                        }
                                setShowLoader(true)
                                        axiosauth.post('/footer/list_footer', obj1)
                                            .then(async (response) => {
                                                let res = JSON.parse(response.data)
                                                console.log("res:", res)
                                                if (res.success === true) {
                                                    setHelpYou(res.data)
                                                    setShowLoader(false)
                                                } else {
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                setShowLoader(false)
                                            })
                                        } else {
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                                  }
                                },
                                {
                                  label: 'No',
                                  onClick: () => {
                                    console.log("not detele")
                                  }
                                }
                              ]
                            });
                          }

                          return (
                            <tr key={index + 1}>
                            <td>{ (index+1)}</td>
                              <td className="titleStyle">{user?.name}</td>
                              <td>{(user?.url).length>0 ?user?.url : <p className="mt-3">link not Found</p>}</td>
                              <td>{user?.suspend === false ? <p className="mt-3">Active</p> :  <p className="mt-3">Inactive</p> }</td>
                       
                              <td>
                              <Link to={`/update-cms/${user._id}`} >
                                                <img   src={editIcon} alt="edit-button" className="edit-icon" />
                               </Link>
                                <img onClick={()=>deleteUser(user._id)} src={deleteIcon} alt="" className="delete-icon" />
                              </td>
                            </tr>
                          )
                        }
                        )}
                    </>
                    : (<NoData message="Page Not Found" />)
                }
              
          </tbody>
        </table>
      
        {/* <div className="pagination-custom">
          <Pagination
            hideDisabled
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={userData.data.count}
            pageRangeDisplayed={5}
            hideNavigation
            onChange={handlePageChange5}
          />
        </div> */}
      </div>
       : 
       <div className="loader-style">
      <Loader
        type="ThreeDots"
        color="rgba(32, 159, 132, 1)"
        height={100}
        width={100}
        timeout={500000}
      />
    </div> }

 

            {/* <div className="cms-wrap">
                <div className="menu-bar category-menu mx-2">
                    <section>
                        <div className={orderStatus ? "active-menu" : ""} onClick={handleOrderStatus}>Order Status</div>
                        <div className={delivery ? "active-menu" : ""} onClick={handleDelivery}>Delivery</div>
                        <div className={cancellation ? "active-menu" : ""} onClick={handleCancelllation}>Cancellation</div>
                        <div className={returns ? "active-menu" : ""} onClick={handleReturns}>Returns</div>

                        <div className={paymentOpt ? "active-menu" : ""} onClick={handlePaymentsOpt}>Payment Options</div>
                        <div className={contactUs ? "active-menu" : ""} onClick={handleContactUs}>Contact Us</div>
                        <div className={customercare ? "active-menu" : ""} onClick={handleCustomerCare}>24X7 Customer Care</div>
                        <div className={faq ? "active-menu" : ""} onClick={handleFAQ}>FAQs</div>

                    </section>
                </div>

                {orderStatus && (
                    <OrderStatus />
                )}

                {delivery && (
                    <Delivery />
                )}

                {cancellation && (
                    <Cancellation />
                )}

                {returns && (
                    <Returns />
                )}
                {paymentOpt && (
                    <PaymentsOpt />
                )}

                {contactUs && (
                    <ContactUs />
                )}

                {customercare && (
                    <CustomerCare />
                )}

                {faq && (
                    <FAQ />
                )}


            </div> */}
        </>
    )
}

export default TandC
