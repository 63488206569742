import React, { useEffect,useState } from 'react';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import { useParams,useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { loadUserById } from '../../redux/actions/userAction';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import axiosauth from "../../axios-config-auth";
import { Link } from 'react-router-dom';

import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
const UserDetail = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(state => state.userData); 

  console.log("userData======>>>>", userData)

const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[300],
    '&$checked': {
      color: purple[500],
    },
    '&$checked + $track': {
      backgroundColor: purple[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const [banUser, setBanUser] = useState(userData.data.active);

const statusChange = (e) => { 
    let obj = {
        "user": userId,
        "keyValue": {
            "active":!userData.data.active
        }
    };
    let message=""
    if(!banUser===true)
    {
        message='Are you sure you want to ban this user?'
    }
    else{
        message='Are you sure you want to unban this user?'

    }
    confirmAlert({ 
        message: message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    // setBanUser(obj.keyValue.active);
                    axiosauth
                        .post("/admin/update-userinfo", obj)
                        .then(async (response) => {
                            let res = JSON.parse(response.data);
                            if (res.success === true) { 
                                dispatch(loadUserById({ userId: userId }));
                              } else {
                                dispatch(loadUserById({ userId: userId })); 
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            },
            {
                label: 'No',
                onClick: () => {
                    console.log("not ban")
                }
            }
        ]
    });

}

  useEffect(() => {

    dispatch(loadUserById({ userId: userId }));
  }, [userId, dispatch]);

  return (
    <>
      {userData.loading ?

        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={3000} //3 secs

          />
        </div>
        :

        <div>
          <section className="detail-heading">
          <div className="left_arrow2 back-arrow"  style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" />
            <h2>View Details</h2>
          </div>
          <div className="ml-5"> UnBann <Switch
        checked={!userData.data.active}
        // onChange={handleChange}
        onChange={statusChange}

        control={<PurpleSwitch checked={!userData.data.active} onChange={statusChange} name="checkedA" />}
        label="Custom color"
      />
      Ban</div>
          </section>
              <section className="detail-body" >
                <div>Name</div>
               
                <div  className="titleStyle"> {userData.data.firstName +" "+userData.data.lastName }</div>
              </section>

              <section className="detail-body" >
                <div>username</div>
                <div  className="titleStyle">{userData.data.username}</div>
              </section>

              <section className="detail-body" >
                <div>Customer Id</div>
                <div className="capital">{userId.slice(0, 10)}</div>
              </section>

              <section className="detail-body" >
                <div>Email Id</div>
                <div>{userData.data.userEmail}</div>
              </section>

              <section className="detail-body" >
                <div>contact number</div>
                <div>{userData.data.temporaryMobile}</div>  
              </section>
           {userData.data.cart!==undefined&&userData.data.cart.length>0?( <section  className="detail-body" >
                <div>Cart</div>
              <div>  <Link to={`/cart-view/${userData.data.userId}`}>                           
                            View Details
                            </Link></div>
              </section>):null}  
              <section className="detail-body" >
                <div>Past Orders</div>
                <div onClick={()=>{
                  localStorage.setItem("orderId",userData.data._id)
                }}><Link to={`/past-orders-view/${userData.data.userId}`}>                           
                            View Details
                            </Link></div>  
              </section>
              {/* <section className="detail-body" >
                <div>message</div>
                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque.</div>
              </section> */}
        </div>
      }
    </>
  )
}

export default UserDetail