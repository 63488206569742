import React, { useState , useEffect} from 'react' ; 
// import Facebook from "./cmspages/connect_with_us/Facebook"
// import YouTube from "./cmspages/connect_with_us/YouTube"
// import Instagram from "./cmspages/connect_with_us/Instagram"
// import Twitter from "./cmspages/connect_with_us/Twitter"
// import Koo from "./cmspages/connect_with_us/Koo";
import axiosauth from '../../axios-config-auth';
import deleteIcon from '../../assets/icons/delete.png';
import NoData from '../../components/halper/NoData';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import editIcon from '../../assets/icons/edit.png';
// import LoaderModal from '../../components/halper/LoaderModal';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';

const ReturnPolicy = () => {

  const [connectUs , setConnectUs] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
 
    const history = useHistory()

    

    useEffect(() => {

        const obj = {
            filters: {
                headName: "CONNECT WITH US"
            }
        }
setShowLoader(true)
        axiosauth.post('/footer/list_footer', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data) 
                if (res.success === true) { 
                    setConnectUs(res.data)
                    setShowLoader(false)
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
                setShowLoader(false)
            })
    }, [])

    return (
        <> 
         <div className="button">
            <button onClick={()=>history.push('/add-connect-withus')}>Add Page</button>
          </div>
 {!showLoader ?
        <div className="table-wrap">
        <table>
        {connectUs && connectUs.length > 0 ?(<thead>
            <tr>
            <th>S.No</th>
              <th>Page Name</th>
              <th>Link</th> 
              <th>status</th>
              <th></th>
            </tr>
          </thead>):null}  
          <tbody>

                {
                  connectUs && connectUs.length > 0 ?
                    <>
                      {
                        connectUs.map((user, index) => {

                          const deleteUser = (id) => {
                            let obj = {
                              id:id
                            } 
                            confirmAlert({
                              // title: 'Change Status',
                              message: 'Are you sure you want to delete this page? ',
                              buttons: [
                                {
                                  label: 'Yes',
                                  onClick: () => {
                                    axiosauth.post('/footer/delete-footer' ,obj)
                                    .then(async (response) => {
                                        let res = JSON.parse(response.data)
                                        if (res.success === true) {
                                          const obj = {
                                            filters: {
                                                headName: "CONNECT WITH US"
                                            }
                                        }
                                setShowLoader(true)
                                        axiosauth.post('/footer/list_footer', obj)
                                            .then(async (response) => {
                                                let res = JSON.parse(response.data)
                                                console.log("res:", res)
                                                if (res.success === true) {
                                                    console.log("class", res)
                                                    setConnectUs(res.data)
                                                    setShowLoader(false)
                                                } else {
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                setShowLoader(false)
                                            })
                                        } else {
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                                  }
                                },
                                {
                                  label: 'No',
                                  onClick: () => {
                                    console.log("not detele")
                                  }
                                }
                              ]
                            });
                          }

                          return (
                            <tr key={index + 1}>
                            <td>{ (index+1)}</td>
                              <td className="titleStyle">{user?.name}</td>
                              <td>{(user?.url).length>0 ?user?.url : <p className="mt-3">link not Found</p>}</td>
                              <td>{user?.suspend === false ? <p className="mt-3">Active</p> :  <p className="mt-3">Inactive</p> }</td>
                       
                              <td>
                              <Link to={`/update-cms/${user._id}`} >
                                                <img   src={editIcon} alt="edit-button" className="edit-icon" />
                               </Link>
                                <img onClick={()=>deleteUser(user._id)} src={deleteIcon} alt="" className="delete-icon" />
                              </td>
                            </tr>
                          )
                        }
                        )}
                    </>
                    : (<NoData message="Page Not Found" />)
                }
              
          </tbody>
        </table>
      
        {/* <div className="pagination-custom">
          <Pagination
            hideDisabled
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={userData.data.count}
            pageRangeDisplayed={5}
            hideNavigation
            onChange={handlePageChange5}
          />
        </div> */}
      </div>
      : 
      <div className="loader-style">
     <Loader
       type="ThreeDots"
       color="rgba(32, 159, 132, 1)"
       height={100}
       width={100}
       timeout={500000}
     />
   </div> }

 
        
         {/* <div className="cms-wrap">
                <div className="menu-bar category-menu mx-2">
                    <section>
                        <div className={youTube ? "active-menu" : ""} onClick={handleYouTube}>YouTube</div>
                        <div className={facebook ? "active-menu" : ""} onClick={handleFacebook}>Facebook</div>
                        <div className={instagram ? "active-menu" : ""} onClick={handleInstagram}>Instagram</div>
                        <div className={twitter ? "active-menu" : ""} onClick={handleTwitter}>Twitter</div> 
                        <div className={koo ? "active-menu" : ""} onClick={handleKoo}>Koo</div>
                         
                    </section>
                </div>

                {youTube && ( 
                    <YouTube/>
                )}

                {facebook && (
                    <Facebook />
                )}

                {instagram && (
                    <Instagram />
                )}

                {twitter && (
                    <Twitter />
                )}
                {koo && (
                    <Koo />
                )}

                
            </div> */}
            
        </>
    )
}

export default ReturnPolicy
