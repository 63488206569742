import React from 'react'

const DownloadExcelModal = ({children}) => {
    return (
        <div className="excel-bg">
            <div className="excel-success">
            {children}
            </div>
        </div>
    )
}

export default DownloadExcelModal
