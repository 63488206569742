import React, { useState, useEffect } from 'react';
import Layout from "../../layout/layout";
// import CustomCropper from '../../components/halper/CustomCropper';
// import editImageIcon from '../../assets/icons/edit.png';
import { Redirect } from "react-router-dom";
import { useDispatch} from 'react-redux';
import { fetchBanners } from '../../redux/actions/bannerAction';
import axiosauth from '../../axios-config-auth';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { useHistory } from 'react-router-dom';
// import editIcon from '../../assets/icons/edit.png';

import SuccessModal from '../../components/halper/SuccessModal';
import LoaderModal from '../../components/halper/LoaderModal';
// import deleteIcon from '../../assets/icons/delete.png';
// import noImage from '../../assets/images/no-img.png';
const EditFooterBanner = () => {

    const [show, setShow] = useState(false);

    const history = useHistory();
    // eslint-disable-next-line
    const [file, setfile] = useState(null);
    // eslint-disable-next-line
    const [urlFile, setUrlFile] = useState(null)

    const [titleError, setTitleError] = useState(null)
    // eslint-disable-next-line
    const [urlError, setUrlError] = useState(null)

    const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [linkerror, setlinkerror] = useState("")

    // const banners = useSelector(state => state.bannerdata)
    // console.log("banner data->", banners, "type=>", typeof banners)

    console.log("banner-->", urlFile);
    // banner_list=[]
    // for(i=0;i<banners.length;i++)
    const dispatch = useDispatch()

    const [slideBanner, setSlideBanner] = useState({
        title: "",
        newtab: false,
        link: "",
        alt: "",
        available: true,
        typeId: "O4FFLiVa"
    });

    const handleChange = (e) => {
        setSlideBanner({ ...slideBanner, error: false, [e.target.name]: e.target.value });
        setTitleError("")
    }



    const bannerUpload = () => {

        // console.log("obj-->", obj)
        if (slideBanner.title === "") {
            setTitleError("Banner title Required");
            return 0;
        }
        if (slideBanner.link === "") {
            setlinkerror("Page Url Required");
            return 0;
        }


        let obj = {
            
	"bannerId": localStorage.getItem("bannerId"),
	"title":slideBanner.title,
	"link":slideBanner.link,
	"available":true
            // "bannerId": localStorage.getItem("bannerId"),
            // "title": slideBanner.title,
            // "link": slideBanner.link,
            // "newtab": false,
            // "available": true,
            // "alt": "banner-image",
            // "typeId": "O4FFLiVa"
        }
        // var formData = new FormData();
        // formData.append("bannerId", localStorage.getItem("bannerId"));

        // formData.append("title", slideBanner.title);
        // formData.append("link", slideBanner.link);
        // formData.append("newtab", false);
        // formData.append("alt", "banner-image");
        // formData.append("available", true);
        // formData.append("typeId", "O4FFLiVb");

        setShowLoader(true)

        axiosauth.post('/banner/update-banner', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setShowLoader(false)
                    setShow(true)
                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            })


        // dispatch(uploadBanners(formData))
    }

    useEffect(() => {

        let obj = {
            bannerId: localStorage.getItem("bannerId")
        }
        axiosauth.post('/banner/get-banner-by-id', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("jdkd", res)
                if (res.success === true) {
                    console.log("banner true-->", res.banner);
                    setSlideBanner({
                        title: res.banner.title,
                        newtab: false,
                        link: res.banner.link,
                        alt: "",
                        available: true,
                        typeId: "O4FFLiVc"
                    })
                    setfile(res.banner.image)


                } else {
                    console.log("banner fasle-->", res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })


        dispatch(fetchBanners())
    }, [dispatch])



    const closeModal = () => {
        setRedirect(true);
    }

    const connectionError = () => {
        setNetworkError(false)
    }


    return (
        <Layout>

            {redirect ? <Redirect to="/footer-banner" /> : null}
            {show === true ? <SuccessModal message=" Banner Updated!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null}



            <div className="form-wrap">
                {/* <div className="form-header"> */}
                {/* <h4>Add Brand</h4> */}

                <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                    <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" /> &nbsp;
                    <h2>Update Footer Banner</h2>
                </div>
                {/* </div> */}
                <div className="form-body">

                    <div className="single-field">
                        <p>Banner Title <b>*</b></p>
                        <input type="text"
                            id="title"
                            placeholder="Enter title"
                            name="title"
                            value={slideBanner.title}
                            onChange={handleChange}
                        />
                        <i className="error">{titleError}</i>
                    </div>




                    {/* <i className="error">{urlError}</i> */}
                    {/* <div id="short-description">
                        <p>Banner Description <b>*</b></p>
                        <textarea
                            placeholder="Short Description"
                            name="description"
                            onChange={handleChange}
                           
                        />
                       
                    </div> */}

                    <div className="single-field">
                        <p>Banner URL <b>*</b></p>
                        <input type="text"
                            id="link"
                            placeholder="page url"
                            name="link"
                            value={slideBanner.link}
                            onChange={handleChange}
                        />
                        <i className="error">{linkerror}</i>
                    </div>


                    {/* 

                    <section className="image-uploaded">
                        {file === null && result=== null ?
                            <aside class="upload-btn-wrapper mt-2">
                                <button className="uplod-btn">
                                    <img src={uploadIcon} alt="" /><br />
                                Upload Banner
                                
                                </button>
                                <input
                                    type="file"
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.currentTarget.files[0].size >= 400000) {
                                            alert("Images size must be less than 400kb")
                                            return 0
                                        }

                                        let img = new Image()
                                        img.src = window.URL.createObjectURL(e.target.files[0]);

                                        img.onload = () => {
                                        if(img.width >= 300 && img.height >= 300){       
                                            setfile(img.src);

                                                } else {
                                                    alert('Incorrect image dimension. Please upload an image of 300x300 pixels.')
                                                   
                                                }                
                                        }

                                    }}
                                />
                                
                            </aside> :
                            <aside class="image">
                                <div class="overlay">
                                <label for="file-upload">
                                    <img for="upload-image" class="edit-img" src={editImageIcon} alt=""/>
                                </label>
                                </div>
                                <img class="screen" src={result ? result : file} alt=""/>
                                
                                 <input
                                    type="file"
                                    id="file-upload"
                                    style={{display:'none'}}
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.currentTarget.files[0].size >= 400000) {
                                            alert("Images size must be less than 400kb")
                                            return 0
                                        }
                                        
                                        
                                        let img = new Image()
                                        img.src = window.URL.createObjectURL(e.target.files[0]);

                                        img.onload = () => {
                                        if(img.width >= 300 && img.height >= 300){       
                                            setfile(img.src);

                                                } else {
                                                    alert('Incorrect image dimension. Please upload an image of 300x300 pixels.')
                                                   
                                                }                
                                        }

                                    }}
                                
                                />


                  

                            </aside>
                        }
                        
                    </section> */}



                    <div className="banner-wrap">


                        {/* {file ?
        <img src={file} alt="" />
:null} */}




                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <div class="upload-btn-wrapper1">
                <button className="uplod-btn1">
                    Upload image
        <img src={uploadIcon} alt="" />
                </button>
                <input
                    type="file"
                    name="myfile"
                    onChange={(e) => {
                        // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                        setUrlError(null)
                       

                        let img = new Image()
                            img.src = window.URL.createObjectURL(e.target.files[0]);
                            setUrlFile(e.currentTarget.files[0]);
                            img.onload = () => {
                            if(img.width >= 1200 && img.height >= 400){       
                                setfile(img.src);
                                    } else {
                                        alert('Incorrect image dimension. Please upload an image of 1200x400 pixels.')
                                        
                                    }                
                            }

                    }}
                />
            </div>

            <div style={{ display: 'flex' }}>

                <div class="upload-btn-wrapper1">
                    <button className="uplod-btn1" style={{ background: "#209F84", color: "#FFFFFF" }}>
                        change image
                    </button>
                    <input
                        type="file"
                        name="myfile"
                        onChange={(e) => {
                            // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                            setUrlError(null)
                            setUrlFile(e.currentTarget.files[0]);

                            let img = new Image()
                                img.src = window.URL.createObjectURL(e.target.files[0]);

                                img.onload = () => {
                                if(img.width >= 1200 && img.height >= 400){       
                                    setfile(img.src);

                                        } else {
                                            alert('Incorrect image dimension. Please upload an image of 1200x400 pixels.')
                                            
                                        }                
                                }


                        }}
                    />
                </div>
                <div className="banner-delete-button">
                    <button
                        onClick={(e) => {
                            setfile(null)
                            setUrlFile(null)
                        }}
                    >Delete</button>
                </div>
            </div>
        </div> */}

                    </div>


                    {/* <i className="error">{urlError}</i> */}



                </div>
                <div className="button">
                    <button onClick={bannerUpload}>Update Banner</button>
                </div>
            </div>


        </Layout>
    )
}

export default EditFooterBanner
