import {
    FETCH_COUPON,
    USER_LOADING,
    USER_FAIL,
    CREATE_COUPON,
    FETCH_COUPON_BY_ID,
    UPDATE_COUPON,
    COUPON_COUNT
} from '../constants/types';

const initialState = {
    loading: false,
    data: [],
    error: ''
}


export function couponData(state = initialState, action) {
    switch (action.type) {
        case FETCH_COUPON:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: ''
            }
        case FETCH_COUPON_BY_ID:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: ''
            }
        case CREATE_COUPON:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: ''
            }
        case UPDATE_COUPON:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: ''
            }
        case USER_LOADING:
            return {
                ...state,
                data: [],
                loading: true,
                error: ''
            }

        case USER_FAIL:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export function couponCount(state = initialState, action) {
    switch (action.type) {
        case COUPON_COUNT:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: ''
            }


        case USER_LOADING:
            return {
                ...state,
                data: [],
                loading: true,
                error: ''
            }

        case USER_FAIL:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}