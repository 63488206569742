import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../layout/layout";
import editIcon from "../../assets/icons/edit.png";
import deleteIcon from "../../assets/icons/delete.png";
import NoData from "../../components/halper/NoData";
import { useHistory } from "react-router-dom";
import axiosauth from "../../axios-config-auth";
import SuccessModal from "../../components/halper/SuccessModal";
import ErrorModal from "../../components/halper/ErrorModal";
import LoaderModal from "../../components/halper/LoaderModal";

const HomePageSeo = () => {
  const [list, setList] = useState({});
  const [responseData, setResponseData] = useState({
    success: false,
    error: false,
    msg: "",
  });
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const getData = async () => {
    setLoader(true);
    axiosauth
      .post("/homePageSeo/load-homepageseo")
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setLoader(false);
          setList(res?.homePageSeo);
        } else {
          setLoader(false);
          setResponseData({
            ...responseData,
            error: true,
            msg: res?.message,
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        setResponseData({
          ...responseData,
          error: true,
          msg: error?.message,
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteHomeSeo = async (id) => {
    axiosauth
      .post("/homePageSeo/delete-homepageseo", { id })
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setResponseData({
            ...responseData,
            success: true,
            msg: "Deleted Successfully!",
          });
        } else {
          console.log("res 22 >> ", res);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const closeModal = () => {
    setResponseData({
      ...responseData,
      success: false,
      msg: "",
    });

    getData();
  };

  const closeErrorModal = () => {
    setResponseData({
      ...responseData,
      error: false,
      msg: "",
    });
  };

  return (
    <Layout>
      {responseData?.success ? (
        <SuccessModal message={responseData?.msg} onClick={closeModal} />
      ) : null}
      {responseData?.error ? (
        <ErrorModal message={responseData?.msg} onClick={closeErrorModal} />
      ) : null}
      <div
        className="left_arrow2 back-arrow my-5 mx-3"
        style={{
          display: "flex",
          alignItems: "baseline",
          fontFamily: "inherit",
        }}
      >
        <h2 className="mx-3">Home Page SEO</h2>
      </div>

      <div className="button">
        <button
          className={Object.keys(list).length ? "text-secondary" : ""}
          disabled={Object.keys(list).length}
          onClick={() => history.push("/add-home-page-seo")}
        >
          Add
        </button>
      </div>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {Object.keys(list).length ? (
              <tr>
                <td>{1}</td>
                <td className="titleStyle">{list?.title}</td>
                <td className="titleStyle">{list?.description}</td>

                <td>
                  <Link to={`/edit-home-page-seo/${list?._id}`}>
                    <img
                      src={editIcon}
                      alt="edit-button"
                      className="edit-icon"
                    />
                  </Link>
                  <img
                    onClick={() => deleteHomeSeo(list._id)}
                    src={deleteIcon}
                    alt=""
                    className="delete-icon"
                  />
                </td>
              </tr>
            ) : (
              <NoData message="Page Not Found" />
            )}
          </tbody>
        </table>
      </div>
      {loader ? <LoaderModal /> : null}
    </Layout>
  );
};

export default HomePageSeo;
