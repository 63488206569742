import React, { useState } from 'react';
// import md5 from "md5";
import axios from '../../axiosconfig';
import { useDispatch } from 'react-redux';
import { loginSuccess, authenticated } from "../../redux/actions/login";
import { Link } from 'react-router-dom';
import CommonModal from '../halper/CommonModal';
import { useToasts } from 'react-toast-notifications';

const LoginForm = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [netError, setNetError] = useState("");
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [user, setUser] = useState({
    loginId: "",
    password: "",

  });
  const { loginId, password } = user
  const handleChange = (e) => {
    setUser({ ...user, error: false, [e.target.name]: e.target.value });
  };

  const togglePassword2 = () => {
    const typ2 = document.getElementById("password")
    let eye2 = document.getElementById("eye-pass")
    eye2.classList.toggle('visibility')
    if (typ2.type === 'password') {
      typ2.setAttribute('type', 'text')
    }
    else {
      typ2.setAttribute('type', 'password')
    }

  }

  const Login = (e) => {
    e.preventDefault();

    let obj = {
      "loginId": loginId,
      "password": password
      // "password": md5(password)
    }
    // console.log("obj", obj)
    axios.post('/login/login', obj).then(response => {
      let res = JSON.parse(response.data)

      if (res.success === true) {

        localStorage.setItem("accesstoken", res.sessionId)
        localStorage.setItem("userData", JSON.stringify(res.data.profile))
        if (res.data.business !== undefined) {
          localStorage.setItem("businessId", res.data.business.businessId)
        }
        dispatch(loginSuccess(res));
        dispatch(authenticated(true));
        addToast("Login Successful", { appearance: "success", autoDismiss: true });
        setErrorMsg("");
        setNetError("");
      }
      else { 
        setErrorMsg(res.message);
        setNetError("");
        setTimeout(()=>{
          setErrorMsg("")
        },5000)
      }

    }).catch((error) => {
      // console.log(error);
      setNetError("Network Error");
    }).then(() => {

      // console.log("-----always executes");
    })

  }

  const closeModal = () => {
    setShow(false);
    dispatch(authenticated(true));
  }

  return (
    <div>
      {show === true ? <CommonModal message="Login Successfully!" onClick={closeModal} /> : null}
      <form onSubmit={Login}>
        <div className="login-form">
          <h3>Login to get started</h3>

          <div>
            <p>Admin Email Id <b>*</b> </p>
            <input
              type="email"
              placeholder="Enter Email"
              name="loginId"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <section><p>Enter Password <b>*</b></p> <Link to="/forgot-password"><b>Forgot Password?</b></Link></section>
            <aside className="passlogin">
              <input
                id="password"
                type="password"
                placeholder="Enter Password"
                name="password"
                onChange={handleChange}
                required
              />
              <aside id="eye-pass" className="eye-view-loginpage" onClick={togglePassword2}>
              </aside>
            </aside>
          </div>
          <div className="network-error">
            {netError}
          </div>
          <i className="error-msg">
            {errorMsg}
          </i>
          <button>LOGIN</button>
        </div>
      </form>
    </div>
  )
}

export default LoginForm