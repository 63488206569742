import React, {useState, useEffect,useRef} from 'react';
import Layout from "../../layout/layout"
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DatePickerInput from '../../components/halper/DatePickerInput';
import axiosauth from '../../axios-config-auth';
import { AsyncPaginate } from 'react-select-async-paginate';
import SuccessModal from '../../components/halper/SuccessModal';
import LoaderModal from '../../components/halper/LoaderModal';
import { Redirect } from 'react-router-dom';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import { confirmAlert } from 'react-confirm-alert';
import country from '../../country.json'

const AddProductVariant = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const animatedComponents = makeAnimated()
  const[gst,setgst]=useState(0)
  const [countryList,setCountryList]=useState([])
  const [showLoader, setShowLoader] = useState(false);
  const [networkError, setNetworkError] = useState(false);

const myRefname = useRef(null);
const [value, setValue] = useState(null);
const [value19, setValue19] = useState(null);



const[total2,settotal2]=useState(0)



  /**
* TODO: function use for handle navigation
*@version 1.0.0
* @author [ Unknown]
*/
  // eslint-disable-next-line
const handleClick = () => {
// myRefname.current.focus();
myRefname.current.dispatchEvent(
  new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
    buttons: 1,
  }),
);
}
const handleSelectGst = (selectedOption,index) => {
  handleChange2(selectedOption.label, "type", index)

  handleChange2(selectedOption.value, "age", index)
  // console.log("select gst option", selectedOption)
  // setGst(selectedOption.value);
  // setGstError("");
  // setCommonError("")
}

const gstList = [
  { value: "0-1", label: "Upto 1 Years" },
  { value: "0-2", label: "Upto 2 Years" },
  { value: "3-4", label: "3-4 Years" },
  { value: "5-7", label: "5-7 Years" },
  { value: "8-11", label: "8-11 Years" },

  { value: "12-100", label: "12 years above" },

]
const[catalogueerror,setcatalogueerror]=useState("")



  const[error,seterror]=useState("")


// option's api call ::
const [specs, setspecs] = useState([{
  "units": "",
  "value": "",
  "sku":"",
  "mrp":"",
  "price":"",
  "length":"",
  "breadth":"",
  "height":"",
  "modelNumber":"",
  "hsnCode":"",
  "country":"China",
  "age":"0-1",
  "mobile":"",
  "address":"",
  "email":"",
  "isReturnable":false,
  "returnDays":"",
  "type":"Upto 1 Years",
  "date":new Date(),
  "dateToPass":new Date().getFullYear()+"-"  + ((new Date().getMonth() > 8) ? (new Date().getMonth() + 1) : ('0' + (new Date().getMonth() + 1)))+"-"+ ((new Date().getDate() > 9) ? (new Date().getDate()) : ('0' + (new Date().getDate())))
}])
    /**
* TODO: function use for removing specification row
*@version 1.0.0
* @author [ Unknown]
*/
const removespecs = (index) => {
  const temp = [...specs];

  const filter = temp.filter((item, i) => i !== index)

  setspecs(filter)

}
  /**
* TODO: function use for adding specification row
*@version 1.0.0
* @author [ Unknown]
*/
const addspecs = () => {

  setspecs([...specs, {   "units": "",
  "value": "",
  "sku":"",
  "mrp":"",
  "price":"",
  "modelNumber":"",
  "hsnCode":"",
  "length":"",
  "breadth":"",
  "height":"",
  "country":"China",
  "age":"0-1",
  "mobile":"",
  "address":"",
  "email":"",
  "isReturnable":false,
  "returnDays":"",
  "type":"Upto 1 Years",
  "date":new Date(),
  "dateToPass":new Date().getFullYear()+"-"  + ((new Date().getMonth() > 8) ? (new Date().getMonth() + 1) : ('0' + (new Date().getMonth() + 1)))+"-"+ ((new Date().getDate() > 9) ? (new Date().getDate()) : ('0' + (new Date().getDate())))}]);


}
const handleSelectCountry = (selectedOption,index) => {
  // handleChange2(selectedOption.label, "type", index)

  handleChange2(selectedOption.value, "country", index)
  // console.log("select gst option", selectedOption)
  // setGst(selectedOption.value);
  // setGstError("");
  // setCommonError("")
}
/**
* TODO: function use for creating new product 
*@version 1.0.0
* @author [ Unknown]
*/
const submit=()=>{
seterror(false)
// console.log("Submitted")
if(value===null||value19===null)
{
  // console.log("here",specs)

  seterror(true)
  return 0
}
for(var i=0;i<specs.length;i++)
{
    if(specs[i].units===""||specs[i].value===""||specs[i].address===""||specs[i].hsnCode===""||specs[i].country===""||specs[i].sku===""||specs[i].modelNumber===""||specs[i].mrp===""||specs[i].price===""||specs[i].dateToPass===""||specs[i].age===""||specs[i].type===""
    ||specs[i].length===""||specs[i].breadth===""||specs[i].height==="")
    {
        seterror(true);
        return 0
    }
}

for(let i=0;i<specs.length;i++)
{
    if((parseInt(specs[i].price)+parseInt(specs[i].price*gst)/100)>parseInt(specs[i].mrp))
    {
        seterror(true)
        alert("Price must be less than or equal to mrp in all variants")
        return 0
    }
}
for(let i=0;i<specs.length;i++)
{
  if((/\S+@\S+\.\S+/).test(specs[i].email)===false)    
    {
        seterror(true)
      
        return 0
    }
}
for(let i=0;i<specs.length;i++)
{
  if(/^\+?\d{10,15}$/.test(specs[i].mobile)===false)    
    {
        seterror(true)
      
        return 0
    }
}

for(let i=0;i<specs.length;i++)
{
  if(specs[i].isReturnable===true&&specs[i].returnDays==="")
  {
    seterror(true)
    return 0
  }
}
var product=[]
// console.log("rohm",specs)
for(var x=0;x<specs.length;x++)
{
  product.push({
    // "businessId": localStorage.getItem("rohanbusiness"),

    "productCatalogue":value19.value,
    "brand":value.value,
    "selectedVariant":specs[x].value.value,

    "sku": specs[x].sku,
    "expiryDate":specs[x].dateToPass,
    "modelNo": specs[x].modelNumber,
    "ageGroup":{
      "minAge":parseInt(specs[x].age.split("-")[0]),
      "maxAge":parseInt(specs[x].age.split("-")[1])
  },
  "dimensions":{
    length:specs[x].length,
    breadth:specs[x].breadth,
    height:specs[x].height
  },
  "price": parseInt(parseInt(specs[x].price)+parseInt(specs[x].price*gst)/100),
  "priceWithoutTax":parseInt(specs[x].price),
    "offer":parseInt(parseInt(specs[x].price)+parseInt(specs[x].price*gst)/100),
    "mrp": parseInt(specs[x].mrp),
    "stock": parseInt(specs[x].units),
    "status": "new like",
    "manufacturerAddress":specs[x].address,

    "minOrder": 2,
    "hsnCode":specs[x].hsnCode,
    "manufacturerEmail":specs[x].email,
    "manufacturerPhone":specs[x].mobile,
    "returnDays":specs[x].returnDays,
    "isReturnable":specs[x].isReturnable,
    "countryOfOrigin":specs[x].country,
    "available": "yes available",
    "gst": "gst",
    "geoLocation" : {
      "coordinates" : [
        76.321312,
        34.3211
      ],
      "type" : "Point"
    },
})
}

setShowLoader(true);

  // console.log("objtopass------------>",product)
  axiosauth.post("/products/post-product", product).then(response => {

  let res = JSON.parse(response.data)

      if (res.message === "Product posted successfully") {
        setOpenSuccess(true);
        setShowLoader(false);
      }
      else {

          setcatalogueerror(res.message)
          setShowLoader(false)
      }

    }).catch((error) => {
    console.log(error);
    setNetworkError(true)
    }).then(() => {
    console.log("-----always executes");
    })
  }
useEffect(() => {
  var cnty=[]
  for(var i=0;i<country.length;i++)
  {
     cnty.push({
       value:country[i].name,
       label:country[i].name
     })
  }
  setCountryList(cnty)
  setgst(localStorage.getItem("gst"))
  // console.log("pname---->",localStorage.getItem("pname"))
  // console.log("pbrand---->",localStorage.getItem("pbrand"))
  // console.log("pobj---->",localStorage.getItem("pobj"))
  // console.log("bname---->",localStorage.getItem("bname"))
 
  setValue({label:localStorage.getItem("bname"),
  value:localStorage.getItem("brandId")})
  setValue19({label:localStorage.getItem("pname"),
  value:localStorage.getItem("pobj")})
  var slug;
  const FetchUserDataInit = () => {
    axiosauth
      .get('/profile/load-profile')
      .then((Response) => {
        let res = JSON.parse(Response.data);
        // console.log("rohanjha", res);
        if (res.success === true) {
          if( res.data.business[0]!==undefined)
          slug = res.data.business[0].businessId;
          else
          slug=""
          // loadProducts(slug);
          localStorage.setItem("rohanbusiness", slug);
        } else {
          console.log("No data");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  FetchUserDataInit();
 
}, []);
// const[value,setValue]=useState(null)
      /**
* TODO: function use for handling name value change of specification
*@version 1.0.0
* @author [ Unknown]
*/
// eslint-disable-next-line
const handleChange2 = (e, name, index) => {
  seterror(false)
  // console.log("here",name)
    if(name==="isReturnable")
    {
      // console.log("here",name)

      const value=!e
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0
    
    }
  if(name==="value")
  {
    const  value  = e;
    const list = [...specs];
    list[index][name] = value;
    setspecs(list);
  }
  else if(name==="age")
  {
      const  value  = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
   
  }
  else if(name==="country")
  {
      const  value  = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
   
  }
else  if(name==="type")
  {
      const  value  = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
   
  }
  else    if(name==="date")
  {
    let datetoPass = e.getFullYear()+"-"  + ((e.getMonth() > 8) ? (e.getMonth() + 1) : ('0' + (e.getMonth() + 1)))+"-"+ ((e.getDate() > 9) ? (e.getDate()) : ('0' + (e.getDate()))) 
   
    const  value  = e;
    const list = [...specs];
    list[index][name] = value;
    list[index]["dateToPass"]=datetoPass
    setspecs(list);
  }
  else
  {
    const { value } = e.target;
    const list = [...specs];
    list[index][name] = value;
    setspecs(list);
  }


};
// const modalClose = () => {
 
//   handleClick()
// }

// console.log("bbbbbb------------>",value19)
async function loadOptions2(search, loadedOptions, { page }) {
  // console.log("sfjjjjjjjj------------>",value19)
  let newarray=[]
  let filtered
  let has_more=true
  let obj = {
    "fields": "max",
    "limit": 10,
    "count": (page - 1) * 10,
    "sortBy":{
      "type":"createdOn",
      "order": -1
  },
  "businessId": JSON.parse(localStorage.getItem("userData")).userId,

    "filters": {
      "name": search,
      "baseProduct":value19.value,
      "unique":true

    }
  }
  
  if(loadedOptions.length===0||loadedOptions.length<total2)
  {
    await axiosauth.post('/product-variant/load', obj)
    .then(async (response) => {
      let res = JSON.parse(response.data)
      if (res.success === true) {
        if(res.total>0)
        settotal2(res.total)
        // var filter= product.imageUrls.filter((item) => item !== null);
        
     for(var i=0;i<res.data.length;i++)
     {
       let lbl=""
       if(res.data[i].variant[0])
       {
         lbl+=res.data[i].variant[0].parentVariant.variantName+" "+res.data[i].variant[0].value+" "
       }
       if(res.data[i].variant[1])
       {
         lbl+=res.data[i].variant[1].parentVariant.variantName+" "+res.data[i].variant[1].value+" "
       }
       if(res.data[i].variant[2])
       {
         lbl+=res.data[i].variant[2].parentVariant.variantName+" "+res.data[i].variant[2].value+" "
       }
       newarray.push({
         label:lbl,
         value:res.data[i]._id
       })
     }
     var sarray=[]
     for(var j=0;j<specs.length;j++)
     {
       sarray.push(specs[j].value.value)
     }
    filtered = newarray.filter(function(item) {
      return sarray.indexOf(item.value) === -1 
});
    //  const r = newarray.filter((elem) => !specs.find(({ value }) => elem.value!== value.value) );

    //  setoptionarray(newarray)
     
      } else {
      has_more=false
      }
    })
    .catch((error) => {
      console.log(error);
    })
 
  }

  const responseJSON = {
    results:filtered,
   has_more: filtered.length>0?has_more:false,
  };

  return {
    options: responseJSON.results,
    hasMore: responseJSON.has_more,
    additional: {
      page: page + 1,
    },
  };
}

const shouldLoadMore = () => {
  // const bottomBorder = (scrollHeight - clientHeight) / 2;

  return true
};


const [redirect, setRedirect] = useState(false);
    const closeModal = () => {
        setRedirect(true);
    }

    const backButton = ()=>{

      if(specs[0].units!==""||specs[0].value!==""||specs[0].sku!==""){
          
          confirmAlert({
                  // title: 'Change Status',
                  message: 'Are you sure to do this. You will loss your data.',
                  buttons: [
                      {
                          label: 'Yes',
                          onClick: () => 
                          setRedirect(true)
                      },
                      {
                          label: 'No',
                          onClick: () => console.log("No")
                      }
                  ]
              });
      }else{
        setRedirect(true)
      }
  }


  const connectionError=()=>{
    setNetworkError(false)
  }



    return (
        <Layout>

{showLoader === true ? <LoaderModal /> : null}
           {redirect ? <Redirect to="/products-variant?page=1" /> : null}
            {openSuccess ? <SuccessModal message="New Variant Added!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}



          {/* <div className="form-header">
            <h4>Add Product Variant</h4>
          </div> */}
<br/><br/>

          <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                   <img src={left_arrow2}  onClick={backButton} alt="left-arrow" /> &nbsp;
                      <h2>Add Product Variant</h2>
                </div>


                <div id="select-tag">
                <p>Select Brand <b>*</b></p>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={{label:localStorage.getItem("bname"), value:localStorage.getItem("bname")}}
                        isDisabled

                    />
                </div>


                <div id="select-tag">
                <p>Select Catalogue <b>*</b></p>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        // options={colourOptions}
                        isDisabled
                        value={{label:localStorage.getItem("pname"), value:localStorage.getItem("pname")}}
                   
                    />
                </div>            

                 <div>

                  { specs && specs.length > 0
                      ? specs.map((value, index) => {

                          return (

                    <div className="form-wrap">



                        <div className="form-header2" >
                              <h4>Select Variant Product-{index+1} {index!==0?( <span style={{color:'red',float:'right', cursor:'pointer'}} onClick={()=>removespecs(index)}>X</span>):null}</h4>
                              
                              
                            </div>

                            <div className="form-body">


                            <div id="select-tag">
                              <p>Variant <b>*</b></p>
                              <AsyncPaginate
          
                              cacheUniqs={[value,value19,specs]}
                              value={specs[index].value}
                              placeholder={"Select/Search Variant"}
                              loadOptions={loadOptions2}
                              onChange={(e)=>{handleChange2(e,"value",index)
                            }}
                              shouldLoadMore={shouldLoadMore}
                              additional={{
                                page: 1,
                              }}
                            />
                             {error===true&&specs[index].value===""?<i className="error">Please Select Variant</i>:null}
                              </div> 
                          
                        <section>
                     
                      


                     
                        <div className="two-field">
                                <p>Units<b>*</b></p>
                                <input type="number"
                                  id="units"
                                  placeholder="Enter Units"
                                  name="units"
                                  value={specs[index].units}
                                  onChange={(e) => handleChange2(e, "units", index)}
                                />
                                 
                                 {error===true&&specs[index].units==="" && <i className="error">Please Enter Units</i>}
                              </div>

                              <div className="two-field">
                          <p>MODAL NUMBER<b>*</b></p>
                             <input type="text"
                               id="sku"
                               name="sku"
                               value={specs[index].modelNumber}
                               onChange={(e) => handleChange2(e, "modelNumber", index)}
                               placeholder="Enter Model Number"
                               />

                          {error===true&&specs[index].modelNumber==="" && <i className="error">Please Enter Model Number</i>}
                          </div> 
                        

                        </section>


                      <section>
                      <div className="two-field">
                     <p>HSN Code<b>*</b></p>
                        <input type="text"
                          id="mrp"
                          name="mrp"
                          value={specs[index].hsnCode}
                          onChange={(e) => handleChange2(e, "hsnCode", index)}
                         placeholder="Enter HSN Code"
                        />

                    {error===true&&specs[index].hsnCode==="" && <i className="error">Please Enter HSN Code</i> }

                     </div> 
                          <div className="two-field">
                          <p>MRP<b>*</b></p>
                             <input type="number"
                               id="mrp"
                               name="mrp"
                               value={specs[index].mrp}
                               onChange={(e) => handleChange2(e, "mrp", index)}
                              placeholder="Enter MRP"
                             />

                         {error===true&&specs[index].mrp==="" && <i className="error">Please Enter Mrp</i> }

                          </div> 
                        
                          {/* <div className="two-field">
                          <p>Price<b>*</b></p>
                             <input type="number"
                               id="price"
                               name="price"
                               value={specs[index].price}
                               onChange={(e) => handleChange2(e, "price", index)}
                               placeholder="Enter Price"
                             />
                             {error===true&&specs[index].price==="" &&  <i className="error">Please Enter Price</i> }


                           </div> */}
                        </section>
                        <section>
                        <div className="two-field">
                         {/* <p>MRP<b>*</b></p>
                             <input
                                type="number"
                                value={specs[index].mrp}
                                onChange={(e) => handleChange2(e, "mrp", index)}
                                placeholder="Enter MRP"
                                required
                            /> */}
                              <p>OFFER PRICE<b>*</b> 
                                    
                                 {gst!==0?( <>(GST: {gst}%)</>):null}  
                                    
                                    </p>
                                    <input
                                       type="number"
                                       value={specs[index].price}
                                       onChange={(e) => handleChange2(e, "price", index)}
                                       placeholder="Enter Price"
                                           required
                                   />
                        </div>
                        <div className="two-field">
                        <p>TOTAL PRICE<b>*</b> 
                                    
                             
                                    </p>
                                    <input
                                       type="number"
                                       value={parseInt(specs[index].price)+parseInt((specs[index].price*gst)/100)}
                                       onChange={(e) => handleChange2(e, "price", index)}
                                       placeholder="Enter Price"
                                           required
                                   />
                        </div>
                    </section>
                     <section>
                     <div className="two-field">
                          <p>SKU<b>*</b></p>
                             <input type="text"
                               id="sku"
                               name="sku"
                               value={specs[index].sku}
                               onChange={(e) => handleChange2(e, "sku", index)}
                               placeholder="Enter SKU"
                             />
                             {error===true&&specs[index].sku==="" &&  <i className="error">Please Enter Sku</i> }


                           </div>
                     <div className="two-field">
                         <p>Age Group<b>*</b></p>
                         <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={gstList}
                            value={  { value: specs[index].age, label: specs[index].type }}
                            name="gst"
                            // required
                            onChange={(e)=>handleSelectGst(e,index)}
                        />
                        </div>
                     </section>
                     <section>
                     
                   
                  
                   </section>
                   <section>
                    <div className="two-field">

                    <div className="datePic5">
                            <p className="label-form mt-2">MANUFACTURE DATE<b>*</b></p>
                            <DatePicker
                            selected={specs[index].date}
                                 onChange={(dat) =>{
                                    var date = new Date(dat);
                                
                                    handleChange2(date, "date", index)}}
                                    
                                customInput={<DatePickerInput value={specs[index].date} />}
                                maxDate={new Date()}

                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"

                            />


                        </div>
                        </div>

                      <div className="two-field">
                            <p>Country Of Origin<b>*</b></p>
                            <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={countryList}
                            value={  { value: specs[index].country, label: specs[index].country }}
                            name="gst"
                            // required
                            onChange={(e)=>handleSelectCountry(e,index)}
                        />
                    </div>

                    </section>
                    <div className="form-body">
                      <div className="single-field">
                      <p>Manufacturer/Importer Address<b>*</b></p>
                                     <textarea
                                        type="text"
                                        value={specs[index].address}
                                        onChange={(e) => handleChange2(e, "address", index)}
                                        placeholder="Enter Address"
                                        required
                                    /> 
                {specs[index].address==="" &&error===true&& <p  style={{ color: "red",
    "fontSize": "small",
    "marginTop": "10px",
    "fontWeight": "200 !important" }}>Please Enter Address</p>} 
                      </div>
                    </div>
                    <section>
                      <div className="two-field">
                      <p>Manufacturer Email<b>*</b></p>

                      <input
                                        type="text"
                                        value={specs[index].email}
                                        onChange={(e) => handleChange2(e, "email", index)}
                                        placeholder="Enter Manufacturer Email"
                                        required
                                    /> 
                                    {specs[index].email==="" &&error===true&& <p  style={{ color: "red",
    "fontSize": "small",
    "marginTop": "10px",
    "fontWeight": "200 !important" }}>Please Enter Email</p>} 
            {specs[index].email!=="" &&error===true&&(/\S+@\S+\.\S+/).test(specs[index].email)===false&& <p  style={{ color: "red",
    "fontSize": "small",
    "marginTop": "10px",
    "fontWeight": "200 !important" }}>Please Enter Valid Email</p>} 
                      </div>
                      <div className="two-field">
                      <p>Manufacturer Contact Number<b>*</b></p>

                      <input
                                        type="text"
                                        value={specs[index].mobile}
                                        onChange={(e) => handleChange2(e, "mobile", index)}
                                        placeholder="Enter Manufacturer Mobile Number"
                                        required
                                    /> 
                                 {specs[index].mobile==="" &&error===true&& <p  style={{ color: "red",
    "fontSize": "small",
    "marginTop": "10px",
    "fontWeight": "200 !important" }}>Please Enter Mobile</p>} 
               {error===true&&specs[index].mobile!==""&&/^\+?\d{10,15}$/.test(specs[index].mobile)===false && <p  style={{ color: "red",
    "fontSize": "small",
    "marginTop": "10px",
    "fontWeight": "200 !important" }}>Please Enter Valid Mobile</p>}
                      </div>
                    </section>
                    <p className="mt-5 font-weight-bold">Product Dimensions</p>
                    <section>
                    
                      <div className="two-field">
                      <p>Length<b>*</b></p>

                      <input
                                        type="text"
                                        value={specs[index].length}
                                        onChange={(e) => handleChange2(e, "length", index)}
                                        placeholder="Enter Length"
                                        required
                                    /> 
                              
                      </div>
                      <div className="two-field">
                      <p>Breadth<b>*</b></p>

                      <input
                                        type="text"
                                        value={specs[index].breadth}
                                        onChange={(e) => handleChange2(e, "breadth", index)}
                                        placeholder="Enter Breadth"
                                        required
                                    /> 
                             
           
                      </div>
                    </section>
                    <section>
                      <div className="two-field">
                      <p>Height<b>*</b></p>

                      <input
                                        type="text"
                                        value={specs[index].height}
                                        onChange={(e) => handleChange2(e, "height", index)}
                                        placeholder="Enter Height"
                                        required
                                    /> 
                              
                      </div>
                    
                    </section>
                    <section>
                    <div style={{marginTop:'12px'}}>
                        <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        checked={specs[index].isReturnable}
                        onChange={(e) => handleChange2(specs[index].isReturnable, "isReturnable", index)}
                        />
                            <label className="ml-1 label-form" for="vehicle1">Is Returnable</label><br/>
                            </div>
                    </section>
                   
                 {specs[index].isReturnable?( <section>
                    <div className="two-field">
                    <p>Return Days<b>*</b></p>

                    <input
                                        type="number"
                                        value={specs[index].returnDays}
                                        onChange={(e) => handleChange2(e, "returnDays", index)}
                                        placeholder="Enter Return Days"
                                        required
                                    /> 
                                         {specs[index].returnDays==="" &&error===true&&specs[index].isReturnable&& <p  style={{ color: "red",
    "fontSize": "small",
    "marginTop": "10px",
    "fontWeight": "200 !important" }}>Please Enter Return Days</p>} 
                            </div>
                    </section>):""}         

                          </div>

                       
                  
                  </div>



                          )
                      })
                      :null}

<i className="error">{catalogueerror}</i>


                            <div className="button" style={{marginBottom:10, marginTop:10}}>
            

                              {specs.length<total2?( <button onClick={addspecs} >Add more product</button>):( <button onClick={()=>alert("You have added all available variants")}>Add more product</button>)}   

                              &nbsp;&nbsp;
                              <button onClick={()=>submit()}>Save</button>
                            </div>
                          </div>
                      
    
        </Layout>
    )
}

export default AddProductVariant
