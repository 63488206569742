import React from 'react';
import Layout from "../../layout/layout"
import CollectionList from '../../wrapper/collection/CollectionList'

const Collection = () => {
    return (
        <Layout>
            <CollectionList />
        </Layout>

    )
}

export default Collection
