import React, { useState, useEffect } from 'react';
import Layout from "../../layout/layout";
// import CustomCropper from '../../components/halper/CustomCropper';
// import editImageIcon from '../../assets/icons/edit.png';
// import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanners } from '../../redux/actions/bannerAction';
import axiosauth from '../../axios-config-auth';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { Redirect, useHistory, useParams } from 'react-router-dom';
// import editIcon from '../../assets/icons/edit.png';

import SuccessModal from '../../components/halper/SuccessModal';
import LoaderModal from '../../components/halper/LoaderModal';
// import deleteIcon from '../../assets/icons/delete.png';
// import noImage from '../../assets/images/no-img.png';
const EditLoginBanner = () => {
    // const [result, setResult]=useState(null);
    // const [imageObj, setImageObj]=useState(null);

    const { bannerId } = useParams();

    const [show, setShow] = useState(false);

    const history = useHistory();
    const [file, setfile] = useState(null);
    const [urlFile, setUrlFile] = useState(null)
    // eslint-disable-next-line
    const [titleError, setTitleError] = useState(null)
    const [urlError, setUrlError] = useState(null)

    const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [redirect, setRedirect] = useState(false);


    const banners = useSelector(state => state.bannerdata)
    console.log("banner data->", banners, "type=>", typeof banners)

    console.log("banner-->", urlFile);
    // banner_list=[]
    // for(i=0;i<banners.length;i++)
    const dispatch = useDispatch()

    const [slideBanner, setSlideBanner] = useState({
        title: "",
        newtab: false,
        link: "http://toyvala-main-website.s3-website.ap-south-1.amazonaws.com/",
        alt: "",
        available: true,
        typeId: "O4FFLiVb"
    });
    // eslint-disable-next-line
    const handleChange = (e) => {
        setSlideBanner({ ...slideBanner, error: false, [e.target.name]: e.target.value });
        setTitleError("")
    }



    const bannerUpload = () => {
        // let obj = {
        //     "title": slideBanner.title,
        //     "link": slideBanner.link,
        //     "newtab": false,
        //     "alt": "banner-image",
        //     "startDate": "",
        //     "expirationDate": "",
        //     "available": true,
        //     "typeId": "banner-top",
        //     "image": imageObj
        // }

        // console.log("obj-->", obj)

        if (urlFile === null) {
            setUrlError("Banner required");
            return 0;
        }


        var formData = new FormData();
        // formData.append("bannerId", localStorage.getItem("bannerId"));
        formData.append("bannerId", bannerId);


        formData.append("image", urlFile);

        setShowLoader(true)

        axiosauth.post('/banner/edit-banner-image', formData)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setShowLoader(false)
                    setShow(true)
                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            })


        // dispatch(uploadBanners(formData))
    }

    useEffect(() => {

        let obj = {
            bannerId: localStorage.getItem("bannerId")
        }
        axiosauth.post('/banner/get-banner-by-id', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("jdkd", res)
                if (res.success === true) {
                    console.log("banner true-->", res.banner);
                    setSlideBanner({
                        title: res.banner.title,
                        newtab: false,
                        link: res.banner.link,
                        alt: "wide-image",
                        available: true,
                        typeId: "O4FFLiVb"
                    })
                    setfile(res.banner.image)


                } else {
                    console.log("banner fasle-->", res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })


        dispatch(fetchBanners())
    }, [dispatch])



    const closeModal = () => {
        setRedirect(true);
    }

    const connectionError = () => {
        setNetworkError(false)
    }


    return (
        <Layout>

            {redirect ?
                  <Redirect to="/login-banner" />
                // (history.goBack())
                : null}
            {show === true ? <SuccessModal message=" Banner Image Updated!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null}



            <div className="form-wrap">

                <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                    <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" /> &nbsp;
                    <h2>{`Image Dimension Must be (600 X 240) , image (jpg, png , WebP) and image size must be less than < 200KB` }</h2>
                </div>
                {/* </div> */}
                <div className="form-body">



                    <div className="banner-wrap">
                        <>

                            {file ?
                                <img src={file} alt="" />
                                : null}


                            {/* <p className="label-form my-5">{`Image Dimension Must be (1440 X 400) , image (jpg, png , WebP) and image size must be less than < 200KB`}<b>*</b></p> */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>


                                <div style={{ display: 'flex' }}>

                                    <div class="upload-btn-wrapper1">
                                        <button className="uplod-btn1" style={{ background: "#209F84", color: "#FFFFFF" }}>
                                            change image
                                        </button>
                                        <input
                                            type="file"
                                            name="myfile"
                                            onChange={(e) => {
                                                // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                                                setUrlError(null)
                                                setUrlFile(e.currentTarget.files[0]);

                                                let img = new Image()
                                                img.src = window.URL.createObjectURL(e.target.files[0]);

                                                img.onload = () => {
                                                    if (img.width >= 600 && img.height >= 240) {
                                                        setfile(img.src);

                                                    } else {
                                                        alert('Incorrect image dimension. Please upload an image of 600 X 240 pixels.')

                                                    }
                                                }


                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </>
                    </div>


                    <i className="error">{urlError}</i>



                </div>
                <div className="button">
                    <button onClick={bannerUpload}>Update Banner</button>
                </div>
            </div>


        </Layout>
    )
}

export default EditLoginBanner
