// import {
//     FATCH__USER,
//     USER_LOADING,
//     USER_FAIL,
//     SEARCHING,
//     FATCH__USER_BY_ID
// } from '../constants/types';
// import axiosauth from '../../axios-config-auth';

// export function loadingUser() {
//     return {
//         type: USER_LOADING,
//     };
// }


// export function fetchUser(user) {
//     return {
//         type: FATCH__USER,
//         payload: user
//     };
// }

// export function fetchUserById(user) {
//     return {
//         type: FATCH__USER_BY_ID,
//         payload: user
//     };
// }

// export function fetchUserFail(user) {
//     return {
//         type: USER_FAIL,
//         payload: user
//     };
// }

// export function searchUser(user) {
//     return {
//         type: SEARCHING,
//         payload: user
//     };
// }



// export const loadUser = (pageCount,excelDateStart,dateStart) => {
//     return async dispatch => {
//         // if(excelDateStart!==null&&dateStart!==null&&dateStart>excelDateStart)
//         // {
//         //     function formatDate(date) {
//         //         var d = new Date(date),
//         //             month = '' + (d.getMonth() + 1),
//         //             day = '' + d.getDate(),
//         //             year = d.getFullYear();
            
//         //         if (month.length < 2) 
//         //             month = '0' + month;
//         //         if (day.length < 2) 
//         //             day = '0' + day;
                  
//         //         console.log("Download--->",[year, month, day].join('-'))
            
//         //         return [year, month, day].join('-');
//         //     }
//         //     dispatch(loadingUser());
//         //     var myDate = new Date(dateStart);
//         //     myDate.setDate(dateStart.getDate() + 1);
//         //     let obj = {
//         //         "count": (pageCount - 1) * 10,
//         //         "sortBy": {
//         //             "type": "registrationDate",
//         //             "order": -1
//         //         },
              
//         //         "filters": {
//         //             "role": "customer",
//         //             "registrationDate":{
//         //                 "startDate":formatDate(excelDateStart),
//         //                 "endDate":formatDate(myDate)
//         //             },
//         //         }
//         //     }
//         //     axiosauth.post('/admin/load-users', obj)
//         //         .then(async (response) => {
//         //             let res = JSON.parse(response.data)
//         //             if (res.success === true) {
//         //                 dispatch(fetchUser(res.data));
//         //             } else {
//         //                 dispatch(fetchUserFail(res.message));
//         //             }
//         //         })
//         //         .catch((error) => {
//         //             console.log(error);
//         //             dispatch(fetchUserFail("Network Error"));
//         //         })
    
//         // }
//         // else{
//         //     dispatch(loadingUser());
//         //     let obj = {
//         //         "count": (pageCount - 1) * 10,
//         //         "sortBy": {
//         //             "type": "registrationDate",
//         //             "order": -1
//         //         },
//         //         "filters": {
//         //             "role": "customer"
//         //         }
//         //     }
//             axiosauth.post('/admin/load-users')
//                 .then(async (response) => {
//                     let res = JSON.parse(response.data)
//                     console.log("uuuuu",res)
//                     if (res.success === true) {
//                         dispatch(fetchUser(res.data));
//                     } else {
//                         dispatch(fetchUserFail(res.message));
//                     }
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                     dispatch(fetchUserFail("Network Error"));
//                 })
    
//         }
       
//     // };
// };

// export const loadUserByPage = (pageNumber) => {
//     return async dispatch => {
//         dispatch(loadingUser());
//         let obj = {
//             "count": (pageNumber - 1) * 10,
//             "sortBy": {
//                 "type": "registrationDate",
//                 "order": -1
//             },
//             "filters": {
//                 "role": "customer",
//                 // "registrationDate":{
//                 //     "startDate":"2021-02-12",
//                 //     "endDate":"2021-03-11"
//                 // }
//             }
//         }
//         axiosauth.post('/admin/load-users', obj)
//             .then(async (response) => {
//                 let res = JSON.parse(response.data)
//                 if (res.success === true) {
//                     dispatch(fetchUser(res.data));
//                 } else {
//                     dispatch(fetchUserFail(res.message));
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 dispatch(fetchUserFail("Network Error"));
//             })

//     };
// };




// export const loadDateFilter = (obj) => {
//     return async dispatch => {
//         dispatch(loadingUser());

//         console.log("action obj--->", obj)
    
//         axiosauth.post('/admin/load-users', obj)
//             .then(async (response) => {
//                 let res = JSON.parse(response.data)
//                 if (res.success === true) {
                    
//                     dispatch(fetchUser(res.data));
//                 } else {
//                     dispatch(fetchUserFail(res.message));
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 dispatch(fetchUserFail("Network Error"));
//             })

//     };
// };



// export const loadUserById = (obj) => {
//     return async dispatch => {
//         dispatch(loadingUser());
//         axiosauth.post('/admin/load-user-by-id', obj)
//             .then(async (response) => {
//                 let res = JSON.parse(response.data)
//                 if (res.success === true) {
//                     if(res.data.user.addresses1[0] !== undefined){
//                         localStorage.setItem('getUserName', res.data.user.addresses1[0].firstName+" "+res.data.user.addresses1[0].lastName);
//                     }
                   
//                     dispatch(fetchUser(res.data.user));
//                 } else {
//                     dispatch(fetchUserFail(res.message));
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 dispatch(fetchUserFail("Network Error"));
//             })

//     };
// };

// export const deleteUserById = (obj,pageCount,excelDateStart,startDate) => {
//     return async dispatch => {
//         dispatch(loadingUser());
//         axiosauth.post('/admin/delete-user', obj)
//             .then(async (response) => {
//                 let res = JSON.parse(response.data)
//                 if (res.success === true) {
//                     dispatch(loadUser(pageCount,excelDateStart,startDate));
//                 } else {
//                     dispatch(fetchUserFail(res.message));
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 dispatch(fetchUserFail("Network Error"));
//             })

//     };
// };

// export const searchingUser = (search,count,startDate,endDate) => {
//     return async dispatch => {
//         dispatch(loadingUser());
//         // let count1
//         // if (count === 1) {
//         //   count1 = 0;
//         // } else {
//         //   count1 = (count - 1) * 16;
//         // }
//         // let currentUrlParams = new URLSearchParams(window.location.search);
//         // currentUrlParams.set('page', 1);
//         // history.push(window.location.pathname + "?" + currentUrlParams.toString());
//         console.log("here",search , count,search,search.length)
//         if(search.toString().length===2)
//         {
//             dispatch(loadUserByPage(count));
//            return 0
//         }
//         let filter={}
//         if(startDate!==null&&endDate!==null&&endDate>startDate)
//         {
//             function formatDate(date) {
//                 var d = new Date(date),
//                     month = '' + (d.getMonth() + 1),
//                     day = '' + d.getDate(),
//                     year = d.getFullYear();
            
//                 if (month.length < 2) 
//                     month = '0' + month;
//                 if (day.length < 2) 
//                     day = '0' + day;
                  
//                 console.log("Download--->",[year, month, day].join('-'))
            
//                 return [year, month, day].join('-');
//             }
          
//             var myDate = new Date(endDate);
//           myDate.setDate(endDate.getDate() + 1);
//             filter={
//                 "role": "customer"
// ,
//                 "registrationDate":{
//                     "startDate":formatDate(startDate),
//                     "endDate":formatDate(myDate)
//                 }
//             }
//         }
//         else{
//             filter={
//                 "role": "customer"

//             }
//         }
//         let obj = {
//             "count":0,
//             "sortBy": {
//                 "type": "registrationDate",
//                 "order": -1
//             },
//             "filters": filter,
//             "search": search
//         }
//         console.log("obj ",obj)
//         axiosauth.post('/admin/load-users', obj)
//             .then(async (response) => {
//                 let res = JSON.parse(response.data)
//                 if (res.success === true) {
//                     dispatch(fetchUser(res.data));
//                 } else {
//                     dispatch(fetchUserFail(res.message));
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 dispatch(fetchUserFail("Network Error"));
//             })

//     };
// };


// export const userStatus = (obj,pageCount,excelDateStart,startDate) => {
//     return async dispatch => {
//         dispatch(loadingUser());
//         console.log("inside action obj:", obj)
//         axiosauth.post('/admin/update-userinfo', obj)
//             .then(async (response) => {
//                 let res = JSON.parse(response.data)
//                 if (res.success === true) {
//                     dispatch(loadUser(pageCount,excelDateStart,startDate));
//                 } else {
//                     dispatch(fetchUserFail(res.message));
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 dispatch(fetchUserFail("Network Error"));
//             })

//     };
// };
import {
    FATCH__USER,
    USER_LOADING,
    USER_FAIL,
    SEARCHING,
    FATCH__USER_BY_ID
} from '../constants/types';
import axiosauth from '../../axios-config-auth';

export function loadingUser() {
    return {
        type: USER_LOADING,
    };
}


export function fetchUser(user) {
    return {
        type: FATCH__USER,
        payload: user
    };
}

export function fetchUserById(user) {
    return {
        type: FATCH__USER_BY_ID,
        payload: user
    };
}

export function fetchUserFail(user) {
    return {
        type: USER_FAIL,
        payload: user
    };
}

export function searchUser(user) {
    return {
        type: SEARCHING,
        payload: user
    };
}



export const loadUser = (pageCount,excelDateStart,dateStart ,limitData) => {
    return async dispatch => {
        if(excelDateStart!==null&&dateStart!==null&&dateStart>excelDateStart)
        {
            function formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
            
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
                  
                // console.log("Download--->",[year, month, day].join('-'))
            
                return [year, month, day].join('-');
            }
            dispatch(loadingUser());
            var myDate = new Date(dateStart);
            myDate.setDate(dateStart.getDate() + 1);
            let obj = {
                "count": (pageCount - 1) * limitData,
                active:true,
                limit:limitData,
         
                "sortBy": {
                    "type": "registrationDate",
                    "order": -1
                },
              
                "filters": {
                    "role": "customer",
                    "registrationDate":{
                        "startDate":formatDate(excelDateStart),
                        "endDate":formatDate(myDate)
                    },
                }
            }
            axiosauth.post('/admin/load-users', obj)
                .then(async (response) => {
                    
                    let res = JSON.parse(response.data)
                    if (res.success === true) {
                        dispatch(fetchUser(res.data));
                    } else {
                        dispatch(fetchUserFail(res.message));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(fetchUserFail("Network Error"));
                })
    
        }
        else{
            dispatch(loadingUser());
            let obj = {
                "count": (pageCount - 1) * limitData,
                active:true,
                limit:limitData,
                // active:true,
                "sortBy": {
                    "type": "registrationDate",
                    "order": -1
                },
                "filters": {
                    "role": "customer"
                }
            }
            axiosauth.post('/admin/load-users', obj)
                .then(async (response) => {
                    let res = JSON.parse(response.data)
                    if (res.success === true) {
                        dispatch(fetchUser(res.data));
                    } else {
                        dispatch(fetchUserFail(res.message));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(fetchUserFail("Network Error"));
                })
    
        }
       
    };
};

export const loadUserByPage = (pageNumber ,limitData) => {
    return async dispatch => {
        dispatch(loadingUser());
        let obj = {
            "count": (pageNumber - 1) * limitData,
            active:true,
            limit:limitData,
        
            "sortBy": {
                "type": "registrationDate",
                "order": -1
            },
            "filters": {
                "role": "customer",
                // "registrationDate":{
                //     "startDate":"2021-02-12",
                //     "endDate":"2021-03-11"
                // }
            }
        }
        axiosauth.post('/admin/load-users', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(fetchUser(res.data));
                } else {
                    dispatch(fetchUserFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(fetchUserFail("Network Error"));
            })

    };
};




export const loadDateFilter = (obj) => {
    return async dispatch => {
        dispatch(loadingUser());

        console.log("action obj--->", obj)
    
        axiosauth.post('/admin/load-users', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    
                    dispatch(fetchUser(res.data));
                } else {
                    dispatch(fetchUserFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(fetchUserFail("Network Error"));
            })

    };
};



export const loadUserById = (obj) => {
    return async dispatch => {
        dispatch(loadingUser());
        axiosauth.post('/admin/load-user-by-id', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    if(res.data.user.addresses1[0] !== undefined){
                        localStorage.setItem('getUserName', res.data.user.addresses1[0].firstName+" "+res.data.user.addresses1[0].lastName);
                    }
                   
                    dispatch(fetchUser(res.data.user));
                } else {
                    dispatch(fetchUserFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(fetchUserFail("Network Error"));
            })

    };
};

export const deleteUserById = (obj,pageCount,excelDateStart,startDate) => {
    return async dispatch => {
        dispatch(loadingUser());
        axiosauth.post('/admin/delete-user', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(loadUser(pageCount,excelDateStart,startDate));
                } else {
                    dispatch(fetchUserFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(fetchUserFail("Network Error"));
            })

    };
};

export const searchingUser = (search,count,startDate,endDate  ,limitData) => {
    return async dispatch => {
        dispatch(loadingUser());
        // let count1
        // if (count === 1) {
        //   count1 = 0;
        // } else {
        //   count1 = (count - 1) * 16;
        // }
        // let currentUrlParams = new URLSearchParams(window.location.search);
        // currentUrlParams.set('page', 1);
        // history.push(window.location.pathname + "?" + currentUrlParams.toString());
        console.log("here",count,search,search.length)
        if(search.toString().length===2)
        {
            dispatch(loadUserByPage(count));
           return 0
        }
        let filter={}
        if(startDate!==null&&endDate!==null&&endDate>startDate)
        {
            function formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
            
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
                  
                console.log("Download--->",[year, month, day].join('-'))
            
                return [year, month, day].join('-');
            }
          
            var myDate = new Date(endDate);
          myDate.setDate(endDate.getDate() + 1);
            filter={
                "role": "customer"
,
                "registrationDate":{
                    "startDate":formatDate(startDate),
                    "endDate":formatDate(myDate)
                }
            }
        }
        else{
            filter={
                "role": "customer"

            }
        }
        let obj = {
            "count":0,
            // "count":1,
            active:true,
            limit:limitData,
            "sortBy": {
                "type": "registrationDate",
                "order": -1
            },
            "filters": filter,
            "search": search
        }
        axiosauth.post('/admin/load-users', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(fetchUser(res.data));
                } else {
                    dispatch(fetchUserFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(fetchUserFail("Network Error"));
            })

    };
};


export const userStatus = (obj,pageCount,excelDateStart,startDate) => {
    return async dispatch => {
        dispatch(loadingUser());
        console.log("inside action obj:", obj)
        axiosauth.post('/admin/update-userinfo', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(loadUser(pageCount,excelDateStart,startDate));
                } else {
                    dispatch(fetchUserFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(fetchUserFail("Network Error"));
            })

    };
};