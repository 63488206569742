 
import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useHistory } from "react-router-dom";  
import SuccessModal from '../../../components/halper/SuccessModal'; 
import left_arrow2 from '../../../assets/icons/down_arrow2.png'
import Layout from '../../../layout/layout'
import axiosInstance from '../../../axios-config-auth';
import Loader from 'react-loader-spinner';
import LoaderModal from '../../../components/halper/LoaderModal';


const UpdateStyle = () => { 
  const [redirect, setRedirect] = useState(false);  
  const [style , setStyle] = useState('')
  const [showLoader, setShowLoader] = useState(false);
//   const [errorMsg, setErrorMsg] = useState('');
  const [networkError, setNetworkError] = useState(false);
  const [dataLoader, setDataLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [styleObj ,setStyleObj] = useState([])
  const history = useHistory();
  const { styleId } = useParams(); 

  useEffect(()=>{
    axiosInstance.post('/style/list_style_byId/',{id :styleId})
    .then(async (response) => {
        let res = JSON.parse(response.data) 
        if (res.success === true) {
            setStyleObj(res.data)
            setStyle(res.data.name) 
            setDataLoader(false)
        }  
        else{
            
            setDataLoader(false)
        }
    })
    .catch((error) => {
        setDataLoader(false)
        console.log(error);
    })
  } ,[]) 

  const updateVariant = (e) => {
    e.preventDefault()
    let obj = {
      "id": styleId,
      "name": style
    }
    setShowLoader(false) 

    // console.log("objobj", obj)
    axiosInstance.post('/style/update-style/',obj)
    .then(async (response) => {
        let res = JSON.parse(response.data) 
        if (res.success === true) { 
          
            setShow(true)
        } else { 
            // setShowLoader(false);
        }
    })
    .catch((error) => {
        setNetworkError(true);
        console.log(error);
    }) 
  }

//   useEffect(() => {
//     dispatch(fetchVariantValueById({ variantId: variantId }));
//   }, [dispatch, variantId]);

const connectionError=()=>{
    setNetworkError(false)
}

  const closeModal = () => {
    setRedirect(true);
  }

  return ( 

<Layout>

{!dataLoader ?
       <>

   <form onSubmit={updateVariant} className="form-wrap"> 
        <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
               <img src={left_arrow2}  onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                   <h2>Update Style</h2>
           </div> 
       <div className="form-body">

           <div className="single-field">
               <p>Style Name <b>*</b></p>
               <input type="text"
                   id="name"
                   placeholder="Style Name"
                   name="name"
                   value={style}
                   onChange={(e)=>setStyle(e.target.value)}
                   required
               />
           </div>
 

       </div> 
       <div className="button">
           <button>Update</button>
       </div> 
   </form>

   {redirect ? <Redirect to="/style-manage" /> : null}
   {show === true ? <SuccessModal message="Style updated!" onClick={closeModal} /> : null}
   {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
   {showLoader === true ? <LoaderModal /> : null}

      </>

:
 <div className="loader-style">
     <Loader
         type="ThreeDots"
         color="rgba(32, 159, 132, 1)"
         height={100}
         width={100}
         timeout={30000} //3 secs
     />
 </div>
}
   
</Layout>
  )
}

export default UpdateStyle