import React, { useEffect, useState } from "react";
import deleteIcon from "../../assets/icons/delete.png";
import editIcon from "../../assets/icons/edit.png";
// import SearchBox from "../../components/halper/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import {
  // fetchVariantSearch,
  fetchVariantPage,
  deleteVariantNameById,
} from "../../redux/actions/variantAction";
import { confirmAlert } from "react-confirm-alert";
// import Pagination from "react-js-pagination";
import { Link, useHistory } from "react-router-dom";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useParams } from "react-router";

const ManageVariantName = () => {
  // const [activePage, setActivePage] = useState(1);
  const { variandId } = useParams();
  // console.log("params===>>>", variandId)
  // For page count
  const history = useHistory(); 
  const pageCount = 1;

  const dispatch = useDispatch();
  const varData = useSelector((state) => state.variantData.data);
  

  useEffect(() => {
     

    if (pageCount !== null) {
      dispatch(fetchVariantPage(pageCount));
    } else {
      dispatch(fetchVariantPage(1));
    }
  }, [dispatch, history, pageCount]);

  /**
   * TODO: Pagination
   *@version 1.0.0
   * @author [Unknown]
   */

  // Handle Pagination

  // const [locationKeys, setLocationKeys] = useState([]);
 
   
  return (
    <>
      <div className="d-flex justify-content-between">
        <div
          className="left_arrow2 back-arrow align-self-center mb-0"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.push("/variant-manage")}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Manage Variant Name</h2>
        </div>
        <div>
          <div className="d-flex ">
            <div className="button ml-0">
              <button onClick={() => history.push("/variant-manage")}>
                Back
              </button>
            </div>
            <div className="button ml-2">
              <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
          </div>
        </div>
      </div>
      {/* <SearchBox
        placeholder="Search Variant .."
        onChange={(e) => dispatch(fetchVariantSearch(e.target.value))}
      /> */}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Variant Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {varData.data && varData.data.length > 0 ? (
              <>
                {varData.data.map((variant, index, id) => {
                  const deleteVariant = () => {
                    let obj = {
                      id: variant.parent._id,
                    };
                    confirmAlert({
                      // title: 'Change Status',
                      message: "Are you sure want to delete this Variant Name?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            dispatch(deleteVariantNameById(obj));
                          },
                        },
                        {
                          label: "No",
                          onClick: () => {
                            console.log("not detele");
                          },
                        },
                      ],
                    });
                  };

                  return (
                    <>
                      {variandId === variant.parent.variantId &&
                        <tr key={index}>
                          <td>
                            {1}
                          </td>
                          <td className="titleStyle">
                            {variant.parent.variantName}
                          </td>
                          <td>
                            <Link
                              to={`/edit-variant-name/${variant.parent.variantId}`}
                            > 
                              <img
                                src={editIcon}
                                alt=""
                                className="edit-icon"
                              />{" "}
                            </Link>
                            <img
                              onClick={deleteVariant}
                              src={deleteIcon}
                              alt=""
                              className="delete-icon"
                            />
                          </td>
                        </tr>
                      }
                    </>
                  );
                })}
              </>
            ) : null}
          </tbody>
        </table>

        {/* <div className="pagination-custom">
          <Pagination
            hideDisabled
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={varData.total}
            pageRangeDisplayed={5}
            hideNavigation
            onChange={handlePageChange5}
          />
        </div> */}
      </div>
    </>
  );
};

export default ManageVariantName;
