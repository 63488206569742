import React, { useState, useEffect } from 'react';
import Layout from "../../layout/layout";
import uploadIcon from '../../assets/icons/upload.png';
import axiosauth from '../../axios-config-auth';
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import SuccessModal from '../../components/halper/SuccessModal';
import editImageIcon from '../../assets/icons/edit.png';
import pdfIcon from '../../assets/images/pdfdefault.png';

const AddTrademark = () => {
    const [openSuccess, setOpenSuccess] = useState(false);
    // const [file, setfile] = useState("");
    // const [urlFile, setUrlFile] = useState("");
    // const [isFile, setIsFile] = useState(false);
    // const [collectionMsg, setCollectionMasg] = useState('');
    // const [errorMsg, setErrorMsg] = useState('');
    // const [redirect, setRedirect] = useState(false);

    const [error, seterror] = useState("")
    const [specs, setspecs] = useState([{
        "name": "",
        "value": ""
    }])

    /**
* TODO: function use for removing specification row
*@version 1.0.0
* @author [ Unknown]
*/
    const removespecs = (index) => {
        const temp = [...specs];

        const filter = temp.filter((item, i) => i !== index)

        setspecs(filter)
    }
    /**
  * TODO: function use for adding specification row
  *@version 1.0.0
  * @author [ Unknown]
  */
    const addspecs = () => {
        setspecs([...specs, { name: "", value: "" }]);
    }

    /**
  * TODO: function use for adding trademark in brand
  *@version 1.0.0
  * @author [ Unknown]
  */
    const onSubmit = () => {
        console.log("rohan")
        var formData = new FormData();
        var brandId = localStorage.getItem("brandId")
        formData.append("brandId", brandId)

        for (var i = 0; i < specs.length; i++) {
            if (specs[i].name === "" || specs[i].value === "") {
                seterror(true)
                return 0
            }
        }
        console.log("rohan")
        for (var j = 0; j < specs.length; j++) {
            formData.append(specs[j].name, specs[j].value)
        }
        console.log("rohan")
        axiosauth.post('/brand/upload-brand-trademark', formData).then(response => {

            let res = JSON.parse(response.data)
            console.log("rohan", res)
            if (res.success === true) {
                setOpenSuccess(true);
            }
            else {
            }

        }).catch((error) => {
            console.log(error);
        }).then(() => {
            console.log("-----always executes");
        })
    }


    /**
* TODO: function use for handling name value change of specification
*@version 1.0.0
* @author [ Unknown]
*/
    const handleChange2 = (e, name, index) => {
        seterror(false)
        if (name === "value") {
            const value = e
            const list = [...specs];
            list[index][name] = value;
            setspecs(list);
        }
        else {
            const { value } = e.target;
            const list = [...specs];
            list[index][name] = value;
            setspecs(list);

        }

    };


    useEffect(() => {

    }, []);

    const [redirect, setRedirect] = useState(false);
    const closeModal = () => {
        setRedirect(true);
    }

    return (

        <Layout>
            {redirect ? <Redirect to="/brand" /> : null}
            {openSuccess ? <SuccessModal message="Trademark Added!" onClick={closeModal} /> : null}

            <form className="form-wrap">
                <div className="form-header">
                    <h4>Add Brand Trademark</h4>
                </div>
                <div className="form-body">

                    {console.log("rj", specs)}
                    {specs && specs.length > 0
                        ? specs.map((value, index) => {

                            return (

                                <div className="card">
                                    <div className="card-header">
                                        {index !== 0 ? (<div onClick={() => removespecs(index)} class="btn-style2">X</div>) : null}

                                        <div>
                                            <p>trademark <b>*</b></p>
                                            <input type="text"
                                                id="name"
                                                placeholder="trademark Name"
                                                name="name"
                                                value={value.name}
                                                onChange={(e) => handleChange2(e, "name", index)}
                                                required
                                            />
                                        </div>

                                    </div>

                                    {value.value === "" ? (
                                        <section className="image-uploaded">

                                            <div class="upload-btn-wrapper mt-2">
                                                <button className="uplod-btn">
                                                    <img src={uploadIcon} alt="" /><br />
                                                        UPLOAD IMAGE/PDF
                                                </button>
                                                <input
                                                    type="file"
                                                    name="myfile"
                                                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                                    onChange={(e) => {
                                                        if (e.currentTarget.files[0].size >= 1000000) {
                                                            alert("size must be less than 1mb")
                                                            return 0
                                                        }
                                                        handleChange2(e.currentTarget.files[0], "value", index)
                                                    }}
                                                // required
                                                />
                                            </div>
                                        </section>) :
                                        <section className="image-uploaded">

                                            {console.log("ss", value.value)}
                                            {value.value.name.includes(".pdf") ? 
                                            (<img src={pdfIcon} alt="" style={{ width: 120, height: 140, padding: '10px' }} />) 
                                            :
                                                                                        
                                            <aside class="image">
                                                    <div class="overlay">
                                                    <label for="file-upload">
                                                        <img for="upload-image" class="edit-img" src={editImageIcon} alt=""/>
                                                    </label>
                                                    </div>
                                                    <img class="screen" src={URL.createObjectURL(value.value)} alt=""/>
                                                    
                                                    <input
                                                        type="file"
                                                        id="file-upload"
                                                        style={{display:'none'}}
                                                        name="myfile"
                                                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                                    onChange={(e) => {
                                                        if (e.currentTarget.files[0].size >= 1000000) {
                                                            alert("size must be less than 1mb")
                                                            return 0
                                                        }
                                                        handleChange2(e.currentTarget.files[0], "value", index)
                                                    }}
                                // required
                                />
                            </aside>
                                            
                                            
                                            
                                            }

                                        </section>}


                                </div>
                            )
                        })
                        : null}


                    <div class="upload-btn-wrapper">
                        <button onClick={() => addspecs()} class={"newbotton"}>
                            <img src={uploadIcon} alt="" />
                            <br />
                            ADD MORE TRADEMARK
                </button>
                    </div>
                </div>

            </form>
            {error}
            <div className="button">
                <NavLink to="/brand">
                    <button>Skip</button></NavLink>&nbsp;&nbsp;
                    <button onClick={onSubmit}>Save</button>
            </div>


        </Layout>
    )
}

export default AddTrademark
