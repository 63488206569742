import React, { useState, useEffect } from 'react'
// import SearchBox from '../../components/halper/SearchBox'
import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
// import eyeIcon from '../../assets/icons/eye.png';
import axiosauth from '../../axios-config-auth';
import Loader from 'react-loader-spinner';
import { Link,useHistory } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert'; 
import NoData from '../../components/halper/NoData';
import left_arrow2 from '../../assets/icons/down_arrow2.png'

const VariantList = () => {

    const [collections, setCollections] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [total, settotal] = useState(0);
    // const [catelogueImg, setCatelogueImg]=useState("");
    // const [name1,setName1]=useState("");
    const history = useHistory();

    /**
     * TODO: function for pagination handling page number change
     *@version 1.0.0
     * @author [ Unknown]
     */
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": (pageNumber - 1) * 10,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            },
            "filters": {
                "baseProduct": localStorage.getItem("productobj"),
            }
        }
        axiosauth.post('/product-variant/load', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setCollections(res.data)
                    settotal(res.total);
                    setShowLoader(false);

                } else {
                    // console.log("else block")
                    setCollections([])
                    settotal(0)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    /**
* TODO: function for searching according to variant name
*@version 1.0.0
* @author [ Unknown]
*/
    // const searching = (search) => {
    //     // setsearch(search)
    //     let obj = {
    //         "fields": "max",
    //         "limit": 10,
    //         "count": 0,
    //         "sortBy": {
    //             "type": "createdOn",
    //             "order": -1
    //         },
    //         "filters": {
    //             "name": search,
    //             "baseProduct": localStorage.getItem("productobj"),
    //         }
    //     }
    //     axiosauth.post('/product-variant/load', obj)
    //         .then(async (response) => {
    //             let res = JSON.parse(response.data)
    //             if (res.success === true) {
    //                 console.log("search--->", res.data)
    //                 setCollections(res.data)
    //                 settotal(res.total);
    //                 setShowLoader(false);
    //             } else {
    //                 setCollections([])
    //                 settotal(0);
    //                 setShowLoader(false);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // }

 const loadvariant=()=>{
    let obj = {
        "fields": "max",
        "limit": 10,
        "count": 0,
        "filters": {
            "baseProduct": localStorage.getItem("productobj"),
        },

        "sortBy": {
            "type": "createdOn",
            "order": -1
        },
    }
    axiosauth.post('/product-variant/load', obj)
        .then(async (response) => {
            let res = JSON.parse(response.data)
            if (res.success === true) {
                // console.log("---->", res.data)
                setCollections(res.data)
                settotal(res.total);
                setShowLoader(false);
            } else {
                //   setErrorMsg("No Variants Found")
                setCollections([])
                settotal(0);
                setShowLoader(false);
            }
        })
        .catch((error) => {
            console.log(error);
        })

        // Get Catelogue bu id
        let obj1 = {
            "catalogueId":localStorage.getItem("image")
        }
// console.log("obj1", obj1)
        axiosauth.post('/catalogue/find-catalogue-by-id', obj1)
        .then(async (response) => {
            let res = JSON.parse(response.data)
            if (res.success === true) {
                // setCatelogueImg(res.result[0].imageUrls[0].url);
                // setName1(res.result[0].name)
                // console.log("catelogue",res.result)
            } else {
                console.log("esle catelogue",res)
            }
        })
        .catch((error) => {
            console.log(error);
        })

 }

    useEffect(() => {
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": 0,
            "filters": {
                "baseProduct": localStorage.getItem("productobj"),
            },

            "sortBy": {
                "type": "createdOn",
                "order": -1
            },
        }
        axiosauth.post('/product-variant/load', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    // console.log("---->", res.data)
                    setCollections(res.data)
                    settotal(res.total);
                    setShowLoader(false);
                } else {
                    //   setErrorMsg("No Variants Found")
                    setCollections([])
                    settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })

            // Get Catelogue bu id
            let obj1 = {
                "catalogueId":localStorage.getItem("image")
            }
// console.log("obj1", obj1)
            axiosauth.post('/catalogue/find-catalogue-by-id', obj1)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    // setCatelogueImg(res.result[0].imageUrls[0].url);
                    // setName1(res.result[0].name)
                    // console.log("catelogue",res.result)
                } else {
                    console.log("esle catelogue",res)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }, []);


    return (
        <div>
            {/* <SearchBox
                placeholder="Search.."
                onChange={(e) => searching(e.target.value)}
            /> */}

            <div  className="left_arrow2 back-arrow mt-5" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img src={left_arrow2}  onClick={() => history.goBack()} alt="left-arrow" />&nbsp;
                <h2>Manage Variant</h2>
               
            </div>
            <div className="button">
                    <Link to="/add-brand-variant">
                        <button>Add New Variant</button>
                    </Link>

                </div>

            {!showLoader ?
                <div className="table-wrap">
                {collections && collections.length > 0 ?
                    <table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th style={{textAlign:"left"}}>Variant Name</th>
                                {/* <th>Variant Image</th> */}
                                <th>View Details</th>
                                {/* <th>Edit Image</th> */}
                                <th>Actions</th>

                            </tr>
                        </thead>
                        <tbody>

                  
                        {        collections.map((variant, index) => {

                                    const deleteVariant=()=>{
                                            let obj = {
                                                "productVariantId": variant.productVariantId,
                                            }
                                            confirmAlert({
                                                // title: 'Change Status',
                                                message: 'Are you sure you want to delete this variant ?',
                                                buttons: [
                                                    {
                                                        label: 'Yes',
                                                        onClick: () => {
                                                            axiosauth.post('/product-variant/remove-product-variant', obj)
                                                                .then(async (response) => {
                                        
                                                                    let res = JSON.parse(response.data)
                                                                    console.log("res",res)
                                                                    if (res.success === true) {
                                                                        loadvariant()
                                                                    } else {
                                                                        loadvariant()

                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                })
                                                        }
                                                    },
                                                    {
                                                        label: 'No',
                                                        onClick: () => console.log("Hello")
                                                    }
                                                ]
                                            });
                                        
                                        }
    


                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>

                                             <td className="titleStyle title-name" style={{textAlign:"left"}}>{variant?.variant[0] !== undefined ? variant?.variant[0].parentVariant?.variantName:null}-{variant?.variant[0] !== undefined ? variant.variant[0].value : null}
                                             {variant?.variant[2]!==undefined?",":null}
                                             {variant?.variant[1]!==undefined?",":null}
                                             {variant?.variant[1] !== undefined ? variant.variant[1].parentVariant?.variantName:null} {variant?.variant[1] !== undefined ? variant?.variant[1].value : null}
                                             {variant?.variant[2] !== undefined ? variant.variant[2].parentVariant?.variantName:null} {variant?.variant[2] !== undefined ? variant?.variant[2].value : null}

                                             </td>
                                            {/* <td className="titleStyle">{variant.name}</td> */}
                                            {/* <td className="collection-img">
                                                <img src={variant?.imageUrls[0] !== undefined ? variant?.imageUrls[0].url : catelogueImg} alt="" />
                                            </td> */}

                                            <td>
                                            <Link to={`/brand-variant-details/${variant.productVariantId}`} >
                                            <i onClick={()=>localStorage.setItem("variant",variant?.productVariantId)} className="fa fa-eye fa-eye-style"></i>
                                            {/* <img onClick={()=>localStorage.setItem("variant",variant.productVariantId)} src={eyeIcon} alt="view-button" className="eye-icon" /> */}
                                                </Link>
                                            </td>
                                            
                                            {/* <td>
                                            <Link to="/update-variant-image" >
                                            <img onClick={()=>{localStorage.setItem("variant",variant?.productVariantId)
                                        localStorage.setItem("baseProduct",variant.baseProduct._id)}} src={editIcon} alt="edit-button" className="edit-icon" />
                                            </Link>
                                            </td> */}
                                            
                                            <td>
                                                <Link to={`/update-brand-variant/${variant.productVariantId}`} >
                                                <img onClick={()=>localStorage.setItem("variant",variant?.productVariantId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                                </Link>
                                                <img  onClick={deleteVariant} src={deleteIcon} alt="delete" className="delete-icon" />
                                            </td>
                                        </tr>
                                    )
                                })

                        }

                        </tbody>
                    </table>

                    :
                                <>
                               {/* "No variant Available DAta Here" */}
                               <NoData message="variant not available" />
                               </>
                            }

                    <div className="pagination-custom">
                        <Pagination
                            hideDisabled
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            hideNavigation
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
                :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </div>
            }

        </div >
    )
}

export default VariantList
