import React from 'react'
import Layout from "../../layout/layout"
import AddCoupon from '../../wrapper/coupon/AddCoupon'
const CreateCoupon = () => {
    return (
        <Layout>
            <AddCoupon />
        </Layout>
    )
}

export default CreateCoupon
