import React, { useState } from 'react' 
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; 
import { useEffect } from 'react';
// import LoaderModal from '../../components/halper/LoaderModal';
import axiosauth from '../../axios-config-auth';
import Loader from 'react-loader-spinner';
import deleteIcon from '../../assets/icons/delete.png';
import NoData from '../../components/halper/NoData';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import editIcon from '../../assets/icons/edit.png';
import { useHistory } from 'react-router-dom';


const AboutUs = () => {
 
    const history = useHistory() 
    const [knowUs , setKnowus] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

  

    useEffect(()=>{

        const obj =  {
            
            "filters":{
                "headName": "GET TO KNOW US"
        }}
        setShowLoader(true);
        axiosauth.post('/footer/list_footer' ,obj)
  .then(async (response) => {
      let res = JSON.parse(response.data)
      if (res.success === true) { 
        setKnowus(res.data)
        setShowLoader(false)
      } else {
      }
  })
  .catch((error) => {
      console.log(error);
  })
    } ,[])


 

    return (
        <>  
        
        <div className="button">
            <button onClick={()=>history.push('/add-get-knowUs')}>Add Page</button>
          </div>
        {!showLoader ?
        <div className="table-wrap">
        <table>
        {knowUs && knowUs.length > 0 ?(<thead>
            <tr>
            <th>S.No</th>
              <th>Page Name</th>
              <th>Link</th> 
              <th>status</th>
              <th></th>
            </tr>
          </thead>):null}  
          <tbody>

                {
                  knowUs && knowUs.length > 0 ?
                    <>
                      {
                        knowUs.map((user, index) => {

                          const deleteUser = (id) => {
                            let obj = {
                              id:id
                            } 
                            confirmAlert({
                              // title: 'Change Status',
                              message: 'Are you sure you want to delete this page? ',
                              buttons: [
                                {
                                  label: 'Yes',
                                  onClick: () => {
                                    axiosauth.post('/footer/delete-footer' ,obj)
                                    .then(async (response) => {
                                        let res = JSON.parse(response.data)
                                        if (res.success === true) { 
                                          const obj1 =  {
            
                                            "filters":{
                                                "headName": "GET TO KNOW US"
                                        }}
                                        setShowLoader(true);
                                        axiosauth.post('/footer/list_footer' ,obj1)
                                  .then(async (response) => {
                                      let res = JSON.parse(response.data)
                                      if (res.success === true) {
                                        console.log("class" ,res)
                                        setKnowus(res.data)
                                        setShowLoader(false)
                                      } else {
                                      }
                                  })
                                  .catch((error) => {
                                      console.log(error);
                                  })
                                        } else {
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                                  }
                                },
                                {
                                  label: 'No',
                                  onClick: () => {
                                    console.log("not detele")
                                  }
                                }
                              ]
                            });
                          }

                          return (
                            <tr key={index + 1}>
                            <td>{ (index+1)}</td>
                              <td className="titleStyle">{user?.name}</td>
                              <td  >{(user?.url).length>0 ?user?.url : <p className="mt-3">link not Found</p>}</td>
                              <td >{user?.suspend === false ? <p className="mt-3">Active</p> :  <p className="mt-3">Inactive</p> }</td>
                       
                              <td>
                              <Link to={`/update-cms/${user._id}`} >
                                                <img   src={editIcon} alt="edit-button" className="edit-icon" />
                               </Link>
                                <img onClick={()=>deleteUser(user._id)} src={deleteIcon} alt="" className="delete-icon" />
                              </td>
                            </tr>
                          )
                        }
                        )}
                    </>
                    : (<NoData message="Page Not Found" />)
                }
              
          </tbody>
        </table> 
      </div>
      :
        <div className="loader-style">
     <Loader
       type="ThreeDots"
       color="rgba(32, 159, 132, 1)"
       height={100}
       width={100}
       timeout={500000}
     />
   </div>
    }

 
 
        </>
    )
}

export default AboutUs
