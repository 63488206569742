import React, { useState, useEffect } from "react";
import axiosauth from "../../axios-config-auth";
import Pagination from "react-js-pagination";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../layout/layout";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import close from "../../assets/icons/close.png";
import { confirmAlert } from "react-confirm-alert";
import deleteIcon from "../../assets/icons/delete.png";
import NoData from "../../components/halper/NoData";
import editIcon from "../../assets/icons/edit.png";
import SuccessModal from "../../components/halper/SuccessModal";

const ManageGajariCoins = () => { 
  const [success, setSuccess] = useState(false) 
  const [collections, setCollections] = useState([]); 
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get("page");
 

  const [total, settotal] = useState(0);
 
  useEffect(() => {
    let obj = {
      fields: "max",
      limit: 10,
      count: (pageCount - 1) * 10,

      sortBy: {
        type: "createdOn",
        order: -1,
      },
    };
    axiosauth
      .post("/coins-value/load-value", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          // console.log("coins====>>>", res.data.data);
          setCollections(res.data.data);
          settotal(res.total);
          // setShowLoader(false);
        } else {
          setCollections([]);
          settotal(0);
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pageCount]);

  const handlePageChange5 = (pageNumber) => {
    // handlePageChange(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  const showDate = (data) => {
    var d = new Date(data);
    var date = d.getDate();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
    var year = d.getFullYear();
    var dateStr = date + " " + months[month] + " " + year;
    // var res =
    return dateStr;
  };

  
  const closeModal =()=>{
    setSuccess(false)
  }

  return (
    <Layout>
       {success ? (
        <SuccessModal message="coine Value Added!" onClick={closeModal} />
      ) : null}
      <div className="container-fluid collection-2">
        <div
          className="left_arrow2 back-arrow my-3 mt-4"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Manage Gajari Coins</h2>
          
        </div>
        <div className="d-flex">
        <div className="button">
            <button onClick={() => history.push("/addgajaricoins")}>
              Add{" "}
            </button>
          </div> 
        </div>
 

        <div className="table-wrap overflow-auto table-responsive table border p-0 border-top-0 border-bottom-0">
          <table>
            {collections && collections.length > 0 ? (
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Date</th>
                  <th>Minimum Amount</th>
                  <th>Maximum Amount</th>
                  <th>Percentage</th> 
                  <th>Actions</th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {collections && collections.length > 0 ? (
                collections.map((brand, index) => {
                  const deleteCoins = (id) => {
                    let obj = {
                      gazaricoinvalueId: id,
                    };
                    confirmAlert({
                      // title: 'Change Status',
                      message: "Are you sure you want to delete this  Coins ?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            axiosauth
                              .post("/coins-value/remove-value", obj)
                              .then(async (response) => {
                                let res = JSON.parse(response.data);
                                if (res.success === true) {
                                  let obj = {
                                    fields: "max",
                                    limit: 10,
                                    count: (pageCount - 1) * 10,

                                    sortBy: {
                                      type: "createdOn",
                                      order: -1,
                                    },
                                  };
                                  axiosauth
                                    .post("/coins-value/load-value", obj)
                                    .then(async (response) => {
                                      let res = JSON.parse(response.data);
                                      if (res.success === true) {
                                        setCollections(res.data.data);
                                        settotal(res.total); 
                                      } else {
                                        setCollections([]);
                                        settotal(0); 
                                      }
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                } else {
                                  // getBrands()
                                  // setEditShow(false);
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          },
                        },
                        {
                          label: "No",
                          onClick: () => console.log("Hello"),
                        },
                      ],
                    });
                  };

                  return (
                    <tr key={index}>
                      <td>
                        {((pageCount ? pageCount : 1) - 1) * 10 + (index + 1)}
                      </td>
                      <td>{showDate(brand.createdOn)}</td>
                      <td className="titleStyle title-name">
                        {brand?.startAmount}
                      </td>
                      <td className="titleStyle title-name">
                        {brand?.endAmount}
                      </td>
                      <td className="titleStyle title-name">{brand?.value}</td>

                      <td>
                        <Link to={`/update-gajaricoins/${brand.gazaricoinvalueId}`}>
                          <img
                            // onClick={() =>
                            //   localStorage.setItem("brand", brand._id)
                            // }
                            src={editIcon}
                            alt="edit-button"
                            className="edit-icon"
                          />
                        </Link>

                        <img
                          onClick={() => deleteCoins(brand.gazaricoinvalueId)}
                          src={deleteIcon}
                          alt="delete"
                          className="delete-icon"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoData message="Coins Not Found" />
              )}
            </tbody>
          </table>
          
        </div>

        <div className="pagination-custom pro-pagination-style">
          <Pagination
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={1}
            disabledClass={true}
            hideDisabled={true}
            onChange={handlePageChange5}
            hideFirstLastPages={true}
            pageContainerClass="mb-0 mt-0"
            pagePrevText="«"
            pageNextText="»"
          />
        </div>
      </div>
    </Layout>
  );
};

export default ManageGajariCoins;

 