import React from 'react';
import EditCollectionForm from '../../wrapper/collection/EditCollectionForm';
import Layout from '../../layout/layout';

const EditCollection = () => {

  return (
    <Layout>
      <EditCollectionForm />
    </Layout>
  )
}

export default EditCollection