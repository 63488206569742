import React from 'react';
import BillingAddressView from './BillingAddressView';

const BillingAddress = ({address}) => {
    return (
        <div>
            <BillingAddressView address={address} />
        </div>
    )
}

export default BillingAddress;
