import React from 'react'
import Layout from "../../layout/layout"
import AddCategory from '../../wrapper/category/AddCategory'
const CreateCategory = () => {
    return (
        <Layout>
            <AddCategory />
        </Layout>
    )
}

export default CreateCategory
