import React, { useEffect, useState } from 'react';
// import SearchBox from '../../components/halper/SearchBox';
import { Link, useHistory } from 'react-router-dom';
import Pagination from "react-js-pagination";
import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
// import eyeIcon from '../../assets/icons/eye.png';
import axiosauth from '../../axios-config-auth';
import { confirmAlert } from 'react-confirm-alert';
import SuccessModal from '../../components/halper/SuccessModal';
import Loader from 'react-loader-spinner';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import ficon from '../../assets/icons/ficon.png';
import ficon2 from '../../assets/icons/ficon2.png'
import searcha from '../../assets/icons/search_icon.png'
import DatePicker from "react-datepicker";
import DatePickerInput from '../../components/halper/DatePickerInput';
import DownloadExcelModal from '../../components/halper/DownloadExcelModal';
import LoaderModal from '../../components/halper/LoaderModal';
import NoData from '../../components/halper/NoData';
import Layout from '../../layout/layout';
import noimgae from "../../assets/images/no-img.png"


const SuspendedProductVariantList = () => {
  const [varList, setVarList] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [total, settotal] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setsearch] = useState("")
  const [excelDateStart, setExcelDateStart] = useState(null);
  // eslint-disable-next-line
  const [disButton, setDisButton] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [excelLink, setExcelLink] = useState(null);
  const [showLoader1, setShowLoader1] = useState(false);
  const [excelDateStartError, setExcelDateStartError] = useState("")
  const [startDateError, setstartDateError] = useState("")
  const excelDateSearch = (date) => {
    if (excelDateStart === null) {
      setExcelDateStartError("Select Start Date")
      return 0
    }

    if (date === null) {
      setstartDateError("Select End Date")
      return 0
    }
    if (date < excelDateStart) {
      alert("End Date Must Be Greater Than Start Date")
      return 0
    }
    setStartDate(date)
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;


      return [year, month, day].join('-');
    }
    var myDate = new Date(date);
    myDate.setDate(date.getDate() + 1);

    let obj = {
      "count": (pageCount - 1) * 10,
      limit: 10,
      suspend:"true",
      field: "max",
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }, filters: {
        "productCatalogue": localStorage.getItem("pobj"),

        "datePosted": {
          "startDate": formatDate(excelDateStart),
          "endDate": formatDate(myDate)
        },



      },
    };


    axiosauth
      .post("/products/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setExcelDateStartError("")
          setstartDateError("")
          setVarList(res.data.products);
          settotal(res.data.total)
          setShowLoader(false)
          // console.log("load-catelogue--->", res)
          // setProductCatalogue(res.data.data)
          // setTotal(res.data.total);

          // setShowLoader(false);
          setDisButton(false);
        } else {
          // setProductCatalogue([])
          // setShowLoader(false);
          setExcelDateStartError("")
          setstartDateError("")
          setVarList([])
          settotal(0)
          setDisButton(false);

          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const excelDateSearch1 = (date) => {
    if (date === null) {
      setExcelDateStartError("Select Start Date")
      return 0
    }

    if (startDate === null) {
      setstartDateError("Select End Date")
      return 0
    }
    if (startDate < date) {
      alert("End Date Must Be Greater Than Start Date")
      return 0
    }
    setExcelDateStart(date)
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;


      return [year, month, day].join('-');
    }

    var myDate = new Date(startDate);
    myDate.setDate(startDate.getDate() + 1);
    let obj = {
      "count": (pageCount - 1) * 10,
      limit: 10,
      suspend:"true",
      field: "max",
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }, filters: {
        "productCatalogue": localStorage.getItem("pobj"),

        "datePosted": {
          "startDate": formatDate(date),
          "endDate": formatDate(myDate)
        },



      },
    };


    axiosauth
      .post("/products/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setExcelDateStartError("")
          setstartDateError("")
          setVarList(res.data.products);
          settotal(res.data.total)
          setShowLoader(false)
          // console.log("load-catelogue--->", res)
          // setProductCatalogue(res.data.data)
          // setTotal(res.data.total);

          // setShowLoader(false);
          setDisButton(false);
        } else {
          setExcelDateStartError("")
          setstartDateError("")
          // setProductCatalogue([])
          // setShowLoader(false);
          setVarList([])
          settotal(0)
          setDisButton(false);

          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const downloadExcel = () => {
    if (excelDateStart === null) {
      setExcelDateStartError("Select Start Date")
      return 0
    }

    if (startDate === null) {
      setstartDateError("Select End Date")
      return 0
    }
    if (startDate < excelDateStart) {
      alert("End Date Must Be Greater Than Start Date")
      return 0
    }
    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      // console.log("Download--->",[year, month, day].join('-'))

      return [year, month, day].join('-');
    }

    var myDate = new Date(startDate);
    myDate.setDate(startDate.getDate() + 1);
    let obj = {
      "count": 0,
      "limit": 10000,
      suspend:"true",
      filters: {
        "productCatalogue": localStorage.getItem("pobj"),

        "datePosted": {
          "startDate": formatDate(excelDateStart),
          "endDate": formatDate(myDate)
        },


      },
      // "filters":{
      //   "date":{
      //     "startDate":formatDate(excelDateStart),
      //     "endDate":formatDate(startDate)
      //   }},
    }
    setShowLoader1(true);
    axiosauth.post('/products/product-export', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setExcelDateStartError("")
          setstartDateError("")
          // console.log("====user Excel===>", res.data)
          setShowLoader1(false);
          setExcelLink(`https://backend.toyvala.com/${res.data}`)

        } else {

        }
      })
      .catch((error) => {
        console.log(error);

      })
  }




  // For page count
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get('page');

  const deleteBrand = (pid) => {
    let obj = {
      "productId": pid,
    }
    confirmAlert({
      // title: 'Change Status',
      message: 'Are you sure want to delete this product variant ?.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axiosauth.post('/products/delete-product-single', obj)
              .then(async (response) => {

                let res = JSON.parse(response.data)
                if (res.success === false) {

                } else {
                  setOpenSuccess(true);

                }
              })
              .catch((error) => {
                console.log(error);
              })
          }
        },
        {
          label: 'No',
          onClick: () => console.log("Hello")
        }
      ]
    });

  }

  const handleProductSuspend = (product) => {
    // console.log( "deleted product=====>>>",product)
    let obj = {
      "productId": product?.productId,
      "suspend": product.suspend === "true" ? "false" : "true",
    }
    confirmAlert({
      // title: 'Change Status',
      message: 'Are you sure you want to Activate this product and go live?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axiosauth.post('/products/update-product', obj)
              .then(async (response) => {

                let res = JSON.parse(response.data)
                if (res.success === false) {

                } else {
                  setOpenSuccess(true);

                }
              })
              .catch((error) => {
                console.log(error);
              })
          }
        },
        {
          label: 'No',
          onClick: () => console.log("Not Deleted")
        }
      ]
    });


  }

  const loadProduct = (type, order) => {
    let obj = {
      "fields": "max",
      "limit": 10,
      suspend:"true",
      "count": 0,
      "filters": {
        "productCatalogue": localStorage.getItem("pobj"),
      },

      "sortBy": {
        type: type, order: order === true ? 1 : -1
      },
    }

    // console.log("pobj",localStorage.getItem("pobj"))

    //  setShowLoader(true)

    axiosauth.post('/products/load', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setVarList(res.data.products)
          setShowLoader(false)
        } else {
          setVarList([])
          settotal(0)

          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }



  useEffect(() => {
    let obj = {
      "fields": "max",
      "limit": 10,
      suspend:"true",
      "count": 0,
      "filters": {
        "productCatalogue": localStorage.getItem("pobj"),
      },
      //  sortBy: { type: "mrp", order: 1 },
      "sortBy": {
        "type": "datePosted",
        "order": -1
      },
    }

    // console.log("pobj",localStorage.getItem("pobj"))

    axiosauth.post('/products/load', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setVarList(res.data.products);
          settotal(res.data.total)
          setShowLoader(false)
        } else {
          setVarList([])
          settotal(0)

          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, []);

  const closeModal = () => {
    loadProduct();
    setOpenSuccess(false);
  }


  /**
  * TODO: function for pagination handling page number change
  *@version 1.0.0
  * @author [Unknown]
  */
  const handlePageChange = (pageNumber) => {
    let filter = {}
    if (excelDateStart !== null && startDate !== null) {
      function formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        // console.log("Download--->",[year, month, day].join('-'))

        return [year, month, day].join('-');
      }

      var myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);
      filter = {
        "productCatalogue": localStorage.getItem("pobj"),

        "datePosted": {
          "startDate": formatDate(excelDateStart),
          "endDate": formatDate(myDate)
        },
        "sku": search



      }
    }
    else {
      filter = {
        "productCatalogue": localStorage.getItem("pobj"),


        "sku": search



      }
    }
    let obj = {
      "fields": "max",
      "limit": 10,
      suspend:"true",
      "count": (pageNumber - 1) * 10,
      "filters": filter,

      "sortBy": {
        "type": "datePosted",
        "order": -1
      },
    }


    axiosauth.post('/products/load', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setVarList(res.data.products)
          settotal(res.data.total)
          setShowLoader(false)
        } else {
          setVarList([])
          settotal(0)

          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
      })


  }


  /**
* TODO: function for searching according to product name
*@version 1.0.0
* @author [ Unknown]
*/
  const searching = (search) => {
    setsearch(search)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    let filter = {}
    if (excelDateStart !== null && startDate !== null) {
      function formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        // console.log("Download--->",[year, month, day].join('-'))

        return [year, month, day].join('-');
      }

      var myDate = new Date(startDate);
      myDate.setDate(startDate.getDate() + 1);
      filter = {
        "productCatalogue": localStorage.getItem("pobj"),

        "datePosted": {
          "startDate": formatDate(excelDateStart),
          "endDate": formatDate(myDate)
        },
        "sku": search



      }
    }
    else {
      filter = {
        "productCatalogue": localStorage.getItem("pobj"),


        "sku": search



      }
    }
    let obj = {
      "fields": "max",
      "limit": 10,
      suspend:"true",
      "count": (pageCount - 1) * 10,
      "filters": filter,

      "sortBy": {
        "type": "datePosted",
        "order": -1
      },
    }
    axiosauth.post('/products/load', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setVarList(res.data.products)
          settotal(res.data.total)
          setShowLoader(false)
        } else {
          setVarList([])
          settotal(0)

          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }



  /**
* TODO: Pagination
*@version 1.0.0
* @author [Unknown]
*/

  // Handle Pagination

  const [locationKeys, setLocationKeys] = useState([]);

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    // console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {

        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)
          handlePageChange1(getQueryVariable('page'))
          // Handle forward event

        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          handlePageChange1(getQueryVariable('page'))

        }
      }
    })
  }, [locationKeys, history])

  const handlePageChange1 = (pageNumber) => {

  };

  const handlePageChange5 = (pageNumber) => {
    handlePageChange(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };





  return (

    <Layout>
      {excelLink !== null ?
        <DownloadExcelModal>
          <span className="excel-title">Download Product List</span><br />


          <hr />


          <a href={excelLink} onClick={() => setExcelLink(null)} target="_blank" rel="noopener noreferrer" className="exportLink">Download</a>

        </DownloadExcelModal>
        : null}



      {openSuccess ? <SuccessModal message="Product Updated Successfully..!" onClick={closeModal} /> : null}
      {showLoader1 === true ? <LoaderModal /> : null}

      {/* <SearchBox /> */}
      <div className="search-box">
        <img src={searcha} alt="search-icon" />
        <input type="text"
          placeholder={
            "Search By Sku"
          }
          // onChange={onChange}
          onChange={(e) => searching(e.target.value)}

        />
      </div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-2">
          <div className="p-1 datePicorderExcel">
            <DatePicker
              selected={excelDateStart}
              onChange={date => {
                setExcelDateStart(date)
                excelDateSearch1(date)
                setExcelDateStartError('')
              }}
              maxDate={new Date()}

              customInput={<DatePickerInput place={"Select Start Date"} isRound={true} value={excelDateStart} />}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />

          </div>
          <p style={{ color: "red" }}>{excelDateStartError}</p>
        </div>

        <div className="col-md-2">
          <div className="p-1 datePicorderExcel">
            <DatePicker
              selected={startDate}
              onChange={date => excelDateSearch(date)}
              customInput={<DatePickerInput place={"Select End Date"} isRound={true} value={startDate} />}
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabled={!excelDateStart}
            />

          </div>
          <p style={{ color: "red" }}>{startDateError}</p>
        </div>

        <div className="col-md-2">
          <div className={"button2"}>
            <button onClick={downloadExcel}>Export Excel</button>
          </div>
        </div>

      </div>
      <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
        {/* <div> */}
        <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" />&nbsp;
        <h2>Manage Variants </h2> <span className="product-name"> &nbsp; &nbsp;( Product : {localStorage.getItem("pname")})</span>

        {/* <div className="button">
                    <Link to="/add-products-variant">
                        <button onClick={()=>localStorage.setItem("bname",varList[0].brand.name)}>Add Variant</button>
                    </Link>

                </div> */}

        {/* </div> */}

      </div>
      {!showLoader ?


        <div className="table-wrap overflow-auto table-responsive">
          <table className="">
            {varList && varList.length > 0 ? (<thead>
              <tr>
                <th>S.No</th>
                <th className="text-nowrap px-2">Variant Name</th>
                <th className="text-nowrap px-2">view</th>
                <th className="text-nowrap px-2">Sku</th>

                <th className="text-nowrap px-2">Image
                </th>
                <th className="text-nowrap px-2">
                  MRP
                  <span>
                    <img onClick={() => loadProduct("mrp", true)} className="f-icon2" src={ficon2} alt="filterIcon" />
                    <img onClick={() => loadProduct("mrp", false)} className="f-icon" src={ficon} alt="filterIcon" />
                  </span>
                </th>

                <th className="text-nowrap px-2">
                Available Stock
                  <span>
                    <img onClick={() => loadProduct("netQuantity", true)} className="f-icon2" src={ficon2} alt="filterIcon" />
                    <img onClick={() => loadProduct("netQuantity", false)} className="f-icon" src={ficon} alt="filterIcon" />
                  </span>
                </th>
                <th className="text-nowrap px-2">
                unit
                  <span>
                    <img onClick={() => loadProduct("stock", true)} className="f-icon2" src={ficon2} alt="filterIcon" />
                    <img onClick={() => loadProduct("stock", false)} className="f-icon" src={ficon} alt="filterIcon" />
                  </span>
                </th>
                <th className="text-nowrap px-2">Date</th>
                <th className="text-nowrap px-2">View Details</th>
                <th className="text-nowrap px-2">Reviews</th>
                {/* <th className="text-nowrap px-2">Edit Image</th> */}
                <th className="text-nowrap px-2">Suspend Product</th>
                <th className="text-nowrap px-2">Actions</th>

              </tr>
            </thead>) : null}

            <tbody>

              {varList && varList.length > 0 ?
                varList.map((v, index) => {
                  if (v.suspend === "true") {

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="titleStyle title-name">{v?.selectedVariant?.variant[0] !== undefined ? v?.selectedVariant?.variant[0]?.parentVariant?.variantName : null}-{v?.selectedVariant?.variant[0] !== undefined ? v.selectedVariant.variant[0].value : null}
                          {v?.selectedVariant?.variant[1] !== undefined ? "," : null}
                          {v?.selectedVariant?.variant[1] !== undefined ? v.selectedVariant?.variant[1]?.parentVariant?.variantName : null}-{v?.selectedVariant?.variant[1] !== undefined ? v.selectedVariant.variant[1].value : null}
                          {v?.selectedVariant?.variant[2] !== undefined ? "," : null}
                          {v?.selectedVariant?.variant[2] !== undefined ? v.selectedVariant?.variant[2]?.parentVariant?.variantName : null}-{v?.selectedVariant?.variant[2] !== undefined ? v.selectedVariant.variant[2].value : null}

                        </td>
                        <td>
                          {/* <a target="_blank" href={`34.131.106.77:8002/catalogue/${product.catalogueId}`}> view</a> */}
                          <a target="_blank" href={`http://15.206.82.93:1221/productId/${v.slug}`} rel='noreferrer'> view</a>

                        </td>
                        <td className="titleStyle">


                          {v.sku}

                        </td>
                        <td >
                          <div className="banner-img">
                            <img src={v?.productCatalogue?.imageUrls[0] !== undefined ? v?.productCatalogue?.imageUrls[0].url : v?.productCatalogue?.imageUrls[0] !== undefined ? v?.productCatalogue?.imageUrls[0].url : noimgae} alt="variant-img" />
                          </div>
                        </td>
                        <td>
                          ₹{v.mrp}
                        </td>
                        <td>{v.stock ?v.stock :0}</td>
                        <td>{v.stock ?v.netQuantity :0}</td>
                        <td>{v.datePosted.substring(0,10)}</td>

                        <td onClick={() => localStorage.setItem("provariant", v.productId)}>
                          <Link to={`/products-variant-details`} >
                            <i className="fa fa-eye fa-eye-style"></i>
                            {/* <img src={eyeIcon} alt="eye-button" className="eye-icon" />     */}
                          </Link></td>

                        <td>
                          <Link to="/review?page=1">
                            <span
                              className="manage-link"
                              onClick={() => {
                                localStorage.setItem("proslug", v.slug)
                              }}
                            >

                              Manage

                            </span>
                            {/* <span className='count-style' >{v.reviews.length}</span> */}

                          </Link>

                        </td>
                        {/* <td
                          onClick={() =>
                            localStorage.setItem(
                              "productVariantId",
                              v.productId
                            )
                          }
                        >
                          <Link to={`/update-variant-image/${v.productId}`}>
                            <img
                              src={editIcon}
                              alt="edit-button"
                              className="edit-icon"
                            />
                          </Link>
                        </td> */}
                        <td>
                          <div className="custom-control custom-switch mt-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleProductSuspend(v)}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              // value={user.active} 
                              checked={v.published}
                              name="customSwitchesChecked"
                            // onChange={()=>handleCategoryAlert(user)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customSwitchesChecked"
                            >
                            </label>
                          </div>
                        </td>
                        <td>

                          <Link to={`/edit-products-variant`} >
                            <img onClick={() => {
                              localStorage.setItem("pid", v.productId)
                              localStorage.setItem("bname", v.brand.name)
                              localStorage.setItem("bid", v.brand._id)
                            }}
                              src={editIcon} alt="edit-button" className="edit-icon" />
                          </Link>



                          <img onClick={() => deleteBrand(v.productId)} src={deleteIcon} alt="delete" className="delete-icon" />
                        </td>
                      </tr>
                    )
                  }
                })

                :
                (<NoData message="No Products Found" />
                )}

            </tbody>
          </table>

          <div className="pagination-custom">
            <Pagination
              hideDisabled
              activePage={parseInt(pageCount)}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              hideNavigation
              onChange={handlePageChange5}
            />
          </div>

        </div>
        :

        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={50000}
          />
        </div>

      }

    </Layout>
  )
}

export default SuspendedProductVariantList
