import React from 'react';
import { useEffect, useState } from 'react'
import SearchBox from '../../components/halper/SearchBox';
import { useHistory } from 'react-router-dom';
import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
import { useDispatch, useSelector } from 'react-redux';
import { loadSubSubCategory , loadSubSubCategoryByPage , deleteSubSubCategoryById } from '../../redux/actions/subSubCategoryAction';
// 
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Loader from 'react-loader-spinner';
import Pagination from "react-js-pagination";
import noImage from '../../assets/images/no-img.png';
import NoData from '../../components/halper/NoData';
import Layout from '../../layout/layout';
import left_arrow2 from '../../assets/icons/down_arrow2.png'


const SubSubCategoryList = () => {
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const subCategoryData = useSelector(state => state.subCategoryData);
  const subsubcategoryData  = useSelector(state => state.subSubCategoryData);
  // console.log("subsub category data" ,subsubcategoryData);
   // for pagination
   const history = useHistory();
   const pageCount = new URLSearchParams(window.location.search).get('page');

  //  console.log("subsub------------>>>", subsubcategoryData)

  useEffect(() => { 

  if(activePage===1)
  {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', (pageCount ? pageCount : 1));
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  }

  if(pageCount !== null){
    dispatch(loadSubSubCategoryByPage(pageCount));
  }else{
    dispatch(loadSubSubCategoryByPage(1))
  }

  }, [dispatch,history,pageCount,activePage]);



  /**
 * TODO: Pagination
 *@version 1.0.0
 * @author [ Unknown]
 */
 
  const [ locationKeys, setLocationKeys ] = useState([]);

  const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1); 
  var vars = query.split("&"); 
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}

  useEffect(() => {
  return history.listen(location => {
    if (history.action === 'PUSH') {
      setLocationKeys([ location.key ])
    }
    // console.log("history.action",history.action)

    if (history.action === 'POP') {
      if (locationKeys[1] === location.key) {
        setLocationKeys(([ _, ...keys ]) => keys) 
          // console.log("js",getQueryVariable('page'))
          handlePageChange1( getQueryVariable('page'))
        // Handle forward event

      } else {
        setLocationKeys((keys) => [ location.key, ...keys ]) 
        // console.log("js",getQueryVariable('page'))
        handlePageChange1( getQueryVariable('page'))

      }
    }
  })
}, [ locationKeys,history])

const handlePageChange1 = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handlePageChange = (pageNumber) => {
    // console.log("dd",pageNumber)
    dispatch(loadSubSubCategoryByPage(pageNumber))
    setActivePage(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };


  return (
    <Layout> 
     
        
       <div className="left_arrow2 back-arrow mt-3" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
         <div> 
            <img src={left_arrow2}  onClick={() => history.push('/category-manage?page=1')} alt="left-arrow" /> &nbsp;
               <h2 style={{marginTop:"-20px" , marginLeft:"15px"}}>Manage Sub-Sub-Category</h2> 
                </div>
                <div className="button">
                    <Link to="/create-sub-sub-category">
                        <button>Add Sub-Sub-Category</button>
                    </Link>
                </div>
            </div> 
            
            <SearchBox
        placeholder="Search Sub Sub Category Name.."
        onChange={(e) => dispatch(loadSubSubCategory(e.target.value))}
      />
            
         


{subsubcategoryData?.length ===0 ?
              <div className="loader-style">
                <Loader
                  type="ThreeDots"
                  color="rgba(32, 159, 132, 1)"
                  height={100}
                  width={100}
                  timeout={3000} //3 secs
                />
              </div> :
              <>
      <div className="table-wrap overflow-auto table-responsive card py-4" style={{height:"500px"  }}>
        <table>
        {subsubcategoryData?.data?.categories && subsubcategoryData?.data?.categories.length > 0 ?(<thead>
            <tr>
              <th className="text-nowrap px-3">S No</th>
              {/* <th>Category Name</th> */}
              <th className="text-nowrap px-3">Sub-Sub-Category Name</th>
              <th className="text-nowrap px-3">Sub-Category Name</th>
              <th className="text-nowrap px-3">Category Name</th>
              <th className="text-nowrap px-3">Sub-Sub-Category Image</th>
              <th></th>
            </tr>
          </thead>):null}  
          <tbody>
            
                {
                  subsubcategoryData?.data?.categories && subsubcategoryData?.data?.categories?.length > 0 ?
                    <>
                      {subsubcategoryData?.data?.categories.map((category, index) => {

                        const deleteSubCategory = () => {
                          let obj = {
                            "categoryIds": [category?.subSubCategory?.categoryId]
                          };
                          // console.log("obj:", obj)
                          confirmAlert({
                            // title: 'Change Status',
                            message: 'Are you sure you want to delete this sub-sub-category',
                            buttons: [
                              {
                                label: 'Yes',
                                onClick: () => {
                                  // console.log("deleted user")
                                  dispatch(deleteSubSubCategoryById(obj));
                                }
                              },
                              {
                                label: 'No',
                                onClick: () => {
                                  console.log("not detele")
                                }
                              }
                            ]
                          });
                        }
                        // console.log(category)
                        return (
                          <tr key={index}>
                            <td>{((pageCount?pageCount:1)-1)*10 + (index+1)}</td> 
                            <td className="titleStyle">{category?.subSubCategory?.name}</td>
                            <td className="titleStyle">{category?.subCategory ? category?.subCategory?.name:null}</td> 
                            <td className="titleStyle">{category?.subCategory ? category?.category[0]?.name:null}</td> 
                            <td>
                              <div className="banner-img">
                                <img src={category?.subSubCategory?.image ? `${category?.subSubCategory?.image}?date=${new Date()}`: noImage} 
                                 alt="banner-img" className="category-image" style={{ width: "70px",height: "3em", objectFit: "contain"}} />
                              </div>
                            </td> 
                            <td>
                              <Link to={`/edit-sub-sub-category/${category?.subSubCategory?.categoryId}`}>
                                <img src={editIcon} alt="edit-button" className="edit-icon" />
                              </Link>
                              <img src={deleteIcon} alt="delete" className="delete-icon" onClick={deleteSubCategory} />
                            </td>
                          </tr>
                        )
                      }
                      )}
                    </>
                    : (<NoData message="Sub sub Category Not Found" />)
                }
             
          </tbody>
        </table>
      </div>
      <div className="pagination-custom">
        <Pagination
          hideDisabled
          activePage={parseInt(pageCount)}
          itemsCountPerPage={10}
          totalItemsCount={subCategoryData.data.count}
          pageRangeDisplayed={5}
          hideNavigation
          onChange={handlePageChange}
        />
      </div>
      </>
            }

    
      <br/>
    </Layout>
  )
}

export default SubSubCategoryList