import React from 'react';
import Layout from '../../layout/layout';
import EditVariantNameForm from '../../wrapper/variant/EditVariantNameForm';

const EditVariantNamePage = () => {

  return (
    <Layout>
      <EditVariantNameForm />
    </Layout>
  )
}

export default EditVariantNamePage