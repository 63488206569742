import React, { useState, useEffect } from 'react'
import axiosauth from '../../axios-config-auth';
import SearchBox from '../../components/halper/SearchBox'
import editIcon from '../../assets/icons/edit.png';
import editimage from '../../assets/icons/editimage.png';
import close from '../../assets/icons/close.png';
import deleteIcon from '../../assets/icons/delete.png';
// import eyeIcon from '../../assets/icons/eye.png';
import { Link, useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import noImage from '../../assets/images/no-img.png';
import NoData from '../../components/halper/NoData';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SuccessModal from '../../components/halper/SuccessModal';
import { Redirect } from 'react-router-dom';

const CatalogueList = () => {
    const [showLoader, setShowLoader] = useState(true);
    const [collections, setCollections] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [total, settotal] = useState(0);
    const handleClose = () => setShowEditModal(false);
    const handleShow = () => setShowEditModal(true);
    const [showModal, setShowEditModal] = useState(false);
    const [file, setFile] = useState(null)
    const [success, setSuccess] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const history = useHistory();

    const pageCount = new URLSearchParams(window.location.search).get('page');

    /**
* TODO: function for pagination handling page number change
*@version 1.0.0
* @author [ Unknown]
*/
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": (pageNumber - 1) * 10,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            },
            "filters": {
                "brand": localStorage.getItem("brandObj")
                // "brand": localStorage.getItem("brandobj")
            }
        }
        axiosauth.post('/catalogue/load', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setCollections(res.data)
                    settotal(res.count)

                } else {
                    console.log("else block")
                    setCollections([])
                    settotal(0)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    /**
* TODO: function for searching according to product name
*@version 1.0.0
* @author [ Unknown]
*/
    const searching = (search) => {
        // setsearch(search)
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": 0,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            },
            "filters": {
                // "brand": localStorage.getItem("brandObj"),
                "brand": localStorage.getItem("brandobj"),
                "name": search
            }
        }
        axiosauth.post('/catalogue/load', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setCollections(res.data)
                    settotal(res.count)
                    setShowLoader(false);
                } else {
                    setCollections([])
                    settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }




    useEffect(() => {
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": 0,
            "filters": {
                "brand": localStorage.getItem("brandObj"),
                // "date": {
                //     "startDate": "2021-03-10",
                //     "endDate": "2021-03-11"
                // },
            },
            "sortBy": {
                "type": "createdOn",
                "order": -1
            }
        }
        axiosauth.post('/catalogue/load', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setCollections(res.data)
                    settotal(res.count);
                    // console.log("product count-->", res)
                    setShowLoader(false);
                } else {
                    setCollections([])
                    settotal(0);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);





    /**
* TODO: Pagination
*@version 1.0.0
* @author [Unknown]
*/

    // Handle Pagination

    const [locationKeys, setLocationKeys] = useState([]);

    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        //   console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        //   console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
                //   console.log("push call")
            }
            // console.log("locationKeys",locationKeys)

            if (history.action === 'POP') {

                if (locationKeys[1] === location.key) {
                    // console.log("Inside if pop call")
                    setLocationKeys(([_, ...keys]) => keys)
                    //        console.log("forward")
                    //   console.log("js",getQueryVariable('page'))
                    handlePageChange1(getQueryVariable('page'))
                    // Handle forward event

                } else {
                    // console.log("Inside else pop call")
                    setLocationKeys((keys) => [location.key, ...keys])
                    // console.log("backward",location.key)
                    // console.log("js",getQueryVariable('page'))
                    handlePageChange1(getQueryVariable('page'))

                }
            }
        })
    }, [locationKeys, history])

    const handlePageChange1 = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handlePageChange5 = (pageNumber) => {
        // console.log("dd",pageNumber)
        handlePageChange(pageNumber);
        setActivePage(pageNumber);
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('page', pageNumber);
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
    };
    const handleChangeUpload = (e) => {
        // console.log(e.target.value)
        let data = e.target.value
        if (data === "uploadManually") {
            history.push("/add-product")
        }
        if (data === "downloadExcel") {
            // setUploadProduct(e.target.value)
            window.location = 'http://34.131.106.77:8005/excel'
        }
        if (data === "uploadExcel") {
            handleShow()
        }
    }
    const handleSubmit = () => {

        console.log(file);

        var formData = new FormData();
        formData.append("file", file)

        console.log(formData)

        axiosauth.post("/catalogue/upload-product-catalogue-xlsx", formData)
            .then((res) => {
                let result = JSON.parse(res.data);
                if (result.success === true) {
                    setSuccess(true)
                    handleClose(true)


                }
            })

    }

    const closeModal = () => {
        setRedirect(true);
    }


    // console.log(uploadProduct)

    return (
        <div>


            <div className="left_arrow2 back-arrow mt-5" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" />&nbsp;
                <h2>Manage Product Catalogue</h2>
            </div>
            <SearchBox
                placeholder="Search.."
                onChange={(e) => searching(e.target.value)}
            />
            <div className="button button-select" >
                <select className="form-select form-select-lg" aria-label="Default select example "
                    style={{ backgroundColor: " #9fa59f3b", color: "black" }}
                    defaultValue=""
                    onChange={(e) => handleChangeUpload(e)}
                >
                    <option value="" disabled >Add product Catalogue</option>
                    <option value="downloadExcel" >Download Sample Excel</option>
                    <option value="uploadExcel">Upload  Excel File</option>
                    <option value="uploadManually">Add product Catalogue</option>
                </select>
            </div>

            {/* =================== Modal here================ */}
            <Modal show={showModal} backdrop="static" onHide={handleClose}>
                <Modal.Header className="border-0">
                    <Modal.Title>Upload Excel file</Modal.Title>
                    <end onClick={handleClose} style={{ cursor: "pointer" }}>
                        {" "}
                        {/* <i className="bi bi-x-lg"></i> */}
                        <img src={close} style={{ height: "20px", width: "20px" }} ></img>
                    </end>
                </Modal.Header>
                <Modal.Body>
                    <p>Upload File</p>
                    <div className="d-flex" style={{ justifyContent: "space-around" }}>
                        <input
                            accept=".xlsx"
                            type="file"
                            onChange={(e) => {
                                console.log((e.target.files[0]))
                                setFile(e.target.files[0])
                            }}

                            style={{ width: "70%" }}
                        //   className="form-control "
                        />
                        <Button
                            variant="primary"

                            style={{ backgroundColor: "#333333", width: "25%" }}
                            onClick={handleSubmit}
                        >
                            Upload
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>


            {/* </div> */}
            {!showLoader ?
                <div className="table-wrap">
                    {collections && collections.length > 0 ?
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th style={{ textAlign: "left" }}>Product Catalogue Name</th>
                                    <th>Brand Name</th>
                                    <th>Image</th>
                                    <th>Variants</th>
                                    {/* <th>View Details</th> */}
                                    <th>Edit Image</th>
                                    <th>Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {collections.map((product, index) => {
                                    /**
                                        * TODO: function call when click on delete button from collections list
                                        *@version 1.0.0
                                        * @author [Unknown]
                                        */
                                    const deleteBrand = () => {
                                        let obj = {
                                            "catalogueId": product.catalogueId
                                        }
                                        confirmAlert({
                                            // title: 'Change Status',
                                            message: 'Are you sure you want to delete this Catalogue ? .',
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () => {
                                                        axiosauth.post('/catalogue/remove-catalogue', obj)
                                                            .then(async (response) => {
                                                                let res = JSON.parse(response.data)
                                                                if (res.success === true) {
                                                                    searching("");
                                                                } else {
                                                                    searching("");
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            })
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    onClick: () => console.log("Hello")
                                                }
                                            ]
                                        });
                                    }

                                    return (
                                        <tr key={index}>
                                            <td>{((pageCount ? pageCount : 1) - 1) * 10 + (index + 1)}</td>
                                            <td className="titleStyle title-name" style={{ textAlign: "left" }}>{product?.name}</td>
                                            <td>{product?.brand?.name}</td>
                                            <td className="collection-img">
                                                <img src={product.imageUrls[0] !== undefined ? product.imageUrls[0].url : noImage} alt="" />
                                            </td>

                                            <td>

                                                <Link to="/brand-variant?page=1" >
                                                    <span onClick={() => {
                                                        localStorage.setItem("productobj", product._id)
                                                        localStorage.setItem("image", product.catalogueId)
                                                    }}
                                                        className="manage-link"
                                                    >
                                                        Manage
                                                    </span>
                                                </Link>

                                            </td>
                                            {/* <td>
                                                <Link to={`/brand-product-details/${product.catalogueId}`} >
                                                    <i onClick={() => localStorage.setItem("image", product.catalogueId)} class="fa fa-eye fa-eye-style"></i> 
                                                </Link>
                                            </td> */}

                                            <td>
                                                <Link to={`/update-variant-image/${product.catalogueId}`}>
                                                    <img onClick={() => localStorage.setItem("image", product.catalogueId)} src={editimage} alt="edit-button" className="edit-icon" height={25} />
                                                </Link>
                                            </td>

                                            <td>
                                                <span>
                                                <Link to={`/brand-product-details/${product.catalogueId}`} >
                                                    <i onClick={() => localStorage.setItem("image", product.catalogueId)} className="fa fa-eye fa-eye-style " style={{ marginRight:"15px"}}></i>
                                                </Link>
                                                </span>
                                                <Link to={`/update-product/${product.catalogueId}`}>
                                                    <img onClick={() => localStorage.setItem("image", product.catalogueId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                                </Link>

                                                <img onClick={deleteBrand} src={deleteIcon} alt="delete" className="delete-icon" />
                                            </td>
                                        </tr>
                                    )
                                })

                                }

                            </tbody>
                        </table>
                        :
                        <NoData message="Product Not available" />
                    }
                    <div className="pagination-custom">
                        <Pagination
                            hideDisabled
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            hideNavigation
                            onChange={handlePageChange5}
                        />
                    </div>
                </div>
                :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </div>
            }
            {redirect ? <Redirect to="/manage-product" /> : null}
            {success ? <SuccessModal message=" Catalogue Created !" onClick={closeModal} /> : null}
        </div >
    )
}

export default CatalogueList
