import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Layout from "../../layout/layout";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanners } from '../../redux/actions/bannerAction';
import axiosauth from '../../axios-config-auth';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { useHistory } from 'react-router-dom';
import { loadCategory } from '../../redux/actions/categoryAction';
import SuccessModal from '../../components/halper/SuccessModal';
import LoaderModal from '../../components/halper/LoaderModal';

const EditCategoryBanner = () => {

    const [show, setShow] = useState(false);
    const animatedComponents = makeAnimated()
    const history = useHistory();
    // eslint-disable-next-line
    const [file, setfile] = useState(null);
    // eslint-disable-next-line
    const [urlFile, setUrlFile] = useState(null)

    const [titleError, setTitleError] = useState(null)
    // eslint-disable-next-line
    const [urlError, setUrlError] = useState(null)

    const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [linkerror, setlinkerror] = useState("")

    const categoryData = useSelector(state => state.categoryData.data);
    const [selectCategory, setSelectCategory] = useState([]);
    const [catError, setCatError] = useState("");

    // const banners = useSelector(state => state.bannerdata)
    // console.log("banner data->", banners, "type=>", typeof banners)

    console.log("banner-->", urlFile);
    // banner_list=[]
    // for(i=0;i<banners.length;i++)
    const dispatch = useDispatch()

    const [slideBanner, setSlideBanner] = useState({
        title: "",
        newtab: false,
        link: "",
        alt: "",
        available: true,
        typeId: "O4FFLiVd"
    });

    const handleChange = (e) => {
        setSlideBanner({ ...slideBanner, error: false, [e.target.name]: e.target.value });
        setTitleError("")
    }
    useEffect(() => {
        dispatch(loadCategory());
    }, [dispatch])

    var cat_list = [];
    if (typeof categoryData.categories === "object") {
        for (var p = 0; p < Object.keys(categoryData.categories).length; p++) {
            // console.log(categoryData.categories[p])
            cat_list.push({ value: categoryData.categories[p]._id, label: categoryData.categories[p].name })
        }
    }

    const handleSelect = (selectedOption) => {
        setSelectCategory(selectedOption);
        console.log("selsected option:", selectedOption)
        setCatError("")
    }

    const bannerUpload = () => {

        // console.log("obj-->", obj)
        if (slideBanner.title === "") {
            setTitleError("Banner title Required");
            return 0;
        }
        if (slideBanner.link === "") {
            setlinkerror("Page Url Required");
            return 0;
        }


        let obj = {

            "bannerId": localStorage.getItem("bannerId"),
            "title": slideBanner.title,
            "link": slideBanner.link,
            "available": true,
            "categoryId":selectCategory.value,
        }
        setShowLoader(true)

        axiosauth.post('/banner/update-banne', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setShowLoader(false)
                    setShow(true)
                } else { 
                    setShowLoader(false)
                    setShow(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setShowLoader(false)
                setNetworkError(true)

            })

    }

    useEffect(() => {

        let obj = {
            bannerId: localStorage.getItem("bannerId")
        }
        axiosauth.post('/banner/get-banner-by-id', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("jdkd", res)
                if (res.success === true) {
                    console.log("banner true-->", res.banner);
                    setSlideBanner({
                        title: res.banner.title,
                        newtab: false,
                        link: res.banner.link,
                        alt: "",
                        available: true,
                        typeId: "O4FFLiVd"
                    })
                    setfile(res.banner.image)


                } else {
                    console.log("banner fasle-->", res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })


        dispatch(fetchBanners())
    }, [dispatch])



    const closeModal = () => {
        setRedirect(true);
    }

    const connectionError = () => {
        setNetworkError(false)
    }


    return (
        <Layout>

            {redirect ? <Redirect to="/categories-banner" /> : null}
            {show === true ? <SuccessModal message=" Banner Updated!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null}



            <div className="form-wrap">
                <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                    <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" /> &nbsp;
                    <h2>Update Category Banner</h2>
                </div>
                {/* </div> */}
                <div className="form-body">

                    <div className="single-field">
                        <p>Banner Title <b>*</b></p>
                        <input type="text"
                            id="title"
                            placeholder="Enter title"
                            name="title"
                            value={slideBanner.title}
                            onChange={handleChange}
                        />
                        <i className="error">{titleError}</i>
                    </div>

                    <div className="single-field">
                        <p>Banner URL <b>*</b></p>
                        <input type="text"
                            id="link"
                            placeholder="page url"
                            name="link"
                            value={slideBanner.link}
                            onChange={handleChange}
                        />
                        <i className="error">{linkerror}</i>
                    </div>
                    <div id="select-tag">
                        <p>Category Name</p>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={cat_list}
                            // value={[{label:pcat,value:pcat}]}
                            value={selectCategory}
                            onChange={handleSelect} />
                        <i className="error">{catError}</i>
                    </div>
                    <div className="banner-wrap"> 

                    </div> 

                </div>
                <div className="button">
                    <button onClick={bannerUpload}>Update Banner</button>
                </div>
            </div>


        </Layout>
    )
}

export default EditCategoryBanner;
