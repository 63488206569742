import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from "react";
import axiosauth from "../../axios-config-auth";
import Loader from "react-loader-spinner";
import close from "../../assets/icons/close.png";
import NoData from "../../components/halper/NoData";
import editIcon from "../../assets/icons/edit.png";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Layout from "../../layout/layout";
import { confirmAlert } from 'react-confirm-alert';
import deleteIcon from '../../assets/icons/delete.png';
import { useHistory } from "react-router";

const ShippingCharge = () => {
    const history = useHistory()
    const [shipping, setShipping] = useState([]);
    const [showLoader, setShowLoader] = useState(false); 
 
    const LoadShipping = () => {
        axiosauth
            .post("shipping/load-shipping", { limit: 1000 })
            .then(async (response) => {
                let res = JSON.parse(response.data);
                if (res.success === true) {
                    setShipping(res.vouchers.result);
                    console.log("response---->>", res.vouchers.result)
                    setShowLoader(false);
                } else {
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        setShowLoader(true);
        LoadShipping();
    }, []);

 

    return (
        <Layout>
            <div
                className="left_arrow2 back-arrow mt-3"
                style={{
                    display: "flex",
                    alignItems: "baseline",
                    fontFamily: "inherit",
                }}>
                <h2>Shipping Charge</h2>
                <div className="button">
                    <button onClick={() => history.push('/add-Shipping-charge')}>Add Shipping Charge</button>
                </div>
            </div>
  
            {!showLoader ? (
                <div className="table-wrap overflow-auto table-responsive card py-2 " style={{ height: "500px" }}>
                    <table>
                        {shipping && shipping.length > 0 ? (<thead>
                            <tr>
                                <th>S.No</th>
                                <th >Minimum Amount</th>
                                <th >Maximum Amount</th>
                                <th> Charge Value</th>
                                <th>Actions</th>
                            </tr>
                        </thead>) : null}
                        <tbody>
                            {shipping && shipping.length > 0 ?

                                shipping.map((item, index) => {

                                    const deleteShipping = () => {
                                        let obj = {
                                            "shipId": item.shipId,
                                        }
                                        confirmAlert({
                                            // title: 'Change Status',
                                            message: 'Are you sure you want to delete this Shipping ?',
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () => {
                                                        axiosauth.post("shipping/remove-shipping", obj)
                                                            .then(async (response) => {
                                                                console.log("delete explore---->>", response)

                                                                let res = JSON.parse(response.data)
                                                                if (response.status === 200) {
                                                                    LoadShipping()
                                                                } else {
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            })
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    onClick: () => console.log("Hello")
                                                }
                                            ]
                                        });
                                    } 
                                    return (
                                        <tr key={index}>
                                            <td>{(index + 1)}</td>
                                            <td className="titleStyle title-name" >{item.minimumAmount}</td>
                                            <td className="titleStyle title-name" >{item?.maximumAmount}</td>
                                            <td className="titleStyle title-name" >{item.amountValue}</td>
                                            <td>
                                                <img onClick={() => {
                                               history.push(`/update-Shipping-charge/${item.shipId}`)
                                                  }} src={editIcon} alt="edit-button" className="edit-icon" />
                                                 <img onClick={() => deleteShipping()} src={deleteIcon} alt="delete" className="delete-icon" />
                                            </td>
                                        </tr>
                                    )
                                })

                                :
                                (<NoData message=" Shipping Not Found" />
                                )
                            }

                        </tbody>
                    </table>

                </div>
            ) : (
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={500000}
                    />
                </div>
            )}
        </Layout>
    );
};

export default ShippingCharge;
