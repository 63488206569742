import React from 'react'
import noData from '../../assets/images/notdata.png';
const NoData = ({message}) => {
    return (
        <div className="noData">
                <img src={noData} alt="edit-button" /> 
                <h4>{message}</h4> 
        </div>
    )
}

export default NoData
