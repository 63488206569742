import React from 'react';
import AddCollectionForm from '../../wrapper/collection/AddCollectionForm';
import Layout from '../../layout/layout';

const AddCollection = () => {

  return (
    <Layout>
      <AddCollectionForm />
    </Layout>
  )
}

export default AddCollection