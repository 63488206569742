import React from 'react';
import Layout from "../../layout/layout"
import BrandViewDetail from '../../wrapper/brand/BrandViewDetail';

const BrandViewPage = () => {

  return (
    <Layout>
      <BrandViewDetail />
    </Layout>
  )
}

export default BrandViewPage