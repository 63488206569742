import {
    FETCH_COUPON,
    USER_LOADING,
    USER_FAIL,
    CREATE_COUPON,
    FETCH_COUPON_BY_ID,
    UPDATE_COUPON,
    COUPON_COUNT,
} from '../constants/types';

import axiosauth from '../../axios-config-auth';

export function loadingCoupon() {
    return {
        type: USER_LOADING,
    };
}

export function loadCoupon(user) {
    return {
        type: FETCH_COUPON,
        payload: user
    };
}

export function loadCouponById(user) {
    return {
        type: FETCH_COUPON_BY_ID,
        payload: user
    };
}
export function totalCoupon(user) {
    return {
        type: COUPON_COUNT,
        payload: user
    };
}

export function addCoupon(user) {
    return {
        type: CREATE_COUPON,
        payload: user
    };
}

export function EditCoupon(user) {
    return {
        type: UPDATE_COUPON,
        payload: user
    };
}



export function couponFail(user) {
    return {
        type: USER_FAIL,
        payload: user
    };
}


export const fetchCoupon = (search) => {
    return async dispatch => {
        dispatch(loadingCoupon());

        let obj = {
            filters: {
                voucherTitle: search,
            },
            "sortBy": {
                "type": "createdOn",
                "order": 1
            }
        };
        axiosauth.post('/vouchers/load-voucher', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("res:", res)
                if (res.success === true) {
                    dispatch(loadCoupon(res.vouchers.result));
                } else {
                    dispatch(couponFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(couponFail("Network Error"));
            })

    };
};

export const fetchCouponCount = (search) => {
    return async dispatch => {
        dispatch(loadingCoupon());

        let obj = {
            filters: {
                voucherTitle: search,
            },
            "sortBy": {
                "type": "startDate",
                "order": 1
            }
        };
        axiosauth.post('/vouchers/load-voucher', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("res:", res)
                if (res.success === true) {
                    dispatch(totalCoupon(res));
                } else {
                    dispatch(couponFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(couponFail("Network Error"));
            })

    };
};




export const fetchCouponPage = (pageNumber) => {
    return async dispatch => {
        dispatch(loadingCoupon());
        let obj = {
            count: (pageNumber - 1) * 10,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            }
        };
        axiosauth.post('/vouchers/load-voucher', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("res:", res)
                if (res.success === true) {
                    dispatch(loadCoupon(res.vouchers.result));
                } else {
                    dispatch(couponFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(couponFail("Network Error"));
            })

    };
};


export const fetchCouponById = (id) => {
    return async dispatch => {
        dispatch(loadingCoupon());
        // console.log("coupon by id")
        let obj = {
            "voucherId": id
        };
        axiosauth.post('/vouchers/load-voucher-by-id', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("res:", res)
                if (res.success === true) {
                    dispatch(loadCouponById(res.result));
                } else {
                    dispatch(couponFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(couponFail("Network Error"));
            })

    };
};


export const createCoupon = (obj) => {
    return async dispatch => {
        dispatch(loadingCoupon());
        console.log("create coupon")
        axiosauth.post('/vouchers/create-voucher', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                if (res.message === "success") {
                    dispatch(addCoupon(res));
                } else {
                    alert(res.message)
                    dispatch(couponFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(couponFail("Network Error"));
            })

    };
};

export const updateCoupon = (obj) => {
    return async dispatch => {
        dispatch(loadingCoupon());
        // console.log("coupon action")
        axiosauth.post('/vouchers/update-voucher', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("res:", res)
                if (res.message === "success") {
                    dispatch(EditCoupon(res));
                } else {
                    alert(res.message)
                    dispatch(couponFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(couponFail("Network Error"));
            })

    };
};


export const updateStatusCoupon = (obj,pageCount) => {
    return async dispatch => {
        dispatch(loadingCoupon());
        // console.log("coupon action")
        axiosauth.post('/vouchers/update-voucher', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("res:", res)
                if (res.message === "success") {
                    dispatch(fetchCouponPage(pageCount));
                    dispatch(fetchCouponCount())
                } else {
                    dispatch(couponFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(couponFail("Network Error"));
            })

    };
};
