import {
    FETCH_COLLECTION,
    USER_LOADING,
    USER_FAIL,
    ADD_COLLECTION,
    FETCH_COLLECTION_BY_ID,
    EDIT_COLLECTION
} from '../constants/types';

import axiosauth from '../../axios-config-auth';

export function loadingCollection() {
    return {
        type: USER_LOADING,
    };
}

export function loadCollection(user) {
    return {
        type: FETCH_COLLECTION,
        payload: user
    };
}

export function loadCollectionById(user) {
    return {
        type: FETCH_COLLECTION_BY_ID,
        payload: user
    };
}

export function addCollection(user) {
    return {
        type: ADD_COLLECTION,
        payload: user
    };
}

export function editCollection(user) {
    return {
        type: EDIT_COLLECTION,
        payload: user
    };
}



export function collectionFail(user) {
    return {
        type: USER_FAIL,
        payload: user
    };
}


export const fetchCollection = (search) => {
    return async dispatch => {
        dispatch(loadingCollection());
        let obj = {
            "filters": {
                "name": search
            }
        }
        axiosauth.post('gazariCollection/load-collection', obj)
            .then(async (response) => {
                console.log("Rrrrrrrrrrrrrrrrrrr",response)
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    dispatch(loadCollection(res.data));
                } else {
                    dispatch(collectionFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(collectionFail("Network Error"));
            })

    };
};

export const fetchPageCollection = (pageNumber) => {
    return async dispatch => {
        dispatch(loadingCollection());
        let obj = {
            "fields": "max",
            "limit": 10,
            "count": (pageNumber - 1) * 10,
            "sortBy": {
                "type": "datePosted",
                "order": -1
            }
        }
        axiosauth.post('gazariCollection/load-collection', obj)
            .then(async (response) => {
                console.log(response)
                console.log("Rrrrrrrrrrrrrrrrrrr 22222222",JSON.parse(response.data))
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    console.log("collection action response----->>" , res)
                    
                    dispatch(loadCollection(res.data));
                } else {
                    dispatch(collectionFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(collectionFail("Network Error"));
            })

    };
};




export const fetchCollectionById = (obj ,setCollections , setLoading) => {
    return async dispatch => {
        dispatch(loadingCollection());
        axiosauth.post('gazariCollection/load-collection-by-id', obj)
            .then(async (response) => {
                console.log("response-----------" , response)
                let res = JSON.parse(response.data)
                console.log("res------>>" , res)
                if (response.status === 200) {
                    setCollections(res)
                    setLoading(true)

                    // dispatch(loadCollectionById(res.data.collection));
                } else {
                    // dispatch(collectionFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(collectionFail("Network Error"));
            })

    };
};

const collectionImageUpdate = (cId, imageFile ,setSuccess) => {
    // console.log("id", cId, "Image =>", imageFile)
    var formData = new FormData();
    formData.append("collectionId", cId);
    formData.append("image", imageFile);
    // axiosauth.post('/collection/edit-collection-image', formData)
    if(imageFile){
        axiosauth.post('/gazariCollection/upload-collection-image', formData)
        .then(async (response) => {
            let res = JSON.parse(response.data)
            console.log("Image response ---->>" , res)
            if (res.success === true) {
                setSuccess(true)
                console.log("image res:", res);
            } else {
                console.log("..")
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }
   
}

export const createCollection = (obj, fileImage ,setSuccess) => {
    return async dispatch => {
        dispatch(loadingCollection());
        axiosauth.post('/gazariCollection/create', obj)
            .then(async (response) => { 
                let res = JSON.parse(response.data) 
                if (response.statusText === "Created") {
                    // setSuccess(true)
                    collectionImageUpdate(res.create._id, fileImage ,setSuccess)
                    // dispatch(addCollection(res));
                    // console.log("created successfullyy--------------" , res)
                } else {
                    dispatch(collectionFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(collectionFail("Network Error"));
            })

    };
};

export const updateCollection = (obj, fileImage , setUpdateStatus) => {

// console.log(" update call")

    return async dispatch => { 
        axiosauth.post('gazariCollection/update-collection', obj)
            .then(async (response) => {
                // console.log("response-------->>" , response)
                setUpdateStatus(true)
                let res = JSON.parse(response.data)

                // console.log("response--------res-" , res)
                if (response.status === 200) {
                    // setUpdateStatus(true)
                    console.log("success response-------", res.update._id)
                    collectionImageUpdate(res.update._id, fileImage , setUpdateStatus)
                    // dispatch(editCollection(res));
                } else {
                    // dispatch(collectionFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                // dispatch(collectionFail("Network Error"));
            })

    };
};




export const deleteCollection = (obj) => {

    return async dispatch => {
        dispatch(loadingCollection());
        axiosauth.post('gazariCollection/delete-collection', obj)
            .then(async (response) => {
                console.log("Delete Response----->>>", JSON.parse(response.data))
                let res = JSON.parse(response.data);
                if (res.msg === "deleted successfully") {
                    dispatch(fetchCollection());
                } else {
                    dispatch(collectionFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(collectionFail("Network Error"));
            })

    };
};
