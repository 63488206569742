import React from 'react';
import './order copy.scss';

const BillingAddressView = ({address}) => { 
 
  return (
    <div className="card content-p-h6 p-3">
      <div className="general-content">
        <p>Customer Name</p>
        <h6>{address?.firstName} {address?.lastName}</h6>
      </div>
      
      <div className="general-content">
        <p>Area, Colony, Street, Sector, Village</p>
        <h6>{address?.address}</h6>
      </div>
      <div className="general-content">
        <p>Landmark</p>
        <h6>{address?.landmark}</h6>
      </div>
      <div className="general-content">
        <p>Locality/Block/Mohalla</p>
        <h6>{address?.locality}</h6>
      </div>
      <div className="general-content">
        <p>City/District/Town</p>
        <h6>{address?.city}</h6>
      </div>
      <div className="general-content">
        <p>State</p>
        <h6>{address?.state?.stateName}</h6>
      </div>
      <div className="general-content">
        <p>Pin Code</p>
        <h6>{address?.pincode}</h6>
      </div>
    </div>
  )
}

export default BillingAddressView
