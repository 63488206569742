import {
    SEND_EMAIL_LINK,
    SEND_EMAIL_LOADING,
    SEND_EMAIL_FAIL
} from '../constants/types';

const initialState = {
    loading: false,
    data: [],
    error: ''
}


export function authData(state = initialState, action) {
    switch (action.type) {
        case SEND_EMAIL_LINK:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: ''
            }
        case SEND_EMAIL_LOADING:
            return {
                ...state,
                data: [],
                loading: true,
                error: ''
            }

        case SEND_EMAIL_FAIL:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}