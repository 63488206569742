import React from 'react';
import Layout from "../layout/layout"
import CartList from '../wrapper/CartManage/cartList';

const CartDetail = () => {

  return (
    <Layout>
      <div>
        <CartList />
      </div>
    </Layout>
  )
}

export default CartDetail