import React from 'react'
import Layout from '../../layout/layout'
import CmsMenu from '../../components/menus/CmsMenu'

const Cms = () => {
    return (
        <Layout>
            <CmsMenu />
        </Layout>
    )
}

export default Cms;
