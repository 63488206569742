import React from 'react';
import Layout from "../../layout/layout"
import SettingMenu from '../../components/menus/SettingMenu';

const Setting = () => {

  return (
    <Layout>
      <>
        <SettingMenu />
      </>
    </Layout>
  )
}

export default Setting