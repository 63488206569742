import React from 'react';
import LoginForm from "../components/login/loginForm"
// import login_art from "../assets/images/login_art.png"
import login_art from "../assets/images/gajari.svg"
// import gajariLogo from "../../public/fav1.png"
const Login = () => {

  return (
    <div className="login-wrap">
      <div className="login-art" style={{marginTop :"7%"}}>
      <h1></h1>
        <img src={login_art} alt="login-art" />
        {/* <img src={gajariLogo} alt="login-art" /> */}
      </div>
      <div className="loginform-wrap">
        <LoginForm />
      </div>
    </div>
  )
}

export default Login