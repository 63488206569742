import React, {useState, useEffect} from 'react';
import Layout from "../../layout/layout";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import axiosauth from '../../axios-config-auth';
import { Redirect,useHistory,useParams } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';
import LoaderModal from '../../components/halper/LoaderModal';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import Loader from 'react-loader-spinner';

const EditBrandVariant = () => {
     const history = useHistory()
     const { productVariantId } = useParams();
    const animatedComponents = makeAnimated();
    const [value1, setValue1] = useState("true");
    const [redirect, setRedirect] = useState(false);
    const [show, setShow]=useState(false);
    const[checked,setchecked]=useState(false)
    const[checked1,setchecked1]=useState(false)
    const[checked2,setchecked2]=useState(false)
      const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
     const [dataLoader, setDataLoader] = useState(true);
 // eslint-disable-next-line
    const [variants, setVariants]=useState([])
    const [variantValue, setVariantValue]=useState([]);
    const [variantValue1, setVariantValue1]=useState([]);
    const [variantValue2, setVariantValue2]=useState([]);

const [sendVarList, setsendVarList]=useState([])
 // eslint-disable-next-line
const [sendVarList1, setsendVarList1]=useState([])
 // eslint-disable-next-line
const [sendVarList2, setsendVarList2]=useState([])
 // eslint-disable-next-line
    const [variantshow, setvariantshow] = useState(true)
     // eslint-disable-next-line
    const [variantshowa, setvariantshowa] = useState(false)
     // eslint-disable-next-line
    const [variantshowb, setvariantshowb] = useState(false)
    const [catalogueData, setCatelogueData]=useState([]);
    const [product, setproduct] = useState({
        "name": "",
        "shortDescription": "",
        "longDescription": ""
    })
    const {shortDescription, longDescription } = product;

    const[selectedOption,setselectedOption]=useState("")
    const[selectedOption1,setselectedOption1]=useState("")
    const[selectedOption2,setselectedOption2]=useState("")
     // eslint-disable-next-line
const [varParent, setVarParent]=useState([]);
 // eslint-disable-next-line

const [varValue, setVarValue]=useState([]);

const handleSelect = (selectedOption,variants,a) => {
    
    let varvaluelist=[]
    console.log("select option", selectedOption)

    for(var p=0;p<variants.length;p++){
        if(selectedOption.value===variants[p].parent._id){
             console.log("variants:",variants[p].values)
             for(var l=0;l<variants[p].values.length;l++){
                  varvaluelist.push({label:variants[p].values[l].value, value:variants[p].values[l]._id})
             }
        }
    }
    setselectedOption(a)
    setVariantValue(varvaluelist)
}

const handleSelectValue = (selectedOption) => {
    setsendVarList(selectedOption.value);
    // setVarError("")
    setselectedOption(selectedOption)
    console.log("sendVarList",sendVarList)
}

  // select variant 2
  const handleSelect1 = (selectedOption,variants,b) => {
    let varvaluelist=[]
    // setCategoryId(selectedOption.catObjid);
    console.log("select option", selectedOption)
    for(var p=0;p<variants.length;p++){
        if(selectedOption.value===variants[p].parent._id){
             console.log("variants:",variants[p].values)
             for(var l=0;l<variants[p].values.length;l++){
                  varvaluelist.push({label:variants[p].values[l].value, value:variants[p].values[l]._id})
             }
        }
    }
    setselectedOption1(b)

    setVariantValue1(varvaluelist)
}

const handleSelectValue1 = (selectedOption) => {
    setsendVarList1(selectedOption.value)
    console.log("sendVarList",sendVarList)
    setselectedOption1(selectedOption)
}

  // select variant3
  const handleSelect2 = (selectedOption,variants,c) => {
    let varvaluelist=[]
    // setCategoryId(selectedOption.catObjid);
    console.log("select option", selectedOption)

    for(var p=0;p<variants.length;p++){
        if(selectedOption.value===variants[p].parent._id){
             console.log("variants:",variants[p].values)
             for(var l=0;l<variants[p].values.length;l++){
                  varvaluelist.push({label:variants[p].values[l].value, value:variants[p].values[l]._id})
             }
        }
    }
    setselectedOption2(c)
    setVariantValue2(varvaluelist)
}

const handleSelectValue2 = (selectedOption) => {
    setsendVarList2(selectedOption.value)
    console.log("sendVarList",sendVarList)
    setselectedOption2(selectedOption)
}

console.log("varParent",varParent)

          /**
* TODO: function use for updating values required for creating variant
*@version 1.0.0
* @author [ Unknown]
*/
const handleChange = (e) => {
    setproduct({ ...product, error: false, [e.target.name]: e.target.value });
    // console.log(collection);
  };
const submitVariant=(e)=>{
    e.preventDefault();
var variantSend = []
console.log("sendVarList.length",sendVarList.length)
console.log("sendVarList",sendVarList)

variantSend.push(selectedOption?.value)
variantSend.push(selectedOption1?.value)
// variantSend.push(selectedOption2.value)

    let obj={
    "baseProduct":localStorage.getItem("productobj"),
    "productVariantId":localStorage.getItem("variant"),
	"variant":variantSend,
	"name":catalogueData.name,
	"slug":catalogueData.name,
	"seoTitle":catalogueData.name,
	"seoDescription":catalogueData.name,
    "shortDescription":checked1===true?catalogueData.shortDescription:shortDescription,
	"longDescription":checked2===true?catalogueData.longDescription:longDescription,
	
	"specification":checked===true?catalogueData.specification:specs
    }
    console.log("variant --obj--->:", obj);

    setShowLoader(true)

    axiosauth.post("/product-variant/update-product-variant", obj).then(response => {
  
        let res = JSON.parse(response.data)
      console.log("res", res)
        if (res.message === "success") {
        setShowLoader(false)
           setShow(true)
           localStorage.setItem("productVariantId",res.data.productVariantId)
      
        }
        else {
            setShowLoader(false)

            // setcatalogueerror(res.message)
        }
      
      }).catch((error) => {
        console.log(error);
        // setNetworkError(true)
      }).then(() => {
        console.log("-----always executes");
      })


}


    const handleChange1 = (e) => {
        setValue1(e.target.value);
    }

    const [specs, setspecs] = useState([{
        "name": "",
        "value": ""
    }])
          /**
    * TODO: function use for removing specification row
    *@version 1.0.0
    * @author [ Unknown]
    */
    const removespecs = (index) => {
        const temp = [...specs];
        const filter = temp.filter((item, i) => i !== index)
        setspecs(filter)
    }
       /**
    * TODO: function use for adding specification row
    *@version 1.0.0
    * @author [ Unknown]
    */
    const addspecs = () => {
        setspecs([...specs, { name: "", value: "" }]);
    }

             /**
* TODO: function use for handling name value change of specification
*@version 1.0.0
* @author [ Unknown]
*/
  const handleChange2 = (e, name, index) => {
    const { value } = e.target;
    const list = [...specs];
    list[index][name] = value;
    setspecs(list);
};
// eslint-disable-next-line
const [varListParent, setVarListParent]=useState([]);


useEffect(() => {

    const fetchVarData=()=>{

    let obj = {
        "productVariantId":localStorage.getItem("variant")
    }
    axiosauth.post('/product-variant/find-product-variant-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("resssss-->", res.result[0])
        
        if (res.success === true) {
            setproduct({
              
                // name:res.result[0].name,
                shortDescription:res.result[0].shortDescription,
                longDescription:res.result[0].longDescription,
               
            })
            // console.log("rj----->",res.result[0].variant[0]) 
            setVarParent({value:res.result[0].variant[0].parentVariant._id, label:res.result[0].variant[0].value})
            setVarValue({value:res.result[0].variant[0]._id, label:res.result[0].variant[0].value})
            setDataLoader(false)
            findVariants(
                {value:res.result[0].variant[0]._id, label:res.result[0].variant[0].value},
                {value:res.result[0].variant[1]._id, label:res.result[0].variant[1].value},
                // {value:res.result[0].variant[2]._id, label:res.result[0].variant[2].value}
                );

            // console.log("Loader....")
            // if (res.result[0].variant.length === 1) {
            //     change333(res.result[0].variant[0].parentVariant.variantName, res.result[0].variant[0].value)
            // }
            // if (res.result[0].variant.length === 2) {
            //     change333(res.result[0].variant[0].parentVariant.variantName, res.result[0].variant[0].value)
            //     setvariantshowa(true)
            //     change444(res.result[0].variant[1].parentVariant.variantName, res.result[0].variant[1].value)
            //     //  changevalue33()
            // }
            // if (res.result[0].variant.length === 3) {
            //     change333(res.result[0].variant[0].parentVariant.variantName, res.result[0].variant[0].value)
            //     setvariantshowa(true)
            //     change444(res.result[0].variant[1].parentVariant.variantName, res.result[0].variant[1].value)
            //     setvariantshowb(true)
            //     change555(res.result[0].variant[2].parentVariant.variantName, res.result[0].variant[2].value)
            //     //  changevalue33()
            // }

            setspecs(res.result[0].specification)
            if(res.result[0].specification.length===0)
              {
                  setValue1('no')
                //   setshow(false)
              }
        } else {
         
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }



fetchVarData()

    let obj = {
        "catalogueId":localStorage.getItem("image")
    }
    axiosauth.post('/catalogue/find-catalogue-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
            if(res.result){
                setCatelogueData(res.result[0]);
            }
        } else {
         
        }
      })
      .catch((error) => {
        console.log(error);
      })
      setvariantshow(true)


    const findVariants = (a,b,c) => {
        let obj = {

        }
        axiosauth.post('/variants/findAllVariants/', obj).then(response => {
            let res = JSON.parse(response.data)
            if (res.success === true) {
                let variants = [];
                var varList = []
                variants.push({
                    "parent": {
                        "_id": "5f60998ee0cb47251035e65b",
                        "variantId": "yikh8vDPiUjkKVLO1bneTmkBQhbvRSRa",
                        "variantName": "colorzzzzzzzzzzzzzz",
                        "__v": 0
                    },
                    "values": [
                        {
                            "_id": "5f6c5b8be2eab8709342c308",
                            "value": "redzzzzzzzzzzzzzzz",
                            "parentVariant": "5f60998ee0cb47251035e65b",
                            "variantId": "color-red",
                            "__v": 0
                        },
                        {
                            "_id": "5f6c5b90e2eab8709342c309",
                            "value": "blackzzzzzzzzzzzzzz",
                            "parentVariant": "5f60998ee0cb47251035e65b",
                            "variantId": "color-black",
                            "__v": 0
                        },
                        {
                            "_id": "5f6c5b94e2eab8709342c30a",
                            "value": "goldenzzzzzzzzzzzzzzzzz",
                            "parentVariant": "5f60998ee0cb47251035e65b",
                            "variantId": "color-golden",
                            "__v": 0
                        }
                    ]
                })
                for (var i = 0; i < res.data.length; i++) {
                    variants.push(res.data[i])
                    varList.push({label:res.data[i].parent.variantName,value:res.data[i].parent._id})
                }
                console.log("jjdjd",varList)
                setVariants(variants)
                setVarListParent(varList)
                handleSelect(varList[0],variants,a)
                handleSelect1(varList[1],variants,b)
                handleSelect2(varList[2],variants,c)

            }
            else {

            }

        }).catch((error) => {
            console.log(error);
        }).then(() => {
            console.log("-----always executes");
        })
    }
    findVariants()
   
  }, [productVariantId]);


  const closeModal = () => {
    setRedirect(true);
}

const connectionError=()=>{
        setNetworkError(false)
    }

const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }


    return (
        <Layout>
         {/* {dataLoader === true ? */}
                <>

            {redirect ? <Redirect to="/brand-variant?page=1" /> : null}
            {show === true ? <SuccessModal message="Variant Updated!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null}
           <form onSubmit={submitVariant} className="form-wrap">
                {/* <div className="form-header"> */}

                <div className="left_arrow2  back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                        <img src={left_arrow2}  onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                            <h2>Update Variant</h2>
                    </div>

                    {/* <h4>Update Variant</h4> */}
                {/* </div> */}
                <div className="form-body">

                    <div className="single-field">
                        <p>Product Name <b>*</b></p>
                        <input type="text"
                            id="name"
                            placeholder="Enter Product Name"
                            // name="name"
                            value={catalogueData.name}
                            // onChange={handleChange}
                            readOnly
                        />
                    </div>


                {/* <div style={{display:'flex',justifyContent:'space-between' }}> */}
                <div className="select-variant">
                <br/>
                
                <div className="var1">
                <h4  className="label-form"> Variant <b>*</b></h4>
             
                    <div className="row">
                    <div className="col-md-4">
                       <span className="ml-1">
                              Size
                           </span>
                           <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={variantValue1}
                                value={selectedOption1}
                                name="category"
                                required
                                onChange={handleSelectValue1}
                            />
                       </div>
                       {/* <div className="col-md-4">
                           <span className="ml-1">
                              Colour
                           </span>
                           <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={variantValue}
                                    name="variantValue"
                                    required
                                    value={selectedOption}
                                    onChange={handleSelectValue}
                                />
                       </div> */}
                        
                       {/* <div className="col-md-4">
                       <span className="ml-1">
                               Weight
                           </span>
                           <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={variantValue2}
                                    value={selectedOption2}
                                    name="category"
                                    required
                                    onChange={handleSelectValue2}
                                />
                       </div>   */}
                          
                    </div>
                   
              
              
                    {/* {variantshowa === false && variantshowb === false ? (
                        <div className="button" style={{marginTop:0, marginRight:'1em'}}>
                            <button onClick={() => setvariantshowa(true)} >+ ADD</button>
                        </div>
                        ) : null} */}
            </div>


            {/* <div className="var2">
                
             
                    
                    <div className="select-flex">                        
                            <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={varListParent}
                                value={varListParent[1]}

                                name="category"
                                required
                                // onChange={handleSelect1}
                            />
                        <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={variantValue1}
                                value={selectedOption1}
                                name="category"
                                required
                                onChange={handleSelectValue1}
                            />
                    </div>
                   
              
              
                  
            </div> */}

            {/* <div className="var3">
                
              
                    
                    <div className="select-flex">                         
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={varListParent}
                                    value={varListParent[2]}
                                    name="category"
                                    required
                                    // onChange={handleSelect2}
                                />
                            <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={variantValue2}
                                    value={selectedOption2}
                                    name="category"
                                    required
                                    onChange={handleSelectValue2}
                                />
                        </div>
                  
              
            </div> */}


                </div>
                {/* <div className="spec">

                <div className="radio-wrap"> 

                    <div className="d-flex justify-content-between">
                        <h4> Specification <b>*</b></h4>
                        <div style={{marginTop:'12px'}}>
                        <label style={{ display: "flex" }}>
                                <input type="checkbox" style={{ width: "18px", marginRight: -"38px" }}
                                    checked={checked}
                                    onChange={() => setchecked(!checked)}
                                />
                                <span style={{ lineHeight: '3', paddingLeft: '5px', marginTop: "-18px", flex: '1' }}>Same as Product</span>
                            </label>
                            </div>
                    </div>


                        <div>
                            <aside>
                                <input
                                    type="radio"
                                    name="specification"
                                    value="true"
                                    checked={value1 === "true" ? true : false}
                                    onChange={handleChange1}
                                />
                                <span>Yes</span>
                            </aside>
                            <aside>
                                <input
                                    type="radio"
                                    name="specification"
                                    value="false"
                                    checked={value1 === "true" ? false : true}
                                    onChange={handleChange1}
                                />
                                <span>No</span>
                            </aside>
                        </div>
                    </div>

                    <div className="specification">

                        {value1 === "true" ?
                            <>
                                {specs && specs.length > 0
                                    ? specs.map((value, index) => {

                                        return (
                                            <>
                                                <section>
                                                
                                                        <div className="two-field">
                                                            <input
                                                                placeholder="Title"
                                                                value={value.name}
                                                                className={'form-control form-control-nonround'}
                                                                mandatory={true}
                                                                onChange={(e) => handleChange2(e, "name", index)}

                                                            />
                                                        </div>
                                            
                                                        <div className="two-field">
                                                            <input
                                                                placeholder="Value"
                                                                value={value.value}
                                                                className={'form-control form-control-nonround'}
                                                                mandatory={true}
                                                                onChange={(e) => handleChange2(e, "value", index)}

                                                            />
                                                        </div>
                                                    <div>
                                                        <button className="cross_button" onClick={() =>
                                                            removespecs(index)}> X</button>
                                                    </div>
                                                </section>
                                            </>
                                        )

                                    })
                                    : null}

                                {specs.length < 5 ? (
                                  
                                    <div className="add_button">
                                        <button onClick={() => addspecs()}>+ADD</button>
                                       
                            
                                    </div>) : null}
                                    
                            </>
                            : null
                        }

                    </div>


                </div> */}
                    
                    {/* <div id="short-description">
                      
                    <div className="d-flex justify-content-between">
                         <p className="label-form">Short Description (max-500 character)<b>*</b></p>
                        <div style={{marginTop:'12px'}}>
                        <label style={{ display: "flex" }}>
            <input type="checkbox" style={{ width: "18px", marginRight: -"38px" }}
                checked={checked1}
                onChange={() => {setchecked1(!checked1)}}
            />
            <span style={{ lineHeight: '3', paddingLeft: '5px', marginTop: "-18px", flex: '1' }}>Same as Product</span>
        </label>
                            </div>
                    </div>


                        <textarea
                            placeholder="Enter Short Description"
                            name="shortDescription"
                            value={shortDescription}
                            onChange={handleChange}
                            maxLength={500}
                            onInput={maxLengthCheck}
                            onKeyDown={e => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                        />
                    </div> */}

                    {/* <div id="short-description">
                    
                       <div className="d-flex justify-content-between">
                        <p className="label-form">Long Description (max-2000 character)<b>*</b></p>
                        <div style={{marginTop:'12px'}}>
                        <label style={{ display: "flex" }}>
                                <input type="checkbox" style={{ width: "18px", marginRight: -"38px" }}
                                    checked={checked2}
                                    onChange={() => setchecked2(!checked2)}
                                />
                                <span style={{ lineHeight: '3', paddingLeft: '5px', marginTop: "-18px", flex: '1' }}>Same as Product</span>
                            </label>
                            </div>
                    </div>
                      
                      
                        <textarea
                            placeholder="Enter Long Description"
                            name="longDescription"
                            value={longDescription}
                            onChange={handleChange}
                            maxLength={2000}
                            onInput={maxLengthCheck}
                            onKeyDown={e => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                        />
                    </div> */}

                    {/* <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                    <label for="vehicle1">Images same as Product</label><br/> */}

                </div>
               

                <div className="button">
                    <button>Update</button>
                </div>
            </form>

              </>
{/* 
         :
          <div className="loader-style">
              <Loader
                  type="ThreeDots"
                  color="rgba(32, 159, 132, 1)"
                  height={100}
                  width={100}
                  timeout={30000} //3 secs
              />
          </div>
        } */}
        </Layout>
    )
}

export default EditBrandVariant
