import {
    FETCH_PRODUCT,
    USER_LOADING,
    USER_FAIL,
} from '../constants/types';
import axiosauth from '../../axios-config-auth';

export function loadingProduct() {
    return {
        type: USER_LOADING,
    };
}


export function fetchProduct(user) {
    return {
        type: FETCH_PRODUCT,
        payload: user
    };
}

export function fetchProductFail(user) {
    return {
        type: USER_FAIL,
        payload: user
    };
}



export const loadProduct = () => {
    return async dispatch => {
        dispatch(loadingProduct());

        axiosauth.post('/products/load')
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("product:", res.data)
                if (res.success === true) {
                    dispatch(fetchProduct(res.data));
                } else {
                    dispatch(fetchProductFail(res.message));
                }
            })
            .catch((error) => {
                // console.log(error);
                dispatch(fetchProductFail("Network Error"));
            })

    };
};
