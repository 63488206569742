import React, { useState, useEffect, useRef } from 'react'
import uploadIcon from '../../assets/icons/upload.png';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { createCategory } from '../../redux/actions/categoryAction';
import SuccessModal from '../../components/halper/SuccessModal';
import editImageIcon from '../../assets/icons/edit.png';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router';
import { Editor } from '@tinymce/tinymce-react';

/**
* TODO: Add Category :
* @version 1.0.0
* @author [ Unknown]
*/

const AddCategory = () => {
    const [file, setfile] = useState("");
    const [isFile, setIsFile] = useState(false);
    const [urlFile, setUrlFile] = useState(null)
    const [redirect, setRedirect] = useState(false);
    const [imgError, setImgError] = useState("");
    const history = useHistory();
    const [seoText, setSEOText] = useState('');
    const editorRef = useRef(null);
    const catstatus = useSelector(state => state.categoryData.data)
    // const [seoTitle , setSEOTitle] = useState("");
    // const [seodescription , setSEODescription] = useState("")

    const variantOptions = useSelector(state => state.variantData.data.data)

    var var_list = [];
    if (typeof variantOptions === "object") {
        for (var p = 0; p < Object.keys(variantOptions).length; p++) {
            var_list.push({ label: variantOptions[p].parent.variantName, value: variantOptions[p].parent._id })
        }
    }
    const [formValues, setFormValues] = useState({
        name: '',
        longDescription: '',
        shortDescription: '',
        seoTitle: '',
        seoDescription: '',
        featured: true,
        "deliveryTime": '',
        "premiumDeliveryTime": '',
        "priority": '',
        "tags": ''
    })
    const { name, seoTitle, seoDescription, tags } = formValues;

    const dispatch = useDispatch();
    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            error: false,
            [e.target.name]: e.target.value
        })
    }

    const categorySubmit = (e) => {
        e.preventDefault()

        let obj = {
            "name": name,
            "longDescription": name,
            "shortDescription": name,
            "type": "category",
            "seoTitle": seoTitle,
            "seoDescription": seoDescription,
            "featured": true,
            seoText :seoText,
            // "tags": [tags],
            "tags": tags.split(","),
            "deliveryTime": "",
            "premiumDeliveryTime": "",
            "priority": 1
        }

        if (file.length < 1) {
            setImgError("Image required")
            setTimeout(() => {
                setImgError("")
            }, 5000)
            return 0;
        }

        dispatch(createCategory(obj, file.length > 1 ? urlFile : null))
    }

    const closeModal = () => {
        setRedirect(true);
    }

    const backButton = () => {

        if (name !== "") {

            confirmAlert({
                // title: 'Change Status',
                message: 'Are you sure to do this. You will loss your data.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () =>
                            setRedirect(true)
                    },
                    {
                        label: 'No',
                        onClick: () => console.log("No")
                    }
                ]
            });
        } else {
            setRedirect(true)
        }

    }



    return (
        <>
            {redirect ? <Redirect to="/category-manage?page=1" /> : null}
            {catstatus.success === true ? <SuccessModal message="New Category Created !" onClick={closeModal} /> : null}

            <form className="form-wrap" onSubmit={categorySubmit}>

                <div className='d-flex justify-content-between'>
                    <div className="left_arrow2 back-arrow align-self-center mb-0" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                        <img src={left_arrow2} onClick={backButton} alt="left-arrow" /> &nbsp;
                        <h2>Create Category</h2>

                    </div>
                    <div>
                        <div className='d-flex '>
                            <div className="button ml-0">
                                <button onClick={() => history.goBack()}>Back</button>
                            </div>
                            <div className="button ml-2">
                                <button onClick={() => window.location.reload()}>Refresh</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-body">
                    <div div className="single-field">
                        <p>Category Name <b>*</b> </p>
                        <input type="text" placeholder="Category Name"
                            //  pattern="^[A-Za-z]+$"
                            //  onKeyPress={(e) => onlyLetterAllowWithSpace (e)}
                            //   oninvalid="setCustomValidity('Please enter alphabets only. ')" 

                            name="name" required onChange={handleChange} />
                    </div>


                    <div className="single-field">
                        <p> Search Keyword</p>
                        <input title="Please enter on alphabets only. "
                            // pattern="^[A-Za-z ,]+$"
                            // oninvalid="setCustomValidity('Please enter  alphabets only. ')"

                            type="text" placeholder="Tags" name="tags" onChange={handleChange} />
                    </div>

                    <div className="single-field">
                        <p>SEO Title <b></b></p>
                        <input title="Please enter on alphabets only. "
                            //  pattern="^[A-Za-z ,]+$" oninvalid="setCustomValidity('Please enter  alphabets only. ')" 
                            type="text" placeholder="SEO Title" name="seoTitle" onChange={handleChange} />
                    </div>
                    <div className="single-field">
                        <p>SEO Description <b></b></p>
                        <input title="Please enter on alphabets only. "
                            //  pattern="^[A-Za-z ,]+$" oninvalid="setCustomValidity('Please enter alphabets only. ')" 
                            type="text" placeholder="SEO Description" name="seoDescription" onChange={handleChange} />
                    </div>

                    <div className='mt-3'>
                        <p className="label-form">SEO Text </p>
                        <Editor
                            onInit={(evt, editor) => { editorRef.current = editor }}
                            initialValue=""
                            onEditorChange={(newText) => {
                                //   console.log(newText)
                                setSEOText(newText)
                            }}
                            init={{
                                height: 450,
                                menubar: true,
                                plugins: [
                                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>


                    <div >
                        <p style={{
                            "marginTop": "12px",
                            "fontFamily": "Roboto",
                            "fontStyle": "normal",
                            "fontWeight": 500,
                            "fontSize": "18px",
                            "lineHeight": "24px",
                            "color": "#4F4F4F"
                        }}>{`Image Dimension Must be (500 X 500) , image (jpg, png , WebP) and image size must be less than < 200KB`} <b>*</b></p>
                        {!isFile ?
                            <div class="upload-btn-wrapper mt-2">
                                <button className="uplod-btn">
                                    <img src={uploadIcon} alt="" /><br />
                                    <p>Upload image
                                        <br />
                                        <span style={{ fontSize: "10px" }}>500 X 500</span></p>
                                </button>
                                <input
                                    type="file"
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                                        if (e.currentTarget.files[0].size >= 204800) {
                                            alert("Images size must be less than 200KB")
                                            return 0
                                        }
                                        setIsFile(true);
                                        setUrlFile(e.currentTarget.files[0]);
                                        setImgError("")

                                        let img = new Image()
                                        img.src = URL.createObjectURL(e.currentTarget.files[0])
                                        img.onload = () => {
                                            if (img.width === 500 && img.height === 500) {
                                                setfile(img.src);
                                                // alert("correct image")
                                            } else {
                                                alert('Incorrect image dimension. Please upload an image 500X500 pixels.')
                                            }
                                        }

                                    }}
                                />
                            </div> :
                            <aside class="image">
                                <div class="overlay1">
                                    <label for="file-upload">
                                        <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                                    </label>
                                </div>
                                <img class="screen" src={file} alt="" />

                                <input
                                    type="file"
                                    id="file-upload"
                                    style={{ display: 'none' }}
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                                        if (e.currentTarget.files[0].size >= 204800) {
                                            alert("Images size must be less than 200KB")
                                            return 0
                                        }
                                        setIsFile(true);
                                        setUrlFile(e.currentTarget.files[0]);

                                        let img = new Image()
                                        img.src = URL.createObjectURL(e.currentTarget.files[0])
                                        img.onload = () => {
                                            if (img.width === 500 && img.height === 500) {
                                                setfile(img.src);
                                                // alert("correct image")
                                            } else {
                                                alert('Incorrect image dimension. Please upload an image  500X500 pixels.')
                                            }
                                        }
                                    }}
                                // required
                                />
                            </aside>
                        }
                        <i className="error">{imgError}</i>
                    </div>
                    <div className="button mt-5">
                        <button>Create</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default AddCategory
