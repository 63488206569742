import React, { useState, useEffect, useRef } from "react";
import Layout from "../../layout/layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerInput from "../../components/halper/DatePickerInput";
import axiosauth from "../../axios-config-auth";
import SuccessModal from "../../components/halper/SuccessModal";
import LoaderModal from "../../components/halper/LoaderModal";
import { useHistory } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
// import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import country from "../../country.json";

const CreateProduct = () => {
  const history = useHistory();
  const [openSuccess, setOpenSuccess] = useState(false);
  const myRefname = useRef(null);
  const [value, setValue] = useState(null);
  const [value19, setValue19] = useState(null);
  const animatedComponents = makeAnimated();
  // const [gst, setgst] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [total, settotal] = useState(0);
  const [total1, settotal1] = useState(0);
  const [total2, settotal2] = useState(0);

  const [showLoader, setShowLoader] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const [brandError, setBrandError] = useState("");
  const [catError, setCatError] = useState("");
  const [allError, setAllError] = useState("");

  // const [isDisplay, setIsDisplay] = useState("false");
  // const [isExchangeable, setIsExchangeable] = useState("false");
  // const [COD, setCOD] = useState("false");
  const [productId, setProductId] = useState("");
  const [productCode, setProductCode] = useState("");
  const [pproductId, setPProductId] = useState("");
  const [totalQuantity, setTotalQuantity] = useState();
  const [variantError, setVariantError] = useState("");

  // const [isNumberLarge, setIsNumberLarge] = useState(0);

  const handleSelectCountry = (selectedOption, index) => {
    handleChange2(selectedOption.value, "country", index);
  };
  /**
   * TODO: function use for handle navigation
   *@version 1.0.0
   * @author [ Unknown]
   */
  // eslint-disable-next-line
  const handleClick = () => {
    // myRefname.current.focus();
    myRefname.current.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  };

  const [catalogueerror, setcatalogueerror] = useState("");
  const [error, seterror] = useState("");
  const [dateError, setDateError] = useState("");
  // const [stickyList, setStickyList] = useState([])
  // const [selectSticky, setSelectSticky] = useState([])

  // option's api call ::
  const [specs, setspecs] = useState([
    {
      units: "",
      sku: "",
      shippingCharge: "",
      mrp: "",
      modelNumber: "",
      price: "",
      isReturnable: false,
      isExchangeable: false,
      EssentialItem: false,
      isCOD: false,
      showExpiry: false,
      priceSecondaryDisplay: false,
      sticky: [],
      returnDays: "",
      country: "China",
      // date: new Date(),
      expiryDate: "",
      date: "",
      dateToPass:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() > 8
          ? new Date().getMonth() + 1
          : "0" + (new Date().getMonth() + 1)) +
        "-" +
        (new Date().getDate() > 9
          ? new Date().getDate()
          : "0" + new Date().getDate()),
    },
  ]);
  // console.log("spec----->>", specs)
  /**
   * TODO: function use for removing specification row
   *@version 1.0.0
   * @author [ Unknown]
   */
  const removespecs = (index) => {
    const temp = [...specs];

    const filter = temp.filter((item, i) => i !== index);

    setspecs(filter);
  };
  /**
   * TODO: function use for adding specification row
   *@version 1.0.0
   * @author [ Unknown]
   */
  const addspecs = () => {
    setspecs([
      ...specs,
      {
        units: "",
        sku: "",
        shippingCharge: "",
        mrp: "",
        modelNumber: "",
        price: "",
        isReturnable: false,
        priceSecondaryDisplay: false,
        isCOD: false,
        showExpiry: false,
        isExchangeable: false,
        EssentialItem: false,
        // sticky: [],
        returnDays: "",
        country: "China",
        date: "",
        expiryDate: "",
        dateToPass:
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() > 8
            ? new Date().getMonth() + 1
            : "0" + (new Date().getMonth() + 1)) +
          "-" +
          (new Date().getDate() > 9
            ? new Date().getDate()
            : "0" + new Date().getDate()),
      },
    ]);
  };
  /**
   * TODO: function use for creating new product
   *@version 1.0.0
   * @author [ Unknown]
   */

  const submit = (e) => {
    // console.log("submit", specs)
    e.preventDefault()
    seterror(false);

    if (value === null) {
      seterror(true);
      setBrandError("Please Select Brand");
      return 0;
    }
    if (value19 === null) {
      seterror(true);
      setCatError("Please Select Catalogue");
      setBrandError("");
      return 0;
    }

    for (let i = 0; i < specs.length; i++) {
      if (parseInt(specs[i].price) > parseInt(specs[i].mrp)) {
        seterror(true);
        alert(" Offer Price must be less than MRP ");
        return 0;
      }
    }

    for (let i = 0; i < specs.length; i++) {
      if (specs[i].price === "") {
        seterror(true);
        alert(" Offer Price must be required ");
        return 0;
      }
    }

    for (let i = 0; i < specs.length; i++) {
      if (specs[i].date === "") {
        seterror(true);
        setDateError("Select Date")
        setTimeout(() => {
          setDateError("")
        }, 3000)
        // alert(" Offer Price must be less than MRP ");
        return 0;
      }
    }
    for (var x = 0; x < specs.length; x++) {

      if (specs[x].value === "") {
        seterror(true);
        setVariantError("select Variant")
        setTimeout(() => {
          setVariantError("")
        }, 5000)
        return 0;
      }
    }
    var product = [];

    for (var x = 0; x < specs.length; x++) {
      // console.log(specs[x].sku.toUpperCase());
      product.push({
        productCatalogue: value19?.value,
        brand: value.value,
        selectedVariant: specs[x]?.value?.value,
        sku: specs[x].sku.toUpperCase(),
        shippingCharge: parseInt(specs[x].shippingCharge),
        slug: "",
        price: parseInt(parseInt(specs[x].price)),
        offer: parseInt(parseInt(specs[x].price)),
        mrp: parseInt(specs[x].mrp),
        stock: parseInt(specs[x].units),
        status: "sdbn",
        minOrder: 45,
        isExchangable: specs[x].isExchangeable,
        EssentialItem: specs[x]?.EssentialItem,
        cod: specs[x].isCOD,
        priceSecondaryDisplay: specs[x].priceSecondaryDisplay,
        explore: [],
        available: "vd",
        hsnCode: "",
        manufacturerDate: specs[x].date.getFullYear() +
          "-" +
          (specs[x].date.getMonth() > 8
            ? specs[x].date.getMonth() + 1
            : "0" + (specs[x].date.getMonth() + 1)) +
          "-" +
          (specs[x].date.getDate() > 9
            ? specs[x].date.getDate()
            : "0" + specs[x].date.getDate()),

        expiryDate: specs[x].expiryDate ? specs[x].expiryDate.getFullYear() +
          "-" +
          (specs[x].expiryDate.getMonth() > 8
            ? specs[x].expiryDate.getMonth() + 1
            : "0" + (specs[x].expiryDate.getMonth() + 1)) +
          "-" +
          (specs[x].expiryDate.getDate() > 9
            ? specs[x].expiryDate.getDate()
            : "0" + specs[x].expiryDate.getDate()) : "",

        returnDays: specs[x].returnDays,
        isReturnable: specs[x].isReturnable,
        modelNo: {
          productId: productId,
          productCode: productCode,
        },

        freeDelivery: specs[x].isReturnable,
        netQuantity: parseInt(totalQuantity),
        countryOfOrigin: specs[x].country,
        geoLocation: {
          type: "Point",
          coordinates: [35, 35],
        },
      });
    }

    setShowLoader(true);
    axiosauth
      .post("/products/post-product", product)
      .then((response) => {
        let res = JSON.parse(response.data);

        if (res.message === "Product posted successfully") {
          setPProductId(res.data.productId);
          setShowLoader(false);
          setOpenSuccess(true);
        } else {
          setcatalogueerror(res.message);
        }
      })
      .catch((error) => {
        setNetworkError(true);

      })
      .then(() => {
        console.log("-----always executes");
      });
  };
  useEffect(() => {
    var cnty = [];
    for (var i = 0; i < country.length; i++) {
      cnty.push({
        value: country[i].name,
        label: country[i].name,
      });
    }
    setCountryList(cnty);
    var slug;

  }, []);
  // const[value,setValue]=useState(null)
  /**
   * TODO: function use for handling name value change of specification
   *@version 1.0.0
   * @author [ Unknown]
   */
  // eslint-disable-next-line
  const [mrpValue, setMrpValue] = useState();

  const handleChange2 = (e, name, index) => {
    seterror(false);
    setAllError("");
    // console.log("here",name)
    if (name === "isReturnable") {
      const value = !e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0;
    }
    if (name === "isExchangeable") {
      const value = !e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0;
    }
    if (name === "EssentialItem") {
      const value = !e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0;
    }
    if (name === "isCOD") {
      const value = !e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0;
    }
    if (name === "showExpiry") {
      const value = !e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0;
    }
    if (name === "priceSecondaryDisplay") {
      const value = !e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
      return 0;
    }

    if (name === "value") {
      const value = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
    } else if (name === "age") {
      const value = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);

    } else if (name === "country") {
      const value = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
    } else if (name === "type") {
      const value = e;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
    }
    else if (name === "date") {
      let datetoPass =
        e.getFullYear() +
        "-" +
        (e.getMonth() > 8 ? e.getMonth() + 1 : "0" + (e.getMonth() + 1)) +
        "-" +
        (e.getDate() > 9 ? e.getDate() : "0" + e.getDate());

      const value = e;
      const list = [...specs];
      list[index][name] = value;
      list[index]["dateToPass"] = datetoPass;
      setspecs(list);
    } else if (name === "expiryDate") {
      let datetoPass =
        e.getFullYear() +
        "-" +
        (e.getMonth() > 8 ? e.getMonth() + 1 : "0" + (e.getMonth() + 1)) +
        "-" +
        (e.getDate() > 9 ? e.getDate() : "0" + e.getDate());

      const value = e;
      const list = [...specs];
      list[index][name] = value;
      list[index]["dateToPass"] = datetoPass;
      setspecs(list);
    }
    // else if (name === "sticky") { 
    //   let arr = [];
    //   for (var i = 0; i < e.length; i++) {

    //     // console.log("list[index][name] = value;" ,  e[i].value)
    //     arr.push(e[i].value)

    //   }

    //   const value = arr;
    //   const list = [...specs]
    //   list[index][name] = value;
    //   setspecs(list)

    // }

    else {
      const { value } = e.target;
      const list = [...specs];
      list[index][name] = value;
      setspecs(list);
    }
  };

  async function loadOptions2(search, loadedOptions, { page }) {
    // console.log("sfjjjjjjjj------------>",value19)
    let newarray = [];
    let filtered;
    let has_more = true;
    let obj = {
      fields: "max",
      limit: 10,
      count: (page - 1) * 10,
      sortBy: {
        type: "createdOn",
        order: -1,
      },
      businessId: JSON.parse(localStorage.getItem("userData")).userId,
      filters: {
        name: search,
        baseProduct: value19?.value,
        unique: true,
      },
    };

    if (loadedOptions.length === 0 || loadedOptions.length < total2) {
      await axiosauth
        .post("/product-variant/load", obj)
        .then(async (response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            if (res.total > 0) settotal2(res.total);
            // var filter= product.imageUrls.filter((item) => item !== null);

            for (var i = 0; i < res.data.length; i++) {
              let lbl = "";
              if (res.data[i].variant[0]) {
                lbl +=
                  res.data[i].variant[0].parentVariant?.variantName +
                  " " +
                  res.data[i].variant[0].value +
                  " ";
              }
              if (res.data[i].variant[1]) {
                lbl +=
                  res.data[i].variant[1].parentVariant?.variantName +
                  " " +
                  res.data[i].variant[1].value +
                  " ";
              }
              if (res.data[i].variant[2]) {
                lbl +=
                  res.data[i].variant[2].parentVariant?.variantName +
                  " " +
                  res.data[i].variant[2].value +
                  " ";
              }
              newarray.push({
                label: lbl,
                value: res.data[i]._id,
              });
            }
            var sarray = [];
            for (var j = 0; j < specs.length; j++) {
              sarray.push(specs[j].value?.value);
            }
            filtered = newarray.filter(function (item) {
              return sarray.indexOf(item.value) === -1;
            });
          } else {
            has_more = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const responseJSON = {
      results: filtered,
      has_more: filtered.length > 0 ? has_more : false,
    };

    return {
      options: responseJSON.results,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  }
  async function loadOptions1(search, loadedOptions, { page }) {
    // console.log("sfjjjjjjjj------------>",value)
    let newarray = [];
    let has_more = true;
    let obj = {
      fields: "max",
      limit: 10,
      count: (page - 1) * 10,
      sortBy: {
        type: "createdOn",
        order: -1,
      },
      filters: {
        name: search,
        brand: value.value,
      },
    };

    if (loadedOptions.length === 0 || loadedOptions.length < total1) {
      await axiosauth
        .post("/catalogue/load", obj)
        .then(async (response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            settotal1(res.count);
            for (var i = 0; i < res.data.length; i++) {
              newarray.push({
                label: res.data[i].name,
                value: res.data[i]._id,
                gst: res.data[i]?.gst,
              });
            }
            //  setoptionarray(newarray)
          } else {
            has_more = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const responseJSON = {
      results: newarray,
      has_more: newarray.length > 0 ? has_more : false,
    };

    return {
      options: responseJSON.results,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  }
  const shouldLoadMore = () => {
    // const bottomBorder = (scrollHeight - clientHeight) / 2;

    return true;
  };
  async function loadOptions(search, loadedOptions, { page }) {
    setValue19(null);
    let newarray = [];
    let has_more = true;
    let obj = {
      fields: "max",
      limit: 10,
      count: (page - 1) * 10,
      sortBy: {
        type: "createdOn",
        order: -1,
      },
      filters: {
        name: search,
      },
    };

    if (loadedOptions.length === 0 || loadedOptions.length < total) {
      await axiosauth
        .post("/brand/load", obj)
        .then(async (response) => {
          let res = JSON.parse(response.data);
          // console.log("brand res", res);
          if (res.success === true) {
            // console.log(res?.data?.count, res?.data?.data);
            settotal(res?.data?.count);
            for (var i = 0; i < res?.data?.data.length; i++) {
              newarray.push({
                label: res?.data?.data[i].name,
                value: res?.data?.data[i]._id,
              });
            }
          } else {
            has_more = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const responseJSON = {
      results: newarray,
      has_more: newarray.length > 0 ? has_more : false,
    };

    return {
      options: responseJSON.results,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  }

  // useEffect(() => {
  //   let obj = {
  //     limit: 500,
  //     filters: {
  //       type: "sub1",
  //     },
  //   };
  //   axiosauth.post("/explore/load-Explore", { limit: 1000 })
  //     .then(async (response) => {
  //       let res = JSON.parse(response.data);
  //       if (response.status === 200) {
  //         // setExplore(res.load);
  //         // console.log("response---->>", res.load)
  //         let arr = [];
  //         for (var i = 0; i < res.load.length > 0; i++) {
  //           arr.push({
  //             label: res.load[i].name,
  //             value: res.load[i]._id,
  //           });
  //         }

  //         setStickyList(arr) 
  //         setShowLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // }, []);

  // const handleSticky = (selectedOption) => {
  //   setSelectSticky({ selectedOption });
  // };

  // console.log("select sticky-------------", selectSticky)


  const closeModal = () => {
    // history.push(`/add-variant-image/${pproductId}`);
    history.push(`/products?page=1`);
    // setRedirect(true);
  };


  // console.log("spec===>>", specs)

  const connectionError = () => {
    setNetworkError(false);
    setShowLoader(false);
  };

  const onlyNumberAllow = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const SKUInputHandle = (e) => {
    const re = /[a-zA-Z0-9 ]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };



  return (
    <Layout>
      {showLoader === true ? <LoaderModal /> : null}
      {/* {redirect === true ? (
        <Redirect to={`/add-variant-image/${pproductId}`} />
      ) : null} */}
      {openSuccess ? (
        <SuccessModal message="New Product Added!" onClick={closeModal} />
      ) : null}
      {networkError === true ? (
        <SuccessModal
          message="Some Error Occured.."
          onClick={connectionError}
        />
      ) : null}

      {/* <div className="form-header">
                    <h4>Add Product</h4>
                </div> */}

      <div
        className="left_arrow2 back-arrow pt-3 create-product-heading"
        style={{
          display: "flex",
          alignItems: "baseline",
          fontFamily: "inherit",
          position: "fixed",
          backgroundColor: "white",
          zIndex: "999",
          width: "100%",
        }}
      >
        <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
        <h2 className="mx-2"> Add Product </h2>
      </div>

      <form onSubmit={submit} className="form-wrap">

        <div id="select-tag " style={{ marginTop: "70px" }}>
          <p>
            Select Brand<b>*</b>
          </p>
          <AsyncPaginate
            value={value}
            placeholder={"Select/Search Brand"}
            loadOptions={loadOptions}
            required={true}
            onChange={(e) => {
              setValue(e);
              setValue19(null);
              setspecs([
                {
                  units: "",
                  value: "",
                  sku: "",
                  shippingCharge: "",
                  mrp: "",
                  price: "",
                  age: "0-1",
                  type: "Upto 1 Years",
                  modelNumber: "",
                  hsnCode: "",
                  country: "China",
                  mobile: "",
                  address: "",
                  email: "",
                  isReturnable: false,
                  isCOD: false,
                  showExpiry: false,
                  priceSecondaryDisplay: false,
                  isExchangeable: false,
                  EssentialItem: false,
                  // sticky: [],
                  returnDays: "",
                  // date: new Date(),
                  date: "",
                  expiryDate: "",
                  dateToPass:
                    new Date().getFullYear() +
                    "-" +
                    (new Date().getMonth() > 8
                      ? new Date().getMonth() + 1
                      : "0" + (new Date().getMonth() + 1)) +
                    "-" +
                    (new Date().getDate() > 9
                      ? new Date().getDate()
                      : "0" + new Date().getDate()),
                },
              ]);
              seterror(false);
              setBrandError("");
            }}
            additional={{
              page: 1,
            }}
          />

          <i className="error">{brandError}</i>
        </div>

        <div id="select-tag">
          <p>
            Select Product Catalogue<b>*</b>
          </p>
          <AsyncPaginate
            className={error === true && value19 === null ? "dddddddddddd" : ""}
            cacheUniqs={[value]}
            value={value19}
            placeholder={"Select/Search Product Catalogue"}
            loadOptions={loadOptions1}
            onChange={(e) => {
              // console.log("jdjdj", e);
              setValue19(e);
              // setgst(e?.gst?._id);
              // setgst(5)
              setspecs([
                {
                  units: "",
                  value: "",
                  sku: "",
                  shippingCharge: "",
                  mrp: "",
                  price: "",
                  age: "0-1",
                  type: "Upto 1 Years",
                  modelNumber: "",
                  hsnCode: "",
                  country: "China",
                  mobile: "",
                  address: "",
                  email: "",
                  isReturnable: false,
                  isCOD: false,
                  showExpiry: false,
                  priceSecondaryDisplay: false,
                  isExchangeable: false,
                  EssentialItem: false,
                  // sticky: [],
                  returnDays: "",
                  // date: new Date(),
                  date: "",
                  expiryDate: "",
                  dateToPass:
                    new Date().getFullYear() +
                    "-" +
                    (new Date().getMonth() > 8
                      ? new Date().getMonth() + 1
                      : "0" + (new Date().getMonth() + 1)) +
                    "-" +
                    (new Date().getDate() > 9
                      ? new Date().getDate()
                      : "0" + new Date().getDate()),
                },
              ]);
              seterror(false);
              setCatError("");
            }}
            shouldLoadMore={shouldLoadMore}
            additional={{
              page: 1,
            }}
          />
          <i className="error">{catError}</i>
        </div>

        <div className="form-wrap">
          <div className="form-body">
            {specs && specs.length > 0
              ? specs.map((value, index) => {
                return (
                  <div className="selected-variant">
                    <p>Variant-{index + 1}</p>
                    {index !== 0 ? (
                      <span
                        style={{
                          color: "red",
                          float: "right",
                          cursor: "pointer",
                        }}
                        onClick={() => removespecs(index)}
                      >
                        X
                      </span>
                    ) : null}

                    <p className="label-form">
                      Select Variant<b>*</b>
                    </p>
                    <AsyncPaginate
                      className={
                        error === true && specs[index].value === ""
                          ? "dddddddddddd"
                          : ""
                      }
                      cacheUniqs={[value, value19, specs]}
                      value={specs[index].value}
                      placeholder={"Select/Search Variant"}
                      loadOptions={loadOptions2}
                      required
                      onChange={(e) => {
                        handleChange2(e, "value", index);
                      }}
                      shouldLoadMore={shouldLoadMore}
                      additional={{
                        page: 1,
                      }}
                    />
                    <i className="error">{variantError}</i>
                    <section>
                      <div className="two-field ">
                        <p>
                          Product Id
                          {/* <b>*</b> */}
                        </p>
                        <div className="d-flex">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => {
                              setProductId(e.target.value);
                            }}
                          >
                            <option selected>Open this select menu</option>
                            <option value="EAN-13">EAN-13</option>
                            <option value="UPC-12">UPC-12</option>
                            <option value="ISBN-13">ISBN-13 </option>

                            <option value="FSSAI-LIC">FSSAI LIC NO.</option>
                            <option value="MPN">MPN </option>
                          </select>

                          <input
                            type="text"
                            value={productCode.toUpperCase()}
                            onChange={(e) => setProductCode(e.target.value)}
                            placeholder="Enter Product Id"
                            onKeyPress={(e) => SKUInputHandle(e)}
                            // maxLength={productId === "UPC-12" ? 12 : 13}
                            maxLength={productId === "UPC-12" ? 12 : productId === "ISBN-13" || productId === "EAN-13" ? 13 : 20}
                          />
                        </div>
                      </div>

                      <div className="two-field">
                        <p>
                          SKU<b>*</b>
                        </p>
                        <input
                          type="text"
                          value={specs[index].sku.toUpperCase()}
                          onChange={(e) => handleChange2(e, "sku", index)}
                          placeholder="Enter SKU"
                          onKeyPress={(e) => SKUInputHandle(e)}
                          minLength={8}
                          maxLength={30}
                          required
                        />
                      </div>
                    </section>

                    <section>
                      <div className="two-field">
                        <p>
                          Units<b>*</b>
                        </p>
                        <input
                          type="number"
                          // value={specs[index].units}
                          onChange={(e) => setTotalQuantity(e.target.value)}
                          // onChange={(e) => handleChange2(e, "units", index)}
                          placeholder="Enter Units"
                          onKeyPress={(e) => onlyNumberAllow(e)}
                          min="0"

                          required
                        />
                      </div>

                      <div className="two-field">
                        <p>
                          MRP<b>*</b>
                        </p>
                        <input
                          type="number"
                          value={specs[index].mrp}
                          onChange={(e) => {
                            setMrpValue(e.target.value);
                            handleChange2(e, "mrp", index);
                          }}
                          onKeyPress={(e) => onlyNumberAllow(e)}
                          min="0"
                          placeholder="Enter MRP"
                          required
                        />
                      </div>
                      <div></div>
                    </section>
                    <section></section>
                    <section>
                      <div className="two-field">
                        <p>
                          OFFER PRICE<b>*</b>
                        </p>
                        <input
                          type="number"
                          value={specs[index].price}
                          onChange={(e) => handleChange2(e, "price", index)}
                          placeholder="Enter Price"
                          onKeyPress={(e) => onlyNumberAllow(e)}
                          min="0"

                        />
                        {parseInt(specs[index].mrp) <
                          parseInt(specs[index].price) && (
                            <p style={{ color: "red", fontSize: "10px" }}>
                              Price Should be less then MRP
                            </p>
                          )}
                      </div>
                      <div className="two-field">
                        <p>
                          Country Of Origin<b>*</b>
                        </p>
                        <Select
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={countryList}
                          placeholder="Select or Search"
                          // value={{
                          //   value: specs[index].country,
                          //   label: specs[index].country,
                          // }}
                          name="gst"
                          // required
                          onChange={(e) => handleSelectCountry(e, index)}
                        />
                      </div>


                      {/* <div style={{ marginTop: "20px", display: "flex", marginLeft: "45px" }}>
                        <label
                          className="ml-1 label-form "
                          for="vehicle1"
                          style={{ marginTop: "-5px" }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              marginTop: "10px",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "12px",
                              color: "#4F4F4F",

                            }}
                          >
                            Price Secondary Display<b></b>
                          </p>
                        </label>
                        <div>
                          <input
                            className="mx-4 mt-1"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            checked={specs[index].priceSecondaryDisplay}
                            onChange={(e) =>
                              handleChange2(specs[index].priceSecondaryDisplay, "priceSecondaryDisplay", index)
                            }
                          />
                        </div>

                      </div> */}

                    </section>

                    <section>

                      {/* <div style={{ marginTop: "20px", display: "flex" }}>
                        <label
                          className="ml-1 label-form "
                          for="vehicle1"
                          style={{ marginTop: "-5px" }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              marginTop: "10px",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "12px",
                              color: "#4F4F4F",
                            }}
                          >
                            {" "}
                            COD<b></b>
                          </p>
                        </label>
                        <input
                          className="mx-4 mt-1"
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          checked={specs[index].isCOD}
                          onChange={(e) =>
                            handleChange2(specs[index].isCOD, "isCOD", index)
                          }
                        />

                        <br />

                        <span className="mx-4 " style={{ marginTop: "-5px" }}>
                          <label
                            className="mx-1"
                            style={{
                              fontSize: "18px",
                              marginTop: "10px",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "12px",
                              color: "#4F4F4F",
                            }}
                          >
                            Essential item
                          </label>
                          <input
                            className="mx-4 mt-1"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            checked={specs[index].EssentialItem}
                            onChange={(e) =>
                              handleChange2(
                                specs[index].EssentialItem,
                                "EssentialItem",
                                index
                              )
                            }
                          />

                        </span>

                      </div> */}



                      
                    </section>

                    <section>
                      <div className="two-field">
                        <div className="datePic5">
                          <p className="label-form mt-2">
                            MANUFACTURE DATE<b>*</b>
                          </p>
                          <DatePicker
                            selected={specs[index].date}
                            onChange={(dat) => {
                              var date = new Date(dat);
                              handleChange2(date, "date", index);
                            }}
                            customInput={
                              <DatePickerInput value={specs[index].date} />
                            }
                            maxDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                          <i className="error">{dateError}</i>

                        </div>
                      </div>
                      <div className="two-field">

                        <div className="datePic5">
                          <div className="label-form  ">

                            <span className="d-flex align-items-center">
                              <span className="mt-3"> Is Expiry Date </span>
                              <input
                                className="col-1 mx-0 mt-3"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                checked={specs[index].showExpiry}
                                onChange={(e) =>
                                  handleChange2(specs[index].showExpiry, "showExpiry", index)
                                }
                              />
                            </span>
                          </div>
                          {specs[index].showExpiry === true &&
                            <DatePicker
                              selected={specs[index].expiryDate}
                              onChange={(dat) => {
                                var date = new Date(dat);
                                handleChange2(date, "expiryDate", index);
                              }}
                              customInput={
                                <DatePickerInput value={specs[index].expiryDate} />
                              }
                              // maxDate={new Date()}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          }
                          {/* <i className="error">{dateError}</i> */}

                        </div>
                      </div>

                    </section>
                    <section>

                      {/* <div
                        className="d-flex mt-5"
                      // style={{ marginTop: "20px", display: "flex" }}
                      >
                        <label
                          className="ml-1 label-form "
                          for="vehicle1"
                          style={{ marginTop: "-5px" }}
                        >
                          Is Returnable
                        </label>
                        <input
                          className="mx-3"
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          checked={specs[index].isReturnable}
                          onChange={(e) =>
                            handleChange2(
                              specs[index].isReturnable,
                              "isReturnable",
                              index
                            )
                          }
                        />

                        <br />

                        <span className="mx-4 " style={{ marginTop: "-5px" }}>
                          <label
                            className="mx-1"
                            style={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "24px",
                              color: "#4F4F4F",
                            }}
                          >
                            Is Exchangeable
                          </label>
                          <input
                            className="mx-3"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            checked={specs[index].isExchangeable}
                            onChange={(e) =>
                              handleChange2(
                                specs[index].isExchangeable,
                                "isExchangeable",
                                index
                              )
                            }
                          />

                        </span>

                      </div> */}
                      <div className="two-field">
                        <p>Available Stock <b>*</b></p>
                        <input
                          type="number"
                          // value={specs[index].price}
                          // onChange={(e) => setTotalQuantity(e.target.value)}
                          onChange={(e) => handleChange2(e, "units", index)}
                          placeholder="Enter Total Stock"
                          onKeyPress={(e) => onlyNumberAllow(e)}
                          min="0"

                          required
                        // max={10}
                        />
                      </div>

                    </section>

                    <section> 
                    </section>

                    {/* {specs[index].isReturnable || specs[index].isExchangeable ? (
                      <section>
                        <div className="two-field">
                          <p>
                            Return Days<b>*</b>
                          </p>

                          <input
                            type="number"
                            value={specs[index].returnDays}
                            onChange={(e) =>
                              handleChange2(e, "returnDays", index)
                            }
                            placeholder="Enter Return Days"
                            required
                          />
                        </div>
                      </section>
                    ) : (
                      ""
                    )} */}


                  </div>
                );
              })
              : null}
          </div>

          <i className="error">
            {catalogueerror}
            {allError}
          </i>
          <div className="button">
            {/* {specs.length < total2 ? (
              <button onClick={addspecs}>Add</button>
            ) : (
              <button
                onClick={() => alert("You have added all available variants")}
                className="btn-style px-5 mr-4"
              >
                ADD
              </button>
            )} */}
            &nbsp;
            {/* <button onClick={submit}>Save</button> */}

            <button type='submit'>Save</button>

          </div>
        </div>
      </form>
      <div>
        {/* <div className="button">
{specs.length < total2 ? (
            <button onClick={addspecs}>Add</button>
          ) : (
            <button
              onClick={() => alert("You have added all available variants")}
              className="btn-style px-5 mr-4"
            >
              ADD
            </button>
          )}
          </div> */}
      </div>
    </Layout>
  );
};

export default CreateProduct;
