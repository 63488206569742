import React from "react";

const ConfirmationModal = ({ title, content, HandleClose, performAction }) => {
  return (
    <div
      class="modal fade show d-block confirm-modal"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content p-1">
          <div class="modal-header pb-2 m-auto order-model-heading border-0">
            <h5 class="modal-title fs-1" id="exampleModalLabel">
              {title}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={HandleClose}
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
          <div class="modal-body border-0 pt-0 text-center model-text">
            {content}
          </div>
          <div class="modal-footer d-block text-center border-0">
            <div>
            <button
              type="button"
              class="py-2 px-4 m-yes-btn m-btns"
              onClick={performAction}
            >
              Yes
            </button>
            <button
              type="button"
              class="py-2 px-4 m-no-btn m-btns"
              data-dismiss="modal"
              onClick={HandleClose}
            >
              No
            </button>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
