import React, { useState, useEffect } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axiosauth from '../../axios-config-auth';
import deleteIcon from '../../assets/icons/delete.png';
import NoData from '../../components/halper/NoData';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import editIcon from '../../assets/icons/edit.png';
// import LoaderModal from '../../components/halper/LoaderModal';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';


const Faq = () => {
  const history = useHistory()
    const [policy , setPolicy] = useState([]);
    const [showLoader, setShowLoader] = useState(false); 
 
    useEffect(() => {

        const obj = {
            filters: {
                headName: "POLICY"
            }
        }
setShowLoader(true)
        axiosauth.post('/footer/list_footer', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data) 
                if (res.success === true) {
                    setPolicy(res.data)
                    setShowLoader(false)
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
                setShowLoader(false)
            })
    }, [])



    return (
        <>
         <div className="button">
            <button onClick={()=>history.push('/add-policy')}>Add Page</button>
          </div>
         {/* {showLoader === true ? <LoaderModal /> : null} */}

         {!showLoader ?
        <div className="table-wrap">
        <table>
        {policy && policy.length > 0 ?(<thead>
            <tr>
            <th>S.No</th>
              <th>Page Name</th>
              <th>Link</th> 
              <th>status</th>
              <th></th>
            </tr>
          </thead>):null}  
          <tbody>

                {
                  policy && policy.length > 0 ?
                    <>
                      {
                        policy.map((user, index) => {

                          const deleteUser = (id) => {
                            let obj = {
                              id:id
                            } 
                            confirmAlert({
                              // title: 'Change Status',
                              message: 'Are you sure you want to delete this page? ',
                              buttons: [
                                {
                                  label: 'Yes',
                                  onClick: () => {
                                    axiosauth.post('/footer/delete-footer' ,obj)
                                    .then(async (response) => {
                                        let res = JSON.parse(response.data)
                                        if (res.success === true) {
                                          const obj1 = {
                                            filters: {
                                                headName: "POLICY"
                                            }
                                        }
                                          console.log("class" ,res)
                                          axiosauth.post('/footer/list_footer', obj1)
                                          .then(async (response) => {
                                              let res = JSON.parse(response.data)
                                              console.log("res:", res)
                                              if (res.success === true) {
                                                  setPolicy(res.data)
                                                  setShowLoader(false)
                                              } else {
                                              }
                                          })
                                          .catch((error) => {
                                              console.log(error);
                                              setShowLoader(false)
                                          })
                                        } else {
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                                  }
                                },
                                {
                                  label: 'No',
                                  onClick: () => {
                                    console.log("not detele")
                                  }
                                }
                              ]
                            });
                          }

                          return (
                            <tr key={index + 1}>
                            <td>{ (index+1)}</td>
                              <td className="titleStyle">{user?.name}</td>
                              <td>{(user?.url).length>0 ?user?.url : <p className="mt-3">link not Found</p>}</td>
                              <td>{user?.suspend === false ? <p className="mt-3">Active</p> :  <p className="mt-3">Inactive</p> }</td>
                       
                              <td>
                              <Link to={`/update-cms/${user._id}`} >
                                                <img   src={editIcon} alt="edit-button" className="edit-icon" />
                               </Link>
                                <img onClick={()=>deleteUser(user._id)} src={deleteIcon} alt="" className="delete-icon" />
                              </td>
                            </tr>
                          )
                        }
                        )}
                    </>
                    : (<NoData message="Page Not Found" />)
                }
              
          </tbody>
        </table>
      
      
        {/* <div className="pagination-custom">
          <Pagination
            hideDisabled
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={userData.data.count}
            pageRangeDisplayed={5}
            hideNavigation
            onChange={handlePageChange5}
          />
        </div> */}
      </div>
  :
  <div className="loader-style">
<Loader
 type="ThreeDots"
 color="rgba(32, 159, 132, 1)"
 height={100}
 width={100}
 timeout={500000}
/>
</div>
}
 


            {/* <div className="cms-wrap">
                <div className="menu-bar category-menu mx-2">
                    <section>
                        <div className={termsCondition ? "active-menu" : ""} onClick={handleTermsCondition}>terms & Condition</div>
                        <div className={termsUse ? "active-menu" : ""} onClick={handleTermsUse}>Terms of Use</div>
                        <div className={privacyPoly ? "active-menu" : ""} onClick={handlePrivacyPoly}>Privacy Policy</div>
                        <div className={returnPoly ? "active-menu" : ""} onClick={handleReturnPoly}>Return Policy</div>
                        <div className={security ? "active-menu" : ""} onClick={handleSecurity}>Security</div>
                        <div className={siteMap ? "active-menu" : ""} onClick={handleSiteMap}>Sitemaps</div>

                    </section>
                </div>

                {termsCondition && (
                    <TermsCondition />
                )}

                {termsUse && (
                    <TermsOfUse />
                )}

                {privacyPoly && (
                    <PrivacyPolicy />
                )}

                {returnPoly && (
                    <ReturnPolicy />
                )}
                {security && (
                    <Security />
                )}
                {siteMap && (
                    <SiteMap />
                )}

            </div> */}

        </>
    )
}

export default Faq
