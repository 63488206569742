import React from 'react';
import { useState, useEffect } from "react";
import Layout from "../../layout/layout"
import axiosauth from '../../axios-config-auth';
import { useHistory} from "react-router-dom";
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
const ReviewDetails = () => {
  const [showLoader, setShowLoader] = useState(true);
const[calluseeffect,setcalluseeffect]=useState(null)

    const history = useHistory();
    // eslint-disable-next-line
  const[base,setbase]=useState(null)
  // eslint-disable-next-line
  const showDate = (data) => {
    var d = new Date(data);
    var date = d.getDate();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
    var year = d.getFullYear();
    var dateStr = date + " " + months[month] + " " + year;
    // var res =
    return dateStr;
  };
  
  
    useEffect(() => {
      let obj = {
        "slug":localStorage.getItem("proslug"),
        "reviewId":localStorage.getItem("reviewId")

      }
      axiosauth.post('/products/load-review-by-id', obj)
        .then(async (response) => {
          let res = JSON.parse(response.data)
          console.log("rj",res)
          if (res.success === true) {
            setbase(res.data)
            console.log("---->",res.data)
            setShowLoader(false)
           
          } else {
            setShowLoader(false)
          }
        })
        .catch((error) => {
          console.log(error);
        })
       
    }, [calluseeffect]);

    const statusChange = (e) => {
      function stringToBoolean(val){
        var a = {
            'true':true,
            'false':false
        };
        return a[val];
    }
      let obj = {
        "slug":localStorage.getItem("proslug"),
        "reviewId":localStorage.getItem("reviewId"),
        "active":stringToBoolean(e.target.value)

      };
      console.log("obj:", obj)
      confirmAlert({
        // title: 'Change Status',
        message: 'Are you sure you want to Change this Status?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
            
              axiosauth.post('/products/update-product-review', obj)
                .then(async (response) => {
                  let res = JSON.parse(response.data)
                  console.log("rj",res)
                  if (res.success === true) {
                  
                   setcalluseeffect(Math.random(1000))
                  } else {
                  
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
            }
          },
          {
            label: 'No',
            onClick: () => {
              console.log("not ban")
            }
          }
        ]
      });
    }
    return (
        <Layout>
          
          
          <div>
      <section className="detail-heading">
      <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                {/* <div> */}
                <img src={left_arrow2}  onClick={() => history.goBack()} alt="left-arrow" />&nbsp;
                <h2>Manage Review & Ratings </h2> - Product : {localStorage.getItem("pname")}
                
               <div className="button">
                    {/* <Link to="/add-products-variant">
                        <button onClick={()=>localStorage.setItem("bname",varList[0].brand.name)}>Add Variant</button>
                    </Link> */}

                </div>
                </div>
      </section>
      {!showLoader ?
      <>
         
          <section className="detail-body" >
            <div>Date</div>
            <div className="titleStyle">
        {base!==null?(showDate(base.reviewedOn)):""}
            </div>
          </section>
          <section className="detail-body" >
            <div>Name of User</div>
            <div>
         {base!==null?(base.user?.firstName+" "+base.user?.lastName):null}
            </div>
          </section>
          <section className="detail-body" >
            <div>Email</div>
            <div>
            {base!==null?(base.user?.userEmail):null}
            </div>
          </section>

          <section className="detail-body" >
            <div>Mobile Number</div>
            <div>
            {base!==null?(base.user?.mobile):null}

            </div>
          </section>

          <section className="detail-body" >
            <div>Ratings</div>
            <div>
           {base.rating}
            </div>
          </section>

          <section className="detail-body" >
            <div>Review</div>
            <div>
          {base.review}
            </div>
          </section>

          <section className="detail-body" >
            <div>Action</div>
            <div>
            <select
                                  name="select-ban-unban"
                                  id="ban-unban"
                                  value={base.active}
                                  onChange={(e)=>statusChange(e)}
                                >
                                  <option value={true}>Approve</option>
                                  <option value={false}>Reject</option>
                                </select>
            </div>
          </section>
        
          

          {/* <div className="button">
            
            <button  onClick={() => history.goBack()}>Back</button> &nbsp;
        
            <Link to={`/edit-products-variant`}>
                <button>Edit</button>
            </Link>
        </div> */}
        <br/>
</>:
      <div className="loader-style">
          <Loader
              type="ThreeDots"
              color="rgba(32, 159, 132, 1)"
              height={100}
              width={100}
              timeout={3000} //3 secs
          />
      </div>

}
          </div>
<br/>
<br/>
           
        </Layout>
    )
}

export default ReviewDetails
