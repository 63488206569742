import React from 'react';
import Layout from "../../layout/layout";
import TopBanner from '../../wrapper/banner/TopBanner';

const ManageUpperBanner = () => {
    return (
        <Layout>
           <TopBanner/>
        </Layout>
    )
}

export default ManageUpperBanner
