import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import uploadIcon from '../../assets/icons/upload.png';
import editImageIcon from '../../assets/icons/edit.png';
import { useDispatch, useSelector } from "react-redux"
import { updateSubCategory, loadSubCategoryById } from '../../redux/actions/subCategoryAction';
import { loadCategory} from '../../redux/actions/categoryAction';
import { Redirect, useParams,useHistory } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import Layout from '../../layout/layout';
import axiosauth from '../../axios-config-auth';
import { Editor } from '@tinymce/tinymce-react';


const EditSubSubCategoryForm = () => {
  const history = useHistory()
  const [file, setfile] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [urlFile, setUrlFile] = useState("")
  const animatedComponents = makeAnimated()
  const { categoryId } = useParams();
  const [seoText, setSEOText] = useState('');
  const editorRef = useRef(null);

// Error State
const [catError, setCatError]=useState("");
const [subError, setSubError]=useState("");
const [subCat , setSubCat] = useState([])

const [checkName, setCheckName]=useState("");
 
  const subCategoryData = useSelector(state => state.subCategoryData); 

  var cat_list = [];
  if (typeof subCat === "object") {
    for (var p = 0; p < Object.keys(subCat).length; p++) {
     
      cat_list.push({ value: subCat[p]._id, label: subCat[p].name })
    }
  }

 

  const [selectCategory, setSelectCategory] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [subCategory, setSubCategory] = useState({
    subCategoryName: "",
    seoDescription:"",
        seoTitle :"",
  });


  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSubCategory({
      ...subCategory,
      error: false,
      [e.target.name]: e.target.value
    })
    setSubError("")
  } 

  const handleSelect = (selectedOption) => {
    setSelectCategory(selectedOption); 
    setCatError("")
  }

  const categorySubmit = (e) => {
    e.preventDefault()

    if(subCategory.subCategoryName===""){
      setSubError("Sub-Category Name Required");
      return 0;
  }
 
  if(selectCategory===0){
    setCatError("Category Required");
      setSubError("")
      return 0;
  }

 
    if(subCategory.subCategoryName===checkName){

      let obj = {
        type: "sub2",
        parentCategory: selectCategory.value,
        categoryId: categoryId,
        seoDescription:subCategory.seoDescription,
        seoTitle :subCategory.seoTitle,
        seoText:seoText.newText,
  
      }; 
      dispatch(updateSubCategory(obj, urlFile))
    }else{
      let obj = {
        name: subCategory.subCategoryName,
        type: "sub2",
        parentCategory: selectCategory.value,
        categoryId: categoryId,
        seoDescription:subCategory.seoDescription,
        seoTitle :subCategory.seoTitle,
        seoText:seoText.newText,
      }; 
      dispatch(updateSubCategory(obj, urlFile))
    }

  }


  useEffect(() => {
    if (typeof subCategoryData === "object") { 
      setSubCategory({
        subCategoryName: subCategoryData.data.name,
        seoDescription : subCategoryData.data.seoDescription,
        seoTitle :subCategoryData.data.seoTitle,
      })
      setCheckName(subCategoryData.data.name)
      if(subCategoryData?.data?.parentCategory?.length && subCategoryData?.data?.parentCategory[0]?._id && subCategoryData?.data?.parentCategory[0]?.name){
        setSelectCategory({value:subCategoryData.data.parentCategory[0]._id, label:subCategoryData.data.parentCategory[0].name})
    
      }
      setSEOText(subCategoryData.data.seoText)
     
     if(subCategoryData.data.image !==undefined && subCategoryData.data.image.length>0){
      
      setfile(`${subCategoryData.data.image}?date=${new Date()}`); 
      setIsFile(true);
     }
    }

  }, [subCategoryData]);


  useEffect(() => {
    dispatch(loadCategory());
    dispatch(loadSubCategoryById(categoryId));
  }, [dispatch, categoryId]);

  const closeModal = () => {
    setRedirect(true);
  }

  useState(()=>{
    let obj = { 
      "limit":10000,
      "filters": {
        "type": "sub1", 
      }

    } 
    axiosauth.post('/categories/load', obj)
    .then( (response) => { 
      let res= JSON.parse(response.data) 
      setSubCat(res.data.categories)
    }).catch(e=>console.log(e))
  },[])

  const onlyLetterAllowWithSpace =(e)=> {
    const re = /[a-zA-Z ]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  
  return (
    <Layout>
      {redirect ? <Redirect to="/manage-sub-sub-category?page=1" /> : null}
      {subCategoryData.data.success === true ? <SuccessModal message="Sub-Sub-Category Updated!" onClick={closeModal} /> : null}

      <form className="form-wrap" onSubmit={categorySubmit}>
        {/* <div className="form-header">
          <h4>Edit Sub Catagory</h4>
        </div> */}



        <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img src={left_arrow2}  onClick={() => history.goBack()}alt="left-arrow" /> &nbsp;
                <h2>Edit Sub Sub Catagory</h2>
        </div>


        <div className="form-body">
          <div className="single-field">
            <p>Sub-Category Name <b>*</b></p>
            <input
              type="text"
              placeholder="Sub Category Name"
              name="subCategoryName"
              onKeyPress={(e) => onlyLetterAllowWithSpace (e)}
              value={subCategory.subCategoryName}
              required
              onChange={handleChange} />
               <i className="error">{subError}</i>
          </div>
          <div id="select-tag">
            <p>Sub Category Name</p>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={cat_list}
              // value={[{label:pcat,value:pcat}]}
              value={selectCategory}
              onChange={handleSelect} />
              <i className="error">{catError}</i>
          </div>

          <div className="single-field">
            <p>SEO Title <b></b></p>
            <input title="Please enter on alphabets only. "
              //  pattern="^[A-Za-z ,]+$" oninvalid="setCustomValidity('Please enter  alphabets only. ')" 
              type="text" placeholder="SEO Title" value={subCategory.seoTitle} name="seoTitle" onChange={handleChange} />
          </div>
          <div className="single-field">
            <p>SEO Description <b></b></p>
            <input title="Please enter on alphabets only. "
              //  pattern="^[A-Za-z ,]+$" oninvalid="setCustomValidity('Please enter alphabets only. ')" 
              type="text" placeholder="SEO Description" value={subCategory.seoDescription} name="seoDescription" onChange={handleChange} />
          </div>
          <div className='single-field mb-3'>
            <p className="label-form">SEO Text </p>
            <Editor
              onInit={(evt, editor) => { editorRef.current = editor }}
              initialValue={seoText}
              onEditorChange={(newText) => {
                //   console.log(newText)
                setSEOText({...seoText , newText});
              }}
              init={{
                height: 450,
                menubar: true,
                plugins: [
                  'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                  'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                  'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                  'alignleft aligncenter alignright alignjustify | ' +
                  'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />
          </div>

          <div id="img-upload">
            <p>{`Image Dimension Must be (500 X 500) , image (jpg, png , WebP) and image size must be less than < 200KB` }<b>*</b></p>

            {!isFile ?
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" /><br />
                    Upload image
                  </button>
                <input
                  type="file"
                  name="myfile"
                  onChange={(e) => {
                    // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setIsFile(true);
                    setUrlFile(e.currentTarget.files[0]);
                    
                    let img = new Image()
                    img.src = URL.createObjectURL(e.currentTarget.files[0])
                    img.onload = () => {
                      if (img.width === 500 && img.height === 500) {
                            setfile(img.src);
                            // alert("correct image")
                        } else {
                            alert('Incorrect image dimension. Please upload an image 500X500 pixels.')
                        }
                    }
                  }}
                />
              </div> :
              <aside class="image">
                    <div class="overlay1">
                    <label for="file-upload">
                        <img for="upload-image" class="edit-img" src={editImageIcon} alt=""/>
                    </label>
                    </div>
                    <img class="screen" src={file} alt=""/>
                    
                      <input
                        type="file"
                        id="file-upload"
                        style={{display:'none'}}
                        name="myfile"
                        accept="image/*"
                        onChange={(e) => {
                        // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                        if (e.currentTarget.files[0].size >= 204800) {
                          alert("Images size must be less than 200KB")
                          return 0
                      }
                        setIsFile(true);
                        setUrlFile(e.currentTarget.files[0]);
                        
                    let img = new Image()
                    img.src = URL.createObjectURL(e.currentTarget.files[0])
                    img.onload = () => {
                      if (img.width === 500 && img.height === 500) {
                            setfile(img.src);
                            // alert("correct image")
                        } else {
                            alert('Incorrect image dimension. Please upload an image 500X500 pixels.')
                        }
                    }
                      }}
                    />
                </aside>
            }
           
          </div>
        
          <div className="button">
            <button>Update</button>
          </div>
         
        </div>
      </form>
      {/* {formValues === true ? <Redirect to="/category-manage" /> : null} */}
    </Layout>
  )
}

export default EditSubSubCategoryForm
