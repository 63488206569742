import React, { useState} from "react"; 
import Layout from "../../layout/layout"; 
import axiosauth from "../../axios-config-auth";
import { useHistory } from "react-router-dom";
 
import SuccessModal from "../../components/halper/SuccessModal";
import left_arrow2 from "../../assets/icons/down_arrow2.png"; 
import { Redirect } from "react-router-dom";

const AddShippingCharges = () => {
  const [show, setShow] = useState(false);
  const [collectionMsg, setCollectionMasg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [redirect , setRedirect] = useState(false)
  // const myRefname = useRef(null);
  const history = useHistory();
  const [shipping, setshipping] = useState({
    startvalue: "",
    cost: "",
    endvalue: "",
  });
  const [shippingstartvalueerror, setshippingstartvalueerror] = useState("");
  const [shippingcosterror, setshippingcosterror] = useState("");
  const [shippingendvalueerror, setshippingendvalueerror] = useState("");
 
  const handleChange = (name) => (event) => {
    setshippingstartvalueerror("");
    setshippingendvalueerror("");
    setshippingcosterror("");
    setErrorMsg("");
    setCollectionMasg("");
    setshipping({ ...shipping, error: false, [name]: event.target.value });
  };

  const onSubmit = () => {
    var error = "";
    var errora = "";
    var errorb = "";

    if (shipping.startvalue === "") {
      setshippingstartvalueerror("Please Enter Minimun Amount");
      error = "a";
    } else {
      error = "";
    }
    if (shipping.endvalue === "") {
      setshippingendvalueerror("Please Enter Maximum Amount");
      errora = "a";
    } else {
      errora = "";
    }
    if (parseInt(shipping.endvalue) <= parseInt(shipping.startvalue)) {
      setshippingendvalueerror("Maximum Amount Must be Greater Than Minimum Amount");
      errora = "a";
    } else {
      errora = "";
    }
    if (shipping.cost === "") {
      setshippingcosterror("Please Enter Shipping Charge");
      errorb = "a";
    } else {
      errorb = "";
    }
    if (!(shipping.cost >= 0 && shipping.cost <= 10000)) {
      setshippingcosterror("Shipping Charge Invalid");
      errorb = "a";
    } else {
      errorb = "";
    }
    if (error === "a" || errora === "a" || errorb === "a") {
      return 0;
    }
    setErrorMsg("");
    setCollectionMasg("");

    let obj = { 
        maximumAmount: shipping.endvalue ,
      minimumAmount:shipping.startvalue  ,
      amountValue :shipping.cost
      
    };

    axiosauth
      .post("shipping/create-shipping", obj)
      .then(async (response) => {
        // console.log("response", response);
        // console.log("response----------->>" , JSON.parse(response.data))
                let res = JSON.parse(response.data)
                if (res.message=== "success") {
                    setErrorMsg("");

                    setShow(true);
                    // setMinimumAmount("");
                    // setAmountValue("")
                    // handleClose();
                    // LoadShipping()
                } else {
                }
            }) 
      .catch((error) => {
        console.log(error);
      });
  };

  const modalClose = () => {
    setshipping({
      startvalue: "",
      cost: "",
      endvalue: "",
    });
    setShow(false);
    // handleClick(); 
    setRedirect(true)
  };

  

  return (
    <Layout>
      
      {show ? ( <SuccessModal message="New Shipping Charges Created!" onClick={modalClose} />  ) : null}
       {redirect ? <Redirect to="/shipping-charges" /> : null}
       
      <div
        className="left_arrow2 back-arrow my-3"
        style={{
          display: "flex",
          alignItems: "baseline",
          fontFamily: "inherit",
        }}
      >
        <img
          src={left_arrow2}
          onClick={() => history.goBack()}
          alt="left-arrow"
        />{" "}
        &nbsp;
        <h2>Add Shipping Charges</h2>
      </div>
      <div className="sub-category-wrap">
        <div className="form-group form-input">
          <label className="label-style ml-0" for="name">
            Minimum Amount<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="number"
            name="name"
            className={
              shippingstartvalueerror !== "" || errorMsg !== ""
                ? "error form-control form-control-nonround"
                : "form-control form-control-nonround"
            }
            id="name"
            placeholder="Type here"
            value={shipping.startvalue}
            onChange={handleChange("startvalue")}
          />
          <p style={{color:"red"}}>{shippingstartvalueerror}</p>
        </div>
        <div className="form-group form-input">
          <label className="label-style ml-0" for="name">
            Maximum Amount<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="number"
            name="name"
            className={
              shippingendvalueerror !== "" || errorMsg !== ""
                ? "error form-control form-control-nonround"
                : "form-control form-control-nonround"
            }
            id="name"
            placeholder="Type here"
            value={shipping.endvalue}
            onChange={handleChange("endvalue")}
          />
          <p style={{color:"red"}}>{shippingendvalueerror}</p>
        </div>
        <div className="form-group form-input">
          <label className="label-style ml-0" for="name">
            Shipping Charges<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="number"
            name="name"
            className={
              shippingcosterror !== "" || errorMsg !== ""
                ? "error form-control form-control-nonround"
                : "form-control form-control-nonround"
            }
            id="name"
            placeholder="Type here"
            value={shipping.cost}
            onChange={handleChange("cost")}
          />
          <p style={{color:"red"}}>{shippingcosterror}</p>
        </div>

        <div className="button">
          <button onClick={onSubmit}>Save</button>
        </div>

        <span className="text-success font-weight-bold m-3">
          {collectionMsg}
        </span>
        <span className="text-danger font-weight-bold m-3">{errorMsg}</span>
      </div>

    </Layout>
  );
};

export default AddShippingCharges;
