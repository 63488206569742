import {
    FETCH_COLLECTION,
    USER_LOADING,
    USER_FAIL,
    ADD_COLLECTION,
    FETCH_COLLECTION_BY_ID,
    EDIT_COLLECTION
} from '../constants/types';

const initialState = {
    loading: false,
    data: [],
    error: ''
}


export function collectionData(state = initialState, action) {
    switch (action.type) {
        case FETCH_COLLECTION:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case FETCH_COLLECTION_BY_ID:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case ADD_COLLECTION:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case EDIT_COLLECTION:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case USER_LOADING:
            return {
                ...state,
                data: [],
                loading: true,
            }

        case USER_FAIL:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}