import React, { useState, useEffect, useRef } from "react";
import Layout from "../../layout/layout";
import axiosauth from "../../axios-config-auth";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SuccessModal from "../../components/halper/SuccessModal";
import { Redirect, useHistory } from "react-router-dom";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import deleteIcon from '../../assets/icons/delete.png';
import addIcon from '../../assets/icons/add.png';
import { confirmAlert } from "react-confirm-alert";
import LoaderModal from "../../components/halper/LoaderModal";
// import { EditorState, convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import draftToHtml from "draftjs-to-html";
// import JoditEditor from 'jodit-pro-react';
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
import { Editor } from '@tinymce/tinymce-react';


const AddProduct = () => {
  const history = useHistory();
  const animatedComponents = makeAnimated();
  const [category, setCategory] = useState([]);
  const [rediretId, setRedirectId] = useState()

  const [styleObj, setStyleObj] = useState([]);
  const [styleId, setStyleId] = useState();
  const [idealObj, setIdealObj] = useState([]);
  const [idealId, setIdealId] = useState();
  const [traderObj, setTraderObj] = useState([]);
  const [traderId, setTraderId] = useState();
  const [trendsObj, setTrendsObj] = useState([]);
  const [trendsId, setTrendsId] = useState();
  const [conditionObj, setConditionObj] = useState([]);
  const [conditionId, setConditionId] = useState();
  const [seasonObj, setSeasonObj] = useState([]);
  const [seasonId, setSeasonId] = useState();
  const [stickyList, setStickyList] = useState([]);
  const [selectSticky, setSelectSticky] = useState([]);

  // Error State
  const [nameError, setNameError] = useState("");
  const [longError, setLongError] = useState("");
  const [shortError, setShortError] = useState("");
  const [catError, setCatError] = useState("");
  const [gstError, setGstError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const [showLoader, setShowLoader] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [back, setBack] = useState(false);

  const [content, setContent] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [sizeChart, setSizeChart] = useState("");
  const [measure, setMeasure] = useState("");
  const [COD, setCOD] = useState("false")
  const [EssentialItem, setEssentialItem] = useState("false")
  const [isReturnable, setIsReturnable] = useState("true")
  const [isExchangeable, setIsExchangeable] = useState("false")
  const [returnDays, setReturnDays] = useState("");
  const [priceSecondaryDisplay, setPriceSecondaryDisplay] = useState("false")
  const [ gender , setGender] = useState("")


  // const [updateData, setUpdateData] = useState(EditorState.createEmpty());
  // const [updateData1, setUpdateData1] = useState(EditorState.createEmpty());
  // const [updateData2, setUpdateData2] = useState(EditorState.createEmpty());
  // const [updateData3, setUpdateData3] = useState(EditorState.createEmpty());

  // console.log("---------->>>>>>>>>>", selectSticky)
  const editorRef = useRef(null);

  // const onEditorStateChange = (editorState) => {
  //   setUpdateData(editorState);
  //   let enterData = draftToHtml(convertToRaw(updateData.getCurrentContent()));
  //   setContent(enterData)
  // };

  // const onEditorStateChange1 = (editorState) => {
  //   setUpdateData1(editorState);
  //   let enterData = draftToHtml(convertToRaw(updateData1.getCurrentContent()));
  //   setProductDescription(enterData)
  // };

  // const onEditorStateChange2 = (editorState) => {
  //   setUpdateData2(editorState);
  //   let enterData = draftToHtml(convertToRaw(updateData2.getCurrentContent()));
  //   setSizeChart(enterData)
  // };

  // const onEditorStateChange3 = (editorState) => {
  //   setUpdateData3(editorState);
  //   let enterData = draftToHtml(convertToRaw(updateData3.getCurrentContent()));
  //   setMeasure(enterData)
  // };

  const [Subcategory, setSubCategory] = useState([]);
  const [SubSubCategory, setSubSubCategory] = useState([]);

  // const editor = useRef(null);

  // const config = {
  //   readonly: false,
  // };



  const [categoryId, setCategoryId] = useState([]);
  const [SubcategoryId, setSubCategoryId] = useState([]);
  const [subId, setSubId] = useState([]);
  const [SubSubCategoryId, setSubSubCategoryId] = useState([]);
  const [shortCount, setShortCount] = useState(0);
  const [longCount, setLongCount] = useState(0);
  const [shortCountError, setShortCountError] = useState(null);
  const [longCountError, setLongCountError] = useState(null);
  const [commonError, setCommonError] = useState("");
  
  // ==================================New Input field ================================
  console.log("commonError===>>" , commonError)

  const [productTag, setProductTag] = useState("");
  const [searchTag, setSearchtag] = useState("");
  const [materialCare, setMaterialCare] = useState("");
  const [packageContent, setPackageContent] = useState("");
  const [itemWt, setItemWt] = useState(0);
  const [dispatchAdd, setDispatchAdd] = useState("");
  const [HSNCode, setHSNCode] = useState("");
  const [country, setCountry] = useState("");
  const [color, setColor] = useState("");
  const [length, setLength] = useState("");
  const [breath, setBreath] = useState("");
  const [height, setHeight] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [sizeFit, setSizeFit] = useState("");
  const [productName, setProductName] = useState("");
  const [emailError, setemailError] = useState("");
  const [faqList, setFaqList] = useState([{question:'',answer:''}]);
  const [faqReq, setFaqReq] = useState(false);

  const [product, setproduct] = useState({
    name: "",
    shortDescription: "",
    longDescription: "",
    foodType: "",
  });
  const { name, shortDescription, longDescription, foodType } = product;

  const handleSelect = (selectedOption) => {
    setCategoryId(selectedOption.catObjid);
    setCatError("");
    setIsDisabled(false);
    // loadSubCategory(selectedOption.catId);
    loadSubCategory(selectedOption.catId);
    setCommonError("");
  };

  const handleSelectSubCat = (selectedOption) => {
    setSubCategoryId(selectedOption.subCatId);
    setSubId(selectedOption.id);
    // setSubError('');
    loadSubSubCategory(selectedOption.subCatId);

    setCommonError("");
  };

  const handleSelectSubSubCat = (selectedOption) => {
    setSubSubCategoryId(selectedOption.subCatId);

    setCommonError("");
  };



  const handleChange = (e) => {
    setproduct({ ...product, error: false, [e.target.name]: e.target.value });
    setNameError("");
    setLongError("");
    setShortError("");
    // setfoodError("");
    setCommonError("");
    if (e.target.name === "shortDescription") {
      setShortCount(product.shortDescription.split(" ").length);
    }

    if (e.target.name === "longDescription") {
      setLongCount(product.longDescription.split(" ").length);
    }


  };


  // const [gstCode, setGstCode] = useState("");
  const [gstObj, setGstObj] = useState([]);

  const [gsList, setGsList] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  useEffect(() => {
    const obj = {
      limit: 100000,
    };
    axiosauth
      .post("/gst/load", obj)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setGstObj(res.data);
        } else {
          // setCatalogueError(res.message)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log("-----always executes");
      });
  }, []);

  useEffect(() => {
    if (gstObj && gstObj.length > 0) {
      let v = [];

      gstObj.forEach((gst) => {
        if (gst.suspend === "true") {
          v.push({ label: gst.gstName, value: gst._id });
        }
      });
      setGsList(v);
    }
  }, [gstObj]);

  const [gst, setGst] = useState("");
  const handleSelectGst = (selectedOption) => {
    setGst(selectedOption.value);
    setGstError("");
    setCommonError("");
  };
  const [value1, setValue1] = useState("false");

  const handleChange1 = (e) => {
    setValue1(e.target.value);
    setCommonError("");
  };

  const [specs, setspecs] = useState([
    {
      name: "",
      value: "",
    },
  ]);

  /**
   * TODO: function use for removing specification row
   *@version 1.0.0
   * @author [ Unknown]
   */
  const removespecs = (index) => {
    const temp = [...specs];

    const filter = temp.filter((item, i) => i !== index);

    setspecs(filter);
  };
  /**
   * TODO: function use for adding specification row
   *@version 1.0.0
   * @author [ Unknown]
   */
  const addspecs = () => {
    setspecs([...specs, { name: "", value: "" }]);
  };

  /**
   * TODO: function use for handling name value change of specification
   *@version 1.0.0
   * @author [ Unknown]
   */

  const [openSuccess, setOpenSuccess] = useState(false);
  const [catalogueError, setCatalogueError] = useState("");


  const submitProduct = (e) => {
    e.preventDefault();
    var error1 = "";


    if (name === "") {
      setNameError("Product Name Required");
      setCommonError("Product Name Required");
      return 0;
    }

    if (categoryId.length === 0) {
      setCatError("Category Required");
      setCommonError("Category Required");
      return 0;
    } else {
      setCatError("")
    }
    if (subId.length === 0) {
      setCatError("Category Required");
      setCommonError("Sub Category Required");
      return 0;
    }
    if (SubSubCategoryId.length === 0) {
      // setCatError("Category Required");
      setCommonError("Sub-Sub Category Required");
      return 0;
    }

    if (gst === "") {
      setGstError("GST Required");
      setCommonError("GST Required");
      return 0;
    }

    if (address === "") {
      // setGstError("GST Required");
      setCommonError("Manufacturer Address Required");
      return 0;
    }
    if (length === "") {
      // setGstError("GST Required");
      setCommonError("Length Required");
      return 0;
    }
    if (breath === "") {
      // setGstError("GST Required");
      setCommonError("Width Required");
      return 0;
    }
    if (height === "") {
      // setGstError("GST Required");
      setCommonError("Height Required");
      return 0;
    }

    if (HSNCode === "") {
      // setGstError("GST Required");
      setCommonError("HSN Code Required");
      return 0;
    }

    if (value1 === "true") {
      if (specs[0].name === "") {
        setCommonError("Title Required");
        return 0;
      }

      if (specs[0].value === "") {
        setCommonError("Value Required");
        return 0;
      }
    }

    if (content === "") {
      setShortError("Product Specification Required");
      setCommonError("Product Specification Required");
      return 0;
    }
    if (mobile === "" && mobile.length < 10) {
      setCommonError("Contact Number Required");
      return 0;
    }

    if (shortCount > 500) {
      setShortCountError("Product Specification should be <=500 words.");
      setShortError("");
      setCommonError("Product Specification should be <=500 words.");

      return 0;
    } else {
      setShortError("");
      setCommonError("")
    }

    if (productDescription === "") {
      setLongError("Product Description Required");
      setCommonError("Product Description Required");
      return 0;
    } else {
      setLongError("");
      setCommonError("")
    }

    if (longCount > 2000) {
      setLongCountError("Product Descriptionshould be <=2000 words.");
      setCommonError("Product Description should be <=2000 words.");
      setLongError("");
      return 0;
    }

    if (email === "") {
      setemailError("Please Enter Email");
      setCommonError("Email Required")
      error1 = "a";
    } else {
      setemailError("");
      error1 = "";
    }
    if (email.length > 0) {
      // eslint-disable-next-line
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) === false) {
        setemailError("Invalid Email");
        error1 = "a";
        // return false;
      } else {
        setemailError("");
        error1 = "";
      }
    } 
    // console.log("return days=====>>" , returnDays)
    if((returnDays === "" && (isReturnable=== "true" || isExchangeable === "true"))){
      setCommonError("Please Enter return Days")
      // alert("Please Enter return Days")
      return 0;
    }

    let ls = []

    if(faqList && faqList?.length){
      faqList.forEach(item=>{
        if(item.question && item.answer){
          ls.push(item)
        }
      })
    }

    let obj = {
      brand: localStorage.getItem("brandObj"),
      name: name,
      gst: gst,
      hsnCode: HSNCode,
      category: {
        cat: categoryId,
        sub1: subId.length > 0 ? subId : "",

        sub2: SubSubCategoryId.length > 0 ? SubSubCategoryId : "",
      },
      specification: {
        name: "",
        value: "",
      },
      shortDescription: content,
      longDescription: productDescription,
      foodType: "none",
      productTags: productTag,
      searchTags: searchTag,
      materialCare: materialCare,
      packageContents: packageContent,

      sizeChart: sizeChart,
      howTomeasure: measure,
      title: productName,
      sizeFit: sizeFit,
      itemWeight: itemWt,
      dispatchAddress: dispatchAdd,
      style: styleId,
      ideals: idealId,
      trends: trendsId,
      tradertype: traderId,
      seasons: seasonId,
      condition: conditionId,
      countryOrigin: country,
      color: color,
      explore: selectSticky,
      sex:gender,
      cod: COD === "true" ? true : false,
      EssentialItem: EssentialItem === "true" ? true : false,
      isReturnable: isReturnable === "true" ? true : false,
      isExchangable: isExchangeable === "true" ? true : false,
      returnDays: parseInt(returnDays),
      priceSecondaryDisplay: priceSecondaryDisplay === "true" ? true : false,
      faq:ls,

      productDimension: {
        length: length,
        breadth: breath,
        height: height,
      },
      manufacturerEmail: email,
      manufacturerAddress: address,
      manufacturerMobile: mobile,
    };

    // console.log("submit obj------->>", obj)

    setShowLoader(true);
    axiosauth
      .post("/catalogue/create-catalogue", obj)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.message === "success") {
          localStorage.setItem("catalogueId", res.data.catalogueId);
          setRedirectId(res.data.catalogueId)
          setShowLoader(false);
          setOpenSuccess(true);
        } else {
          setShowLoader(false);
          setCatalogueError(res.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        setCommonError("Some Error Occured")
        //   setNetworkError(true);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };
 

  /**
   * TODO: function use for getting subcategory of a specific category
   *@version 1.0.0
   * @author [ Unknown]
   */
  const loadSubCategory = (id) => {
    let obj = {
      categoryId: id,
    };
    axiosauth
      .post("/categories/load-by-id", obj)
      .then((response) => {
        let res = JSON.parse(response.data);

        if (res.success === true) {

          // for (var i = 0; i < res.data.categories.length; i++) {
          // }
          // if(res.data.category.category[0].childCategories){
          if (res.data.category.category[0].childCategories) {
            setSubCategory(res.data.category.category[0].childCategories);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };
  const loadSubSubCategory = (id) => {
    let obj = {
      categoryId: id,
    };
    axiosauth
      .post("/categories/load-by-id", obj)
      .then((response) => {
        let res = JSON.parse(response.data);

        if (res.success === true) {
          if (res.data.category.category[0].childCategories) {
            setSubSubCategory(res.data.category.category[0].childCategories);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };

  useEffect(() => {
    const loadCategory = () => {
      let obj = {
        count: 0,
        filters: {
          type: "category",
        },
        sortBy: {
          type: "createdOn",
          order: -1,
        },
      };
      var catlist = [];
      axiosauth
        .post("/categories/load", obj)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            for (var i = 0; i < res.data.categories.length; i++) {
              if (res.data.categories[i]) {
                catlist.push({
                  catId: res.data.categories[i].categoryId,
                  label: res.data.categories[i].name,
                  catObjid: res.data.categories[i]._id,
                });
              }
            }
            setCategory(catlist);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          console.log("-----always executes");
        });
    };
    loadCategory();
  }, []);

  var subcatlist = [];
  if (Subcategory && Subcategory.length > 0) {
    for (var k = 0; k < Subcategory.length; k++) {
      subcatlist.push({
        subCatId: Subcategory[k].categoryId,
        label: Subcategory[k].name,
        id: Subcategory[k]._id,
      });
    }
  }

  var subSubcatlist = [];
  if (SubSubCategory && SubSubCategory.length > 0) {
    for (var i = 0; i < SubSubCategory.length; i++) {
      subSubcatlist.push({
        subCatId: SubSubCategory[i]._id,
        label: SubSubCategory[i].name,
      });
    }
  }

  const [redirect, setRedirect] = useState(false);

  const closeModal = () => {

    // setRedirect(true);
    history.push(`/add-catalouge-image/${rediretId}`)

  };

  const handleInputChange = (search) => {
    let obj = {
      filters: {
        type: "category",
        name: search,
      },
      sortBy: {
        type: "createdOn",
        order: -1,
      },
    };
    var catlist = [];

    axiosauth
      .post("/categories/load", obj)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          for (var i = 0; i < res.data.categories.length; i++) {
            if (res.data.categories[i].childCategories.length !== 0) {
              catlist.push({
                catId: res.data.categories[i].categoryId,
                label: res.data.categories[i].name,
                catObjid: res.data.categories[i]._id,
              });
            }
          }
          setCategory(catlist);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
      });
  };

  //  const maxLengthCheck = (object) => {
  //     if (object.target.value.length > object.target.maxLength) {
  //       object.target.value = object.target.value.slice(0, object.target.maxLength)
  //     }
  //   }

  const connectionError = () => {
    setNetworkError(false);
  };

  const backButton = () => {
    if (name !== "") {
      confirmAlert({
        // title: 'Change Status',
        message: "Are you sure to do this. You will loss your data.",
        buttons: [
          {
            label: "Yes",
            onClick: () => setBack(true),
          },
          {
            label: "No",
            onClick: () => console.log("No"),
          },
        ],
      });
    } else {
      setBack(true);
    }
  };

  const handleSelectIdeal = () => {
    axiosauth
      .post("/ideals/list-ideals/")
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setIdealObj(res.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        //   setNetworkError(true);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };

  const handleStyle = () => {
    axiosauth
      .post("/style/list-styles/")
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setStyleObj(res.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        //   setNetworkError(true);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };

  const handleTraderType = () => {
    axiosauth
      .post("/tradertype/list-tradertype/")
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setTraderObj(res.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        //   setNetworkError(true);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };
  const handleSeason = () => {
    axiosauth
      .post("/seasons/list-seasons/")
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setSeasonObj(res.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        //   setNetworkError(true);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };

  const handleTrands = () => {
    axiosauth
      .post("/trends/list-trends/")
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setTrendsObj(res.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        //   setNetworkError(true);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };

  const handleCondition = () => {
    axiosauth
      .post("/condition/list-condition/")
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setConditionObj(res.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        //   setNetworkError(true);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };

  const onlyNumberAllow = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  const onlyNumericWithDotAllow = (e) => {
    const re = /[0-9..}]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }


  useEffect(() => {

    axiosauth.post("/explore/load-Explore", { limit: 1000 })
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (response.status === 200) {
          let arr = [];
          for (var i = 0; i < res.load.length > 0; i++) {
            arr.push({
              label: res.load[i].name,
              value: res.load[i]._id,
            });
          }

          setStickyList(arr)
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);

  const handleSticky = (e) => {
    let arr = []
    e.forEach((ele) => {
      arr.push(ele.value)
      // console.log(ele.value)
    })
    // console.log(e)
    setSelectSticky(arr)

  }

  const manageList = (index) => {
    if(faqList[index].question && faqList[index].answer){
      setFaqReq(false)
      if(faqList.length < 10){
        const cloneFaqList = [...faqList]
        cloneFaqList.push({question:'',answer:''})
        setFaqList(cloneFaqList)
      }
    }else{
      setFaqReq(true)
    }
  }
  const handleRemove = (ind) =>{
    const cloneFaqList = [...faqList]
    cloneFaqList.splice(ind,1)
    setFaqList(cloneFaqList)
  }

  const handleChangeFaqQue = (e,index) =>{
    const {value} = e.target
    const cloneFaqList = [...faqList]
    cloneFaqList[index].question = value
    setFaqList(cloneFaqList)
  }

  const handleChangeFaqAns = (newText,index) =>{
    const cloneFaqList = [...faqList]
    cloneFaqList[index].answer = newText
    setFaqList(cloneFaqList)
  }

  return (
    <Layout>
      {redirect ? <Redirect to="/brand" /> : null}
      {openSuccess ? (
        <SuccessModal message="New Product Added!" onClick={closeModal} />
      ) : null}
      {networkError === true ? (
        <SuccessModal
          message="Internet Connection Error.."
          onClick={connectionError}
        />
      ) : null}

      {showLoader === true ? <LoaderModal /> : null}

      {back ? <Redirect to="/manage-product?page=1" /> : null}

      <div
        className="left_arrow2 back-arrow pt-4"
        style={{
          display: "flex",
          alignItems: "baseline",
          fontFamily: "inherit",
          position: "fixed",
          backgroundColor: "white",
          zIndex: "999",
          width: "100%"
        }}
      >
        <img src={left_arrow2} onClick={backButton} alt="left-arrow" /> &nbsp;
        <h2>Add a Product Catalogue</h2>
      </div>

      <form onSubmit={submitProduct} className="form-wrap ">
        {/* <div className="form-header"> */}

        <div className="form-body mt-5">
          <div className="single-field">
            <p>
              Product Title <b>*</b>
            </p>
            <input
              type="text"
              id="name"
              className={`${nameError.length > 0 ? "border-danger" : ""}`}
              placeholder="Enter Product Name"
              name="name"
              onChange={handleChange}
            // required
            />
            <i className="error">{nameError}</i>
          </div>

          <div id="select-tag">
            <p>
              Select Categories<b>*</b>
            </p>

            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={category}
              name="category"
              className={`${catError.length > 0 ? "border-danger" : ""}`}
              placeholder="Select or Search"
              onInputChange={handleInputChange}
              onChange={handleSelect}
            />
            <i className="error">{catError}</i>
          </div>

          <div id="select-tag">
            <p>
              Select Sub-Categories<b>*</b>
            </p>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={subcatlist}
              name="subcat"
              isDisabled={isDisabled}
              // required
              onChange={handleSelectSubCat}
            />
            {/* <i className="error">{subError}</i> */}
          </div>

          <div id="select-tag">
            <p>
              Select Sub-Sub-Categories<b>*</b>
            </p>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={subSubcatlist}
              name="subcat"
              isDisabled={isDisabled}
              onChange={handleSelectSubSubCat}
            />
            {/* <i className="error">{subSubError}</i> */}
          </div>

          <div id="select-tag">
            <p>
              Gst Tax Code<b>*</b>
            </p>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={gsList}
              name="gst"
              // required
              onChange={handleSelectGst}
            />
            <i className="error">{gstError}</i>
          </div>

          <section>
            <div className="two-field">
              <p>Name of the Product </p>
              <input
                type="text"
                // value={condition}
                onChange={(e) => setProductName(e.target.value)}
                placeholder="Name of the Product"
              // required
              />
            </div>
            <div className="two-field">
              <p>
                Product Tag(Comma Separated)<b></b>
              </p>
              <input
                type="text"
                // value={specs[index].hsnCode}
                onChange={(e) => setProductTag(e.target.value)}
                placeholder="Enter product tag"
              // required
              />
            </div>
          </section>
          <section>
            <div className="two-field">
              <p>
                Search Keywords(Comma Separated)<b></b>
              </p>
              <input
                type="text"
                // value={specs[index].mrp}
                onChange={(e) => setSearchtag(e.target.value)}
                placeholder="Enter Search tag"
              // required
              />
            </div>

            <div className="two-field">
              <p>
                Size & Fit<b></b>
              </p>
              <input
                type="text"
                // value={condition}
                onChange={(e) => setSizeFit(e.target.value)}
                placeholder="Enter Size & fit"
              // required
              />
            </div>
          </section>

          <section>
            <div className="two-field">
              <p>
                Ideal For<b></b>
              </p>
              <div id="select-tag" onClick={handleSelectIdeal}>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setIdealId(e.target.value)}
                >
                  <option value="" selected>
                    select Ideal
                  </option>
                  {idealObj?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="two-field">
              <div id="select-tag" onClick={handleStyle}>
                <p>Select Style </p>

                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setStyleId(e.target.value)}
                >
                  <option value="" selected>
                    select Style
                  </option>
                  {styleObj?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>

                {/* <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={styleObj}
                            name="styel"
                            placeholder="Select or Search"
                            onInputChange={handleSelectStyle}
                            onChange={handleInputStyle}
                        />
                        <i className="error">{catError}</i> */}
              </div>

              {/* 
                            <p>Style<b></b></p>
                            <input
                                type="text"
                                // value={specs[index].mrp}
                                onChange={(e) => setStyle(e.target.value)}
                                placeholder="Enter Style"
                            // required
                            /> */}
            </div>
          </section>
          <section>
            <div className="two-field">
              <p>
                Material & Care<b></b>
              </p>
              <input
                type="text"
                // value={specs[index].hsnCode}
                onChange={(e) => setMaterialCare(e.target.value)}
                placeholder="Enter  Material & Care"
              // required
              />
            </div>
            <div className="two-field">
              <p>
                Product Package Content<b></b>
              </p>
              <input
                type="text"
                // value={specs[index].mrp}
                onChange={(e) => setPackageContent(e.target.value)}
                placeholder="Enter Product Package Content"
              // required
              />
            </div>
          </section>

          <section>
            <div className="two-field">
              <p>Trends</p>
              <div id="select-tag" onClick={handleTrands}>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setTrendsId(e.target.value)}
                >
                  <option value="" selected>
                    select trends
                  </option>
                  {trendsObj?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="two-field">
              <p>
                Item weight(Grams)<b></b>
              </p>
              <input
                type="number"
                // value={specs[index].mrp}
                onChange={(e) => setItemWt(e.target.value)}
                placeholder="Enter Item weight"
              // required
              />
            </div>
          </section>

          <section>
            <div className="two-field">
              <p>
                HSN Code<b>*</b>
              </p>

              <input
                type="text"
                // value={specs[index].breadth} 
                className={` form-control shadow-none ${commonError==="HSN Code Required" ? "border-danger " : null}`}
                onChange={(e) => setHSNCode(e.target.value)}
                placeholder="Enter  HSN Code"
                // required
                onKeyPress={(e) => onlyNumberAllow(e)}
                maxLength={13}
              />
            </div>

            <div className="two-field">
              <div id="select-tag" onClick={handleTraderType}>
                <p> Trader Type </p>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setTraderId(e.target.value)}
                >
                  <option value="" selected>
                    select trader type
                  </option>
                  {traderObj?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </section>

          <section>
            <div className="two-field">
              <div id="select-tag" onClick={handleSeason}>
                <p>Season </p>

                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setSeasonId(e.target.value)}
                >
                  <option value="" selected>
                    select trader type
                  </option>
                  {seasonObj?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="two-field">
              <p>
                Dispatch Address<b></b>
              </p>
              <input
                type="text"
                // value={specs[index].mrp}
                onChange={(e) => setDispatchAdd(e.target.value)}
                placeholder="Enter Dispatch Address"
              // required
              />
            </div>
          </section>
          <section>
            <div className="two-field">
              <div id="select-tag" onClick={handleCondition}>
                <p>Condition </p>

                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setConditionId(e.target.value)}
                >
                  <option value="" selected>
                    select condition
                  </option>
                  {conditionObj?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="two-field">
              <p>
                Country of Origin<b></b>
              </p>
              <input
                type="text"
                // value={condition}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="Enter  country of origin"
              // required
              />
            </div>
          </section>
          <section>
            <div className="two-field">
              <p>
                <b></b>
                Color<b></b>
              </p>
              <input
                type="text"
                // value={condition}
                onChange={(e) => setColor(e.target.value)}
                placeholder="Enter  Product Color"
              // required
              />
            </div>
            <div className="two-field">
              <p>Select Sticky Stickers</p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={stickyList}
                onChange={(e) => {
                  // console.log(e)
                  // setSelectSticky(e)

                  handleSticky(e)
                  // handleChange(e)
                }}
              />
            </div>
          </section>

          <div className="row d-flex">
            <div className="col">
              <div style={{ marginTop: "20px", display: "flex" }}>
                {/* <label
                  className="ml-1 label-form "
                  for="vehicle1"
                  style={{ marginTop: "-5px" }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "12px",
                      color: "#4F4F4F",
                    }}
                  >
                    {" "}
                    COD<b></b>
                  </p>
                </label> */}
                {/* <input
                  className="mx-4 mt-1"
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value={COD === "false" ? true : false}
                  onChange={(e) => {
                    // console.log(e.target.value)
                    setCOD(e.target.value)
                  }}
                /> */}

                <br />

                <span className="mx-4 " style={{ marginTop: "-5px" }}>
                  <label
                    className="mx-1"
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "12px",
                      color: "#4F4F4F",
                    }}
                  >
                    Essential item
                  </label>
                  <input
                    className="mx-4 mt-1"
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value={EssentialItem === "false" ? true : false}
                    onChange={(e) => {
                      setEssentialItem(e.target.value)
                    }}
                  />

                </span>

              </div>
            </div>
            <div className="col">
              <div
                className="d-flex  "
                style={{ marginTop: "20px" }}
              >
                <label
                  className=" label-form mx-2"
                  for="vehicle1"
                  style={{ marginTop: "-5px" }}
                >
                  Is Returnable
                </label>
                <input
                  className="mx-3"
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  checked={isReturnable=== "true" ? true : false}
                  value={isReturnable === "false" ? true : false}
                  onChange={(e) => {
                    setIsReturnable(e.target.value)
                  }}
                />

                <br />

                <span className="" style={{ marginTop: "-5px" }}>
                  <label
                    className="mx-4"
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "24px",
                      color: "#4F4F4F",
                    }}
                  >
                    Is Exchangeable
                  </label>
                  <input
                    className="mx-3"
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value={isExchangeable === "false" ? true : false}
                    onChange={(e) => {
                      setIsExchangeable(e.target.value)
                    }}
                  />

                </span>

              </div>
              {isReturnable === "true" || isExchangeable === "true" ? (
                <section>
                  <div className="two-field mx-2">
                    <p>
                      Return Days<b></b>
                    </p>

                    <input
                    className={` form-control shadow-none ${commonError==="Please Enter return Days" ? "border-danger " : null}`}
                      type="number"
                      onChange={(e) =>
                        setReturnDays(e.target.value)
                      }
                      // style={{ returnDays && {borderColor: "red"}}}
                      placeholder="Enter Days"
                      // required
                    />
                  </div>
                </section>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div style={{ marginTop: "20px", display: "flex" }}>
                {/* <label
                  className="ml-1 label-form "
                  for="vehicle1"
                  style={{ marginTop: "-5px" }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "12px",
                      color: "#4F4F4F",

                    }}
                  >
                    Price Secondary Display<b></b>
                  </p>
                </label>
                <div>
                  <input
                    className="mx-4 mt-1"
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value={priceSecondaryDisplay === "false" ? true : false}
                    onChange={(e) => {
                      setPriceSecondaryDisplay(e.target.value)
                    }}
                  />
                </div> */}

              </div>
            </div>

            <div className="col">
              <div style={{ marginTop: "20px", display: "flex" }}>
                <label
                  className="ml-1 label-form "
                  for="vehicle1"
                  style={{ marginTop: "-5px" }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "12px",
                      color: "#4F4F4F",

                    }}
                  >
                    Gender<b></b>
                  </p>
                </label>
                <div>
                  <div className="col d-flex"> 
                    <div>

                      <select className="form-select mx-3" aria-label="Default select example"
                        value={gender}
                        onChange={(e) => {
                          // console.log(e.target.value)
                          setGender(e.target.value)
                        }}
                      >
                        <option value="" disabled >Select Gender</option>
                        <option value="MEN">MEN</option>
                        <option value="WOMEN">WOMEN</option>
                        <option value="UNISEX">UNISEX</option> 
                        <option value="GIRLS">GIRLS</option> 
                        <option value="BOYS">BOYS</option> 
                      </select>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <p className="mt-5 font-weight-bold">Product Package Dimensions</p>
          <section>
            <div className="two-field">
              <p>
                Length(cm)<b>*</b>
              </p>

              <input
                type="text"
                // value={length?.toFixed(2)}
                className={` form-control shadow-none ${commonError==="Length Required" ? "border-danger " : null}`}
                onChange={(e) => setLength(e.target.value)}
                placeholder="Enter Length"
                onKeyPress={(e) => onlyNumericWithDotAllow(e)}
                // required
              />
            </div>
            <div className="two-field">
              <p>
                Breadth(cm)<b>*</b>
              </p>

              <input
                type="text"
                className={` form-control shadow-none ${commonError==="Width Required" ? "border-danger " : null}`}
                // value={specs[index].breadth}
                onChange={(e) => setBreath(e.target.value)}
                placeholder="Enter Breadth"
                onKeyPress={(e) => onlyNumericWithDotAllow(e)}
                // required
              />
            </div>
          </section>
          <section>
            <div className="two-field">
              <p>
                Height(cm)<b>*</b>
              </p>

              <input
                type="text"
                className={` form-control shadow-none ${commonError==="Height Required" ? "border-danger " : null}`}
                onChange={(e) => setHeight(e.target.value)}
                placeholder="Enter Height"
                onKeyPress={(e) => onlyNumericWithDotAllow(e)}
                // required
              />
            </div>
          </section>

          {/* <div className="specification">

                        {value1 === "true" ?
                            <>
                                {specs && specs.length > 0
                                    ? specs.map((value, index) => {

                                        return (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-5 col-10">
                                                        <div class="form-group form-input">
                                                            <input
                                                                placeholder="Title"
                                                                value={value.name}
                                                                className={'form-control form-control-nonround'}
                                                                mandatory={true}
                                                                onChange={(e) => handleChange2(e, "name", index)}

                                                            />
                                                        </div>
                                                        <i className="error"> {speError1}</i>
                                                    </div>
                                                    <div className="col-md-5 col-10 mr-0">
                                                        <div class="form-group form-input">
                                                            <input
                                                                placeholder="Value"
                                                                value={value.value}
                                                                className={'form-control form-control-nonround'}
                                                                mandatory={true}
                                                                onChange={(e) => handleChange2(e, "value", index)}

                                                            />
                                                        </div>

                                                        <i className="error"> {speError2}</i>
                                                    </div>
                                                    <div>
                                                        <button className="cross_button" onClick={() =>
                                                            removespecs(index)}> X</button>
                                                    </div>
                                                </div>
                                            </>
                                        )

                                    })
                                    : null}

                                {specs.length < 5 ? (
                                    <div className="add_button">
                                        <button onClick={() => addspecs()}>+ADD</button>
                                    </div>) : null}
                            </>
                            : null
                        }

                    </div> */}

          <div id="short-description" className="cms-wrap">
            <p className="label-form">
              Product Specification <b>*</b>
            </p>
            <div className="cms-body">

              {/* <Editor
                editorState={updateData}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
              /> */}
              <Editor
                onInit={(evt, editor) => { editorRef.current = editor }}
                initialValue=""
                onEditorChange={(newText) => {
                  setContent(newText)
                }}
                init={{
                  height: 200,
                  menubar: true,
                  plugins: [
                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            </div>

            {/* <textarea
                            placeholder="Enter Short Description"
                            name="shortDescription"
                            onChange={handleChange}
                            // maxLength={500}
                            // onInput={maxLengthCheck}
                            // onKeyDown={e => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                        /> */}
            <i className="error">
              {shortError}
              {shortCountError}
            </i>
          </div>
          <div id="short-description" className="cms-wrap">
            <p className="label-form">
              Product Description <b>*</b>
            </p>
            <div className="cms-body ">
              {/* <DescriptionEditor/> */}
              {/* <JoditEditor
                ref={editor}
                value={productDescription}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setProductDescription(newContent)}
                onChange={(newContent) => {
                  // setContent(newContent) 
                }}
              /> */}
              {/* <Editor
                editorState={updateData1}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange1}
              /> */}
              <Editor
                onInit={(evt, editor) => { editorRef.current = editor }}
                initialValue=""
                onEditorChange={(newText) => {
                  setProductDescription(newText)
                }}
                init={{
                  height: 200,
                  menubar: true,
                  plugins: [
                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            </div>
            <i className="error">
              {longError}
              {longCountError}
            </i>
          </div>

          <div id="short-description" className="cms-wrap">
            <p className="label-form">Size Chart </p>
            <div className="cms-body ">
              {/* <SizeChartEditor/> */}
              {/* <JoditEditor
                ref={editor}
                value={sizeChart}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setSizeChart(newContent)}
                onChange={(newContent) => {
                  // setContent(newContent) 
                }}
              />   */}
              {/* <Editor
                editorState={updateData2}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange2}
              /> */}
              <Editor
                onInit={(evt, editor) => { editorRef.current = editor }}
                initialValue=""
                onEditorChange={(newText) => {
                  console.log(newText)
                  setSizeChart(newText)
                }}
                init={{
                  height: 200,
                  menubar: true,
                  plugins: [
                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            </div>
            {/* <i className="error">{longError}{longCountError}</i> */}
          </div>

          <div id="short-description" className="cms-wrap">
            <p className="label-form">How To Measure </p>
            <div className="cms-body ">
              {/* <HowToMeasureEditor/> */}
              {/* <JoditEditor
                ref={editor}
                value={measure}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setMeasure(newContent)}
                onChange={(newContent) => {
                  // setContent(newContent) 
                }}
              /> */}
              {/* <Editor
                editorState={updateData3}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange3}
              /> */}
              <Editor
                onInit={(evt, editor) => { editorRef.current = editor }}
                initialValue=""
                onEditorChange={(newText) => {
                  setMeasure(newText)
                }}
                init={{
                  height: 200,
                  menubar: true,
                  plugins: [
                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            </div>
            {/* <i className="error">{longError}{longCountError}</i> */}
          </div>

          <div className="form-body">
            <div className="single-field">
              <p>
                Manufacturer/Importer Address<b>*</b>
              </p>
              <textarea
                type="text"
                className={` form-control shadow-none ${commonError==="Manufacturer Address Required" ? "border-danger " : null}`}
                 
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter Address"
                // required
              />
            </div>
          </div>

          <section>
            <div className="two-field">
              <p>
                Manufacturer Email<b>*</b>
              </p>
              {/* <input
                type="text"
                // value={specs[index].email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Manufacturer Email"
                required
              /> */}
              <input
                type="email"
                name="email"
                className={
                  commonError === "Email taken"  ? "error form-control form-control-round"
                    : "form-control form-control-round"
                }
                id="email"
                placeholder="Enter Email"
                required={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              // onChange={handleChange}
              />
              {emailError && <p style={{ color: "red" }}>{emailError}</p>}
            </div>

            <div className="two-field">
              <p>
                Manufacturer Contact Number<b>*</b>
              </p>
              <input
                type="text"
                className={` form-control shadow-none ${commonError==="Contact Number Required" ? "border-danger " : null}`}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Enter Manufacturer Mobile Number"
                maxlength="10"
                minLength="10"
                name="mobile"
                title="Please enter on alphabets only. "
                onKeyPress={(e) => onlyNumberAllow(e)}
              // pattern="^[0-9 -]+$"
              // oninvalid="setCustomValidity('Please enter on alphabets only. ')"
              // // required
              />
            </div>

          </section>
          <div>
            {faqList.map((item,index,{length})=>{
              return(
                <>
                  <section>
                    <div className="two-field">
                        <p>
                          Enter FAQs
                        </p>
                      <div>
                        <div className="d-flex">
                        <input
                          type="text"
                          className={`form-control shadow-none`}
                          onChange={(e)=>handleChangeFaqQue(e,index)}
                          placeholder={`Enter ${index+1} FAQ`}
                          value={item?.question}
                          name={`faq${index+1}`}
                        />
                        {length - 1 === index && length < 10 ? (
                          <>
                            <img src={addIcon} alt="delete" className="add-icon ml-2" onClick={()=>manageList(index)}/>
                            <i class="bi bi-plus-circle-fill"></i>
                          </>
                        ) : null}
                        <br />
                        {length > 1 ? (
                          <img src={deleteIcon} alt="delete" className="delete-icon2" onClick={()=>handleRemove(index)}/>
                         ) : null} 
                        </div>
                      </div>
                    </div>
                  </section>
                  <br />
                  <div>
                    <Editor
                      onInit={(evt, editor) => { editorRef.current = editor }}
                      initialValue=""
                      onEditorChange={(newText) => {
                        handleChangeFaqAns(newText,index)
                      }}
                      value={item?.answer}
                      init={{
                        height: 200,
                        menubar: true,
                        plugins: [
                          'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                          'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                          'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />
                  </div>
                  {faqReq ? (
                              <p  class='fs-3 pt-1' style={{color:'red', fontSize:'18px'}}>Please enter above faq Question and Answer...</p>
                            ) : null}
                </>
              )
            })}
          </div>
        </div>
        <br />
        <i className="error">
          {catalogueError}
          {commonError}
        </i>

        <div className="button">
          <button className="px-5">Add</button>
        </div>
      </form>
    </Layout>
  );
};

export default AddProduct;
