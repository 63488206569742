import React, { useState, useEffect } from 'react'
import axiosauth from '../../axios-config-auth';
import SearchBox from '../../components/halper/SearchBox';
import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
// import noImage from '../../assets/images/no-img.png';
// import eyeIcon from '../../assets/icons/eye.png';
import { confirmAlert } from 'react-confirm-alert';
import { Link,useHistory } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import Loader from 'react-loader-spinner';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import NoData from '../../components/halper/NoData';
import Layout from '../../layout/layout';

const SeasonList = () => {
    const [showLoader, setShowLoader] = useState(true);
    const [collections, setCollections] = useState([]);
    // const [total, settotal] = useState(0);
    const [activePage, setActivePage] = useState(1);

    // For page count
    const history = useHistory();
    const pageCount = new URLSearchParams(window.location.search).get('page');


    const getBrands = () => {
        let obj = {
            "fields": "max",
            "limit": 200,
            "count": 1,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            }
        }
        axiosauth.post('/seasons/list-seasons/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("jdkd 1", res)
                if (res.success === true) {
                    setCollections(res.data)
                    // settotal(res.data?.count);
                    setShowLoader(false);
                } else {
                    setCollections([])
                    // settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }



    /**
    * TODO: function for pagination handling page number change
    *@version 1.0.0
    * @author [Unknown]
    */
    const handlePageChange = (pageNumber) => {
        // history.push(`/brand?pageCount=${pageNumber}`)
        let obj = {
            "fields": "max",
            "limit": 200,
            // "count": (pageNumber - 1) * 10,
            "count": 1,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            }
        }
        axiosauth.post('/seasons/list-seasons/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("222",res)
                if (res.success === true) {
                    setCollections(res.data)
                    // settotal(res.data?.count);
                    setShowLoader(false);
                } else {
                    setCollections([])
                    // settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

  
    const searching = (search) => {
        let obj = {
            "fields": "max",
            "limit": 200,
            "count": 1,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            },
            "filters": {
                "name": search
            }
        }
        axiosauth.post('/seasons/list-seasons/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("333",res)
                if (res.success === true) {
                    setCollections(res.data)
                    // settotal(res.data?.count);
                    setShowLoader(false);
                } else {
                    setCollections([])
                    // settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }



    useEffect(() => {
        // if(pageCount===null){
        //     history.push(`/brand?pageCount=1`)
        // }


        // if(activePage===1)
        // {
        //   let currentUrlParams = new URLSearchParams(window.location.search);
        //   currentUrlParams.set('page', (pageCount ? pageCount : 1));
        //   history.push(window.location.pathname + "?" + currentUrlParams.toString());
        // }
        let obj;
        if(pageCount !== null){
           
             obj = {
                "fields": "max",
                "limit": 200,
                "count": (pageCount - 1) * 10,
                "sortBy": {
                    "type": "createdOn",
                    "order": -1
                }
            }

          }else{
            obj = {
                "fields": "max",
                "limit": 200,
                // "count": (1 - 1) * 10,
                "count": 1,
                "sortBy": {
                    "type": "createdOn",
                    "order": -1
                }
            }
          }

        
        axiosauth.post('/seasons/list-seasons/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("4444", res)
                if (res.success === true) {
                    setCollections(res.data)
                    // settotal(res.data?.count);
                    setShowLoader(false);
                } else {
                    setCollections([])
                    // settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [pageCount,history,activePage]);


     /**
 * TODO: Pagination
 *@version 1.0.0
 * @author [ Unknown]
 */

  // Handle Pagination

  const [ locationKeys, setLocationKeys ] = useState([]);

  const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
//   console.log(query)//"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
//   console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}

  useEffect(() => {
  return history.listen(location => {
    if (history.action === 'PUSH') {
      setLocationKeys([ location.key ])
    //   console.log("push call")
    }
    // console.log("locationKeys",locationKeys)

    if (history.action === 'POP') {
       
      if (locationKeys[1] === location.key) {
        // console.log("Inside if pop call")
        setLocationKeys(([ _, ...keys ]) => keys)
            //    console.log("forward")
        //   console.log("js",getQueryVariable('page'))
          handlePageChange1( getQueryVariable('page'))
        // Handle forward event

      } else {
        // console.log("Inside else pop call")
        setLocationKeys((keys) => [ location.key, ...keys ])
        // console.log("backward",location.key)
        // console.log("js",getQueryVariable('page'))
        handlePageChange1( getQueryVariable('page'))

      }
    }
  })
}, [ locationKeys,history])

const handlePageChange1 = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handlePageChange5 = (pageNumber) => {
    // console.log("dd",pageNumber)
    handlePageChange(pageNumber);
    setActivePage(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };



    return (
        <Layout> 
           
            <div className="left_arrow2 back-arrow mt-5" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img src={left_arrow2}  onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                <h2>Manage Season</h2>
               
            </div> 
            <SearchBox
                placeholder="Search.."
                onChange={(e) => searching(e.target.value)}
            />
            <div className="button">
                    <Link to="/add-season">
                        <button>Add Season</button>
                    </Link>
                </div>
            {!showLoader ?
                <div className="table-wrap overflow-auto table-responsive table border p-0 border-top-0 border-bottom-0">
                    <table>
                      {collections&&collections.length>0?( <thead>
                            <tr>
                                <th>S.No</th>
                                <th className='text-left px-2'>Style Name</th>   
                                <th>Actions</th>

                            </tr>
                        </thead>):null} 
                        <tbody>
                            {collections && collections.length > 0 ?

                                collections.map((brand, index) => {

                                    const deleteBrand = () => {
                                        let obj = {
                                            "id": brand._id,
                                        }
                                        confirmAlert({
                                            // title: 'Change Status',
                                            message: 'Are you sure you want to delete this season  ?',
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () => {
                                                        axiosauth.post('/seasons/delete-seasons/', obj)
                                                            .then(async (response) => {

                                                                let res = JSON.parse(response.data)
                                                                if (res.success === false) {
                                                                    // setEditShow(false);
                                                                    // getCollections("Collection Deleted");
                                                                } else {
                                                                    getBrands()
                                                                    // setEditShow(false);
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            })
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    onClick: () => console.log("Hello")
                                                }
                                            ]
                                        });

                                    }


                                    return (
                                        <tr key={index}>
                                            <td>{((pageCount?pageCount:1)-1)*10 + (index+1)}</td>
                                            <td className="titleStyle title-name" style={{textAlign:"left"}}>{brand.name}</td> 
                                            
                                            
                                            <td>
                                             <Link to={`/update-season/${brand._id}`} >
                                                <img onClick={() => localStorage.setItem("brand", brand.brandId)} src={editIcon} alt="edit-button" className="edit-icon" />
                                            </Link>
                                            
                                            <img onClick={() => deleteBrand()} src={deleteIcon} alt="delete" className="delete-icon" />
                                            </td>
                                        </tr>
                                    )
                                })

                                :
                                (            <NoData message="Brand Not Found" />
                                )
                            }

                        </tbody>
                    </table>

                    {/* <div className="pagination-custom">
                        <Pagination
                            hideDisabled
                            activePage={parseInt(pageCount)}
                            itemsCountPerPage={10}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            hideNavigation
                            onChange={handlePageChange5}
                        />
                    </div> */}

                </div>
                :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </div>
            }

        </Layout>
    )
}

export default SeasonList
