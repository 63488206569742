import React, { useState } from 'react'
import AboutUs from '../../wrapper/cms/AboutUs';
import Faq from '../../wrapper/cms/Faq';
import TandC from '../../wrapper/cms/TandC';
import ReturnPolicy from '../../wrapper/cms/ReturnPolicy';
import Privacy from '../../wrapper/cms/Privacy';
// import Delivery from '../../wrapper/cms/DeliveryInformation'
const CmsMenu = () => {

    const [showAbout, setShowAbout] = useState(true)
    const [showFaq, setShowFaq] = useState(false);
    const [showTc, setShowTc] = useState(false);
    const [showPolicy, setShowPolicy] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);

    const [showDeliveryInfo, setshowDeliveryInfo] = useState(false)

    const handleAbout = () => {
        setShowAbout(true);
        setShowFaq(false);
        setShowTc(false);
        setShowPolicy(false);
        setShowPrivacy(false);
        setshowDeliveryInfo(false) 
    }

    const handleFaq = () => {
        setShowAbout(false)
        setShowFaq(true)
        setShowTc(false);
        setShowPolicy(false);
        setShowPrivacy(false);
        setshowDeliveryInfo(false)
    }

    const handleTc = () => {
        setShowAbout(false);
        setShowFaq(false);
        setShowTc(true);
        setShowPolicy(false);
        setShowPrivacy(false);
        setshowDeliveryInfo(false)
    }

    const handlePolicy = () => {
        setShowAbout(false);
        setShowFaq(false);
        setShowTc(false);
        setShowPolicy(true);
        setShowPrivacy(false);
        setshowDeliveryInfo(false)
    }
    const handleDelivery = () => {
        setshowDeliveryInfo(true)
        setShowAbout(false);
        setShowFaq(false);
        setShowTc(false);
        setShowPolicy(false);
        setShowPrivacy(false);
    }

    const handlePrivacy = () => {
        setShowAbout(false);
        setShowFaq(false);
        setShowTc(false);
        setShowPolicy(false);
        setShowPrivacy(true);
        setshowDeliveryInfo(false)
    }

    return (
        <div>
            <div className="menu-bar category-menu">
                <section>
                    <div className={showAbout ? "active-menu" : ""} onClick={handleAbout}>GET TO KNOW US</div>
                    <div className={showFaq ? "active-menu" : ""} onClick={handleFaq}>POLICY</div>
                    <div className={showTc ? "active-menu" : ""} onClick={handleTc}>LET US HELP YOU</div>
                    <div className={showPolicy ? "active-menu" : ""} onClick={handlePolicy}>CONNECT WITH US</div>
                    <div className={showPrivacy ? "active-menu" : ""} onClick={handlePrivacy}>SEARCH LINKS</div> 
                    {/* <div className={showPrivacy ? "active-menu" : ""} onClick={handlePrivacy}>REGISTERED OFFICE ADDERSS</div> */}
                    {/* <div className={showDeliveryInfo ? "active-menu" : ""} onClick={handleDelivery}>Delivery Information</div> */}

                </section>
            </div>

            {showAbout && ( 
                <AboutUs />
            )}

            {showFaq && (
                // POLICY
                <Faq />
            )}

            {showTc && (
                // LET US HELP YOU
                <TandC />
            )}

            {showPolicy && (
                // CONNECT WITH US
                <ReturnPolicy />
            )} 

            {showPrivacy && (
                 //  XYZ
                <Privacy />
            )}
        </div>
    )
}

export default CmsMenu
