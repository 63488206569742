import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory, useParams } from "react-router";
// import MultiSelect from "react-multi-select-component";

import axiosauth from "../../axios-config-auth";
import SuccessModal from '../../components/halper/SuccessModal';
import { Redirect } from "react-router-dom";
import LoaderModal from '../../components/halper/LoaderModal';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosInstance from "../../axios-config-auth";

const UpdateDealofTheDay = () => {
    const { dealId } = useParams();
    const history = useHistory();
    // const [product, setProduct] = useState([])
    const animatedComponents = makeAnimated();
    // const [productOptions, setproductOptions] = useState()
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [show, setShow] = useState(false);
    // ================================================
    // const [selectProduct, setSelectProduct] = useState([]);
    // const [productuse, setproductuse] = useState([]);
    // ===================================================================
    const [isApply, setIsApply] = useState("all-Products");
    const [productOptions, setproductOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [subsubcategoryOptions, setSubsubCategoryOptions] = useState([])
    const [checkboxLoading, setCheckboxloading] = useState(false);

    const [product, setProduct] = useState([]);
    const [productuse, setproductuse] = useState([])
    const [selectProduct, setSelectProduct] = useState([]);

    const [cat, setCat] = useState([])
    const [categoryuse, setcategoryuse] = useState([]);
    const [selectCategory, setSelectCategory] = useState([]);

    const [subcat, setSubCat] = useState([])
    const [subcategoryuse, setSubcategoryuse] = useState([]);
    const [selectSubCategory, setSelectSubCategory] = useState([]);

    const [subsubcat, setSubSubCat] = useState([])
    const [subsubcategoryuse, setSubsubcategoryuse] = useState([]);
    const [selectSubsubCategory, setSelectSubsubCategory] = useState([]);
    const [collection, setCollection] = useState();
    const [collId, setCollId] = useState()

    useEffect(() => {
        let arr = [];
        const obj = {
            limit: 10000000,
            // suspend: "false",
            filters: {
                suspend: "false"
                // sub1categoryslug: search,
            },
            sorts: { sortbyPrice: 1 },
            userId: ""
        }
        axiosauth
            .post("/products/loadv2", obj)
            .then((Response) => {
                let res = JSON.parse(Response.data);
                if (res.success === true) {
                    //   console.log("setProduct====>>" ,res.data.products )
                    setProduct(res.data.products)
                    for (var i = 0; i < res.data.products.length > 0; i++) {
                        arr.push({
                            value: res.data.products[i]._id,
                            label: res.data.products[i].catalogueName
                        })
                    }
                    setproductOptions(arr)

                } else {
                }
            })
            .catch((error) => {
                console.log(error.message);
            });

    }, [])


    useEffect(() => {
        let arr = [];
        const obj = {
            "limit": 10000,
            "filters": {
                "type": "category"
            }
        }
        axiosauth
            .post("/categories/load", obj)
            .then((Response) => {
                let res = JSON.parse(Response.data);
                if (res.success === true) {
                    // setCategoryObj(res.data.categories)
                    setCat(res.data.categories)
                    if (res.data.categories) {
                        for (var i = 0; i < res.data.categories.length; i++) {
                            arr.push({
                                value: res.data.categories[i]._id,
                                label: res.data.categories[i].name
                            })
                        }
                    }
                    setCategoryOptions(arr)
                    setCheckboxloading(true)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let arr = [];
        const obj = {
            "limit": 10000,
            "filters": {
                "type": "sub1"
            }
        }
        axiosauth
            .post("/categories/load", obj)
            .then((Response) => {
                let res = JSON.parse(Response.data);
                if (res.success === true) {
                    setSubCat(res.data.categories)
                    if (res.data.categories) {
                        for (var i = 0; i < res.data.categories.length; i++) {
                            arr.push({
                                value: res.data.categories[i]._id,
                                label: res.data.categories[i].name + " (" + res.data.categories[i].parentCategory[0]?.name + " )"
                            })
                        }
                        setCheckboxloading(true)
                        setSubCategoryOptions(arr)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let arr = [];
        const obj = {
            "limit": 10000,
            "filters": {
                "type": "sub2"
            }
        }
        axiosauth
            .post("/categories/load", obj)
            .then((Response) => {
                let res = JSON.parse(Response.data);
                if (res.success === true) {
                    // console.log("--------->>>>" , res.data.categories)
                    setSubSubCat(res.data.categories)
                    if (res.data.categories) {
                        for (var i = 0; i < res.data.categories.length; i++) {
                            arr.push({
                                value: res.data.categories[i]._id,
                                label: res.data.categories[i].name + " (" + res.data.categories[i].parentCategory[0]?.name + " )"
                            })
                        }
                        setSubsubCategoryOptions(arr)
                    }
                    setCheckboxloading(true)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(async () => {
        let obj = {
            dealId: dealId
        }
        const result = await axiosauth.post('/deals/day-deals-one', obj)
        const res = JSON.parse(result.data)
        if (res.success) {
            setDeal({
                time: res.data.dealOfTheDayEndDate,
                discount: res.data.dealOfTheDayOffer,
                offerName: res.data.name
            })

            if (res.data.products && res.data.products.length !== 0) {
                setIsApply("specific-products");
                let plist = [];

                for (let a = 0; a < res.data.products.length; a++) {
                    let index;


                    for (let x = 0; x < product?.length; x++) {
                        if (product[x]._id === res.data.products[a]) {
                            index = x;
                        }
                    }
                    plist.push({
                        value: product[index]?._id,
                        label: product[index]?.catalogueName,
                    });
                }
                setproductuse(plist);
                setCheckboxloading(true)
            }
            if (res.data.category && res.data.category.length !== 0) {
                setIsApply("specific-category");
                let catlist = [];

                for (let a = 0; a < res.data.category.length; a++) {
                    let index;

                    for (let x = 0; x < cat?.length; x++) {
                        if (cat[x]._id === res.data.category[a]) {
                            index = x;
                        }
                    }
                    catlist.push({
                        value: cat[index]?._id,
                        label: cat[index]?.name,
                    });
                }
                setcategoryuse(catlist);
                setCheckboxloading(true)
            }
            if (res.data.sub1category && res.data.sub1category.length !== 0) {
                setIsApply("specific-sub-category");
                let subcatlist = [];

                for (let a = 0; a < res.data.sub1category.length; a++) {
                    let index;
                    for (let x = 0; x < subcat?.length; x++) {
                        if (subcat[x]._id === res.data.sub1category[a]) {
                            index = x;
                        }
                    }
                    subcatlist.push({
                        value: subcat[index]?._id,
                        label: subcat[index]?.name,
                    });
                }
                setSubcategoryuse(subcatlist);
                setCheckboxloading(true)
            }
            if (res.data.sub2category && res.data.sub2category.length !== 0) {
                setIsApply("specific-sub-sub-category");
                let subsubcatlist = [];
                for (let a = 0; a < res.data.sub2category.length; a++) {
                    let index;
                    for (let x = 0; x < subsubcat?.length; x++) {
                        if (subsubcat[x]._id === res.data.sub2category[a]) {
                            index = x;
                        }
                    }
                    subsubcatlist.push({
                        value: subsubcat[index]?._id,
                        label: subsubcat[index]?.name,
                    });
                }
                setSubsubcategoryuse(subsubcatlist);
                setCheckboxloading(true)
            }

        }

    }, [dealId, product, cat, subcat, subsubcat])

    const [deal, setDeal] = useState({
        offerName: '',
        time: "",
        discount: "",
    });


    const handleProduct = (selectedOption) => {
        setSelectProduct({ selectedOption });
        setproductuse(selectedOption);
    };

    const handleCategory = (selectedOption) => {
        setSelectCategory({ selectedOption });
        setcategoryuse(selectedOption);
    };
    const handleSUbCategory = (selectedOption) => {
        setSelectSubCategory({ selectedOption });
        setSubcategoryuse(selectedOption);
    };

    const handleSubsubCategory = (selectedOption) => {
        setSelectSubsubCategory({ selectedOption });
        setSubsubcategoryuse(selectedOption);
    };


    const handleChange = (name) => (event) => {
        setDeal({ ...deal, error: false, [name]: event.target.value });
    };


    const onSubmit = async () => {

        if (deal && deal.time === "") {
            alert("Please Enter Date");
            return 0;
        }
        if (deal && deal.discount > 100) {
            alert("Enter valid discount")
            return 0;
        }

        var product_list = [];
        var category_list = [];
        var subcategory_list = [];
        var subsubcategory_list = [];

        if (isApply === "specific-products") {
            for (var i = 0; i < selectProduct.selectedOption?.length; i++) {
                product_list.push(selectProduct.selectedOption[i].value);
            }
        }
        if (isApply === "specific-category") {
            for (var i = 0; i < selectCategory.selectedOption?.length; i++) {
                category_list.push(selectCategory.selectedOption[i].value);
            }
        }
        if (isApply === "specific-sub-category") {
            for (var i = 0; i < selectSubCategory.selectedOption?.length; i++) {
                subcategory_list.push(selectSubCategory.selectedOption[i].value);
            }
        }
        if (isApply === "specific-sub-sub-category") {
            for (var i = 0; i < selectSubsubCategory.selectedOption?.length; i++) {
                subsubcategory_list.push(selectSubsubCategory.selectedOption[i].value);
            }
        }

        let obj = {
            dealId: dealId,
            "name": deal.offerName,
            "dealOfTheDayOffer": deal.discount,
            "dealOfTheDayEndDate": deal.time,
            "shortDescription": "",
            collectionId :collId,
            ...(product_list.length > 0 && isApply === "specific-products" && { products: product_list }),
            ...(category_list.length > 0 && isApply === "specific-category" && { category: category_list }),
            ...(subcategory_list.length > 0 && isApply === "specific-sub-category" && { sub1category: subcategory_list }),
            ...(subsubcategory_list.length > 0 && isApply === "specific-sub-sub-category" && { sub2category: subsubcategory_list }),
        }
        const result = await axiosauth.post("/deals/update-day-deal", obj)
        const res = JSON.parse(result.data)
        if (res.success) {
            setShowLoader(false)
            setShow(true)
        } else {
            setNetworkError(true)
        }
    }

    const closeModal = () => {
        setRedirect(true);
    }
    const connectionError = () => {
        setNetworkError(false)
    }
    useEffect(() => {
        axiosInstance.post("gazariCollection/load-collection").then((response) => {
            let res = JSON.parse(response.data);
            if (res.success === true) {
                const collectionList = res.collections.length > 0 && res.collections.filter((coll) =>
                    (coll.products && coll.products.length > 0) || (coll.category && coll.category.length > 0) || (coll.sub1category && coll.sub1category.length > 0) || (coll.sub2category && coll.sub2category.length > 0))
                setCollection(collectionList)
            }
        }).catch((erreor) => console.log(erreor))
    }, [])


    return (
        <Layout>
            <div
                className="left_arrow2 back-arrow my-3"
                style={{
                    display: "flex",
                    alignItems: "baseline",
                    fontFamily: "inherit",
                }}
            >
                <img
                    src={left_arrow2}
                    onClick={() => history.goBack()}
                    alt="left-arrow"
                />{" "}
                &nbsp;
                <h2>Update Deal Of The Day</h2>
            </div>
            <div className="sub-category-wrap">
                <div className="form-group form-input">
                    <label className="label-style ml-0" for="name">
                        Offer Name<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                        type="text"
                        name="offerName"
                        className={"form-control form-control-nonround"
                        }
                        id="offerName"
                        placeholder="Enter Offer Name"
                        value={deal.offerName}
                        onChange={handleChange("offerName")}
                    />
                </div>
                <div className="form-group form-input">
                    <label className="label-style ml-0" for="name">
                        Date & Time<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                        type="text"
                        name="name"
                        className={"form-control form-control-nonround"
                        }
                        id="name"
                        placeholder="Enter Time HH:MM:SS"
                        value={deal.time}
                        onChange={handleChange("time")}
                    />
                </div>
                <div className="form-group form-input">
                    <label className="label-style ml-0" for="name">
                        Discount<span style={{ color: "red" }}>* </span>
                    </label>
                    <input
                        type="number"
                        name="name"
                        className={
                            "form-control form-control-nonround"
                        }
                        id="name"
                        placeholder="Enter Discount"
                        value={deal.discount}
                        min="0"
                        onChange={handleChange("discount")}
                    />
                </div>

                <div className="form-group form-input">
                    <label className="label-style ml-0" for="name">
                        Collections
                    </label>
                    <select className="form-select form-control" aria-label="Default select example"
                        defaultValue=""
                        onChange={(e) => {
                            console.log(e.target.value)
                            setCollId(e.target.value)
                        }}
                    >
                        <option value="" disabled >Select page</option>
                        {collection && collection.length > 0 && collection.map((coll) => (
                            <option value={coll._id}  >{coll.title}</option>
                        ))}
                    </select>
                </div>

                {checkboxLoading === true &&
                    <div className="container mt-5">
                        <div className="radio-wrap ">
                            <h4 className="mb-3">
                                {" "}
                                Applied to <b></b>
                            </h4>

                            <div className="alignment-justify">
                                <aside>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="all-Products"
                                        checked={isApply === "all-Products" ? true : false}
                                        onClick={() => setIsApply("all-Products")}
                                    />
                                    <span>No Select</span>
                                </aside>
                                <aside>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="specific-products"
                                        checked={isApply === "specific-products" ? true : false}
                                        onClick={() => setIsApply("specific-products")}
                                    />
                                    <span>Specific Products</span>
                                </aside>
                                <aside>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="specific-category"
                                        checked={isApply === "specific-category" ? true : false}
                                        onClick={() => setIsApply("specific-category")}
                                    />
                                    <span>Specific Category</span>
                                </aside>
                                <aside>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="specific-sub-category"
                                        checked={isApply === "specific-sub-category" ? true : false}
                                        onClick={() => setIsApply("specific-sub-category")}
                                    />
                                    <span>Specific Sub Category</span>
                                </aside>
                                <aside>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="specific-sub-sub-category"
                                        checked={isApply === "specific-sub-sub-category" ? true : false}
                                        onClick={() => setIsApply("specific-sub-sub-category")}
                                    />
                                    <span>Specific Sub SUb Category</span>
                                </aside>

                            </div>
                        </div>
                        <div className='mb-5'>
                            {isApply === "specific-products" ? (
                                <div id="select-tag">
                                    <p></p>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        value={productuse}
                                        options={productOptions}
                                        onChange={handleProduct}
                                    />
                                </div>
                            ) : null}

                            {isApply === "specific-category" ? (
                                <div id="select-tag">
                                    <p></p>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        value={categoryuse}
                                        options={categoryOptions}
                                        onChange={handleCategory}
                                    />
                                </div>
                            ) : null}

                            {isApply === "specific-sub-category" ? (
                                <div id="select-tag">
                                    <p></p>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        value={subcategoryuse}
                                        options={subCategoryOptions}
                                        onChange={handleSUbCategory}
                                    />
                                </div>
                            ) : null}

                            {isApply === "specific-sub-sub-category" ? (
                                <div id="select-tag">
                                    <p></p>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        value={subsubcategoryuse}
                                        options={subsubcategoryOptions}
                                        onChange={handleSubsubCategory}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                }

            </div>
            <div className="button">
                <button
                    onClick={onSubmit}
                >Save</button>
            </div>

            {redirect ? <Redirect to="/deal-of-the-management" /> : null}
            {/* {back ? <Redirect to="/brand?page=1" /> : null} */}
            {show === true ? <SuccessModal message="Deal Updated !!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null}

        </Layout>
    )
}

export default UpdateDealofTheDay