import React from 'react';
// import loading from '../../assets/icons/loading.gif';
import Loader from 'react-loader-spinner';

const LoaderModal = () => {

  return (
    <div className="loader-modal-bg">
      <div className="loader-modal-success">
      
      <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />

       {/* <img src={loading} alt="loading" /> */}
      </div>
    </div>
  )
}

export default LoaderModal
