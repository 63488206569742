import React, { useEffect, useState } from "react";
import SuccessModal from "../../components/halper/SuccessModal";
import axiosauth from "../../axios-config-auth";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import Swal from "sweetalert2";
import LoaderModal from "../../components/halper/LoaderModal";
import ConfirmationModal from "../../components/halper/ConfirmationModal";
import { handleToast } from "../../utils/customFunction";
import {ReactComponent as DownloadIcon} from "../../assets/icons/DownloadArrow.svg"
import './order copy.scss';

const ItemDetailView = ({
  collections,
  total,
  orderId,
  loadProductById,
  payment,
  shipaddress,
  billaddress,
  createdBy,
}) => {
  const [show, setShow] = useState(false);
  const [link, setLink] = useState(null);
  const [downloadButton, setDownloadButton] = useState(false);
  const [shiped, setShiped] = useState();
  const [loader, setLoader] = useState(false);
  const [cancelOrder, setCancelOrder] = useState({});
  const [alreadySent, setAlreadySent] = useState([]);
  const [isSendToShip, setIsSendToShip] = useState({
    itemId:'',
    isSend:false
  });

  const { errorMsg, successMsg } = handleToast();

  const closeModal = () => {
    setShow(false);
  };

  // console.log("billaddress====>>",billaddress)
  // console.log("shipaddress====>>",shipaddress)

  // const handleChange1 = (e , product) => {
  //   let objKey = (e === "inProgress" ? "isShipped" : e);

  //   if (objKey === "Status") {
  //     return 0;
  //   }
  //   confirmAlert({
  //     // title: 'Change Status',
  //     message: 'Are you sure, you want to change the status of order ?',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => {
  //           let obj = {
  //             "orderId": orderId,
  //             "productId":product.productId,
  //             [objKey]:(e === "isDelivered" ? true : e === "isShipped"? true:e === "inProgress" ? false : false),
  //           }

  //           axiosauth.post('/order/update-order-product', obj)
  //             .then(async (response) => {
  //               let res = JSON.parse(response.data)
  //               if (res.success === true) {
  //                 loadProductById();
  //                 setShow(true);
  //                 // setOrderId("")
  //                 // setLoadProduct("yes updated")
  //               } else {
  //               }
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             })

  //         }
  //       },
  //       {
  //         label: 'No',
  //         onClick: () => {
  //           console.log("not detele")
  //         }
  //       }
  //     ]
  //   });

  // }

  const downloadInvoice = (orderId, prodId) => {
    let obj = {
      orderId,
      prodId,
    };
    try {
      setLoader(true);
      axiosauth
        .post("/order/order-invoice", obj)
        .then((response) => {
          console.log(
            "download invalice response ---",
            JSON.parse(response.data)
          );
          let res = JSON.parse(response.data);
          if (res.invoiceLink) {
            setLink(res.invoiceLink);
            setDownloadButton(true);
            setLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const ShippedRocket = async (order) => {
    let existUser = localStorage.getItem("shiprocket");

    if (!existUser) {
      setLoader(true);
      axiosauth
        .post("/login/shiprocket-token")
        .then((response) => {
          let res = JSON.parse(response.data);
          localStorage.setItem("shiprocket", res.data.token);
          createOrder(order);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          const data = error?.response?.data?.errors;
          let msg = "";
          if (data && Object.keys(data).length) {
            Object.keys(data).forEach((item) => {
              msg += `${data[item][0]}. `;
            });
          }
          errorMsg(`${error?.response?.data?.message} ${msg}`);

          Swal.fire({
            title: "Shiped Status",
            text: `Login failed.`,
            // text: `$`,
            icon: "warning",
            // showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#0CDA98",
            dangerMode: true,
            cancelButtonColor: "#DD6B55",
            // cancelButtonText: "No",
          }).then(async (isConfirm) => {
            if (isConfirm.isConfirmed) {
            }
          });
        });
    } else {
      createOrder(order);
    }
  };
  const createOrder = async (order) => {
    let arr = [
      {
        name: order.productId.productCatalogue?.name,
        sku: order.productId.sku,
        units: order.quantity * order.productId.netQuantity,
        selling_price: order.price,
        // "discount": (order.products[i].productId?.discount).toFixed(2),
        tax: order.tax,
        // "hsn": order.products[i].productId?.hsnCode
      },
    ];

    let obj = {
      order_id: order?.prodOrderId,
      order_date:
        (order?.addedOn).substring(0, 10) +
        " " +
        order.addedOn.substring(11, 16),
      pickup_location: "Primary",
      // "comment": "Reseller: M/s Goku",
      billing_customer_name: createdBy?.firstName,
      // "billing_customer_name": billaddress?.firstName,
      // "billing_last_name": order.address[0]?.lastName,
      billing_last_name: createdBy?.lastName || "", // from backend
      // "billing_last_name": "xyzzzz", // from backend
      billing_address: billaddress?.landmark,
      billing_address_2: billaddress?.landmark,
      billing_city: billaddress?.city,
      billing_pincode: billaddress?.pincode,
      billing_state: billaddress?.state?.stateName,
      billing_country: "India",
      billing_email: createdBy?.userEmail, // Customers billings email
      // "billing_email": "naruto@uzumaki.com", // Customers billings email
      billing_phone: billaddress?.mobile,

      // "shipping_is_billing" : shipaddress !==null ? false: true,
      shipping_is_billing: true,

      shipping_customer_name: shipaddress?.firstName,
      // "shipping_last_name": order.address[1].lastName,
      // "shipping_last_name": order.address[1]?.firstName,
      shipping_address: shipaddress?.landmark,
      // "shipping_address_2":shipaddress.landmark,
      shipping_city: shipaddress?.city,
      shipping_pincode: shipaddress?.pincode,
      shipping_country: "India",
      shipping_state: shipaddress?.state?.stateName,
      // "shipping_email": "dailly@daily.com",
      // "shipping_phone": order.address[1]?.mobile,
      shipping_phone: shipaddress?.mobile,
      order_items: arr,
      payment_method: "Prepaid",
      // "payment_method": payment,
      // "shipping_charges": 0,
      // "giftwrap_charges": 0,
      // "transaction_charges": 0,
      // "total_discount": 0,
      sub_total: order?.totalAmount,
      length: Number(
        order?.productId?.productCatalogue?.productDimension?.length
      ),
      // "length": 10, // from backend
      breadth: Number(
        order?.productId?.productCatalogue?.productDimension?.breadth
      ),
      // "breadth": 15, // from backend
      height: Number(
        order?.productId?.productCatalogue?.productDimension?.height
      ),
      // "height": 20, // from backend
      weight: Number(order?.productId?.productCatalogue?.itemWeight) / 1000,
      // "weight": 2 // from backend
    };

    let existUser = localStorage.getItem("shiprocket");

    const userAxios = axios.create({
      headers: {
        Authorization: `Bearer ${existUser}`,
        "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    userAxios
      .post("https://apiv2.shiprocket.in/v1/external/orders/create/adhoc", obj)
      .then((response) => {
        setShiped(response.data);
        if (response.data?.shipment_id) {
          let obj2 = {
            shipment_id: response.data?.shipment_id,
            courier_id: "",
            status: "",
          };
          userAxios
            .post(
              "https://apiv2.shiprocket.in/v1/external/courier/assign/awb",
              obj2
            )
            .then((response) => {
              if (response.data?.response?.data) {
                updateOrder(response.data?.response?.data, shiped, order);
              } else {
                SuccessMessageFailed(response.data.message);
              }
            })
            .catch((error) => {
              const data = error?.response?.data?.errors;
              let msg = "";
              if (data && Object.keys(data).length) {
                Object.keys(data).forEach((item) => {
                  msg += `${data[item][0]}. `;
                });
              }
              errorMsg(`${error?.response?.data?.message} ${msg}`);
            });
        } else {
          SuccessMessageFailed(response.data.message);
        }
      })
      .catch((error) => {
        const data = error?.response?.data?.errors;
        let msg = "";
        if (data && Object.keys(data).length) {
          Object.keys(data).forEach((item) => {
            msg += `${data[item][0]}. `;
          });
        }
        errorMsg(`${error?.response?.data?.message} \n ${msg}`);
      });
  };
  const updateOrder = (awbdata, createOrder, order) => {
    let obj = {
      shipRocketOrderId: awbdata?.order_id,
      shipRocketShipmentId: awbdata?.shipment_id,
      shipRocketAWBcode: awbdata?.awb_code,
      prodOrderId: order.prodOrderId,
      orderId: orderId,
    };
    axiosauth
      .post("/order/update-products-orders", obj)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          SuccessMessage(res.data);
          loadProductById();
        } else {
          SuccessMessageFailed();
        }
      })
      .catch((error) => {
        SuccessMessageFailed();
      });
  };
  const SuccessMessage = (data) => {
    Swal.fire({
      title: "Shiped Status",
      // text: `Order Id - ${shiped.order_id} Shipment-Id - ${shiped.shipment_id}`,
      text: `Order Shiped Successfully`,
      icon: "success",
      confirmButtonText: "OK",
      confirmButtonColor: "#0CDA98",
      cancelButtonColor: "#DD6B55",
    }).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
      }
    });
  };

  const SuccessMessageFailed = (data) => {
    Swal.fire({
      title: "Shiped Status",
      text: data,
      // text: `$`,
      icon: "warning",
      // showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
      cancelButtonColor: "#DD6B55",
      // cancelButtonText: "No",
    }).then(async (isConfirm) => {
      if (isConfirm.isConfirmed) {
      }
    });
  };
  const checkStatus = (data) => {
    let existUser = localStorage.getItem("shiprocket");

    if (!existUser) {
      axiosauth
        .post("/login/shiprocket-token")
        .then((response) => {
          let res = JSON.parse(response.data);
          localStorage.setItem("shiprocket", res.data.token);
          ShipTrackingAPI(data);
        })
        .catch((error) => {
          Swal.fire({
            title: "Shiped Status",
            text: `Login Failed !!`,
            // text: `$`,
            icon: "warning",
            // showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#0CDA98",
            dangerMode: true,
            cancelButtonColor: "#DD6B55",
            // cancelButtonText: "No",
          }).then(async (isConfirm) => {
            if (isConfirm.isConfirmed) {
            }
          });
        });
    } else {
      ShipTrackingAPI(data);
    }
  };

  const ShipTrackingAPI = (data) => {
    let existUser = localStorage.getItem("shiprocket");

    const userAxios = axios.create({
      headers: {
        Authorization: `Bearer ${existUser}`,
        "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    userAxios
      .get(
        `https://apiv2.shiprocket.in/v1/external/courier/track/awb/${data.shipRocketAWBcode}`
      )
      .then((response) => {
        if (response.data) {
          Swal.fire({
            title: "Tracking Status",
            text: `${response.data.tracking_data.error}`,
            icon: "warning",
            // showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#0CDA98",
            dangerMode: true,
            cancelButtonColor: "#DD6B55",
            // cancelButtonText: "No",
          }).then(async (isConfirm) => {
            if (isConfirm.isConfirmed) {
            }
          });
        }
      })
      .catch((error) => {
        SuccessMessageFailed(error);
      });
  };

  const handleCancel = (orderId, productId, title, content, type) => {
    setCancelOrder({ orderId, productId, title, content, type });
  };

  const HandleClose = () => {
    if(isSendToShip.isSend) setIsSendToShip({...isSendToShip,itemId:'',isSend:false})
    else setCancelOrder({});
  };

  const cancelProduct = () => {
    if (cancelOrder?.type === "Cancel Modal") {
      const obj = {
        orderId: cancelOrder?.orderId,
        prodId: cancelOrder?.productId,
      };
      setLoader(true);
      axiosauth
        .post("/order/cancel-order", obj)
        .then((response) => {
          const res = JSON.parse(response?.data);
          if (res?.code === 200 && res?.success) {
            setCancelOrder({});
            successMsg(res?.message);
            loadProductById();
          } else {
            errorMsg("Something went wrong");
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          errorMsg(err?.message);
          console.log("error", err);
        });
    } else {
      const obj = {
        "orderId":cancelOrder?.orderId,
        "prodId":cancelOrder?.productId,
        "isRefunded":true
      }
      setLoader(true);
      axiosauth
        .post("/transaction/refund-payment", obj)
        .then((response) => {
          const res = JSON.parse(response?.data);
          if (res?.code === 200 && res?.success) {
            setCancelOrder({});
            successMsg(res?.message);
            loadProductById();
          } else {
            errorMsg(res?.message);
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          errorMsg(err?.message);
        });
    }
  };

  const readyToShip = () => {
    let obj = {
      prodId: isSendToShip.itemId,
    };
    setIsSendToShip({...isSendToShip,isSend:false})
    setLoader(true);
    axiosauth
      .post("/order/ready-to-ship", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res?.success) {
          const alreadySentClone = [...alreadySent];
          alreadySentClone.push(res?.data);
          setAlreadySent(alreadySentClone);
          successMsg(res?.message);
          setLoader(false);
          setIsSendToShip({...isSendToShip,itemId:'',isSend:false})
        } else {
          setLoader(false);
          errorMsg(JSON.parse(response?.data)?.message);
          setIsSendToShip({...isSendToShip,itemId:'',isSend:false})
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        errorMsg(error);
      });
  };

  const manageAlreadySent = (productId) => {
    const data = alreadySent.find((item) => item.prodId === productId);
    if (data) return true;
    else return false;
  };

  return (
    <>
      {downloadButton === true ? (
        <div className="excel-bg">
          <div className="excel-success">
            <span>
              Download Invoice
            </span>
            <span className="cross-icon" onClick={()=>setDownloadButton(false)}>X</span>
            <br />
            <hr />
            <div className="button-box">
            <a
              href={link}
              onClick={() => {
                setDownloadButton(false);
                //  console.log("download")
              }}
              target="_blank"
              rel="noopener noreferrer"
              className="exportLink download-button"
            >
              Download <DownloadIcon />
            </a>
            </div>
          </div>
        </div>
      ) : null}
      <div className="w-100">
        {/* <div className="card item-detail"> */}
        <div className="table-responsive">
          <table className="table orderDetail-table">
            <thead>
              <tr>
                <th scope="col" className="text-nowrap">
                  {/* Order Id */}
                  Product Order ID
                </th>
                <th scope="col" className="text-nowrap">
                  Product Id
                </th>
                <th scope="col" className="text-nowrap">
                  Image
                </th>
                <th
                  scope="col"
                  className="text-nowrap"
                  // style={{ minWidth: "257px" }}
                >
                  Items Name
                </th>
                {/* <th scope="col" className="text-nowrap">
                  HSN Code
                </th> */}
                <th scope="col" className="text-nowrap">
                  Quantity
                </th>
                <th scope="col" className="text-nowrap">
                  GST%
                </th>
                <th scope="col" className="text-nowrap">
                  Subtotal Price
                </th>
                <th scope="col" className="text-nowrap">
                  Total Inclusive Tax
                </th>

                <th scope="col">Total</th>
                <th scope="col" className="text-center text-nowrap">
                  Status
                </th>
                <th scope="col" className=" text-nowrap">
                  Download Invoice
                </th>
                <th scope="col">Product Status</th>
                <th scope="col">Cancel</th>
                <th scope="col">Refund</th>
                {/* <th scope="col" className=" text-nowrap">
                  Download shipping
                </th> */}
              </tr>
            </thead>
            {/* {console.log("collection-->>" , collections)} */}
            <tbody>
              {collections && collections.length > 0
                ? collections.map((value, index) => {
                    const handleChange = (e) => {
                      let objKey =
                        e.target.value === "inProgress"
                          ? "isShipped"
                          : e.target.value;

                      if (objKey === "Status") {
                        return 0;
                      }
                      confirmAlert({
                        // title: 'Change Status',
                        message:
                          "Are you sure, you want to change the status of order ?",
                        buttons: [
                          {
                            label: "Yes",
                            onClick: () => {
                              let obj = {
                                orderId: orderId,
                                productId: value.productId._id,
                                [objKey]:
                                  e.target.value === "isDelivered"
                                    ? true
                                    : e.target.value === "isShipped"
                                    ? true
                                    : e.target.value === "inProgress"
                                    ? false
                                    : false,
                              };

                              axiosauth
                                .post("/order/update-order-product", obj)
                                .then(async (response) => {
                                  let res = JSON.parse(response.data);
                                  if (res.success === true) {
                                    loadProductById();
                                    setShow(true);
                                  } else {
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            },
                          },
                          {
                            label: "No",
                            onClick: () => {
                              loadProductById();
                              window.location.reload();
                            },
                          },
                        ],
                      });
                    };
                    return (
                      <>
                        <tr>
                          <td>{value?.prodOrderId}</td>
                          {/* <td>{orderId}</td>   */}
                          <td>{value?.prodId}</td>
                          <td>
                            {/* {console.log("value------->>>" , value.productId.productCatalogue)} */}
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={
                                value.productId.productCatalogue.imageUrls[0]?.url
                              }
                            />
                          </td>

                          <td>{value?.productId?.productCatalogue?.name}</td>

                          {/* <td>{value.productId.hsnCode}</td> */}
                          <td>{value?.quantity}</td>
                          <td>{value.gst}</td>
                          {/* <td>{value.tax}</td> */}
                          <td>₹{value?.price?.toFixed(2)}</td>
                          <td>₹{value?.tax?.toFixed(2)}</td>
                          {/* <td>₹{value?.totalTaxAmount?.toFixed(2)}</td> */}

                          {/* <td>{(value.price + value.tax).toFixed(2)}</td> */}
                          <td>₹{value.totalAmount?.toFixed(2)}</td>

                          <td className="text-nowrap px-2">
                            {
                              value &&
                              value?.shipRocketAWBcode &&
                              !value?.isCancelled ? (
                                <button
                                  className="btn btn-primary"
                                  // onClick={() => checkStatus(value)}
                                >
                                  {/* Track Order */}
                                  {value?.productOrderStatus}
                                </button>
                              ) : (
                                <button
                                  className={`${
                                    value?.isCancelled || value?.isRefunded
                                      ? "btn btn-secondary"
                                      : (value?.shipRocketOrderId &&
                                          value?.shipRocketShipmentId) ||
                                        manageAlreadySent(value?.prodId)
                                      ? "btn m-no-btn"
                                      : "btn btn-success"
                                  }`}
                                  disabled={
                                    value?.isCancelled || value?.isRefunded ||
                                    (value?.shipRocketOrderId &&
                                      value?.shipRocketShipmentId) ||
                                    manageAlreadySent(value?.prodId)
                                  }
                                  onClick={() => {
                                    setIsSendToShip({...isSendToShip,itemId:value?.prodId,isSend:true});
                                    // setIsSendToShip(value?.prodId);
                                  }}
                                  // onClick={() => {
                                  //   readyToShip(value?.prodId);
                                  // }}
                                  // onClick={() => { ShippedRocket(value) }}
                                >
                                  {(value?.shipRocketOrderId &&
                                    value?.shipRocketShipmentId) ||
                                  manageAlreadySent(value?.prodId)
                                    ? "Sent"
                                    : "Send to Ship"}
                                </button>
                              )
                              // >Ready to Ship</button>
                            }
                          </td>

                          {/* <td>
                          {value.isCancelled !== true ? (
                            <div className="statusSelectSingle">
                              <select
                                onChange={handleChange}
                                style={{ paddingLeft: '0px', width:"130px" }} 
                              >
                                <option value="" selected disabled hidden>
                                  {value.isDelivered === true
                                    ? "Delivered"
                                    : value.isShipped === true
                                    ? "Shipped"
                                    : "In Progress"}
                                </option>
                                <option
                                  value="inProgress"
                                  disabled={
                                    value.isShipped === true ||
                                    value.isShipped === false
                                  }
                                  hidden={
                                    value.isShipped === true ||
                                    value.isShipped === false
                                  }
                                >
                                  In Progress
                                </option>
                                <option
                                  value="isShipped"
                                  disabled={value.isShipped === true}
                                  hidden={value.isShipped === true}
                                >
                                  Shipped
                                </option>
                                <option
                                  value="isDelivered"
                                  disabled={
                                    value.isDelivered === false
                                      ? value.isShipped === true
                                        ? false
                                        : true
                                      : true
                                  }
                                  hidden={value.isDelivered === true}
                                >
                                  Delivered
                                </option>
                              </select>
                            </div>
                          ) : (
                            <h6 style={{ color: "#F42B0C" }}>Cancelled</h6>
                          )}
                        </td> */}

                          <td>
                            <button
                              className="btn btn-success"
                              onClick={() => {
                                downloadInvoice(orderId, value.prodId);
                              }}
                            >
                              Download
                            </button>
                          </td>
                          <td>
                            {value.isCancelled === true ? (
                              <span className="isCancelled">Cancelled</span>
                            ) : value.isDelivered === true ? (
                              <span className="isDelivered">Delivered</span>
                            ) : value.isShipped === true ? (
                              <span className="isShipped">Shipped</span>
                            ) : (
                              <span className="isProgress">In Progress</span>
                            )}
                          </td>
                          <td>
                            <button
                              className={`${
                                value?.isCancelled || value?.isRefunded
                                  ? "btn btn-secondary"
                                  : "btn btn-success"
                              }`}
                              disabled={value?.isCancelled || value?.isRefunded}
                              onClick={() => {
                                handleCancel(
                                  orderId,
                                  value.prodId,
                                  "Are you sure?",
                                  "Are you sure you want to cancel the product?",
                                  "Cancel Modal"
                                );
                              }}
                            >
                              {value?.isCancelled ? "Cancelled" : "Cancel"}
                            </button>
                          </td>
                          <td>
                            <button
                              //  className='btn btn-secondary'
                              className={`${
                                value?.isCancelled || value?.isRefunded
                                  ? "btn btn-secondary"
                                  : "btn m-no-btn"
                              }`}
                              disabled={value?.isCancelled || value?.isRefunded}
                              onClick={() =>
                                handleCancel(
                                  orderId,
                                  value?.prodId,
                                  "Are you sure?",
                                  "Order transaction amount has been refunded back to customer's payment source account?",
                                  "Refund Modal"
                                )
                              }
                            >
                            {value?.isRefunded ? 'Refunded' : 'Refund'} 
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <div className="row m-3">
          <div className="col-6 text-weight-bold text-dark ">
            <h6 className="heading">Total</h6>
          </div>
          <div className="col-6 text-right">
            <h6 className="heading"> ₹{total?.toFixed(2)}</h6>
          </div>
        </div>

        <div className="row m-3">
          <div className="col-6">
            <h6 className="heading">Payment Method</h6>
          </div>
          <div className="col-6 text-right text-uppercase">
            <h6 className="heading">{payment}</h6>
          </div>
        </div>
      </div>

      {show === true ? (
        <SuccessModal message="Order Status Changed!" onClick={closeModal} />
      ) : null}
      {loader ? <LoaderModal /> : null}

      {Object.keys(cancelOrder).length ? (
        <ConfirmationModal
          title={cancelOrder?.title}
          content={cancelOrder?.content}
          HandleClose={HandleClose}
          performAction={cancelProduct}
        />
      ) : null}
      {isSendToShip.isSend ? (
        <ConfirmationModal
          title='Are you sure?'
          content='Are you sure you want to send this product for Shipping?'
          HandleClose={HandleClose}
          performAction={readyToShip}
        />
      ) : null}
    </>
  );
};

export default ItemDetailView;
