import React from 'react';
import Layout from "../../layout/layout"
import CouponList from '../../wrapper/coupon/couponList';

const CouponManage = () => {
  // console.log("token:", localStorage.getItem("accesstoken"))
  // console.log("userData:", localStorage.getItem("userData"))
  return (
    <Layout>
      <>

        <CouponList />
      </>
    </Layout>
  )
}

export default CouponManage