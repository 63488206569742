import React, { useEffect, useState } from "react";
// import SearchBox from "../../components/halper/SearchBox";
// import deleteIcon from "../../assets/icons/delete.png";
import editIcon from "../../assets/icons/edit.png";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchVariantPage,
  // fetchVariantSearch,
  deleteVariantValueById,
} from "../../redux/actions/variantAction";
import Pagination from "react-js-pagination";
import NoData from "../../components/halper/NoData";
import { confirmAlert } from "react-confirm-alert";
import Loader from "react-loader-spinner";

const ManageVariant = () => {
  // For page count
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get("page");

  const [activePage, setActivePage] = useState(1);

  const dispatch = useDispatch();
  const varData = useSelector((state) => state.variantData.data);
  const loading = useSelector((state) => state.variantData?.loading);
  


  useEffect(() => { 

    if (activePage === 1) {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set("page", pageCount ? pageCount : 1);
      history.push(
        window.location.pathname + "?" + currentUrlParams.toString()
      );
    }

    if (pageCount !== null) {
      dispatch(fetchVariantPage(pageCount));
    } else {
      dispatch(fetchVariantPage(1));
    }
  }, [dispatch, history, pageCount, activePage]);

  /**
   * TODO: Pagination
   *@version 1.0.0
   * @author [Unknown]
   */

  // Handle Pagination

  const [locationKeys, setLocationKeys] = useState([]);

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1); 
    var vars = query.split("&"); 
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("="); 
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
      // console.log("history.action",history.action)

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          //  console.log("forward")
          // console.log("js",getQueryVariable('page'))
          handlePageChange1(getQueryVariable("page"));
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // console.log("backward",location.key)
          // console.log("js",getQueryVariable('page'))
          handlePageChange1(getQueryVariable("page"));
        }
      }
    });
  }, [locationKeys, history]);

  const handlePageChange1 = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handlePageChange = (pageNumber) => {
    // console.log("dd",pageNumber)
    dispatch(fetchVariantPage(pageNumber));
    setActivePage(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  const deleteVariant = (id) => {
    let obj = {
      variantId: id,
    };
    console.log("obj:", obj);
    confirmAlert({
      // title: 'Change Status',
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // console.log("variant user")
            dispatch(deleteVariantValueById(obj));
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("not detele");
          },
        },
      ],
    });
  };

  return (
    <>
      <div className="button">
        <Link to="/add-variant-name">
          <button>Add Variant</button>
        </Link>
      </div>
{loading ===true ? 
      <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={5000} //3 secs
          />
        </div>
       : <>
      {!varData.error ? (
        <div className="table-wrap  overflow-auto table-responsive card py-4 " style={{height:"500px"  }}>
          <table>
            {varData.data && varData.data.length > 0 ? (
              <thead>
                <tr>
                  <th className="text-nowrap px-2" style={{ textAlign: "left" }}>Sr. No.</th>
                  <th className="text-nowrap px-2" style={{ textAlign: "left" }}>Variant Name</th>
                  <th className="text-nowrap px-2">Variant Value</th>
                  <th className="text-nowrap px-2">Manage Variant Name</th>
                  <th className="text-nowrap px-2">View</th>
                  <th className="text-nowrap px-2"> Add Variant Value</th>

                  <th className="text-nowrap px-2">Manage Variant Value </th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {varData.data && varData.data.length > 0 ? (
                <>
                  {varData.data.map((variant, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "left" }}>
                        {((pageCount ? pageCount : 1) - 1) * 10 + (index + 1)}
                      </td>
                      <td className="titleStyle" style={{ textAlign: "left" }}>
                        {variant.parent.variantName}
                      </td>
                      <td>
                        {variant.values && variant.values.length > 0
                          ? variant.values.map((val, index, list) => {
                            return (
                              <span key={index}>
                                {val.value}
                                {index < list.length - 1 ? ", " : null}{" "}
                              </span>
                            );
                          })
                          : null}
                      </td>
                      <td>
                        <Link to={`/variant-name-manage/${variant.parent.variantId}`}>Manage</Link>
                      </td>
                      <td>
                        <Link to={`/view-variant-value/${variant.parent.variantId}`} >
                          {/* <img src={eyeIcon} alt="eye-button" className="eye-icon" /> */}
                          <i class="fa fa-eye fa-eye-style"></i>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/add-variant/${variant.parent.variantId}`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-plus-lg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                            />
                          </svg>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/edit-variant/${variant.parent.variantId}`}>
                          {" "}
                          <img
                            src={editIcon}
                            alt=""
                            className="edit-icon"
                          />{" "}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <NoData message="No Variant Found" />
              )}
            </tbody>
          </table>

         
        </div>
      ) : (
        <div className="network-error">{varData.error}</div>
      )}
      </>
      
}
<div className="pagination-custom">
            <Pagination
              hideDisabled
              activePage={parseInt(pageCount)}
              itemsCountPerPage={10}
              totalItemsCount={varData.total}
              pageRangeDisplayed={5}
              hideNavigation
              onChange={handlePageChange}
            />
          </div>
    </>
  );
};

export default ManageVariant;
