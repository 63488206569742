import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerInput from "../../components/halper/DatePickerInput";
import { useSelector, useDispatch } from "react-redux";
import { loadCategory } from "../../redux/actions/categoryAction";
import { loadProduct } from "../../redux/actions/productAction";
import { createCoupon, fetchCoupon } from "../../redux/actions/couponAction";
import { Redirect, useHistory } from "react-router-dom";
import SuccessModal from "../../components/halper/SuccessModal";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import axiosauth from "../../axios-config-auth";
import LoaderModal from "../../components/halper/LoaderModal";

const AddCoupon = () => {
  const [isDiscount, setIsDiscount] = useState("percentage");
  const [isApply, setIsApply] = useState("all-Products");
  const [startDate, setStartDate] = useState(new Date());
  var myDate = new Date();
  myDate.setDate(myDate.getDate() + 1);
  const [expiryDate, setExpiryDate] = useState(myDate);
  const [selectProduct, setSelectProduct] = useState([]);

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState([]);
  const [selectSubSubCategory, setSelectSubSubCategory] = useState([]);
  const [selectCollection, setSelectCollection] = useState([]);

  const [redirect, setRedirect] = useState(false);
  const [fieldHide, setFieldHide] = useState(true);
  const [show, setShow] = useState(false);

  const [sub_cat_List, setSub_cat_List] = useState([]);
  const [sub_sub_List, setSub_sub_List] = useState([]);

  const [collection, setCollection] = useState()
  const [loader, setLoader] = useState(false);
  const [plist, setPlist] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const cat = useSelector((state) => state.categoryData.data.categories);
  // const productData = useSelector((state) => state.productData);
  const coupons = useSelector((state) => state.couponData.data);
  const animatedComponents = makeAnimated();
  const [error, setError] = useState("");

  // console.log("selectCollection---->>" , selectCollection)



  const cat_list = [];
  if (cat) {
    for (var k = 0; k < cat.length; k++) {
      cat_list.push({ value: cat[k]._id, label: cat[k].name });
    }
  }

  const [coupon, setCoupon] = useState({
    name: "",
    discount: 0,
    minimumAmount: "",
    maxDiscount: "",
    couponLimit: "",
    oncePerUse: false,
    voucherType: "",
  });
  const {
    name,
    discount,
    minimumAmount,
    maxDiscount,
    couponLimit,
    oncePerUse,
  } = coupon;

  const handleProduct = (selectedOption) => {
    setSelectProduct({ selectedOption });
  };

  const handleCategory = (selectedOption) => {
    setSelectCategory({ selectedOption });
  };

  const handleSubCategory = (selectedOption) => {
    setSelectSubCategory({ selectedOption });
  };

  const handleSubSubCategory = (selectedOption) => {
    setSelectSubSubCategory({ selectedOption });
  };

  const handleCollection = (selectedOption) => {
    setSelectCollection({ selectedOption });
  };

  const handleChange = (e) => {
    if (e.target.name === "name") {
      setCoupon({
        ...coupon,
        error: false,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    } else
      setCoupon({
        ...coupon,
        error: false,
        [e.target.name]: e.target.value,
      });
    if (e.target.name === "oncePerUse" && e.target.value === "false") {
      setFieldHide(false);
      setShow(false);
    }
    if (e.target.name === "oncePerUse" && e.target.value === "true") {
      setFieldHide(true);
      setShow(true);
    }
  };

  const couponSubmit = (e) => {

    e.preventDefault();

    var date = new Date(startDate);
    var dateStart =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      date.getDate() +
      "-" +
      date.getFullYear();

    var date1 = new Date(expiryDate);
    var dateExpire =
      (date1.getMonth() > 8
        ? date1.getMonth() + 1
        : "0" + (date1.getMonth() + 1)) +
      "-" +
      date1.getDate() +
      "-" +
      date1.getFullYear();

    var product_list = [];
    var category_list = [];
    var sub_category_list = [];
    var sub_sub_category_list = [];

    if (isApply === "specific-products") {
      if (selectProduct.selectedOption) {
        for (var i = 0; i < selectProduct.selectedOption.length; i++) {
          product_list.push(selectProduct.selectedOption[i].value);
        }
      }
      else {
        setError("Please Select Product")
        setTimeout(() => {
          setError("")
        }, 2000)
        return 0;
        // console.log("enter product")
      }

    }

    if (isApply === "specific-category") {
      if (selectCategory.selectedOption) {
        for (var j = 0; j < selectCategory.selectedOption.length; j++) {
          category_list.push(selectCategory.selectedOption[j].value);
        }
      } else {
        setError("Please Select Category")
        setTimeout(() => {
          setError("")
        }, 2000)
        return 0;
      }

    }
    if (isApply === "specific-sub-category") {
      if (selectSubCategory.selectedOption) {
        for (var j = 0; j < selectSubCategory.selectedOption.length; j++) {
          sub_category_list.push(selectSubCategory.selectedOption[j].value);
        }
      } else {
        setError("Please Select Sub-Category");
        setTimeout(() => {
          setError("")
        }, 2000)

        return 0;
      }

    }
    if (isApply === "specific-sub-sub-category") {
      if (selectSubSubCategory.selectedOption) {
        for (var j = 0; j < selectSubSubCategory.selectedOption.length; j++) {
          sub_sub_category_list.push(
            selectSubSubCategory.selectedOption[j].value
          );
        }
      } else {
        setError("Please Select Sub-Sub-Category")
        setTimeout(() => {
          setError("")
        }, 2000)
        return 0;
      }

    }


    if (isApply === "specific-collection") {
      if (selectSubSubCategory.selectedOption) {
        for (var j = 0; j < selectSubSubCategory.selectedOption.length; j++) {
          sub_sub_category_list.push(
            selectSubSubCategory.selectedOption[j].value
          );
        }
      } else {
        setError("Please Select Collections")
        setTimeout(() => {
          setError("")
        }, 2000)
        return 0;
      }

    }

    let obj = {
      voucherTitle: name,
      voucherType: isDiscount,
      discount: discount,
      startDate: dateStart,
      expiryDate: dateExpire,
      expired: false,
      ...(maxDiscount && {maxDiscountLimit: maxDiscount}),
      ...(minimumAmount && {minShoppingValue: minimumAmount,}),
      usageLimit: fieldHide ? parseInt(couponLimit) : "",
      oncePerUse: oncePerUse,
      category: category_list,
      product: product_list,
      sub1: sub_category_list,
      sub2: sub_sub_category_list,
    };
    if (dateExpire < dateStart) {
      alert("Expiry Date Must be Greater than Start Date");
      return 0;
    }
    dispatch(createCoupon(obj));
  };

  useEffect(() => {
    // dispatch(loadProduct(setLoader));
    dispatch(loadCategory());
    // dispatch(fetchCoupon());
  }, [dispatch]);

  const closeModal = () => {
    setRedirect(true);
  };

  const getProductData = () =>{
    let obj = {
      "count": 0,
      // limit: 10,
      limit: 500,
      field: "max",
      isProduct : true ,
      suspend: "false",
      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
      filters: {
        "postedBy.ownerId": " "
      },
    };

    setLoader(true);
    axiosauth
      .post("/products/load-distinct-catalogue", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
          if(res?.data?.data?.length){
            let list = []
            res.data.data.forEach(item=>{
              list.push({
                value: item?._id,
                label: item?.name,
              })
            })
            setPlist(list)
          }else{
            setPlist([])
          }
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    let obj = {
      limit: 500,
      filters: {
        type: "sub1",
      },
    };
    axiosauth.post("/categories/load", obj).then((response) => {
      let res = JSON.parse(response.data);
      if (res.success === true) {
        let arr = [];
        for (var i = 0; i < res.data?.categories?.length; i++) {
          arr.push({
            value: res.data?.categories[i]._id,
            label: res.data?.categories[i].name,
          });
        }
        setSub_cat_List(arr);
      }
    });
    getProductData()
  }, []);


  useEffect(() => {
    let obj = {
      limit: 500,
      filters: {
        type: "sub2",
      },
    };
    axiosauth.post("/categories/load", obj).then((response) => {
      let res = JSON.parse(response.data);
      if (res.success === true) {
        let arr = [];
        for (var i = 0; i < res.data?.categories?.length; i++) {
          arr.push({
            value: res.data?.categories[i]._id,
            label: res.data?.categories[i].name,
          });
        }
        setSub_sub_List(arr);
      }
    });
  }, []);

  const onlyAlphaNumericAllow = (e) => {
    const re = /[a-zA-Z-0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };


  useEffect(() => {
    axiosauth.post("gazariCollection/load-collection").then((response) => {
      let res = JSON.parse(response.data);
      if (response.status === 200) {
        // console.log("load---------->>" , res.load)
        let arr =[]
        res.load.forEach((element) =>{
          // console.log(element)
          arr.push({
            value :element._id,
            label :element.title
          })
        })
        // console.log("Array--->>" , arr)
        setCollection(arr)
      }
    }).catch((erreor) => console.log(erreor))
  } , [])

  // console.log(selectCollection)

  return (
    <>
      <br />
      <br />
      <div className="d-flex justify-content-between">
        <div
          className="left_arrow2 back-arrow align-self-center mb-0"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Add Coupon</h2>
        </div>
        <div>
          <div className="d-flex ">
            <div className="button ml-0">
              <button onClick={() => history.goBack()}>Back</button>
            </div>
            <div className="button ml-2">
              <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={couponSubmit} className="form-wrap">
        <div className="form-body">
          <div className="single-field">
            <p>Coupon Name</p>
            <input
              type="text"
              placeholder="Enter Coupon Name"
              name="name"
              value={coupon.name}
              onChange={handleChange}
              onKeyPress={(e) => onlyAlphaNumericAllow(e)}
              required
            />
          </div>
          <div className="radio-wrap">
            <h4>
              {" "}
              Discount Type <b>*</b>
            </h4>
            <div>
              <aside>
                <input
                  type="radio"
                  name="voucherType"
                  value="percentage"
                  onChange={handleChange}
                  onClick={() => setIsDiscount("percentage")}
                  checked={isDiscount === "percentage" ? true : false}
                />
                <span>Percentage</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="voucherType"
                  value="fix-value"
                  onChange={handleChange}
                  onClick={() => setIsDiscount("fix-value")}
                />
                <span>Fixed Value</span>
              </aside>
            </div>
          </div>
          <section>
            <div className="two-field">
              <p>
                Discount<b>*</b>
              </p>
              <input
                type="number"
                placeholder={
                  isDiscount === "percentage"
                    ? "Enter % Discount"
                    : "Enter Fixed Discount"
                }
                name="discount"
                onChange={handleChange}
                required
              />
            </div>
            <div className="two-field">
              <p>Minimum Shopping Amount</p>
              <input
                type="number"
                placeholder="Minium Shopping Amount"
                name="minimumAmount"
                onChange={handleChange}
              />
            </div>
          </section>
          {isDiscount === "percentage" ? (
            <div className="single-field">
              <p>Maximum Discount</p>
              <input
                type="number"
                placeholder="Maxium Discount"
                name="maxDiscount"
                onChange={handleChange}
              />
            </div>
          ) : null}

          <div className="radio-wrap ">
            <h4>
              {" "}
              Applied to <b>*</b>
            </h4>
            <div className="alignment-justify">
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="all-Products"
                  checked={isApply === "all-Products" ? true : false}
                  onClick={() => setIsApply("all-Products")}
                />
                <span>All Products</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-products"
                  onClick={() => setIsApply("specific-products")}
                />
                <span>Specific Products</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-category"
                  onClick={() => setIsApply("specific-category")}
                />
                <span>Specific Category</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-category"
                  onClick={() => setIsApply("specific-sub-category")}
                />
                <span>Specific Sub-Category</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-category"
                  onClick={() => setIsApply("specific-sub-sub-category")}
                />
                <span>Specific Sub-Sub-Category</span>
              </aside>
              {/* <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-category"
                  onClick={() => setIsApply("specific-collection")}
                />
                <span> Specific Collection</span>
              </aside> */}
            </div>
          </div>

          {isApply === "specific-products" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={plist}
                onChange={handleProduct}
              />
              <span className="text-danger">{error.length > 2 && error}</span>
            </div>
          ) : null}

          {isApply === "specific-category" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={cat_list}
                onChange={handleCategory}
              />
              <span className="text-danger">{error.length > 2 && error}</span>
            </div>
          ) : null}

          {isApply === "specific-sub-category" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={sub_cat_List}
                onChange={handleSubCategory}
              />
              <span className="text-danger">{error.length > 2 && error}</span>
            </div>
          ) : null}

          {isApply === "specific-sub-sub-category" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={sub_sub_List}
                onChange={handleSubSubCategory}
              />
              <span className="text-danger">{error.length > 2 && error}</span>
            </div>
          ) : null}

          {/* {isApply === "specific-collection" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={collection}
                onChange={handleCollection}
              />
              <span className="text-danger">{error.length > 2 && error}</span>
            </div>
          ) : null} */}

          <section>
            <div className="two-field">
              <p>Limit one use customer</p>
              <select
                value={oncePerUse}
                name="oncePerUse"
                onChange={handleChange}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
            </div>
            {show === false && (
              <div className="two-field">
                <p>Coupon Usage Limit</p>
                <input
                  type="number"
                  name="couponLimit"
                  onChange={handleChange}
                />
              </div>
            )}
          </section>
          <br />
          <div className="datepicker-alignment">
            <div>
              <p className="label-form">
                Start Date <b>*</b>
              </p>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<DatePickerInput />}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
            <div>
              <p className="label-form">
                Expiry Date <b>*</b>
              </p>
              <DatePicker
                selected={expiryDate}
                onChange={(date) => {
                  if (date < startDate) {
                    alert("Expiry Date Must Be Greater Than Start Date");
                    return 0;
                  }
                  setExpiryDate(date);
                }}
                customInput={<DatePickerInput />}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>

          <div className="button">
            <button>Create</button>
          </div>
        </div>
      </form>
      {redirect ? <Redirect to="/coupon-manage" /> : null}
      {coupons.message === "success" ? (
        <SuccessModal message="New Coupon Created !" onClick={closeModal} />
      ) : null}
      {loader ? <LoaderModal /> : null}
    </>
  );
};

export default AddCoupon;
