
import React, { useState } from "react";
import Layout from "../../layout/layout";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory ,Redirect} from "react-router"; 
import axiosInstance from "../../axios-config-auth";
import SuccessModal from "../../components/halper/SuccessModal"; 

export const AddOfferSetting = () => {
    const history = useHistory();
    const [content , setContent] = useState('')
    const [success , setSuccess] = useState(false);
    const [redirect , setRedirect] = useState(false);


    const handleSubmit =async() =>{ 
        let obj ={
          offerTitle :content,
        }

        const result = await axiosInstance.post("/vouchers/create-offer-title" ,obj );
        let res =JSON.parse(result.data) 
        if(res.success){
          setSuccess(true)
        }else{

        }
    }
    const closeModal = () => {
      setRedirect(true);
    };

    return (
        <Layout>
          <div className="form-wrap"> 
        <div
          className="left_arrow2 back-arrow"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            onClick={() => history.goBack()}
            src={left_arrow2}
            alt="left-arrow"
          /> 
          &nbsp;  &nbsp;  &nbsp;  &nbsp;
          <h2>Add Product Offer Coupon</h2>
        </div>
        {/* </div> */}
        <div className="form-body mt-5">
          <div className="single-field">
            <p>
              Offer Title<b></b>
            </p>
            <input
              type="text"
              id="title"
              placeholder="Enter Title"
              name="title"
              onChange={(e) => setContent(e.target.value)}
            /> 
          </div>
        </div>
        <div className="button">
          <button onClick={handleSubmit}>Add </button>
        </div>
      </div>
      {redirect ? <Redirect to="/load-offer-setting" /> : null}
      {success ? (
        <SuccessModal message="New Offer Created !" onClick={closeModal} />
      ) : null}
        </Layout>
    )
}
