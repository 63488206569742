import {
  FETCH_SUB_CATEGORY,
  FETCH_SUB_CATEGORY_SUCCESS,
  FETCH_SUB_CATEGORY_FAILURE,
  CREATE_SUB_CATEGORY,
  FETCH_SUB_CATEGORY_BY_ID,
  // SEARCH_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
  DELETE_SUB_CATEGORY
} from '../constants/types';

const initialState = {
  loading: false,
  data: [],
  error: ''
}

export function subCategoryData(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUB_CATEGORY_SUCCESS:
     
      return {
        ...state,
        data: action.payload,
        loading: false
      }

    case FETCH_SUB_CATEGORY:
      return {
        ...state,
        data: [],
        loading: true,
      }

    case FETCH_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload
      }

    case CREATE_SUB_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }
    case FETCH_SUB_CATEGORY_BY_ID:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }

    case UPDATE_SUB_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }
    case DELETE_SUB_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      }

    default:
      return state;
  }
}

// ----
