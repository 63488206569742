import React,{useState, useEffect} from 'react';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import { useParams,useHistory, Link} from 'react-router-dom';
import axiosauth from '../../axios-config-auth';
import noImage from '../../assets/images/no-img.png';
import pdfIcon from '../../assets/images/pdfdefault.png';
import Loader from 'react-loader-spinner';

const BrandViewDetail = () => {
  const { brandId } = useParams();
  const history = useHistory();
      const [showLoader, setShowLoader] = useState(true);

const [brand, setBrand]=useState([]);


  useEffect(() => {
    let obj = {
        "brandId":brandId
    }
    axiosauth.post('/brand/find-brandby-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("res" ,res)
        if (res.success === true) {
          setBrand(res.result)
          setShowLoader(false);
              
        } else {
         
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [brandId]);

// console.log("brandd--->",brand)
// useEffect(()=>{

// },[brandId])

  return (
    <div>
      <section className="detail-heading">
      <div className="left_arrow2 back-arrow"  style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
       <img  onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" />
        <h2>Brand Detail</h2>
       </div>
      </section>
  
   {!showLoader ?
         <>
          <section className="detail-body" >
            <div>Brand Name</div>
            <div className="titleStyle">{brand.name}</div>
          </section>
          <section className="detail-body" >
            <div>Brand Logo</div>
            <div>
              <img src={brand.logo ? brand.logo: noImage} alt=""/>
            </div>
          </section>
          <section className="detail-body" >
            <div>Image</div>
            <div>
              <img src={brand.image ? brand.image: noImage} alt=""/>
            </div>
          </section>
          <section className="detail-body" >
            <div>Brand Description</div>
            <div>
              {brand.description}
            </div>
          </section>

            {brand.trademark && brand.trademark.length>0 ?
            brand.trademark.map((trade, index)=>{
              return(
                <div key={index}>
                      <section className="detail-body" >
                        <div>Trademark (class-{index+1})<br/> <br/><br/> {trade.className}</div>
                        <div><img src={trade.url && trade.url.includes('pdf') ? pdfIcon :trade.url} alt="" style={{height:'150px', width:'150px', padding:'10px'}}/></div>
                      </section>
                </div>
              )
            })

            :null
            }


           <div className="button">
            
              <button  onClick={() => history.goBack()}>Back</button> &nbsp;
          
              <Link to={`/update-brand/${brandId}`}>
                <button>Edit</button>
              </Link>
            </div>

                 </>
                 :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </div>
            } 

          </div>

  )
}

export default BrandViewDetail