import React, { useState, useEffect, useRef } from "react";
import axiosauth from "../../axios-config-auth";
import { useHistory } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import SuccessModal from "../../components/halper/SuccessModal";
import Layout from "../../layout/layout";
import { useParams } from "react-router";
import left_arrow2 from "../../assets/icons/down_arrow2.png";


const UpdateShippingCharges = () => {
    const [show, setShow] = useState(false);
    const [collectionMsg, setCollectionMasg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const myRefname = useRef(null);
    const history = useHistory();

    const [shipping, setshipping] = useState({
        startvalue: "",
        cost: "",
        endvalue: "",
    });

    const { shipId } = useParams();

    useEffect(() => {
        let obj = {
            shipId: shipId,
        };
        axiosauth
            .post("shipping/load-shipping-by-id", obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                console.log("response------------>>", res)

                if (res.success === true) {
                    setshipping({
                        startvalue: res.result.minimumAmount,
                        endvalue: res.result.maximumAmount,
                        cost: res.result.amountValue,
                    });

                }
                // let res = JSON.parse(response.data);
                // console.log("rj", res);
                // if (res.success === true) {
                //   setshipping({
                //     startvalue: res.data.startAmount,
                //     endvalue: res.data.endAmount,
                //     cost: res.data.value,
                //   });
                // } else {
                // }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [shipId]);

    const handleClick = () => {
        myRefname.current.dispatchEvent(
            new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            })
        );
    };

    const [shippingstartvalueerror, setshippingstartvalueerror] = useState("");
    const [shippingcosterror, setshippingcosterror] = useState("");
    const [shippingendvalueerror, setshippingendvalueerror] = useState("");

    /**
     * TODO: function use for handle create brands form fields
     *@version 1.0.0
     * @author [Rohan Jha]
     */
    const handleChange = (name) => (event) => {
        setshippingstartvalueerror("");
        setshippingendvalueerror("");
        setshippingcosterror("");
        setErrorMsg("");
        setCollectionMasg("");
        setshipping({ ...shipping, error: false, [name]: event.target.value });
    };

    const onSubmit = () => {
        var error = "";
        var errora = "";
        var errorb = "";

        if (shipping.startvalue === "") {
            setshippingstartvalueerror("Please Enter Starting Value");
            error = "a";
        } else {
            error = "";
        }
        if (shipping.endvalue === "") {
            setshippingendvalueerror("Please Enter End Value");
            errora = "a";
        } else {
            errora = "";
        }
        if (parseInt(shipping.endvalue) < parseInt(shipping.startvalue)) {
            setshippingendvalueerror("End Value Must be Greater Than Starting Value");
            errora = "a";
        } else {
            errora = "";
        }
        if (shipping.cost === "") {
            setshippingcosterror("Please Enter cost");
            errorb = "a";
        } else {
            errorb = "";
        }
        if (!(parseInt(shipping.cost) >= 0 && parseInt(shipping.cost) <= 10000)) {
            setshippingcosterror("Percentage Invalid");
            errorb = "a";
        } else {
            errorb = "";
        }
        if (error === "a" || errora === "a" || errorb === "a") {
            return 0;
        }
        setErrorMsg("");
        setCollectionMasg("");

        let obj = {
            //   startAmount: shipping.startvalue,
            //   endAmount: shipping.endvalue,
            //   value: shipping.cost,
            //   active: true,
            //   gazaricoinvalueId: shipId,
            "amountValue": parseInt(shipping.cost),
            "minimumAmount": parseInt(shipping.startvalue),
            maximumAmount: parseInt(shipping.endvalue),
            shipId: shipId,
        };

        axiosauth
            .post("shipping/update-shipping", obj)
            .then(async (response) => {
                console.log("response", response);
                let res = JSON.parse(response.data);
                if (res.message === "success") {
                    setErrorMsg("");

                    setShow(true);
                } else {
                    setErrorMsg(res.message);
                    setCollectionMasg("");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const modalClose = () => { 
        history.push("/shipping-charges")
    }; 
    
    return (
        <Layout>
            <div
                className="left_arrow2 back-arrow my-3 mt-4"
                style={{
                    display: "flex",
                    alignItems: "baseline",
                    fontFamily: "inherit",
                }}
            >
                <img
                    src={left_arrow2}
                    onClick={() => history.goBack()}
                    alt="left-arrow"
                />{" "}
                &nbsp;
                <h2>Update Shipping Charge</h2>

            </div>

            <div className="sub-category-wrap">
                <div className="form-group form-input">
                    <label className="label-style" for="name">
                        Minimum Amount<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                        type="number"
                        name="name"
                        className={
                            shippingstartvalueerror !== "" || errorMsg !== ""
                                ? "error form-control form-control-nonround"
                                : "form-control form-control-nonround"
                        }
                        id="name"
                        placeholder="Type here"
                        value={shipping.startvalue}
                        onChange={handleChange("startvalue")}
                    />
                    <p>{shippingstartvalueerror}</p>
                </div>
                <div className="form-group form-input">
                    <label className="label-style" for="name">
                        Maximum Amount<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                        type="number"
                        name="name"
                        className={
                            shippingendvalueerror !== "" || errorMsg !== ""
                                ? "error form-control form-control-nonround"
                                : "form-control form-control-nonround"
                        }
                        id="name"
                        placeholder="Type here"
                        value={shipping.endvalue}
                        onChange={handleChange("endvalue")}
                    />
                    <p>{shippingendvalueerror}</p>
                </div>
                <div className="form-group form-input">
                    <label className="label-style" for="name">
                        Shipping Charge<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                        type="number"
                        name="name"
                        className={
                            shippingcosterror !== "" || errorMsg !== ""
                                ? "error form-control form-control-nonround"
                                : "form-control form-control-nonround"
                        }
                        id="name"
                        placeholder="Type here"
                        value={shipping.cost}
                        onChange={handleChange("cost")}
                    />
                    <p>{shippingcosterror}</p>
                </div>

                <div className="text-right p-3">
                    {/* <button
            ref={myRefname}
            style={{ display: "none" }}
            className="btn-style px-5"
          >
            Next
          </button>
          <NavLink to="/managearjoicoins?page=1">
            {" "}
            <button
              ref={myRefname}
              style={{ marginRight: "43px" }}
              className="btn-style px-5"
            >
              Back
            </button>
          </NavLink> */}
                    {/* <button onClick={onSubmit} className="btn-style px-5">
            Save
          </button> */}
                    <div className="button">
                        <button onClick={onSubmit}>Update</button>
                    </div>
                </div>
                <span className="text-success font-weight-bold m-3">
                    {collectionMsg}
                </span>
                <span className="text-danger font-weight-bold m-3">{errorMsg}</span>
            </div>

            {show ? (
                <SuccessModal
                    message="Shipping Charge Updated Successfully!"
                    onClick={modalClose}
                />
            ) : null}
        </Layout>
    );
};

export default UpdateShippingCharges;
