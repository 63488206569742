import {
    FETCH_PAGES,
    EDIT_PAGES,
    PAGES_SUCCESS,
    USER_FAIL,
    USER_LOADING
} from '../constants/types';

const initialState = {
    loading: false,
    data: [],
    error: ''
}

export function pageData(state = initialState, action) {
    switch (action.type) {
        case FETCH_PAGES:
            return {
                ...state,
                data: action.payload,
                loading: false
            }

        case EDIT_PAGES:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case USER_LOADING:
            return {
                ...state,
                data: [],
                loading: true
            }
        case USER_FAIL:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
}


export function pageUpdate(state = initialState, action) {
    switch (action.type) {
        case PAGES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }

        default:
            return state;
    }
}
