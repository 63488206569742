import React from 'react'

const CommonModal = ({ onClick, message }) => {
    return (
       <div className="common-bg">
      <div className="common-success">
        <h5>{message}</h5>
        <button onClick={onClick}>OK</button>
      </div>
    </div>
    )
}

export default CommonModal
