import axios from 'axios';
import { logoutUser, sessionexpire } from '../src/redux/actions/login'
import { store } from './redux/store';
const { dispatch } = store; // direct access to redux store.

const axiosInstance = axios.create({ 
    baseURL: process.env.REACT_APP_WEB_URL,
//   baseURL: 'https://backstaging.gajari.com/', 
//   baseURL: 'http://192.168.1.50:1221/', 

    transformResponse: [function (data) {
        // Do whatever you want to transform the data
        // console.log(data);
        return data;
    }],
});

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    var accessToken = localStorage.getItem("accesstoken")
    // console.log("inside request interceptors-->");
    // config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
    config.headers.Authorization = "Bearer " + accessToken;


    // console.log(config);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("inside reponse interceptors-->");
    if (JSON.parse(response.data).code === 401) {
        // console.log("logout")
        // alert("Unauthorized access Please login again")
        dispatch(sessionexpire(true))
        dispatch(logoutUser())
        // window.location.reload()
    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export default axiosInstance;