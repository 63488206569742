import React from 'react';
import Layout from '../../layout/layout';
import AddVariantForm from '../../wrapper/variant/AddVariantForm';

const AddVariantPage = () => {

  return (
    <Layout>
      <AddVariantForm />
    </Layout>
  )
}

export default AddVariantPage