import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../layout/layout';
import axiosauth from '../../axios-config-auth';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Redirect, useParams, useHistory } from 'react-router';
import SuccessModal from '../../components/halper/SuccessModal';
import Loader from 'react-loader-spinner';
import LoaderModal from '../../components/halper/LoaderModal';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { Editor } from '@tinymce/tinymce-react';
import deleteIcon from '../../assets/icons/delete.png';
import addIcon from '../../assets/icons/add.png';
// import { EditorState, convertToRaw, ContentState, convertFromHTML, } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from 'draftjs-to-html';
// import JoditEditor from 'jodit-pro-react';
// import { loadSubSubCategory } from '../../redux/actions/subSubCategoryAction';
const EditProduct = () => {
  const history = useHistory()
  const animatedComponents = makeAnimated();
  const [category, setCategory] = useState([]);
  const [Subcategory, setSubCategory] = useState([]);
  const [Subsubcategory, setSubSubCategory] = useState([]);

  const [content, setContent] = useState('')
  const [content1, setContent1] = useState('')
  const [content2, setContent2] = useState('')
  const [content3, setContent3] = useState('')
  const [sizeChart , setSizeChart] = useState('');

  // const [updateData, setUpdateData] = useState(EditorState.createEmpty());

  // const onEditorStateChange = (editorState) => {
  //   setUpdateData(editorState);

  //   let enterData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //   // setproduct({ ...product, error: false, "shortDescription": enterData });

  //   setContent(enterData)
  // };
 

  // const [updateData1, setUpdateData1] = useState(EditorState.createEmpty());
  // const onEditorStateChange1 = (editorState) => {
  //   setUpdateData1(editorState);
  //   let enterData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //   setContent1(enterData)
  //   // setproduct({ ...product, error: false, "longDescription": enterData });
  // };

  // const [updateData2, setUpdateData2] = useState(EditorState.createEmpty());
  // const onEditorStateChange2 = (editorState) => {
  //   setUpdateData2(editorState);
  //   let enterData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //   // setproduct({ ...product, error: false, "longDescription": enterData });
  //   setContent2(enterData)
  // };

  // const [updateData3, setUpdateData3] = useState(EditorState.createEmpty());

  // const onEditorStateChange3 = (editorState) => {
  //   setUpdateData3(editorState);
  //   let enterData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //   // setproduct({ ...product, error: false, "howTomeasure": enterData });
  //   setContent3(enterData)
  // };
  // ========================
  // const editor = useRef(null)

  // const config = {
  //   readonly: false
  // }

  const editorRef = useRef(null);


  const [shortCount, setShortCount] = useState(0);
  const [longCount, setLongCount] = useState(0);
  const [shortCountError, setShortCountError] = useState(null);
  const [longCountError, setLongCountError] = useState(null);
  const [longError, setLongError] = useState("");
  const [shortError, setShortError] = useState("");
  const [commonError, setCommonError] = useState("")
  const { catalogueId } = useParams();

  const [dataLoader, setDataLoader] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const [emailError, setemailError] = useState("");

  const [idealObj, setIdealObj] = useState([])
  const [styleObj, setStyleObj] = useState([]);
  const [trendsObj, setTrendsObj] = useState([])
  const [trederType, setTrederType] = useState([])
  const [seasonObj, setSeasonObj] = useState([]);
  const [conditionObj, setConditionObj] = useState([]);
  // ============================================================== New Field=============
  const [productTag, setProductTag] = useState('')
  const [searchTag, setSearchtag] = useState('')
  const [materialCare, setMaterialCare] = useState('')
  const [packageContent, setPackageContent] = useState('')
  const [itemWt, setItemWt] = useState(0)
  const [dispatchAdd, setDispatchAdd] = useState('')
  const [HSNCode, setHSNCode] = useState('')
  const [country, setCountry] = useState('');
  const [color, setColor] = useState('');
  const [length, setLength] = useState('');
  const [breath, setBreath] = useState('');
  const [height, setHeight] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [mobile, setMobile] = useState('');

  const [idealId, setIdealId] = useState('')
  const [styleId, SetStyleId] = useState('');
  const [trensdId, setTrendsId] = useState('')
  const [traderTypeId, setTraderTypeId] = useState('');
  const [seasonId, setSeasonId] = useState('')
  const [conditionId, setConditionId] = useState('');

  const [allExplore, setAllExplore] = useState([])
  const [exploreuse, setExploreuse] = useState([]);
  const [selectExplore, setSelectExplore] = useState([]);
  // =======================================================================
  const [COD, setCOD] = useState("")
  const [EssentialItem, setEssentialItem] = useState()
  const [isReturnable, setIsReturnable] = useState()
  const [isExchangeable, setIsExchangeable] = useState()
  const [returnDays , setReturnDays] = useState();
  const [priceSecondaryDisplay, setPriceSecondaryDisplay] = useState()
  const [ gender , setGender] = useState("")
  const [sizeFit, setSizeFit] = useState("");
  const [faqList, setFaqList] = useState([{question:'',answer:''}]);
  const [faqReq, setFaqReq] = useState(false);
 
  const [gstObj, setGstObj] = useState([])

  const [gsList, setGsList] = useState([{
    value: "",
    label: '',
  }])

  useEffect(() => {
    if (gstObj && gstObj.length > 0) {
      let v = []

      gstObj.forEach((gst) => {
        v.push({ label: gst.gstName, value: gst._id })
      })
      setGsList(v)
    }
  }, [gstObj])



  useEffect(() => {
    const obj = {
      "limit": 100000,
    }
    axiosauth.post("/gst/load", obj)
      .then(response => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setGstObj(res.data)
        }
        else {
          // setCatalogueError(res.message)
        }

      }).catch((error) => {
        console.log(error);
      }).then(() => {
        console.log("-----always executes");
      })

  }, [])

  const [categoryId, setCategoryId] = useState([]);
  const [SubcategoryId, setSubCategoryId] = useState([]);
  const [SubSubcategoryId, setSubSubCategoryId] = useState([]); 
  const [product, setproduct] = useState({
    name: '',
    shortDescription: '',
    longDescription: '',
    foodType: '',
    productName: "",

    // productTag :"",
  });
  const { name, shortDescription, longDescription, foodType, productName } = product;

  const handleSelect = (selectedOption) => {
    setCategoryId(selectedOption.catObjid); 
    setCategoryName(selectedOption.label)
    loadSubCategory(selectedOption.catId);
    setselectsubcategory("select sub category")
    setselectsubcategory("")
    setselectsubsubcategory("")
  };

  const handleSelectSubCat = (selectedOption) => {
    setSubCategoryId(selectedOption.subCatId); 
    loadSubSubCat(selectedOption.catId)
    setselectsubcategory(selectedOption.label)
    setselectsubsubcategory("")
  };

  const handleSelectSubSubCat = (selectedOption) => {
    setSubSubCategoryId(selectedOption.subCatId); 
    setselectsubsubcategory(selectedOption.label)
  };

  const handleChange = (e) => {
    setproduct({
      ...product,
      error: false,
      [e.target.name]: e.target.value,
      // [e.target.productTag] :e.target.value,
    });

    if (e.target.name === "shortDescription") {
      setShortCount(product.shortDescription.split(' ').length)
    }

    if (e.target.name === "longDescription") {
      setLongCount(product.longDescription.split(' ').length)
    }


  };

  const [gst, setGst] = useState("");
  const handleSelectGst = (selectedOption) => { 
    setGst(selectedOption.value);
    setGstValue(selectedOption.label)
  };
  const [value1, setValue1] = useState('true');


  const [specs, setspecs] = useState([
    {
      name: '',
      value: '',
    },
  ]);

  /**
   * TODO: function use for removing specification row
   *@version 1.0.0
   * @author [ Unknown]
   */
  const removespecs = (index) => {
    const temp = [...specs];

    const filter = temp.filter((item, i) => i !== index);

    setspecs(filter);
  };
  /**
   * TODO: function use for adding specification row
   *@version 1.0.0
   * @author [ Unknown]
   */
  const addspecs = () => {
    setspecs([
      ...specs,
      {
        name: '',
        value: '',
      },
    ]);
  };

  /**
   * TODO: function use for handling name value change of specification
   *@version 1.0.0
   * @author [ Unknown]
   */
  const handleChange2 = (e, name, index) => {
    const { value } = e.target;
    const list = [...specs];
    list[index][name] = value;
    setspecs(list);
  };

  const [openSuccess, setOpenSuccess] = useState(false);
  const [catalogueError, setCatalogueError] = useState('');

  // console.log("isReturnable====>>" , isReturnable)

  const submitProduct = (e) => {
    e.preventDefault();

    var error1 = ""; 

    if (content === "") {
      setShortError(" Product Specification Required");
      return 0;
    }


    if (content1 === "") {
      setLongError(" Product Description Required");
      return 0;
    }
    if (HSNCode === "") {
      setCommonError("HSN Code Required");
      return 0;
    }
    if (categoryId === "") {
      setCommonError("Category Required");
      return 0;
    }
    if (SubcategoryId === "") {
      setCommonError("Sub Category Required");
      return 0;
    }
    if (SubSubcategoryId === "") {
      setCommonError("Sub Sub Category Required");
      return 0;
    }
    if (length === "") { 
      setCommonError("Length Required");
      return 0;
    }
    if (breath === "") { 
      setCommonError("Width Required");
      return 0;
    }
    if (height === "") { 
      setCommonError("Height Required");
      return 0;
    }
    if (address === "") { 
      setCommonError("Manufacturer Address Required");
      return 0;
    }
    if (mobile === "" && mobile.length < 10) {
      setCommonError("Contact Number Required");
      return 0;
    }
    if (email === "") {
      setemailError("Please Enter Email");
      error1 = "a";
    } else {
      setemailError("");
      error1 = "";
    }

    if (email.length > 0) {
      // eslint-disable-next-line
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) === false) {
        setemailError("Invalid Email");
        error1 = "a";
        // return false;
      } else {
        setemailError("");
        error1 = "";
      }
    }
    if((returnDays === "" && (isReturnable=== "true" || isExchangeable === "true"))){
      setCommonError("Please Enter return Days") 
      return 0;
    }


    var explore = [];
    for (var j = 0; j < selectExplore?.selectedOption?.length; j++) {
      explore.push(selectExplore.selectedOption[j].value);
    }

    let ls = []

    if(faqList && faqList?.length){
      faqList.forEach(item=>{
        if(item.question && item.answer){
          ls.push(item)
        }
      })
    }


    let obj = {
      catalogueId: catalogueId,
      brand: localStorage.getItem('brandObj'),
      name: name,
      title: productName,
      shortDescription: content,
      longDescription: content1,
      foodType: foodType,
      // sizeChart: content2,
      sizeChart: sizeChart,
      howTomeasure: content3,
      specification: value1 === 'true' ? specs : [],
      category: {
        cat: categoryId,
        sub1: SubcategoryId,
        sub2: SubSubcategoryId,
      },
      gst: gst,
    };
    // =============================
    const obj2 = {
      catalogueId: catalogueId,
      brand: localStorage.getItem('brandObj'),
      name: name,
      title: productName,
      gst: gst,
      hsnCode: HSNCode,
      category: {
        cat: categoryId,
        sub1: SubcategoryId,
        sub2: SubSubcategoryId,
      },
      specification: {
        "name": "Color",
        "value": "Vl"
      },
      shortDescription: content.newText,
      // sizeChart: content2,
      sizeChart: sizeChart.newText,
      longDescription: content1.newText,
      howTomeasure: content3.newText,
      foodType: "none",
      productTags: productTag,
      searchTags: searchTag,
      materialCare: materialCare,
      packageContents:  packageContent,
      itemWeight: itemWt,
      dispatchAddress: dispatchAdd,
      style: styleId,
      ideals: idealId,
      trends: trensdId,
      tradertype: traderTypeId,
      seasons: seasonId,
      condition: conditionId,
      countryOrigin: country,
      color: color,
      sizeFit:sizeFit,
      sex :gender,
      cod: COD === "true" ? true : false,
      EssentialItem: EssentialItem === "true" ? true : false,
      isReturnable: isReturnable === "true" ? true : false,
      isExchangable: isExchangeable === "true" ? true : false,
      returnDays: (isReturnable === "true" || isExchangeable === "true" ) ? parseInt(returnDays) :0,
      priceSecondaryDisplay: priceSecondaryDisplay === "true" ? true : false,
      faq:ls,

      explore: explore,
      productDimension: {
        "length": length,
        "breadth": breath,
        "height": height
      },
      manufacturerEmail: email,
      manufacturerAddress: address,
      manufacturerMobile: mobile

    }

    // ================================
    setShowLoader(true)
    axiosauth
      .post('/catalogue/update-catalogue', obj2)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.message === 'success') {
          localStorage.setItem('catalogueId', obj.catalogueId);
          setShowLoader(false)
          setOpenSuccess(true)
        } else {
          setCatalogueError(res.message);
          setShowLoader(false)
        }
      })
      .catch((error) => {
        setNetworkError(true)
        setShowLoader(false)
      })
      .then(() => {
        console.log('-----always executes');
      });

  }; 

  useEffect(() => {
    axiosauth
      .post("/explore/load-Explore", { limit: 1000 })
      .then(async (response) => {
        let res = JSON.parse(response.data);

        if (response.status === 200) {
          setAllExplore(res.load);
        } else {
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  /**
   * TODO: function use for getting subcategory of a specific category
   *@version 1.0.0
   * @author [ Unknown]
   */
  const loadSubCategory = (id) => {
    let obj = {
      categoryId: id,
    };
    axiosauth
      .post('/categories/load-by-id', obj)
      .then((response) => {
        let res = JSON.parse(response.data);

        if (res.success === true) {
          if (res.data.category.category[0].childCategories) {
            setSubCategory(res.data.category.category[0].childCategories) 
          }
          // setSubCategoryId(res.data.childCategories[0].categoryId)

          //   setvoucher(res.vouchers.result)
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log('-----always executes');
      });
  };


  const loadSubSubCat = (id) => {
    let obj = {
      categoryId: id,
    };
    axiosauth
      .post('/categories/load-by-id', obj)
      .then((response) => {
        let res = JSON.parse(response.data);

        if (res.success === true) {
          if (res.data.category.category[0].childCategories) {
            setSubSubCategory(res.data.category.category[0].childCategories) 
          }
          // setSubCategoryId(res.data.childCategories[0].categoryId)

          //   setvoucher(res.vouchers.result)
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log('-----always executes');
      });
  };


  // const[productById, setProductById]=useState([]);
  const [gstValue, setGstValue] = useState("");
  const [selectsubcategory, setselectsubcategory] = useState("")
  const [selectsubsubcategory, setselectsubsubcategory] = useState("")
  const [categoryName, setCategoryName] = useState("");
 

  useEffect(() => {

    let obj = {
      "catalogueId": catalogueId
    }
    axiosauth.post('/catalogue/find-catalogue-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          // setProductById(res.result[0])
          setproduct({

            name: res.result[0].name,
            productName: res.result[0].title,
            shortDescription: res.result[0].shortDescription,
            sizeChart: res.result[0].sizeChart,
            longDescription: res.result[0].longDescription,
            hsncode: res.result[0].hsnCode,
            foodType: res.result[0].foodType,
            //  productTag : res.result[0].productTags
          })

          if (res.result[0].explore && res.result[0].explore?.length !== 0) {

            let explorelist = [];
            for (let a = 0; a < res.result[0].explore.length; a++) {

              let index; 
              for (let x = 0; x < allExplore?.length; x++) {  
                if (allExplore[x]._id === res.result[0].explore[a]) {
                  index = x;
                }
              }
              explorelist.push({
                value: allExplore[index]?._id,
                label: allExplore[index]?.name,
              });
            } 
            setExploreuse(explorelist);
          }

          
          setProductTag(res.result[0]?.productTags)
          setSearchtag(res.result[0]?.searchTags)
          setSizeFit(res.result[0]?.sizeFit)
          setMaterialCare(res.result[0]?.materialCare)
          setPackageContent(res.result[0].packageContents)
          setItemWt(res.result[0]?.itemWeight)
          setHSNCode(res.result[0]?.hsnCode);
          setDispatchAdd(res.result[0]?.dispatchAddress);
          setCountry(res.result[0]?.countryOrigin)
          setColor(res.result[0]?.color)
          setHeight(res.result[0]?.productDimension?.height)
          setBreath(res.result[0]?.productDimension?.breadth)
          setLength(res.result[0]?.productDimension?.length)
          setAddress(res.result[0]?.manufacturerAddress)
          setEmail(res.result[0]?.manufacturerEmail)
          setFaqList(res.result[0]?.faq && res.result[0]?.faq?.length ? res.result[0]?.faq : [{question:'',answer:''}])
          setMobile(res.result[0]?.manufacturerMobile)
          setIdealId(res.result[0]?.ideals);
          SetStyleId(res.result[0]?.style);
          setTrendsId(res.result[0]?.trends)
          setTraderTypeId(res.result[0]?.tradertype)
          setSeasonId(res.result[0]?.seasons)
          setConditionId(res.result[0]?.condition)
          setContent(res.result[0].shortDescription)
          setCOD(res.result[0].cod === true ? "true" :"false")
          setGender(res.result[0].sex); 
          setEssentialItem(res.result[0].essentialItem === true ? "true" :"false");
          setIsExchangeable(res.result[0].isExchangable === true ? "true" :"false");
          setIsReturnable(res.result[0].isReturnable === true ? "true" :"false");
          setPriceSecondaryDisplay(res.result[0].priceSecondaryDisplay === true ? "true" :"false");
          setReturnDays(res.result[0].returnDays);



          //   setUpdateData(EditorState.createWithContent(
          //     ContentState.createFromBlockArray(
          //         convertFromHTML(res.result[0].shortDescription)
          //     ))
          // )
          //   setUpdateData1(EditorState.createWithContent(
          //     ContentState.createFromBlockArray(
          //         convertFromHTML(res.result[0].longDescription)
          //     ))
          // )
          //   setUpdateData2(EditorState.createWithContent(
          //     ContentState.createFromBlockArray(
          //         convertFromHTML(res.result[0].sizeChart)
          //     ))
          // )
          // setUpdateData3(EditorState.createWithContent(
          //   ContentState.createFromBlockArray(
          //       convertFromHTML(res.result[0].howTomeasure)
          //   ))
          // )

          setContent1(res.result[0].longDescription)
          // setContent2(res.result[0].sizeChart);
          setSizeChart(res.result[0].sizeChart)
          setContent3(res.result[0].howTomeasure)
          setShortCount(res.result[0].shortDescription.split(' ').length);
          setLongCount(res.result[0].longDescription.split(' ').length)
          setGst(res.result[0].gst?._id)
          setGstValue(res.result[0].gst?.gstName)
          setCategoryName(res.result[0].category.cat.name);
          // setCategoryId(res.result[0].category.cat.name)
          //   setSubCategoryId(res.result[0].category.sub1.name)
          setspecs(res.result[0].specification)
          setselectsubcategory(res.result[0].category.sub1.name)
          setselectsubsubcategory(res.result[0].category.sub2.name)
          setSubSubCategoryId(res.result[0].category.sub2._id)
          var sub = res.result[0].category.sub1.name
          var sub2 = res.result[0].category.sub2
          setCategoryId(res.result[0].category.cat._id)
          let obj = {
            "categoryId": res.result[0].category.cat.categoryId
          }
          axiosauth.post('/categories/load-by-id', obj).then(response => {

            let res = JSON.parse(response.data)

            if (res.success === true) {
              // setSubCategory([])
              setDataLoader(false);
              let arr = []
              for (var i = 0; i < res.data.category.category[0].childCategories.length; i++) {
                arr.push(res.data.category.category[0].childCategories[i])
              }
              setSubCategory(arr)

              for (var j = 0; j < res.data.category.category[0].childCategories.length; j++) {
                if (res.data.category.category[0].childCategories[j].name === sub) {
                  setSubCategoryId(res.data.category.category[0].childCategories[j]._id)
                  // sub sub category select here......................
                  for (var k = 0; k < res.data.category.category[0].childCategories[0].childCategories.length; k++) {

                    if (res.data.category.category[0].childCategories[0].childCategories[k]._id === sub2) {
                      setSubSubCategoryId(res.data.category.category[0].childCategories[0].childCategories[k]._id)
                    }
                  }
                }
              }

            }
            else {

            }

          }).catch((error) => {
            console.log(error);
          }).then(() => {
            console.log("-----always executes");
          })
          //    loadSubCategory1(res.result[0].category.categoryId, res.result[0].category.sub1.name)
          //  change141(res.result[0].category.cat.name, res.result[0].category.sub1.name)

          //    change11(res.result[0].category.sub1.name)
          if (res.result[0].specification.length === 0) {
            setValue1('no')
            //  setshow(false)
          }
        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      })


    // =============================================


    const loadCategory = () => {
      let obj = {
        "count": 0,
        filters: {
          type: 'category',
        },
        "sortBy": {
          "type": "createdOn",
          "order": -1
        }
      };
      var catlist = [];
      axiosauth
        .post('/categories/load', obj)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            for (var i = 0; i < res.data.categories.length; i++) {
              if (res.data.categories[i].childCategories.length !== 0) {
                catlist.push({
                  catId: res.data.categories[i].categoryId,
                  label: res.data.categories[i].name,
                  catObjid: res.data.categories[i]._id,
                });
              }
            }
            setCategory(catlist);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          console.log('-----always executes');
        });
    };
    loadCategory();
  }, [catalogueId, allExplore]);

  var subcatlist = [];
  if (Subcategory && Subcategory.length > 0) {
    for (var k = 0; k < Subcategory.length; k++) { 
      subcatlist.push({
        catId: Subcategory[k].categoryId,
        subCatId: Subcategory[k]._id,
        label: Subcategory[k].name,
      });
    }
  }

  var subsubcatlist = [];
  if (Subsubcategory && Subsubcategory.length > 0) {
    for (var k = 0; k < Subsubcategory.length; k++) {
      subsubcatlist.push({
        subCatId: Subsubcategory[k]._id,
        label: Subsubcategory[k].name,
      });
    }
  }
 

  const handleInputChange = (search) => {
    let obj = {
      "filters": {
        "type": "category",
        name: search
      },
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }

    }
    var catlist = [];
    axiosauth
      .post('/categories/load', obj)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          for (var i = 0; i < res.data.categories.length; i++) {
            if (res.data.categories[i].childCategories.length !== 0) {
              catlist.push({
                catId: res.data.categories[i].categoryId,
                label: res.data.categories[i].name,
                catObjid: res.data.categories[i]._id,
              });
            }
          }
          setCategory(catlist);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log('-----always executes');
      });

  }


  const [redirect, setRedirect] = useState(false);
  const closeModal = () => {
    setRedirect(true);
  }

  const connectionError = () => {
    setNetworkError(false)
  }



  useEffect(() => {
    axiosauth
      .post('/ideals/list-ideals/')
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setIdealObj(res.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [])

  useEffect(() => {
    axiosauth
      .post('/style/list-styles/')
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setStyleObj(res.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [])
  useEffect(() => {
    axiosauth
      .post('/trends/list-trends/')
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setTrendsObj(res.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [])

  useEffect(() => {
    axiosauth
      .post('/tradertype/list-tradertype/')
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setTrederType(res.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [])
  useEffect(() => {
    axiosauth
      .post('/seasons/list-seasons/')
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setSeasonObj(res.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [])

  useEffect(() => {
    axiosauth
      .post('/condition/list-condition/')
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setConditionObj(res.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [])



  // const onlyNumberAllow = (e) => {
  //   const re = /[0-9]+/g;
  //   if (!re.test(e.key)) {
  //     e.preventDefault();
  //   }
  // };

  const onlyNumberAllow = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  const onlyNumericWithDotAllow = (e) => {
    const re = /[0-9..}]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  const explore_list = [];
  if (allExplore) {
    for (var k = 0; k < allExplore.length; k++) {
      explore_list.push({ value: allExplore[k]._id, label: allExplore[k].name });
    }
  }

  const handleExplore = (selectedOption) => {
    setSelectExplore({ selectedOption });
    setExploreuse(selectedOption);
  };

  const manageList = (index) => {
    if(faqList[index].question && faqList[index].answer){
      setFaqReq(false)
      if(faqList.length < 10){
        const cloneFaqList = [...faqList]
        cloneFaqList.push({question:'',answer:''})
        setFaqList(cloneFaqList)
      }
    }else{
      setFaqReq(true)
    }
  }
  const handleRemove = (ind) =>{
    const cloneFaqList = [...faqList]
    cloneFaqList.splice(ind,1)
    setFaqList(cloneFaqList)
  }

  const handleChangeFaqQue = (e,index) =>{
    const {value} = e.target
    const cloneFaqList = [...faqList]
    cloneFaqList[index].question = value
    setFaqList(cloneFaqList)
  }

  const handleChangeFaqAns = (newText,index) =>{
    const cloneFaqList = [...faqList]
    cloneFaqList[index].answer = newText
    setFaqList(cloneFaqList)
  }

  return (
    <Layout>
      {!dataLoader ?
        <>

          {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
          {showLoader === true ? <LoaderModal /> : null}

          {redirect ? <Redirect to="/manage-product" /> : null}
          {openSuccess ? <SuccessModal message="Product Updated!" onClick={closeModal} /> : null}

          <form onSubmit={submitProduct} className="form-wrap">
            {/* <div className="form-header"> */}

            <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
              <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
              <h2>Update a Product Catalogue</h2>
            </div>
            <div className="form-body">
              <div className="single-field">
                <p>
                  {' '}
                  Product Title <b> * </b>
                </p>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter Product Name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="single-field">
                <p>
                  {' '}
                  Name of the product <b> * </b>
                </p>
                <input
                  type="text"
                  id="productName"
                  placeholder="Enter Product Name"
                  name="productName"
                  value={productName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div id="select-tag">
                <p> Select Categories </p>{' '}
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={category}
                  name="category"
                  required
                  onInputChange={handleInputChange}
                  value={[{ label: categoryName, value: categoryName }]}
                  onChange={handleSelect}
                />{' '}
              </div>

              <div id="select-tag">
                <p> Select Sub - Categories </p>{' '}
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={subcatlist}
                  name="subcat"
                  required
                  // onInputChange={handleInputSubChange}
                  value={[{ label: selectsubcategory, value: selectsubcategory }]}
                  onChange={handleSelectSubCat}
                />{' '}
              </div>

              <div id="select-tag">
                <p> Select Sub-Sub - Categories </p>
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={subsubcatlist}
                  name="subcat"
                  placeholder="Select or Search"
                  required
                  value={[{ label: selectsubsubcategory, value: selectsubsubcategory }]}
                  onChange={handleSelectSubSubCat}
                />{' '}
              </div>

              <div id="select-tag">
                <p>Gst Tax Code </p>{' '}
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={gsList}
                  name="gst"
                  value={[{ label: gstValue, value: gstValue }]}
                  required
                  onChange={handleSelectGst}
                />{' '}
              </div>
              <section>

                {/* ================================================================================================= */}
                <div className="two-field">
                  <p>Product Tag(Comma Separated)<b></b></p>
                  <input
                    type="text"
                    value={productTag}
                    onChange={(e) => setProductTag(e.target.value)}
                    placeholder="Enter product tag"
                  // required
                  />
                </div>
                <div className="two-field">


                  <p>Search Keywords(Comma Separated)<b></b></p>
                  <input
                    type="text"
                    value={searchTag}
                    onChange={(e) => setSearchtag(e.target.value)}
                    placeholder="Enter Search tag"
                  // required
                  />
                </div>

              </section>
              <section className="two-field">
                <div className="two-field">
                  <p>
                    Size & Fit<b></b>
                  </p>
                  <input
                    type="text"
                    value={sizeFit}
                    onChange={(e) => setSizeFit(e.target.value)}
                    placeholder="Enter Size & fit"
                  // required
                  />
                </div>
              </section>

              <section>
                <div className="two-field">
                  <p>Ideal For<b></b></p>
                  <div id="select-tag"
                  >
                    <select className="form-select" aria-label="Default select example"

                      onChange={(e) => {
                        setIdealId(e.target.value)
                      }}
                    >
                      {/* <option value="" selected>select Ideal</option> */}
                      {idealObj?.map((item) => (
                        <option value={item._id} selected={item._id === idealId}>{item.name}</option>

                      ))}

                    </select>
                  </div>
                </div>
                <div className="two-field">
                  <div id="select-tag"
                  //  onClick={handleStyle}
                  >
                    <p>Select Style </p>

                    <select className="form-select" aria-label="Default select example"

                      onChange={(e) => SetStyleId(e.target.value)}
                    >
                      {styleObj?.map((item) => (
                        <option value={item._id} selected={item._id === styleId}>{item.name}</option>

                      ))}

                    </select>

                    {/* <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={styleObj}
                            name="styel"
                            placeholder="Select or Search"
                            onInputChange={handleSelectStyle}
                            onChange={handleInputStyle}
                        />
                        <i className="error">{catError}</i> */}
                  </div>

                  {/* 
                            <p>Style<b></b></p>
                            <input
                                type="text"
                                // value={specs[index].mrp}
                                onChange={(e) => setStyle(e.target.value)}
                                placeholder="Enter Style"
                            // required
                            /> */}
                </div>
              </section>
              <section>
                <div className="two-field">
                  <p>Material & Care<b></b></p>
                  <input
                    type="text"
                    value={materialCare}
                    onChange={(e) => setMaterialCare(e.target.value)}
                    placeholder="Enter  material & care"
                  // required
                  />
                </div>
                <div className="two-field">


                  <p>Product Package Content<b></b></p>
                  <input
                    type="text"
                    value={packageContent}
                    onChange={(e) => setPackageContent(e.target.value)}
                    placeholder="Enter packacge contents"
                  // required
                  />
                </div>
              </section>


              <section>
                <div className="two-field">
                  <p>Trends</p>
                  <div id="select-tag"
                  // onClick={handleTrands}
                  >
                    <select className="form-select" aria-label="Default select example"

                      onChange={(e) => setTrendsId(e.target.value)}
                    >
                      {trendsObj?.map((item) => (
                        <option value={item._id} selected={item._id === trensdId}>{item.name}</option>

                      ))}

                    </select>
                  </div>
                </div>
                <div className="two-field">


                  <p>Item weight(Grams)<b></b></p>
                  <input
                    type="number"
                    value={itemWt}
                    onChange={(e) => setItemWt(e.target.value)}
                    placeholder="Enter Item weight"
                  // required
                  />
                </div>
              </section>


              <section>
                <div className="two-field">
                  <p className="label-form">HSN Code<b>*</b></p>
                  <input
                    type="text"
                    className={` form-control shadow-none ${commonError==="HSN Code Required" ? "border-danger " : null}`}
                    value={HSNCode}
                    onChange={(e) => setHSNCode(e.target.value)}
                    placeholder="Enter  HSN Code"
                    onKeyPress={(e) => onlyNumberAllow(e)}
                    maxLength={13}
                    // required
                  />
                </div>

                <div className="two-field">
                  <div id="select-tag"
                  //  onClick={handleTraderType}
                  >
                    <p> Trader Type </p>
                    <select className="form-select" aria-label="Default select example"

                      onChange={(e) => setTraderTypeId(e.target.value)}
                    >
                      {trederType?.map((item) => (
                        <option value={item._id} selected={item._id === traderTypeId}>{item.name}</option>

                      ))}

                    </select>
                  </div>
                </div>
              </section>

              <section>
                <div className="two-field">
                  <div id="select-tag"
                  //  onClick={handleSeason}
                  >
                    <p>Season </p>

                    <select className="form-select" aria-label="Default select example"

                      onChange={(e) => setSeasonId(e.target.value)}
                    >
                      {seasonObj?.map((item) => (
                        <option value={item._id} selected={item._id === seasonId}>{item.name}</option>

                      ))}

                    </select>
                  </div>
                </div>
                <div className="two-field">


                  <p>Dispatch Address<b></b></p>
                  <input
                    type="text"
                    value={dispatchAdd}
                    onChange={(e) => setDispatchAdd(e.target.value)}
                    placeholder="Enter Dispatch Address"
                  // required
                  />
                </div>
              </section>
              <section>
                <div className="two-field">
                  <div id="select-tag"
                  // onClick={handleCondition}
                  >
                    <p>Condition </p>

                    <select className="form-select" aria-label="Default select example"

                      onChange={(e) => setConditionId(e.target.value)}
                    >
                      <option value="" selected>select condition</option>
                      {conditionObj?.map((item) => (
                        <option value={item._id} selected={item._id === conditionId}>{item.name}</option>

                      ))}

                    </select>
                  </div>
                </div>
                <div className="two-field">
                  <p>Country of Origin<b></b></p>
                  <input
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Enter  country of origin"
                  // required
                  />
                </div>
              </section>
              <section>
                <div className="two-field">
                  <p>Color<b></b></p>
                  <input
                    type="text"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    placeholder="Enter Color"
                  // required
                  />
                </div>
                <div className="two-field">
                  <p>Select Sticky Stickers</p>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    value={exploreuse}
                    options={explore_list}
                    onChange={handleExplore}
                  />
                </div>
              </section>

              <div className="row d-flex">
            <div className="col">
              <div style={{ marginTop: "20px", display: "flex" }}>
                {/* <label
                  className="ml-1 label-form "
                  htmlFor="vehicle1"
                  style={{ marginTop: "-5px" }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "12px",
                      color: "#4F4F4F",
                    }}
                  >
                    {" "}
                    COD<b></b>
                  </p>
                </label> */}
                {/* <input
                  className="mx-4 mt-1"
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value={COD === "false" ? true : false}
                  checked={COD==="true" ?true :false}
                  onChange={(e) => { 
                    setCOD(e.target.value)
                  }}
                /> */}

                <br />

                <span className="mx-4 " style={{ marginTop: "-5px" }}>
                  <label
                    className="mx-1"
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "12px",
                      color: "#4F4F4F",
                    }}
                  >
                    Essential item
                  </label>
                  <input
                    className="mx-4 mt-1"
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value={EssentialItem === "false" ? true : false}
                  checked={EssentialItem==="true" ?true :false}
                  onChange={(e) => { 
                    setEssentialItem(e.target.value)
                  }}
                  />

                </span>

              </div>
            </div>
            <div className="col">
              <div
                className="d-flex  "
                style={{ marginTop: "20px" }}
              >
                <label
                  className=" label-form mx-2"
                  htmlFor="vehicle1"
                  style={{ marginTop: "-5px" }}
                >
                  Is Returnable
                </label>
                <input
                  className="mx-3"
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1" 
                  value={isReturnable === "false" ? true : false}
                  checked={isReturnable==="true" ?true :false}
                  onChange={(e) => { 
                    setIsReturnable(e.target.value)
                  }}
                />

                <br />

                <span className="" style={{ marginTop: "-5px" }}>
                  <label
                    className="mx-4"
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "24px",
                      color: "#4F4F4F",
                    }}
                  >
                    Is Exchangeable
                  </label>
                  <input
                    className="mx-3"
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value={isExchangeable === "false" ? true : false}
                  checked={isExchangeable==="true" ? true :false}
                  onChange={(e) => { 
                    setIsExchangeable(e.target.value)
                  }}
                  />

                </span>

              </div>
              {isReturnable==="true" || isExchangeable==="true" ? (
                      <section>
                        <div className="two-field mx-2">
                          <p>
                            Return Days<b></b>
                          </p>

                          <input
                          className={` form-control shadow-none ${commonError==="Please Enter return Days" ? "border-danger " : null}`}
                            type="number" 
                            value={returnDays}
                            onChange={(e) =>
                              setReturnDays(e.target.value)
                            }
                            placeholder="Enter Days"
                            // required
                          />
                        </div>
                      </section>
                    ) : (
                      ""
                    )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              {/* <div style={{ marginTop: "20px", display: "flex" }}>
                <label
                  className="ml-1 label-form "
                  htmlFor="vehicle1"
                  style={{ marginTop: "-5px" }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "12px",
                      color: "#4F4F4F",

                    }}
                  >
                    Price Secondary Display<b></b>
                  </p>
                </label>
                <div>
                  <input
                    className="mx-4 mt-1"
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value={priceSecondaryDisplay === "false" ? true : false}
                  checked={priceSecondaryDisplay==="true" ?true :false}
                  onChange={(e) => { 
                    setPriceSecondaryDisplay(e.target.value)
                  }}
                  />
                </div>

              </div> */}
            </div>

            <div className="col">
              <div style={{ marginTop: "20px", display: "flex" }}>
                <label
                  className="ml-1 label-form "
                  for="vehicle1"
                  style={{ marginTop: "-5px" }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "12px",
                      color: "#4F4F4F",

                    }}
                  >
                    Gender<b></b>
                  </p>
                </label>
                <div>
                  <div className="col d-flex"> 
                    <div>

                      <select className="form-select mx-3" aria-label="Default select example"
                        value={gender}
                        onChange={(e) => {
                          
                          setGender(e.target.value)
                        }}
                      >
                        <option value="" disabled >Select Gender</option>
                        <option value="MEN">MEN</option>
                        <option value="WOMEN">WOMEN</option>
                        <option value="UNISEX">UNISEX</option> 
                        <option value="GIRLS">GIRLS</option> 
                        <option value="BOYS">BOYS</option> 
                      </select>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

              <p className="mt-5 font-weight-bold">Product Package Dimensions</p>
              <section>

                <div className="two-field">
                  <p>Length (CM)<b>*</b></p>

                  <input
                    type="number"
                    className={` form-control shadow-none ${commonError==="Length Required" ? "border-danger " : null}`}
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                    placeholder="Enter Length"
                    onKeyPress={(e) => onlyNumericWithDotAllow(e)}
                    // required
                  />

                </div>
                <div className="two-field">
                  <p>Breadth (CM)<b>*</b></p>

                  <input
                    type="number"
                    className={` form-control shadow-none ${commonError==="Width Required" ? "border-danger " : null}`}
                    value={breath}
                    onChange={(e) => setBreath(e.target.value)}
                    placeholder="Enter Breadth"
                    onKeyPress={(e) => onlyNumericWithDotAllow(e)}
                    // required
                  />


                </div>
              </section>
              <section>
                <div className="two-field">
                  <p>Height(CM)<b>*</b></p>

                  <input
                    type="number"
                    className={` form-control shadow-none ${commonError==="Height Required" ? "border-danger " : null}`}
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    placeholder="Enter Height"
                    onKeyPress={(e) => onlyNumericWithDotAllow(e)}
                    // required
                  />

                </div>

              </section>
              {/* <div className="radio-wrap">
            <h4>
              {' '}
              Specification <b> * </b>
            </h4>
            <div>
              <aside>
                <input
                  type="radio"
                  name="specification"
                  value="true"
                  checked={value1 === 'true' ? true : false}
                  onChange={handleChange1}
                />{' '}
                <span> Yes </span>{' '}
              </aside>{' '}
              <aside>
                <input
                  type="radio"
                  name="specification"
                  value="false"
                  checked={value1 === 'true' ? false : true}
                  onChange={handleChange1}
                />{' '}
                <span> No </span>{' '}
              </aside>{' '}
            </div>{' '}
          </div> */}

              {/* <div className="specification">
            {value1 === 'true' ? (
              <>
                {specs && specs.length > 0
                  ? specs.map((value, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-5 col-10">
                              <div class="form-group form-input">
                                <input
                                  placeholder="Title"
                                  value={value.name}
                                  className={
                                    'form-control form-control-nonround'
                                  }
                                  mandatory={true}
                                  onChange={(e) =>
                                    handleChange2(e, 'name', index)
                                  }
                                />{' '}
                              </div>{' '}
                            </div>{' '}
                            <div className="col-md-5 col-10 mr-0">
                              <div class="form-group form-input">
                                <input
                                  placeholder="Value"
                                  value={value.value}
                                  className={
                                    'form-control form-control-nonround'
                                  }
                                  mandatory={true}
                                  onChange={(e) =>
                                    handleChange2(e, 'value', index)
                                  }
                                />{' '}
                              </div>{' '}
                            </div>{' '}
                            <div>
                              <button
                                className="cross_button"
                                onClick={() => removespecs(index)}
                              >
                                {' '}
                                X{' '}
                              </button>{' '}
                            </div>{' '}
                          </div>{' '}
                        </>
                      );
                    })
                  : null}
                {specs.length < 5 ? (
                  <div className="add_button">
                    <button onClick={() => addspecs()}> +ADD </button>{' '}
                  </div>
                ) : null}{' '}
              </>
            ) : null}
          </div> */}

              <div id="short-description" className="cms-wrap">
                <p className="label-form"> Product Specification <b>*</b></p>
                <div className="cms-body">
                  {/* <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={newContent => setContent(newContent)}
                    onChange={newContent => {
                      // setContent(newContent) 
                    }}

                  /> */}


                  {/* <Editor
                    editorState={updateData}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                  /> */}
                  <Editor
                    onInit={(evt, editor) => { editorRef.current = editor }}
                    initialValue={content}
                    onEditorChange={(newText) => {
                      setContent({...content , newText})
                    }}
                    init={{
                      height: 200,
                      menubar: true,
                      plugins: [
                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                </div>


                <i className="error">{shortError}{shortCountError}</i>
              </div>

              <div id="short-description" className="cms-wrap">
                <p className="label-form">Product Description<b>*</b></p>
                <div className="cms-body ">
                  <Editor
                    onInit={(evt, editor) => { editorRef.current = editor }}
                    initialValue={content1}
                    onEditorChange={(newText) => {
                      setContent1({...content1 , newText})
                    }}
                    init={{
                      height: 200,
                      menubar: true,
                      plugins: [
                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {/* <Editor
                    editorState={updateData1}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange1}
                  /> */}
                  {/* <JoditEditor
                    ref={editor}
                    value={content1}
                    config={config}
                    tabIndex={1}
                    onBlur={newContent => setContent1(newContent)}
                    onChange={newContent => {
                      // setContent(newContent) 
                    }}

                  /> */}
                </div>
                <i className="error">{longError}{longCountError}</i>
              </div>

              <div id="short-description" className="cms-wrap">
                <p className="label-form">Size Chart<b></b></p>
                <div className="cms-body ">
                  <Editor
                    onInit={(evt, editor) => { editorRef.current = editor }}
                    initialValue={sizeChart}
                    onEditorChange={(newText) => { 
                      setSizeChart({...sizeChart , newText})
                    }}
                    init={{
                      height: 200,
                      menubar: true,
                      plugins: [
                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {/* <Editor
                    editorState={updateData2}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange2}
                  /> */}
                  {/* <JoditEditor
                    ref={editor}
                    value={content2}
                    config={config}
                    tabIndex={1}
                    onBlur={newContent => setContent2(newContent)}
                    onChange={newContent => {
                      // setContent(newContent) 
                    }}

                  /> */}
                </div>
                {/* <i className="error">{longError}{longCountError}</i> */}
              </div>

              <div id="short-description" className="cms-wrap">
                <p className="label-form">How To Measure</p>
                <div className="cms-body ">
                  {/* <Editor
                    editorState={updateData3}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange3}
                  /> */}
                  {/* <JoditEditor
                    ref={editor}
                    value={content3}
                    config={config}
                    tabIndex={1}
                    onBlur={newContent => setContent3(newContent)}
                    onChange={newContent => {
                      // setContent(newContent) 
                    }}
                  /> */}
                  <Editor
                    onInit={(evt, editor) => { editorRef.current = editor }}
                    initialValue={content3}
                    onEditorChange={(newText) => {
                      setContent3({...content3 ,newText})
                    }}
                    init={{
                      height: 200,
                      menubar: true,
                      plugins: [
                        'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                </div>
                {/* <i className="error">{longError}{longCountError}</i> */}
              </div>

              <div className="form-body">
                <div className="single-field">
                  <p>Manufacturer/Importer Address<b>*</b></p>
                  <textarea
                    type="text"
                    className={` form-control shadow-none ${commonError==="Manufacturer Address Required" ? "border-danger " : null}`}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Enter Address"
                    // required
                  />

                </div>
              </div>

              <section>
                <div className="two-field">
                  <p>Manufacturer Email<b>*</b></p>

                  {/* <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Manufacturer Email"
                    required
                  /> */}
                  <input
                    type="email"
                    name="email"
                    className={
                      // emailError !== "" ||
                      // errorMsg !== "" ||
                      emailError === "Email taken"
                        ? "error form-control form-control-round"
                        : "form-control form-control-round"
                    }
                    id="email"
                    placeholder="Type here"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  // onChange={handleChange}
                  />
                  {emailError && <p style={{ color: "red" }}>{emailError}</p>}

                </div>
                <div className="two-field">
                  <p>Manufacturer Contact Number<b>*</b></p>

                  <input
                  
                  className={` form-control shadow-none ${commonError==="Contact Number Required" ? "border-danger " : null}`}
                    type="text"
                    maxLength={10}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder="Enter Manufacturer Mobile Number"
                    onKeyPress={(e) => onlyNumberAllow(e)}
                    // required
                  />
                </div>
              </section>

              <div>
            {faqList.map((item,index,{length})=>{
              return(
                <>
                  <section>
                    <div className="two-field">
                        <p>
                          Enter FAQs
                        </p>
                      <div>
                        <div className="d-flex">
                        <input
                          type="text"
                          value={item?.question}
                          className={`form-control shadow-none`}
                          onChange={(e)=>handleChangeFaqQue(e,index)}
                          placeholder={`Enter ${index+1} FAQ`}
                          name={`faq${index+1}`}
                        />
                        {length - 1 === index && length < 10 ? (
                          <>
                            <img src={addIcon} alt="delete" className="add-icon ml-2" onClick={()=>manageList(index)}/>
                            <i class="bi bi-plus-circle-fill"></i>
                          </>
                        ) : null}
                        <br />
                        {length > 1 ? (
                          <img src={deleteIcon} alt="delete" className="delete-icon2" onClick={()=>handleRemove(index)}/>
                         ) : null}
                        </div>
                      </div>
                    </div>
                  </section>
                  <br />
                  <div>
                    <Editor
                      onInit={(evt, editor) => { editorRef.current = editor }}
                      value={item?.answer}
                      onEditorChange={(newText) => {
                        handleChangeFaqAns(newText,index)
                      }}
                      init={{
                        height: 200,
                        menubar: true,
                        plugins: [
                          'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                          'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                          'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />
                    {faqReq ? (
                              <p style={{color:'red'}}>Please enter above faq Question and Answer...</p>
                            ) : null}
                  </div>
                </>
              )
            })}
          </div>

              {/* <div className="radio-wrap">
            <h4>
              {' '}
              Food Type <b> * </b>
            </h4>
            <div>
              <aside>
                <input
                  type="radio"
                  name="foodType"
                  value="ELECTRONIC"
                  checked={foodType==="ELECTRONIC"?true:false}
                  onChange={handleChange}
                />{' '}
                <span> ELECTRONIC </span>{' '}
              </aside>{' '}
              <aside>
                <input
                  type="radio"
                  name="foodType"
                  value="NON-ELECTRONIC"
                  checked={foodType==="NON-ELECTRONIC"?true:false}
                  onChange={handleChange}
                />{' '}
                <span> NON-ELECTRONIC </span>{' '}
              </aside>{' '}
            </div>{' '}
          </div> */}
            </div>{' '}
            <i className="error">
          {catalogueError}
          {commonError}
        </i>
 
            <div className="button">
              <button className="px-5"> Update </button>{' '}
            </div>{' '}
          </form>{' '}


        </>

        :
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div>
      }
       
      {/* <span className='text-danger'>{commonError}</span> */}
    </Layout>
  );
};

export default EditProduct;
