import React, { useState, useRef } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Layout from "../../../layout/layout";
import left_arrow2 from "../../../assets/icons/down_arrow2.png";
import { useHistory } from "react-router";
import axiosauth from "../../../axios-config-auth";
import SuccessModal from "../../../components/halper/SuccessModal";
// import JoditEditor from "jodit-pro-react";
import { Editor } from '@tinymce/tinymce-react';

const AddGetKnowUS = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [isLink, setIsLink] = useState("true");
  const [link, setLink] = useState("");
  const [showsuccess, setShowSuccess] = useState(false);

  const editorRef = useRef(null);
  const [content, setContent] = useState("");
  // const config = {
  //   readonly: false,
  // };

  const handleUpdate = () => {
    let obj = {};
    // console.log(link)
    const regexp =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    var pattern =
      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

    if (isLink === "true") {
      if (pattern.test(link)) {
        obj = {
          headName: "GET TO KNOW US",
          name: name,
          is_link: isLink === "true" ? true : false,
          body: content.length > 5 ? content : "",
          url: isLink === "true" && link.length > 3 ? link : "",
        };
      } else {
        alert("Please Enter valid Link");
      }
    }

    if (isLink === "false") {
      obj = {
        headName: "GET TO KNOW US",
        name: name,
        is_link: isLink === "true" ? true : false,
        body: content.length > 5 ? content : "",
        url: isLink === "true" && link.length > 3 ? link : "",
      };
    }
    axiosauth
      .post("/footer/footer", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setShowSuccess(true);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        // setNetworkError(true)
      });
  };
  const closeModal = () => {
    history.push("/cms");
  };

  return (
    <Layout>
      {/* {!showLoader ? */}
      <>
        <div
          className="left_arrow2 back-arrow my-5 mx-3"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2 className="mx-3">GET TO KNOW US</h2>
        </div>
        <div className="container">
          <div className="single-field">
            <p>
              Page Name<b>*</b>
            </p>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-100 form-control"
              placeholder="Enter page name"
            />
          </div>

          <div className="radio-wrap">
            <h4> Is Link </h4>
            <div>
              <aside>
                <input
                  type="radio"
                  name="specification"
                  value="true"
                  checked={isLink === "true" ? true : false}
                  onChange={(e) => {
                    setIsLink(e.target.value);
                  }}
                />
                <span> Yes </span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="specification"
                  value="false"
                  checked={isLink === "true" ? false : true}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setIsLink(e.target.value);
                  }}
                  l
                />
                <span> No </span>
              </aside>
            </div>
          </div>
          {/* {isLink === 'true' ? */}
          <div
            className="single-field"
            style={{ display: isLink == "false" && "none" }}
          >
            <p>
              Link <b>*</b>
            </p>
            <input
              type="text"
              id="name"
              value={link}
              // validate={validateUrl }
              className="w-100 form-control"
              placeholder="Enter link"
              onChange={(e) => setLink(e.target.value)}
            />
          </div>
          <div
            className="cms-body my-4"
            style={{ display: isLink == "true" && "none" }}
          >
            <Editor
                onInit={(evt, editor) => { editorRef.current = editor }}
                initialValue=""
                onEditorChange={(newText) =>{
                  setContent(newText)
                }  }
                init={{
                  height: 200,
                  menubar: true,
                  plugins: [
                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            {/* <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => setContent(newContent)}
              onChange={(newContent) => {
                // setContent(newContent)
                // console.log("new content", newContent)
              }}
            /> */}
          </div>
          <div className="button">
            <button className="px-5" onClick={handleUpdate}>
              {" "}
              Add{" "}
            </button>{" "}
          </div>
        </div>
      </>
      {/* :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={500000}
                    />
                </div>
            } */}
      {/* {show === true ? <SuccessModal message="CMS updated!" onClick={closeModal} /> : null} */}
      {/* {redirect ? <Redirect to="/cms" /> : null}
            {show === true ? <SuccessModal message="CMS updated!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null} */}

      {showsuccess === true ? (
        <SuccessModal message="CMS Page Added" onClick={closeModal} />
      ) : null}
    </Layout>
  );
};

export default AddGetKnowUS;
