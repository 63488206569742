import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useHistory } from "react-router-dom";
import { fetchVariantValueById, updateVariantValue } from '../../redux/actions/variantAction';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import SuccessModal from '../../components/halper/SuccessModal';
import left_arrow2 from '../../assets/icons/down_arrow2.png'

const EditVariantForm = () => {
  const animatedComponents = makeAnimated()
  const dispatch = useDispatch();
  const [varId, setVarId] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const varData = useSelector(state => state.variantData);
  // console.log("varData:", varData.data);
  const history = useHistory();
  const { variantId } = useParams();
  const [displayName, setDisplayName] = useState("");
  const [serialNumber , setSerialNumber] = useState('')

  const handleSelect = (selectedOption) => {
    setVarId(selectedOption.value)
  }
  // console.log(varId)
  var varValueList = [];
  if (typeof varData.data === "object" && varData.data.length !== undefined) {
    for (var i = 0; i < Object.keys(varData.data).length; i++) {
      varValueList.push({ value: varData.data[i].variantId, label: varData.data[i].value });
    }
  }
  // console.log(varValueList);

  const [variantUpdate, setVariantUpdate] = useState({
    newValue: "",
  });

  const { newValue } = variantUpdate;

  const handleChange = (e) => {
    setVariantUpdate({
      ...variantUpdate,
      error: false,
      [e.target.name]: e.target.value,
    });
  }

  const updateVariant = (e) => {
    e.preventDefault()
    let obj = {
      "variantId": varId,
      "value": newValue,
      "displayName": displayName,
      "serialNumber" :parseInt(serialNumber),
    }

    // console.log("obj----->>>" , obj)
    dispatch(updateVariantValue(obj));
  }

  useEffect(() => {
    dispatch(fetchVariantValueById({ variantId: variantId }));
  }, [dispatch, variantId]);

  const closeModal = () => {
    setRedirect(true);
  }

  return (

    <>

      {redirect ? <Redirect to="/variant-manage" /> : null}
      {varData.data.success === true ? <SuccessModal message="Variant Value Updated!" onClick={closeModal} /> : null}

      <br />
      <br />
      <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
        <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" /> &nbsp;
        <h2>Edit Variant</h2>
      </div>

      <form onSubmit={updateVariant} className="form-wrap">
        {/* <div className="form-header">
          <h4>Edit Variant</h4>
        </div> */}
        <div className="form-body">
          <div>
            <p className="label-form">Variant Value <b>*</b></p>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={varValueList}
              name="variants"
              required
              onChange={handleSelect} />
          </div>
          <div className="single-field" id="short-description">
            <p>New Variant Value <b>*</b></p>
            <input type="text"
              placeholder="Variant Name"
              name="newValue"
              onChange={handleChange}
            />

          </div>
          <div className="single-field" id="short-description">
            <p>Display Name <b>*</b></p>
            <input type="text"
              placeholder="Variant Name"
              name="newValue"
              onChange={(e) => setDisplayName(e.target.value)}
            />

          </div>
          <div className="single-field" id="short-description">
            <p>Serial Number <b>*</b></p>
            <input type="number"
              placeholder="Serial Number"
              name="newValue"
              min='0'
              onChange={(e) => setSerialNumber(e.target.value)}
            />

          </div>
        </div>
        <div className="button">
          <button>update</button>
        </div>
      </form>
    </>
  )
}

export default EditVariantForm