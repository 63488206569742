import React, { useState, useEffect } from 'react'
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { useHistory, Link } from 'react-router-dom';
import axiosauth from '../../axios-config-auth';
import Pagination from "react-js-pagination";
import NoData from '../../components/halper/NoData';

const ReviewList = () => {
  const history = useHistory();
  const [reviews, setReviews] = useState([]);
  const [total, settotal] = useState(0);
  const pageCount = new URLSearchParams(window.location.search).get('page');
  const handlePageChange5 = (pageNumber) => {
    handlePageChange(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };
  const showDate = (data) => {
    var d = new Date(data);
    var date = d.getDate();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
    var year = d.getFullYear();
    var dateStr = date + " " + months[month] + " " + year;
    // var res =
    return dateStr;
  };
  const handlePageChange = (pageNumber) => {
    // history.push(`/managevendorbrand?pageCount=${pageNumber}`);
    let obj = {
      "fields": "max",
      "limit": 10,
      "count": (pageNumber - 1) * 10,
      "slug": localStorage.getItem("proslug"),
      "sortBy": {
        "type": "reviewedOn",
        "order": -1
      },

    }
    axiosauth.post('/products/load-product-reviews', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setReviews(res.data.reviews)
          settotal(res.data.reviewCount);
          // setShowLoader(false);
        } else {
          setReviews([])
          settotal(0);
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  useEffect(() => {
    let obj = {
      "fields": "max",
      "limit": 10,
      "count": (pageCount - 1) * 10,
      "slug": localStorage.getItem("proslug"),
      //  "status":"true",
      "sortBy": {
        "type": "reviewedOn",
        "order": -1
      },

    }
    axiosauth.post('/products/load-product-reviews', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setReviews(res.data.reviews)
          settotal(res.data.reviewCount);
          // setShowLoader(false);
        } else {
          setReviews([])
          settotal(0);
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [pageCount, history]);

  return (
    <>
      <div className="mt-5">
        <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
          {/* <div> */}
          <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" />&nbsp;
          <h2>Manage Review & Ratings </h2> <span className="product-name"> &nbsp; &nbsp;( Product : {localStorage.getItem("pname")})</span>


          <div className="button">
            {/* <Link to="/add-products-variant">
                        <button onClick={()=>localStorage.setItem("bname",varList[0].brand.name)}>Add Variant</button>
                    </Link> */}

          </div>
        </div>
      </div>
      <div className="table-wrap">
        <table>
          <thead style={{ display: reviews.length === 0 ? "none" : "" }}>
            <tr>
              <th>S.No</th>
              <th>Date</th>

              <th>Name of User</th>
              <th>Ratings</th>
              <th>Review</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {reviews && reviews.length > 0 ?

              reviews.map((value, index) => {
                return ( 
                  <tr key={index}>
                    <td>{((pageCount ? pageCount : 1) - 1) * 10 + (index + 1)}</td>

                    <td>{showDate(value.reviewedOn)}</td>
                    <td>{value.username}</td>
                    <td>
                      {value.rating}
                    </td>
                    <td>
                      {value.review}
                    </td>
                    <td
                      onClick={() => localStorage.setItem("reviewId", value.reviewId)}
                    >
                      <Link to={`/review-details`} >
                        <i class="fa fa-eye fa-eye-style"></i>
                        {/* <img src={eyeIcon} alt="eye-button" className="eye-icon" />     */}
                      </Link></td>
                  </tr>

                )

              })


              : (<>
                <NoData message="No Reviews" />
              </>)}



          </tbody>
        </table>
        <div className="pagination-custom">
          <Pagination
            hideDisabled
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            hideNavigation
            onChange={handlePageChange5}
          />
        </div>
      </div>
    </>
  )
}

export default ReviewList
