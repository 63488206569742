import React from 'react';
// import login_art from "../../assets/images/login_art.png"
import login_art from "../../assets/images/gajari.svg"
import ForgotPasswordForm from '../../wrapper/forgotPassword/ForgotPasswordForm';

const ForgotPassword = () => {

  return (
    <div className="login-wrap">
      <div className="login-art" style={{marginTop :"7%"}}>
      <h1> </h1>
        <img src={login_art} alt="login-art" />
      </div>
      <div className="loginform-wrap">
        <ForgotPasswordForm />
      </div>
    </div>
  )
}

export default ForgotPassword