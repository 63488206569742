import {
  FETCH_SUB_CATEGORY,
  FETCH_SUB_CATEGORY_SUCCESS,
  FETCH_SUB_CATEGORY_FAILURE,
  CREATE_SUB_CATEGORY,
  FETCH_SUB_CATEGORY_BY_ID,
  SEARCH_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY
} from '../constants/types';
import axiosauth from '../../axios-config-auth';

export function loadingSubCategory() {
  return {
    type: FETCH_SUB_CATEGORY,
  };
}

export function fetchSubCategory(category) {
  return {
    type: FETCH_SUB_CATEGORY_SUCCESS,
    payload: category
  };
}

export function fetchSubCategoryFail(category) {
  return {
    type: FETCH_SUB_CATEGORY_FAILURE,
    payload: category
  }
}
export function loadCategoryById(category) {
  return {
    type: FETCH_SUB_CATEGORY_BY_ID,
    payload: category
  };
}

export function searchCategory(category) {
  return {
    type: SEARCH_SUB_CATEGORY,
    payload: category
  };
}
export function addSubCategory(category) {
  return {
    type: CREATE_SUB_CATEGORY,
    payload: category
  };
}

export function EditSubCategory(category) {
  return {
    type: UPDATE_SUB_CATEGORY,
    payload: category
  };
}

// -------------------------------------------------

export const loadSubCategory = (search) => {
  return async dispatch => {
    dispatch(loadingSubCategory());
    let obj = {
      "filters": {
        "type": "sub1",
        name: search
      }
    }
    axiosauth.post('/categories/load', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log(res)
        if (res.success === true) {
          dispatch(fetchSubCategory(res.data));
        } else {
          dispatch(fetchSubCategoryFail(res.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchSubCategoryFail("Network Error"));
      })

  };
};

export const loadSubCategoryByPage = (pageNumber) => {
  return async dispatch => {
    dispatch(loadingSubCategory());
    let obj = {
      count: (pageNumber - 1) * 10,
      filters: {
        type: "sub1",
      },
      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
    };
    axiosauth.post('/categories/load', obj)
      .then(async (response) => {
        // console.log(response.data)
        let res = JSON.parse(response.data)
        // console.log(res)
        if (res.success === true) {
          dispatch(fetchSubCategory(res.data));
        } else {
          dispatch(fetchSubCategoryFail(res.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchSubCategoryFail("Network Error"));
      })
  };
};


export const loadSubCategoryById = (id) => {
  return async dispatch => {
    dispatch(loadingSubCategory());
    // console.log("category by id")
    let obj = {
      "categoryId": id
    };
    axiosauth.post('/categories/load-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          // console.log("if res:", res)
          dispatch(fetchSubCategory(res.data.category.category[0]));
        } else {
          dispatch(fetchSubCategoryFail(res.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchSubCategoryFail("Network Error"));
      })

  };
};


export const deleteSubCategoryById = (obj) => {
  return async dispatch => {
    dispatch(loadingSubCategory());
    axiosauth.post('/categories/delete-categories', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("response->", res)
        // console.log("inside action delete - ", res)
        if (res.success === true) {
          // console.log("successfully deleted !", res)
          dispatch(loadSubCategory());
        } else {
          // console.log("failed !!")
          dispatch(fetchSubCategoryFail(res.message));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchSubCategoryFail("Network Error"));
      })

  };
};


const subCategoryImageUpdate = (cId, imageFile) => {
  var formData = new FormData();
  formData.append("categoryId", cId);
  formData.append("type", "rectangle");
  formData.append("image", imageFile);
  axiosauth.post('/categories/edit-image', formData)
    .then(async (response) => {
      let res = JSON.parse(response.data)
      if (res.success === true) {
        console.log("image res:", res);
      } else {
        console.log("..")
      }
    })
    .catch((error) => {
      console.log(error);
    })
}

export const createSubCategory = (obj, fileImage) => {
  return async dispatch => {
    dispatch(loadingSubCategory());
    axiosauth.post('/categories/create-category', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          subCategoryImageUpdate(res.message.categoryId, fileImage)
          dispatch(addSubCategory(res));
        } else {
          alert(res.message)
          dispatch(fetchSubCategoryFail(res.message));
        }
      })
      .catch((error) => {
        // console.log("error")
        console.log(error);
        dispatch(fetchSubCategoryFail("Network Error"));
      })

  };
};

export const updateSubCategory = (obj, fileImage) => {
  // console.log("obj.categoryId",obj.categoryId)
  // console.log("fileImage",fileImage)
  return async dispatch => {
    dispatch(loadingSubCategory());
    axiosauth.post('/categories/update-category', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("res:", res)
        if (res.success === true) {
          subCategoryImageUpdate(obj.categoryId, fileImage)
          dispatch(EditSubCategory(res));
        } else {
          dispatch(fetchSubCategoryFail(res.message));
        }
      })
      .catch(() => {
        // console.log(error);
        dispatch(fetchSubCategoryFail("Network Error"));
      })

  };
};
