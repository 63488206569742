import React, { useState, useEffect } from 'react'
import SearchBox from '../../components/halper/SearchBox';
// import collectionImg from '../../assets/image/collectionImg.png';
import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCollection, deleteCollection, fetchPageCollection } from '../../redux/actions/collectionAction';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {useHistory } from 'react-router-dom';
import axiosInstance from '../../axios-config-auth';

const CollectionList = () => {
    const [activePage, setActivePage] = useState(1);
    const dispatch = useDispatch();
    // const collections = useSelector(state => state.collectionData);
    const [collection , setCollection] = useState()
    const [ loading , setLoading] = useState(false);
    
    // for pagination
    const history = useHistory();
    const pageCount = new URLSearchParams(window.location.search).get('page');

    const loadCollection = () =>{
        axiosInstance.post("gazariCollection/load-collection").then((response) =>{
            let res = JSON.parse(response.data); 

            if(res?.message === 'Collections is Empty'){
                setCollection()
                setLoading(true)
            }

            if(res.success === true){
                setCollection(res.collections)
                setLoading(true)
            }
        }).catch( (erreor) =>console.log(erreor))
    }

    useEffect(() =>{
loadCollection()
    } ,[])


    // const handlePageChange = (pageNumber) => {
    //     history.push(`/collection?pageCount=${pageNumber}`)
    //     setActivePage(pageNumber);
    //     dispatch(fetchPageCollection(pageNumber))
    // }



    // useEffect(() => {
    //     // if(pageCount===null){
    //     //     history.push(`/collection?pageCount=1`)
    //     // }
    //     // dispatch(fetchPageCollection(pageCount));


    //     if(activePage===1)
    //         {
    //             let currentUrlParams = new URLSearchParams(window.location.search);
    //             currentUrlParams.set('page', (pageCount ? pageCount : 1));
    //             history.push(window.location.pathname + "?" + currentUrlParams.toString());
    //         }

    //         if(pageCount !== null){
    //             dispatch(fetchPageCollection(pageCount));
    //         }else(dispatch(fetchPageCollection(1)))


    // }, [dispatch,pageCount,history,activePage]);

 /**
 * TODO: Pagination
 *@version 1.0.0
 * @author [Unknown]
 */


     // Handle Pagination

//   const [ locationKeys, setLocationKeys ] = useState([]);

const getQueryVariable = (variable) => {
var query = window.location.search.substring(1);
console.log(query)//"app=article&act=news_content&aid=160990"
var vars = query.split("&");
console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
for (var i = 0; i < vars.length; i++) {
  var pair = vars[i].split("=");
  console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
  if (pair[0] === variable) { return pair[1]; }
}
return (false);
}

// useEffect(() => {
// return history.listen(location => {
//   if (history.action === 'PUSH') {
//     setLocationKeys([ location.key ])
//   }
//   console.log("history.action",history.action)

//   if (history.action === 'POP') {
//     if (locationKeys[1] === location.key) {
//       setLocationKeys(([ _, ...keys ]) => keys)
//              console.log("forward")
//         console.log("js",getQueryVariable('page'))
//         handlePageChange1( getQueryVariable('page'))
//       // Handle forward event

//     } else {
//       setLocationKeys((keys) => [ location.key, ...keys ])
//       console.log("backward",location.key)
//       console.log("js",getQueryVariable('page'))
//       handlePageChange1( getQueryVariable('page'))

//     }
//   }
// })
// }, [ locationKeys,history])

// const handlePageChange1 = (pageNumber) => {
//   setActivePage(pageNumber);
// };

// const handlePageChange5 = (pageNumber) => {
//   console.log("dd",pageNumber)
//   dispatch(fetchPageCollection(pageNumber));
//   setActivePage(pageNumber);
//   let currentUrlParams = new URLSearchParams(window.location.search);
//   currentUrlParams.set('page', pageNumber);
//   history.push(window.location.pathname + "?" + currentUrlParams.toString());
// };



    return (
        <>
            {/* <SearchBox
                placeholder="Search collection...."
                onChange={(e) => dispatch(fetchCollection(e.target.value))}
            /> */}
            <div className="button">
           <button onClick={()=>history.push("/add-collection")}>
                Add Collection
            </button>
        </div>
           
          
            {/* {!collections.error ? */}
                <div className="table-wrap">
                    <table>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Collection Name</th>
                                <th>Collection Type</th>
                                <th>Name</th>
                                <th>Start Value</th>
                                <th>End Value</th>
                                <th></th>

                            </tr>
                        </thead>
                        <tbody>

                            {!loading ?
                                <div className="loader-style">
                                    <Loader
                                        type="ThreeDots"
                                        color="rgba(32, 159, 132, 1)"
                                        height={100}
                                        width={100}
                                        timeout={3000} //3 secs

                                    />
                                </div> :
                                <>
                                    {
                                        collection && collection.length > 0 ?
                                            <>
                                                {collection.map((collection, index) => { 
                                                    const removeCollection = () => {
                                                        let obj = {
                                                            "_id": collection._id
                                                        } 
                                                        confirmAlert({
                                                            // title: 'Change Status',
                                                            message: 'Are you sure to do this.',
                                                            buttons: [
                                                                {
                                                                    label: 'Yes',
                                                                    onClick: () => { 
                                                                        // dispatch(deleteCollection(obj));
                                                                        axiosInstance.post('gazariCollection/delete-collection', obj)
                                                                        .then(async (response) => { 
                                                                            let res = JSON.parse(response.data);
                                                                            if (res.msg === "deleted successfully") {
                                                                                 loadCollection();
                                                                                // dispatch(fetchCollection());
                                                                            } else {
                                                                                // dispatch(collectionFail(res.message));
                                                                            }
                                                                        })
                                                                        .catch((error) => {
                                                                            console.log(error);
                                                                            // dispatch(collectionFail("Network Error"));
                                                                        })
                                                                    }
                                                                },
                                                                {
                                                                    label: 'No',
                                                                    onClick: () => {
                                                                        console.log("not detele")
                                                                    }
                                                                }
                                                            ]
                                                        });
                                                    }

                                                    return (
                                                        <tr key={index}>
                                                            <td>{((pageCount?pageCount:1)-1)*10 + (index+1)}</td>
                                                            <td className="titleStyle">{(collection?.title)?.toUpperCase()}</td>
                                                            <td>{(collection?.type)?.toUpperCase()}</td>
                                                            <td>{collection.discountFrom === null ? collection.priceLt===null? collection.searchKey:"-" : "-"}</td>
                                                            <td>{collection.discountFrom === null ? collection.priceLt === null ? "-" :collection.priceLt : collection.discountFrom}</td>

                                                            
                                                            <td>{collection.discountTo === null ? collection.priceGt=== null ? "-":collection.priceGt : collection.discountTo}</td>
                                                            <td className="collection-img">
                                                                <img src={collection.image} alt="" />
                                                            </td>
                                                            <td>
                                                                <Link to={`/edit-collection/${collection._id}`} >
                                                                    <img src={editIcon} alt="" className="edit-icon" />
                                                                </Link>
                                                                <img onClick={removeCollection} src={deleteIcon} alt="" className="delete-icon" />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </>
                                            : null
                                    }
                                </>
                            } 
                        </tbody>
                    </table>
                    {/* <div className="pagination-custom">
                        <Pagination
                            hideDisabled
                            activePage={parseInt(pageCount)}
                            itemsCountPerPage={10}
                            totalItemsCount={collections.data.count}
                            pageRangeDisplayed={5}
                            hideNavigation
                            // onChange={handlePageChange5}
                        />
                    </div> */}
                </div>
                
                {/* <div className="network-error">
                    {collections.error}
                </div>
            } */}
        </>
    )
}

export default CollectionList
