import React, {useState, useEffect} from 'react';
import Layout from "../../layout/layout";
import axiosauth from '../../axios-config-auth';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { useHistory,useParams, Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

const VariantViewDetails = () => {
const { productVariantId } = useParams();
    const history = useHistory();
    const [showLoader, setShowLoader] = useState(true);


    const[youtubeurl,setyoutubeurl]=useState(null)
    const [file, setFile]=useState([]);
     const[base,setbase]=useState([])
    // const [product, setproduct] = useState({
    //     "name": "",
    //     "shortDescription": "",
    //     "longDescription": ""
    // })
    // const { shortDescription, longDescription } = product;



    // const [specs, setspecs] = useState([{
    //     "name": "",
    //     "value": ""
    // }])

const [cateName, setCateName]=useState("");
// const [catImage, setCatImage]=useState([]);
const [allVariant, setAllVariant]=useState([])

useEffect(() => {

    const fetchVarData=()=>{

    let obj = {
        "productVariantId":productVariantId
    }
    axiosauth.post('/product-variant/find-product-variant-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        
        if (res.success === true) {
            setShowLoader(false);
            console.log("variant data---->", res.result[0])
            setyoutubeurl(res.result[0].youtubeUrl)
            // setproduct({
              
            //     name:res.result[0].name,
            //     shortDescription:res.result[0].shortDescription,
            //     longDescription:res.result[0].longDescription,
               
            // })
            // if(res.result[0].imageUrls){
            //     setFile(res.result[0].imageUrls)
            // }

            setAllVariant(res.result[0].variant)
         
            // setspecs(res.result[0].specification)
            // if(res.result[0].specification.length===0)
            //   {
                  
            //   }
        } else {
         
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }

    fetchVarData();

        let obj = {
            "catalogueId":localStorage.getItem("image")
        }
        axiosauth.post('/catalogue/find-catalogue-by-id', obj)
        .then(async (response) => {
            let res = JSON.parse(response.data)
            console.log("catelogue----->",res.result[0])
            if (res.success === true) {
                setCateName(res.result[0].name)
                setbase(res.result[0])

                // setCatImage(res.result[0].imageUrls)
            // setCollections(res.data.collection)
                // setbase(res.result[0])
            } else {
            
            }
        })
        .catch((error) => {
            console.log(error);
        })


    const findVariants = () => {
        let obj = {

        }
        axiosauth.post('/variants/findAllVariants/', obj).then(response => {
            let res = JSON.parse(response.data)
            if (res.success === true) {
                let variants = [];
                var varList = []
                variants.push({
                    "parent": {
                        "_id": "5f60998ee0cb47251035e65b",
                        "variantId": "yikh8vDPiUjkKVLO1bneTmkBQhbvRSRa",
                        "variantName": "colorzzzzzzzzzzzzzz",
                        "__v": 0
                    },
                    "values": [
                        {
                            "_id": "5f6c5b8be2eab8709342c308",
                            "value": "redzzzzzzzzzzzzzzz",
                            "parentVariant": "5f60998ee0cb47251035e65b",
                            "variantId": "color-red",
                            "__v": 0
                        },
                        {
                            "_id": "5f6c5b90e2eab8709342c309",
                            "value": "blackzzzzzzzzzzzzzz",
                            "parentVariant": "5f60998ee0cb47251035e65b",
                            "variantId": "color-black",
                            "__v": 0
                        },
                        {
                            "_id": "5f6c5b94e2eab8709342c30a",
                            "value": "goldenzzzzzzzzzzzzzzzzz",
                            "parentVariant": "5f60998ee0cb47251035e65b",
                            "variantId": "color-golden",
                            "__v": 0
                        }
                    ]
                })
                for (var i = 0; i < res.data.length; i++) {
                    variants.push(res.data[i])
                    varList.push({lavel:res.data[i].parent.variantName,value:res.data[i].parent._id})
                }

            }
            else {

            }

        }).catch((error) => {
            console.log(error);
        }).then(() => {
            console.log("-----always executes");
        })
    }
    findVariants()
   
  }, [productVariantId]);


    return (
        <Layout>
        {!showLoader ?

            <div>
                <section className="detail-heading">
                <img  onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" />
                    <h3>Variant Details</h3>
                </section>
        
                
                <section className="detail-body" >
                    <div>Product Name</div>
                    <div className="titleStyle">{cateName}</div>
                </section> 
                <section className="detail-body" >
                    <div>Variant</div>
                    <div className="titleStyle">
                        {allVariant && allVariant.length>0 ?
                            allVariant.map((v, i)=>{
                                return(
                                    <span className='titleStyle'> {v?.parentVariant?.variantName}-{v?.value}   </span>
                                )
                            })
                        :

                        "No"}

                    </div>
                </section>

                {/* <section className="detail-body" >
                    <div>Specification</div>
                    <div className="titleStyle">
                    {specs && specs.length>0 ? 
                    specs.map((spe, index)=>{
                        return(
                        <span key={index}>{spe.name+"-"+spe.value} <br/></span>
                        )
                    })
                    
                    :"No"}
                    </div>
                </section> */}

                {/* <section className="detail-body" >
                    <div>Short Description </div>
                    <div className="titleStyle">{shortDescription}</div>
                </section>

                <section className="detail-body" >
                    <div>Long Description</div>
                    <div className="titleStyle">{longDescription}</div>
                </section> */}
                {/* <section className="detail-body" >
                    <div>Youtube URL</div>
                   
                      
                    <div className="titleStyle">
                            {catImage && catImage.length>0 && file.length<1 ?(base.youtubeUrl):(youtubeurl)
                            }
                            </div> 
                </section> */}
                {/* <section className="detail-body" >
                    <div>Variant Images</div>
                    <div>

                    {file && file.length>0 ?
                        file.map((file1, index)=>
                            <img key={index} src={file1.url} alt="" style={{height:'150px', width:'150px', padding:'10px'}}/>    
                            )
                            :null
                            }

                            {catImage && catImage.length>0 && file.length<1 ?
                                catImage.map((imgUrl, index)=>
                            <img key={index} src={imgUrl.url} alt="" style={{height:'150px', width:'150px', padding:'10px'}}/>    
                            )
                            :null
                            }
                        
                    </div>
                </section>  */}
                 <div className="button"> 
                    <button  onClick={() => history.goBack()}>Back</button> &nbsp;
                
                    <Link to={`/update-brand-variant/${productVariantId}`}>
                        <button>Edit</button>
                    </Link>
                </div>
                <br/> 
                </div>
                :
          <div className="loader-style">
              <Loader
                  type="ThreeDots"
                  color="rgba(32, 159, 132, 1)"
                  height={100}
                  width={100}
                  timeout={30000} //3 secs
              />
          </div>
        }

        </Layout>
    )
}

export default VariantViewDetails
