import React, { useState, useEffect } from 'react';
import Layout from '../../layout/layout';
import axiosauth from '../../axios-config-auth';
import { useHistory, useParams, Link } from 'react-router-dom';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import Loader from 'react-loader-spinner';
import parse from 'html-react-parser';

const ProductDetails = () => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(true);
  // const [catImg, setCatImg] = useState([]);
  const { catalogueId } = useParams();

  // console.log("image url" , catImg)

  const [Subcategory, setSubCategory] = useState([
    {
      _id: '5f6c5b17e2eab8709342c302',
      categoryId: 'Y8wH4iLz',
      name: 'Sxxxxxxxxxxxxxxxxxxxxxxxkirt',
      slug: 'skirt',
      seoTitle: 'sports',
      shortDescription: "it's all about sports and product associated to it",
      icon: '',
      image: '',
      type: 'sub1',
      featured: true,
      premiumSeller: [],
      tags: ['dasdas', 'asdsad'],
    },
  ]);

  const [product, setproduct] = useState({
    brandname: '',
    name: '',
    title: '',
    shortDescription: '',
    longDescription: '',
    foodType: '',
    countryOrigin: '',
    dispatchAddress: '',
    hsnCode: '',
    itemWeight: '',
    manufacturerAddress: '',
    manufacturerEmail: '',
    manufacturerMobile: '',
    materialCare: '',
    searchTags: '',
    productTags: '',
    sizeChart: '',
    howTomeasure: "",
    packageContents: "",
    returnDays: "",
    isExchangable: "",
    isReturnable: "",
    // materialCare :"",

  });
  const { brandname, name, title, shortDescription, longDescription, countryOrigin,
    dispatchAddress, hsnCode, itemWeight, manufacturerAddress, manufacturerEmail,
    manufacturerMobile, materialCare, searchTags, productTags, sizeChart,
    howTomeasure, packageContents, returnDays, isExchangable, isReturnable } = product;

  // const [specs, setspecs] = useState([
  //   {
  //     name: '',
  //     value: '',
  //   },
  // ]);


  const [gstValue, setGstValue] = useState("");
  const [selectsubcategory, setselectsubcategory] = useState("")
  const [subsubCategory, setSubsubCategory] = useState('')
  const [categoryName, setCategoryName] = useState("");
  const [imageUrls, setimageUrls] = useState();
  // const[youtubeurl,setyoutubeurl]=useState(null)



  useEffect(() => {

    let obj = {
      "catalogueId": catalogueId
    }
    axiosauth.post('/catalogue/find-catalogue-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {

          setShowLoader(false);
          // setyoutubeurl(res.result[0].youtubeUrl)
          setproduct({
            brandname: res.result[0]?.brand.name,
            name: res.result[0].name,
            title: res.result[0].title,
            shortDescription: res.result[0].shortDescription,
            longDescription: res.result[0].longDescription,
            hsncode: res.result[0].hsnCode,
            foodType: res.result[0].foodType,
            countryOrigin: res.result[0].countryOrigin,
            dispatchAddress: res.result[0].dispatchAddress,
            hsnCode: res.result[0].hsnCode,
            itemWeight: res.result[0].itemWeight,
            manufacturerAddress: res.result[0].manufacturerAddress,
            manufacturerEmail: res.result[0].manufacturerEmail,
            manufacturerMobile: res.result[0].manufacturerMobile,
            materialCare: res.result[0].materialCare,
            searchTags: res.result[0].searchTags,
            productTags: res.result[0].productTags,
            sizeChart: res.result[0].sizeChart,
            howTomeasure: res.result[0]?.howTomeasure,
            packageContents: res.result[0].packageContents,
            imageUrls: res.result[0].imageUrls,
            returnDays: res.result[0].returnDays,
            isReturnable: res.result[0].isReturnable,
            isExchangable: res.result[0].isExchangable,
          })
          setimageUrls(res.result[0].imageUrls)
          //  setCatImg(res.result[0].imageUrls)
          setGstValue(res.result[0].gst.gstName)
          setCategoryName(res.result[0].category.cat.name);
          //  setspecs(res.result[0].specification)
          setselectsubcategory(res.result[0].category.sub1.name)
          setSubsubCategory(res.result[0].category?.sub2?.name)
          var sub = res.result[0].category.sub1.name
          let obj = {
            "categoryId": res.result[0].category.cat.categoryId
          }
          axiosauth.post('/categories/load-by-id', obj).then(response => {

            let res = JSON.parse(response.data)

            if (res.success === true) {
              // setSubCategory([])
              let arr = []
              // Subcategory=[]
              arr.push({
                "_id": "5f6c5b17e2eab8709342c302",
                "categoryId": "Y8wH4iLz",
                "name": "Skirt",
                "slug": "skirt",
                "seoTitle": "sports",
                "shortDescription": "it's all about sports and product associated to it",
                "icon": "",
                "image": "",
                "type": "sub1",
                "featured": true,
                "premiumSeller": [],
                "tags": [
                  "dasdas",
                  "asdsad"
                ]
              })
              for (var i = 0; i < res.data?.childCategories?.length; i++) {
                arr.push(res.data.childCategories[i])
              }
              setSubCategory(arr)

              for (var j = 0; j < res.data?.childCategories?.length; j++) {
                if (res.data.childCategories[j].name === sub) {
                  //  /  setSubCategoryId(res.data.childCategories[j]._id)
                }
              }


            }
            else {

            }

          }).catch((error) => {
            console.log(error);
          }).then(() => {
            console.log("-----always executes");
          })

          if (res.result[0].specification.length === 0) {
            //  setValue1('no')
          }
        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      })


    const loadCategory = () => {
      let obj = {
        filters: {
          type: 'category',
        },
      };
      var catlist = [];
      axiosauth
        .post('/categories/load', obj)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            for (var i = 0; i < res.data.categories.length; i++) {
              catlist.push({
                catId: res.data.categories[i].categoryId,
                label: res.data.categories[i].name,
                catObjid: res.data.categories[i]._id,
              });
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          console.log('-----always executes');
        });
    };
    loadCategory();
  }, [catalogueId]);
  var subcatlist = [];
  if (Subcategory && Subcategory.length > 0) {
    for (var k = 0; k < Subcategory.length; k++) {
      subcatlist.push({
        subCatId: Subcategory[k]._id,
        label: Subcategory[k].name,
      });
    }
  }


  return (
    <Layout>


      {!showLoader ?

        <div style={{ marginBotton: '30px' }}>
          <section className="detail-heading">
            <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
              <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" />
              <h2>Product Details :</h2>
            </div>
          </section>


          <section className="detail-body" >
            <div>Brand Name :</div>
            <div className="titleStyle">{brandname}</div>
          </section>
          <section className="detail-body" >
            <div>Product Name :</div>
            <div className="titleStyle">{name}</div>
          </section>
          <section className="detail-body" >
            <div>Product Title :</div>
            <div className="titleStyle">{title}</div>
          </section>
          <section className="detail-body" >
            <div>Categories :</div>
            <div className="titleStyle">{categoryName}</div>
          </section>

          <section className="detail-body" >
            <div>Sub-Categories :</div>
            <div className="titleStyle">{selectsubcategory}</div>
          </section>

          <section className="detail-body" >
            <div>Sub-Sub-Categories :</div>
            {subsubCategory && subsubCategory.length > 0 &&
              <div className="titleStyle">{subsubCategory}</div>}
          </section>

          <section className="detail-body" >
            <div>GST :</div>
            <div className="titleStyle">{gstValue}</div>
          </section>

          <section className="detail-body" >
            <div>Country of Origin :</div>
            <div className="titleStyle">{countryOrigin}</div>
          </section>

          <section className="detail-body" >
            <div>Item Weight :</div>
            <div className="titleStyle">{itemWeight} Grams</div>
          </section>
          <section className="detail-body" >
            <div>Return Days :</div>
            {isExchangable === false && isReturnable === false &&
              <div className="titleStyle">
               Item is not eligible for exchange or return</div> 
            }
            {isExchangable === true && isReturnable === true &&
              <div className="titleStyle">
                Item eligible for exchange or return within {returnDays} days of delivery</div> 
            }
            {isExchangable === true && isReturnable === false &&
              <div className="titleStyle">
                Item eligible for exchange within {returnDays} days of delivery</div>
            }
            {isExchangable === false && isReturnable === true &&
              <div className="titleStyle">
                Item eligible for return within {returnDays} days of delivery</div>
            }

          </section>
          <section className="detail-body" >
            <div>HSN Code :</div>
            <div className="titleStyle">{hsnCode}</div>
          </section>
          <section className="detail-body" >
            <div>packageContents :</div>
            <div className="titleStyle">{packageContents}</div>
          </section>

          <section className="detail-body" >
            <div>Manufacturer Address :</div>
            <div className="titleStyle">{manufacturerAddress}</div>
          </section>
          <section className="detail-body" >
            <div>Dispatch Address :</div>
            <div className="titleStyle">{dispatchAddress}</div>
          </section>
          <section className="detail-body" >
            <div>Manufacturer Email & Mobile :</div>
            <div className="titleStyle">{manufacturerEmail} , {manufacturerMobile}</div>
            {/* <div className="titleStyle">{manufacturerMobile}</div> */}
          </section>
          <section className="detail-body" >
            <div>Material & Care :</div>
            <div className="titleStyle">{materialCare}</div>
          </section>
          <section className="detail-body" >
            <div>Product Tags :</div>
            <div className="titleStyle">{productTags}</div>
          </section>




          {/* <section className="detail-body" >
            <div>Specification</div>
            <div className="titleStyle">
            {specs && specs.length>0 ? 
              specs.map((spe, index)=>{
                return(
                  <span key={index}>{spe.name+"-"+spe.value} <br/></span>
                )
              })
            
            :"No"}
            </div>
          </section> */}
          <section className="detail-body" >
            <div>Search tags :</div>
            <div className="titleStyle">{searchTags}</div>
          </section>

          <section className="detail-body" >
            <div>Images:</div>
            <div className="titleStyle">{
              imageUrls && imageUrls.length > 0 && (
                imageUrls.map((imgurl, index) => {
                  return (
                    <>
                      <img key={index} src={imgurl.url} alt="" style={{ height: '150px', width: '120px', padding: '5px' }} />
                    </>
                  )
                })
              )
            }</div>
          </section>


          <section className="detail-body">
            <div>Product Specification</div>
            {shortDescription && shortDescription.length > 5 &&
              <div className="titleStyle"   >{parse(shortDescription)}</div>
            }

          </section>

          <section className="detail-body" >
            <div>Product Description :</div>
            {longDescription && longDescription.length > 5 &&
              (
                <div className="titleStyle">{parse(longDescription)}</div>

              )
            }
          </section>
          <section className="detail-body" >
            <div>Size Chart :</div>
            {sizeChart && sizeChart.length > 5 &&
              (
                <div className="titleStyle">{parse(sizeChart)}</div>

              )
            }
          </section>
          <section className="detail-body" >
            <div>How To Measure :</div>
            {howTomeasure && howTomeasure.length > 5 &&
              (
                <div className="titleStyle">{parse(howTomeasure)}</div>

              )
            }
          </section>

          {/* <section className="detail-body" >
            <div>Toy Type</div>
            <div className="titleStyle">{foodType}</div>
          </section> */}
          {/* <section className="detail-body" >
            <div>Youtube URL</div>
            <div className="titleStyle">{youtubeurl}</div>
          </section> */}
          {/* <section className="detail-body" >
            <div>catalogue Images</div>
            <div>
            {catImg && catImg.length>0 ?
            catImg.map((imgUrl, index)=>{
              return(
                  <img src={imgUrl.url} alt="" style={{height:'100px', width:'100px', padding:'5px'}}/>
              )
            })
            :null}
            
            </div>
          </section> */}


          <div className="button">

            <button onClick={() => history.goBack()}>Back</button> &nbsp;

            <Link to={`/update-product/${catalogueId}`}>
              <button>Edit</button>
            </Link>
          </div>

        </div>
        :
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div>
      }

      <br />

    </Layout>
  );
};

export default ProductDetails;
