import React from 'react';
import Layout from "../../layout/layout"
import CategoryMenu from '../../components/menus/categoryMenu';

const CategoryManage = () => {

  return (
    <Layout>
      <div>
        <CategoryMenu />
      </div>
    </Layout>
  )
}

export default CategoryManage