import React, { useState, useEffect } from 'react';
import left_arrow2 from './../assets/icons/down_arrow2.png'
import { useParams, useHistory } from 'react-router-dom';
import axiosauth from './../axios-config-auth';
// import noImage from './../assets/images/no-img.png';
import Loader from 'react-loader-spinner';
import Layout from "../layout/layout"


const CartView = () => {

  const { userId } = useParams();
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(true);
  const [user, setUser] = useState([]);

  useEffect(() => {
    let obj = {
      "userId": window.location.href.split("cart-view/")[1]
    }
    axiosauth.post('/admin/load-user-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          // console.log("==== useeeeerrr===>", res.data.user)
          setUser(res.data.user)
          setShowLoader(false);

        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [userId]);

  // console.log("--user-->",user)




  // Custom date
  const showDate = (data) => {
    var d = new Date(data);
    var date = d.getDate();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
    var year = d.getFullYear();
    var dateStr = date + " " + months[month] + " " + year;
    // var res =
    return dateStr;
  };


  return (
    <Layout>


      <div>
        <section className="detail-heading">
          <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" />
            <h2>Cart Detail</h2>
          </div>
        </section>

        {!showLoader ?
          <>
            <section className="detail-body" >
              <div>NAME</div>
              <div className="titleStyle">{user?.firstName + " " + user?.lastName}</div>
            </section>

            {user?.cart && user.cart?.length > 0 ?
              user?.cart?.map((c, index) => {
                return (
                  <div key={index}>
                    <br />
                    Product ({index + 1})<br /> <br />
                    <section className="detail-body" >
                      <div>Product Name</div>
                      <div >
                        {c.productId?.productCatalogue?.name}
                        <small className="ml-4">
                          {c.productId?.selectedVariant?.variant[0] ? c.productId?.selectedVariant?.variant[0]?.parentVariant?.variantName : ""}
                          {c.productId?.selectedVariant?.variant[0] ? "-" : ""}
                          {c.productId?.selectedVariant?.variant[0] ? c.productId?.selectedVariant?.variant[0].value : ""}</small><br></br>
                        <small>{c.productId?.selectedVariant?.variant[1] ? " " + c.productId?.selectedVariant?.variant[1]?.parentVariant?.variantName : ""}
                          {c?.productId?.selectedVariant?.variant[1] ? "-" : ""}
                          {c.productId?.selectedVariant?.variant[1] ? c.productId?.selectedVariant?.variant[1]?.value : ""}</small>{c?.productId?.selectedVariant?.variant[1] ? (<br></br>) : null}
                        <small> {c.productId?.selectedVariant?.variant[2] ? " " + c?.productId?.selectedVariant?.variant[2].parentVariant?.variantName : ""}
                          {c.productId?.selectedVariant?.variant[2] ? "-" : ""}
                          {c.productId?.selectedVariant?.variant[2] ? c.productId?.selectedVariant?.variant[2]?.value : ""}</small>{c?.productId?.selectedVariant?.variant[2] ? (<br></br>) : null}
                      </div>

                    </section>
                    <section className="detail-body" >
                      <div>Product Image</div>
                      <div>
                        {c.productId?.productId !== null && (c?.productId.productCatalogue?.imageUrls[0] || c.productId.productCatalogue?.imageUrls?.imageUrls[0]) ?
                          (
                            <img
                              src={c.productId.productCatalogue.imageUrls && c.productId.productCatalogue.imageUrls.length ? c.productId.productCatalogue.imageUrls[0].url : c.productId.productCatalogue.imageUrls[0].url} alt="" />
                          )
                          :
                          (<img src="/assets/images/no-image.jpg" alt="" className="img-fluid" />)



                        }
                      </div>
                    </section>
                    <section className="detail-body" >
                      <div>Price</div>
                      <div>
                        {c.price}
                      </div>
                    </section>
                    <section className="detail-body" >
                      <div>Quantity</div>
                      <div>
                        {c.quantity}
                      </div>
                    </section>
                    <section className="detail-body" >
                      <div>Date</div>
                      <div>
                        {showDate(c.addedOn)}
                      </div>
                    </section>

                  </div>
                )
              })

              : null
            }



            <div className="button">

              <button onClick={() => history.goBack()}>Back</button> &nbsp;
            </div>

          </>
          :
          <div className="loader-style">
            <Loader
              type="ThreeDots"
              color="rgba(32, 159, 132, 1)"
              height={100}
              width={100}
              timeout={30000} //3 secs
            />
          </div>
        }

      </div>


    </Layout>
  )
}

export default CartView
