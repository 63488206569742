import React, { useState, useEffect } from 'react'
import axiosauth from '../../axios-config-auth';
import SearchBox from '../../components/halper/SearchBox';
import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
// import noImage from '../../assets/images/no-img.png';
// import eyeIcon from '../../assets/icons/eye.png';
import { confirmAlert } from 'react-confirm-alert';
import { Link,useHistory } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import Loader from 'react-loader-spinner';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import NoData from '../../components/halper/NoData';
import Layout from '../../layout/layout';

const BrandList = () => {
    const [showLoader, setShowLoader] = useState(true);
    const [collections, setCollections] = useState([]);
    // const [total, settotal] = useState(0);
    const [activePage, setActivePage] = useState(1);

    // For page count
    const history = useHistory();
    const pageCount = new URLSearchParams(window.location.search).get('page');


    const getBrands = () => {
        let obj = {
            "fields": "max",
            "limit": 200,
            "count": 1,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            }
        }
        axiosauth.post('/style/list-styles/')
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("jdkd 1", res)
                if (res.success === true) {
                    setCollections(res.data)
                    // settotal(res.data?.count);
                    setShowLoader(false);
                } else {
                    setCollections([])
                    // settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }



    /**
    * TODO: function for pagination handling page number change
    *@version 1.0.0
    * @author [Unknown]
    */
    // const handlePageChange = (pageNumber) => {
    //     // history.push(`/brand?pageCount=${pageNumber}`)
    //     let obj = {
    //         "fields": "max",
    //         "limit": 10,
    //         "count": (pageNumber - 1) * 10,
    //         "sortBy": {
    //             "type": "createdOn",
    //             "order": -1
    //         }
    //     }
    //     axiosauth.post('/style/list-styles/', obj)
    //         .then(async (response) => {
    //             let res = JSON.parse(response.data)
    //             // console.log("222",res)
    //             if (res.success === true) {
    //                 setCollections(res.data)
    //                 // settotal(res.data?.count);
    //                 setShowLoader(false);
    //             } else {
    //                 setCollections([])
    //                 // settotal(0);
    //                 setShowLoader(false);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // }

  
    const searching = (search) => {
        let obj = {
            "fields": "max",
            "limit": 200,
            "count": 1,
            "sortBy": {
                "type": "createdOn",
                "order": -1
            },
            "filters": {
                "name": search
            }
        }
        axiosauth.post('/style/list-styles/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("333",res)
                if (res.success === true) {
                    setCollections(res.data)
                    // settotal(res.data?.count);
                    setShowLoader(false);
                } else {
                    setCollections([])
                    // settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }



    useEffect(() => { 
        let obj;
        // if(pageCount !== null){
           
             obj = {
                "fields": "max",
                "limit": 200,
                "count": 1,
                "sortBy": {
                    "type": "createdOn",
                    "order": -1
                }
            }

        //   }
        //   else{
        //     obj = {
        //         "fields": "max",
        //         "limit": 200,
        //         // "count": (1 - 1) * 10,
        //         "count": (1 - 1) * 10,
        //         "sortBy": {
        //             "type": "createdOn",
        //             "order": -1
        //         }
        //     }
        //   }

        
        axiosauth.post('/style/list-styles/')
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("4444", res)
                if (res.success === true) {
                    setCollections(res.data)
                    // settotal(res.data?.count);
                    setShowLoader(false);
                } else {
                    setCollections([])
                    // settotal(0);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [pageCount,history,activePage]);


     /**
 * TODO: Pagination
 *@version 1.0.0
 * @author [ Unknown]
 */

  // Handle Pagination

  const [ locationKeys, setLocationKeys ] = useState([]);

  const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1); 
  var vars = query.split("&"); 
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("="); 
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}

  useEffect(() => {
  return history.listen(location => {
    if (history.action === 'PUSH') {
      setLocationKeys([ location.key ]) 
    } 
    if (history.action === 'POP') {
       
      if (locationKeys[1] === location.key) { 
        setLocationKeys(([ _, ...keys ]) => keys) 
          handlePageChange1( getQueryVariable('page')) 
      } else { 
        setLocationKeys((keys) => [ location.key, ...keys ]) 
        handlePageChange1( getQueryVariable('page'))

      }
    }
  })
}, [ locationKeys,history])

const handlePageChange1 = (pageNumber) => {
    setActivePage(pageNumber);
  };

//   const handlePageChange5 = (pageNumber) => {
//     // console.log("dd",pageNumber)
//     handlePageChange(pageNumber);
//     setActivePage(pageNumber);
//     let currentUrlParams = new URLSearchParams(window.location.search);
//     currentUrlParams.set('page', pageNumber);
//     history.push(window.location.pathname + "?" + currentUrlParams.toString());
//   };



    return (
        <Layout> 
            <SearchBox
                placeholder="Search.."
                onChange={(e) => searching(e.target.value)}
            />
            <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img src={left_arrow2}  onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                <h2>Manage Style</h2>
                <div className="button">
                    <Link to="/add-style">
                        <button>Add Style</button>
                    </Link>
                </div>
            </div> 
            {!showLoader ?
                <div className="table-wrap  overflow-auto table-responsive table border p-0 border-top-0 border-bottom-0" >
                    <table>
                      {collections&&collections.length>0?( <thead>
                            <tr>
                                <th >S.No</th>
                                <th className='text-left px-2'>Style Name</th>  
                                {/* <th>View Details</th>  */}
                                <th>Actions</th>

                            </tr>
                        </thead>):null} 
                        <tbody>
                            {collections && collections.length > 0 ?

                                collections.map((brand, index) => {

                                    const deleteBrand = () => {
                                        let obj = {
                                            "id": brand._id,
                                        }
                                        confirmAlert({
                                            // title: 'Change Status',
                                            message: 'Are you sure you want to delete this style ?',
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () => {
                                                        axiosauth.post('/style/delete-style/', obj)
                                                            .then(async (response) => {

                                                                let res = JSON.parse(response.data)
                                                                if (res.success === false) { 
                                                                } else {
                                                                    getBrands() 
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            })
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    onClick: () => console.log("Hello")
                                                }
                                            ]
                                        });

                                    }


                                    return (
                                        <tr  key={index}>
                                            <td  >{((pageCount?pageCount:1)-1)*10 + (index+1)}</td>
                                            <td className="titleStyle title-name" style={{textAlign:"left"}}>{brand.name}</td> 
                                            {/* <td>
                                                <Link to="/manage-product?page=1" >
                                                    <span className="manage-link" onClick={() => localStorage.setItem("brandObj", brand._id)} > Manage</span>
                                                </Link>


                                            </td> */}
                                            {/* <td>
                                            <Link to={`/view-style/${brand._id}`} > 
                                            <i class="fa fa-eye fa-eye-style"></i>
                                                </Link>
                                            </td>
                                             */}
                                            <td>
                                             <Link to={`/update-style/${brand._id}`} >
                                                <img onClick={() => localStorage.setItem("brand", brand._id)} src={editIcon} alt="edit-button" className="edit-icon" />
                                            </Link>
                                            
                                            <img onClick={() => deleteBrand()} src={deleteIcon} alt="delete" className="delete-icon" />
                                            </td>
                                        </tr>
                                    )
                                })

                                :
                                (            <NoData message="Style Not Found" />
                                )
                            }

                        </tbody>
                    </table>

                    {/* <div className="pagination-custom">
                        <Pagination
                            hideDisabled
                            activePage={parseInt(pageCount)}
                            itemsCountPerPage={10}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            hideNavigation
                            onChange={handlePageChange5}
                        />
                    </div> */}

                </div>
                :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </div>
            }

        </Layout>
    )
}

export default BrandList
