import React, {useEffect, useState} from 'react';
// eslint-disable-next-line
import SearchBox from '../../components/halper/SearchBox';
import axiosauth from '../../axios-config-auth';
import { Link,useHistory } from 'react-router-dom';
import Pagination from "react-js-pagination";
import NoData from '../../components/halper/NoData';

const CartList = () => {

  const [cartUsers, setCartUser]=useState([]);
  const [total, setTotal] = useState(0);

  // For page count
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get('page');
// console.log("page count--->", pageCount)



  /**
    * TODO: function for pagination handling page number change
    *@version 1.0.0
    * @author [Unknown]
    */
   const handlePageChange = (pageNumber) => {
    let obj = {
      "fields": "max",
      "limit": 10,
      "count":(pageNumber - 1) * 10,
      "filters":{
        "role":"customer",
            "cart":"empty"
      },
      "sortBy":{
        "type":"createdOn",
        "order": -1
      }
    }
    axiosauth.post('/admin/load-users', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("cart res--->", res)
        if (res.success === true) {
          // console.log("cart data--->", res.data)
          setCartUser(res.data)
          setTotal(res.data.count)
        } else {
          console.log("cart res--->", res)
        }
      })
      .catch((error) => {
        console.log(error);
      })
}

// eslint-disable-next-line
const search = (e) => {
  let obj = {
      "fields": "max",
      "limit": 10,
      "count":(pageCount - 1) * 10,
      "filters":{
        "role":"customer",
            "cart":"empty"
      },
      "search":e.target.value,
      "sortBy":{
        "type":"createdOn",
        "order": -1
      }
    }
    axiosauth.post('/admin/load-users', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("cart res--->", res)
        if (res.success === true) {
          // console.log("cart data--->", res.data)
          setCartUser(res.data)
          setTotal(res.data.count)
        } else {
          console.log("cart res--->", res)
        }
      })
      .catch((error) => {
        console.log(error);
      })
};



  useEffect(() => {
 
    let obj = {
      "fields": "max",
      "limit": 10,
      "count":(pageCount - 1) * 10,
      "filters":{
        "role":"customer",
            "cart":"empty"
      },
      "sortBy":{
        "type":"createdOn",
        "order": -1
      }
    }
    axiosauth.post('/admin/load-users', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("cart res--->", res)
        if (res.success === true) {
          // console.log("cart data--->", res.data)
          setCartUser(res.data);
          setTotal(res.data.count)
        } else {
          console.log("cart res--->", res)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [pageCount]);





      /**
 * TODO: Pagination
 *@version 1.0.0
 * @author [Unknown]
 */

  // Handle Pagination

  const [ locationKeys, setLocationKeys ] = useState([]);

  const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  // console.log(query)//"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}

  useEffect(() => {
  return history.listen(location => {
    if (history.action === 'PUSH') {
      setLocationKeys([ location.key ])
      // console.log("push call")
    }
    // console.log("locationKeys",locationKeys)

    if (history.action === 'POP') {
       
      if (locationKeys[1] === location.key) {
        // console.log("Inside if pop call")
        setLocationKeys(([ _, ...keys ]) => keys)
              //  console.log("forward")
          // console.log("js",getQueryVariable('page'))
          handlePageChange1( getQueryVariable('page'))
        // Handle forward event

      } else {
        // console.log("Inside else pop call")
        setLocationKeys((keys) => [ location.key, ...keys ])
        // console.log("backward",location.key)
        // console.log("js",getQueryVariable('page'))
        handlePageChange1( getQueryVariable('page'))

      }
    }
  })
}, [ locationKeys,history])

const handlePageChange1 = (pageNumber) => {
    // setActivePage(pageNumber);
  };

  const handlePageChange5 = (pageNumber) => {
    // console.log("dd",pageNumber)
    handlePageChange(pageNumber);
    // setActivePage(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };



  return (
    <>
      {/* <SearchBox
        placeholder="Search Customer cart"
        onChange={search}
      /> */}
      <h2 className="mt-4">Abandoned Cart</h2>
      <div className="table-wrap">
        <table>
         {cartUsers.users && cartUsers.users.length>0 ?(<thead>
            <tr>
              <th>S.No</th>
              <th style={{textAlign:"left"}}>Customer Name</th>
              {/* <th>Cart Amount</th> */}
              <th>Email</th>
              <th>Contact Number</th>
              <th></th>
            </tr>
          </thead>):null} 
          <tbody>

              {cartUsers.users && cartUsers.users.length>0 ?

                cartUsers.users.map((user, index)=>{
                  const {firstName,lastName} = user
                  return(
                          <tr key={index}>
                            <td>{((pageCount?pageCount:1)-1)*10 + (index+1)}</td>
                            <td style={{textAlign:"left"}} >{firstName || ''+' '+lastName || ''}</td>
                           
                            <td>{user.userEmail}</td>
                            <td>{user.temporaryMobile}</td>
                            <td  className="manage-link">
                            <Link to={`/cart-view/${user.userId}`}>                           
                            View Details
                            </Link>

                            </td>
                          </tr>
                  )
                })
                :(<NoData message="No Result Found" />)
              }

            



          </tbody>
        </table>


        <div className="pagination-custom">
                        <Pagination
                            hideDisabled
                            activePage={parseInt(pageCount)}
                            itemsCountPerPage={10}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            hideNavigation
                            onChange={handlePageChange5}
                        />
                    </div>


      </div>
    </>
  )
}

export default CartList