import React from 'react'
import Layout from "../../layout/layout";
import BrandList from '../../wrapper/brand/BrandList'

const Brand = () => {
    return (
        <Layout>
            <BrandList />
        </Layout>
    )
}

export default Brand
