import React from 'react';
import Layout from "../layout/layout"
import UserDetail from '../wrapper/userManage/UserDetail';

const ViewDetail = () => {

  return (
    <Layout>
      <div>
        <UserDetail />
      </div>
    </Layout>
  )
}

export default ViewDetail