import React, { useState, useEffect, useRef } from 'react'
import uploadIcon from '../../assets/icons/upload.png';
import {
  createCollection
  // , fetchCollection
} from '../../redux/actions/collectionAction';
import { useDispatch } from 'react-redux';
import { Redirect } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';
import axiosauth from '../../axios-config-auth';
import editImageIcon from '../../assets/icons/edit.png';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Editor } from '@tinymce/tinymce-react';

const AddCollectionForm = () => {
  const animatedComponents = makeAnimated();
  const [file, setfile] = useState(null);
  const [result, setResult] = useState(null); 
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch(); 
  // =======================================================================
  const [isApply, setIsApply] = useState("all-Products");
  const [productOptions, setproductOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [subsubcategoryOptions, setSubsubCategoryOptions] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedSubsubCategory, setSelectedSubsubCategory] = useState([]);
  const [seoText, setSEOText] = useState('');
  const editorRef = useRef(null);

  const [collection, setCollection] = useState({
    name: '',
    shortDescription: '',
    value: "",
    startPrice: null,
    endPrice: null,
    startOffer: null,
    endOffer: null,
    collectionType: null,

  })
  // const [manualProduct, setManualProduct] = useState(false);
  // const [product, setproduct] = useState([])
  // const [pushProduct, setPushProduct] = useState([])

  const [success, setSuccess] = useState();
  // const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setCollection({ ...collection, error: false, [e.target.name]: e.target.value })
  }

  const handleProduct = (selectedOption) => {
    setSelectedProducts({ selectedOption });
};

const handleCategory = (selectedOption) => {
    setSelectedCategory({ selectedOption });
};
const handleSubCategory = (selectedOption) => {
  setSelectedSubCategory({ selectedOption });
};
const handleSubsubCategory = (selectedOption) => {
  setSelectedSubsubCategory({ selectedOption });
};

  const submitCollection = (e) => {
    e.preventDefault();
    
    let products =[];
    let category =[];
    let subcategory =[];
    let subsubCategory =[];

    if(isApply === "specific-products")
    for( var i =0 ; i< selectedProducts.selectedOption.length ;i++){ 
      products.push(selectedProducts.selectedOption[i].value)
    }
    if(isApply === "specific-category")
    for( var i =0 ; i< selectedCategory.selectedOption.length ;i++){ 
      category.push(selectedCategory.selectedOption[i].value)
    }
    if(isApply === "specific-sub-category")
    for( var i =0 ; i< selectedSubCategory.selectedOption.length ;i++){ 
      subcategory.push(selectedSubCategory.selectedOption[i].value)
    }
    if(isApply === "specific-sub-sub-category")
    for( var i =0 ; i< selectedSubsubCategory.selectedOption.length ;i++){ 
      subsubCategory.push(selectedSubsubCategory.selectedOption[i].value)
    }
     

    if (collection.collectionType === "price") {

      if (parseInt(collection.startPrice) > parseInt(collection.endPrice)) {
        setError(true)
        alert("Please Enter End Value Greater than Start Value")
        return 0;
      }
    }

    if (collection.collectionType === "offer") {

      if ((parseInt(collection.startOffer) >= parseInt(collection.endOffer))) {
        setError(true)
        alert("Please Enter End Offer Greater than Start Offer")
        return 0;
      }
    }

    if (collection.collectionType === "offer") {

      if ((parseInt(collection.endOffer) > 99)) {
        setError(true)
        alert("Please Enter End Offer Greater than Start Offer")
        return 0;
      }
    }
    if (collection.collectionType === "offer") {

      if ((parseInt(collection.startOffer) > 99)) {
        setError(true)
        alert("Please Enter End Offer Greater than Start Offer")
        return 0;
      }
    }
    if (file === null && result === null) {
      alert("Please Add Image")
      return 0;
    }

  

    let obj = {
      title: collection.name,
      // product: product,
      // image: [],
      active: true,
      searchKey: (collection.collectionType === "tag" || collection.collectionType === "name") ? collection.value : null,
      description: collection.shortDescription,
      type: collection.collectionType,
      priceGt: collection.collectionType === "price" ? collection.endPrice : null,
      priceLt: collection.collectionType === "price" ? collection.startPrice : null,
      discountFrom: collection.collectionType === "offer" ? collection.startOffer : null,
      discountTo: collection.collectionType === "offer" ? collection.endOffer : null,
      seoText:seoText,
      ...( products.length>0 && isApply === "specific-products" && { products : products}),
      ...( category.length>0 && isApply === "specific-category" && { category : category}),
      ...( subcategory.length>0 && isApply === "specific-sub-category" && { sub1category : subcategory}),
      ...( subsubCategory.length>0 && isApply === "specific-sub-sub-category" && { sub2category : subsubCategory}),
    }
    // console.log("obj===>>" , obj)
    dispatch(createCollection(obj, result, setSuccess));

  }

  useEffect(() =>{
    let arr =[];
    const obj = {
      "limit": 10000,
      "filters": {
        "type": "category"
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
          // setCategoryObj(res.data.categories)
          
          if(res.data.categories){
            for(var i =0 ; i<res.data.categories.length ; i++){ 
              arr.push({
                value : res.data.categories[i]._id,
                label :res.data.categories[i].name
              })
            }
          }
          setCategoryOptions(arr)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } , [])

  useEffect(() =>{
    let arr =[];
    const obj = {
      "limit": 10000,
      "filters": {
        "type": "sub1"
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) { 
          if(res.data.categories){
            for(var i=0 ; i<res.data.categories.length ; i++){ 
              arr.push({
                value :res.data.categories[i]._id,
                label :res.data.categories[i].name  + " (" +res.data.categories[i].parentCategory[0]?.name+" )"
              })
            }
            setSubCategoryOptions(arr)
          } 
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } ,[])

  useEffect(() =>{
    let arr =[];
    const obj = {
      "limit": 10000,
      "filters": {
        "type": "sub2"
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) { 
          if(res.data.categories){
            for(var i=0 ; i<res.data.categories.length ; i++){ 
              arr.push({
                value :res.data.categories[i]._id,
                label :res.data.categories[i].name  + " (" +res.data.categories[i].parentCategory[0]?.name +" )"
              })
            }
            setSubsubCategoryOptions(arr)
          } 
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } ,[])
 

  useEffect(() => {
    let arr =[];
    const obj = {
      limit: 10000000,
      // suspend: "false",
      filters: {
        suspend: "false"
        // sub1categoryslug: search,
      },
      sorts: { sortbyPrice: 1 },
      userId: ""
    }
    axiosauth
      .post("/products/loadv2", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
             for(var i=0 ; i< res.data.products.length>0 ;i++ ){ 
              arr.push({
                value :res.data.products[i]._id,
                label : res.data.products[i].catalogueName
              })
             }
             setproductOptions(arr) 

        } else {
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

  }, [])

  const closeModal = () => {
    setRedirect(true);
  }

  console.log("product", selectedProducts )


  return (
    <>
      {redirect ? <Redirect to="/collection" /> : null}

      {success === true ? <SuccessModal message="New Collection Created !" onClick={closeModal} /> : null}
      <div className="form-header">
        <h4>Add Collection</h4>
      </div>
      <form onSubmit={submitCollection} className="form-wrap">


        <div className="form-body">

          <div className="single-field">
            <p>Collection Name <b>*</b></p>
            <input type="text"
              id="name"
              placeholder="Collection Name"
              name="name"
              onChange={handleChange}
              required={true}
            />
          </div>

          <section>
            <div className="two-field w-100">
              <p>
                Collection Type
                {/* <b>*</b> */}
              </p>
              <div className="d-flex">
                <select
                  id='collectionType'
                  name='collectionType'
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleChange}
                  required={true}
                  value={collection.collectionType}
                >
                  <option selected>Select Collection Type</option>
                  <option value="name">Name</option>
                  <option value="price">Price</option>
                  <option value="offer">Offer</option>

                  <option value="tag">Tag</option>
                </select>

                {(collection.collectionType === "name" || collection.collectionType === "tag") && (
                  <input
                    type="text"
                    id='value'
                    name='value'
                    placeholder={`Enter ${collection.collectionType === "name" ? "Name" : "Tag"}`}
                    onChange={handleChange}
                    required={true}
                  />
                )}

                {(collection.collectionType === "price" || collection.collectionType === "offer") && (
                  <>
                    <input
                      type="number"
                      id='startPrice'
                      style={{ border: error === true ? "1px solid #EE4B2B" : null }}
                      name={`${collection.collectionType === "price" ? 'startPrice' : 'startOffer'}`}
                      placeholder={`Enter ${collection.collectionType === "price" ? "Price" : "Offer"} Start Value`}
                      onChange={handleChange}
                      required={true}
                    />
                    <input
                      type="number"
                      id='endPrice'
                      style={{ border: error === true ? "1px solid #EE4B2B" : null }}
                      name={`${collection.collectionType == "price" ? "endPrice" : "endOffer"}`}
                      placeholder={`Enter ${collection.collectionType === "price" ? "Price" : "Offer"} End Value`}
                      onChange={handleChange}
                      required={true}
                    />
                  </>
                )}
              </div>
            </div>
          </section>

          <div id="short-description">
            {/* <p>Description <b>*</b></p> */}
            <p>Description <b></b></p>
            <textarea
              placeholder="short Description"
              name="shortDescription"
              onChange={handleChange}
            />
          </div>

          <div className="container mt-5">
            <div className="radio-wrap ">
              <h4 className="mb-3">
                {" "}
                Applied to <b>*</b>
              </h4>

              <div className="alignment-justify">
                <aside>
                  <input
                    type="radio"
                    name="gender"
                    value="all-Products"
                    checked={isApply === "all-Products" ? true : false}
                    onClick={() => setIsApply("all-Products")}
                  />
                  <span>No Select</span>
                </aside>
                <aside>
                  <input
                    type="radio"
                    name="gender"
                    value="specific-products"
                    onClick={() => setIsApply("specific-products")}
                  />
                  <span>Specific Products</span>
                </aside>
                <aside>
                  <input
                    type="radio"
                    name="gender"
                    value="specific-category"
                    onClick={() => setIsApply("specific-category")}
                  />
                  <span>Specific Category</span>
                </aside>
                <aside>
                  <input
                    type="radio"
                    name="gender"
                    value="specific-sub-category"
                    onClick={() => setIsApply("specific-sub-category")}
                  />
                  <span>Specific Sub Category</span>
                </aside>
                <aside>
                  <input
                    type="radio"
                    name="gender"
                    value="specific-sub-sub-category"
                    onClick={() => setIsApply("specific-sub-sub-category")}
                  />
                  <span>Specific Sub SUb Category</span>
                </aside>

              </div>
            </div>
            <div className='mb-5'>
              {isApply === "specific-products" ? (
                <div id="select-tag">
                  <p></p>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={productOptions}
                    onChange={handleProduct}
                  />
                </div>
              ) : null}

              {isApply === "specific-category" ? (
                <div id="select-tag">
                  <p></p>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={categoryOptions}
                    onChange={handleCategory}
                  />
                </div>
              ) : null}

              {isApply === "specific-sub-category" ? (
                <div id="select-tag">
                  <p></p>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={subCategoryOptions}
                    onChange={handleSubCategory}
                  />
                </div>
              ) : null}

              {isApply === "specific-sub-sub-category" ? (
                <div id="select-tag">
                  <p></p>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={subsubcategoryOptions}
                    onChange={handleSubsubCategory}
                  />
                </div>
              ) : null}
            </div>
          </div>
 

          <p className="label-form"> {`Image Dimension Must be (400 x 400 ) , image (jpg, png , WebP) and image size must be less than < 200KB`}<b>*</b></p>
          <section className="image-uploaded">

            {file === null && result === null ?
              <aside className="upload-btn-wrapper">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" /><br />
                  {/* Upload Logo */}
                  <p>Upload Image
                    <br />
                    <span style={{ fontSize: "10px" }}>400 X 400</span></p>

                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                    }
                    // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                    // setUrlError("")  
 
                    let img = new Image()
                    img.src = URL.createObjectURL(e.currentTarget.files[0])
                    img.onload = () => {
                      if (img.width === 400 && img.height === 400) {
                        setResult(e.target.files[0])
                        setfile(img.src);
                      } else {
                        alert('Incorrect image dimension. Please upload an image 400 X 400 pixels.')
                      }
                    }
                  }}
                />

              </aside> :
              <aside className="image">
                <div className="overlay">
                  <label htmlFor="file-upload">
                    <img htmlFor="upload-image" className="edit-img" src={editImageIcon} alt="" />
                  </label>
                </div>
                <img className="screen" src={file ? file : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                   
                    // setfile(URL.createObjectURL(e.currentTarget.files[0]));  
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                    }
                   

                    let img = new Image()
                    img.src = URL.createObjectURL(e.currentTarget.files[0])
                    img.onload = () => {
                      if (img.width === 400 && img.height === 400) {
                        setfile(img.src);
                        setResult(e.target.files[0])
                        // alert("correct image")
                      } else {
                        alert('Incorrect image dimension. Please upload an image 400 X 400 pixels.')
                      }
                    }
                  }

                  }
                />

              </aside>
            }

          </section>
          <div className='mt-3'>
              <p className="label-form">SEO Text </p>
              <Editor
                  onInit={(evt, editor) => { editorRef.current = editor }}
                  initialValue=''
                  // initialValue={seoText}
                  onEditorChange={(newText) => {
                        setSEOText(newText)
                        // setSEOText({ ...seoText, newText })
                  }}
                  init={{
                      height: 450,
                      menubar: true,
                      plugins: [
                          'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                          'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                          'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
              />
          </div>
        </div>
        <div className="button">
          <button>Add</button>
        </div>
      </form>
    </>
  )
}

export default AddCollectionForm