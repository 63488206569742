import React, { useEffect, useState } from "react";
import SearchBox from "../../components/halper/SearchBox";
import deleteIcon from "../../assets/icons/delete.png";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  loadDateFilter,
  loadUser,
  searchingUser,
  deleteUserById,
  loadUserByPage,
} from "../../redux/actions/userAction";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import axiosauth from "../../axios-config-auth";
import DownloadExcelModal from "../../components/halper/DownloadExcelModal";
import LoaderModal from "../../components/halper/LoaderModal";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../components/halper/DatePickerInput";
import NoData from "../../components/halper/NoData";

const UserList = () => {
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [excelLink, setExcelLink] = useState(null);

  const [excelDateStart, setExcelDateStart] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [excelDateStartError, setExcelDateStartError] = useState("");
  const [startDateError, setstartDateError] = useState("");
  const [limitData , setLimitData] = useState(30)
  // eslint-disable-next-line
  // const [disButton, setDisButton] = useState(true);

  // for pagination
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    if (activePage === 1) {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set("page", pageCount ? pageCount : 1);
      history.push(
        window.location.pathname + "?" + currentUrlParams.toString()
      );
    }

    if (pageCount !== null) {
      dispatch(loadUser(pageCount, excelDateStart, startDate ,limitData));
    } else {
      dispatch(loadUserByPage(1 ,limitData));
    }
  }, [dispatch, pageCount, history, activePage , limitData]);

  // Handle Pagination

  const [locationKeys, setLocationKeys] = useState([]);
  // console.log("user count---->>>" , userData.data.count)

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };
  const showDate = (data) => {
    var d = new Date(data);
    var date = d.getDate();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
    var year = d.getFullYear();
    var dateStr = date + " " + months[month] + " " + year;
    // var res =
    return dateStr;
  };

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          handlePageChange1(getQueryVariable("page"));
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          handlePageChange1(getQueryVariable("page"));
        }
      }
    });
  }, [locationKeys, history]);

  const handlePageChange1 = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handlePageChange5 = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(loadUser(pageNumber, excelDateStart, startDate ,limitData));
    setActivePage(pageNumber);
  };

  // const downloadFile = () => {
  //   window.location.href = `http://34.131.106.77/${downloadFile}`
  // }

  const downloadExcel = () => {
    if (excelDateStart === null) {
      setExcelDateStartError("Select Start Date");
      return 0;
    }

    if (startDate === null) {
      setstartDateError("Select End Date");
      return 0;
    }
    if (startDate < excelDateStart) {
      alert("End Date Must Be Greater Than Start Date");
      return 0;
    }
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    var myDate = new Date(startDate);
    myDate.setDate(startDate.getDate() + 1);
    let obj = {
      count: 0,
      limit: 100000,
      filters: {
        registrationDate: {
          startDate: formatDate(excelDateStart),
          endDate: formatDate(myDate),
        },
      },
    };
    setShowLoader(true);
    axiosauth
      .post("/admin/export-users", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setExcelDateStartError("");
          setstartDateError("");
          setShowLoader(false);
          setExcelLink(`https://15.207.197.115:1221/${res.data}`);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const excelDateSearch1 = (date) => {
    if (date === null) {
      setExcelDateStartError("Select Start Date");
      return 0;
    }

    if (startDate === null) {
      setstartDateError("Select End Date");
      return 0;
    }
    if (startDate < date) {
      alert("End Date Must Be Greater Than Start Date");
      return 0;
    }
    setExcelDateStart(date);

    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    var myDate = new Date(startDate);
    myDate.setDate(startDate.getDate() + 1);
    let obj = {
      count: 0,
      // limit: 25,
      limit: limitData,
      sortBy: {
        type: "registrationDate",
        order: -1,
      },
      filters: {
        role: "customer",
        registrationDate: {
          startDate: formatDate(date),
          endDate: formatDate(myDate),
        },
      },
    };

    dispatch(loadDateFilter(obj));
    setExcelDateStartError("");
    setstartDateError("");
    // setDisButton(false);
  };
  // ================================

  const excelDateSearch = (date) => {
    if (excelDateStart === null) {
      setExcelDateStartError("Select Start Date");
      return 0;
    }

    if (date === null) {
      setstartDateError("Select End Date");
      return 0;
    }
    if (date < excelDateStart) {
      alert("End Date Must Be Greater Than Start Date");
      return 0;
    }
    setStartDate(date);

    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    var myDate = new Date(date);
    
    if(Number(pageCount) !== 1){
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('page', 1);
      history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    myDate.setDate(date.getDate() + 1);
    let obj = {
      count: pageCount !== 1 ? 0 : (pageCount - 1) * limitData,
      limitData:limitData,
      sortBy: {
        type: "registrationDate",
        order: -1,
      },
      filters: {
        role: "customer",
        registrationDate: {
          startDate: formatDate(excelDateStart),
          endDate: formatDate(myDate),
        },
      },
    };

    dispatch(loadDateFilter(obj));
    setExcelDateStartError("");
    setstartDateError("");
    // setDisButton(false);
  };

  // ======================================

  return (
    <>
      {showLoader === true ? <LoaderModal /> : null}

      {excelLink !== null ? (
        <DownloadExcelModal>
          <span className="excel-title">Download User List</span>
          <br />
          <br />
          <a
            href={excelLink}
            onClick={() => setExcelLink(null)}
            target="_blank"
            rel="noopener noreferrer"
            className="exportLink"
          >
            Download
          </a>
        </DownloadExcelModal>
      ) : null}

      <SearchBox
        placeholder="Search By Email"
        onChange={(e) => {
          if (e.target.value.length > 1)
            dispatch(
              searchingUser(
                e.target.value,
                pageCount,
                excelDateStart,
                startDate,
                limitData
              )
            );
          else dispatch(loadUser(pageCount, excelDateStart, startDate ,limitData));
        }}
      />

      {/* =================================== */}
      <div className="row">
        <div className="col-md-6 col-sm-3 d-flex">
          <p>Total user :  <span className="font-weight-bold ">{userData.data.count} </span></p>
          <div>
       
          <select className="form-select mx-3" aria-label="Default select example" 
          defaultValue=""
          onChange={(e)=> {
            // console.log(e.target.value)
            setLimitData(parseInt(e.target.value))
          }} 
          >
            <option value="" disabled >Select page</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
        </div>
        </div>
        
        <div className="col-md-2 col-sm-3 d-flex">
          <div className="p-1 datePicorderExcel d-flex" >
            <DatePicker
              selected={excelDateStart}
              onChange={(date) => {
                setExcelDateStart(date);
                excelDateSearch1(date);
              }}
              customInput={
                <DatePickerInput
                  place={"Select Start Date"}
                  value={excelDateStart}
                  isRound={true}
                />
              }
              peekNextMonth
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <p style={{ color: "red" }}>{excelDateStartError}</p>
        </div>

        <div className="col-md-2 col-sm-3">
          <div className="p-1 datePicorderExcel">
            <DatePicker
              selected={startDate}
              onChange={(date) => excelDateSearch(date)}
              customInput={
                <DatePickerInput
                  place={"Select End Date"}
                  value={startDate}
                  isRound={true}
                />
              }
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <p style={{ color: "red" }}>{startDateError}</p>
        </div>

        <div className="col-md-2 col-sm-3">
          <div className={"button2"}>
            <button onClick={downloadExcel}>Export Excel</button>
          </div>
        </div>
      </div>

      {/* ===================================== */}

      {/* <div className="button2">
          <button onClick={downloadExcel}>Export Excel</button>
      </div> */}

      {userData.loading ? (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={5000} //3 secs
          />
        </div>
      ) : (
        <>
          <div className="table-wrap overflow-auto table-responsive card py-4" style={{ height: "500px" }}>
            <table className="" >
              {userData.data.users && userData.data.users.length > 0 ? (
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>S.No</th>
                    {/* <th ><p style={{marginLeft:"-30%"}}>Customer Name</p></th> */}
                    <th className="text-nowrap px-3" style={{ textAlign: "left" }}>
                      Customer Name
                    </th>

                    <th className="text-nowrap px-2" >Customer ID</th>
                    <th className="text-nowrap px-2" >
                      Email ID
                    </th>
                    <th className="text-nowrap px-2" >Contact Number</th>
                    <th className="text-nowrap px-2" >Registration Date</th>

                    <th></th>
                    <th></th>
                  </tr>
                </thead>
              ) : null}
              <tbody>
                {userData.data.users && userData.data.users.length > 0 ? (
                  <>
                    {userData.data.users.map((user, index) => {
                      const deleteUser = () => {
                        let obj = {
                          userId: user.userId,
                        };
                        // console.log("obj:", obj)
                        confirmAlert({
                          // title: 'Change Status',
                          message:
                            "Are you sure you want to delete this User? ",
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => {
                                // console.log("deleted user")
                                dispatch(
                                  deleteUserById(
                                    obj,
                                    pageCount,
                                    excelDateStart,
                                    startDate
                                  )
                                );
                              },
                            },
                            {
                              label: "No",
                              onClick: () => {
                                console.log("not detele");
                              },
                            },
                          ],
                        });
                      };

                      return (
                        <tr key={index + 1}>
                          <td style={{ textAlign: "left" }} className="text-nowrap px-2">
                            {((pageCount ? pageCount : 1) - 1) * 30 +
                              (index + 1)}
                          </td>
                          <td className="titleStyle px-3 " style={{ textAlign: "left" }}>
                            {user.firstName} {user?.lastName}
                          </td>
                          {/* <td className="titleStyle  ">{user.username }</td> */}
                          <td className="text-nowrap px-2">{user.userId.slice(0, 10)}</td>
                          <td className="text-nowrap px-2" >
                            {user.userEmail}
                          </td>
                          <td className="text-nowrap px-2">{user.mobile}</td>

                          <td className="text-nowrap px-2">{showDate(user.registrationDate)}</td>
                          <td className="text-nowrap px-2">
                            <Link
                              className="manage-link"
                              to={`/user-detail/${user.userId}`}
                            >
                              View Details
                            </Link>
                          </td>
                          <td>
                            <img
                              onClick={deleteUser}
                              src={deleteIcon}
                              alt=""
                              className="delete-icon"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <NoData message="Users Not Found" />
                )}
              </tbody>
            </table>

          </div>

        </>

      )}
      <div className="pagination-custom">
        <Pagination
          hideDisabled
          activePage={parseInt(pageCount)}
          itemsCountPerPage={limitData}
          totalItemsCount={userData.data.count}
          pageRangeDisplayed={5}
          hideNavigation
          onChange={handlePageChange5}
        />
      </div>
    </>

  );
};

export default UserList;
