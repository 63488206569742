import React from 'react';
import Layout from "../../layout/layout";
import FooterBanner from '../../wrapper/banner/FooterBanner';

const ManageLowerBanner = () => {
    return (
        <Layout>
          <FooterBanner/>
        </Layout>
    )
}

export default ManageLowerBanner
