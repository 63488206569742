import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import uploadIcon from "../../assets/icons/upload.png";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadCategory } from '../../redux/actions/categoryAction';
// import { fetchBanners } from "../../redux/actions/bannerAction";
import axiosauth from "../../axios-config-auth";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory } from "react-router-dom";
import SuccessModal from "../../components/halper/SuccessModal";
import LoaderModal from "../../components/halper/LoaderModal";
import { handleToast } from "../../utils/customFunction";

const AddLoginBanner = () => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [file, setfile] = useState(null);
  const [urlFile, setUrlFile] = useState(null);
  const [titleError, setTitleError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [selectCategory, setSelectCategory] = useState([]);
  const [catError, setCatError] = useState("");
  // const [bannerList, setBannerlist] = useState([]);
  const [linkerror, setlinkerror] = useState(""); 

  const dispatch = useDispatch();
  const { errorMsg } = handleToast();

  const [slideBanner, setSlideBanner] = useState({
    title: "",
    newtab: false,
    link: "https://customer.gajari.com/",
    alt: "",
    available: true,
    typeId: "O4FFLiVe",
  });

  const handleChange = (e) => {
    setSlideBanner({
      ...slideBanner,
      error: false,
      [e.target.name]: e.target.value,
    });
    setTitleError("");
  };

  // console.log("category------->>>>", selectCategory?.selectedOption?.catid)
  const bannerUpload = () => {
    if (slideBanner.title === "") {
      setTitleError("Banner title Required");
      return 0;
    }
    // if (slideBanner.link === null) {
    //   setlinkerror("Page Url required");
    //   return 0;
    // }
    if (file === null) {
      setUrlError("Banner required");
      return 0;
    }

    var formData = new FormData();

    formData.append("title", slideBanner.title);
    formData.append("link", slideBanner.link);
    formData.append("newtab", false);
    formData.append("alt", "wide-image");
    formData.append("available", true);
    formData.append("typeId", "O4FFLiVe");
    formData.append("image", urlFile); 
    formData.append("isPhoneImg", false);

    setShowLoader(true);

    axiosauth
      .post("/banner/post-banner", formData)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setShowLoader(false);
          setShow(true);
        } else {
          setShowLoader(false);
          errorMsg(JSON.parse(response?.data)?.message)
        }
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  };

  // const handleInputChange =() =>{
  //   dispatch(loadCategory()); 
  // }
 
  
 
  const closeModal = () => {
    setRedirect(true);
  };

  const connectionError = () => {
    setNetworkError(false);
  };

  return (
    <Layout>
      {redirect ? <Redirect to="/login-banner" /> : null}
      {show === true ? (
        <SuccessModal message="New Banner Added!" onClick={closeModal} />
      ) : null}
      {networkError === true ? (
        <SuccessModal
          message="Internet Connection Error.."
          onClick={connectionError}
        />
      ) : null}
      {showLoader === true ? <LoaderModal /> : null}

      <div className="form-wrap">
        <div
          className="left_arrow2 back-arrow"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            onClick={() => history.goBack()}
            src={left_arrow2}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Add Login Banner</h2>
        </div>
        <div className="form-body">
          <div className="single-field">
            <p>
              Banner Title <b>*</b>
            </p>
            <input
              type="text"
              id="title"
              placeholder="Enter title"
              name="title"
              onChange={handleChange}
            />
            <i className="error">{titleError}</i>
          </div>

          {/* <div className="single-field">
            <p>
              Banner URL <b>*</b>
            </p>
            <input
              type="text"
              id="link"
              placeholder="page url"
              name="link"
              onChange={handleChange}
            />
            <i className="error">{linkerror}</i>
          </div> */}

           

          <div className="banner-wrap">
            <>
              {file ? <img src={file} alt="" /> : null}
              <p className="label-form my-5">{`Image Dimension Must be (600 X 240) , image (jpg, png , WebP) and image size must be less than < 200KB` }<b>*</b> </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <div class="upload-btn-wrapper1">
                  <button className="uplod-btn1">
                    Upload image
                    <img src={uploadIcon} alt="" />
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    onChange={(e) => {
                      // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                      setUrlError(null);
                      if (e.currentTarget.files[0].size >= 204800) {
                        alert("Images size must be less than 200KB")
                        return 0
                    }

                      let img = new Image();
                      img.src = window.URL.createObjectURL(e.target.files[0]);
                      setUrlFile(e.currentTarget.files[0]);
                      img.onload = () => {
                        console.log( img.width , img.height)
                        if (img.width === 600 && img.height === 240) {
                          setfile(img.src);
                        } else {
                          alert(
                            "Incorrect image dimension. Please upload an image of 600X240 pixels."
                          );
                        }
                      };
                    }}
                  />
                </div>

                <div style={{ display: "flex" }}></div>
              </div>
            </>
          </div>

          <i className="error">{urlError}</i>
        </div>
        <div className="button">
          <button onClick={bannerUpload}>Add Banner</button>
        </div>
      </div>
    </Layout>
  );
};

export default AddLoginBanner;
