 
import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import "../../style/dashboard.scss";
import { Line } from "react-chartjs-2";
import axiosauth from '../../axios-config-auth';
import "date-fns";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DatePicker
} from "@material-ui/pickers";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
/**
 * TODO: Dashboard Page Components
 * @version 1.0.0
 * @author [Unknown]
 */


const Dashboard = () => {
  const [selectedDate, setSelectedDate] = React.useState(
    null
  );
  
  
  const [selectedDate1, setSelectedDate1] = useState(new Date());

  const[type,settype]=useState("yearly")
  // const [graphData , setGraphData] = useState([])
  const[base,setbase]=useState("")
  const label= [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const label1= [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31"
  ]
  const datasetKeyProvider=()=>{ 
    return btoa(Math.random()).substring(0,12)
} 
  const[data,setdata]=useState([]
  )

 
  let Linechart = {
    data: {
      labels:type==="yearly"?label:label1,
      datasets: [data],
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              suggestedMax: 35,
            },
          },
        ],
      },
      tooltips: {},
      legend: {
        // labels: {},
        display: true,
      },
    },
  };
  
  useEffect(() => {
    function startOfMonth(date)
  {
     
   return new Date(date.getFullYear(), date.getMonth(), 1);
 
  }
  function endOfMonth(date)
  {
     
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
 
  }


    let obj = {}
    if(type==="monthly")
    {
      // var d = new Date(sele, 11, 17);

      let dt 
      if(selectedDate===undefined)
      {
        dt= new Date(selectedDate1.getFullYear(), new Date().getMonth(), new Date().getDate());
      } 
      else{
        dt= new Date(selectedDate1.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
      }

// console.log(startOfMonth(dt).toString());
// console.log(endOfMonth(dt).toString());
      let myDate = new Date();
      myDate.setDate(myDate.getDate() - 365);
      function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
          
        // console.log("Excel date--->",[year, month, day].join('-'))
    
        return [year, month, day].join('-');
    }
       obj = {
  
        "filterType":"monthly",
        "endDate":formatDate(endOfMonth(dt).toString()),
        "startDate":formatDate(startOfMonth(dt).toString())
    
    }
    }
    else{

      //  console.log("fjjdjd",selectedDate1)
       var d = new Date(selectedDate1.getFullYear(), 0, 1);
      //  console.log("fjjdjd",d)
           let l=new Date(selectedDate1.getFullYear(), 11, 31)
          //  console.log("rohan",l)

   
      function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
          
        // console.log("Excel date--->",[year, month, day].join('-'))
    
        return [year, month, day].join('-');
    }
       obj = {
  
        "filterType":"yearly",
        "endDate":formatDate(l),
        "startDate":formatDate(d)
    
    }
    }
    setdata([0, 76, 2, 1, 5, 2, 0, 0, 0, 0, 0, 0])
    axiosauth
        .post("/admin/load-sales-stats", obj)
        .then(async (response) => {
            let res = JSON.parse(response.data);
            if (res.success === true) {
              // console.log("result is",res.data.orders)
               setbase(res.data)
               setdata([0, 76, 2, 1, 5, 2, 0, 0, 0, 0, 0, 0])
              //  setdata(res.data.customer_stat)
              // setdata([])

            } else {
              setdata([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

                // console.log("else block");
                // setShowLoader(false);
            }
        })
        .catch((error) => {
          setdata([0, 76, 2, 1, 5, 2, 0, 0, 0, 0, 0, 0])

            // console.log(error);
        });
  
}, [type,selectedDate,selectedDate1]);

 


  return (
    <Layout>
      <div className="filter">
        <div className="container-fluid">
      
        </div>
      </div>
      <div className="dashboard-stat">
        <section className="order-statics">
          <h3>ORDER STATISTICS</h3>
          <section>
            <div>
              <h4>In-Progress</h4> <p>{base.pending_order!==undefined?base.pending_order:""}</p>
            </div>
            <div>
              <h4>delivered</h4> <p>{base.delivered_order!==undefined?base.delivered_order:""}</p>
            </div>
            <div>
              <h4>returned</h4> <p>{base.returned_orders!==undefined?base.returned_orders:""}</p>
            </div>
            <div>
              <h4>cancelled</h4> <p>{base.cancelled_order!==undefined?base.cancelled_order:""}</p>
            </div>
           
          </section>
        </section>
      </div>
      <div className="site-statics mb-4">
        <h3>site statistics</h3>
        <section className="time-filter">
          <div className="row">
            <div className="col-md-4">
            <Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <DatePicker
        openTo="year"
        views={["year"]}
        label="Select Year"
        value={selectedDate1}
        onChange={(newValue) =>{
          settype("yearly")
          setSelectedDate(null)
           setSelectedDate1(newValue)}}
        renderInput={(props) => <TextField {...props} helperText="Start from year selection" />}
      />
      </MuiPickersUtilsProvider>
      </Grid>
            </div>
            <div className="col-md-6">
      
            <button className="yearly-button">
{/* <p className="mr-2">Select Month</p> */}
            <select value={selectedDate!==null?selectedDate.getMonth():""} onChange={(e)=>{

              let year=selectedDate1.getFullYear()
              let month=e.target.value
              let day=1
              let date=new Date(year,month,day)
              setSelectedDate(date)
              settype("monthly")
            }}>
              <option>Select Month</option>
              <option value="0">January</option>
              <option value="1">February</option>
              <option value="2">March</option>
              <option value="3">April</option>
              <option value="4">May</option>
              <option value="5">June</option>
              <option value="6">July</option>
              <option value="7">August</option>
              <option value="8">September</option>
              <option value="9"> October</option>
              <option value="10"> November</option>
              <option value="11"> December</option>

            </select>
          </button>
            </div>
          </div>
  
      {/* <Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <DatePicker
        // openTo="month"
        views={["year,month"]}
        minDate={new Date(selectedDate1)}
        maxDate={new Date(selectedDate1)}
        label="Select Month"
        value={selectedDate}
        onChange={(newValue) =>{
          console.log("fjjf",newValue)
          settype("monthly")
           setSelectedDate(newValue)}}
        renderInput={(props) => <TextField {...props} helperText="Start from year selection" />}
      />
      </MuiPickersUtilsProvider>
      </Grid> */}
          
          <div className="line-chart">
            <Line
              width={10}
              height={4}
              datasetKeyProvider={datasetKeyProvider}
              data={{
                labels:type==="yearly"?label:label1,
                datasets: [{
                  label: "Customer",
                  data: data,
                  pointRadius: 8,
                  showLine: true,
                  type: 'line',
                  fill: false,
                  lineTension: 0.15,
                  borderColor: ["#209f84"],
                  pointBackgroundColor: "#209f84",
                  pointStyle: "rectRounded",
                  spanGaps: true,
                }],
              }}
              options={Linechart.options}
            ></Line>
          </div>
        </section>
      </div> 
      <ToastContainer />
    </Layout>
  );
};

export default Dashboard;


