import React from 'react';
import Layout from '../../layout/layout';
import ManageVariantName from '../../wrapper/variant/ManageVariantName'

const ManageVariantNamePage = () => {

  return (
    <Layout>
      <ManageVariantName />
    </Layout>
  )
}

export default ManageVariantNamePage