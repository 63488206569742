import React, { useState, useEffect } from "react";
import Layout from '../../layout/layout';
import axiosauth from '../../axios-config-auth';
import { confirmAlert } from 'react-confirm-alert';
import editImageIcon from '../../assets/icons/edit.png';
import delIcon from '../../assets/icons/delete.png';
import uploadIcon from '../../assets/icons/upload.png';
import { useParams, useHistory } from 'react-router-dom';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import CustomCropperEdit from '../../components/halper/CustomCropperEdit';
import CustomCropperAdd from '../../components/halper/CustomCropperAdd';

const EditProductImage = () => {
  const [youtubeurl, setyoutubeurl] = useState(null)


  // eslint-disable-next-line
  const [imageUrl, setImageurl] = useState("")
  const [file, setfile] = useState("")
  const [newfile, setnewfile] = useState("")
  const [showfile, setshowfile] = useState(null);
  const [imgId, setImgId] = useState("");
  const [result, setResult] = useState(null);


  // eslint-disable-next-line
  const [imageUrl1, setImageurl1] = useState("")
  const [file1, setfile1] = useState("")
  const [newfile1, setnewfile1] = useState("")
  const [showfile1, setshowfile1] = useState(null)
  const [result1, setResult1] = useState(null);


  // eslint-disable-next-line
  const [imageUrl2, setImageurl2] = useState("")
  const [file2, setfile2] = useState("")
  const [newfile2, setnewfile2] = useState("")
  const [showfile2, setshowfile2] = useState(null)
  const [result2, setResult2] = useState(null);


  // eslint-disable-next-line
  const [imageUrl3, setImageurl3] = useState("")
  const [file3, setfile3] = useState("");
  const [newfile3, setnewfile3] = useState("")
  const [showfile3, setshowfile3] = useState(null)
  const [result3, setResult3] = useState(null);


  // eslint-disable-next-line
  const [imageUrl4, setImageurl4] = useState("")
  const [file4, setfile4] = useState("");
  const [newfile4, setnewfile4] = useState("")
  const [showfile4, setshowfile4] = useState(null)
  const [result4, setResult4] = useState(null);

  // eslint-disable-next-line
  const [imageUrl5, setImageurl5] = useState("")
  const [file5, setfile5] = useState("");
  const [newfile5, setnewfile5] = useState("")
  const [showfile5, setshowfile5] = useState(null)
  const [result5, setResult5] = useState(null);

  // eslint-disable-next-line
  const [imageUrl6, setImageurl6] = useState("")
  const [file6, setfile6] = useState("");
  const [newfile6, setnewfile6] = useState("")
  const [showfile6, setshowfile6] = useState(null)
  const [result6, setResult6] = useState(null);

  // eslint-disable-next-line
  const [imageUrl7, setImageurl7] = useState("")
  const [file7, setfile7] = useState("");
  const [newfile7, setnewfile7] = useState("")
  const [showfile7, setshowfile7] = useState(null)
  const [result7, setResult7] = useState(null);



  const [fsize, setFsize] = useState("");
  const [sizeMsg, setSizeMsg] = useState("");

  const [fsize1, setFsize1] = useState("");
  const [sizeMsg1, setSizeMsg1] = useState("");

  const [fsize2, setFsize2] = useState("");
  const [sizeMsg2, setSizeMsg2] = useState("");

  const [fsize3, setFsize3] = useState("");
  const [sizeMsg3, setSizeMsg3] = useState("");

  const [fsize4, setFsize4] = useState("");
  const [sizeMsg4, setSizeMsg4] = useState("");

  const [fsize5, setFsize5] = useState("");
  const [sizeMsg5, setSizeMsg5] = useState("");

  const [fsize6, setFsize6] = useState("");
  const [sizeMsg6, setSizeMsg6] = useState("");

  const [fsize7, setFsize7] = useState("");
  const [sizeMsg7, setSizeMsg7] = useState("");




  const [product, setproduct] = useState("")
  const [calluseeffect, setcalluseeffect] = useState("");

  const { catalogueId } = useParams();
  const history = useHistory();

  /**
* TODO: function use for adding new image
*@version 1.0.0
* @author [ Unknown]
*/
  const handleChange222 = (imageurl) => {
    var formData = new FormData();
    var productId = localStorage.getItem("image");
    formData.append("catalogueId", productId);

    formData.append("image", result5);

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }

    axiosauth
      .post("/catalogue/push-catalogue-image", formData)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          alert("Image Added Successfully");
          // setfile("");
          // setfile1("");
          // setfile2("");
          // setfile3("");
          // setfile4("")
          setshowfile(null);
          setshowfile1(null);
          setshowfile2(null);
          setshowfile3(null);
          setshowfile4(null);
          setshowfile5(null);
          setshowfile6(null);
          setshowfile7(null);

          setnewfile("")
          setnewfile1("")
          setnewfile2("")
          setnewfile3("")
          setnewfile4("")
          setnewfile5("")
          setnewfile6("")
          setnewfile7("")
          setcalluseeffect(generateString(5));
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };


  useEffect(() => {

    let obj = {
      "catalogueId": catalogueId
    }
    axiosauth.post('/catalogue/find-catalogue-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setproduct(res.result[0])
          setyoutubeurl(res.result[0].youtubeUrl)
          if (res.result[0].imageUrls.length === 1) {
            // setfile(res.result[0].imageUrls[0].url);
            setshowfile(res.result[0].imageUrls[0].url);
          }
          if (res.result[0].imageUrls.length === 2) {
            // setfile(res.result[0].imageUrls[0].url);
            // setfile1(res.result[0].imageUrls[1].url);
            setshowfile(res.result[0].imageUrls[0].url);
            setshowfile1(res.result[0].imageUrls[1].url);
          }
          if (res.result[0].imageUrls.length === 3) {
            // setfile(res.result[0].imageUrls[0].url);
            // setfile1(res.result[0].imageUrls[1].url);
            // setfile2(res.result[0].imageUrls[2].url);
            setshowfile(res.result[0].imageUrls[0].url);
            setshowfile1(res.result[0].imageUrls[1].url);
            setshowfile2(res.result[0].imageUrls[2].url);
          }
          if (res.result[0].imageUrls.length === 4) {
            // setfile(res.result[0].imageUrls[0].url);
            // setfile1(res.result[0].imageUrls[1].url);
            // setfile2(res.result[0].imageUrls[2].url);
            // setfile3(res.result[0].imageUrls[3].url);
            setshowfile(res.result[0].imageUrls[0].url);
            setshowfile1(res.result[0].imageUrls[1].url);
            setshowfile2(res.result[0].imageUrls[2].url);
            setshowfile3(res.result[0].imageUrls[3].url);
          }
          if (res.result[0].imageUrls.length > 4) {
            setshowfile(res.result[0].imageUrls[0].url);
            setshowfile1(res.result[0].imageUrls[1].url);
            setshowfile2(res.result[0].imageUrls[2].url);
            setshowfile3(res.result[0].imageUrls[3].url);
            setshowfile4(res.result[0].imageUrls[4].url);
          }
          if (res.result[0].imageUrls.length > 5) {
            setshowfile(res.result[0].imageUrls[0].url);
            setshowfile1(res.result[0].imageUrls[1].url);
            setshowfile2(res.result[0].imageUrls[2].url);
            setshowfile3(res.result[0].imageUrls[3].url);
            setshowfile4(res.result[0].imageUrls[4].url);
            setshowfile5(res.result[0].imageUrls[5].url);
          }
          if (res.result[0].imageUrls.length > 6) {
            setshowfile(res.result[0].imageUrls[0].url);
            setshowfile1(res.result[0].imageUrls[1].url);
            setshowfile2(res.result[0].imageUrls[2].url);
            setshowfile3(res.result[0].imageUrls[3].url);
            setshowfile4(res.result[0].imageUrls[4].url);
            setshowfile6(res.result[0].imageUrls[6].url);
          }
          if (res.result[0].imageUrls.length > 7) {
            setshowfile(res.result[0].imageUrls[0].url);
            setshowfile1(res.result[0].imageUrls[1].url);
            setshowfile2(res.result[0].imageUrls[2].url);
            setshowfile3(res.result[0].imageUrls[3].url);
            setshowfile4(res.result[0].imageUrls[4].url);
            setshowfile6(res.result[0].imageUrls[6].url);
            setshowfile7(res.result[0].imageUrls[7].url);
          }
        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [calluseeffect, catalogueId]);


  /**
* TODO: function use for updating specific image
*@version 1.0.0
* @author [ Unknown]
*/
  const handleChange22 = (imageurl) => {
    var formData = new FormData();
    var productId = localStorage.getItem("image");
    formData.append("catalogueId", productId);
    formData.append("imageId", imgId);

    formData.append("image", imageurl);

    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    function generateString(length) {
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }

    axiosauth
      .post("/catalogue/update-catalogue-image", formData)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          console.log("imgId------->", imgId)
          setImgId("");
          alert("Image Edited Successfully");
          // setfile("");
          // setfile1("");
          // setfile2("");
          // setfile3("");
          // setfile4("")
          setshowfile(null);
          setshowfile1(null);
          setshowfile2(null);
          setshowfile3(null);
          setshowfile4(null);
          setshowfile5(null);
          setshowfile6(null);
          setshowfile7(null);
          setcalluseeffect(generateString(5));
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        console.log("-----always executes");
      });
  };
  // eslint-disable-next-line
  const handleChange2 = () => {
    console.log("fsize", fsize);
    if (fsize < 500000) {
      setSizeMsg("");
      if (fsize1 < 500000) {
        setSizeMsg1("");
        if (fsize2 < 500000) {
          setSizeMsg2("");
          if (fsize3 < 500000) {

            setSizeMsg3("");
            if (fsize4 < 500000) {
              setSizeMsg4("");
              if (fsize5 < 500000) {
                setSizeMsg5("");
                if (fsize6 < 500000) {
                  setSizeMsg6("");
                  if (fsize7 < 500000) {
                    setSizeMsg7("");
                    var formData = new FormData();
                    var productId = localStorage.getItem("catalogueId")
                    formData.append("catalogueId", productId)
                    if (imageUrl !== "") {
                      formData.append("image", imageUrl);

                    }
                    if (imageUrl1 !== "") {
                      formData.append("image", imageUrl1)

                    }
                    if (imageUrl2 !== "") {
                      formData.append("image", imageUrl2)

                    }
                    if (imageUrl3 !== "") {
                      formData.append("image", imageUrl3)

                    }
                    if (imageUrl4 !== "") {
                      formData.append("image", imageUrl4)
                    }
                    if (imageUrl5 !== "") {
                      formData.append("image", imageUrl5)
                    }
                    if (imageUrl6 !== "") {
                      formData.append("image", imageUrl6)
                    }
                    if (imageUrl7 !== "") {
                      formData.append("image", imageUrl7)
                    }

                    axiosauth.post('/catalogue/upload-product-images', formData).then(response => {
                      let res = JSON.parse(response.data)
                      if (res.success === true) {


                      }
                      else {


                      }

                    }).catch((error) => {
                      console.log(error);
                    }).then(() => {
                      console.log("-----always executes");
                    })
                  }
                  else {
                    setSizeMsg7('image size should be less than 500kb')
                  }
                }
                else {
                  setSizeMsg6('image size should be less than 500kb')
                }
              }
              else {
                setSizeMsg5('image size should be less than 500kb')
              }
            }
            else {
              setSizeMsg4('image size should be less than 500kb')
            }

          } else {
            setSizeMsg3("image size should be less than 500kb")
          }

        } else {
          setSizeMsg2("image size should be less than 500kb")
        }


      } else {
        setSizeMsg1("image size should be less than 500kb")
      }

    } else {
      setSizeMsg("image size should be less than 500kb");
    }




  };
  // eslint-disable-next-line
  /**
* TODO: function use for deleting specific image
*@version 1.0.0
* @author [ Unknown]
*/
  const deleteImage = (imageId) => {
    let obj = {
      catalogueId: localStorage.getItem("image"),
      imageId: imageId,
    };

    confirmAlert({
      title: "Delete Image",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axiosauth
              .post("/catalogue/delete-catalogue-image", obj)
              .then((response) => {
                let res = JSON.parse(response.data);
                if (res.success === true) {
                  alert("Image deleted successfully");
                  // setfile("");
                  // setfile1("");
                  // setfile2("");
                  // setfile3("");
                  // setfile4("")
                  setshowfile(null);
                  setshowfile1(null);
                  setshowfile2(null);
                  setshowfile3(null);
                  setshowfile4(null);
                  setcalluseeffect(imageId);
                  // dispatch(otherModal(true))
                } else {
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .then(() => {
                console.log("-----always executes");
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Hello"),
        },
      ],
    });
  };



  return (
    <Layout>
      <div className="form-wrap">

        <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
          <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
          <h2>Update Product Catalogue Images</h2>
        </div>

        <div className='row d-flex justify-content-between'>
        <div className="images-wrap">

          <div className="single-image">
            {showfile === null ? (

              <section className="image-uploaded">

                <div class="upload-btn-wrapper">
                  <button className="uplod-btn">
                    <img src={uploadIcon} alt="" /><br />
                    UPLOAD IMAGE
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept={"image/*"}
                    onChange={(e) => {
                      // if(e.currentTarget.files[0].size>=500000)
                      // {
                      // alert("Images size must be less than 500kb")
                      // return 0
                      // }
                      // setImageurl(e.currentTarget.files[0]);
                      setFsize(e.currentTarget.files[0].size)
                      // setfile(
                      //   URL.createObjectURL(
                      //     e.currentTarget.files[0]
                      //   )
                      // );
                      // handleChange222(e.currentTarget.files[0]);
                      //   handleChange2(e.currentTarget.files[0]);

                      let img = new Image()
                      img.src = window.URL.createObjectURL(e.target.files[0]);

                      img.onload = () => {
                        // if(img.width >=500 && img.height >=500){       
                        setnewfile(img.src);
                        // setImageError("");

                        // } else {
                        //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                        // }                
                      }


                    }}
                  />
                </div>

              </section>


            ) : null}

            {showfile !== null ? (
              <aside class="image">
                {/* <div class="overlay3">
                                                <label for="file-upload">
                                                    <img for="upload-image" class="edit-img" src={editImageIcon} alt=""/>
                                                </label>
                                                <img onClick={() => deleteImage(product.imageUrls[0].imageId)} src={delIcon} alt="delete" className="del-icon" />
                                                </div> */}
                <img class="screen" src={showfile != null ? showfile : result} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept={"image/*"}
                  onChange={(e) => {

                    // if(e.currentTarget.files[0].size>=500000)
                    // {
                    // alert("Images size must be less than 500kb")
                    // return 0
                    // }


                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]);

                    img.onload = () => {
                      // if(img.width >=500 && img.height >=500){   
                      // console.log("productImageId1===>",product.imageUrls[0].imageId)    
                      setImgId(product.imageUrls[0].imageId)
                      setfile(img.src);

                      // setImageError("");

                      // } else {
                      //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                      // }                
                    }

                    setFsize(e.currentTarget.files[0].size);

                  }}

                />

                <div class="overlay3">
                  <label for="file-upload">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                  <img onClick={() => deleteImage(product.imageUrls[0].imageId)} src={delIcon} alt="delete" className="del-icon" />
                </div>

              </aside>
            ) : null}

            <span style={{ fontSize: '12px', color: 'red' }}>
              {sizeMsg}
            </span>
          </div> 

          <div className="single-image">
            {showfile1 === null && showfile !== null ? (
              <section className="image-uploaded">

                <div class="upload-btn-wrapper">
                  <button className="uplod-btn">
                    <img src={uploadIcon} alt="" /><br />
                    UPLOAD IMAGE
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept={"image/*"}
                    onChange={(e) => {
                      // if(e.currentTarget.files[0].size>=500000)
                      // {
                      // alert("Images size must be less than 500kb")
                      // return 0
                      // }
                      // setFsize1(e.currentTarget.files[0].size)
                      // setImageurl1(e.currentTarget.files[0]);
                      // setfile1(
                      //   URL.createObjectURL(
                      //     e.currentTarget.files[0]
                      //   )
                      // );
                      // handleChange222(e.currentTarget.files[0]);




                      let img = new Image()
                      img.src = window.URL.createObjectURL(e.target.files[0]);

                      img.onload = () => {
                        // if(img.width >=500 && img.height >=500){       
                        setnewfile1(img.src);
                        // setImageError("");

                        // } else {
                        //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                        // }                
                      }

                      setFsize(e.currentTarget.files[0].size);



                    }}
                  />
                </div>

              </section>
            ) : null}

            {showfile1 !== null ? (

              <aside class="image">

                <img class="screen" src={showfile1 !== null ? showfile1 : result1} alt="" />

                <input
                  type="file"
                  id="file-upload1"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept={"image/*"}
                  onChange={(e) => {
                    // if(e.currentTarget.files[0].size>=500000)
                    // {
                    // alert("Images size must be less than 500kb")
                    // return 0
                    // }
                    // setFsize1(e.currentTarget.files[0].size)
                    // setImageurl1(e.currentTarget.files[0]);
                    // setfile1(
                    //   URL.createObjectURL(
                    //     e.currentTarget.files[0]
                    //   )
                    // );
                    // handleChange22(
                    //   e.currentTarget.files[0],
                    //   product.imageUrls[1].imageId
                    // );



                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]);

                    img.onload = () => {
                      // if(img.width >=500 && img.height >=500){     
                      setImgId(product.imageUrls[1].imageId)
                      console.log("productImageId2===>", product.imageUrls[1].imageId)
                      setfile1(img.src);

                      // setImageError("");

                      // } else {
                      //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                      // }                
                    }

                    setFsize1(e.currentTarget.files[0].size);



                  }}
                />

                <div class="overlay3">
                  <label for="file-upload1">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                  <img onClick={() => deleteImage(product.imageUrls[1].imageId)} src={delIcon} alt="delete" className="del-icon" />
                </div>

              </aside>

            ) : null}
            <span style={{ fontSize: '12px', color: 'red' }}>
              {sizeMsg1}
            </span>
          </div>
 
          <div className="single-image">
            {showfile2 === null && showfile1 !== null ? (
              <section className="image-uploaded">

                <div class="upload-btn-wrapper mt-2">
                  <button className="uplod-btn">
                    <img src={uploadIcon} alt="" /><br />
                    UPLOAD IMAGE
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept={"image/*"}
                    onChange={(e) => {
                      // if(e.currentTarget.files[0].size>=500000)
                      // {
                      // alert("Images size must be less than 500kb")
                      // return 0
                      // }
                      // setImageurl2(e.currentTarget.files[0]);
                      // setFsize2(e.currentTarget.files[0].size)
                      // setfile2(
                      //   URL.createObjectURL(
                      //     e.currentTarget.files[0]
                      //   )
                      // );
                      // handleChange222(e.currentTarget.files[0]);
                      //   handleChange2(e.currentTarget.files[0]);

                      let img = new Image()
                      img.src = window.URL.createObjectURL(e.target.files[0]);

                      img.onload = () => {
                        // if(img.width >=500 && img.height >=500){       
                        setnewfile2(img.src);
                        // setImageError("");

                        // } else {
                        //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                        // }                
                      }

                      setFsize(e.currentTarget.files[0].size);



                    }}
                  />
                </div>

              </section>
            ) : null}

            {showfile2 !== null ? (

              <aside class="image">

                <img class="screen" src={showfile2 !== null ? showfile2 : result2} alt="" />

                <input
                  type="file"
                  id="file-upload2"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept={"image/*"}
                  onChange={(e) => {
                    // if(e.currentTarget.files[0].size>=500000)
                    // {
                    // alert("Images size must be less than 500kb")
                    // return 0
                    // }
                    // setImageurl2(e.currentTarget.files[0]);
                    setFsize2(e.currentTarget.files[0].size)
                    // setfile2(
                    //   URL.createObjectURL(
                    //     e.currentTarget.files[0]
                    //   )
                    // );
                    // handleChange22(
                    //   e.currentTarget.files[0],
                    //   product.imageUrls[2].imageId
                    // );


                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]);

                    img.onload = () => {
                      // if(img.width >=500 && img.height >=500){   

                      setImgId(product.imageUrls[2].imageId)
                      setfile2(img.src);

                      // setImageError("");

                      // } else {
                      //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                      // }                
                    }


                  }}
                />
                <div class="overlay3">
                  <label for="file-upload2">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                  <img onClick={() => deleteImage(product.imageUrls[2].imageId)} src={delIcon} alt="delete" className="del-icon" />
                </div>
              </aside>


            ) : null}
            <span style={{ fontSize: '12px', color: 'red' }}>
              {sizeMsg2}
            </span>
          </div> 

          <div className="single-image">
            {showfile3 === null && showfile2 !== null ? (
              <section className="image-uploaded">

                <div class="upload-btn-wrapper mt-2">
                  <button className="uplod-btn">
                    <img src={uploadIcon} alt="" /><br />
                    UPLOAD IMAGE
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    onChange={(e) => {
                      // if(e.currentTarget.files[0].size>=500000)
                      // {
                      // alert("Images size must be less than 500kb")
                      // return 0
                      // }
                      // setImageurl3(e.currentTarget.files[0]);
                      // setFsize3(e.currentTarget.files[0].size)
                      // setfile3(
                      //   URL.createObjectURL(
                      //     e.currentTarget.files[0]
                      //   )
                      // );
                      // handleChange222(e.currentTarget.files[0]);
                      //   handleChange2(e.currentTarget.files[0]);

                      let img = new Image()
                      img.src = window.URL.createObjectURL(e.target.files[0]);

                      img.onload = () => {
                        // if(img.width >=500 && img.height >=500){       
                        setnewfile3(img.src);
                        // setImageError("");

                        // } else {
                        //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                        // }                
                      }

                      setFsize(e.currentTarget.files[0].size);


                    }}
                  />
                </div>

              </section>
            ) : null}

            {showfile3 !== null ? (

              <aside class="image">

                <img class="screen" src={showfile3 !== null ? showfile3 : result3} alt="" />

                <input
                  type="file"
                  id="file-upload3"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept={"image/*"}
                  onChange={(e) => {
                    // if(e.currentTarget.files[0].size>=500000)
                    // {
                    // alert("Images size must be less than 500kb")
                    // return 0
                    // }
                    // setImageurl3(e.currentTarget.files[0]);
                    setFsize3(e.currentTarget.files[0].size)
                    // setfile3(
                    //   URL.createObjectURL(
                    //     e.currentTarget.files[0]
                    //   )
                    // );
                    // handleChange22(
                    //   e.currentTarget.files[0],
                    //   product.imageUrls[3].imageId
                    // );


                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]);

                    img.onload = () => {
                      // if(img.width >=500 && img.height >=500){   

                      setImgId(product.imageUrls[3].imageId)
                      setfile3(img.src);

                      // setImageError("");

                      // } else {
                      //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                      // }                
                    }
                  }}
                />
                <div class="overlay3">
                  <label for="file-upload3">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                  <img onClick={() => deleteImage(product.imageUrls[3].imageId)} src={delIcon} alt="delete" className="del-icon" />
                </div>
              </aside>

            ) : null}
            <span style={{ fontSize: '12px', color: 'red' }}>
              {sizeMsg3}
            </span>
          </div> 

          <div className="single-image">
            {showfile4 === null && showfile3 !== null ? (
              <section className="image-uploaded">

                <div class="upload-btn-wrapper mt-2">
                  <button className="uplod-btn">
                    <img src={uploadIcon} alt="" /><br />
                    UPLOAD IMAGE
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    onChange={(e) => {
                      //  if(e.currentTarget.files[0].size>=500000)
                      //  {
                      //  alert("Images size must be less than 500kb")
                      //  return 0
                      //  }
                      // setImageurl3(e.currentTarget.files[0]);
                      // setFsize3(e.currentTarget.files[0].size)
                      // setfile3(
                      //   URL.createObjectURL(
                      //     e.currentTarget.files[0]
                      //   )
                      // );
                      // handleChange222(e.currentTarget.files[0]);
                      //   handleChange2(e.currentTarget.files[0]);

                      let img = new Image()
                      img.src = window.URL.createObjectURL(e.target.files[0]);

                      img.onload = () => {
                        //  if(img.width >=500 && img.height >=500){       
                        setnewfile4(img.src);
                        // setImageError("");

                        //  } else {
                        //      alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                        //  }                
                      }

                      setFsize(e.currentTarget.files[0].size);


                    }}
                  />
                </div>

              </section>
              // <aside class="image">

              //             {/* <img class="screen" src={file3} alt=""/> */}

              //             <input
              //                 type="file"
              //                 id="file-upload4"
              //                 style={{display:'none'}}
              //                 name="myfile"
              //                 accept={"image/*"}
              //                 onChange={(e) => {
              //                 if(e.currentTarget.files[0].size>=500000)
              //                 {
              //                 alert("Images size must be less than 500kb")
              //                 return 0
              //                 }
              //                 // setImageurl4(e.currentTarget.files[0]);
              //                 // setFsize4(e.currentTarget.files[0].size)
              //                 // setfile4(
              //                 //   URL.createObjectURL(
              //                 //     e.currentTarget.files[0]
              //                 //   )
              //                 // );
              //                 // handleChange222(e.currentTarget.files[0]);
              //               //   handleChange2(e.currentTarget.files[0]);

              //                let img = new Image()
              //           img.src = window.URL.createObjectURL(e.target.files[0]);

              //           img.onload = () => {
              //           if(img.width >= 500 && img.height >= 500){       
              //                   setnewfile4(img.src);
              //                   // setImageError("");

              //                   } else {
              //                       alert('Incorrect image dimension. Please upload an image of 500x500 pixels.')
              //                   }                
              //           }

              //         setFsize(e.currentTarget.files[0].size);

              //               }}
              //         />
              //          <div class="overlay3">
              //             <label for="file-upload4">
              //                 <img for="upload-image" class="edit-img" src={editImageIcon} alt=""/>
              //             </label>
              //             <img onClick={() => deleteImage(product.imageUrls[3].imageId)} src={delIcon} alt="delete" className="del-icon" />
              //             </div>
              //     </aside>

            ) : null}

            {showfile4 !== null ? (


              <aside class="image">

                <img class="screen" src={showfile4 !== null ? showfile4 : result4} alt="" />

                <input
                  type="file"
                  id="file-upload5"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept={"image/*"}
                  onChange={(e) => {
                    // if(e.currentTarget.files[0].size>=500000)
                    // {
                    // alert("Images size must be less than 500kb")
                    // return 0
                    // }
                    // setImageurl4(e.currentTarget.files[0]);
                    setFsize4(e.currentTarget.files[0].size)
                    // setfile4(
                    //   URL.createObjectURL(
                    //     e.currentTarget.files[0]
                    //   )
                    // );
                    // handleChange22(
                    //   e.currentTarget.files[0],
                    //   product.imageUrls[4].imageId
                    // );
                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]);

                    img.onload = () => {
                      // if(img.width >=500 && img.height >=500){   

                      setImgId(product.imageUrls[4].imageId)
                      setfile4(img.src);

                      // setImageError("");

                      // } else {
                      //     alert('Incorrect image dimension. Please upload an image greater than 500X500 pixels.')
                      // }                
                    }


                  }}
                />
                <div class="overlay3  ">
                  <label for="file-upload5">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                  <img onClick={() => deleteImage(product.imageUrls[4].imageId)} src={delIcon} alt="delete" className="del-icon" />
                </div>
              </aside>


            ) : null}
            <span style={{ fontSize: '12px', color: 'red' }}>
              {sizeMsg4}
            </span>
          </div>

          <div className="single-image">
            {showfile5 === null && showfile4 !== null ? (
              <section className="image-uploaded">

                <div class="upload-btn-wrapper mt-2">
                  <button className="uplod-btn">
                    <img src={uploadIcon} alt="" /><br />
                    UPLOAD IMAGE
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    onChange={(e) => {
                      setResult5(e.target.files[0])
                      let img = new Image()
                      img.src = window.URL.createObjectURL(e.target.files[0]); 
                      img.onload = () => {       
                        setnewfile5(img.src); 
                      } 
                      setFsize(e.currentTarget.files[0].size); 
                    }}
                  />
                </div>

              </section>


            ) : null}

            {showfile5 !== null ? ( 
              <aside class="image"> 
                <img class="screen" src={showfile5 !== null ? showfile5 : result5} alt="" /> 
                <input
                  type="file"
                  id="file-upload6"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept={"image/*"}
                  onChange={(e) => { 
                    setFsize5(e.currentTarget.files[0].size) 
                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]);

                    img.onload = () => {
                      // if(img.width >=500 && img.height >=500){   

                      setImgId(product.imageUrls[5].imageId)
                      setfile5(img.src);              
                    } 
                  }}
                />
                <div class="overlay3  ">
                  <label for="file-upload6">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                  <img onClick={() => deleteImage(product.imageUrls[5].imageId)} src={delIcon} alt="delete" className="del-icon" />
                </div>
              </aside>


            ) : null}
            <span style={{ fontSize: '12px', color: 'red' }}>
              {sizeMsg5}
            </span>
          </div>

          <div className="single-image">
            {showfile6 === null && showfile5 !== null ? (
              <section className="image-uploaded">

                <div class="upload-btn-wrapper mt-2">
                  <button className="uplod-btn">
                    <img src={uploadIcon} alt="" /><br />
                    UPLOAD IMAGE
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    onChange={(e) => {

                      let img = new Image()
                      img.src = window.URL.createObjectURL(e.target.files[0]); 
                      img.onload = () => {       
                        setnewfile6(img.src); 
                      } 
                      setFsize(e.currentTarget.files[0].size); 
                    }}
                  />
                </div>

              </section>


            ) : null}

            {showfile6 !== null ? ( 
              <aside class="image"> 
                <img class="screen" src={showfile6 !== null ? showfile6 : result6} alt="" /> 
                <input
                  type="file"
                  id="file-upload7"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept={"image/*"}
                  onChange={(e) => { 
                    setFsize6(e.currentTarget.files[0].size) 
                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]);

                    img.onload = () => {
                      // if(img.width >=500 && img.height >=500){   

                      setImgId(product.imageUrls[6].imageId)
                      setfile6(img.src);              
                    } 
                  }}
                />
                <div class="overlay3  ">
                  <label for="file-upload7">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                  <img onClick={() => deleteImage(product.imageUrls[6].imageId)} src={delIcon} alt="delete" className="del-icon" />
                </div>
              </aside>


            ) : null}
            <span style={{ fontSize: '12px', color: 'red' }}>
              {sizeMsg6}
            </span>
          </div>

          <div className="single-image">
            {showfile7 === null && showfile6 !== null ? (
              <section className="image-uploaded">

                <div class="upload-btn-wrapper mt-2">
                  <button className="uplod-btn">
                    <img src={uploadIcon} alt="" /><br />
                    UPLOAD IMAGE
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    onChange={(e) => {

                      let img = new Image()
                      img.src = window.URL.createObjectURL(e.target.files[0]); 
                      img.onload = () => {       
                        setnewfile7(img.src); 
                      } 
                      setFsize(e.currentTarget.files[0].size); 
                    }}
                  />
                </div>

              </section>


            ) : null}

            {showfile7 !== null ? ( 
              <aside class="image"> 
                <img class="screen" src={showfile7 !== null ? showfile7 : result7} alt="" /> 
                <input
                  type="file"
                  id="file-upload8"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept={"image/*"}
                  onChange={(e) => { 
                    setFsize7(e.currentTarget.files[0].size) 
                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]);

                    img.onload = () => {
                      // if(img.width >=500 && img.height >=500){   

                      setImgId(product.imageUrls[7].imageId)
                      setfile7(img.src);              
                    } 
                  }}
                />
                <div class="overlay3  ">
                  <label for="file-upload8">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                  <img onClick={() => deleteImage(product.imageUrls[7].imageId)} src={delIcon} alt="delete" className="del-icon" />
                </div>
              </aside>


            ) : null}
            <span style={{ fontSize: '12px', color: 'red' }}>
              {sizeMsg7}
            </span>
          </div> 

        </div>
        </div>


        {/* Image Croopr Components */}

        <div style={{ width: '100%', height: '100%', marginTop: "50px" }}>

          <CustomCropperEdit
            handleChange22={handleChange22}
            setResult={setResult}
            src={file}
            selectFile={setfile}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperAdd
            handleChange222={handleChange222}
            setResult={setResult}
            src={newfile}
            selectFile={setfile}
            imgWidth={13500}
            imgHeight={1800}
          />


          <CustomCropperEdit
            handleChange22={handleChange22}
            setResult={setResult1}
            src={file1}
            selectFile={setfile1}
            imgWidth={1350}
            imgHeight={1800}
          />
          <CustomCropperAdd
            handleChange222={handleChange222}
            setResult={setResult1}
            src={newfile1}
            selectFile={setfile1}
            imgWidth={1350}
            imgHeight={1800}
          />


          <CustomCropperEdit
            handleChange22={handleChange22}
            setResult={setResult2}
            src={file2}
            selectFile={setfile2}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperAdd
            handleChange222={handleChange222}
            setResult={setResult2}
            src={newfile2}
            selectFile={setfile2}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperEdit
            handleChange22={handleChange22}
            setResult={setResult3}
            src={file3}
            selectFile={setfile3}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperAdd
            handleChange222={handleChange222}
            setResult={setResult3}
            src={newfile3}
            selectFile={setfile3}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperEdit
            handleChange22={handleChange22}
            setResult={setResult4}
            src={file4}
            selectFile={setfile4}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperAdd
            handleChange222={handleChange222}
            setResult={setResult4}
            src={newfile4}
            selectFile={setfile4}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperEdit
            handleChange22={handleChange22}
            setResult={setResult5}
            src={file5}
            selectFile={setfile5}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperAdd
            handleChange222={handleChange222}
            setResult={setResult5}
            src={newfile5}
            selectFile={setfile5}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperEdit
            handleChange22={handleChange22}
            setResult={setResult6}
            src={file6}
            selectFile={setfile6}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperAdd
            handleChange222={handleChange222}
            setResult={setResult6}
            src={newfile6}
            selectFile={setfile6}
            imgWidth={1350}
            imgHeight={1800}
          />

<CustomCropperEdit
            handleChange22={handleChange22}
            setResult={setResult7}
            src={file7}
            selectFile={setfile7}
            imgWidth={1350}
            imgHeight={1800}
          />

          <CustomCropperAdd
            handleChange222={handleChange222}
            setResult={setResult7}
            src={newfile7}
            selectFile={setfile7}
            imgWidth={1350}
            imgHeight={1800}
          />

        </div>


        {/* <div className="button">
                    <button  onClick={() => history.goBack()}>Skip</button> &nbsp;
                    <button  onClick={() => history.goBack()}>Publish</button>
                      </div> */}
        <div style={{ paddingTop: "4em" }} className="form-body ">

          <div className="single-field ">
            <p>Youtube Video URL </p>
            <input type="text"
              id="name"
              placeholder="Enter Url"
              name="name"
              value={youtubeurl}
              onChange={(e) => setyoutubeurl(e.target.value)}
            // onChange={handleChange}
            />
            {/* <i className="error">{nameError}</i> */}
          </div>
        </div>
        <div className="button">
          {/* <button  onClick={() => history.goBack()}>Skip</button> &nbsp; */}
          <button onClick={() => {
            axiosauth.post('/catalogue/update-catalogue', {
              youtubeUrl: youtubeurl,
              catalogueId: localStorage.getItem("image")
            }).then(response => {
              let res = JSON.parse(response.data)
              if (res.success === true) {
                alert("Successfully Updated")
                history.goBack()
                // // console.log("images created")
                // //  setShowLoader(false)
                // // setOpenSuccess(true);
                // setShowLoader(false)
                // setOpenSuccess(true);
              }
              else {


              }

            }).catch((error) => {
              console.log(error);
              // setNetworkError(true)
            }).then(() => {
              console.log("-----always executes");
            })
          }}>Update</button>
        </div>
      </div>
    </Layout>
  )
}

export default EditProductImage
