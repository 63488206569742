import {
  FETCH_CATEGORY,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  CREATE_CATEGORY,
  FETCH_CATEGORY_BY_ID,
  UPDATE_CATEGORY,
  ADD_IMAGE
} from '../constants/types';
import axiosauth from '../../axios-config-auth';

export function loadingCategory() {
  return {
    type: FETCH_CATEGORY,
  };
}

export function fetchCategory(category) {
  return {
    type: FETCH_CATEGORY_SUCCESS,
    payload: category
  };
}

export function fetchCategoryFail(category) {
  return {
    type: FETCH_CATEGORY_FAILURE,
    payload: category
  };
}

export function loadCategoryById(category) {
  return {
    type: FETCH_CATEGORY_BY_ID,
    payload: category
  };
}

export function addCategory(category) {
  return {
    type: CREATE_CATEGORY,
    payload: category
  };
}

export function EditCategory(category) {
  return {
    type: UPDATE_CATEGORY,
    payload: category
  };
}

export function addImage(image) {
  return {
    type: ADD_IMAGE,
    payload: image
  }
}


export const loadCategory = (search) => {
  return async dispatch => {
    dispatch(loadingCategory());
    let obj = {
      "count":0,
      "filters": {
        "type": "category",
        name: search
      }

    }
    axiosauth.post('/categories/load', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          dispatch(fetchCategory(res?.data));
        } else {
          dispatch(fetchCategoryFail(res.message));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(fetchCategoryFail("Network Error"));
      })
  };
};



export const loadCategoryByPage = (pageNumber) => {
  return async dispatch => {
    dispatch(loadingCategory());
    // console.log("pageNumberAction:",pageNumber)
    let obj = {
      count: (pageNumber - 1) * 10,
      filters: {
        type: "category",
      },
      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
    };
    axiosauth.post('/categories/load', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          dispatch(fetchCategory(res.data));
        } else {
          dispatch(fetchCategoryFail(res.message));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(fetchCategoryFail("Network Error"));
      })
  };
};


export const fetchCategoryById = (id) => {
  return async dispatch => {
    dispatch(loadingCategory());
    // console.log("category by id")
    let obj = {
      "categoryId": id
    };
    axiosauth.post('/categories/load-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("category by res:",res)
        if (res.success === true) {
          // console.log("if res:", res.data)
          dispatch(loadCategoryById(res.data.category.category[0]));
        } else {
          dispatch(fetchCategoryFail(res.message));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(fetchCategoryFail("Network Error"));
      })

  };
};


export const deleteCategoryById = (obj) => {
  return async dispatch => {
    dispatch(loadingCategory());
    axiosauth.post('/categories/delete-categories', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("response->", res)
        // console.log("inside action delete - ", res)
        if (res.success === true) {
          // console.log("successfully deleted !", res)
          dispatch(loadCategory());
        } else {
          // console.log("failed !!")
          dispatch(fetchCategoryFail(res.message));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(fetchCategoryFail("Network Error"));
      })

  };
};

//  Update Image ::

const imageUpdate = (cId, imageFile) => {
  // console.log("id", cId, "Image =>", imageFile)
  var formData = new FormData();
  formData.append("categoryId", cId);
  formData.append("type", "rectangle");
  formData.append("image", imageFile);
  axiosauth.post('/categories/edit-image', formData)
    .then(async (response) => {
      let res = JSON.parse(response.data)
      if (res.success === true) {
       
        // console.log("image res:", res);
      } else {
        alert(res.message)
        // console.log("..")
      }
    })
    .catch((error) => {
      console.log(error);
    })
}

export const createCategory = (obj, fileImage) => {
  return async dispatch => {
    dispatch(loadingCategory());
    // console.log("created category data =>", obj)
    axiosauth.post('/categories/create-category', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          imageUpdate(res.message.categoryId, fileImage)
          // console.log("res->", res.message.categoryId)
          dispatch(addCategory(res));

        } else {
          // console.log("error", res)
          alert(res.message)

          dispatch(fetchCategoryFail(res.message));
        }
      })
      .catch((error) => {
        // console.log("error")
        // console.log(error);
        dispatch(fetchCategoryFail("Network Error"));
      })

  };
};

export const updateCategory = (obj, fileImage) => {
  return async dispatch => {
    dispatch(loadingCategory());
    // console.log("coupon action")
    axiosauth.post('/categories/update-category', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("res:", res)
        if (res.success === true) {
          imageUpdate(obj.categoryId, fileImage)
          dispatch(EditCategory(res));
        } else {
          dispatch(fetchCategoryFail(res.message));
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(fetchCategoryFail("Network Error"));
      })

  };
};


