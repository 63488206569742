import React from 'react';
import './order copy.scss';

const GeneralDetailsView = ({orders,createdBy,transactionId ,user}) => {
   

    const showDate = (data) => {
        var d = new Date(data);
        var date = d.getUTCDate();
        // var date = d.getDate();
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
        var year = d.getFullYear();
        var dateStr = date + " " + months[month] + " " + year;
        // var res =
        return dateStr;
      };

    return (
        <div className="card content-p-h6 p-3">
            {/* <div className="row justify-content-between mb-2">
                <div className="col-md-6 col-sm-4 col-6">
                    <p>Order Status</p>
                    <span className="card px-3 py-1 my-1">
                    
                  
                    {orders.isCancelled===true ?
                    <span className="isCancelled">Cancelled</span> 
                    :
                        (orders.isDelivered===true ?
                        <span className="isDelivered">Delivered</span>
                        :
                        (orders.isShipped ===true ?
                        <span className="isShipped">Shipped</span>
                        :
                        <span className="isProgress">In Progress</span>
                        )
                        )
                    }

                    
                    </span>
                </div>
               {payment==="online"?(<div className="col-md-4 col-sm-4 col-6">
                    <p>Transaction ID</p>
                    <h6>{transactionId}</h6>
                </div>):null} 
            </div> */}
            
            <div  className='row'>
            <div className="col-md-4 general-content d-flex flex-column">
                <div className='d-flex mb-2'>
                <p className="heading">Order Date : </p>
                <h6 className='ml-1'>{showDate(orders?.createdOn)}</h6>
                </div>

                <div className='d-flex'>
                <p className="heading">Order ID : </p>
                <h6 className='ml-1 mb-0'>{orders?.orderId}</h6>
                </div>
            </div>
            {/* <div className="col-md-3 general-content d-flex flex-column">
                <div className='d-flex'>
                <p>Invoice Date : </p>
                <h6>{(orders?.invoiceDate)?.substring(0,10)}</h6>
                </div>

                <div className='d-flex'>
                <p >Invoice ID : </p>
                <h6>{orders?.invoiceId}</h6>
                </div>
            </div> */}
            <div className="col-md-4 general-content d-flex flex-column">
                <div className='d-flex'>
                <p className="heading">Customer Name : </p>
                <h6 className='ml-1'>{createdBy?.firstName} {createdBy?.lastName}</h6>
                </div> 
                <div className='d-flex'>
                <p className="heading">Email : </p>
                <h6 className='ml-1 mb-0'>{user?.userEmail}</h6>
                </div>
            </div>
            <div className="col-md-4 general-content d-flex flex-column">
                <div className='d-flex'>
                <p className="heading">Mobile Number : </p>
                <h6 className='ml-1'>{createdBy?.mobile}</h6>
                </div>
                
            </div>
 
            </div>
            
        </div>
    )
}

export default GeneralDetailsView;
