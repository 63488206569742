import React, { useState, useEffect } from 'react';
import md5 from "md5";
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from "react-router-dom";
import { updatePassword, fetchPasswordStatus } from '../../redux/actions/settingAction';
import SuccessModal from '../../components/halper/SuccessModal';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const userpassword = useSelector(state => state.changePassword);
  console.log("userpassword", userpassword)

  const [passError, setPassError] = useState("")
  const [passMsg, setPassMag] = useState("")
  const [redirect, setRedirect] = useState(false);
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  })

  const passwordChange = (e) => {
    setPassword({ ...password, error: false, [e.target.name]: e.target.value })
  }

  const passwordSubmit = (e) => {
    e.preventDefault();

    if (password.oldPassword.length < 6 || password.newPassword.length < 6) {
      setPassError('password too short');
      setPassMag('');
    }
    else if (password.confirmPassword !== password.newPassword) {
      setPassError('password do not match');
      setPassMag('');
    }
    else {
      let obj = {
        // oldPassword: md5(password.oldPassword),
        // password: md5(password.newPassword),
        oldPassword: (password.oldPassword),
        password:(password.newPassword),
      };
      console.log("password:", obj)
      dispatch(updatePassword(obj));
    }

  }

  useEffect(() => {
    dispatch(fetchPasswordStatus());
  }, [dispatch]);

  const closeModal = () => {
    setRedirect(true);
  }

  const togglePassword1 = () => {
    const typ1 = document.getElementById("newPassword")
    let eye1 = document.getElementById("eye-pass1")
    eye1.classList.toggle('visibility')
    if (typ1.type === 'password') {
      typ1.setAttribute('type', 'text')
    }
    else {
      typ1.setAttribute('type', 'password')
    }

  }
  const togglePassword2 = () => {
    const typ2 = document.getElementById("confirmPassword")
    let eye2 = document.getElementById("eye-pass2")
    eye2.classList.toggle('visibility')
    if (typ2.type === 'password') {
      typ2.setAttribute('type', 'text')
    }
    else {
      typ2.setAttribute('type', 'password')
    }

  }
  const togglePassword = () => {
    const typ = document.getElementById("oldPassword")
    let eye = document.getElementById("eye-pass")
    eye.classList.toggle('visibility')
    console.log(typ.type)
    if (typ.type === 'password') {
      typ.setAttribute('type', 'text')
    }
    else {
      typ.setAttribute('type', 'password')
    }
  }

  return (
    <>
      <form onSubmit={passwordSubmit}>
        <div className="changePass-wrap">
          <section>
            <p>Old Password</p>
            <div>
              <input type="password"
                name="oldPassword"
                id="oldPassword"
                onChange={passwordChange}
                required />
              <aside id="eye-pass" className="eye-view" onClick={togglePassword}>
              </aside>
            </div>
          </section>
          <section>
            <p>New Password</p>
            <div>
              <input type="password"
                name="newPassword"
                id="newPassword"
                onChange={passwordChange}
                required />
              <aside id="eye-pass1" className="eye-view" onClick={togglePassword1}>
              </aside>
            </div>
          </section>
          <section>
            <p>Confirm Password</p>
            <div>
              <input type="password"
                name="confirmPassword"
                id="confirmPassword"
                onChange={passwordChange}
                required />
              <aside id="eye-pass2" className="eye-view" onClick={togglePassword2}>
              </aside>
            </div>
          </section>
          {passError}
          {passMsg}
          <div className="button">
            <button>Change</button>
          </div>
        </div>
      </form>

      {redirect ? <Redirect to="/dashboard" /> : null}
      {userpassword.data.success === true ? <SuccessModal message="Password Change Success!" onClick={closeModal} /> : null}
    </>
  )
}

export default ChangePassword