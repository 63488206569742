import React from 'react';
import Layout from '../../layout/layout';
import EditVariantForm from '../../wrapper/variant/EditVariantForm';

const EditVariantPage = () => {

  return (
    <Layout>
      <EditVariantForm />
    </Layout>
  )
}

export default EditVariantPage