import React from 'react';
import { useEffect, useState } from 'react'
import SearchBox from '../../components/halper/SearchBox';
import { Link } from 'react-router-dom';
import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
import { loadCategory, deleteCategoryById, loadCategoryByPage } from '../../redux/actions/categoryAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";
import { useHistory } from 'react-router-dom';
import noImage from '../../assets/images/no-img.png';
import NoData from '../../components/halper/NoData';

const CategoryList = () => {
  const dispatch = useDispatch();
  const categoryData = useSelector(state => state.categoryData); 
  // for pagination
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get('page'); 

  // const handlePageChange = (pageNumber) => {
  //   history.push(`/category-manage?pageCount=${pageNumber}`);
  //   dispatch(loadCategoryByPage(pageNumber))
  // }

  useEffect(() => {

    dispatch(loadCategoryByPage(pageCount));

  }, [dispatch, pageCount]);



  // Handle Pagination

  const [locationKeys, setLocationKeys] = useState([]);

  //   const getQueryVariable = (variable) => {
  //   var query = window.location.search.substring(1);
  //   console.log(query)//"app=article&act=news_content&aid=160990"
  //   var vars = query.split("&");
  //   console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
  //   for (var i = 0; i < vars.length; i++) {
  //     var pair = vars[i].split("=");
  //     console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
  //     if (pair[0] === variable) { return pair[1]; }
  //   }
  //   return (false);
  // }

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      } 

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)
        } else {
          setLocationKeys((keys) => [location.key, ...keys])

        }
      }
    })
  }, [locationKeys, history])

  const handlePageChange = (pageNumber) => {
    // console.log("dd",pageNumber)
    dispatch(loadCategoryByPage(pageNumber))
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };


  return (
    <>
      <div className="button" style={{ marginTop: "-50px" }}>
        <Link to="/create-category">
          <button>
            Add New Category
          </button>
        </Link>
      </div>
      <SearchBox
        placeholder="Search Category Name.."
        className="mt-md-0"
        onChange={(e) => dispatch(loadCategory(e.target.value))}
      />

      {categoryData.loading ?
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div> :
        <>

          <div className="table-wrap overflow-auto table-responsive card py-4 " style={{height:"500px"  }}>
            <table>
              {categoryData.data.categories && categoryData.data.categories.length > 0 ? (<thead>
                <tr>
                  <th className="text-nowrap px-3" >S No</th>
                  <th className="text-nowrap px-3" >Category Name</th>
                  <th className="text-nowrap px-3" >Category Image</th>
                  <th></th>
                </tr>
              </thead>) : null}
              <tbody>

                {
                  categoryData.data.categories && categoryData.data.categories.length > 0 ?
                    <>
                      {categoryData.data.categories.map((category, index) => {
 
                        const deleteCategory = () => {
                          let obj = {
                            "categoryIds": category.categoryId
                          }; 
                          confirmAlert({
                            // title: 'Change Status',
                            message: 'Are you sure you want to delete this category',
                            buttons: [
                              {
                                label: 'Yes',
                                onClick: () => { 
                                  dispatch(deleteCategoryById(obj));
                                }
                              },
                              {
                                label: 'No',
                                onClick: () => {
                                  console.log("not detele")
                                }
                              }
                            ]
                          });
                        }
                        return (
                          <tr key={index}>
                            <td>{((pageCount ? pageCount : 1) - 1) * 10 + (index + 1)}</td>
                            <td className="titleStyle">{category.name}</td>
                            <td>
                              <div className="banner-img">
                                <img src={category.image ? `${category.image}?date=${new Date()}` : noImage} alt="banner-img" className="category-image" style={{ width: "70px", height: "3em", objectFit: "contain" }} />
                              </div>
                            </td>
                            <td>
                              <Link to={`/edit-category/${category.categoryId}`}>
                                {/* {category.categoryId} */}
                                <img src={editIcon} alt="edit-button" className="edit-icon" />
                              </Link>
                              <img src={deleteIcon} alt="delete" className="delete-icon" onClick={deleteCategory} />
                            </td>
                          </tr>
                        )
                      }
                      )}
                    </>
                    : (<NoData message="Category Not Found" />)
                }

              </tbody>
            </table>
           
          </div>
          <div className="pagination-custom">
              <Pagination
                hideDisabled
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={categoryData.data.count}
                pageRangeDisplayed={5}
                hideNavigation
                onChange={handlePageChange}
              />
            </div>
        </>
      }


      <br />
    </>
  )
}

export default CategoryList