import React from 'react';
import Layout from "../layout/layout"
import UserList from '../wrapper/userManage/userList';

const UserDetail = () => {

  return (
    <Layout>
      <div>
        <UserList />
      </div>
    </Layout>
  )
}

export default UserDetail