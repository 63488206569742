/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axiosauth from "../../axios-config-auth";

const addEditContainer = (seoId) => {
  const initialState = {
    title: "",
    description: "",
    error: "",
    success: false,
    responseError: false,
    msg: "",
  };

  const [homeSeo, setHomeSeo] = useState(initialState);
  const editorRef = useRef(null);
  const [seoText, setSEOText] = useState("");
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setHomeSeo({
      ...homeSeo,
      [name]: value,
      error: "",
    });
  };

  const handleAction = () => {
    if (!homeSeo?.title && !homeSeo?.description && !seoText) {
      setHomeSeo({
        ...homeSeo,
        error: "Required***, Please enter Title or Description or SEO text...",
      });
      return;
    }

    const obj = {
      ...(seoId ? { id: seoId } : ""),
      title: homeSeo?.title || "",
      description: homeSeo?.description || "",
      footerSeo: seoText?.newText || seoText || "",
      //   footerSeo: seoText?.newText || "",
    };

    setLoader(true);

    axiosauth
      .post(
        `/homePageSeo/${seoId ? "update-homepageseo" : "post-homepageseo"}`,
        obj
      )
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setHomeSeo({
            ...homeSeo,
            success: true,
          });
          setLoader(false);
        } else {
          setHomeSeo({
            ...homeSeo,
            responseError: true,
            msg: res?.message || res?.data?.message,
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        setHomeSeo({
          ...homeSeo,
          responseError: true,
          msg: error?.message,
        });
      });
  };

  const getData = async () => {
    setLoader(true);
    axiosauth
      .post("/homePageSeo/load-homepageseo")
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setHomeSeo({
            ...homeSeo,
            title: res?.homePageSeo?.title,
            description: res?.homePageSeo?.description,
          });
          setSEOText(res?.homePageSeo?.footerSeo);
          setLoader(false);
        } else {
          setHomeSeo({
            ...homeSeo,
            responseError: true,
            msg: res?.message || res?.data?.message,
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        setHomeSeo({
          ...homeSeo,
          responseError: true,
          msg: error?.message,
        });
        setLoader(false);
      });
  };

  useEffect(() => {
    if (seoId) {
      getData();
    }
  }, [seoId]);

  const closeModal = () => {
    setHomeSeo({
      ...homeSeo,
      success: false,
    });
    history.push("/home-page-seo");
  };

  const closeErrorModal = () => {
    setHomeSeo({
      ...homeSeo,
      responseError: false,
      msg: "",
    });
  };

  return {
    homeSeo,
    handleChange,
    closeModal,
    closeErrorModal,
    loader,
    editorRef,
    setSEOText,
    setHomeSeo,
    seoText,
    handleAction,
  };
};

export default addEditContainer;
