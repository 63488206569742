import React, { useState, useEffect } from 'react';
import Layout from "../../layout/layout";
// import CustomCropper from '../../components/halper/CustomCropper';
// import editImageIcon from '../../assets/icons/edit.png';
import uploadIcon from '../../assets/icons/upload.png';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanners} from '../../redux/actions/bannerAction';
import axiosauth from '../../axios-config-auth';    
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import {useHistory } from 'react-router-dom';
// import editIcon from '../../assets/icons/edit.png';

import SuccessModal from '../../components/halper/SuccessModal';
import LoaderModal from '../../components/halper/LoaderModal';
// import deleteIcon from '../../assets/icons/delete.png';
// import noImage from '../../assets/images/no-img.png';
const AddBanner = () => {
    // const [result, setResult]=useState(null);
    // const [imageObj, setImageObj]=useState(null);

    const [show, setShow] = useState(false);

    const history = useHistory();
    const [file, setfile] = useState(null);
    const [urlFile, setUrlFile] = useState(null)

    const [titleError, setTitleError]=useState(null)
    const [urlError, setUrlError]=useState(null)

    const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const [bannerList, setBannerlist]=useState([]);
    const[linkerror,setlinkerror]=useState("")
    const banners = useSelector(state => state.bannerdata)
    console.log("banner data->", banners, "type=>", typeof banners)

    console.log("banner-->", urlFile);
    // banner_list=[]
    // for(i=0;i<banners.length;i++)
    const dispatch = useDispatch()

    const [slideBanner, setSlideBanner] = useState({
        title: "",
        newtab: false,
        link:"http://toyvala-main-website.s3-website.ap-south-1.amazonaws.com/",
        alt:"",
        available:true,
        typeId:"O4FFLiVb"
    });

    const handleChange = (e) => {
        setSlideBanner({ ...slideBanner, error: false, [e.target.name]: e.target.value });
        setTitleError("")
    }



    const bannerUpload =()=>{
        // let obj = {
        //     "title": slideBanner.title,
        //     "link": slideBanner.link,
        //     "newtab": false,
        //     "alt": "banner-image",
        //     "startDate": "",
        //     "expirationDate": "",
        //     "available": true,
        //     "typeId": "banner-top",
        //     "image": imageObj
        // }

        // console.log("obj-->", obj)
        if(slideBanner.title === ""){
            setTitleError("Banner title Required");
            return 0;
        }
        if(slideBanner.link===null){
            setlinkerror("Page Url required");
            return 0;
        }
        if(file===null){
            setUrlError("Banner required");
            return 0;
        }
        

        var formData = new FormData();

        formData.append("title", slideBanner.title);
        formData.append("link", slideBanner.link);
        formData.append("newtab", false);
        formData.append("alt", "banner-image");
        formData.append("available", true);
        formData.append("typeId", "O4FFLiVb");
        formData.append("image", urlFile);

        setShowLoader(true)

        axiosauth.post('/banner/post-banner', formData)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        if (res.success === true) {
          setShowLoader(false)
          setShow(true)
        } else {
   
        }
      })
      .catch((error) => {
        console.log(error);
      })


        // dispatch(uploadBanners(formData))
    }

    useEffect(() => {

let obj={

}
        axiosauth.post('/banner/load-banner', obj)
        .then(async (response) => {
            let res = JSON.parse(response.data)
            // console.log("jdkd", res)
            if (res.success === true) {
                console.log("banner true-->", res.data.banners);
                setBannerlist(res.data.banners)
            } else {
                console.log("banner fasle-->", res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })


        dispatch(fetchBanners())
    }, [dispatch])


    console.log("bannerList-->",bannerList)

    const closeModal = () => {
        setRedirect(true);
    }

    const connectionError=()=>{
        setNetworkError(false)
    }


    return (
        <Layout>

         {redirect ? <Redirect to="/banner" /> : null}
            {show === true ? <SuccessModal message="New Banner Added!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null}



<div className="form-wrap">
                {/* <div className="form-header"> */}
                    {/* <h4>Add Brand</h4> */}

                    <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                        <img onClick={()=>history.goBack()} src={left_arrow2}   alt="left-arrow" /> &nbsp;
                            <h2>Add Banner</h2>
                    </div>
                {/* </div> */}
                <div className="form-body">

                    <div className="single-field">
                        <p>Banner Title <b>*</b></p>
                        <input type="text"
                            id="title"
                            placeholder="Enter title"
                            name="title"
                            onChange={handleChange}
                        />
                        <i className="error">{titleError}</i>
                    </div>




                    {/* <i className="error">{urlError}</i> */}
                    {/* <div id="short-description">
                        <p>Banner Description <b>*</b></p>
                        <textarea
                            placeholder="Short Description"
                            name="description"
                            onChange={handleChange}
                           
                        />
                       
                    </div> */}

                    <div className="single-field">
                        <p>Banner URL <b>*</b></p>
                        <input type="text"
                            id="link"
                            placeholder="page url"
                            name="link"
                            onChange={handleChange}
                        />
                        <i className="error">{linkerror}</i>
                    </div>


{/* 

                    <section className="image-uploaded">
                        {file === null && result=== null ?
                            <aside class="upload-btn-wrapper mt-2">
                                <button className="uplod-btn">
                                    <img src={uploadIcon} alt="" /><br />
                                Upload Banner
                                
                                </button>
                                <input
                                    type="file"
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.currentTarget.files[0].size >= 400000) {
                                            alert("Images size must be less than 400kb")
                                            return 0
                                        }

                                        let img = new Image()
                                        img.src = window.URL.createObjectURL(e.target.files[0]);

                                        img.onload = () => {
                                        if(img.width >= 300 && img.height >= 300){       
                                            setfile(img.src);

                                                } else {
                                                    alert('Incorrect image dimension. Please upload an image of 300x300 pixels.')
                                                   
                                                }                
                                        }

                                    }}
                                />
                                
                            </aside> :
                            <aside class="image">
                                <div class="overlay">
                                <label for="file-upload">
                                    <img for="upload-image" class="edit-img" src={editImageIcon} alt=""/>
                                </label>
                                </div>
                                <img class="screen" src={result ? result : file} alt=""/>
                                
                                 <input
                                    type="file"
                                    id="file-upload"
                                    style={{display:'none'}}
                                    name="myfile"
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.currentTarget.files[0].size >= 400000) {
                                            alert("Images size must be less than 400kb")
                                            return 0
                                        }
                                        
                                        
                                        let img = new Image()
                                        img.src = window.URL.createObjectURL(e.target.files[0]);

                                        img.onload = () => {
                                        if(img.width >= 300 && img.height >= 300){       
                                            setfile(img.src);

                                                } else {
                                                    alert('Incorrect image dimension. Please upload an image of 300x300 pixels.')
                                                   
                                                }                
                                        }

                                    }}
                                
                                />


                  

                            </aside>
                        }
                        
                    </section> */}



                    <div className="banner-wrap">

{/* {isFile === null ? ( */}
    <>

    {file ?
        <img src={file} alt="" />
:null}


        {/* <CustomCropper
                        setResult={setResult}
                        src={file}
                        selectFile={setfile}
                        imgWidth={800}
                        imgHeight={400}
                        setImageObj={setImageObj}
                    /> */}


        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <div class="upload-btn-wrapper1">
                <button className="uplod-btn1">
                    Upload image(1200 X 400)
        <img src={uploadIcon} alt="" />
                </button>
                <input
                    type="file"
                    name="myfile"
                    onChange={(e) => {
                        // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                        if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                        }
                        setUrlError(null)
                       

                        let img = new Image()
                            img.src = window.URL.createObjectURL(e.target.files[0]);
                            setUrlFile(e.currentTarget.files[0]);
                            img.onload = () => {
                            if(img.width === 1200 && img.height === 400){       
                                setfile(img.src);
                                    } else {
                                        alert('Incorrect image dimension. Please upload an image of 1200x400 pixels.')
                                        
                                    }                
                            }

                    }}
                />
            </div>

            <div style={{ display: 'flex' }}>

                {/* <div class="upload-btn-wrapper1">
                    <button className="uplod-btn1" style={{ background: "#209F84", color: "#FFFFFF" }}>
                        change image
                    </button>
                    <input
                        type="file"
                        name="myfile"
                        onChange={(e) => {
                            // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                            setUrlError(null)
                            setUrlFile(e.currentTarget.files[0]);

                            let img = new Image()
                                img.src = window.URL.createObjectURL(e.target.files[0]);

                                img.onload = () => {
                                if(img.width >= 1200 && img.height >= 400){       
                                    setfile(img.src);

                                        } else {
                                            alert('Incorrect image dimension. Please upload an image of 1200x400 pixels.')
                                            
                                        }                
                                }


                        }}
                    />
                </div> */}
                {/* <div className="banner-delete-button">
                    <button
                        onClick={(e) => {
                            setfile(null)
                            setUrlFile(null)
                        }}
                    >Delete</button>
                </div> */}
            </div>
        </div>
    </>
{/* ):null} */}
</div>


 <i className="error">{urlError}</i>



                </div>
                <div className="button">
                    <button onClick={bannerUpload}>Add Banner</button>
                </div>
            </div>


        </Layout>
    )
}

export default AddBanner
