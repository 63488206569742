import React, { useState, useEffect, useRef } from 'react'
import Layout from "../../layout/layout";
import uploadIcon from '../../assets/icons/upload.png';
import editImageIcon from '../../assets/icons/edit.png';
import axiosauth from '../../axios-config-auth';
import { Redirect, useParams, useHistory } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';
import LoaderModal from '../../components/halper/LoaderModal';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import Loader from 'react-loader-spinner';
import { Editor } from '@tinymce/tinymce-react';

const UpdateBrand = () => {
    const history = useHistory()
    const [show, setShow] = useState(false);

    const [file, setfile] = useState("");
    const [file2, setfile2] = useState("");

    const [urlFile, setUrlFile] = useState("");
    const [urlFile2, setUrlFile2] = useState("");

    const [isFile, setIsFile] = useState(false);
    const [isFile2, setIsFile2] = useState(false);

    const [errorMsg, setErrorMsg] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const [countError, setCountError] = useState(null);
    const [descriptionError, setDescriptionError] = useState("");

    const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [dataLoader, setDataLoader] = useState(true);
    const [seoText, setSEOText] = useState('');
    const editorRef = useRef(null);

    const { brandId } = useParams();

    const [brand, setBrand] = useState({
        name: "",
        description: "",
    });

    const handleChange = (e) => {
        setBrand({ ...brand, error: false, [e.target.name]: e.target.value })
        if (e.target.name === "description") {
            setCharCount(e.target.value.length)
            setCountError("")
        }
        setDescriptionError("")
    }



    const imageUpdate = (brandId) => {
        var formData = new FormData();
        formData.append("brandId", brandId);
        formData.append("logo", urlFile);
        formData.append("image", urlFile2);


        axiosauth.post('/brand/upload-brand-logo', formData)
            .then(async (response) => {

                let res = JSON.parse(response.data)
                if (res.success === true) {
                    // console.log("image res:", res);
                    setShowLoader(false)
                    setShow(true)
                    setUrlFile("");
                    setUrlFile2("")
                    setIsFile(false);
                    setTimeout(
                        function () {
                            // setShow(false);

                        }, 1100);

                } else {
                    setErrorMsg(res.message);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const submitBrand = (e) => {
        e.preventDefault();


        if (brand.description === "") {
            setDescriptionError("Brand Description Required");
            return 0;
        }

        if (charCount > 2000) {
            setCountError("Brand description should be <=2000 Character.")
            setDescriptionError("");
            // console.log("Check condition")
            return 0;

        }

        let obj = {
            name: brand.name,
            description: brand.description,
            seoText :seoText.newText,
            brandId: brandId
        }
        // console.log("brand", obj)
        setShowLoader(true)
        axiosauth.post('/brand/update-brand', obj)
            .then(async (response) => {
                // console.log("response", response);
                let res = JSON.parse(response.data)
                if (res.success === true) {
                    setErrorMsg('');

                    // console.log("brand create res:", res)
                    if (isFile === true) {
                        imageUpdate(brandId);

                    }
                    else {
                        setShow(true);
                    }

                } else {
                    setErrorMsg(res.message);
                }
            })
            .catch((error) => {
                // console.log(error);
                setNetworkError(true);
            })
    }

    useEffect(() => {
        let obj = {
            "brandId": brandId
        }
        axiosauth.post('/brand/find-brandby-id', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("rj", res)
                if (res.success === true) {
                    // setCollections(res.data.collection)
                    setBrand({
                        name: res.result.name,
                        description: res.result.description
                    })

                    setCharCount(res.result.description.length)
                    setSEOText(res.result.seoText)
                    if (res.result.logo !== undefined) {
                        // console.log("logo here")
                        setfile(res.result.logo)
                        setIsFile(true)

                    }
                    if (res.result.image !== undefined) {
                        // console.log("image here")
                        setfile2(res.result.image)
                        setIsFile2(true)

                    }

                    setDataLoader(false)


                } else {

                }
            })
            .catch((error) => {
                console.log(error);
                // console.log("error-->",error);
            })
    }, [brandId]);



    const closeModal = () => {
        setRedirect(true);
    }

    const connectionError = () => {
        setNetworkError(false)
    }

    return (
        <Layout>

            {!dataLoader ?
                <>

                    <form onSubmit={submitBrand} className="form-wrap">
                        {/* <div className="form-header"> */}
                        <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                            <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                            <h2>Update Brand</h2>
                        </div>
                        {/* <h4>Update Brand</h4> */}
                        {/* </div> */}


                        <div className="form-body">

                            <div className="single-field">
                                <p>Brand Name <b>*</b></p>
                                <input type="text"
                                    id="name"
                                    placeholder="Brand Name"
                                    name="name"
                                    value={brand.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <section className="image-uploaded">
                                {!isFile ?
                                    <div className="upload-btn-wrapper mt-2">
                                        <button className="uplod-btn">
                                            <img src={uploadIcon} alt="" /><br />
                                            Upload Logo
                                        </button>
                                        <input
                                            type="file"
                                            name="myfile"
                                            accept="image/*"
                                            onChange={(e) => {

                                                if (e.currentTarget.files[0].size >= 204800) {
                                                    alert("Images size must be less than 200KB")
                                                    return 0
                                                }
                                                // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                                                setIsFile(true);
                                                setUrlFile(e.currentTarget.files[0]);

                                                let img = new Image()
                                                img.src = URL.createObjectURL(e.currentTarget.files[0])
                                                img.onload = () => {
                                                    if (img.width === 281 && img.height === 80) {
                                                        setfile(img.src);
                                                        setIsFile(true);
                                                    } else {
                                                        alert('Incorrect image dimension. Please upload an image 281X80 pixels.')
                                                    }
                                                }
                                            }}
                                        />
                                    </div> :
                                    <aside className="image">
                                        <div className="overlay">
                                            <label htmlFor="file-upload">
                                                <img htmlFor="upload-image" className="edit-img" src={editImageIcon} alt="" />
                                            </label>
                                        </div>
                                        <img className="screen" src={file} alt="" />

                                        <input
                                            type="file"
                                            id="file-upload"
                                            style={{ display: 'none' }}
                                            name="myfile"
                                            accept="image/*"
                                            onChange={(e) => {
                                                if (e.currentTarget.files[0].size >= 204800) {
                                                    alert("Images size must be less than 200KB")
                                                    return 0
                                                }
                                                // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                                                setIsFile(true);
                                                setUrlFile(e.currentTarget.files[0]);

                                                let img = new Image()
                                                img.src = URL.createObjectURL(e.currentTarget.files[0])
                                                img.onload = () => {
                                                    if (img.width === 281 && img.height === 80) {
                                                        setfile(img.src);
                                                        setIsFile(true);
                                                    } else {
                                                        alert('Incorrect image dimension. Please upload an image  281X80 pixels.')
                                                    }
                                                }
                                            }}
                                        // required
                                        />
                                    </aside>


                                }
                            </section>

                            <section className="image-uploaded my-5">
                                {!isFile2 ?
                                    <div className="upload-btn-wrapper mt-2">
                                        <button className="uplod-btn">
                                            <img src={uploadIcon} alt="" /><br />

                                            Upload image
                                        </button>
                                        <input
                                            type="file"
                                            name="myfile2"
                                            accept="image/*"
                                            onChange={(e) => {
                                                // setfile2(URL.createObjectURL(e.currentTarget.files[0]));
                                                setIsFile2(true);
                                                setUrlFile2(e.currentTarget.files[0]);

                                                if (e.currentTarget.files[0].size >= 204800) {
                                                    alert("Images size must be less than 200KB")
                                                    return 0
                                                }

                                                let img = new Image()
                                                img.src = URL.createObjectURL(e.currentTarget.files[0])
                                                img.onload = () => {
                                                    if (img.width === 281 && img.height === 420) {

                                                        setfile2(img.src);
                                                        setIsFile2(true);
                                                    } else {
                                                        alert('Incorrect image dimension. Please upload an image 281X420 pixels.')
                                                    }
                                                }
                                            }}
                                        />
                                    </div> :
                                    <aside className="image">

                                        <div className="overlay">
                                            <label htmlFor="file-upload2">
                                                <img htmlFor="upload-image" className="edit-img" src={editImageIcon} alt="" />
                                            </label>
                                        </div>
                                        <img className="screen" src={file2} alt="" />

                                        <input
                                            type="file"
                                            id="file-upload2"
                                            style={{ display: 'none' }}
                                            name="myfile2"
                                            accept="image/*"
                                            onChange={(e) => {
                                                // setfile2(URL.createObjectURL(e.currentTarget.files[0]));
                                                setIsFile2(true);
                                                setUrlFile2(e.currentTarget.files[0]);
                                                if (e.currentTarget.files[0].size >= 204800) {
                                                    alert("Images size must be less than 200KB")
                                                    return 0
                                                }

                                                let img = new Image()
                                                img.src = URL.createObjectURL(e.currentTarget.files[0])
                                                img.onload = () => {
                                                    if (img.width === 281 && img.height === 420) {

                                                        setfile2(img.src);
                                                        setIsFile2(true);
                                                    } else {
                                                        alert('Incorrect image dimension. Please upload an image  281X420 pixels.')
                                                    }
                                                }
                                            }}
                                        // required
                                        />
                                    </aside>


                                }
                            </section>

                            <div id="short-description">
                                <p className="label-form">Brand Description(max-2000 Character, Character Count:<span className={charCount <= 500 ? "word-counter" : "word-counter-error"} > {charCount}</span>) <b>*</b></p>
                                <textarea
                                    placeholder="short Description"
                                    name="description"
                                    value={brand.description}
                                    onChange={handleChange}
                                // onKeyDown={e => /[+\-!@#$%^&*()_+{}:></~]$/.test(e.key) && e.preventDefault()}


                                />
                            </div>
                            <div className='mt-3'>
                                <p className="label-form">SEO Text </p>
                                <Editor
                                    onInit={(evt, editor) => { editorRef.current = editor }}
                                    initialValue={seoText}
                                    onEditorChange={(newText) => {
                                        //   console.log(newText)
                                        //   setSEOText(newText)
                                        setSEOText({ ...seoText, newText })
                                    }}
                                    init={{
                                        height: 450,
                                        menubar: true,
                                        plugins: [
                                            'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                            'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                            'alignleft aligncenter alignright alignjustify | ' +
                                            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>

                        </div>

                        <i className="error">  {errorMsg}{descriptionError}{countError}</i>
                        <div className="button">
                            <button>Update</button>
                        </div>





                    </form>

                    {redirect ? <Redirect to="/brand?page=1" /> : null}
                    {show === true ? <SuccessModal message="Brand updated!" onClick={closeModal} /> : null}
                    {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
                    {showLoader === true ? <LoaderModal /> : null}

                </>

                :
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </div>
            }

        </Layout>
    )
}

export default UpdateBrand
