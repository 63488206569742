import React from "react";
import Layout from "../../layout/layout";
import HomeSeoForm from "./HomeSeoForm";
import { useParams } from "react-router-dom";
import SuccessModal from "../../components/halper/SuccessModal";
import ErrorModal from "../../components/halper/ErrorModal";
import LoaderModal from "../../components/halper/LoaderModal";
import addEditContainer from "./addEditContainer";

const EditHomeSeo = () => {
  const { seoId } = useParams();

  const {
    homeSeo,
    handleChange,
    handleAction,
    closeModal,
    closeErrorModal,
    loader,
    editorRef,
    setSEOText,
    setHomeSeo,
    seoText,
  } = addEditContainer(seoId);

  return (
    <Layout>
      {homeSeo?.success ? (
        <SuccessModal
          message="Data Updated Successfully!"
          onClick={closeModal}
        />
      ) : null}
      {homeSeo?.responseError ? (
        <ErrorModal message={homeSeo?.msg} onClick={closeErrorModal} />
      ) : null}

      <HomeSeoForm
        title="Edit"
        handleChange={handleChange}
        setSEOText={setSEOText}
        setHomeSeo={setHomeSeo}
        homeSeo={homeSeo}
        onClick={handleAction}
        editorRef={editorRef}
        seoText={seoText}
        btnType="Update"
      />

      {loader ? <LoaderModal /> : null}
    </Layout>
  );
};

export default EditHomeSeo;
