import React from 'react'
import Layout from "../../layout/layout"
import AddSubCategory from '../../wrapper/category/AddSubCategory'
const CreateSubCategory = () => {
  return (
    <Layout>
      <AddSubCategory />
    </Layout>
  )
}

export default CreateSubCategory
