import React from 'react';
import { useState, useEffect } from "react";
import Layout from "../../layout/layout"
import axiosauth from '../../axios-config-auth';
import { useHistory } from "react-router-dom";
import left_arrow2 from '../../assets/icons/down_arrow2.png'
// import Loader from 'react-loader-spinner';
import parse from 'html-react-parser';

const ProductVarinatDetails = () => {
  // const [showLoader, setShowLoader] = useState(true);
  // const [dataObj, setDataObj] = useState([])


  const history = useHistory();

  const [base, setbase] = useState()
  // console.log(base)
  const showDate = (data) => {
    var d = new Date(data);
    var date = d.getDate();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
    var year = d.getFullYear();
    var dateStr = date + " " + months[month] + " " + year;
    // var res =
    return dateStr;
  };

  useEffect(() => {
    let id=localStorage.getItem("provariant")
    let obj = {
      "productId": id
    }
    axiosauth.post('/products/load-by-id', obj)
      .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("rp",res)
        if (res.success === true) {
          setbase(res.data?.product)
          // setDataObj(res.data.product) 
          // setShowLoader(false)

        } else {
          // setShowLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, []);


  return (
    <Layout>


      <div>
        <section className="detail-heading">
          <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img onClick={() => history.goBack()} src={left_arrow2} alt="left-arrow" />
            <h2>Product Variant Detail</h2>
          </div>
        </section>
        {/* {showLoader ?
          <> */}
 <section className="detail-body" >
              <div>GSIN </div>
              <div className="titleStyle">
                {base?.GSIN !== undefined && base?.GSIN !== undefined
                  ? (base?.GSIN).toUpperCase()
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Brand Name</div>
              <div className="titleStyle">
                {base?.brand !== undefined && base?.brand.name !== undefined
                  ? base?.brand.name
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Product Catalogue Name</div>
              <div>
                {base?.productCatalogue !== undefined  
                  ? base?.catalogueName
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Product  Name</div>
              <div>
                {base?.productCatalogue !== undefined && base?.productCatalogue.name !== undefined
                  ? base?.productCatalogue.name
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Product  Title</div>
              <div>
                {base?.productCatalogue !== undefined && base?.productCatalogue.title !== undefined
                  ? base?.productCatalogue.title
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Variants</div>
              <div>
                {base?.selectedVariant !== undefined && base?.selectedVariant.variant && base?.selectedVariant.variant.length > 0
                  ? base?.selectedVariant.variant.map((value, index) => {
                    return (
                      <>

                        {value?.parentVariant?.variantName}-{value?.value}
                      </>
                    );
                  })
                  : null}
              </div>
            </section>

            <section className="detail-body" >
              <div>Units</div> 
              <div>
{base?.stock !==null ?base?.stock : 0}
                {/* {base !== undefined
                  ? base?.stock
                  : 0} */}

              </div>
            </section>
            <section className="detail-body" >
              <div>Net quantity</div>
              <div>
                {base !== undefined
                  ? base?.netQuantity
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Material & Care</div>
              <div>
                {base !== undefined
                  ? base?.productCatalogue.materialCare
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Search Keywords</div>
              <div>
                {base !== undefined
                  ? base?.productCatalogue.searchTags
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Product Tags</div>
              <div>
                {base !== undefined
                  ? base?.productCatalogue.productTags
                  : ""}
              </div>
            </section>

            {/* <section className="detail-body" >
              <div>Model Number</div>
              <div>
                {base !== undefined
                  ? base?.modelNo
                  : ""}
              </div>
            </section> */}

            <section className="detail-body" >
              <div>MRP</div>
              <div>
                {base !== undefined
                  ? base?.mrp
                  : ""}
              </div>
            </section>

            <section className="detail-body" >
              <div>Price</div>
              <div>
                {base !== undefined
                  ? base?.price
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>SKU</div>
              <div>
                {base !== undefined
                  ? base?.sku
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>HSN Code</div>
              <div>
                {base !== undefined
                  ? base?.productCatalogue.hsnCode
                  : ""}
              </div>
            </section>
            
            
            <section className="detail-body" >
              <div>COD</div>
              <div>
                {base !== undefined
                  ? base?.cod=== false ? " NO" :"YES"
                  : ""}
              </div>
            </section>
            {/* <section className="detail-body" >
              <div>HSN Code</div>
              <div>
                {base !== undefined
                  ? base.hsnCode
                  : ""}
              </div>
            </section> */}
            <section className="detail-body" >
              <div>Country Of Origin</div>
              <div>
                {base !== undefined
                  ? base?.countryOfOrigin
                  : ""}
              </div>
            </section>
            {/* {base !== undefined ? (<section className="detail-body" >
              <div>Age Group</div>
              <div>
                {(base.ageGroup.minAge === 0 && base.ageGroup.maxAge === 1) ? "Upto 1 Years" : null}
                {(base.ageGroup.minAge === 0 && base.ageGroup.maxAge === 2) ? "Upto 2 Years" : null}
                {(base.ageGroup.minAge === 3 && base.ageGroup.maxAge === 4) ? "3-4 Years" : null}
                {(base.ageGroup.minAge === 5 && base.ageGroup.maxAge === 7) ? "5-7 Years" : null}
                {(base.ageGroup.minAge === 8 && base.ageGroup.maxAge === 11) ? "8-11 Years" : null}
                {(base.ageGroup.minAge === 12 && base.ageGroup.maxAge === 100) ? "12 years above" : null}
              </div>
            </section>) : null} */}
            <section className="detail-body" >
              <div>Manufacturing Date</div>
              <div>
                {base !== undefined
                  ? showDate(base?.datePosted)
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Expiry Date</div>
              {/* {console(expiryDate)} */}
              {/* <div>
                {base !== null
                  ? showDate(base?.expiryDate)
                  : ""}
              </div> */}
            </section>
            <section className="detail-body" >
              <div>Manufacturer Email</div>
              <div>
                {base !== undefined
                  ? base?.productCatalogue.manufacturerEmail
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Manufacturer Mobile</div>
              <div>
                {base !== undefined
                  ? base?.productCatalogue.manufacturerMobile
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Manufacturer Address</div>
              <div>
                {base !== undefined
                  ? base?.productCatalogue.manufacturerAddress
                  : ""}
              </div>
            </section>
            {/* <section className="detail-body" >
              <div>Manufacturer/Importer Address </div>
              <div>
                {base !== undefined
                  ? base.manufacturerAddress
                  : ""}
              </div>
            </section> */}
            {/* <section className="detail-body" >
              <div>Manufacturer Email</div>
              <div>
                {base !== undefined
                  ? base.manufacturerEmail
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Manufacturer Contact Number</div>
              <div>
                {base !== undefined
                  ? base.manufacturerPhone
                  : ""}
              </div>
            </section> */}
            <section className="detail-body" >
              <div>Is Returnable</div>
              <div>
                {base !== undefined
                  ? base?.isReturnable === true ? "Yes" : "No"
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Is Exchangable</div>
              <div>
                {base !== undefined
                  ? base?.isExchangable === true ? "Yes" : "No"
                  : ""}
              </div>
            </section>
            
            
            <section className="detail-body" >
              <div>Return Days</div>
              <div>
                {base !== undefined
                  ? base?.returnDays
                  : ""}
              </div>
            </section>
            {/* <section className="detail-body">
              <div>
                Youtube URL
              </div>
              <div>
                {base.selectedVariant !== undefined && base.selectedVariant.imageUrls !== undefined && base.selectedVariant.imageUrls.length > 0
                  ?
                  <div>
                    {base.selectedVariant.youtubeUrl}
                  </div>
                  :
                  <>
                    {base.productCatalogue !== undefined && base.productCatalogue.imageUrls !== undefined
                      ?
                      <>
                        {base.productCatalogue.youtubeUrl}
                      </>

                      : ""}
                  </>
                }


              </div>
            </section> */}
            <section className="detail-body" >
              <div>Images</div>
              <div>



                { base!== undefined && base?.imageUrls.length > 0
                  ?
                  base?.imageUrls.map((imgurl, index) => {
                    return (
                      <img key={index} src={imgurl.url} alt="" style={{ height: '150px', width: '120px', padding: '5px' }} />

                    )
                  })

                  :
                  <>
                    {base?.productCatalogue !== undefined && base?.productCatalogue.imageUrls !== undefined
                      ?
                      base?.productCatalogue.imageUrls.map((imgurl, index) => {
                        return (
                          <img key={index} src={imgurl.url} alt="" style={{ height: '150px', width: '120px', padding: '5px' }} />

                        )
                      })

                      : ""}
                  </>
                }





              </div>
            </section>
            
            {/* <section className="detail-body" >
              <div>Specification</div>
              <div>
                {base !== undefined
                  ? base?.productCatalogue?.specification.map((val)=>{
                    return (
                      val.value,
                      val.name
                    )
                  })
                  : ""}
              </div>
            </section> */}
            
            <section className="detail-body" >
              <div>Product Specification</div>
              <div>
                {base !== undefined
                  ?parse( base?.productCatalogue.shortDescription)
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Product Description :</div>
              <div>
                {base !== undefined
                  ? parse(base?.productCatalogue.longDescription)
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>Size Chart :</div>
              <div>
                {base !== undefined
                  ? parse(base?.productCatalogue.sizeChart)
                  : ""}
              </div>
            </section>
            <section className="detail-body" >
              <div>How to Measure :</div>
              <div>
                {base !== undefined
                  ? parse(base?.productCatalogue.howTomeasure)
                  : ""}
              </div>
            </section>

            {/* <div className="button">
            
            <button  onClick={() => history.goBack()}>Back</button> &nbsp;
        
            <Link to={`/edit-products-variant`}>
                <button>Edit</button>
            </Link>
        </div> */}
            <br />
          {/* </> :
          <div className="loader-style">
            <Loader
              type="ThreeDots"
              color="rgba(32, 159, 132, 1)"
              height={100}
              width={100}
              timeout={3000} //3 secs
            />
          </div>

        } */}
      </div>
      <br />
      <br />

    </Layout>
  )
}

export default ProductVarinatDetails
