import React from 'react';
import Layout from "../../layout/layout";
import MiddleBanner from '../../wrapper/banner/MiddleBanner';

const ManageSlideBanner = () => {
    return (
        <Layout>
            <MiddleBanner/>
        </Layout>
    )
}

export default ManageSlideBanner
