import {
    CHANGE_PASSWORD,
    USER_LOADING,
    USER_FAIL,
    PASSWORD_STATUS,
} from '../constants/types';

const initialState = {
    loading: false,
    data: [],
    error: ''
}

export function changePassword(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PASSWORD:
            return {
                ...state,
                data: action.payload,
                loading: false
            }


        case USER_LOADING:
            return {
                ...state,
                data: [],
                loading: true,
            }

        case PASSWORD_STATUS:
            return {
                ...state,
                data: [],
                loading: true,
            }

        case USER_FAIL:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
}