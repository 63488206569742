import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../layout/layout';
// import './bootstrap/css/bootstrap.min.css';
// import './style/bootstrap.min.css';
import './order copy.scss';
import ItemDetails from './ItemDetails';
import GeneralDetails from './GeneralDetails';
import BillingAddress from './BillingAddress';
import ShippingAddress from './ShippingAddress';
// import TopBar from '../others/TopBar';
import axiosauth from '../../axios-config-auth';

const OrderDetails = () => {

    const history = useHistory();
    const [orders, setorders] = useState([])
    const [billaddress, setbilladdress] = useState(null)
    const [shipaddress, setshipaddress] = useState(null)
    const [createdBy , setCreatedBy] = useState()
 

    useEffect(() => {

        let obj = {
            "orderId": window.location.href.split('order-details/')[1]
        }
        axiosauth.post('/order/load-order-by-id', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data) 
                if (res.success === true) {
                    setorders(res.data.data)
                    setCreatedBy(res.data.data?.createdBy)
                    
                    for (var i = 0; i < res.data?.data?.address?.length; i++) {
                        if (res.data?.data?.address[i]?.orderAddressType === "billing") { 
                            setbilladdress(res?.data?.data?.address[i])
                        }
                        if (res.data?.data?.address[i].orderAddressType === "shipping") { 
                            setshipaddress(res?.data?.data?.address[i])
                        }
                    }

                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);


    const loadProductById = () => {
        let obj = {
            "orderId": window.location.href.split('order-details/')[1]
        }
        axiosauth.post('/order/load-order-by-id', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data) 
                if (res.success === true) {
                    setorders(res.data.data) 
                    for (var i = 0; i < res.data.address.length; i++) {
                        if (res.data.address[i].orderAddressType === "billing") {
                            setbilladdress(res.data.address[i])
                        }
                        if (res.data.address[i].orderAddressType === "shipping") {
                            setshipaddress(res.data.address[i])
                        }
                    }

                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    return (
        <>
            <Layout >
                <div class="order-details bg-white mt-4 col-12">
                    <div className="row justify-content-between px-4 pt-4">
                        <div className="col">
                            <span className="float-left text-dark h5">
                                <i className="fa fa-chevron-left h6 mr-2" style={{ color: '#209F84' }} onClick={() => history.goBack()}></i>   Order {window.location.href.split('order-details/')[1]}</span>
                        </div>
                        {/* <div className='col-auto'>
                            <div className="row ">
                                <div className='col'>
                                    <div className='row'>
                                        <div className='col'>
                                    <p>Order Status - <span>
                                    {orders.isCancelled === true ?
                                        <span className="isCancelled">Cancelled</span>
                                        :
                                        (orders.isDelivered === true ?
                                            <span className="isDelivered">Delivered</span>
                                            :
                                            (orders.isShipped === true ?
                                                <span className="isShipped">Shipped</span>
                                                :
                                                <span className="isProgress">In Progress</span>
                                            )
                                        )
                                    }</span></p>
                                    </div>
                                     
                                    </div>
                                </div> 
                            </div>

                        </div>  */}
                        <hr /> 

                    </div>


                    <div className="row px-4 pb-1">
                        <div className="col-md-12">
                            {orders.length !== 0 ? (<GeneralDetails orderId={orders?.orderId}
                                transactionId={orders?.transactionId}
                                createdBy={orders?.address[0]}
                                payment={orders?.paymentType}
                                user={createdBy}
                                orderDate={orders?.createdOn}
                                orders={orders}
                            />) : null}
                        </div>

                        <div className="col-md-6">
                            <div className="heading">Billing Address</div>
                            {billaddress !== null ? (<BillingAddress address={billaddress} />) : null}
                        </div>

                        <div className="col-md-6">
                            <div className="heading">Shipping Address</div>
                            {shipaddress !== null ? (<ShippingAddress address={shipaddress} />) : null}
                        </div>
                        <div className="col-md-12">
                            <div className="heading">Order Details</div>

                            {orders?.products && orders?.products?.length > 0 ?
                                (<ItemDetails
                                    loadProductById={loadProductById}
                                    collection={orders?.products}
                                    total={orders?.total}
                                    payment={orders?.paymentType}
                                    orderId={window.location.href?.split('order-details/')[1]}
                                    shipaddress={shipaddress}
                                    billaddress ={billaddress}
                                    createdBy={createdBy}

                                />) : null}
                        </div>

                    </div>

                </div >
            </Layout>
        </>
    )
}

export default OrderDetails;
