import React from "react";

const ErrorModal = ({ onClick, message }) => {
  return (
    <div className="modal-bg">
      <div className="modal-success">
        <h5 className="text-danger">{message}</h5>
        <button onClick={onClick}>OK</button>
      </div>
    </div>
  );
};

export default ErrorModal;
