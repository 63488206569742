import React from 'react';
import Layout from '../../layout/layout'; 
import AddStyleNameForm from './AddStyleNameForm';

const AddStyle = () => {

  return (
    <Layout> 
        <AddStyleNameForm/>
    </Layout>
  )
}

export default AddStyle