import React from 'react';
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import down from '../../assets/icons/down_arrow.png';
import {ReactComponent as UserPic} from '../../assets/icons/userPic.svg';
import { logoutUser } from "../../redux/actions/login";
import { useDispatch } from "react-redux";
import axiosauth from '../../axios-config-auth';

const Sidebar = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState([]);
  const [uId, setUId] = useState("");
  const [showDropdown1, setShowDropdown1] = useState(false)
  const [showDropdown2, setShowDropdown2] = useState(false)
  const [showDropdown3, setShowDropdown3] = useState(false)
  const [showDropdown4, setShowDropdown4] = useState(false)
  const [showDropdown5, setShowDropdown5] = useState(false)
  const [showDropdown6, setShowDropdown6] = useState(false)
  const [showDropdown7, setShowDropdown7] = useState(false)
  const [showDropdown8, setShowDropdown8] = useState(false)
  const [showDropdown9, setShowDropdown9] = useState(false)
  const [showDropdown10, setShowDropdown10] = useState(false)
  // const [sho]



  useEffect(() => {
    if (localStorage.getItem("show1") === "true") {
      setShowDropdown1(true)
    }
    if (localStorage.getItem("show2") === "true") {
      setShowDropdown2(true)
    }

    if (localStorage.getItem("show3") === "true") {
      setShowDropdown3(true)
    }
    if (localStorage.getItem("show4") === "true") {
      setShowDropdown4(true)
    }
    if (localStorage.getItem("show5") === "true") {
      setShowDropdown5(true)
    }
    if (localStorage.getItem("show6") === "true") {
      setShowDropdown6(true)
    }
    if (localStorage.getItem("show7") === "true") {
      setShowDropdown7(true)
    }
    if (localStorage.getItem("show8") === "true") {
      setShowDropdown8(true)
    }
    if (localStorage.getItem("show9") === "true") {
      setShowDropdown9(true)
    }
    if (localStorage.getItem("show10") === "true") {
      setShowDropdown10(true)
    }

    // Get user Details

    const userData = () => {
      axiosauth.get('/profile/load-profile')
        .then(async (response) => {

          let res = JSON.parse(response.data)
          if (res.message === "success") {
            setUId(res.data.user.userId)
            // console.log("response====>>" , res.data.user)
            setUserName(res.data.user?.firstName+" "+res.data.user?.lastName)
          } else {

          }
        })
        .catch((error) => {
          console.log(error);
        })
    }

    userData();

  }, []);

  const categoryDropdown = () => {
    if (showDropdown1 === false) {
      setShowDropdown1(true);
      setShowDropdown2(false)
      setShowDropdown3(false)
      setShowDropdown4(false)
      setShowDropdown5(false)
      setShowDropdown5(false)
      setShowDropdown7(true)
      setShowDropdown8(false)
      setShowDropdown9(false)
      setShowDropdown10(false)



      localStorage.setItem("show1", true)
      localStorage.setItem("show2", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show4", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show6", false)
      localStorage.setItem("show8", false)
      localStorage.setItem("show7", true)
      localStorage.setItem("show9", false)
      localStorage.setItem("show10", false)

    }
    else {
      setShowDropdown1(false)
      localStorage.setItem("show1", false)
    }
  }

  const couponDropdown = () => {
    if (showDropdown2 === false) {
      setShowDropdown2(true);
      setShowDropdown1(false)
      setShowDropdown3(false)
      setShowDropdown4(false)
      setShowDropdown5(false)
      setShowDropdown6(false)
      setShowDropdown7(false)
      setShowDropdown9(false)
      setShowDropdown10(false)

      localStorage.setItem("show2", true)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show4", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show6", false)
      localStorage.setItem("show7", false)
      localStorage.setItem("show9", false)
      localStorage.setItem("show10", false)

    }
    else {
      setShowDropdown2(false)
      localStorage.setItem("show2", false)
    }
  }

  const taxonomyDropdown = () => {
    if (showDropdown8 === false) {
      setShowDropdown2(false);
      setShowDropdown1(false)
      setShowDropdown3(false)
      setShowDropdown4(false)
      setShowDropdown5(false)
      setShowDropdown6(false)
      setShowDropdown7(false)
      setShowDropdown8(true)
      setShowDropdown10(false)

      localStorage.setItem("show2", false)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show4", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show6", false)
      localStorage.setItem("show7", false)
      localStorage.setItem("show8", true)
      localStorage.setItem("show10", false)

    }
    else {
      setShowDropdown8(false)
      localStorage.setItem("show8", false)
    }
  }


  const StyleDropdown = () => {
    if (showDropdown3 === false) {
      setShowDropdown2(false);
      setShowDropdown1(false)
      setShowDropdown3(true)
      setShowDropdown4(false)
      setShowDropdown5(false)
      setShowDropdown6(false)
      setShowDropdown7(true)
      setShowDropdown8(false)
      setShowDropdown9(false)
      setShowDropdown10(false)

      localStorage.setItem("show2", false)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", true)
      localStorage.setItem("show4", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show6", false)
      localStorage.setItem("show7", true)
      localStorage.setItem("show7", false)
      localStorage.setItem("show9", false)
      localStorage.setItem("show10", false)
    }
    else {
      setShowDropdown3(false)
      localStorage.setItem("show3", false)
    }
  }

  // const CollectionDropdown = () => {
  //   if (showDropdown3 === false) {
  //     setShowDropdown3(true);
  //     setShowDropdown1(false)
  //     setShowDropdown2(false)
  //     setShowDropdown4(false)
  //     setShowDropdown5(false)

  //     localStorage.setItem("show3", true)
  //     localStorage.setItem("show2", false)
  //     localStorage.setItem("show1", false)
  //     localStorage.setItem("show4", false)
  //     localStorage.setItem("show5", false)
  //   }
  //   else {
  //     setShowDropdown3(false)
  //     localStorage.setItem("show3", false)
  //   }
  // }

  const VariantDropdown = () => {
    if (showDropdown4 === false) {
      setShowDropdown4(true);
      setShowDropdown1(false)
      setShowDropdown2(false)
      // setShowDropdown3(false)
      setShowDropdown5(false)
      setShowDropdown6(false)
      setShowDropdown7(true)
      setShowDropdown8(false)
      setShowDropdown9(false)
      setShowDropdown10(false)

      localStorage.setItem("show4", true)
      localStorage.setItem("show2", false)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show6", false)
      localStorage.setItem("show7", true)
      localStorage.setItem("show8", false)
      localStorage.setItem("show9", false)
      localStorage.setItem("show10", false)

    }
    else {
      setShowDropdown4(false)
      localStorage.setItem("show4", false)
    }
  }

  const GajariCoin = () => {
    if (showDropdown9 === false) {
      setShowDropdown6(false)
      setShowDropdown5(false);
      setShowDropdown4(false);
      setShowDropdown1(false)
      setShowDropdown2(false)
      setShowDropdown7(false)
      setShowDropdown8(false)
      setShowDropdown9(true)
      setShowDropdown10(false)


      // setShowDropdown3(false)
      localStorage.setItem("show9", true)
      localStorage.setItem("show6", false)
      localStorage.setItem("show2", false)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show4", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show7", false)
      localStorage.setItem("show8", false)
      localStorage.setItem("show10", false)


    }
    else {
      setShowDropdown9(false)
      localStorage.setItem("show9", false)
    }
  }


  const BrandDropdown = () => {
    if (showDropdown5 === false) {
      setShowDropdown5(true)
      setShowDropdown4(false);
      setShowDropdown1(false)
      setShowDropdown2(false)
      setShowDropdown6(false)
      setShowDropdown7(true)
      setShowDropdown8(false)
      setShowDropdown9(false)
      setShowDropdown10(false)


      localStorage.setItem("show5", true)
      localStorage.setItem("show2", false)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show4", false)
      localStorage.setItem("show6", false)
      localStorage.setItem("show7", true)
      localStorage.setItem("show8", false)
      localStorage.setItem("show9", false)
      localStorage.setItem("show10", false)


    }
    else {
      setShowDropdown5(false)
      localStorage.setItem("show5", false)
    }
  }

  const BannerDropdown = () => {
    if (showDropdown6 === false) {
      setShowDropdown6(true)
      setShowDropdown5(false);
      setShowDropdown4(false);
      setShowDropdown1(false)
      setShowDropdown2(false)
      setShowDropdown7(false)
      setShowDropdown8(false)
      setShowDropdown9(false)
      setShowDropdown10(false)


      // setShowDropdown3(false)

      localStorage.setItem("show6", true)
      localStorage.setItem("show2", false)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show4", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show7", false)
      localStorage.setItem("show8", false)
      localStorage.setItem("show9", false)
      localStorage.setItem("show10", false)

    }
    else {
      setShowDropdown6(false)
      localStorage.setItem("show6", false)
    }
  }

  const ProductDropdown = () => {
    if (showDropdown7 === false) {
      setShowDropdown7(true)
      setShowDropdown5(false);
      setShowDropdown4(false);
      setShowDropdown1(false)
      setShowDropdown2(false)
      setShowDropdown6(false)
      setShowDropdown8(false)
      setShowDropdown9(false)
      setShowDropdown10(false)

      localStorage.setItem("show6", false)
      localStorage.setItem("show2", false)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show4", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show7", true)
      localStorage.setItem("show8", false)
      localStorage.setItem("show9", false)
      localStorage.setItem("show10", false)

    }
    else {
      setShowDropdown7(false)
      localStorage.setItem("show7", false)
    }
  }

  const ProductSettingDropdown = () => {
    if (showDropdown10 === false) {
      setShowDropdown7(false)
      setShowDropdown5(false);
      setShowDropdown4(false);
      setShowDropdown1(false)
      setShowDropdown2(false)
      setShowDropdown6(false)
      setShowDropdown8(false)
      setShowDropdown9(false)
      setShowDropdown10(true)

      localStorage.setItem("show6", false)
      localStorage.setItem("show2", false)
      localStorage.setItem("show1", false)
      localStorage.setItem("show3", false)
      localStorage.setItem("show4", false)
      localStorage.setItem("show5", false)
      localStorage.setItem("show7", false)
      localStorage.setItem("show8", false)
      localStorage.setItem("show9", false)
      localStorage.setItem("show10", true)

    }
    else {
      setShowDropdown10(false)
      localStorage.setItem("show10", false)
    }
  }

  const sidebarToggle = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector(".sidebar-wrap");
    // toggle navbar
    nav.classList.toggle('sidebar-active');
    burger.classList.toggle('toggle');
  }

  const DropClose = () => {
    localStorage.removeItem("show1")
    localStorage.removeItem("show2")
    localStorage.removeItem("show3")
    localStorage.removeItem("show4")
    localStorage.removeItem("show5")
    localStorage.removeItem("show6")
    localStorage.removeItem("show9")
    localStorage.removeItem("show8")

  }



  return (
    <div>
      <div className="burger" onClick={sidebarToggle}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <div className="sidebar-wrap">
        <section className="sidebar-user">
          <section><UserPic /></section>
          {/* <section><img src={userPic} alt="user-icon" /></section> */}
          <section className="capital" style={{ marginTop: "20px", marginLeft: "5px" }}>{userName}<br /> Admin ID : {uId.slice(0, 8)}</section>
        </section>
        <section className="sidebar-links">
          <NavLink to="/dashboard" activeClassName="active-link">
            <div onClick={DropClose}>
              Dashboard
            </div>
          </NavLink>

          <NavLink to="/home-page-seo" activeClassName="active-link">
            <div onClick={DropClose}>HOME PAGE SEO</div>
          </NavLink>

          {/* ============================================================================================================== */}
          <div className={showDropdown8 ? "dropdown-active" : "dropdown-btn"} onClick={taxonomyDropdown}>
            Customer Management
            <section><img src={down} alt="down-arrow" /></section>
          </div>

          {showDropdown8 && (
            <section className="dropdown-body">
              <NavLink to="/users" activeClassName="active-link">
                <div>
                  Active User
                </div>
              </NavLink>
              <section>
                <NavLink to="/ban-users" activeClassName="active-link">
                  <div>
                    Ban User
                  </div>
                </NavLink>
              </section>
            </section>
          )}
          {/* ========================================================================================================= */}

          <div className={showDropdown7 ? "dropdown-active" : "dropdown-btn"} onClick={ProductDropdown}>
            product
            <section><img src={down} alt="down-arrow" /></section>
          </div>
          {showDropdown7 ? (



            <div className="dropdown-body">

              <div className={showDropdown5 ? "dropdown-active" : "dropdown-btn"} onClick={BrandDropdown}>
                Brand Management
                <section><img src={down} alt="down-arrow" /></section>
              </div>
              {showDropdown5 ? (
                <section className="dropdown-body">
                  <NavLink to="/brand?page=1" activeClassName="active-link smalllink">
                    <div>
                      Manage Brands
                    </div>
                  </NavLink>
                </section>
              ) : null}


              <div className={showDropdown1 ? "dropdown-active" : "dropdown-btn"} onClick={categoryDropdown}>
                Manage Cat / Sub / Sub_Sub Cat
                <section><img src={down} alt="down-arrow" /></section>
              </div>
              {showDropdown1 ? (
                <section className="dropdown-body">
                  <NavLink to="/category-manage?page=1" activeClassName="active-link smalllink">
                    <div>
                      Manage
                    </div>
                  </NavLink>

                </section>
              ) : null}
              <div className={showDropdown3 ? "dropdown-active" : "dropdown-btn"} onClick={StyleDropdown}>
                product features
                <section><img src={down} alt="down-arrow" /></section>
              </div>
              {showDropdown3 && (
                <section className="dropdown-body">
                  <NavLink to="/style-manage?page=1" activeClassName="active-link">
                    <div>
                      manage style
                    </div>
                  </NavLink>
                  <NavLink to="/ideals-manage?page=1" activeClassName="active-link">
                    <div>
                      manage Ideals
                    </div>
                  </NavLink>
                  <NavLink to="/trends-manage?page=1" activeClassName="active-link">
                    <div>
                      manage Trends
                    </div>
                  </NavLink>
                  <NavLink to="/trader-type-manage?page=1" activeClassName="active-link">
                    <div>
                      manage trader type
                    </div>
                  </NavLink>
                  <NavLink to="/season-manage?page=1" activeClassName="active-link">
                    <div>
                      manage season
                    </div>
                  </NavLink>
                  <NavLink to="/condition-manage?page=1" activeClassName="active-link">
                    <div>
                      manage condition
                    </div>
                  </NavLink>

                </section>
              )}

              <div className={showDropdown4 ? "dropdown-active" : "dropdown-btn"} onClick={VariantDropdown}>
                Variant Management
                <section><img src={down} alt="down-arrow" /></section>
              </div>
              {showDropdown4 && (
                <section className="dropdown-body">
                  <NavLink to="/variant-manage" activeClassName="active-link">
                    <div>
                      Manage
                    </div>
                  </NavLink>

                </section>
              )}

              <NavLink to="/suspended-products?page=1" activeClassName="active-link">
                <div onClick={DropClose}>
                  Suspended product
                </div>
              </NavLink>

              <NavLink to="/products?page=1" activeClassName="active-link">
                <div onClick={DropClose}>
                  product inventory
                </div>
              </NavLink>

            </div>
          ) : null}


          <NavLink to="/option-management?page=1" activeClassName="active-link">
            <div onClick={DropClose}>
            HOME PAGE SECTION 
            </div>
          </NavLink>

          <NavLink to="/collection" activeClassName="active-link">
            <div onClick={DropClose}>
              Collections
            </div>
          </NavLink>

          <NavLink to="/deal-of-the-management" activeClassName="active-link">
            <div onClick={DropClose}>
              Deal Of The Day
            </div>
          </NavLink>

          <NavLink to="/orders?page=1" activeClassName="active-link">
            <div onClick={DropClose}>
              Manage Orders
            </div>
          </NavLink>

          <NavLink to="/orders-return?page=1" activeClassName="active-link">
            <div onClick={DropClose}>
              Return Orders
            </div>
          </NavLink>

          <div className={showDropdown2 ? "dropdown-active" : "dropdown-btn"} onClick={couponDropdown}>
            Coupon Management
            <section><img src={down} alt="down-arrow" /></section>
          </div>

          {showDropdown2 && (
            <section className="dropdown-body">
              <NavLink to="/coupon-manage" activeClassName="active-link">
                <div>
                  Manage (coupon)
                </div>
              </NavLink>
              <section>
                <NavLink to="/create-coupon" activeClassName="active-link">
                  <div>
                    Add Coupon
                  </div>
                </NavLink>
              </section>
            </section>
          )}
          {/* .... */}

          <NavLink to="/cart-manage?page=1" activeClassName="active-link">
            <div onClick={DropClose}>
              Abandoned Cart
            </div>
          </NavLink>


          <div className={showDropdown10 ? "dropdown-active" : "dropdown-btn"} onClick={ProductSettingDropdown}>
            Product Setting
            <section><img src={down} alt="down-arrow" /></section>
          </div>
          {showDropdown10 && (
            <section className="dropdown-body">
              {/* <NavLink to="/add-product-setting" activeClassName="active-link">
                <div>
                  Display secondary price
                </div>
              </NavLink> */}
              <NavLink to="/load-offer-setting" activeClassName="active-link">
                <div>Offer setting</div>
              </NavLink>
              {/* <NavLink to="/add-cod-setting" activeClassName="active-link">
                <div>COD Setting</div>
              </NavLink> */}

            </section>
          )}


          <div className={showDropdown6 ? "dropdown-active" : "dropdown-btn"} onClick={BannerDropdown}>
            Banner Management
            <section><img src={down} alt="down-arrow" /></section>
          </div>
          {showDropdown6 && (
            <section className="dropdown-body">
              <NavLink to="/top-banner" activeClassName="active-link">
                <div>
                  Top banner
                </div>
              </NavLink>
              <NavLink to="/middle-banner" activeClassName="active-link">
                <div>Middle banner</div>
              </NavLink>

              <NavLink to="/footer-banner" activeClassName="active-link">
                <div>
                  footer banner
                </div>
              </NavLink>

              <NavLink to="/categories-banner" activeClassName="active-link">
                <div>
                  Categories banner
                </div>
              </NavLink>
              <NavLink to="/login-banner" activeClassName="active-link">
                <div>
                  Login banner
                </div>
              </NavLink>
              {/* <NavLink to="/banner" activeClassName="active-link">
                  <div>
                    old banner
                  </div>
              </NavLink> */}
            </section>
          )}

          {/* <NavLink to="/image-management?page=1" activeClassName="active-link">
            <div onClick={DropClose}>
              Image Management
            </div>
          </NavLink> */}

          <NavLink to="/taxonomy?page=1" activeClassName="active-link">
            <div onClick={DropClose}>TAX CODE SETTINGS</div>
          </NavLink>

          <NavLink to="/manage-coins?page=1" activeClassName="active-link">
            <div onClick={DropClose}>Gajari Coins</div>
          </NavLink>

          <NavLink to="/shipping-charges" activeClassName="active-link">
            <div onClick={DropClose}>Shipping Charges</div>
          </NavLink>

          <NavLink to="/explore" activeClassName="active-link">
            <div onClick={DropClose}>Explore</div>
          </NavLink>
          <NavLink to="/offer" activeClassName="active-link">
            <div onClick={DropClose}>Sticky Offer</div>
          </NavLink>


          <NavLink to="/cms" activeClassName="active-link">
            <div onClick={DropClose}>CMS Pages</div>
          </NavLink>
          <NavLink to="/setting" activeClassName="active-link">
            <div onClick={DropClose}>Profile Settings</div>
          </NavLink>
          <NavLink to="/logout"
            onClick={() => {
              dispatch(logoutUser());
            }}
            activeClassName="active-link">
            <div>Logout</div>
          </NavLink>
        </section>
      </div>
    </div>
  )
}

export default Sidebar