import React from 'react';

const SuccessModal = ({ onClick, message }) => {

  return (
    <div className="modal-bg">
      <div className="modal-success">
        <h5>{message}</h5>
        <button onClick={onClick}>OK</button>
      </div>
    </div>
  )
}

export default SuccessModal
