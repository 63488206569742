import React, { useState, useEffect } from 'react';
import SearchBox from '../../components/halper/SearchBox';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCoupon, updateStatusCoupon, fetchCouponPage, fetchCouponCount } from '../../redux/actions/couponAction';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";
import {useHistory } from 'react-router-dom';
import NoData from '../../components/halper/NoData';
import editIcon from '../../assets/icons/edit.png';
import deleteIcon from '../../assets/icons/delete.png';
import axiosauth from '../../axios-config-auth';

const CouponList = () => {
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const coupons = useSelector(state => state.couponData);
  const count = useSelector(state => state.couponCount.data.total);
//  console.log(coupons)
   // for pagination
   const history = useHistory();
   const pageCount = new URLSearchParams(window.location.search).get('page');
// console.log(pageCount)

  // const handlePageChange = (pageNumber) => {
  //   history.push(`/coupon-manage?pageCount=${pageNumber}`)
  //   setActivePage(pageNumber);
  //   dispatch(fetchCouponCount());
  //   dispatch(fetchCouponPage(pageNumber))
  // }



  useEffect(() => {
  //   if(pageCount===null){
  //     history.push(`/coupon-manage?pageCount=1`)
  // }
  //   dispatch(fetchCouponPage(pageCount));
  //   dispatch(fetchCouponCount());


    if(activePage===1)
  {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', (pageCount ? pageCount : 1));
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  }

  if(pageCount !== null){
    dispatch(fetchCouponPage(pageCount));
    dispatch(fetchCouponCount());
  }else{
    dispatch(fetchCouponPage(1))
    dispatch(fetchCouponCount());
  }

  }, [dispatch,pageCount,history,activePage]);


   /**
 * TODO: Pagination
 *@version 1.0.0
 * @author [Unknown]
 */

  // Handle Pagination

  const [ locationKeys, setLocationKeys ] = useState([]);

  const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  // console.log(query)//"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}
const deleteBrand = (voucherId) => {
  let obj = {
      "voucherId":voucherId,
  }
  confirmAlert({
      // title: 'Change Status',
      message: 'Are you sure you want to delete this coupon ?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                  axiosauth.post('/vouchers/remove-voucher', obj)
                      .then(async (response) => {

                          let res = JSON.parse(response.data)
                          if (res.message === "Deleted Successfully") {
                            dispatch(fetchCouponPage(pageCount));
                            dispatch(fetchCouponCount());


                              // setEditShow(false);
                              // getCollections("Collection Deleted");
                          } else {
                              // getBrands()
                              // setEditShow(false);
                          }
                      })
                      .catch((error) => {
                          console.log(error);
                      })
              }
          },
          {
              label: 'No',
              onClick: () => console.log("Hello")
          }
      ]
  });

}
  useEffect(() => {
  return history.listen(location => {
    if (history.action === 'PUSH') {
      setLocationKeys([ location.key ])
    }
    // console.log("history.action",history.action)

    if (history.action === 'POP') {
      if (locationKeys[1] === location.key) {
        setLocationKeys(([ _, ...keys ]) => keys)
              //  console.log("forward")
          // console.log("js",getQueryVariable('page'))
          handlePageChange1( getQueryVariable('page'))
        // Handle forward event

      } else {
        setLocationKeys((keys) => [ location.key, ...keys ])
        // console.log("backward",location.key)
        // console.log("js",getQueryVariable('page'))
        handlePageChange1( getQueryVariable('page'))

      }
    }
  })
}, [ locationKeys,history])

const handlePageChange1 = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handlePageChange5 = (pageNumber) => {
    // console.log("dd",pageNumber)
    dispatch(fetchCouponPage(pageNumber))
    dispatch(fetchCouponCount());
    setActivePage(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };



  return (
    <div>
      <SearchBox
        placeholder="Search Coupon by Coupon Code"
        onChange={(e) =>    {if(e.target.value.length>1)
          dispatch(fetchCoupon(e.target.value))
        else
        {
          dispatch(fetchCouponPage(pageCount));

          dispatch(fetchCouponCount());

        }
      }}
      />
      <div className="table-wrap overflow-auto table-responsive card py-2 " style={{height:"500px"  }}>
        <table>
         {  coupons.data && coupons.data.length > 0 ?(<thead>
            <tr>
            <th>S.No.</th>
              <th style={{textAlign:"left"}} className="text-nowrap px-2">Coupon Code</th>
              <th className="text-nowrap px-2">Coupon Type</th>
              <th className="text-nowrap px-2">Coupon Amount</th>
              <th className="text-nowrap px-2">Expiry Date</th>
              <th className="text-nowrap px-2">Status</th>
              <th className="text-nowrap px-2">Actions</th>
            </tr>
          </thead>):null} 
          <tbody>


            {coupons.loading ?
              <div className="loader-style">
                <Loader
                  type="ThreeDots"
                  color="rgba(32, 159, 132, 1)"
                  height={100}
                  width={100}
                  timeout={3000} //3 secs

                />
              </div> :
              <>
                {
                  coupons.data && coupons.data.length > 0 ?
                    <>
                      {coupons.data.map((coupon, index) => {
                        const statusChange = (e) => {
                          let obj = {
                            "voucherId": coupon.voucherId,
                            "active": e.target.value
                          } 
                          confirmAlert({
                            // title: 'Change Status',
                            message: 'Are you sure to do this.',
                            buttons: [
                              {
                                label: 'Yes',
                                onClick: () => { 
                                  dispatch(updateStatusCoupon(obj,pageCount));
                                }
                              },
                              {
                                label: 'No',
                                onClick: () => {
                                  console.log("not detele")
                                }
                              }
                            ]
                          });
                        }

                        var date = new Date(coupon.expiryDate).toLocaleDateString()
                        return (
                          <tr key={index}>
                          <td>{((pageCount?pageCount:1)-1)*10 + (index+1)}</td>
                            <td className="titleCapStyle brandColor" style={{textAlign:"left"}}>{coupon.voucherTitle}</td>
                            <td className="text-nowrap px-2">{coupon.voucherType}</td>
                            <td className="text-nowrap px-2">{coupon.discount}</td>
                            <td className="text-nowrap px-2">{date}</td>
                            <td className="text-nowrap px-2">
                              <select
                                name="status"
                                value={coupon.active}
                                onChange={statusChange}
                              >
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                              </select>
                            </td>
                            <td style={{ color: '#DC1919' }}>
                              <Link to={`/update-coupon/${coupon.voucherId}`}>
                              <img src={editIcon} alt="edit-button" className="edit-icon" />
                              </Link>
                              <img onClick={() => deleteBrand(coupon.voucherId)} src={deleteIcon} alt="delete" className="delete-icon" />

                            </td>
                          </tr>
                        )
                      }
                      )}
                    </>
                    : (<NoData message="Coupons Not Found" />)
                }
              </>
            }

          </tbody>
        </table>
      </div>
      <div className="pagination-custom">
          <Pagination
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={count}
              pageRangeDisplayed={5}
              hideNavigation
              onChange={handlePageChange5}
          />
      </div>
    </div>
  )
}

export default CouponList