import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory } from "react-router-dom";

const HomeSeoForm = ({
  title,
  handleChange,
  setSEOText,
  setHomeSeo,
  homeSeo,
  onClick,
  editorRef,
  btnType,
  seoText,
}) => {
  const history = useHistory();
  return (
    <>
      <div
        className="left_arrow2 back-arrow my-5 mx-3"
        style={{
          display: "flex",
          alignItems: "baseline",
          fontFamily: "inherit",
        }}
      >
        <img
          src={left_arrow2}
          onClick={() => history.goBack()}
          alt="left-arrow"
        />{" "}
        &nbsp;
        <h2 className="mx-3">{title} home page SEO</h2>
      </div>
      <div className="form-body">
        <div div className="single-field">
          <p>Meta Title</p>
          <input
            type="text"
            placeholder="Title"
            name="title"
            onChange={handleChange}
            value={homeSeo?.title}
          />
        </div>
        <div div className="single-field">
          <p>Meta Description</p>
          <input
            type="text"
            placeholder="Description"
            name="description"
            onChange={handleChange}
            value={homeSeo?.description}
          />
        </div>
        <div className="mt-3">
          <p className="label-form">In-Footer SEO Text </p>
          <Editor
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            initialValue={seoText || ""}
            onEditorChange={(newText) => {
              //   setSEOText(newText);
              setSEOText({ ...seoText, newText });
              setHomeSeo({
                ...homeSeo,
                error: "",
              });
            }}
            init={{
              height: 450,
              menubar: true,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | casechange blocks | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </div>

        <div className="button mt-5 mb-5">
          {homeSeo?.error ? (
            <span className="text-danger mr-2">{homeSeo?.error}</span>
          ) : null}
          <button onClick={onClick}>{btnType}</button>
        </div>
      </div>
    </>
  );
};

export default HomeSeoForm;
