import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import uploadIcon from '../../assets/icons/upload.png';
import editImageIcon from '../../assets/icons/edit.png';
import { useDispatch, useSelector } from "react-redux"
import { createSubCategory } from '../../redux/actions/subCategoryAction';
import { loadCategory } from '../../redux/actions/categoryAction';
import { Redirect } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { confirmAlert } from 'react-confirm-alert';
// import { AsyncPaginate } from 'react-select-async-paginate';
// import axiosauth from '../../axios-config-auth';
import { Editor } from '@tinymce/tinymce-react';

import { useHistory } from 'react-router';
const AddSubCategory = () => {
  const history = useHistory()
  const [file, setfile] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [urlFile, setUrlFile] = useState("")
  const animatedComponents = makeAnimated()
  const [seoText, setSEOText] = useState('');
    const editorRef = useRef(null);

  // Error State
  const [catError, setCatError] = useState("");
  const [subError, setSubError] = useState("");
  const [urlError, setUrlError] = useState("");

  const categoryData = useSelector(state => state.categoryData.data);
  // console.log(categoryData)

  const subCategoryData = useSelector(state => state.subCategoryData);

  var cat_list = [];
  if (typeof categoryData.categories === "object") {
    for (var p = 0; p < Object.keys(categoryData.categories).length; p++) {
      // console.log(categoryData.categories[p])
      cat_list.push({ value: categoryData.categories[p].categoryId, label: categoryData.categories[p].name, catid: categoryData.categories[p]._id })
    }
  }


  // console.log("cat_list", cat_list)

  const [selectCategory, setSelectCategory] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [subCategory, setSubCategory] = useState({
    subCategoryName: "",  
        seoTitle: '',
        seoDescription: '', 
        tags: ''
  });

  // console.log("subCategory====>>" , subCategory)

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSubCategory({
      ...subCategory,
      error: false,
      [e.target.name]: e.target.value
    })
    setSubError("")
  }
  // console.log(formValues)

  const handleSelect = (selectedOption) => {
    setSelectCategory({ selectedOption })
    setCatError("")
  }

  const categorySubmit = (e) => {
    e.preventDefault()

    if (subCategory.subCategoryName === "") {
      setSubError("Sub-Category Name Required");
      return 0;
    }

    if (selectCategory.selectedOption === undefined) {
      setCatError("Category Required");
      setSubError("")
      return 0;
    }

    if (file.length<1) {
      setUrlError("Image Required")
      setTimeout(()=>{
        setUrlError("")
      } ,5000)
      setCatError("")
      return 0;
    }


    var catlist = [];
    var c = [];
    for (var k = 0; k < selectCategory.selectedOption.length; k++) {
      catlist.push(selectCategory.selectedOption[k].value);
      c.push(selectCategory.selectedOption[k].catid);
    }

    let obj = {
      name: subCategory.subCategoryName, 
      type: "sub1",
      parentId: selectCategory.selectedOption.catid, 
      "seoTitle":  subCategory.seoTitle,
      "seoDescription":  subCategory.seoDescription, 
      seoText:seoText,
      "tags":subCategory.tags.split(","),  
    };
    // console.log("sub submit ::==>", obj);
    dispatch(createSubCategory(obj, file.length>1   ?urlFile : null))
  }

  useEffect(() => {
    dispatch(loadCategory());
    // dispatch(loadSubCategory());
  }, [dispatch]);

  const closeModal = () => {
    setRedirect(true);
  }

  const handleInputChange = (e) => {
    dispatch(loadCategory(e));
  }

  const customStyles = {
    singleValue: (base) => ({
      ...base,
      padding: "5px 10px 5px 2px",
      borderRadius: 5,
      display: "flex",
      width: "fit-content",
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      padding: "0em 0em 0em .5em"
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#4A4A4A',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999      
    }),
  };



  const backButton = () => {

    if (subCategory.subCategoryName !== "") {

      confirmAlert({
        // title: 'Change Status',
        message: 'Are you sure to do this. You will loss your data.',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>
              setRedirect(true)
          },
          {
            label: 'No',
            onClick: () => console.log("No")
          }
        ]
      });
    } else {
      setRedirect(true)
    }

  }

//  const onlyLetterAllowWithSpace =(e)=> {
//     const re = /[a-zA-Z ]+/g;
//     if (!re.test(e.key)) {
//       e.preventDefault();
//     }
//   }

  return (
    <>
      {redirect ? <Redirect to="/manage-sub-category?page=1" /> : null}
      {subCategoryData.data.success === true ? <SuccessModal message="Sub-Category Created !" onClick={closeModal} /> : null}


      <form className="form-wrap" onSubmit={categorySubmit}>
      
        <div className='d-flex justify-content-between'>
          <div className="left_arrow2 back-arrow align-self-center mb-0" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img src={left_arrow2} onClick={backButton} alt="left-arrow" /> &nbsp;
            <h2>Create Sub-Category</h2>

          </div>
          <div>
            <div className='d-flex '>
              <div className="button ml-0">
                <button onClick={() => history.goBack()}>Back</button>
              </div>
              <div className="button ml-2">
                <button onClick={() => window.location.reload()}>Refresh</button>
              </div>
            </div>
          </div>
        </div>
      

        <div className="form-body">
          <div className="single-field">
            <p>SubCategory Name <b>*</b></p>
            <input
              type="text"
              placeholder="Sub Category Name"
              name="subCategoryName" 
              // onKeyPress={(e) => onlyLetterAllowWithSpace (e)}
              onChange={handleChange} />

            <i className="error">{subError}</i>
          </div>
          <div id="select-tag">
            <p>Select Categories</p>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={cat_list}
              styles={customStyles}
              name="variants"
              required
              onInputChange={handleInputChange}
              onChange={handleSelect} />
            <i className="error">{catError}</i>
          </div>

          
          {/* <div className="single-field">
                        <p> Search Keyword</p>
                        <input title="Please enter on alphabets only. " 
                        // pattern="^[A-Za-z ,]+$"
                        // oninvalid="setCustomValidity('Please enter  alphabets only. ')"

                         type="text" placeholder="Tags" name="tags" onChange={handleChange} />
                    </div> */}

                    <div className="single-field">
                        <p>SEO Title <b></b></p>
                        <input title="Please enter on alphabets only. "
                        //  pattern="^[A-Za-z ,]+$" oninvalid="setCustomValidity('Please enter  alphabets only. ')" 
                         type="text" placeholder="SEO Title" name="seoTitle"   onChange={handleChange} />
                    </div>
                    <div className="single-field">
                        <p>SEO Description <b></b></p>
                        <input title="Please enter on alphabets only. "
                        //  pattern="^[A-Za-z ,]+$" oninvalid="setCustomValidity('Please enter alphabets only. ')" 
                         type="text" placeholder="SEO Description" name="seoDescription"   onChange={handleChange} />
                    </div>
                    <div className='single-field'>
                        <p className="label-form">SEO Text </p>
                        <Editor
                            onInit={(evt, editor) => { editorRef.current = editor }}
                            initialValue=""
                            onEditorChange={(newText) => {
                                //   console.log(newText)
                                setSEOText(newText)
                            }}
                            init={{
                                height: 450,
                                menubar: true,
                                plugins: [
                                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>
          <div >
            <p style={{
              "marginTop": "12px",
              "fontFamily": "Roboto",
              "fontStyle": "normal",
              "fontWeight": 500,
              "fontSize": "18px",
              "lineHeight": "24px",
              "color": "#4F4F4F"
            }}>{`Image Dimension Must be (500 X 500) , image (jpg, png , WebP) and image size must be less than < 200KB` }<b>*</b></p>
            {!isFile ?
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" /><br />
                  <p>Upload image
                                        <br />
                                    <span style={{fontSize:"10px"}}>500 X 500</span></p>
                </button>
                <input
                  type="file" 
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }

                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]); 
                    img.onload = () => {
                      if (img.width === 500 && img.height === 500) {
                        // console.log("ghf")
                        setfile(img.src);

                      } else {
                        alert('Incorrect image dimension. Please upload an image 500X500 pixels.')
                      }
                    }


                    // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                    setIsFile(true);
                    setUrlFile(e.currentTarget.files[0]);
                    setUrlError("")
                  }}
                />
              </div> :
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img for="upload-image" class="edit-img" src={editImageIcon} alt="" />
                  </label>
                </div>
                <img class="screen" src={file} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: 'none' }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                    setIsFile(true);
                    setUrlFile(e.currentTarget.files[0]);

                    let img = new Image()
                    img.src = window.URL.createObjectURL(e.target.files[0]); 
                    img.onload = () => {
                      if (img.width === 500 && img.height === 500) {
                        // console.log("ghf")
                        setfile(img.src);

                      } else {
                        alert('Incorrect image dimension. Please upload an image 500X500 pixels.')
                      }
                    }
                  }}
                // required
                />
              </aside>
            }
            <i className="error">{urlError}</i>
          </div>

          <div className="button mt-5">
            <button>Create</button>
          </div>
        </div>
      </form>
      {/* {catstatus.success === true ? <Redirect to="/category-manage" /> : null} */}

    </>
  )
}

export default AddSubCategory
