import React from 'react'
import Layout from "../../layout/layout";
import CatalogueList from '../../wrapper/brand/CatalogueList';
const ManageCatalogue = () => {
    return (
        <Layout>
            <CatalogueList />
        </Layout>
    )
}

export default ManageCatalogue
