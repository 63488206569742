
import React, { useEffect, useState } from "react";
import Layout from "../../layout/layout";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory } from "react-router"; 
import axiosInstance from "../../axios-config-auth"; 
import editIcon from "../../assets/icons/edit.png";
import { Link } from "react-router-dom";
import NoData from "../../components/halper/NoData";
import Loader from "react-loader-spinner";

export const LoadOfferSetting = () => {
    const history = useHistory(); 
    const [data , setData] = useState()
    const [loading , setLoading] = useState(false)
    const showDate = (data) => {
        var d = new Date(data);
        var date = d.getDate();
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        var month = d.getMonth(); // Since getMonth() returns month from 0-11 not 1-12
        var year = d.getFullYear();
        var dateStr = date + " " + months[month] + " " + year;
        // var res =
        return dateStr;
      };

    useEffect(async() =>{
      const result = await  axiosInstance.post("/vouchers/load-offer-title") 
      let res = JSON.parse(result.data);
      if(res.success){ 
        setLoading(true)
          setData(res.data)
      }
    } ,[])

 

    return (
        <Layout>
          <div className="form-wrap"> 
        <div
          className="left_arrow2 back-arrow"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            onClick={() => history.goBack()}
            src={left_arrow2}
            alt="left-arrow"
          /> 
          &nbsp;  &nbsp;  &nbsp;  &nbsp;
          <h2>Product Offer Coupon</h2>
          <div className="button">
                    <button onClick={() => history.push("/add-offer-setting")}>Add Offer</button>
                </div>
        </div> 
        {loading ? 
        <div className="table-wrap overflow-auto table-responsive table border p-0 border-top-0 border-bottom-0">
          <table>
            {data && data.length > 0 ? (
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>title</th> 
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {data && data.length > 0 ? (
                data.map((offer, index) => {
                  
                  return (
                    <tr key={index}>
                      <td>
                        { (index + 1)}
                      </td>
                      <td className="titleStyle title-name">{offer?.offerTitle}</td>
                      <td>{showDate(offer.createdOn)}</td> 
                      
                      <td>
                        <Link to={`/edit-offer-setting/${offer._id}`}>
                          <img 
                            src={editIcon}
                            alt="edit-button"
                            className="edit-icon"
                          />
                        </Link>

                         
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoData message=" Data not Found" />
              )}
            </tbody>
          </table>
          
        </div>
        : <div className="loader-style">
        <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={500000}
        />
    </div>}
       
      </div> 
        </Layout>
    )
}
