import React from 'react'
import Layout from "../../layout/layout"
import EditSubCategoryForm from '../../wrapper/category/EditSubCategory'
const EditSubCategory = () => {
  return (
    <Layout>
      <EditSubCategoryForm />
    </Layout>
  )
}

export default EditSubCategory
