import React, { useEffect, useState } from 'react'; 
import { Link, useHistory, useParams } from 'react-router-dom';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import axiosauth from '../../axios-config-auth'; 
import Layout from '../../layout/layout'


export const AddOptionProduct = () => {

  const { optionId } = useParams()
  const history = useHistory()
  const [optionobj, setOprionObj] = useState([]);
  const [brandObj, setBrandObj] = useState([])
  const [brandId, setBrandId] = useState('')
  const [categoryObj, setCategoryObj] = useState([]);
  const [categoryId, setCategoryId] = useState("")
  const [subCategoryObj, setSubCategoryObj] = useState([]);
  const [subCategoryId, setSUbCategoryId] = useState("")
  const [subSubCategoryObj, setSubSubCategoryObj] = useState([]);
  const [subSubCategoryId, setSubSubCategoryId] = useState("")

  console.log("optionId",optionId)

  useEffect(() => {
    const obj = {
      optionId: optionId
    }
    axiosauth
      .post("/option/find-option-id", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          // setOptionData(res.data)
          // console.log("load- option--->", res.result)
          setOprionObj(res.result)

          // setShowLoader(false);

        } else {
          // setProductCatalogue([])
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }, [optionId])

  const handleBrands = () => {
    const obj = {
      limit: 10000
    }
    axiosauth
      .post("/brand/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setBrandObj(res.data.data)
          // setOptionData(res.data)
          // console.log("load- option--->", res.result)
          // setOprionObj(res.result)

          // setShowLoader(false);

        } else {
          // setProductCatalogue([])
          // setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleCategory = () => {
    const obj = {
      "limit": 10000,
      "filters": {
        "type": "category"
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setCategoryObj(res.data.categories)

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleSubCategory = (id) => {
    let obj = {
      "filters": {
        "categoryId": id
      },
      "limit": 100000
    }
    console.log(obj)
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          console.log(res.data.categories[0].childCategories)
          // if(res.data.categories[0])
          setSubCategoryObj(res.data.categories[0].childCategories)

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSUbSubCategory = (id) => {
    const obj = {
      "limit": 10000,
      "filters": {
        "categoryId": id
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setSubSubCategoryObj(res.data.categories[0].childCategories)

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleGo =() =>{
    const obj ={}
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setSubSubCategoryObj(res.data.categories[0].childCategories)

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  return (
    <Layout>
      <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
        <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
        <h2>Manage {optionobj?.optionName} Details</h2>
        <div className="button">
          <Link to="/add-brand">
            <button>Add Product</button>
          </Link>
        </div>
      </div>
      <div className="form-body">
        <section>
          <div className="two-field">
            <p>Brands</p>
            <div id="select-tag" onClick={handleBrands}>
              <select className="form-select" aria-label="Default select example"

                onChange={(e) => setBrandId(e.target.value)}
              >
                <option value="" selected>select brends</option>
                {brandObj?.map((item) => (
                  <option value={item.brandId}>{item.name}</option>

                ))}

              </select>
            </div>
          </div>
          <div className="two-field">
            <p>Category</p>
            <div id="select-tag" onClick={handleCategory}>
              <select className="form-select" aria-label="Default select example"
                placeholder='select cat'
                onChange={(e) => {
                  setCategoryId(e.target.value)
                  handleSubCategory(e.target.value)
                }}
              >
                <option value="" selected>select category</option>
                {categoryObj?.map((item) => (
                  <option value={item.categoryId}>{item.name}</option>

                ))}

              </select>
            </div>
          </div>
        </section>

        <section>
          <div className="two-field">
            <p>Sub Category</p>
            <div id="select-tag" >
              <select className="form-select" aria-label="Default select example"

                onChange={(e) => {
                  setBrandId(e.target.value)
                  handleSUbSubCategory(e.target.value)
                }}
              >
                <option value="" selected>select brends</option>
                {subCategoryObj?.map((item) => (
                  <option value={item.categoryId}>{item.name}</option>

                ))}

              </select>
            </div>
          </div>
          <div className="two-field">
            <p>Sub-Sub-Category</p>
            <div id="select-tag" onClick={handleSUbSubCategory}>
              <select className="form-select" aria-label="Default select example"
                placeholder='select cat'
                onChange={(e) => setCategoryId(e.target.value)}
              >
                <option value="" selected>select category</option>
                {subSubCategoryObj?.map((item) => (
                  <option value={item.categoryId}>{item.name}</option>

                ))}

              </select>
            </div>
          </div>
        </section>
        <div className="button">
          <button onClick={handleGo} className="px-5">GO </button>
        </div>
      </div>

 


    </Layout>
  )
}

