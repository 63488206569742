
import React, {useState} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// import Modal from 'react-bootstrap/Modal'
// import SuccessModal from './halper/LoaderModal';

const CustomCropper = ({src,selectFile,setResult,imgWidth, imgHeight, handleChange222}) => {

// const [src, selectFile]=useState(null); //------
  const [crop, setCrop] = useState({ unit: "px", width: imgWidth, height: imgHeight });

// const handleFile=(e)=>{
//     selectFile(URL.createObjectURL(e.target.files[0]))
//      console.log("src-->",src)
// }

const [image, setImage]=useState(null);
// const [result, setResult]=useState(null);

function getCroppedImg() {
    selectFile(null);
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
 
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );


const base64Image = canvas.toDataURL('image/png');
setResult(base64Image)
// console.log("base64Image==>",base64Image)

fetch(base64Image)
  .then(res => res.blob())
  .then(blob => {
    const file = new File([blob], "Filename",{ type: "image/png" })
    // console.log("file-->",file)
    // setImageObj(file);
    handleChange222(file);
  })

}

// console.log("crop--->",crop)
//   const [show, setShow] = useState(false);

  const handleClose = () =>{
selectFile(null);
  };
//   const handleShow = () => setShow(true);

    return (
        <div style={{backgroundColor:'white'}}>

          {src && (
            <>
{/* 
          <div className="cropper-modal-bg">
            <div className="cropper-modal-success"> */}
        

              <ReactCrop onImageLoaded={setImage} src={src} crop={crop}
                onChange={newCrop => {setCrop({x: newCrop.x, y: newCrop.y, width: imgWidth, height: imgHeight, unit: "px"})
                }}
              />
              <br/>
              <spna>Alow only 1350X1800 pixels </spna>
              <br/>
              <br/>
                <div className="cropper-button">
                    <button onClick={handleClose}>
                        Cancel
                    </button> &nbsp;
                    <button  onClick={getCroppedImg}>
                      crop image
                    </button>
                </div> 

                
            {/* </div>
          </div> */}

          </>
            )}
  
        </div>
    )
}

export default CustomCropper