import React from 'react'
import Layout from "../../layout/layout";
import VariantList from '../../wrapper/brand/VariantList';

const Variant = () => {
    return (
        <Layout>
<VariantList />
        </Layout>
    )
}

export default Variant
