import React from 'react';
import GeneralDetailsView from './GeneralDetailsView';

const GeneralDetails = ({orderId,payment,orderDate,createdBy, orders,transactionId ,user}) => {
    return (
        <div>
            <GeneralDetailsView orderId={orderId} payment={payment} orders={orders} transactionId={transactionId} createdBy={createdBy} orderDate={orderDate} user={user}/>
        </div>
    )
}

export default GeneralDetails;
