import React, { useState, useEffect } from "react";
import axiosauth from "../../axios-config-auth";
// import Pagination from "react-js-pagination";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../layout/layout";
import left_arrow2 from "../../assets/icons/down_arrow2.png"; 
import { confirmAlert } from "react-confirm-alert";
import deleteIcon from "../../assets/icons/delete.png";
import NoData from "../../components/halper/NoData";
import editIcon from "../../assets/icons/edit.png";
import Loader from "react-loader-spinner"; 

const DealOfTheDay = () => { 
  const [success, setSuccess] = useState(false) 
  const [dealsDate, setDealsData] = useState([]); 
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get("page");
 
 
  const loaddeals =() =>{
    axiosauth
      .post("/deals/day-deals")
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) { 
          setDealsData(res.data); 
          setSuccess(true)
        }  
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  useEffect(() => {
    loaddeals();
  }, [pageCount]);

  const handlePageChange5 = (pageNumber) => { 
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };
 

  return (
    <Layout> 
      { success == false ? <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={5000} //3 secs
          />
        </div> :
      <div className="container-fluid collection-2">
        <div
          className="left_arrow2 back-arrow my-3 mt-4"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Manage Deal Of The Day</h2>
          
        </div>
        <div className="d-flex">
        <div className="button">
            <button onClick={() => history.push("/add-deal-of-the")}>
              Add{" "}
            </button>
          </div> 
        </div>
 

        <div className="table-wrap overflow-auto table-responsive table border p-0 border-top-0 border-bottom-0">
          <table>
            {dealsDate && dealsDate.length > 0 ? (
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Deal Name</th>
                  <th>Time</th>
                  <th>Offer</th> 
                  <th>Actions</th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {dealsDate && dealsDate.length > 0 ? (
                dealsDate.map((deal, index) => {
                  const deleteDeal = (id) => {
                    let obj = {
                      dealId: id,
                    };
                    confirmAlert({
                      // title: 'Change Status',
                      message: "Are you sure you want to delete this Deal ?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            axiosauth
                              .post("/deals/delete-deals", obj)
                              .then(async (response) => {
                                let res = JSON.parse(response.data);
                                if (res.success === true) {
                                   loaddeals();
                                } 
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          },
                        },
                        {
                          label: "No",
                          onClick: () => console.log("Hello"),
                        },
                      ],
                    });
                  };

                  return (
                    <tr key={index}>
                      <td>
                        {((pageCount ? pageCount : 1) - 1) * 10 + (index + 1)}
                      </td>
                      <td>
                        {deal.name}
                        </td>
                       
                      <td>{deal.dealOfTheDayEndDate}</td>
                      <td>
                        {deal?.dealOfTheDayOffer}
                          
                      </td>
                      <td>
                        <Link to={`/update-deal-of-the/${deal._id}`}>
                          <img 
                            src={editIcon}
                            alt="edit-button"
                            className="edit-icon"
                          />
                        </Link>

                        <img
                          onClick={() => deleteDeal(deal._id)}
                          src={deleteIcon}
                          alt="delete"
                          className="delete-icon"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoData message=" Deals Not Found" />
              )}
            </tbody>
          </table>
          
        </div>

        {/* <div className="pagination-custom pro-pagination-style">
          <Pagination
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={1}
            disabledClass={true}
            hideDisabled={true}
            onChange={handlePageChange5}
            hideFirstLastPages={true}
            pageContainerClass="mb-0 mt-0"
            pagePrevText="«"
            pageNextText="»"
          />
        </div> */}
      </div>
}
    </Layout>
  );
};

export default DealOfTheDay;

 