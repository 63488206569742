import React from "react";
import { useState, useEffect } from "react";
// import Profile from '../../wrapper/setting/Profile';
import ChangePassword from "../../wrapper/setting/ChangePassword";
import axiosauth from "../../axios-config-auth";
import "./setting.scss";
const SettingMenu = () => {
  const [isprofile, showProfile] = useState(true);
  const [changePassword, showChangePassword] = useState(false);
  const [profileEdit, setProfileEdit] = useState(false);
  const [profileMsg, setProfileMsg] = useState("");
  const [profileError, setProfileError] = useState("");
  const [profile, setProfile] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    bio: "",
    userMobile: "",
  });

  const { firstName, middleName, lastName, gender, bio, userMobile } = profile;
  const profileEditOpen = () => {
    setProfileEdit(true);
  };

  const profileClose = () => {
    setProfileEdit(false);
  };
  useEffect(() => {
    // fetchUserDataInit();
    axiosauth
      .get("/profile/load-profile")
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
          if (res.data.user.temporaryMobile === null) {
            setProfile({
              firstName: res.data.user.firstName,
              middleName: res.data.user.middleName,
              lastName: res.data.user.lastName,
              gender: res.data.user.gender,
              bio: res.data.user.bio,
              userMobile: "",
            });
          } else {
            setProfile({
              firstName: res.data.user.firstName,
              middleName: res.data.user.middleName,
              lastName: res.data.user.lastName,
              gender: res.data.user.gender,
              bio: res.data.user.bio,
              userMobile: res.data.user.temporaryMobile,
            });
          }
        } else {
          console.log("No data");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const onProfileChange = (e) => {
    setProfile({ ...profile, error: false, [e.target.name]: e.target.value });
  };
  const handleProfile = () => {
    showProfile(true);
    showChangePassword(false);
    var profile = document.querySelector("#profile");
    var changePass = document.querySelector("#changepassword");
    profile.classList.add("active-menu");
    changePass.classList.remove("active-menu");
  };
  const webIndex = async () => {
    const promise = await axiosauth.post("/webindex", {});
    return promise;
  };

  // console.log( (profile.bio)?.length)

  const profileSubmit = async () => {
    let obj = {
      firstName: profile.firstName,
      middleName: profile.middleName,
      lastName: profile.lastName,
      gender: profile.gender.toLowerCase(),
      bio: profile.bio,
      mobile: profile.userMobile,
    };
    if (profile.userMobile.length === 10) {
      axiosauth
        .post("/profile/update-profile-data", obj)
        .then(async (Response) => {
          let res = JSON.parse(Response.data);
          if (res.success === true) {
            setProfileMsg(res.message);
            setProfileError("");
            const session = await webIndex().then((data) => data);
            localStorage.setItem(
              "accesstoken",
              JSON.parse(session.data).sessionId
            );
            // fetchUserDataInit();
          } else {
            setProfileMsg("");
            setProfileError("Please Fill all the *mandatory Fields");
            // fetchUserDataInit();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setProfileError("Mobile Number Should be 10 digits");
      setProfileMsg("");
    }

    setProfileEdit(false);
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const handleChangepassword = () => {
    showProfile(false);
    showChangePassword(true);
    var profile = document.querySelector("#profile");
    var changePass = document.querySelector("#changepassword");
    profile.classList.remove("active-menu");
    changePass.classList.add("active-menu");
  };

  const CharacterCount =(e)=>{ 
    const  re =profile.bio;
    if (re.length>499) {
      e.preventDefault();
    }
  }

  return (
    <div>
      <div className="menu-bar">
        <section>
          <div id="profile" className="active-menu" onClick={handleProfile}>
            Profile
          </div>
          <div className="mx-3" id="changepassword" onClick={handleChangepassword}>
            Change Password
          </div>
        </section>
      </div>
      <div className="setting-info">
        {isprofile && (
          <div className="profile pb-2">
            <div className="row row-style">
              <div className="col-md-8 col-sm-6 col-6">
                <h3>Profile</h3>
              </div>
              <div className="col-md-4 col-sm-6 col-6 action_button">
                {!profileEdit ? (
                  <svg
                    onClick={profileEditOpen}
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.1255 3.3153C10.705 3.3153 11.1747 3.77911 11.1747 4.35125C11.1747 4.92339 10.705 5.3872 10.1255 5.3872H2.21956V20.8114H17.8415V12.9597C17.8415 12.3875 18.3113 11.9237 18.8907 11.9237C19.4702 11.9237 19.94 12.3875 19.94 12.9597V20.8114C19.94 21.9557 19.0005 22.8833 17.8415 22.8833H2.21956C1.06061 22.8833 0.121094 21.9557 0.121094 20.8114V5.3872C0.121094 4.24292 1.06061 3.3153 2.21956 3.3153H10.1255ZM16.8602 3.43137L19.6985 6.26966L10.4698 15.4983C10.2944 15.6737 10.0661 15.7865 9.82021 15.8192L6.92615 16.2037L7.31068 13.3096C7.34335 13.0637 7.45609 12.8354 7.63149 12.66L16.8602 3.43137ZM19.0776 1.21395C19.5185 0.773083 20.2332 0.773083 20.6741 1.21395L21.9159 2.45571C22.3567 2.89658 22.3567 3.61137 21.9159 4.05225L20.7628 5.2053L17.9245 2.36701L19.0776 1.21395Z"
                      fill="#D94645"
                    />
                  </svg>
                ) : (
                  <div className="d-flex">
                    <button onClick={profileClose} className="cancelBtn">
                      Cancel
                    </button>
                    <button onClick={profileSubmit} className="savebutton">
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
            <hr className="hr-line" />
            <div className="row row-style">
              <div className="col-md-4 col-sm-6 col-12 mb-5">
                <p>Full Name</p>
                {!profileEdit ? (
                  <h4>
                    {firstName} {middleName} {lastName}
                  </h4>
                ) : (
                  <div>
                    <div className="input-group mb-2">
                      <label htmlFor="firstName" className="mt-2">
                        First Name <span style={{ color: "red" }}>*</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </label>
                      <input
                        type="text"
                        className="form-control setting-form"
                        value={firstName}
                        onChange={onProfileChange}
                        name="firstName"
                        required={true}
                        id="firstName"
                        placeholder="First Name"
                      />  
                    </div>
                    
                    <div className="input-group mb-2">
                      <label htmlFor="middleName" className="mt-2">
                        Middle Name &nbsp;
                      </label>
                      <input
                        type="text"
                        className="form-control setting-form"
                        value={middleName}
                        onChange={onProfileChange}
                        name="middleName"
                        required={true}
                        id="middleName"
                        placeholder="Middle Name"
                      />

                      {/* <div class="input-group-prepend">
                                            <div class="input-group-text"></div>
                                        </div> */}
                    </div>
                    <div className="input-group mb-2">
                      <label htmlFor="lastName" className="mt-2">
                        Last Name <span style={{ color: "red" }}>*</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </label>
                      <input
                        type="text"
                        className="form-control setting-form"
                        value={lastName}
                        onChange={onProfileChange}
                        name="lastName"
                        required={true}
                        id="lastName"
                        placeholder="Last Name"
                      /> 
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-3 col-sm-6 col-12 mb-5 ml-0">
                <p>Gender</p>
                {!profileEdit ? (
                  <h4 className="text-capitalize">{gender}</h4>
                ) : (
                  <div className="input-group mb-2"> 

                    <select
                      Name="form-control setting-form "
                      name="gender"
                      value={gender}
                      onChange={onProfileChange}
                      id="exampleFormControlSelect1"
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>

                    <div className="input-group-prepend">
                      <div className="input-group-text"></div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-3 col-sm-6 col-12 mb-5">
                <p>Mobile Number</p>
                {!profileEdit ? (
                  <h4>{userMobile}</h4>
                ) : (
                  <div className="input-group mb-2">
                    <input
                      type="number"
                      className="form-control setting-form"
                      value={userMobile}
                      onChange={onProfileChange}
                      name="userMobile"
                      placeholder="Mobile Number"
                      maxLength="10"
                      onInput={maxLengthCheck}
                      required={true}
                      onKeyDown={(e) =>
                        /[+\-.,]$/.test(e.key) && e.preventDefault()
                      }
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text"></div>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12  mb-2">
                <p>Bio (Total Character {profile.bio?.length})</p>
                {!profileEdit ? (
                  <div
                    className="card p-3"
                    style={{ height: "150px", overflowY: "scroll" }}
                  >
                    <h4>{bio}</h4>
                  </div>
                ) : (
                  <div className="input-group mb-2">
                    <textarea
                      className="form-control"
                      value={bio}
                      onChange={onProfileChange}
                      onKeyPress={(e) => CharacterCount(e)}
                      name="bio"
                      rows="6"
                      placeholder="bio"
                    ></textarea>
                  </div>
                )}
              </div> */}
            </div>
            <span className="text-danger text-center pt-1 pb-2 ml-4 pl-2">
              {profileError}
            </span>
            <span className="text-success tetx-center pt-1 pb-2 ml-1">
              {profileMsg}
            </span>
          </div>
        )}
      </div>
      {changePassword && <ChangePassword />}
    </div>
  );
};

export default SettingMenu;
