import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory } from "react-router";
import axiosauth from "../../axios-config-auth";
import SuccessModal from '../../components/halper/SuccessModal';
import { Redirect } from "react-router-dom";
import LoaderModal from '../../components/halper/LoaderModal';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosInstance from "../../axios-config-auth";

const AddDealOfTheDay = () => {
    const history = useHistory();
    const [product, setProduct] = useState([])
    const animatedComponents = makeAnimated();
    // const [productOptions, setproductOptions] = useState([])
    // const [selectedProducts, setSelectedProducts] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [show, setShow] = useState(false);
    const [collection, setCollection] = useState(); 
    const[collId , setCollId] = useState()
    // =========================================================================
    const [isApply, setIsApply] = useState("all-Products");
    const [productOptions, setproductOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [subsubcategoryOptions, setSubsubCategoryOptions] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState([]);
    const [selectedSubsubCategory, setSelectedSubsubCategory] = useState([]);

    const [deal, setDeal] = useState({
        offerName: '',
        time: "",
        discount: "",
    });

    useEffect(() => {
        let arr = [];
        const obj = {
            "limit": 10000,
            "filters": {
                "type": "category"
            }
        }
        axiosauth
            .post("/categories/load", obj)
            .then((Response) => {
                let res = JSON.parse(Response.data);
                if (res.success === true) {
                    // setCategoryObj(res.data.categories)

                    if (res.data.categories) {
                        for (var i = 0; i < res.data.categories.length; i++) {
                            arr.push({
                                value: res.data.categories[i]._id,
                                label: res.data.categories[i].name
                            })
                        }
                    }
                    setCategoryOptions(arr)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        let arr = [];
        const obj = {
            "limit": 10000,
            "filters": {
                "type": "sub1"
            }
        }
        axiosauth
            .post("/categories/load", obj)
            .then((Response) => {
                let res = JSON.parse(Response.data);
                if (res.success === true) {
                    if (res.data.categories) {
                        for (var i = 0; i < res.data.categories.length; i++) {
                            arr.push({
                                value: res.data.categories[i]._id,
                                label: res.data.categories[i].name + " (" + res.data.categories[i].parentCategory[0]?.name + " )"
                            })
                        }
                        setSubCategoryOptions(arr)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        let arr = [];
        const obj = {
            "limit": 10000,
            "filters": {
                "type": "sub2"
            }
        }
        axiosauth
            .post("/categories/load", obj)
            .then((Response) => {
                let res = JSON.parse(Response.data);
                if (res.success === true) {
                    if (res.data.categories) {
                        for (var i = 0; i < res.data.categories.length; i++) {
                            arr.push({
                                value: res.data.categories[i]._id,
                                label: res.data.categories[i].name + " (" + res.data.categories[i].parentCategory[0]?.name + " )"
                            })
                        }
                        setSubsubCategoryOptions(arr)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])


    useEffect(() => {
        let arr = [];
        const obj = {
            limit: 10000000,
            // suspend: "false",
            filters: {
                suspend: "false"
                // sub1categoryslug: search,
            },
            sorts: { sortbyPrice: 1 },
            userId: ""
        }
        axiosauth
            .post("/products/loadv2", obj)
            .then((Response) => {
                let res = JSON.parse(Response.data);
                if (res.success === true) {
                    for (var i = 0; i < res.data.products.length > 0; i++) {
                        arr.push({
                            value: res.data.products[i]._id,
                            label: res.data.products[i].catalogueName
                        })
                    }
                    setproductOptions(arr)

                } else {
                }
            })
            .catch((error) => {
                console.log(error.message);
            });

    }, [])



    const handleProduct = (selectedOption) => {
        setSelectedProducts({ selectedOption });
    };

    const handleCategory = (selectedOption) => {
        setSelectedCategory({ selectedOption });
    };
    const handleSubCategory = (selectedOption) => {
        setSelectedSubCategory({ selectedOption });
    };
    const handleSubsubCategory = (selectedOption) => {
        setSelectedSubsubCategory({ selectedOption });
    };

    const handleChange = (name) => (event) => {
        setDeal({ ...deal, error: false, [name]: event.target.value });
    };


    const onSubmit = () => {

        if (deal && deal.offerName === "") {
            alert("Please Enter Offer Name");
            return 0;
        }
        if (deal && deal.time === "") {
            alert("Please Enter Date");
            return 0;
        }
        if (deal && deal.discount > 100) {
            alert("Enter valid discount")
            return 0;
        }

        let products = [];
        let category = [];
        let subcategory = [];
        let subsubCategory = [];

        if (isApply === "specific-products")
            for (var i = 0; i < selectedProducts.selectedOption.length; i++) {
                products.push(selectedProducts.selectedOption[i].value)
            }
        if (isApply === "specific-category")
            for (var i = 0; i < selectedCategory.selectedOption.length; i++) {
                category.push(selectedCategory.selectedOption[i].value)
            }
        if (isApply === "specific-sub-category")
            for (var i = 0; i < selectedSubCategory.selectedOption.length; i++) {
                subcategory.push(selectedSubCategory.selectedOption[i].value)
            }
        if (isApply === "specific-sub-sub-category")
            for (var i = 0; i < selectedSubsubCategory.selectedOption.length; i++) {
                subsubCategory.push(selectedSubsubCategory.selectedOption[i].value)
            }


        let obj = {
            "name": deal.offerName,
            "dealOfTheDayOffer": deal.discount,
            "dealOfTheDayEndDate": deal.time,
            collectionId :collId,
            "shortDescription": "",
            ...(products.length > 0 && isApply === "specific-products" && { products: products }),
            ...(category.length > 0 && isApply === "specific-category" && { category: category }),
            ...(subcategory.length > 0 && isApply === "specific-sub-category" && { sub1category: subcategory }),
            ...(subsubCategory.length > 0 && isApply === "specific-sub-sub-category" && { sub2category: subsubCategory }),
        }

        axiosauth.post("/deals/create-day-deal", obj).then((result) => {

            const res = JSON.parse(result.data)
            if (res.success) {
                setShowLoader(false)
                setShow(true)

            } else {
                setNetworkError(true)
            }
        }).catch((error) => { console.log("Error==>>", error.message) })

    }

    const closeModal = () => {
        setRedirect(true);
    }
    const connectionError = () => {
        setNetworkError(false)
    }

    useEffect(() => {
        axiosInstance.post("gazariCollection/load-collection").then((response) => {
            let res = JSON.parse(response.data);
            if (res.success === true) { 
                const collectionList = res.collections.length > 0 && res.collections.filter((coll) =>
                    (coll.products && coll.products.length > 0) || (coll.category && coll.category.length > 0) || (coll.sub1category && coll.sub1category.length > 0) || (coll.sub2category && coll.sub2category.length > 0))
                setCollection(collectionList)  
            }
        }).catch((erreor) => console.log(erreor)) 
    }, [])
 

    return (
        <Layout>
            <div
                className="left_arrow2 back-arrow my-3"
                style={{
                    display: "flex",
                    alignItems: "baseline",
                    fontFamily: "inherit",
                }}
            >
                <img
                    src={left_arrow2}
                    onClick={() => history.goBack()}
                    alt="left-arrow"
                />{" "}
                &nbsp;
                <h2>Add Deal Of The Day</h2>
            </div>
            <div className="sub-category-wrap">
                <div className="form-group form-input">
                    <label className="label-style ml-0" for="name">
                        Offer Name<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                        type="text"
                        name="offerName"
                        className={"form-control form-control-nonround"
                        }
                        id="offerName"
                        placeholder="Enter Offer Name"
                        value={deal.offerName}
                        onChange={handleChange("offerName")}
                    />
                </div>
                <div className="form-group form-input">
                    <label className="label-style ml-0" for="name">
                        Date & Time<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                        type="text"
                        name="name"
                        className={"form-control form-control-nonround"
                        }
                        id="name"
                        placeholder="Enter Time HH:MM:SS"
                        value={deal.time}
                        onChange={handleChange("time")}
                    />
                </div>
                <div className="form-group form-input">
                    <label className="label-style ml-0" for="name">
                        Discount<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                        type="number"
                        name="name"
                        className={
                            "form-control form-control-nonround"
                        }
                        id="name"
                        placeholder="Enter Discount"
                        value={deal.discount}
                        min="0"
                        onChange={handleChange("discount")}
                    />
                </div>
                <div className="form-group form-input">
                <label className="label-style ml-0" for="name">
                      Collections 
                    </label>
                    <select className="form-select form-control" aria-label="Default select example"
                        defaultValue=""
                        onChange={(e) => { 
                            console.log(e.target.value)
                            setCollId(e.target.value)
                            // setCollectionId(parseInt(e.target.value))
                        }}
                    >
                        <option value="" disabled >Select page</option>
                        {collection && collection.length>0 && collection.map((coll)=>(
                            <option value={coll._id}  >{coll.title}</option>
                        ))}
                    </select>
                </div>

                {/* <div className="col-md-6 col-sm-4 d-flex">
                    <div> 
                        <select className="form-select " aria-label="Default select example"
                            defaultValue=""
                            onChange={(e) => {
                                // console.log(e.target.value)
                                // setLimitData(parseInt(e.target.value))
                            }}
                        >
                            <option value="" disabled >Select page</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                    </div>
                </div> */}

                <div className="containerr mx-2 mt-5">
                    <div className="radio-wrap ">
                        <h4 className="mb-3">
                            {" "}
                            Applied to <b></b>
                        </h4>

                        <div className="alignment-justify">
                            <aside>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="all-Products"
                                    checked={isApply === "all-Products" ? true : false}
                                    onClick={() => setIsApply("all-Products")}
                                />
                                <span>No Select</span>
                            </aside>
                            <aside>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="specific-products"
                                    onClick={() => setIsApply("specific-products")}
                                />
                                <span>Specific Products</span>
                            </aside>
                            <aside>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="specific-category"
                                    onClick={() => setIsApply("specific-category")}
                                />
                                <span>Specific Category</span>
                            </aside>
                            <aside>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="specific-sub-category"
                                    onClick={() => setIsApply("specific-sub-category")}
                                />
                                <span>Specific Sub Category</span>
                            </aside>
                            <aside>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="specific-sub-sub-category"
                                    onClick={() => setIsApply("specific-sub-sub-category")}
                                />
                                <span>Specific Sub SUb Category</span>
                            </aside>

                        </div>
                    </div>
                    <div className='mb-5'>
                        {isApply === "specific-products" ? (
                            <div id="select-tag">
                                <p></p>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={productOptions}
                                    onChange={handleProduct}
                                />
                            </div>
                        ) : null}

                        {isApply === "specific-category" ? (
                            <div id="select-tag">
                                <p></p>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={categoryOptions}
                                    onChange={handleCategory}
                                />
                            </div>
                        ) : null}

                        {isApply === "specific-sub-category" ? (
                            <div id="select-tag">
                                <p></p>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={subCategoryOptions}
                                    onChange={handleSubCategory}
                                />
                            </div>
                        ) : null}

                        {isApply === "specific-sub-sub-category" ? (
                            <div id="select-tag">
                                <p></p>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={subsubcategoryOptions}
                                    onChange={handleSubsubCategory}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>

            </div>
            <div className="button">
                <button
                    onClick={onSubmit}
                >Save</button>
            </div>

            {redirect ? <Redirect to="/deal-of-the-management" /> : null}
            {show === true ? <SuccessModal message="New Deal Created !!" onClick={closeModal} /> : null}
            {networkError === true ? <SuccessModal message="Internet Connection Error.." onClick={connectionError} /> : null}
            {showLoader === true ? <LoaderModal /> : null}

        </Layout>
    )
}

export default AddDealOfTheDay