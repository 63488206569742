import React, { useState } from 'react';
import SuccessModal from '../../components/halper/SuccessModal';
import { Redirect, useHistory } from "react-router-dom";
import axiosauth from '../../axios-config-auth';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import Layout from '../../layout/layout';

const AddConditionForm = () => {
  const [success, setSuccess]=useState(false);
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);
  const [variant, setVariant] = useState({
    name: ''
  })

  const [networkError , setNetworkError] = useState("")


  const handleChange = (e) => {
    setVariant({ ...variant, error: false, [e.target.name]: e.target.value })
  }

  function capitalizee(input) {  
    var words = input.split(' ');  
    var CapitalizedWords = [];  
    words.forEach(element => {  
        CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));  
    });  
    return CapitalizedWords.join(' ');  
} 

  const variantSubmit = (e) => {
    e.preventDefault();
    let obj = {
      "name": capitalizee(variant.name)
    }

    axiosauth.post('/condition/create-condition/', obj)
    .then(async (response) => {
        let res = JSON.parse(response.data)
        // console.log("res:", res)
        if (res.success === true) {
            // console.log("variant")
            setSuccess(true)
        } else {
          setNetworkError(res.message);
          setTimeout(()=>{
            setNetworkError("")
          },5000)
        }
    })
    .catch((error) => {
        console.log(error);
    })
  }

  const closeModal = () => {
    setRedirect(true);
  }

  return (
    <Layout>

<br/>
<br/>
      <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img onClick={() => history.goBack()} src={left_arrow2}  alt="left-arrow" /> &nbsp;
              <h2>Add Condition Name</h2>
        </div>


      <form onSubmit={variantSubmit} className="form-wrap">
        <div className="form-header">
          <h4>Add Condition Name</h4>
        </div>
        <div className="form-body">
          <div className="single-field">
            <p>Condition Name <b>*</b></p>
            <input type="text"
              placeholder="condition Name"
              name="name"
              onChange={handleChange}
              required
              // pattern='[a-zA-Z]'
              // title='Please enter alphabet character'
            />
          </div>
          {networkError.length>4 && <p style={{color:"red"}}>{networkError}</p>}
        </div>
        <div className="button">
          <button>Add</button>
        </div>
      </form>
      {redirect ? <Redirect to="/condition-manage" /> : null}
      {success ? <SuccessModal message="New condition Created !" onClick={closeModal} /> : null}
    </Layout>
  )
}

export default AddConditionForm