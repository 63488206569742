import React from 'react'
import Layout from "../../layout/layout"
import EditCategoryForm from '../../wrapper/category/EditCategory'
const EditCategory = () => {
  return (
    <Layout>
      <EditCategoryForm />
    </Layout>
  )
}

export default EditCategory
