import React from 'react';
import Layout from "../../layout/layout"
import ManageVariant from '../../wrapper/variant/ManageVariant';

const ManageVariantPage = () => {
  return (
    <Layout>
      <ManageVariant />
    </Layout>
  )
}

export default ManageVariantPage
