import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerInput from "../../components/halper/DatePickerInput";
import { useSelector, useDispatch } from "react-redux";
import { loadCategory } from "../../redux/actions/categoryAction";
import {
  fetchCouponById,
  updateCoupon,
} from "../../redux/actions/couponAction";
import { Redirect, useParams, useHistory } from "react-router-dom";
import SuccessModal from "../../components/halper/SuccessModal";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import axiosauth from "../../axios-config-auth";
import LoaderModal from "../../components/halper/LoaderModal";

const EditCoupon = () => {
  const [isDiscount, setIsDiscount] = useState("percentage");
  const [isApply, setIsApply] = useState("all-Products");
  const [startDate, setStartDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState([]);
  const [selectSubsubCategory, setSelectSubsubCategory] = useState([]);

  const [isModal, setModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [fieldHide, setFieldHide] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const cat = useSelector((state) => state.categoryData.data.categories);
  const [subcat, setSubcat] = useState([]);
  const [subsubcat, setSubsubcat] = useState([])
  const coupons = useSelector((state) => state.couponData);
  const { voucherId } = useParams();
  const [show, setShow] = useState(false);

  const [productuse, setproductuse] = useState([]);
  const [categoryuse, setcategoryuse] = useState([]);
  const [subcategoryuse, setSubcategoryuse] = useState([]);
  const [subsubcategoryuse, setSubsubcategoryuse] = useState([]);


  const [coupon, setCoupon] = useState({
    name: "",
    discount: "",
    minimumAmount: "",
    maxDiscount: "",
    couponLimit: "",
    oncePerUse: true,
    voucherType: "",
  });

  const [loader, setLoader] = useState(false);
  const [plist, setPlist] = useState([]);

  useEffect(() => {
    if (typeof coupons === "object") {
      setCoupon({
        name: coupons.data?.voucherTitle,
        discount: coupons.data?.discount,
        minimumAmount: coupons.data?.minShoppingValue,
        maxDiscount: coupons.data?.maxDiscountLimit,
        couponLimit: coupons.data?.usageLimit,
        oncePerUse: coupons.data?.oncePerUse,
        voucherType: coupons.data?.voucherType,
      });
      setFieldHide(coupons.data?.oncePerUse);
      setShow(coupons.data?.oncePerUse ? true : false);
      // console.log(
      //   "oncePerUse:",
      //   typeof coupons.data?.oncePerUse,
      //   coupons.data?.oncePerUse
      // );
      if (coupons.data?.expiryDate) {
        setExpiryDate(new Date(coupons.data.expiryDate));
        setStartDate(new Date(coupons.data.startDate));
      }

      if (coupons.data.category && coupons.data?.category?.length !== 0) {
        setIsApply("specific-category");
        let catlist = [];

        for (let a = 0; a < coupons.data?.category.length; a++) {
          let index;

          for (let x = 0; x < cat?.length; x++) {
            if (cat[x]._id === coupons.data?.category[a]) {
              index = x;
            }
          }
          // console.log("cat------------->>",cat[index])
          if(cat){   
            catlist.push({
              value: cat[index]?._id,
              label: cat[index]?.name,
            });
          }
        }
        setcategoryuse(catlist);
      }

      if (coupons.data.sub1 && coupons.data.sub1?.length !== 0) {
        setIsApply("specific-sub-category");
        let subcatlist = [];

        for (let a = 0; a < coupons.data.sub1.length; a++) {
          let index;

          for (let x = 0; x < subcat?.length; x++) {
            if (subcat[x]._id === coupons.data.sub1[a]) {
              index = x;
            }
          }
          subcatlist.push({
            value: subcat[index]?._id,
            label: subcat[index]?.name,
          });
        }
        setSubcategoryuse(subcatlist);
      }

      if (coupons.data.sub2 && coupons.data.sub2?.length !== 0) {
        setIsApply("specific-sub-sub-category");
        let subsubcatlist = [];

        for (let a = 0; a < coupons.data.sub2.length; a++) {
          let index;

          for (let x = 0; x < subsubcat?.length; x++) {
            if (subsubcat[x]._id === coupons.data.sub2[a]) {
              index = x;
            }
          }
          subsubcatlist.push({
            value: subsubcat[index]?._id,
            label: subsubcat[index]?.name,
          });
        }
        setSubsubcategoryuse(subsubcatlist);
      }


      if (coupons.data.product && coupons.data.product.length !== 0) {
        setIsApply("specific-products");
        let productList = [];
        if(plist?.length){
          coupons.data.product.forEach(value=>{
            const data = plist.find(newItem=>newItem.value === value)
            if(data){
              productList.push(data)
            }
          })
        }
        setproductuse(productList);
      }

      if (coupons.data.message === "success") {
        setModal(true);
      }

    }
  }, [coupons,plist]);

  const {
    name,
    discount,
    minimumAmount,
    maxDiscount,
    couponLimit,
    oncePerUse,
    voucherType,
  } = coupon;

  const animatedComponents = makeAnimated();

  const cat_list = [];
  if (cat) {
    for (var k = 0; k < cat.length; k++) {
      cat_list.push({ value: cat[k]._id, label: cat[k].name });
    }
  }

  const sub_cat_list = [];
  if (subcat) {
    for (var k = 0; k < subcat.length; k++) {
      sub_cat_list.push({ value: subcat[k]._id, label: subcat[k].name });
    }
  }

  const sub_sub_cat_list = [];
  if (subsubcat) {
    for (var k = 0; k < subsubcat.length; k++) {
      sub_sub_cat_list.push({ value: subsubcat[k]._id, label: subsubcat[k].name });
    }
  }


  const handleProduct = (selectedOption) => {
    setSelectProduct({ selectedOption });
    setproductuse(selectedOption);
  };

  const handleCategory = (selectedOption) => {
    setSelectCategory({ selectedOption });
    setcategoryuse(selectedOption);
  };

  const handleSUbCategory = (selectedOption) => {
    setSelectSubCategory({ selectedOption });
    setSubcategoryuse(selectedOption);
  };

  const handleSubsubCategory = (selectedOption) => {
    setSelectSubsubCategory({ selectedOption });
    setSubsubcategoryuse(selectedOption);
  };

  const handleChange = (e) => {
    setCoupon({
      ...coupon,
      error: false,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "oncePerUse" && e.target.value === "false") {
      setFieldHide(false);
      setShow(false)
    }
    if (e.target.name === "oncePerUse" && e.target.value === "true") {
      setFieldHide(true);
      setShow(true)
    }
  };

  const couponSubmit = (e) => {
    e.preventDefault();

    var date = new Date(startDate);
    var dateStart =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      date.getDate() +
      "-" +
      date.getFullYear();

    var date1 = new Date(expiryDate);
    var dateExpire =
      (date1.getMonth() > 8
        ? date1.getMonth() + 1
        : "0" + (date1.getMonth() + 1)) +
      "-" +
      date1.getDate() +
      "-" +
      date1.getFullYear();

    var product_list = [];
    var category_list = [];
    var sub1 = [];
    var sub2 = [];

    if (isApply === "specific-products") {
      for (var i = 0; i < selectProduct.selectedOption.length; i++) {
        // console.log(selectProduct.selectedOption[i].value);
        product_list.push(selectProduct.selectedOption[i].value);
      }
    }

    // console.log("product_list:", product_list);

    if (isApply === "specific-category") {
      for (var j = 0; j < selectCategory.selectedOption.length; j++) {
        // console.log(selectCategory.selectedOption[j].value);
        category_list.push(selectCategory.selectedOption[j].value);
      }
    }

    if (isApply === "specific-sub-category") {
      for (var j = 0; j < selectSubCategory.selectedOption.length; j++) {
        // console.log(selectSubCategory.selectedOption[j].value);
        sub1.push(selectSubCategory.selectedOption[j].value);
      }
    }

    if (isApply === "specific-sub-sub-category") {
      for (var j = 0; j < selectSubsubCategory.selectedOption.length; j++) {
        // console.log(selectSubsubCategory.selectedOption[j].value);
        sub2.push(selectSubsubCategory.selectedOption[j].value);
      }
    }

    let obj = {
      voucherId: voucherId,
      voucherTitle: name,
      voucherType: voucherType,
      discount: discount,
      startDate: dateStart,
      expiryDate: dateExpire,
      expired: false,
      maxDiscountLimit: maxDiscount,
      minShoppingValue: minimumAmount,
      usageLimit: !fieldHide ? parseInt(couponLimit) : 0,
      oncePerUse: oncePerUse,
      category: category_list,
      sub1: sub1,
      sub2: sub2,
      product: product_list,
    };
    // console.log("obj", obj);
    dispatch(updateCoupon(obj));
  };

  const getProductData = () =>{
    let obj = {
      "count": 0,
      limit: 500,
      field: "max",
      isProduct : true ,
      suspend: "false",
      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
      filters: {
        "postedBy.ownerId": " "
      },
    };

    setLoader(true);
    axiosauth
      .post("/products/load-distinct-catalogue", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);
        if (res.success === true) {
          if(res?.data?.data?.length){
            let list = []
            res.data.data.forEach(item=>{
              list.push({
                value: item?._id,
                label: item?.name,
              })
            })
            setPlist(list)
          }else{
            setPlist([])
          }
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    // dispatch(loadProduct(setLoader));
    dispatch(loadCategory());
    dispatch(fetchCouponById(voucherId));
  }, [dispatch, voucherId]);

  const closeModal = () => {
    setModal(false);
    setRedirect(true);
  };
  useEffect(() => {
    let obj = {
      limit: 500,
      filters: {
        type: "sub1",
      },
    };
    axiosauth.post("/categories/load", obj).then((response) => {
      let res = JSON.parse(response.data);
      if (res.success === true) {
        setSubcat(res.data?.categories);
      }
    });

    getProductData()
  }, []);

  useEffect(() => {
    let obj = {
      limit: 500,
      filters: {
        type: "sub2",
      },
    };
    axiosauth.post("/categories/load", obj).then((response) => {
      let res = JSON.parse(response.data);
      if (res.success === true) {
        setSubsubcat(res.data?.categories);
      }
    });
  }, []);
  const onlyAlphaNumericAllow = (e) => {
    const re = /[a-zA-Z-0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <>
      {isModal ? (
        <SuccessModal message="Coupon Updated !" onClick={closeModal} />
      ) : null}
      {redirect ? <Redirect to="/coupon-manage" /> : null}

      <br />
      <br />
      <div
        className="left_arrow2 back-arrow"
        style={{
          display: "flex",
          alignItems: "baseline",
          fontFamily: "inherit",
        }}
      >
        <img
          onClick={() => history.goBack()}
          src={left_arrow2}
          alt="left-arrow"
        />{" "}
        &nbsp;
        <h2>Update Coupon</h2>
      </div>

      <form onSubmit={couponSubmit} className="form-wrap">
        {/* <div className="form-header">
                    <h4>Update Coupon</h4>
                </div> */}
        <div className="form-body">
          <div className="single-field">
            <p>Coupon Name</p>
            <input
              type="text"
              // placeholder="Happy Diwali"
              name="name"
              value={name?.toUpperCase()}
              onChange={handleChange}
              onKeyPress={(e) => onlyAlphaNumericAllow(e)}
              required
            />
          </div>
          <div className="radio-wrap">
            <h4> Discount Type</h4>
            <div>
              <aside>
                <input
                  type="radio"
                  name="voucherType"
                  value="percentage"
                  onChange={handleChange}
                  onClick={() => setIsDiscount("percentage")}
                  checked={voucherType === "percentage" ? true : false}
                />
                <span>Percentage</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="voucherType"
                  value="fix-value"
                  onChange={handleChange}
                  onClick={() => setIsDiscount("fix-value")}
                  checked={voucherType === "fix-value" ? true : false}
                />
                <span>Fixed Value</span>
              </aside>
            </div>
          </div>
          <section>
            <div className="two-field">
              <p>
                Discount<b>*</b>
              </p>
              <input
                type="number"
                placeholder={isDiscount === "percentage" ? "15%" : "15"}
                name="discount"
                value={discount}
                onChange={handleChange}
                required
              />
            </div>
            <div className="two-field">
              <p>Minimum Shopping Amount</p>
              <input
                type="number"
                placeholder="Enter Amount"
                name="minimumAmount"
                value={minimumAmount}
                onChange={handleChange}
                // required
              />
            </div>
          </section>
          {voucherType === "percentage" ? (
            <div className="single-field">
              <p>Maximum Discount</p>
              <input
                type="number"
                placeholder="Maxium Discount"
                name="maxDiscount"
                value={maxDiscount}
                onChange={handleChange}
              />
            </div>
          ) : null}

          <div className="radio-wrap ">
            <h4> Applied to</h4>
            <div className="alignment-justify">
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="all-Products"
                  checked={isApply === "all-Products" ? true : false}
                  onClick={() => setIsApply("all-Products")}
                />
                <span>All Products</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-products"
                  checked={isApply === "specific-products" ? true : false}
                  onClick={() => setIsApply("specific-products")}
                />
                <span>Specific Products</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-category"
                  checked={isApply === "specific-category" ? true : false}
                  onClick={() => setIsApply("specific-category")}
                />
                <span>Specific Category</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-sub-category"
                  checked={isApply === "specific-sub-category" ? true : false}
                  onClick={() => setIsApply("specific-sub-category")}
                />
                <span>Specific Sub Category</span>
              </aside>
              <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-sub-sub-category"
                  checked={
                    isApply === "specific-sub-sub-category" ? true : false
                  }
                  onClick={() => setIsApply("specific-sub-sub-category")}
                />
                <span>Specific Sub-Sub Category</span>
              </aside>

              {/* <aside>
                <input
                  type="radio"
                  name="gender"
                  value="specific-collection"
                  checked={
                    isApply === "specific-collection" ? true : false
                  }
                  onClick={() => setIsApply("specific-collection")}
                />
                <span>specific-collection</span>
              </aside> */}

            </div>
          </div>

          {isApply === "specific-products"
            //   && productuse.length > 0 
            ? (
              <div id="select-tag">
                <p></p>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  // defaultValue={[{label: productuse[0].label,
                  // value: productuse[0].value}]}
                  // defaultValue={productuse}
                  value={productuse}
                  options={plist}
                  onChange={handleProduct}
                />
              </div>
            ) : null}

          {isApply === "specific-category" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                value={categoryuse}
                options={cat_list}
                onChange={handleCategory}
              />
            </div>
          ) : null}
          {isApply === "specific-sub-category" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                value={subcategoryuse}
                options={sub_cat_list}
                onChange={handleSUbCategory}
              />
            </div>
          ) : null}

          {isApply === "specific-sub-sub-category" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                value={subsubcategoryuse}
                options={sub_sub_cat_list}
                onChange={handleSubsubCategory}
              />
            </div>
          ) : null}

          {/* {isApply === "specific-collection" ? (
            <div id="select-tag">
              <p></p>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                value={subsubcategoryuse}
                options={sub_sub_cat_list}
                onChange={handleSubsubCategory}
              />
            </div>
          ) : null} */}



          <section>
            <div className="two-field">
              <p>Limit one use customer</p>
              <select
                name="oncePerUse"
                value={oncePerUse}
                onChange={handleChange}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>

            {!show ? (
              <div className="two-field">
                <p>Coupon Usage Limit</p>
                <input
                  type="number"
                  name="couponLimit"
                  value={couponLimit}
                  onChange={handleChange}
                />
              </div>
            ) : null}
          </section>
          {/* <section> */}
          {/* <div> */}
          <br />
          <div className="datepicker-alignment">
            <div>
              <p className="label-form">Start Date </p>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<DatePickerInput />}
              />
            </div>
            <div>
              <p className="label-form">Expiry Date</p>
              <DatePicker
                selected={expiryDate}
                onChange={(date) => {
                  if (date < startDate) {
                    alert("Expiry Date Must Be Greater Than Start Date");
                    return 0;
                  }
                  setExpiryDate(date);
                }}
                customInput={<DatePickerInput />}
              />
            </div>
          </div>

          <div className="button">
            <button>Update</button>
          </div>
        </div>
      </form>

      {coupons.message === "success" ? <Redirect to="/coupon-manage" /> : null}
      {loader ? <LoaderModal /> : null}
    </>
  );
};

export default EditCoupon;
