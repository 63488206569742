import React from 'react';
import ShippingAddressView from './ShippingAddressView';

const ShippingAddress = ({address}) => {
    return (
        <div>
            <ShippingAddressView address={address} />
        </div>
    )
}

export default ShippingAddress;
