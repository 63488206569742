import React from 'react';
// import login_art from "../../assets/images/login_art.png"
import login_art from "../../assets/images/gajari.svg"
import ResetPasswordForm from '../../wrapper/forgotPassword/ResetPassword'

const ResetPassword = () => {


  return (
    <div className="login-wrap">
       <div className="login-art" style={{marginTop :"7%"}}>
      <h1></h1>
        <img src={login_art} alt="login-art" />
        {/* <img src={gajariLogo} alt="login-art" /> */}
      </div>
      <div className="loginform-wrap">
        <ResetPasswordForm />
      </div>
    </div>
  )
}

export default ResetPassword