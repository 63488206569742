import {
    SEND_EMAIL_LINK,
    SEND_EMAIL_LOADING,
    SEND_EMAIL_FAIL
} from '../constants/types';

import axiosauth from '../../axios-config-auth';

export function sendlink(user) {
    return {
        type: SEND_EMAIL_LINK,
        payload: user
    }
};

export function linkLoading(user) {
    return {
        type: SEND_EMAIL_LOADING,
        payload: user
    };
}

export function linkFail(user) {
    return {
        type: SEND_EMAIL_FAIL,
        payload: user
    };
}




export const forgotLink = (obj) => {
    return async dispatch => {
        dispatch(linkLoading());
        console.log("linkLoading")
        axiosauth.post('/forgotpassword/send-link', obj)
            .then(Response => {
                let res = JSON.parse(Response.data);
                // console.log("res", res)
                if (res.success === true) {
                    console.log(res);
                    console.log("sendlink")
                    dispatch(sendlink(res));
                } else {
                    dispatch(linkFail(res))
                }
            })
            .catch((error) => {
                console.log(error);
                console.log("linkFail")
                dispatch(linkFail(error))
            })

    };
};
