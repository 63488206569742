import React from 'react';
import Sidebar from '../components/sidebar/sidebar';

const Layout = (props) => {

  return (
    <div style={{backgroundColor:"#e9f1f9"}} className="page-container">
      <div className="sidebar-left-fix">
        <Sidebar />
      </div>
      <div style={{backgroundColor:"#FFFFFF"}} className="right-side">
        {props.children}
      </div>
    </div>
  )
}

export default Layout