import React, { useEffect, useState } from 'react';
import { forgotLink } from '../../redux/actions/authAction';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from "react-router-dom";
import SuccessModal from '../../components/halper/SuccessModal';
const ForgotPasswordForm = () => {
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.authData);
  const [sendEmail, setSendEmail] = useState({
    email: ''
  })
  useEffect(() => {
    return () => {
      setSendEmail("")
    }
}, []);

  const handleChange = (e) => {
    setSendEmail({ ...sendEmail, error: false, [e.target.name]: e.target.value })
  }

  const linkSend = (e) => {
    e.preventDefault();
    let obj = {
      email: sendEmail.email
    };

    // console.log("obj", obj);
    dispatch(forgotLink(obj));
    // axiosauth.post('/forgotpassword/send-link', obj)
    //     .then(Response => {

    //         let res = JSON.parse(Response.data);
    //         if (res.success === true) {
    //             setEmailMsg(res.message);
    //             setEmailError('');
    //             setSendLink(true);
    //             setNetworkError("");
    //         } else {
    //             setEmailError("Please Enter Email");
    //             setNetworkError("");
    //         }
    //     })
    //     .catch((error) => {
    //         setEmailError("");
    //         setNetworkError("Network Error, Try Again Later")
    //         console.log(error);
    //     })

  }

  const closeModal = () => {
    setRedirect(true);
  }

  return (
    <>
      {redirect ? <Redirect to="/" /> : null
      }
      {auth.data.success === true ? <SuccessModal message="Reset Link Send. Please Check Your Email!" onClick={closeModal} /> : null}

      <form onSubmit={linkSend}>
        <div className="forgot-form">
          <h3>Forgot Password</h3>
          <h5>A reset link will be sent to registered mail id</h5>
          <div>
            <p>Enter Email Id <b>*</b> </p>
            <input
              type="email"
              placeholder="Enter Admin Email Id"
              name="email"
              onChange={handleChange}
              required
            />
          </div>
          {/* <div>
        <section><p>Enter Password <b>*</b></p> <a href="/"><b>Forgot Password?</b></a></section>
        <input
          type="password"
          placeholder="Enter Password"
          name="password"
          onChange={handleChange}
          required
        />
      </div> */}
          {/* <div className="network-error">
        {netError}
      </div>
      <div className="error-msg">
        {errorMsg}
      </div> */}
         
          {auth.data.message}
          <i className="error-msg">
          {auth.error.message}
          </i>
          <button>Send Reset Link</button>
        </div>
      </form>
    </>)
}

export default ForgotPasswordForm