import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from "react";
import axiosauth from "../../axios-config-auth";
import Loader from "react-loader-spinner";
import close from "../../assets/icons/close.png";
import NoData from "../../components/halper/NoData";
import editIcon from "../../assets/icons/edit.png";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Layout from "../../layout/layout";
import { confirmAlert } from 'react-confirm-alert';
import deleteIcon from '../../assets/icons/delete.png';

const Explore = () => {
    const [explore, setExplore] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState("");

    const handleEditClose = () => setShowEditModal(false);
    const handleEditShow = () => setShowEditModal(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editName, setEditName] = useState("");
    const [editId, setEditId] = useState("");


    const LoadExplore = () => {
        axiosauth
            .post("/explore/load-Explore", { limit: 1000 })
            .then(async (response) => {
                let res = JSON.parse(response.data);
                if (response.status === 200) {
                    setExplore(res.load);
                    console.log("response---->>", res.load)
                    setShowLoader(false);
                } else {
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        setShowLoader(true);
        LoadExplore();
    }, []);

    const handleSubmit = () => {
        const obj = {
            name: name,
        };
        axiosauth
            .post("/explore/create", obj)
            .then(async (response) => {
                if (response.status === 201) {
                    handleClose();
                    LoadExplore()
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleEditChange = () => {
        axiosauth
            .post("/explore/update-Explore", { name: editName, _id: editId })
            .then(async (response) => { 
                if (response.status === 200) {
                    handleEditClose();
                    LoadExplore()
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <Layout>
            <div
                className="left_arrow2 back-arrow mt-3"
                style={{
                    display: "flex",
                    alignItems: "baseline",
                    fontFamily: "inherit",
                }}>
                <h2>Explore</h2>
                <div className="button">
                    <button onClick={() => handleShow()}>Add Explore</button>
                </div>
            </div>

            <Modal show={showModal} backdrop="static" onHide={handleClose}>
                <Modal.Header className="border-0">
                    <Modal.Title>Explore Name</Modal.Title>
                    <div className="d-end" onClick={handleClose} style={{ cursor: "pointer" }}>
                        <img
                            src={close}
                            alt="close.jpg"
                            style={{ height: "15px", width: "15px" }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <p>Name</p>
                    <input
                        type="text"
                        style={{ width: "100%" }}
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(e) => setName(e.target.value)}
                    />

                    <Button className="button  btn-dark" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            {/* ================== Edit explore ==================== */}
            <Modal show={showEditModal} backdrop="static" onHide={handleEditClose}>
                <Modal.Header className="border-0">
                    <Modal.Title>Edit Explore</Modal.Title>
                    <div className="d-end" onClick={handleEditClose} style={{ cursor: "pointer" }}>
                        <img src={close} alt="img" height={20} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <p>Explore Name</p>
                    <div className="d-flex" style={{ justifyContent: "space-around" }}>
                        <input
                            type="text"
                            value={editName}
                            onChange={(e) => setEditName(e.target.value)}
                            style={{ width: "70%" }}
                            className="form-control"
                        />
                        <Button
                            variant="primary"
                            style={{ backgroundColor: "#333333", width: "25%" }}
                            onClick={handleEditChange}
                        >
                            Save
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            {!showLoader ? (
                <div className="table-wrap overflow-auto table-responsive card py-2 " style={{ height: "500px" }}>
                    <table>
                        {explore && explore.length > 0 ? (<thead>
                            <tr>
                                <th>S.No</th>
                                <th >Explore Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>) : null}
                        <tbody>
                            {explore && explore.length > 0 ?

                                explore.map((item, index) => {

                                    const deleteExplore = () => {
                                        let obj = {
                                            "_id": item._id,
                                        }
                                        confirmAlert({
                                            // title: 'Change Status',
                                            message: 'Are you sure you want to delete this Explore ?',
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () => {
                                                        axiosauth.post('/explore/delete-Explore', obj)
                                                            .then(async (response) => {
                                                                console.log("delete explore---->>", response)

                                                                let res = JSON.parse(response.data)
                                                                if (response.status === 200) {
                                                                    LoadExplore()
                                                                } else {
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            })
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    onClick: () => console.log("Hello")
                                                }
                                            ]
                                        });

                                    }


                                    return (
                                        <tr key={index}>
                                            <td>{(index + 1)}</td>
                                            <td className="titleStyle title-name" >{item.name}</td>
                                            <td>
                                                <img onClick={() => {
                                                    handleEditShow();
                                                    setEditName(item.name);
                                                    setEditId(item._id);
                                                }} src={editIcon} alt="edit-button" className="edit-icon" />
                                                {/* </Link> */}

                                                <img onClick={() => deleteExplore()} src={deleteIcon} alt="delete" className="delete-icon" />
                                            </td>
                                        </tr>
                                    )
                                })

                                :
                                (<NoData message="Explore Not Found" />
                                )
                            }

                        </tbody>
                    </table>

                </div>
            ) : (
                <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={500000}
                    />
                </div>
            )}
        </Layout>
    );
};

export default Explore;
