import React, { useState, useEffect, useRef } from 'react'
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated';
import uploadIcon from '../../assets/icons/upload.png';
import { fetchCategoryById, updateCategory } from '../../redux/actions/categoryAction';
import { useParams, Redirect,useHistory } from "react-router-dom";
import editImageIcon from '../../assets/icons/edit.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVariant } from '../../redux/actions/variantAction';
import SuccessModal from '../../components/halper/SuccessModal';
import left_arrow2 from '../../assets/icons/down_arrow2.png';
import { Editor } from '@tinymce/tinymce-react';

const EditCategoryForm = () => {
  const history = useHistory()
  const [file, setfile] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [urlFile, setUrlFile] = useState("") 
  const { categoryId } = useParams(); 
  const [checkName, setCheckName]=useState("");
  const [seoText, setSEOText] = useState('');
  const editorRef = useRef(null);
  
  const cat = useSelector(state => state.categoryData.data); 
  const variantOptions = useSelector(state => state.variantData.data.data)
  
  var var_list = [];
  if (typeof variantOptions === "object") {
    for (var p = 0; p < Object.keys(variantOptions).length; p++) { 
      var_list.push({ label: variantOptions[p].parent.variantName, value: variantOptions[p].parent._id })
    }
  } 

  const [formValues, setFormValues] = useState({
    name: '',
    longDescription: '',
    shortDescription: '',
    priority: '',
    premiumDeliveryTime: '',
    deliveryTime: '',
    tags: '',
    seoTitle: '',
    seoDescription: '',
    featured: true
  })

  const { name,tags,seoTitle, seoDescription } = formValues;

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      error: false,
      [e.target.name]: e.target.value
    })

  }

 
  const categorySubmit = (e) => {
    e.preventDefault()  
    if(checkName===name){
      let obj = {
        "categoryId": categoryId,
        "longDescription": checkName,
        "shortDescription": checkName,
        "type": "category",
        "seoTitle": seoTitle,
        "seoDescription": seoDescription,
        "featured": true,
        seoText :seoText.newText,
        "tags": tags?.length ? [tags?.toString()] : [], 
        "deliveryTime":"",
        "premiumDeliveryTime":"",
        "priority":1
      } 
      dispatch(updateCategory(obj, urlFile))
    }

    if(checkName!==name){
      let obj = {
        "categoryId": categoryId,
        "name": name,
        "longDescription": checkName,
        "shortDescription": checkName,
        "type": "category",
        "seoTitle": seoTitle,
        "seoDescription": seoDescription,
        "featured": true,
        seoText:seoText.newText,
        "tags": ["none"],
        // "parentId": variant_list,
        // "variantsIncluded": variant_list,
        "deliveryTime":"",
        "premiumDeliveryTime":"",
        "priority":1
      }
      // console.log("object-> 222", obj)
      dispatch(updateCategory(obj))
    }

  
  }

  useEffect(() => {
    
    if (typeof cat === "object") {
      // console.log("condition if", cat)
      setFormValues({
        name: cat.name,
        longDescription: cat.longDescription,
        shortDescription: cat.shortDescription,
        priority: cat.priority,
        premiumDeliveryTime: cat.premiumDeliveryTime,
        deliveryTime: cat.deliveryTime,
        tags: cat.tags,
        seoTitle: cat.seoTitle,
        seoDescription: cat.seoDescription,
        featured: cat.featured,
      });
      setSEOText(cat.seoText)
      setCheckName(cat.name);
      if(cat.image !==undefined && cat.image.length>0){
        setfile(`${cat.image}?date=${new Date()}`);
        setIsFile(true);
       }
    }

  }, [cat]);

  useEffect(() => {
    dispatch(fetchVariant())
    dispatch(fetchCategoryById(categoryId));

  }, [dispatch, categoryId]);

  const closeModal = () => {
    setRedirect(true);

  }
  const Test = () => {
    console.log("readed")
  }

  // const onlyLetterAllowWithSpace =(e)=> {
  //   const re = /[a-zA-Z ]+/g;
  //   if (!re.test(e.key)) {
  //     e.preventDefault();
  //   }
  // }

  return (
    <>


      {cat.success === true ? <SuccessModal message="Category Updated !" onClick={closeModal} /> : null}
      {redirect ? <Redirect to="/category-manage?page=1" /> : null}

      <form className="form-wrap" onSubmit={categorySubmit}>
       
        <div className="left_arrow2 back-arrow" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
            <img src={left_arrow2}  onClick={() => history.goBack()}alt="left-arrow" /> &nbsp;
                <h2>Update Category</h2>
        </div> 
        <div className="form-body">
          {/* <section> */}
            <div className="single-field">
              <p>Category Name <b>*</b> </p>
              <input
                type="text"
                placeholder="Category Name"
                name="name"
                value={name}
                // pattern="^[A-Za-z]+$"
                // onKeyPress={(e) => onlyLetterAllowWithSpace (e)}
                // oninvalid="setCustomValidity('Please enter  alphabets only. ')"
                required
                onChange={handleChange} />
            </div>  

          <div className="single-field">
            <p>Tags</p>
            <input
              type="text"
              // pattern="^[A-Za-z ,]+$"
              // oninvalid="setCustomValidity('Please enter  alphabets only. ')"
              placeholder="Tags"
              name="tags"
              value={tags}
              onChange={handleChange} />
          </div>
          
          <div className="single-field">
            <p>SEO Title <b>*</b></p>
            <input
              type="text"
              placeholder="SEO Title"
              name="seoTitle"
              value={seoTitle}
              // pattern="^[A-Za-z ,]+$"
              // oninvalid="setCustomValidity('Please enter  alphabets only. ')"
              required
              onChange={handleChange} />
          </div>
          <div className="single-field">
            <p>SEO Description <b>*</b></p>
            <input
              type="text"
              placeholder="SEO Description"
              name="seoDescription"
              // pattern="^[A-Za-z ,]+$"
              // oninvalid="setCustomValidity('Please enter  alphabets only. ')"
              value={seoDescription}
              required
              onChange={handleChange} />
          </div>

          <div className='mt-3'>
                        <p className="label-form">SEO Text </p>
                        <Editor
                            onInit={(evt, editor) => { editorRef.current = editor }}
                            initialValue={seoText}
                            onEditorChange={(newText) => {
                                //   console.log(newText)
                                setSEOText({ ...seoText, newText })
                            }}
                            init={{
                                height: 450,
                                menubar: true,
                                plugins: [
                                    'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                    'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>
           
          <div id="img-upload">
            <p>{`Image Dimension Must be (500 X 500) , image (jpg, png , WebP) and image size must be less than < 200KB` }<b>*</b></p>

            {!isFile ?
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" /><br />
                                Upload image
                                </button>
                <input
                  type="file"
                  name="myfile"
                  onChange={(e) => {
                    // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setIsFile(true);
                    setUrlFile(e.currentTarget.files[0]);

                    let img = new Image()
                    img.src = URL.createObjectURL(e.currentTarget.files[0])
                    img.onload = () => {
                      if (img.width === 500 && img.height === 500) {
                            setfile(img.src);
                            // alert("correct image")
                        } else {
                            alert('Incorrect image dimension. Please upload an image 500X500 pixels.')
                        }
                    }
                  }}
                />
              </div> :
              <aside class="image">
                    <div class="overlay1">
                    <label for="file-upload">
                        <img for="upload-image" class="edit-img" src={editImageIcon} alt=""/>
                    </label>
                    </div>
                    <img class="screen" src={file} alt=""/>
                    
                      <input
                        type="file"
                        id="file-upload"
                        style={{display:'none'}}
                        name="myfile"
                        accept="image/*"
                        onChange={(e) => {
                          if (e.currentTarget.files[0].size >= 204800) {
                            alert("Images size must be less than 200KB")
                            return 0
                        }
                          // setfile(URL.createObjectURL(e.currentTarget.files[0]));
                          setIsFile(true);
                          setUrlFile(e.currentTarget.files[0]);

                          let img = new Image()
                          img.src = URL.createObjectURL(e.currentTarget.files[0])
                          img.onload = () => {
                            if (img.width === 500 && img.height === 500) {
                                  setfile(img.src);
                                  // alert("correct image")
                              } else {
                                  alert('Incorrect image dimension. Please upload an image 500X500 pixels.')
                              }
                          }
                      }}
                    />
                </aside>
            }
           
          </div>

        </div>
        <div className="button">
          <button onClick={Test}>Update</button>
        </div>
      </form>
      {/* {formValues === true ? <Redirect to="/category-manage" /> : null} */}
    </>
  )
}

export default EditCategoryForm
