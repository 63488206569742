import React, { useState, useEffect } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import SuccessModal from "../../components/halper/SuccessModal";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import Layout from "../../layout/layout";
import axiosInstance from "../../axios-config-auth";
import Loader from "react-loader-spinner";
import LoaderModal from "../../components/halper/LoaderModal";

const UpdateTexonomy = () => {
  const [redirect, setRedirect] = useState(false);
  const [style, setStyle] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  //   const [errorMsg, setErrorMsg] = useState('');
  const [networkError, setNetworkError] = useState(false);
  const [dataLoader, setDataLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();

  const { texonomyId } = useParams();
  //   ==================================================================
  const [specs, setspecs] = useState([
    {
      startAmount: "",
      endAmount: "",
      gstPercentage: "",
    },
  ]);

  const [taxName, setTaxname] = useState("");
  const [speNameError, setSpeNameError] = useState("");
  const [speValueError, setSpeValueError] = useState("");
  const [spegstError, setSpegstError] = useState("");
  const [isValidprice, setIsValidPrice] = useState(false);

  const addspecs = () => {
    setspecs([...specs, { startAmount: "", endAmount: "", gstPercentage: "" }]);
  };

  const handleChange2 = (e, name, index) => {
    const { value } = e.target;
    // console.log("valuuuueee", value)
    const list = [...specs];
    list[index][name] = value;
    setspecs(list);
    setSpeNameError("");
    setSpeValueError("");
  };

  const removespecs = (index) => {
    const temp = [...specs];
    const filter = temp.filter((item, i) => i !== index);
    setspecs(filter);
  };

  useEffect(() => {
    axiosInstance
      .post("/gst/find-gst-id", { gstId: texonomyId })
      .then(async (response) => {
        let res = JSON.parse(response.data);
        let gs = [];
        if (res.success === true) {
          setTaxname(res.data.gstName);
          setData(res.data);
          setDataLoader(false);
          for (var i = 0; i < res.data.taxName.length; i++) {
            if (res.data.taxName[i]) {
              gs.push({
                startAmount: res.data.taxName[i].startAmount,
                endAmount: res.data.taxName[i].endAmount,
                gstPercentage: res.data.taxName[i].gstPercentage,
              });
            }
            // console.log("gs", gs)
          }
        } else {
          setDataLoader(false);
        }
        setspecs(gs);
      })
      .catch(() => {
        setDataLoader(false);
      });
  }, [texonomyId]);

  const updateTaxonomy = (e) => {
    e.preventDefault();
    let obj = {
      gstId: texonomyId,
      // "name": style
      gstName: taxName,
      taxName: specs,
    };
    setShowLoader(false);

    axiosInstance
      .post("/gst/update-gst", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          setShow(true);
        } else {
          setShowLoader(false);
        }
      })
      .catch(() => {
        setNetworkError(true);
        // console.log(error);
      });
  };

  const connectionError = () => {
    setNetworkError(false);
  };

  const closeModal = () => {
    setRedirect(true);
  };

  const myStyle = {
    marginTop: "12px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "12px",
    color: "#4F4F4F",
  };

  return (
    <Layout>
      {!dataLoader ? (
        <>
          <div className="d-flex justify-content-between">
            <div
              className="left_arrow2 back-arrow align-self-center mb-0 mt-4"
              style={{
                display: "flex",
                alignItems: "baseline",
                fontFamily: "inherit",
              }}
            >
              <img
                src={left_arrow2}
                onClick={() => history.goBack()}
                alt="left-arrow"
              />{" "}
              &nbsp;
              <h2>Update Tax Code</h2>
            </div>
            <div>
              <div className="d-flex ">
                <div className="button ml-0">
                  <button onClick={() => history.goBack()}>Back</button>
                </div>
                <div className="button ml-2">
                  <button onClick={() => window.location.reload()}>
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="form-wrap">
            {networkError.length > 4 && (
              <p style={{ color: "red" }}>{networkError}</p>
            )}
            <div className="container">
              <div class="mb-3">
                <label className="form-label" style={myStyle}>
                  Tax Slab Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={taxName}
                  aria-describedby="emailHelp"
                  onChange={(e) => setTaxname(e.target.value)}
                />
              </div>
              <div className="spec"></div>
              <div className="specification justify-content-between">
                <>
                  <label className="form-label" style={myStyle}>
                    Tax Slab Value
                  </label>
                  {specs && specs.length > 0
                    ? specs.map((value, index) => {
                        return (
                          <div key={index}>
                            <section
                              className=" my-3 d-flex"
                              style={{
                                width: "100%",
                              }}
                            >
                              <div className="" style={{ width: "33%" }}>
                                <input
                                type="number"
                                  placeholder="From..."
                                  value={value.startAmount}
                                  className={
                                    "form-control form-control-nonround"
                                  }
                                  mandatory={true}
                                  onChange={(e) =>
                                    handleChange2(e, "startAmount", index)
                                  }
                                />

                                <i style={{ fontSize: "13px", color: "red" }}>
                                  {speNameError}
                                </i>
                              </div>

                              <div className=" mx-3" style={{ width: "33%" }}>
                                <input
                                type="number"
                                  placeholder="To..."
                                  value={value.endAmount}
                                  className={
                                    "form-control form-control-nonround"
                                  }
                                  mandatory={true}
                                  onChange={(e) =>
                                    handleChange2(e, "endAmount", index)
                                  }
                                />

                                <i style={{ fontSize: "13px", color: "red" }}>
                                  {speValueError}
                                </i>
                              </div>

                              <div style={{ width: "33%" }}>
                                <select
                                  className="form-select form-control form-control-nonround"
                                  aria-label="Default select example"
                                  onChange={(e) =>
                                    handleChange2(e, "gstPercentage", index)
                                  }
                                  value={value.gstPercentage}
                                >
                                  <option selected>
                                    Open this select menu
                                  </option>
                                  {/* <option selected > {value.gstPercentage +"%"}</option> */}

                                  <option value="3">3 %</option>
                                  <option value="5">5 %</option>
                                  <option value="12">12 %</option>
                                  <option value="18">18 %</option>
                                  <option value="28">28 %</option>
                                </select>
                                <i style={{ fontSize: "13px", color: "red" }}>
                                  {spegstError}
                                </i>
                              </div>

                              <span className="mx-5">
                                {specs.length > 1 && (
                                  <button
                                    className="cross_button"
                                    style={{ paddingTop: "0" }}
                                    onClick={() => removespecs(index)}
                                  >
                                    {" "}
                                    X
                                  </button>
                                )}
                              </span>
                            </section>
                          </div>
                        );
                      })
                    : null}

                  {specs.length < 10 ? (
                    <div className="button-variant">
                      <button onClick={() => addspecs()}>+ADD</button>
                    </div>
                  ) : null}
                </>
              </div>
            </div>
            <div className="button">
              <button onClick={updateTaxonomy}>Update</button>
            </div>
          </div>

          {redirect ? <Redirect to="/taxonomy" /> : null}
          {show === true ? (
            <SuccessModal message="Taxonomy updated!" onClick={closeModal} />
          ) : null}
          {networkError === true ? (
            <SuccessModal
              message="Internet Connection Error.."
              onClick={connectionError}
            />
          ) : null}
          {showLoader === true ? <LoaderModal /> : null}
        </>
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div>
      )}
    </Layout>
  );
};

export default UpdateTexonomy;
