import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CategoryList from '../../wrapper/category/categoryList';
// import SubCategoryList from '../../wrapper/category/subCategoryList';
// import SubSubCategoryList from '../../wrapper/category/SubSubCategoryList';


const CategoryMenu = () => {

  const history = useHistory();
  const [category, showCategory] = useState(true)
  const [subCategory, showSubCategory] = useState(false)
  const [subsubCategory ,showSubsubCategory ] =useState(false)


  const handleCategory = () => {
    showCategory(true)
    showSubCategory(false)
    showSubsubCategory(false)
    var categ = document.querySelector("#category")
    var subcateg = document.querySelector("#subcategory")
    var subsubcate = document.querySelector("#subsubcategory")
    categ.classList.add("active-menu")
    subcateg.classList.remove("active-menu")
    subsubcate.classList.remove("active-menu")
  }

  const handleSubCategory = () => {
    showCategory(false)
    showSubCategory(true) 
    showSubsubCategory(false)
    var categ = document.querySelector("#category")
    var subcateg = document.querySelector("#subcategory")
    var subsubcate = document.querySelector("#subsubcategory")
    categ.classList.remove("active-menu")
    subcateg.classList.add("active-menu")
    subsubcate.classList.remove("active-menu")
  }

  const handleSubSubCategory = () => {
    showCategory(false)
    showSubCategory(false)
    showSubsubCategory(true)
    var categ = document.querySelector("#category")
    var subcateg = document.querySelector("#subcategory")
    var subsubcate = document.querySelector("#subsubcategory")
    categ.classList.remove("active-menu")
    subcateg.classList.remove("active-menu")
    subsubcate.classList.add("active-menu")
  }


  return (
    <div>
      <div className="menu-bar category-menu">
        <section>
          <div id="category" className="active-menu" onClick={handleCategory}>Category</div>
          <div id="subcategory" onClick={handleSubCategory}>SubCategory</div>
          <div id="subsubcategory" onClick={handleSubSubCategory}>SubSubCategory</div>
        </section>
      </div>
      {category && (
        <CategoryList />
      )}
      {subCategory && (
        history.push('/manage-sub-category') 

      )}
{subsubCategory && (
  history.push('/manage-sub-sub-category') 
      )}
    </div>
  )
}

export default CategoryMenu