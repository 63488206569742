import React from 'react';
import Layout from "../../layout/layout"
import ProductVariantList from '../../wrapper/products/ProductVariantList';
const ProductVariant = () => {
    return (
        <Layout>
           <ProductVariantList />
        </Layout>
    )
}

export default ProductVariant
