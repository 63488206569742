import React from 'react';
import Layout from '../../layout/layout';
// import Layout from "../layout/layout" 
import BanUserList from './BanUserList';

const BanUserDetail = () => {

  return (
    <Layout>
      <div>
      <BanUserList/>
      </div>
    </Layout>
  )
}

export default BanUserDetail