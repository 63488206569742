import React from 'react'
import Layout from "../../layout/layout"
import AddSubSubCategory from '../../wrapper/category/AddSubSubCategory'
const CreateSubSubCategory = () => {
  return (
    <Layout>
      <AddSubSubCategory />
    </Layout>
  )
}

export default CreateSubSubCategory
