
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import Layout from '../../layout/layout';
import axiosauth from '../../axios-config-auth';

const ViewvariantValue = () => {


    const { variantId } = useParams();
    const history = useHistory();
    const [variantData, setVariantdata] = useState([])



    useEffect(() => {
        let obj = {
            variantId: variantId,
        }
        axiosauth.post('/variants/find-variantby-id/', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                // console.log("4444", res)
                if (res.success === true) {
                    console.log(res.variant)
                    setVariantdata(res.variant)
                    // setCollections(res.data.data)
                    // settotal(res.data.count);
                    // setShowLoader(false);
                } else {
                    // setCollections([])
                    // settotal(0);
                    // setShowLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [variantId])



    return (
        <Layout>

            <br />
            <br />
            <div className='d-flex justify-content-between'>
                <div className="left_arrow2 back-arrow align-self-center mb-0" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
                    <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
                    <h2>View Variant Value</h2>

                </div>
                <div>
                    <div className='d-flex '>
                        <div className="button ml-0">
                            <button onClick={() => history.goBack()}>Back</button>
                        </div>
                        <div className="button ml-2">
                            <button onClick={() => window.location.reload()}>Refresh</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <section className="detail-body mt-5" >
                    <div className='font-weight-bold'>Variant Name</div>
                    <div className="titleStyle">{variantData.parent?.variantName}</div>
                </section>


                {/* <section className="detail-body" style={{ width: "100%" }}>
                    <div className='font-weight-bold'> Variant Value  </div>
                    <div className='font-weight-bold'>Display name  </div>

                </section>
                <section className="detail-body" style={{ width: "100%" }}>
                    <div
                    // style={{width:"100%"}}
                    >
                        {variantData && variantData.values.length > 0 && (
                            variantData.values?.map((item, index) => {
                                return (
                                    <div>
                                        <p className=''>{index + 1 + ". " + item.value} </p>
                                    </div>
                                )
                            })
                        )}
                    </div>
                    <div>
                        {variantData && variantData.values.length > 0 && (
                            variantData.values?.map((item, index) => {
                                return (
                                    <div>
                                        <p className=''>{index + 1}   {". " + item.value} </p>
                                    </div>
                                )
                            })
                        )}
                    </div>


                    
                </section> */}

<section className='card' style={{backgroundColor: "rgba(51, 51, 51, 0.05)"}}>
<div className='container card-body '>
                <div className='row'>
                    <div className='col-6'>
                        <p className='font-weight-bold'>Variant Value</p>
                        <div className='row'>
                            {variantData && variantData.values.length > 0 && (
                                variantData.values?.map((item, index) => {
                                    return (


                                        <div className='col-12'>
                                            <p className=''>{index + 1 + ". " + item.value} </p>
                                        </div>

                                    )
                                })
                            )}

                        </div>
                    </div>
                    <div className='col-6'>
                        <p className='font-weight-bold'>Display Name</p>
                        <div className='row'>
                            {variantData && variantData.values.length > 0 && (
                                variantData.values?.map((item, index) => {
                                    return (


                                        <div className='col-12'>
                                            <p className=''>{ item.displayName} </p>
                                        </div>

                                    )
                                })
                            )}

                        </div>
                    </div>
                </div>

            </div>
</section>
            </div>

          




            {/* {redirect ? <Redirect to="/variant-name-manage" /> : null}
      {varData.data.message === "success" ? <SuccessModal message="Variant Name Updated !" onClick={closeModal} /> : null} */}
        </Layout>
    )
}

export default ViewvariantValue