import {
    CHANGE_PASSWORD,
    USER_LOADING,
    USER_FAIL,
    PASSWORD_STATUS,

} from '../constants/types';
import axiosauth from '../../axios-config-auth';

export function loadingUser() {
    return {
        type: USER_LOADING,
    };
}


export function editPassword(user) {
    return {
        type: CHANGE_PASSWORD,
        payload: user
    };
}

export function passStatus() {
    return {
        type: PASSWORD_STATUS
    };
}


export function fetchUserFail(user) {
    return {
        type: USER_FAIL,
        payload: user
    };
}

export const fetchPasswordStatus = () => {
    return async dispatch => {
        dispatch(passStatus());
    }
}


export const updatePassword = (obj) => {
    return async dispatch => {
        dispatch(loadingUser());
        axiosauth.post('/profile/set-new-password', obj)
            .then(async (response) => {
                let res = JSON.parse(response.data)
                console.log(res);
                if (res.success === true) {
                    dispatch(editPassword(res));
                } else {
                    dispatch(fetchUserFail(res.message));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(fetchUserFail("Network Error"));
            })

    };
};
