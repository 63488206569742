import React from 'react';
import Layout from "../layout/layout"
import OrderList from '../wrapper/orders/orders';

const OrderDetail = () => {

  return (
    <Layout>
      <div>
        <OrderList />
      </div>
    </Layout>
  )
}

export default OrderDetail