import React, { useEffect, useState } from "react";
// import SearchBox from "../../components/halper/SearchBox";
import { Link, useHistory } from "react-router-dom";
import axiosauth from "../../axios-config-auth";
import Loader from "react-loader-spinner";
// import Pagination from "react-js-pagination";
// eslint-disable-next-line
// import DatePicker from "react-datepicker";
// eslint-disable-next-line
// import DatePickerInput from "../../components/halper/DatePickerInput";
// import DownloadExcelModal from "../../components/halper/DownloadExcelModal";
// import LoaderModal from "../../components/halper/LoaderModal";
import NoData from "../../components/halper/NoData";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import SuccessModal from "../../components/halper/SuccessModal";
import deleteIcon from "../../assets/icons/delete.png";
import close from "../../assets/icons/close.png";
// import close from '../../assets/icons/delete.png'
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { confirmAlert } from "react-confirm-alert";
import editImageIcon from '../../assets/icons/edit.png';

// import SuccessModal from '../../components'

export const OptionDetails = () => {
  const history = useHistory();

  const [optionData, setOptionData] = useState([]);
  const [optionName, setOptionName] = useState("");
  const [success, setSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [showModal, setShowModal] = useState(false);

  const handleEditClose = () => setShowEditModal(false);
  const handleEditShow = () => setShowEditModal(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editName, setEditName] = useState("");
  const [editId, setEditId] = useState("");

  const fetchData = () => {
    axiosauth
      .post("/option/load",{limit:20})
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setOptionData(res.data);
          // console.log("load- option--->", res)

          setShowLoader(false);
        } else {
          // setProductCatalogue([])
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
 

  const handleOptionActive = (product) => {
    // console.log("product ====>>>>" , product)
    // console.log("active", !product.active)
    let obj = {
      "optionId": product.optionId,
      "active": product.active ==="true" ? "false" : "true"
    };
    confirmAlert({
      // title: 'Change Status',
      message: "Are you sure you want to In-Active this sticker ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axiosauth
              .post("/option/update-option", obj)
              .then(async (response) => {
                let res = JSON.parse(response.data);
                console.log("res------------->>",res)
                if (res.success === true) {
                  fetchData()
                  // setEditShow(false);
                  // getCollections("Collection Deleted");
                } else {
                  // fetchData();
                  // setEditShow(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Hello"),
        },
      ],
    });
  };

  const handleAddoption = () => {
    axiosauth
      .post("/option/create-option", { optionName: optionName })
      .then(async (response) => {
        let res = JSON.parse(response.data);
        // console.log("res:", res)
        if (res.success === true) {
          handleClose();
          // setSuccess(true)
          setTimeout(() => {
            fetchData();
          }, 1000);
          // console.log("variant")
          // setSuccess(true)
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const closeModal = () => {
    // setRedirect(true);
    console.log("close");
  };

  const handleEditChange = () => {
    axiosauth
      .post("/option/update-option", { optionName: editName, optionId: editId })
      .then(async (response) => {
        let res = JSON.parse(response.data);
        // console.log("res:", res)
        if (res.success === true) {
          handleEditClose();
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {/* <div><h3>Option Management</h3></div> */}

      <div className="d-flex justify-content-between">
        <div
          className="left_arrow2 back-arrow align-self-center mb-0 mt-4"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>ADD SECTION</h2>
        </div>
        
      </div>
      <div className="d-flex justify-content-end">
          <div className="d-flex ">
            <div className="d-flex ">
              <div className="button">
                <button
                  onClick={() => {
                    handleShow();
                  }}
                >
                  ADD SECTION
                </button>
              </div>
            </div>
            <div className="button ml-0 mx-3">
              <button onClick={() => history.goBack()}>Back</button>
            </div>
            <div className="button ml-2">
              <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
          </div>
        </div>

      {/* ============================================
        Add Options */}

      <Modal show={showModal} backdrop="static" onHide={handleClose}>
        <Modal.Header className="border-0">
          <Modal.Title>Add Sticker</Modal.Title>
          <div className="d-end" onClick={handleClose} style={{ cursor: "pointer" }}>
            <img src={close} alt="img" height={20} />
            {/* <i className="bi bi-x-lg"></i> */}
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>Section Name</p>
          <div className="d-flex" style={{ justifyContent: "space-around" }}>
            <input
              type="text"
              style={{ width: "70%" }}
              className="form-control"
              onChange={(e) => setOptionName(e.target.value)}
            />
            <Button
              variant="primary"
              style={{ backgroundColor: "#333333", width: "45%",marginLeft:'2%' }}
              onClick={handleAddoption}
            >
             ADD SECTION
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* =============== Edit Option Name=========== */}
      <Modal show={showEditModal} backdrop="static" onHide={handleEditClose}>
        <Modal.Header className="border-0">
          <Modal.Title>Edit Section name</Modal.Title>
          <div className="d-end" onClick={handleEditClose} style={{ cursor: "pointer" }}>
            <img src={close} alt="img" height={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>Section Name</p>
          <div className="d-flex" style={{ justifyContent: "space-around" }}>
            <input
              type="text"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              style={{ width: "70%" }}
              className="form-control"
            />
            <Button
              variant="primary"
              style={{ backgroundColor: "#333333", width: "25%" }}
              onClick={handleEditChange}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {!showLoader ? (
        <div className="table-wrap ">
          <table>
            {optionData && optionData.length > 0 ? (
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th style={{textAlign:"left"}}>Section Name</th>
                  <th>Active</th>
                  <th>Edit Section name</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {optionData && optionData.length > 0 ? (
                optionData.map((product, index) => {
                  const deleteOptions = () => {
                    let obj = {
                      optionId: product.optionId,
                    };
                    confirmAlert({
                      // title: 'Change Status',
                      message: "Are you sure you want to delete this sticker ?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            axiosauth
                              .post("/option/remove-option", obj)
                              .then(async (response) => {
                                let res = JSON.parse(response.data);
                                if (res.success === false) {
                                  // setEditShow(false);
                                  // getCollections("Collection Deleted");
                                } else {
                                  fetchData();
                                  // setEditShow(false);
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          },
                        },
                        {
                          label: "No",
                          onClick: () => console.log("Hello"),
                        },
                      ],
                    });
                  };

                  return (
                    <tr key={index}>
                      <td> {index + 1}</td>

                      <td className="titleStyle" style={{textAlign:"left"}}>{product.optionName}</td>
                      <td className="titleStyle">
                        <div
                          className="custom-control custom-switch mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOptionActive(product)}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            // value={user.active}
                            defaultChecked={product.active=== "true" ? true : false}
                            name="customSwitchesChecked"
                            // onChange={()=>handleCategoryAlert(user)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitchesChecked"
                          ></label>
                        </div>
                      </td>
                      <td className="mt-1 ">
                        <p
                          className="mt-3"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleEditShow();
                            setEditName(product.optionName);
                            setEditId(product.optionId);
                          }}
                        >
                          <img src={editImageIcon} alt="edit" />
                        </p>
                      </td>

                      <td className=" mt-1">
                        <Link to={`/options/view/${product.optionId}`}>
                          <p
                            className="mt-3"
                            style={{ color: "red", fontSize: "14px" }}
                          >
                            View All Products
                          </p>
                        </Link>
                      </td>

                      <td>
                        <img
                          onClick={() => deleteOptions()}
                          src={deleteIcon}
                          alt=""
                          className="delete-icon"
                        />
                        {/* deleteOption(product.optionId) */}
                      </td>
                     
                    </tr>
                  );
                })
              ) : (
                <NoData message="Option Not Found" />
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={30000} //3 secs
          />
        </div>
      )}

      {success ? (
        <SuccessModal message="New feature Created !" onClick={closeModal} />
      ) : null}
    </>
  );
};
