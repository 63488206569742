import React from 'react';
import Layout from "../../layout/layout"
import ProductList from '../../wrapper/products/products';

const ProductsDetail = () => {

  return (
    <Layout>
        <ProductList />
    </Layout>
  )
}

export default ProductsDetail