import React from 'react';
import dateicon from '../../assets/icons/dateicon.png';

const DatePickerInput = ({ place,value, onClick }) => {
     return (
        <div className="date-picker-input"
            onClick={onClick} style={{"justifyContent":'space-around'}}>
            <span>
            {value}
            {value.length===0?(<span style={{fontSize:window.innerWidth<=1421?"11px":"14px"}}>
                {place}
                </span>):(null)}
            </span>

            <img src={dateicon} alt="date-picker-icon" />
        </div>
    )
}

export default DatePickerInput;