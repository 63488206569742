import React from 'react'
import search from '../../assets/icons/search_icon.png'
const SearchBox = ({ placeholder, onChange, onClick }) => {
    return (
        <div className="search-box">
            <img src={search} alt="search-icon" onClick={onClick} />
            <input type="text"
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    )
}

export default SearchBox
