import React, { useState, useEffect } from "react";
import axiosauth from "../../axios-config-auth";
import SuccessModal from "../../components/halper/SuccessModal";
import { Redirect, useHistory , useParams} from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import left_arrow2 from "../../assets/icons/down_arrow2.png";

const AddVariantForm = () => {
  const animatedComponents = makeAnimated();
  const [errorMsg, setErrorMsg] = useState("");
  const [show, setShow] = useState(false);
  const [variants, setVariants] = useState([]);
  const [sendVarinat, setSendVariant] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const [id, setId] = useState("");
  const [data, setData] = useState([]);
  const[displayName , setDisplayName] = useState("")

  const {variantId } = useParams();

  const [variantForm, setVariantForm] = useState({
    variantValue: "",
    parentVariant: "",
  });

  const handleChange = (name) => (event) => {
    setVariantForm({
      ...variantForm,
      error: false,
      [name]: event.target.value,
    });
  };

  // console.log("iddd=======",variantId)

  const handleSuccess = () => {
    setShow(false);
    setRedirect(true);
  };

  const handleSelect = (selectedOption) => {
    setSendVariant(selectedOption.label);
    setId(selectedOption.value);
  };
  // console.log("sendVarinat",sendVarinat)
  const onSubmit = (e) => {
    e.preventDefault();
    let obj = {
      value: variantForm.variantValue,
      parentVariant: sendVarinat,
      displayName :displayName,
    };
    // console.log("obj", obj);
    axiosauth
      .post("/variants/create-variant-value/", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          getAllVariant();
          setShow(true);
        } else {
          setErrorMsg("variant Value Already Exist");
          getAllVariant();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //
  const getAllVariant = (search) => {
    let obj = {
      filters: {
        variantName: search,
      },
    };
    axiosauth
      .post("/variants/findAllVariants/", obj)
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          if (res.success === true) {
            if (res.data) {
              let allVarList = [];
              for (var k = 0; k < res.data.length; k++) {
              if(res.data[k].parent.variantId === variantId){

                allVarList.push({
                  value: res.data[k].parent.variantId,
                  label: res.data[k].parent.variantName,
                });
              }
              }
              setVariants(allVarList);
            }
          }
        } else {
          console.log("else block");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axiosauth
      .post("/variants/findAllVariants/")
      .then(async (response) => {
        let res = JSON.parse(response.data);
        if (res.success === true) {
          if (res.data) {
            let allVarList = [];
            for (var k = 0; k < res.data.length; k++) {
              if(res.data[k].parent.variantId === variantId){
                console.log("variant exist" ,variantId)
                allVarList.push({
                  value: res.data[k].parent.variantId,
                  label: res.data[k].parent.variantName,
                });
              }
             
            }
            setVariants(allVarList);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (id.length > 5) {
      axiosauth
        .post("/variants/find-variantby-id/", { variantId: id })
        .then(async (response) => {
          let res = JSON.parse(response.data);
          if (res.success === true) {
            // console.log("res==>>", res.variant.values);
            setData(res.variant.values);
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const handleInputChange = (e) => {
    getAllVariant(e);
  };

  return (
    <>
      {show && (
        <SuccessModal
          onClick={handleSuccess}
          message="New Variant Value Added"
        />
      )}
      {redirect ? <Redirect to="/variant-manage" /> : null}

      <br />
      <br />

      <div className="d-flex justify-content-between">
        <div
          className="left_arrow2 back-arrow align-self-center mb-0"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img
            src={left_arrow2}
            onClick={() => history.goBack()}
            alt="left-arrow"
          />{" "}
          &nbsp;
          <h2>Add Variant Value</h2>
        </div>
        <div>
          <div className="d-flex ">
            <div className="button ml-0">
              <button onClick={() => history.goBack()}>Back</button>
            </div>
            <div className="button ml-2">
              <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={onSubmit}>
        <div className="form-wrap">
          <div className="form-body">
            <div id="select-tag">
              <p>Select Variant</p>
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={variants}
                name="variants"
                required
                onInputChange={handleInputChange}
                onChange={handleSelect}
              />
            </div>
<div className="m-3">
            {data &&
              data.length > 0 ? (
                <div>
                  <span className="font-weight-bold">Available Variants --</span>
             { data.map((item ,index ,list) => {
                return (
                  <>
                    <span className="mt-5"> {item.value }
                    {index < list.length - 1 ? ", " : null}
                    </span>
                  </>
                );
              })
            }
</div>
              )
              
            : null}
            </div>

            <div className="single-field" id="short-description">
              <p>
                Variant Value <b>*</b>
              </p>
              <input
                type="text"
                name="variantValue"
                className="form-control form-control-round"
                id="variantValue"
                placeholder="Enter variant value"
                value={variantForm.variantValue}
                onChange={handleChange("variantValue")}
                required
              />
            </div>

            <div className="single-field" id="short-description">
              <p>
                Display Name <b>*</b>
              </p>
              <input
                type="text"
                name="variantValue"
                className="form-control form-control-round"
                id="variantValue"
                placeholder="Display Name"
                value={displayName}
                onChange={(e)=>setDisplayName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="button">
            <button>Add</button>
          </div>
          <span style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
            {errorMsg}
          </span>
        </div>
      </form>
    </>
  );
};

export default AddVariantForm;
