import React from 'react';
import Layout from "../layout/layout"
import OrderList from '../wrapper/orders/OrderReturn';

const OrderReturn = () => {
    return (
        <Layout>
        <div>
            <OrderList />
        </div>
        </Layout>
    )
}

export default OrderReturn
