import React, { useEffect, useState } from 'react';
import SearchBox from '../../components/halper/SearchBox';
import { Link, useHistory } from 'react-router-dom';
import axiosauth from '../../axios-config-auth';
import Loader from 'react-loader-spinner';
import Pagination from "react-js-pagination";
// eslint-disable-next-line
import DatePicker from "react-datepicker";
// eslint-disable-next-line
import DatePickerInput from '../../components/halper/DatePickerInput';
import DownloadExcelModal from '../../components/halper/DownloadExcelModal';
// import LoaderModal from '../../components/halper/LoaderModal';
import NoData from '../../components/halper/NoData';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import close from '../../assets/icons/close.png';
import Layout from '../../layout/layout';
// import editIcon from "../../assets/icons/edit.png";
import editimage from "../../assets/icons/editimage.png"
import noimgae from "../../assets/images/no-img.png"

const SuspendedProductList = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [productCatalogue, setProductCatalogue] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [excelDateStart, setExcelDateStart] = useState(null);
  const [startDate, setStartDate] = useState(null);
  // eslint-disable-next-line
  // const [disButton, setDisButton] = useState(true);
  const [excelLink, setExcelLink] = useState(null);
  // const [showLoader1, setShowLoader1] = useState(false);
  const [excelDateStartError, setExcelDateStartError] = useState("");
  const [startDateError, setstartDateError] = useState("");

  const handleClose = () => setShowEditModal(false);
  // const handleShow = () => setShowEditModal(true);
  const [showModal, setShowEditModal] = useState(false);
  const [file, setFile] = useState(null)
  const [limitData, setLimitData] = useState(30)
  // const [success, setSuccess] = useState(false);
  // const [redirect, setRedirect] = useState(false);

  // For page count
  const history = useHistory();
  const pageCount = new URLSearchParams(window.location.search).get('page');

  /**
  * TODO: function for pagination handling page number change
  *@version 1.0.0
  * @author [Unknown]
  */
  const handlePageChange = (pageNumber) => { 
    setActivePage(pageNumber);

    let obj = {
      "count": (pageNumber - 1) * limitData,
      // limit: 10,
      limit: limitData,
      field: "max",
      isProduct : true ,
      suspend: "true",
      "sortBy": {
        "type": "createdOn",
        "order": -1
      },
      filters: {
        "postedBy.ownerId": " "
      },
    };

    axiosauth
      .post("/products/load-distinct-catalogue", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setProductCatalogue(res.data.data)
          setTotal(res.data.total);
          setShowLoader(false);
        } else {
          setProductCatalogue([])
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  const search = (e) => {
    let obj = {
      count: 0,
      // limit: 10,
      limit: limitData,
      isProduct : true ,
      suspend: "true",
      field: "max",
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }, filters: {
        "postedBy.ownerId": " ",

        name: e.target.value,
      },
    };

    axiosauth
      .post("/products/load-distinct-catalogue", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {

          setProductCatalogue(res.data.data)
          setTotal(res.data.total);

        } else {
          setProductCatalogue([])

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };





  useEffect(() => {

    const loadProducts = (uuid) => {
      let obj = {
        "count": (pageCount - 1) * limitData,
        // limit: 10,
        limit: limitData,
        
        isProduct : true ,
        suspend: "true",
        field: "max",
        "sortBy": {
          "type": "createdOn",
          "order": -1
        }, filters: {

          "postedBy.ownerId": " "
        },
      };

      axiosauth
        .post("/products/load-distinct-catalogue", obj)
        .then((Response) => {
          let res = JSON.parse(Response.data);

          if (res.success === true) {
            setProductCatalogue(res.data.data)
            setTotal(res.data.total);

            setShowLoader(false);

          } else {
            setProductCatalogue([])
            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    loadProducts()


  }, [pageCount, history , limitData]);



  /**
* TODO: Pagination
*@version 1.0.0
* @author [Unknown]
*/

  // Handle Pagination

  const [locationKeys, setLocationKeys] = useState([]);

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {

        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)
          handlePageChange1(getQueryVariable('page'))
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          handlePageChange1(getQueryVariable('page'))

        }
      }
    })
  }, [locationKeys, history])

  const handlePageChange1 = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handlePageChange5 = (pageNumber) => {
    handlePageChange(pageNumber);
    setActivePage(pageNumber);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };


  // ------excel filter---------------
  const excelDateSearch = (date) => {



    if (excelDateStart === null) {
      setExcelDateStartError("Select Start Date");
      return 0;
    }

    if (date === null) {
      setstartDateError("Select End Date");

      return 0;
    }

    if (date < excelDateStart) {
      alert("End Date Must Be Greater Than Start Date");
      return 0;
    }
    setStartDate(date);
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    var myDate = new Date(date);
    myDate.setDate(date.getDate() + 1);


    let obj = {
      "count": (pageCount - 1) * limitData,
      // limit: 10,
      limit: limitData,
      field: "max",
      isProduct : true ,
      suspend: "true",
      hasFilters:true,
      "sortBy": {
        "type": "createdOn",
        "order": -1
      }, filters: {

        "createdOn": {
          "startDate": formatDate(excelDateStart),
          "endDate": formatDate(date)
        },

        // "postedBy.ownerId": " "
        // D8LkKagF

      },
    };  

    axiosauth
      .post("/products/load-distinct-catalogue", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {

          // console.log("load-catelogue--->", res)
          setProductCatalogue(res.data.data)
          setTotal(res.data.total);
          setShowLoader(false);
          // setDisButton(false);
          setstartDateError("")
          setExcelDateStartError("")
        } else {
          setProductCatalogue([])
          setShowLoader(false);
          setstartDateError("")
          setExcelDateStartError("")
        }
      })
      .catch((error) => { 
        setstartDateError("")
        setExcelDateStartError("")
      });
  }
  const excelDateSearch1 = (date) => {

    if (date === null) {
      setExcelDateStartError("Select Start Date");
      return 0;
    }

    if (startDate === null) {
      setstartDateError("Select End Date");
      return 0;
    }
    if (startDate < date) {
      alert("End Date Must Be Greater Than Start Date");
      return 0;
    }
    setExcelDateStart(date);
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    var myDate = new Date(startDate);
    myDate.setDate(startDate.getDate() );

    let obj = {
      count: (pageCount - 1) * limitData,
      // limit: 10,
      limit: limitData,
      field: "max",
      isProduct : true ,
      suspend: "true",
      hasFilters:true,
      sortBy: {
        type: "createdOn",
        order: -1,
      },
      filters: { 
        createdOn: {
          startDate: formatDate(date),
          endDate: formatDate(myDate),
        },
      },
    }; 
    axiosauth
      .post("/products/load-distinct-catalogue", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) { 
          setProductCatalogue(res.data.data)
          setTotal(res.data.total);
          setstartDateError("")
          setExcelDateStartError("")
          setShowLoader(false);
          // setDisButton(false);
        } else {
          setProductCatalogue([])
          setShowLoader(false);
          setstartDateError("")
          setExcelDateStartError("")
        }
      })
      .catch((error) => { 
        setstartDateError("")
        setExcelDateStartError("")
      });
  }; 

  const handleSubmit = () => {
    var formData = new FormData();
    formData.append("file", file)

    axiosauth.post("/products/upload-product-xlsx", formData)
      .then((res) => {
        let result = JSON.parse(res.data);
        if (result.success === true) {
          // setSuccess(true)
          handleClose(true)


        }
      })

  }

  // const closeModal = () => {
  //   setRedirect(true);
  // }



  // ------------------------------

  return (

    <Layout>
      {/* ----date filter===== */}


      {/* {showLoader1 === true ? <LoaderModal /> : null} */}


      {excelLink !== null ?
        <DownloadExcelModal>
          <span className="excel-title">Download Product List</span><br />


          <hr />


          <a href={excelLink} onClick={() => setExcelLink(null)} target="_blank" rel="noopener noreferrer" className="exportLink">Download</a>

        </DownloadExcelModal>
        : null}


      {/* =================== Modal here================ */}
      <Modal show={showModal} backdrop="static" onHide={handleClose}>
        <Modal.Header className="border-0">
          <Modal.Title>Upload Excel file</Modal.Title>
          <end onClick={handleClose} style={{ cursor: "pointer" }}>
            {" "}
            {/* <i className="bi bi-x-lg"></i> */}
            <img src={close} style={{ height: "20px", width: "20px" }} alt="close-img"></img>
          </end>
        </Modal.Header>
        <Modal.Body>
          <p>Upload File</p>
          <div className="d-flex" style={{ justifyContent: "space-around" }}>
            <input
              accept=".xlsx"
              type="file"
              onChange={(e) => { 
                setFile(e.target.files[0])
              }}

              style={{ width: "70%" }}
            //   className="form-control "
            />
            <Button
              variant="primary"

              style={{ backgroundColor: "#333333", width: "25%" }}
              onClick={handleSubmit}
            >
              Upload
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>



      <SearchBox placeholder="Search products..." onChange={search} />
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-2">
          <div className="p-1 datePicorderExcel">
            <DatePicker
              selected={excelDateStart}
              onChange={(date) => {
                setExcelDateStart(date);
                excelDateSearch1(date);
              }}
              maxDate={new Date()}
              customInput={
                <DatePickerInput
                  place={"Select Start Date"}
                  isRound={true}
                  value={excelDateStart}
                />
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <p style={{ color: "red" }}>{excelDateStartError}</p>
        </div>

        <div className="col-md-2">
          <div className="p-1 datePicorderExcel">
            <DatePicker
              selected={startDate}
              onChange={(date) => excelDateSearch(date)}
              customInput={
                <DatePickerInput
                  place={"Select End Date"}
                  isRound={true}
                  value={startDate}
                />
              }
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <p style={{ color: "red" }}>{startDateError}</p>
        </div> 
      </div>

      <div className='row'>
        <div className="col-md-6 col-sm-3 d-flex">
          <p>Total Master Product :  <span className="font-weight-bold ">{total} </span></p>
          <div>

            <select className="form-select mx-3" aria-label="Default select example"
              onChange={(e) => { 
                setLimitData(parseInt(e.target.value))
              }}
            >
              <option selected>Select page</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
        </div>
        {/* <div className="col-6  ">
          <div className='row d-flex justify-content-end'>
            <div className='col-6 '>
              <select className="form-select form-select-lg" aria-label="Default select example "
                style={{ backgroundColor: " #9fa59f3b", color: "black" }}
                onChange={(e) => handleChangeUpload(e)}
              >
                <option value="" selected>Add product</option>
                <option value="downloadExcel" >Download Sample Excel</option>
                <option value="uploadExcel">Upload  Excel File</option>
                <option value="uploadManually">Upload Product Manually</option>
              </select>
            </div>
          </div>
        </div> */}
      </div>
      <br />



      {!showLoader ?
        <div className="table-wrap overflow-auto table-responsive card py-4" style={{ height: "500px" }}>
          <table>
            {productCatalogue && productCatalogue.length > 0 ? (<thead>
              <tr>
                <th>S.No.</th>
                {/* <th>Product Image</th> */}
                <th style={{ textAlign: "left" }}><span className='mx-1'>Master Product</span></th>
                
                <th className='text-nowrap px-3'>Image</th>
                <th className='text-nowrap px-2'>Brand Name</th>
                <th className='text-nowrap px-2'>Category</th>
                <th className='text-nowrap px-2'>Sub Category</th>
                <th className='text-nowrap px-2'>Date</th>
                <th className='text-nowrap px-2'>Edit image</th>
                <th className='text-nowrap px-2'></th>
              </tr>
            </thead>) : null}
            <tbody>

              {productCatalogue && productCatalogue.length > 0 ?
                productCatalogue.map((product, index) => {
                  var date = new Date(product.createdOn).toLocaleDateString()
                  return (
                    <tr key={index}>
                      <td>{((pageCount ? pageCount : 1) - 1) * 30 + (index + 1)}. </td>
                      {/* <td>
                        <div className="banner-img">
                          {product.imageUrls.length > 0 ? (<img alt="gppglsld" src={product.imageUrls[0].url} />
                          ) : (<img src="/assets/no-image.jpg" alt="banner-img" />
                          )}
                        </div>
                      </td> */}
                      <td className="titleStyle" style={{ textAlign: "left" }}>{product.name}</td>
                      

                      <td >
                        <div className="banner-img">
                          <img src={product?.imageUrls[0] !== undefined ? product?.imageUrls[0].url : product?.imageUrls[0] !== undefined ? product?.imageUrls[0].url : noimgae} alt="variant-img" />
                        </div>
                      </td>
                      <td>{product.brand?.name}</td>

                      <td className="titleStyle">{product.category.cat ? product.category.cat.name : null}</td>
                      <td className="titleStyle">{product.category.sub1 ? product.category.sub1.name : null}</td>
                      <td>{date}</td>
                      <td
                        onClick={() =>
                          localStorage.setItem(
                            "productVariantId",
                            product.catalogueId
                          )
                        }
                      >
                        <Link to={`/update-variant-image/${product?.catalogueId}`}>
                          <img
                            src={editimage}
                            alt="edit-button"
                            className="edit-icon"
                            height={25}
                          />
                        </Link>
                      </td>


                      <td>
                        <Link to="/suspended-products-variant?page=1">
                          <span
                            className="manage-link"
                            onClick={() => {
                              localStorage.setItem("pobj", product._id)
                              localStorage.setItem("pbrand", product.brand.name)
                              localStorage.setItem("brandId", product.brand)
                              localStorage.setItem("gst", product.gst)
                              localStorage.setItem("pname", product.name) 

                            }}
                          >

                            Manage

                          </span>
                        </Link>
                      </td>
                    </tr>
                  )
                })


                :
                (<NoData message="Products Not Found" />)}


            </tbody>
          </table>
          {/* <div className="pagination-custom">
            <Pagination
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={limitData}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              hideNavigation
              onChange={handlePageChange5}
            />
          </div> */}
        </div> :
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={500000}
          />
        </div>
      }
      <div className="pagination-custom">
            <Pagination
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={limitData}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              hideNavigation
              onChange={handlePageChange5}
            />
          </div>

      <br />

    </Layout>
  )
}

export default SuspendedProductList;