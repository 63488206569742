import React, { useState } from "react";
import Layout from "../../layout/layout";
import uploadIcon from "../../assets/icons/upload.png";
import axiosauth from "../../axios-config-auth";
import SuccessModal from "../../components/halper/SuccessModal";
import { Redirect, useParams } from "react-router";
import editImageIcon from "../../assets/icons/edit.png";
import de from "../../assets/icons/delete.png";
// import v from "../../assets/image/videoplayback.mp4";
// import CustomCropper from "../../components/halper/CustomCropper";
import LoaderModal from "../../components/halper/LoaderModal";
import { confirmAlert } from "react-confirm-alert";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
// import DragAndDrop from './DragAndDrop';

const AddVariantImage = () => {
  const [video, setVideo] = useState(null);
  const [videoFile, setVideoFile] = useState();

  const [thumbnail, setthumbNail] = useState(null);
  const [previewThumb, setpreviewThumb] = useState("");

  const [file, setfile] = useState(null);
  // const [imageUrl, setImageurl] = useState("")
  const [fsize, setFsize] = useState("");
  // const [isFile, setIsFile] = useState(false);
  const [sizeMsg, setSizeMsg] = useState("");
  const [result, setResult] = useState(null);
  // const [imageObj, setImageObj] = useState(null);
  // const [youtubeurl, setyoutubeurl] = useState(null);

  const [file1, setfile1] = useState(null);
  // const [imageUrl1, setImageurl1] = useState("")
  const [fsize1, setFsize1] = useState("");
  // const [isFile1, setIsFile1] = useState(false);
  const [sizeMsg1, setSizeMsg1] = useState("");
  const [result1, setResult1] = useState(null);
  // const [imageObj1, setImageObj1] = useState(null);

  const [file2, setfile2] = useState(null);
  // const [imageUrl2, setImageurl2] = useState("")
  const [fsize2, setFsize2] = useState("");
  // const [isFile2, setIsFile2] = useState(false);
  const [sizeMsg2, setSizeMsg2] = useState("");
  const [result2, setResult2] = useState(null);
  // const [imageObj2, setImageObj2] = useState(null);

  const [file3, setfile3] = useState(null);
  // const [imageUrl3, setImageurl3] = useState("")
  const [fsize3, setFsize3] = useState("");
  // const [isFile3, setIsFile3] = useState(false);
  const [sizeMsg3, setSizeMsg3] = useState("");
  const [result3, setResult3] = useState(null);
  // const [imageObj3, setImageObj3] = useState(null);

  const [file4, setfile4] = useState(null);
  // const [imageUrl4, setImageurl4] = useState("")
  const [fsize4, setFsize4] = useState("");
  // const [isFile4, setIsFile4] = useState(false);
  const [sizeMsg4, setSizeMsg4] = useState("");
  const [result4, setResult4] = useState(null);
  // const [imageObj4, setImageObj4] = useState(null);

  // ================================
  const [file5, setfile5] = useState(null);
  // const [imageUrl3, setImageurl3] = useState("")
  const [fsize5, setFsize5] = useState("");
  // const [isFile3, setIsFile3] = useState(false);
  const [sizeMsg5, setSizeMsg5] = useState("");
  const [result5, setResult5] = useState(null);
  // const [imageObj5, setImageObj5] = useState(null);

  const [file6, setfile6] = useState(null);
  // const [imageUrl3, setImageurl3] = useState("")
  const [fsize6, setFsize6] = useState("");
  // const [isFile3, setIsFile3] = useState(false);
  const [sizeMsg6, setSizeMsg6] = useState("");
  const [result6, setResult6] = useState(null);
  // const [imageObj6, setImageObj6] = useState(null);

  const [file7, setfile7] = useState(null);
  // const [imageUrl3, setImageurl3] = useState("")
  const [fsize7, setFsize7] = useState("");
  // const [isFile3, setIsFile3] = useState(false);
  const [sizeMsg7, setSizeMsg7] = useState("");
  const [result7, setResult7] = useState(null);
  // const [imageObj7, setImageObj7] = useState(null);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [imageError, setImageError] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const { varImgId } = useParams();
  // console.log("varImgId", varImgId);

  /**
   * TODO: function use for adding images of product
   *@version 1.0.0
   * @author [ Unknown]
   */
  const imageSubmit = () => {
    console.log("fsize", fsize);
    if (fsize < 500000) {
      setSizeMsg("");
      if (fsize1 < 500000) {
        setSizeMsg1("");
        if (fsize2 < 500000) {
          setSizeMsg2("");
          if (fsize3 < 500000) {
            setSizeMsg3("");
            if (fsize4 < 500000) {
              setSizeMsg4("");
              if (fsize5 < 500000) {
                setSizeMsg5("");
                if (fsize6 < 500000) {
                  setSizeMsg6("");
                  if (fsize7 < 500000) {
                    setSizeMsg7("");

                    var formData = new FormData();

                    // var productVariantId = localStorage.getItem("productVariantId")
                    formData.append("catalogueId", varImgId);

                    if (result === null) {
                      setImageError("Image Required");
                      return 0;
                    }

                    if (result !== null) {
                      formData.append("image", result);
                    }
                    if (result1 === null) {
                      setImageError("Image Required");
                      return 0;
                    }
                    if (result1 !== null) {
                      formData.append("image", result1);
                    }
                    if (result2 !== null) {
                      formData.append("image", result2);
                    }
                    if (result3 !== null) {
                      formData.append("image", result3);
                    }
                    if (result4 !== null) {
                      formData.append("image", result4);
                    }
                    if (result5 !== null) {
                      formData.append("image", result5);
                    }
                    if (result6 !== null) {
                      formData.append("image", result6);
                    }
                    // if (result7 !== null) {
                    //   formData.append("image", result7);
                    // }
                    

                    setShowLoader(true);

                    axiosauth
                      // .post("/products/upload-product-pics", formData) 
                      // .post("/products/push-product-image", formData)
                      .post("/catalogue/upload-catalogue-images", formData)
                      .then((response) => {
                        let res = JSON.parse(response.data);
                        if (res.success === true) {
                          if (result7 === null) {
                            var formdata = new FormData();
                            formdata.append("catalogueId", varImgId);
                            formdata.append("image", video);

                            axiosauth
                              .post("/catalogue/push-product-video", formdata)
                              .then((response) => {
                                let res = JSON.parse(response.data);
                                if (res.success === true) {
                                  var formdata = new FormData();
                                  formdata.append("productId", varImgId);
                                  formdata.append("image", thumbnail);

                                  axiosauth
                                    .post(
                                      "/products/push-product-thumbnail",
                                      formdata
                                    )
                                    .then((response) => {
                                      let res = JSON.parse(response.data);
                                      if (res.success === true) {
                                        setShowLoader(false);
                                        setOpenSuccess(true);
                                      }
                                    });
                                } 
                              })
                              .catch(() => {
                                setNetworkError(true);
                                setShowLoader(false)
                              });
                          } else {
                            setShowLoader(false);
                            setOpenSuccess(true);
                          }
                        }
                      })
                      .catch((err) => {
                        setNetworkError(true);
                      });
                     
                  } else {
                    setSizeMsg7("image size should be less than 500kb");
                  }
                } else {
                  setSizeMsg6("image size should be less than 500kb");
                }
              } else {
                setSizeMsg5("image size should be less than 500kb");
              }
            } else {
              setSizeMsg4("image size should be less than 500kb");
            }
          } else {
            setSizeMsg3("image size should be less than 500kb");
          }
        } else {
          setSizeMsg2("image size should be less than 500kb");
        }
      } else {
        setSizeMsg1("image size should be less than 500kb");
      }
    } else {
      setSizeMsg("image size should be less than 500kb");
    }
  };

  const [redirect, setRedirect] = useState(false);
  const closeModal = () => {
    setRedirect(true);
  };

  const connectionError = () => {
    setNetworkError(false);
  };

  const backButton = () => {
    if (result !== null) {
      confirmAlert({
        // title: 'Change Status',
        message: "Are you sure to do this. Without Image upload.",
        buttons: [
          {
            label: "Yes",
            onClick: () => setRedirect(true),
          },
          {
            label: "No",
            onClick: () => console.log("No"),
          },
        ],
      });
    } else {
      setRedirect(true);
    }
  };

  const imagedelete =(e)=>{
console.log("delete",e.target.id)
if(e.target.id==="1"){
  setfile(null)
  setResult(null)
}
if(e.target.id==="2"){
  setfile1(null)
  setResult1(null)
}
if(e.target.id==="3"){
  setfile2(null)
  setResult2(null)
}
if(e.target.id==="4"){
  setfile3(null)
  setResult3(null)
}
if(e.target.id==="5"){
  setfile4(null)
  setResult4(null)
}
if(e.target.id==="6"){
  setfile5(null)
  setResult5(null)
}
if(e.target.id==="7"){
  setfile6(null)
  setResult6(null)
}
if(e.target.id==="8"){
  setfile7(null)
  setResult7(null)
}
 
  }
  return (
    <Layout>
      {networkError === true && (
        <SuccessModal
          message="Internet Connection Error.."
          onClick={connectionError}
        />
      ) }

      {showLoader === true &&<LoaderModal /> }
      {redirect && <Redirect to="/brand?page=1" /> }
      {openSuccess && (
        <SuccessModal message="Image Added!" onClick={closeModal} />
      ) }

      <div className="form-wrap">
        {/* <div className="form-header">
                    <h4>Add Variant Photo</h4>
                </div> */}

        <div
          className="left_arrow2 back-arrow"
          style={{
            display: "flex",
            alignItems: "baseline",
            fontFamily: "inherit",
          }}
        >
          <img src={left_arrow2} onClick={backButton} alt="left-arrow" /> &nbsp;
          <h2> {`Product Image Dimension Must be (1040 X 1480) , image (jpg, png , WebP),image size must be less than < 200KB` }</h2>
        </div>

        <div className="row d-flex">
          <section className="brand-image-upload mb-4">
            {file === null && result === null ? (
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" />
                  <br />
                  <p>
                    Upload image
                    <br />
                    <span style={{ fontSize: "10px" }}>1040 X 1480</span>
                  </p>
                </button>
                <input
                id="1"
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {

                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                  required
                />
              </div>
            ) : (
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img
                      for="upload-image"
                      class="edit-img"
                      src={editImageIcon}
                      alt=""
                    />
                  </label>
                </div>
                <div class="overlay1 my-4">
                  <label for="" id='1' onClick={(e)=> imagedelete(e)}>
                    <img
                      for=""
                      class="edit-img"
                      src={de}
                      id='1'
                      alt=""
                    />
                  </label>
                </div>
                <img class="screen" src={file ? file : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {

                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }

                    setResult(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480  pixels."
                        );
                      }
                    };
                  }}
                />
              </aside>
            )}

            <span style={{ fontSize: "12px", color: "red" }}>{sizeMsg}</span>
          </section>

          <section className="image-uploaded mx-2">
            {file1 === null && result1 === null ? (
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" />
                  <br />
                  <p>
                    Upload image
                    <br />
                    <span style={{ fontSize: "10px" }}>1040 X 1480</span>
                  </p>
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      console.log("size", img.width, img.height);
                      if (img.width === 1040 && img.height === 1480) {
                        setfile1(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480  pixels."
                        );
                      }
                    };

                    setResult1(e.target.files[0]);
                    // setfile1(URL.createObjectURL(e.currentTarget.files[0]));
                  }}
                  required
                />
              </div>
            ) : (
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img
                      for="upload-image"
                      class="edit-img"
                      src={editImageIcon}
                      alt=""
                    />
                  </label>
                </div>
                <div class="overlay1 my-4">
                  <label for="" id='2' onClick={(e)=> imagedelete(e)}>
                    <img
                      for=""
                      class="edit-img"
                      src={de}
                      id='2'
                      alt=""
                    />
                  </label>
                </div>
                <img class="screen" src={file1 ? file1 : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {

                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult1(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1480 && img.height === 1040) {
                        setfile1(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                />
              </aside>
            )}
          </section>

          <section className="image-uploaded mx-2">
            {file2 === null && result2 === null ? (
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" />
                  <br />
                  <p>
                    Upload image
                    <br />
                    <span style={{ fontSize: "10px" }}>1040 X 1480</span>
                  </p>
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult2(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile2(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480  pixels."
                        );
                      }
                    };
                  }}
                  required
                />
              </div>
            ) : (
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img
                      for="upload-image"
                      class="edit-img"
                      src={editImageIcon}
                      alt=""
                    />
                  </label>
                </div>
                <div class="overlay1 my-4">
                  <label for="" id='3' onClick={(e)=> imagedelete(e)}>
                    <img
                      for=""
                      class="edit-img"
                      src={de}
                      id='3'
                      alt=""
                    />
                  </label>
                </div>
                <img class="screen" src={file2 ? file2 : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult2(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile2(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480  pixels."
                        );
                      }
                    };
                  }}
                />
              </aside>
            )}
          </section>

          <section className="image-uploaded mx-2">
            {file3 === null && result3 === null ? (
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" />
                  <br />
                  <p>
                    Upload image
                    <br />
                    <span style={{ fontSize: "10px" }}>1040 X 1480</span>
                  </p>
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {

                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult3(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile3(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480  pixels."
                        );
                      }
                    };
                  }}
                  required
                />
              </div>
            ) : (
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img
                      for="upload-image"
                      class="edit-img"
                      src={editImageIcon}
                      alt=""
                    />
                  </label>
                </div>
                <div class="overlay1 my-4">
                  <label for="" id='4' onClick={(e)=> imagedelete(e)}>
                    <img
                      for=""
                      class="edit-img"
                      src={de}
                      id='4'
                      alt=""
                    />
                  </label>
                </div>
                <img class="screen" src={file3 ? file3 : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }

                    setResult3(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile3(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                />
              </aside>
            )}
          </section>

          <section className="image-uploaded mx-2">
            {file4 === null && result4 === null ? (
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" />
                  <br />
                  <p>
                    Upload image
                    <br />
                    <span style={{ fontSize: "10px" }}>1040 X 1480</span>
                  </p>
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                  
                    setResult4(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile4(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                  required
                />
              </div>
            ) : (
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img
                      for="upload-image"
                      class="edit-img"
                      src={editImageIcon}
                      alt=""
                    />
                  </label>
                </div>
                <div class="overlay1 my-4">
                  <label for="" id='5' onClick={(e)=> imagedelete(e)}>
                    <img
                      for=""
                      class="edit-img"
                      src={de}
                      id='5'
                      alt=""
                    />
                  </label>
                </div>
                <img class="screen" src={file4 ? file4 : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult4(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile4(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                />
              </aside>
            )}
          </section>

          <section className="image-uploaded mx-2">
            {file5 === null && result5 === null ? (
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" />
                  <br />
                  <p>
                    Upload image
                    <br />
                    <span style={{ fontSize: "10px" }}>1040 X 1480</span>
                  </p>
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult5(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile5(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                  required
                />
              </div>
            ) : (
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img
                      for="upload-image"
                      class="edit-img"
                      src={editImageIcon}
                      alt=""
                    />
                  </label>
                </div>
                <div class="overlay1 my-4">
                  <label for="" id='6' onClick={(e)=> imagedelete(e)}>
                    <img
                      for=""
                      class="edit-img"
                      src={de}
                      id='6'
                      alt=""
                    />
                  </label>
                </div>
                <img class="screen" src={file5 ? file5 : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult5(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile5(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480  pixels."
                        );
                      }
                    };
                  }}
                />
              </aside>
            )}

            {/* <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label" for="flexRadioDefault1">
                                select Product Image
                            </label>
                        </div> */}
          </section>

          <section className="image-uploaded mx-2">
            {file6 === null && result6 === null ? (
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" />
                  <br />
                  <p>
                    Upload image
                    <br />
                    <span style={{ fontSize: "10px" }}>1040 X 1480</span>
                  </p>
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult6(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile6(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                  required
                />
              </div>
            ) : (
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img
                      for="upload-image"
                      class="edit-img"
                      src={editImageIcon}
                      alt=""
                    />
                  </label>
                </div>
                <div class="overlay1 my-4">
                  <label for="" id='7' onClick={(e)=> imagedelete(e)}>
                    <img
                      for=""
                      class="edit-img"
                      src={de}
                      id='7'
                      alt=""
                    />
                  </label>
                </div>
                <img class="screen" src={file6 ? file6 : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {

                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult6(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile6(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                />
              </aside>
            )}
          </section>

          {/* <section className="image-uploaded  mx-2">
            {file7 === null && result7 === null ? (
              <div class="upload-btn-wrapper mt-2">
                <button className="uplod-btn">
                  <img src={uploadIcon} alt="" />
                  <br />
                  <p>
                    Upload image
                    <br />
                    <span style={{ fontSize: "10px" }}>1040 X 1480</span>
                  </p>
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult7(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile7(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                  required
                />
              </div>
            ) : (
              <aside class="image">
                <div class="overlay1">
                  <label for="file-upload">
                    <img
                      for="upload-image"
                      class="edit-img"
                      src={editImageIcon}
                      alt=""
                    />
                  </label>
                </div>
                <div class="overlay1 my-4">
                  <label for="" id='8' onClick={(e)=> imagedelete(e)}>
                    <img
                      for=""
                      class="edit-img"
                      src={de}
                      id='8'
                      alt=""
                    />
                  </label>
                </div>
                <img class="screen" src={file7 ? file7 : null} alt="" />

                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  name="myfile"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.currentTarget.files[0].size >= 204800) {
                      alert("Images size must be less than 200KB")
                      return 0
                  }
                    setResult7(e.target.files[0]);
                    let img = new Image();
                    img.src = URL.createObjectURL(e.currentTarget.files[0]);
                    img.onload = () => {
                      if (img.width === 1040 && img.height === 1480) {
                        setfile7(img.src);
                      } else {
                        alert(
                          "Incorrect image dimension. Please upload an image 1040X1480 pixels."
                        );
                      }
                    };
                  }}
                />
              </aside>
            )}
          </section> */}
        </div>
        <br />
        <span style={{ fontSize: "12px", color: "red" }}>
          {imageError} {sizeMsg} {sizeMsg1} {sizeMsg2} {sizeMsg3} {sizeMsg4}{" "}
          {sizeMsg5} {sizeMsg6} {sizeMsg7}
        </span>

        <div style={{ width: "100%", height: "100%", marginTop: "50px" }}>
          {/* <CustomCropper
                    setResult={setResult}
                    src={file}
                    selectFile={setfile}
                    imgWidth={500}
                    imgHeight={500}
                    setImageObj={setImageObj}
                /> */}

          {/* <CustomCropper
                    setResult={setResult1}
                    src={file1}
                    selectFile={setfile1}
                    imgWidth={500}
                    imgHeight={500}
                    setImageObj={setImageObj1}
                /> */}

          {/* <CustomCropper
                    setResult={setResult2}
                    src={file2}
                    selectFile={setfile2}
                    imgWidth={500}
                    imgHeight={500}
                    setImageObj={setImageObj2}
                /> */}

          {/* <CustomCropper
                    setResult={setResult3}
                    src={file3}
                    selectFile={setfile3}
                    imgWidth={500}
                    imgHeight={500}
                    setImageObj={setImageObj3}
                /> */}

          {/* <CustomCropper
                    setResult={setResult4}
                    src={file4}
                    selectFile={setfile4}
                    imgWidth={500}
                    imgHeight={500}
                    setImageObj={setImageObj4}
                /> */}
        </div>
        <div className="d-flex">
          <div className=" bg-light p-3 rounded-3">
            <div className="upload-btn-wrapper px-3">
              <button class="btn-uoload-custom">Upload a Video</button>
              <input
                type="file"
                accept="video/*"
                onChange={(e) => {
                  setVideo(e.target.files[0]);
                  setVideoFile(URL.createObjectURL(e.currentTarget.files[0]));
                }}
              ></input>
            </div>
            <span style={{marginBottom:"20px"}}>
              <video
                width="350px"
                src={videoFile}
                type="video/mp4"
                controls
                playsInline
              ></video>
            </span>
          </div>
          {/* <div className=" bg-light p-3 rounded-3">
          <div className="mx-5 upload-btn-wrapper px-3">
          <button class="btn-uoload-custom">Upload Thumbnail for video</button>
            <input
              type="file"
              // name="myfile"
              onChange={(e) => {
                setthumbNail(e.target.files[0] ? e.target.files[0] : null);
              }}
              accept="image/*"
            />
            
          </div>
          <span className="mx-5">
              {thumbnail === null ? (
                <img
                  src={thumbnail}
                  style={{ height: "100px", width: "100px" }}
                />
              ) : (
                <img
                  style={{ height: "100px", width: "100px" }}
                  src={URL.createObjectURL(thumbnail)}
                />
              )}
            </span>
          </div> */}
        </div>
        <div className="form-body ">
          {/* <input type="file" 
                    // name="myfile"
                    accept="image/*"
                    /> */}

          {/* <div className="single-field ">
                        <p>Youtube Video URL </p>
                        <input type="text"
                            id="name"
                            placeholder="Enter Url"
                            name="name"
                            value={youtubeurl}
                            onChange={(e) => setyoutubeurl(e.target.value)}
                        />

                    </div> */}
        </div>

        <div className="button">
          <button onClick={imageSubmit}>Publish Photo</button>
        </div>
      </div>
    </Layout>
  );
};

export default AddVariantImage;
