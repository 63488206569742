import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import left_arrow2 from '../../assets/icons/down_arrow2.png'
import axiosauth from '../../axios-config-auth'; 
import SuccessModal from '../../components/halper/SuccessModal'; 
import Loader from "react-loader-spinner";

export const ViewOptionsDetails = () => {

  const { optionId } = useParams()
  const history = useHistory()
  const [optionobj, setOprionObj] = useState([]);
  // const [optionProduct, setOptionProduct] = useState([])
  const [product, setProduct] = useState([]);
  const [pushProduct, setPushProduct] = useState([])
  const [success, setSuccess] = useState(false);

  const [brandObj, setBrandObj] = useState([])
  const [brandId, setBrandId] = useState('')
  const [categoryObj, setCategoryObj] = useState([]);
  const [categoryId, setCategoryId] = useState("")
  const [subCategoryObj, setSubCategoryObj] = useState([]);
  const [subCategoryId, setSUbCategoryId] = useState("")
  const [subSubCategoryObj, setSubSubCategoryObj] = useState([]);
  const [subSubCategoryId, setSubSubCategoryId] = useState("")
  const [loading , setLoading] = useState(false);

  useEffect(() => {
    const obj = {
      optionId: optionId
    }
    axiosauth
      .post("/option/find-option-id", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data); 

        if (res.success === true) {
          setOprionObj(res.result) 
          setPushProduct(res.data?.product)
          setLoading(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }, [optionId])

  useEffect(() => {
    const obj = {
      limit: 10000000,
      // suspend: "false",
      filters: {
        suspend: "false"
        // sub1categoryslug: search,
      },
      sorts: { sortbyPrice: 1 },
      userId: ""
    }
    axiosauth
      .post("/products/loadv2", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data); 
        if (res.success === true) {
          
          setProduct(res.data.products)

        } else {
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

  }, [optionId])
 

  const handleBrands = () => {
    const obj = {
      limit: 10000
    }
    axiosauth
      .post("/brand/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setBrandObj(res.data.data) 

        }  
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleCategory = () => {
    const obj = {
      "limit": 10000,
      "filters": {
        "type": "category"
      }
    }
    axiosauth
      .post("/categories/load", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setCategoryObj(res.data.categories)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleSubCategory = (id) => {
    let obj = {
      // "slug": id,
      "categoryId" :id,
      "limit": 100000
    }
    // console.log(obj)
    axiosauth
      .post("/categories/load-by-id", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) { 
          setSubCategoryObj(res.data.category.category[0].childCategories); 

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSUbSubCategory = (id) => {

    for(var i=0 ; i< subCategoryObj.length ;i++){
      if(subCategoryObj[i].slug === id){
        setSubSubCategoryObj(subCategoryObj[i].childCategories);
      }
    }

  }


  const handleGo = () => {
     
    const obj = {
      limit: 10000000,
      // suspend: "false",
      filters: {
        categoryId: categoryId,
        sub1categoryslug: subCategoryId,
        sub2categoryslug :subSubCategoryId,
        brand : brandId,
        suspend: "false"
      },
      sorts: { sortbyPrice: 1 },
      userId: ""
    }
    

    axiosauth.post("/products/loadv2", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          if (res.data.products.length > 0) {
            setProduct(res.data.products)
          }
          else {
            setProduct([])
          }
        }
        else {
          setProduct([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePushproduct = (product) => { 
    if (!pushProduct.includes(product, 0)) {
      setPushProduct([...pushProduct, product])
    }
  }

  const handleRemoveproduct = (id) => {
    pushProduct.forEach((item) => {
      if (item._id?.includes(id)) {
        setPushProduct(pushProduct.filter(item => item._id !== id))
      } else if (pushProduct.length < 4) {

      }
    })
  }

  const handleTrends = () => {
    let arr = []
    pushProduct.map((item) => {
      arr.push(item._id)

    })
    const obj = {
      "optionId": optionId,
      "product": arr

    }
    axiosauth.post("/option/push-products-toOption", obj).then((response) => {
      if (response.status === 200) {
        setSuccess(true)
      }

    })

  }

  const fetchData = () => {
    const obj = {
      optionId: optionId
    }
    axiosauth
      .post("/option/find-option-id", obj)
      .then((Response) => {
        let res = JSON.parse(Response.data);

        if (res.success === true) {
          setOprionObj(res.result)
          // setOptionProduct(res.result?.product)
          setPushProduct(res.data?.product)


        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const closeModal = () => {
    setSuccess(false)
    fetchData()

  }

  // const handleSuccess = () => {
  //   setShow(false);
  //   setRedirect(true);
  // }

  // console.log("option product====>>", product)


  return (
    <>
      {success === true ? <SuccessModal message=" Option Updated !!" onClick={closeModal} /> : null}
      {/* <div className="left_arrow2 back-arrow my-5" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
        <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
        <h2>Manage {optionobj?.optionName} Details</h2>
      </div> */}
      <div className='d-flex justify-content-between'>
        <div className="left_arrow2 back-arrow align-self-center mb-0 mt-4" style={{ display: 'flex', alignItems: "baseline", fontFamily: "inherit" }}>
          <img src={left_arrow2} onClick={() => history.goBack()} alt="left-arrow" /> &nbsp;
          <h2>Manage {optionobj?.optionName} Details</h2>

        </div>
        <div>
          <div className='d-flex '>
            <div className="button ml-0">
              <button onClick={() => history.goBack()}>Back</button>
            </div>
            <div className="button ml-2">
              <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
          </div>
        </div>
      </div>
      {loading===false ? (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="rgba(32, 159, 132, 1)"
            height={100}
            width={100}
            timeout={5000} //3 secs
          />
        </div>
      ) : (
<>
      <div className="form-body mt-4" style={{ marginTop: "-40px" }}>
        <section>
          <div className="two-field">
            <p>Brands</p>
            <div id="select-tag" onClick={handleBrands}>
              <select className="form-select" aria-label="Default select example"

                onChange={(e) => setBrandId(e.target.value)}
              >
                <option value="" selected>select brand</option>
                {brandObj?.map((item) => (
                  <option value={item.slug}>{item.name}</option>

                ))}

              </select>
            </div>
          </div>
          <div className="two-field">
            <p>Category</p>
            <div id="select-tag" onClick={handleCategory}>
              <select className="form-select" aria-label="Default select example"
                placeholder='select cat'
                onChange={(e) => {
                  setCategoryId(e.target.value)
                  handleSubCategory(e.target.value)
                }}
              >
                <option value="" selected>select category</option>
                {categoryObj?.map((item) => (
                  <option value={item.categoryId}>{item.name}</option>

                ))}

              </select>
            </div>
          </div>
        </section>

        <section>
          <div className="two-field">
            <p>Sub Category</p>
            <div id="select-tag" >
              <select className="form-select" aria-label="Default select example"

                onChange={(e) => {
                  setSUbCategoryId(e.target.value)
                  handleSUbSubCategory(e.target.value)
                }}
              >
                <option value="" selected>select sub category</option>
                {subCategoryObj?.map((item) => (
                  <option value={item.slug}>{item.name}</option>

                ))}

              </select>
            </div>
          </div>
          <div className="two-field">
            <p>Sub-Sub-Category</p>
            <div id="select-tag">
              <select className="form-select" aria-label="Default select example"
                placeholder='select cat'
                onChange={(e) => setSubSubCategoryId(e.target.value)}
              >
                <option value="" selected>select sub sub category</option>
                {subSubCategoryObj?.map((item) => (
                  <option value={item.slug}>{item.name}</option>

                ))}

              </select>
            </div>
          </div>
        </section>
        <div className="button mt-3">
          <button onClick={handleGo} className="px-5">GO </button>
        </div>
      </div>


      <div className='d-flex col-12 my-4'>
        <div className='col-5'>
          <h3 className='mx-3 mb-2'>All Product</h3>

          <div className='col-12' style={{ maxHeight: "50vh", overflow: "auto" }}  >


            {/* <input type="text" className="col-12" placeholder='Search product' /> */}
            <table className="table  " style={{ border: "1px solid", overflow: "auto" }}>

              <thead className=''>
                <tr>
                  <th scope="col-5">S.No.</th>
                  <th scope="col-5">Image</th>
                  <th scope="col-5">Product Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody  >
                {product && product.length > 0 ? (

                  <>
                    {product?.map((product, index) => {
                      // if (product.suspend === "false") {

                        return (
                          <>
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td><img src={product.productCatalogue?.imageUrls[0]?.url} style={{ height: "50px", width: "50px" }} /></td>
                              <td>{product?.catalogueName}</td>
                              <td onClick={() => handlePushproduct(product)}><p style={{ cursor: "pointer" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>

                              </p></td>
                            </tr>
                          </>


                        )
                      // }
                    })
                    }
                  </>

                ) :
                  <div className="alert alert-danger mx-2 my-2" role="alert" style={{ width: "max-content" }}>
                    Product not found
                  </div>
                }
              </tbody>
            </table>
          </div>

          {/* <div className='col-1' style={{ marginTop: "20%" }}>
          {product.length > 0 &&
            <BsFillArrowRightCircleFill style={{ fontSize: "30px" }} />
          }
        </div> */}
        </div>
        <div className='col-5'>
          <h3 className='mx-3 mb-2'>Option Product</h3>

          <div className='col-12' style={{ maxHeight: "50vh", overflow: "auto" }}>
            <table className="table" style={{ border: "1px solid", }} v-align="middle">

              <thead className=''>
                <tr>
                  <th scope="col-5">S.No.</th>
                  <th scope="col-5">Image</th>
                  <th scope="col-5">Product Name</th>
                </tr>
              </thead>
              <tbody>
                {
                  pushProduct && pushProduct.length > 0 ? (
                    pushProduct?.map((product, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td><img src={product.productCatalogue?.imageUrls[0]?.url} style={{ height: "50px", width: "50px" }} /></td>
                            <td>{product?.catalogueName}</td>
                            <td onClick={() => handleRemoveproduct(product._id)}><p style={{ cursor: "pointer" }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg " viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                              </svg>
                            </p></td>
                          </tr>
                        </>
                      )
                    })
                  )
                    : <div className="alert alert-danger mx-2 my-2" role="alert" style={{ width: "max-content" }}>
                      Product not found
                    </div>
                }
              </tbody>
            </table>
          </div>
        </div>

      </div>

      <div className="button mb-5">
        <button onClick={handleTrends} className="px-5">Save </button>
      </div>
</>)}

    </>
  )
}

