
import React, { useEffect, useState } from "react";
import Layout from "../../layout/layout";
import left_arrow2 from "../../assets/icons/down_arrow2.png";
import { useHistory, Redirect, useParams } from "react-router";
import axiosInstance from "../../axios-config-auth";
import SuccessModal from "../../components/halper/SuccessModal";
import Loader from "react-loader-spinner";

export const EditOfferSetting = () => {

    const { offersetting } = useParams()

    const history = useHistory();
    const [content, setContent] = useState('')
    const [success, setSuccess] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);




    useEffect(async () => {
        const result = await axiosInstance.post("/vouchers/get-offer-one", { offerTitleId: offersetting })
        const res = JSON.parse(result.data) 
        if (res.success) {
            setContent(res.data.offerTitle)
            setLoading(true)
        }
    }, [])


    const handleSubmit = async () => { 
        let obj = {
            offerTitle: content,
        }
        const result = await axiosInstance.post("/vouchers/create-offer-title", obj);
        let res = JSON.parse(result.data)
        if (res.success) {
            setSuccess(true)
        } else {

        }
    }
    const closeModal = () => {
        setRedirect(true);
    };

    return (
        <Layout>
            <div className="form-wrap">
                <div
                    className="left_arrow2 back-arrow"
                    style={{
                        display: "flex",
                        alignItems: "baseline",
                        fontFamily: "inherit",
                    }}
                >
                    <img
                        onClick={() => history.goBack()}
                        src={left_arrow2}
                        alt="left-arrow"
                    />
                    &nbsp;  &nbsp;  &nbsp;  &nbsp;
                    <h2>Update Product Offer Setting</h2>
                </div>

                {loading ? (
                    <>
                        <div className="form-body mt-5">
                            <div className="single-field">
                                <p>
                                    Offer Title<b></b>
                                </p>
                                <input
                                    type="text"
                                    id="title"
                                    placeholder="Enter title"
                                    name="title"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="button">
                            <button onClick={handleSubmit}>Save </button>
                        </div>
                    </>) : <div className="loader-style">
                    <Loader
                        type="ThreeDots"
                        color="rgba(32, 159, 132, 1)"
                        height={100}
                        width={100}
                        timeout={500000}
                    />
                </div>}
            </div>
            {redirect ? <Redirect to="/load-offer-setting" /> : null}
            {success ? (
                <SuccessModal message="New Offer Updated !" onClick={closeModal} />
            ) : null}
        </Layout>
    )
}
